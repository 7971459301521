/**
 * このファイルの `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import styled from 'styled-components';
import Paper from '@mui/material/Paper';

const GatewayPaper = styled(Paper).attrs(() => ({
  elevation: 0,
  variant: 'outlined',
}))`
  border-color: #828282;
  margin-bottom: ${(props) => props.theme.spacing(3.5)};
  overflow: auto;

  ${(props) => props.theme.breakpoints.down('sm')} {
    border: none;
    border-radius: 0;
  }
`;

export default GatewayPaper;
