/**
 * このファイル名の `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import React from 'react';
import styled from 'styled-components';
import { Chip as _Chip, ChipProps } from '@mui/material';

type Props = {
  /**
   * MUIが定義したcolorはvariantとして扱っているので別で定義する
   */
  textColor?: string;
  bgColor?: string;
  fontWeight?: string | number;
  /**
   * `theme.breakpoints.down('sm')}`のfontSizeを設定する
   */
  smFontSize?: string;
  fontSize?: string;
  maxWidth?: string;
  /**
   * `theme.breakpoints.down('sm')}`のminWidthを設定する
   */
  smMinWidth?: string;
  width?: string;
} & ChipProps;

const Chip = styled(
  ({
    /* eslint-disable @typescript-eslint/no-unused-vars */
    textColor,
    bgColor,
    fontSize,
    smFontSize,
    fontWeight,
    maxWidth,
    smMinWidth,
    width,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...props
  }: Props) => <_Chip {...props} />,
).attrs<Props>(() => ({
  size: 'small',
}))<Props>`
  background-color: ${(props) =>
    props.bgColor ?? props.theme.palette.chip.main};
  color: ${(props) => props.textColor ?? props.theme.palette.common.white};
  font-size: ${(props) => props.fontSize ?? props.theme.typography.pxToRem(12)};
  font-weight: ${(props) => props.fontWeight ?? 'normal'};
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width};
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: ${(props) =>
      props.smFontSize ?? props.theme.typography.pxToRem(11)};
    min-width: ${(props) => props.smMinWidth ?? '72px'};
  }
`;

export default Chip;
