import React from 'react';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import AlertHistoryTableContainer from 'src/domains/root/features/alerts/histories/AlertHistoryContainer';

const AlertHistory: React.FC = () => {
  return (
    <MainContents noXPaddingIfMobile notAllowOverflow showContractExpiredAlert>
      <AlertHistoryTableContainer />
    </MainContents>
  );
};

// ページタイトルの取得のために設定
AlertHistory.displayName = 'AlertHistory';
export default AlertHistory;
