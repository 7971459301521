import React from 'react';
import { useFormikContext } from 'formik';
import { CircularProgress } from '@mui/material';
import PrimaryButton, {
  PrimaryButtonProps,
} from 'src/domains/root/commons/buttons/Primary';

/**
 * SubmitButtonはstartIConをloadingにすることで、propsからもらわないようにする
 */
type Props = Omit<PrimaryButtonProps, 'startIcon'> & {
  withLoading?: boolean;
};

const SubmitButton: React.FC<Props> = ({
  children,
  withLoading = false,
  ...rest
}) => {
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <PrimaryButton
      type="submit"
      disabled={!isValid || isSubmitting}
      startIcon={
        withLoading &&
        isSubmitting && <CircularProgress size={18} sx={{ color: 'white' }} />
      }
      {...rest}
    >
      {children}
    </PrimaryButton>
  );
};

export default SubmitButton;
