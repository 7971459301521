import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import AlertListCard from 'src/domains/root/features/alerts/lists/AlertListCard';
import AlertChartModal from 'src/domains/root/features/gateways/AlertChartModal';
import type {
  Alert,
  AlertSensorUnit,
} from 'src/domains/root/features/alerts/slice';
import { Table, Typography } from '@mui/material';
import AccessControl from 'src/domains/root/commons/AccessControl';
import GatewayData from '../../gateways/GatewayDataV3';
import GatewayName from '../../gateways/GatewayNameV3';
import GatewayPaper from '../../gateways/GatewayPaperV3';
import TableHeadCell from '../../gateways/sensor-units/TableHeadCell';
import AlertListTableRow from './AlertListTableRow';

type ClickedAlert = {
  alert: Alert;
  alertSensorUnit: AlertSensorUnit;
};

interface AlertListTableProps {
  alerts: Alert[];
}

const AlertListTable: React.FC<AlertListTableProps> = (props) => {
  const { alerts } = props;

  const [clickedAlert, setClickedAlert] = useState<ClickedAlert | null>(null);

  const hideModal = () => setClickedAlert(null);

  return (
    <>
      <AlertsCount alerts={alerts} />
      <AlertList alerts={alerts} onClickAlertChartButton={setClickedAlert} />
      <AlertSensorDataModal clickedAlert={clickedAlert} hideModal={hideModal} />
    </>
  );
};

function AlertsCount({ alerts }: { alerts: Alert[] }) {
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return isSmallScreen ? (
    <Box display="flex" pl={2} pb={3} gap={2}>
      <Typography
        fontSize={(theme) => theme.typography.pxToRem(18)}
        fontWeight="bold"
        borderLeft={(theme) => `solid ${theme.palette.primary.main} 5px`}
        pl={1.5}
      >
        {intl.formatMessage({
          id: 'pages.CurrentAlert.counts.title',
        })}
      </Typography>
      <Typography lineHeight="normal">
        {intl.formatMessage(
          {
            id: 'pages.CurrentAlert.counts.unit',
          },
          {
            counts: alerts.reduce(
              (acc, cur) => acc + cur.sensorUnits.length,
              0,
            ),
          },
        )}
      </Typography>
    </Box>
  ) : null;
}

function AlertList({
  alerts,
  onClickAlertChartButton,
}: {
  alerts: Alert[];
  onClickAlertChartButton: (clickedAlert: ClickedAlert) => void;
}) {
  return (
    <>
      {alerts.map((alert, index) => (
        <GatewayPaper
          data-testid={`GatewayPaper-${index}`}
          key={alert.deviceId}
        >
          <GatewayName gateway={alert} displayBattery={false} />
          <Box
            p={(theme) => theme.spacing(3)}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                p: 2,
              },
            })}
          >
            <Box mb={(theme) => theme.spacing(3.5)}>
              <GatewayData gateway={alert} />
            </Box>
            <AlertSensorUnits
              alert={alert}
              onClickAlertChartButton={onClickAlertChartButton}
            />
          </Box>
        </GatewayPaper>
      ))}
    </>
  );
}

function AlertSensorUnits({
  alert,
  onClickAlertChartButton,
}: {
  alert: Alert;
  onClickAlertChartButton: (clickedAlert: ClickedAlert) => void;
}) {
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return isSmallScreen ? (
    <Box display="flex" flexDirection="column" gap={1.5}>
      {alert.sensorUnits.map((sensorUnit, index) => (
        <Box key={sensorUnit.deviceId} data-testid={`AlertListCard-${index}`}>
          <AlertListCard
            gatewayName={alert.name}
            sensorUnit={sensorUnit}
            onClickAlertChartButton={() =>
              onClickAlertChartButton({
                alert,
                alertSensorUnit: sensorUnit,
              })
            }
          />
        </Box>
      ))}
    </Box>
  ) : (
    <TableContainer>
      <Table>
        <colgroup>
          <col style={{ width: '60px', minWidth: '46px' }} />
          <col style={{ width: '40%' }} />
          <col style={{ minWidth: '92px' }} />
          <col style={{ minWidth: '92px' }} />
          <col style={{ minWidth: '92px' }} />
          <col style={{ minWidth: '92px' }} />
          <col style={{ minWidth: '92px' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableHeadCell align="center">
              {intl.formatMessage({
                id: 'alertListTable.sensorUnit.number',
              })}
            </TableHeadCell>
            <TableHeadCell>
              {intl.formatMessage({
                id: 'common.sensorUnitName',
              })}
            </TableHeadCell>
            <TableHeadCell align="center">
              {intl.formatMessage({
                id: 'common.status',
              })}
            </TableHeadCell>
            <TableHeadCell align="center">
              {intl.formatMessage({
                id: 'alertListTable.type',
              })}
            </TableHeadCell>
            <TableHeadCell align="center">
              {intl.formatMessage({
                id: 'alertListTable.occuredTime',
              })}
            </TableHeadCell>
            <TableHeadCell align="center">
              {intl.formatMessage({
                id: 'alertListTable.latestValue',
              })}
            </TableHeadCell>
            <TableHeadCell align="center">
              {intl.formatMessage({
                id: 'common.graph',
              })}
            </TableHeadCell>
            <AccessControl
              permit={(permissions) =>
                permissions.includes('alert.current.update')
              }
            >
              <TableHeadCell align="center" />
            </AccessControl>
          </TableRow>
        </TableHead>
        <TableBody>
          {alert.sensorUnits.map((sensorUnit, index) => (
            <TableRow
              data-testid={`GatewayTableRow-${index}`}
              key={sensorUnit.alertId}
            >
              <AlertListTableRow
                gatewayName={alert.name}
                sensorUnit={sensorUnit}
                onClickAlertChartButton={() =>
                  onClickAlertChartButton({
                    alert,
                    alertSensorUnit: sensorUnit,
                  })
                }
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AlertSensorDataModal({
  clickedAlert,
  hideModal,
}: {
  clickedAlert: ClickedAlert | null;
  hideModal: () => void;
}) {
  return (
    <>
      {clickedAlert && (
        <AlertChartModal
          handleClose={hideModal}
          alert={clickedAlert.alert}
          alertSensorUnit={clickedAlert.alertSensorUnit}
        />
      )}
    </>
  );
}

export default AlertListTable;
