import React, { useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Backdrop, Box, Button } from '@mui/material';
import {
  CloseRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material';
import * as API from 'src/apis';
import MemoImage from './MemoImage';
import useThumbnail from './useThumbnail';

export default function BiggerImage({
  images,
  selectedImage,
  closeImage,
}: {
  images: API.MemoImage[] | (API.MemoImage & { file: File })[];
  selectedImage: number;
  closeImage: () => void;
}) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    startIndex: selectedImage,
  });

  const onPrevButtonClick = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  const fileImages = images.map((image) => {
    if ('file' in image) {
      return image;
    }
    return {
      ...image,
      file: new File([], image.name, {
        type: image?.type,
      }),
    };
  });

  return (
    <>
      <Box
        display="flex"
        position="fixed"
        width={{
          xs: 300,
          sm: 480 + (48 + 8 * 3) * 2,
          md: 620 + (48 + 8 * 3) * 2,
        }}
        height={{
          xs: 300,
          sm: 480,
          md: 620,
        }}
        top="50%"
        left="50%"
        zIndex={9999 + 1}
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <Box
          position="absolute"
          top={16}
          right={{
            xs: 16,
            sm: 80 + 16,
          }}
          zIndex={9999 + 1}
        >
          <Button
            onClick={closeImage}
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              minWidth: 'unset',
              backgroundColor: 'white',
              '@media (hover: hover) and (pointer: fine)': {
                '&:hover': {
                  backgroundColor: 'lightgray',
                },
              },
            }}
          >
            <CloseRounded htmlColor="#00000054" fontSize="large" />
          </Button>
        </Box>
        <Box
          display={{
            xs: 'none',
            sm: 'flex',
          }}
          alignItems="center"
          pr={4}
        >
          <ArrowButton type="left" handleClick={onPrevButtonClick} />
        </Box>
        <Box overflow="hidden" ref={emblaRef} borderRadius={3}>
          <Box display="flex">
            {fileImages.map((image, index) => (
              <Box flex="0 0 100%" key={index}>
                <ImageItem img={image} />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          display={{
            xs: 'none',
            sm: 'flex',
          }}
          alignItems="center"
          pl={4}
        >
          <ArrowButton type="right" handleClick={onNextButtonClick} />
        </Box>
      </Box>

      <Backdrop open={true} sx={{ zIndex: 9999 }} />
    </>
  );
}

const ImageItem = React.memo(function ImageItem({
  img,
}: {
  img: API.MemoImage & { file: File };
}) {
  const thumbnailUrl = useThumbnail(img);

  return (
    <MemoImage
      src={thumbnailUrl}
      alt={img.name}
      width={{ xs: 300, sm: 480, md: 620 }}
      height={{ xs: 300, sm: 480, md: 620 }}
    />
  );
});

function ArrowButton({
  type,
  handleClick,
}: {
  type: 'left' | 'right';
  handleClick: () => void;
}) {
  return (
    <Button
      onClick={handleClick}
      sx={{
        width: 48,
        height: 48,
        color: 'white',
        borderRadius: '50%',
        minWidth: 'unset',
        backgroundColor: '#00000060',
        '&:hover': {
          '@media (hover: none)': {
            backgroundColor: '#00000060',
          },
        },
      }}
    >
      {type === 'left' ? (
        <KeyboardArrowLeftRounded htmlColor="white" fontSize="large" />
      ) : (
        <KeyboardArrowRightRounded htmlColor="white" fontSize="large" />
      )}
    </Button>
  );
}
