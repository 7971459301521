import React from 'react';
import { useSelector } from 'react-redux';
import New from '../features/invitations/users/New';
import Created from '../features/invitations/users/Created';
import { createdUserSelector } from '../features/invitations/users/slice';

const Signup: React.FC = () => {
  const createdUser = useSelector(createdUserSelector);

  return <>{!createdUser ? <New /> : <Created />}</>;
};

export default Signup;
