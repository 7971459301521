import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import Typography from '@mui/material/Typography';
import { default as _Menu } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccessControl from 'src/domains/root/commons/AccessControl';
import {
  handleAlertRestoreDialogOpen,
  handleAlertRestoreDialogClose,
} from 'src/domains/root/features/alerts/slice';

const SettingMenu = styled(_Menu)`
  & .MuiPopover-paper {
    border-radius: 4px;
    box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.4);

    & > ul {
      padding-bottom: ${(props) => props.theme.spacing(1.5)};
      padding-left: ${(props) => props.theme.spacing(1)};
      padding-right: ${(props) => props.theme.spacing(1)};
      padding-top: ${(props) => props.theme.spacing(1.5)};
      width: 302px;

      li {
        border-radius: 4px;

        &:hover {
          background: ${(props) => props.theme.palette.primary.light};
        }
      }
    }
  }
`;

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};

const AlertListMenu: React.FC<Props> = (props) => {
  const { anchorEl, setAnchorEl } = props;
  const intl = useIntl();

  const dispatch: AppDispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(handleAlertRestoreDialogClose());
  };

  const onClickAlertRestoreMenuItem = () => {
    dispatch(handleAlertRestoreDialogOpen());
    setAnchorEl(null);
  };

  return (
    <SettingMenu
      data-testid="AlertListSettingMenu"
      id="alert-list-setting-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: 184,
        },
      }}
    >
      <AccessControl
        permit={(permissions) => permissions.includes('alert.current.update')}
      >
        <MenuItem
          data-testid="RestoreAlertMenu"
          onClick={onClickAlertRestoreMenuItem}
          sx={{ width: 168 }}
        >
          <Typography>
            {intl.formatMessage({
              id: 'pages.CurrentAlert.restore.menu',
            })}
          </Typography>
        </MenuItem>
      </AccessControl>
    </SettingMenu>
  );
};

export default AlertListMenu;
