import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InformationIcon from './note/InformationIcon';

const Note: React.FC = () => {
  const intl = useIntl();

  return (
    <Box px={2.5} py={2} border="1px solid #828282" borderRadius="4px">
      <Box display="flex" alignItems="center">
        <Box mr={1.5}>
          <InformationIcon />
        </Box>
        <Typography
          fontWeight={700}
          fontSize={(theme) => theme.typography.pxToRem(18)}
          lineHeight={1.57}
        >
          {intl.formatMessage({
            id: 'pages.Setup.Welcome.note.title',
          })}
        </Typography>
      </Box>
      <Box mt={1.5}>
        <Typography
          fontSize={(theme) => theme.typography.pxToRem(15)}
          lineHeight={1.43}
        >
          {intl.formatMessage({
            id: 'pages.Setup.Welcome.note.description',
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default Note;
