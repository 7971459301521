import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import { WeatherHistoriesData } from '../SensorDataChart';
import * as API from './api';
import {
  SensorData,
  WeatherArea,
  WeatherHistoryItem,
  SensorDataPartialResponse,
  WeatherHistoriesResponse,
} from './type';

const BASE_DAYS = 3;
const day = (day: number) => 1000 * 60 * 60 * 24 * day;

export type SucceededResult = {
  status: 'succeeded';
  phase: 'full_data_fetched';
  sensorData: SensorData;
  weatherArea: WeatherArea;
  weatherHistories: WeatherHistoriesData;
};

export type Result =
  | { status: 'hasError' }
  | { status: 'loading' }
  | { status: 'noData' }
  | SucceededResult;

export default function useAlertSensorDataChart({
  sensorUnitId,
  areaId,
  includeRemovedData,
  occuredTimestamp,
}: {
  sensorUnitId: string;
  areaId?: string;
  includeRemovedData: boolean;
  occuredTimestamp: number;
}): Result {
  const begin = occuredTimestamp - day(BASE_DAYS);
  const end = occuredTimestamp + day(BASE_DAYS);

  const { getAccessTokenSilently } = useAuth0();

  const sensorDataPartialResult = useQuery<
    SensorDataPartialResponse,
    API.Error
  >({
    queryKey: Keys.sensorData.suPartial(sensorUnitId, begin, end),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return API.getSensorDataPartial(
        sensorUnitId,
        includeRemovedData,
        begin,
        end,
        token,
      );
    },
    refetchOnWindowFocus: false,
  });

  const weatherHistoriesResult = useQuery<WeatherHistoriesResponse, API.Error>({
    queryKey: Keys.sensorData.weatherHistories(areaId, begin, end),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return API.getWeatherHistories(areaId ?? '', begin, end, token);
    },
    enabled: !!areaId,
    refetchOnWindowFocus: false,
  });

  useRedirectForError(sensorDataPartialResult, weatherHistoriesResult);

  if (sensorDataPartialResult.isPending || weatherHistoriesResult.isLoading) {
    return { status: 'loading' };
  }
  if (sensorDataPartialResult.isError || weatherHistoriesResult.isError) {
    return { status: 'hasError' };
  }
  if (Object.keys(sensorDataPartialResult.data).length === 0) {
    return { status: 'noData' };
  }

  return {
    status: 'succeeded',
    phase: 'full_data_fetched',
    sensorData: sensorDataPartialResult.data.sensorData,
    weatherArea: weatherHistoriesResult.data?.weatherArea ?? {
      displayAreaName: '',
    },
    weatherHistories: createWeatherHistoriesData(
      weatherHistoriesResult.data?.weatherHistories,
      sensorDataPartialResult.data.sensorData,
    ),
  };
}

/**
 * 表示用の天気データを生成する処理
 * selectorにしても良いけど、ユースケース的にmemoizeが有効なケースはないので、
 * とりあえず関数として実装しておく。
 */
const createWeatherHistoriesData = (
  weatherHistories: WeatherHistoryItem[] | undefined,
  sensorData: SensorData,
) => {
  const weatherHistoriesData: WeatherHistoriesData = {
    icons: [],
    info: {},
  };

  const temperatures = sensorData?.temperature;

  if (!temperatures || temperatures.length === 0) {
    return weatherHistoriesData;
  }

  const oldestTimestamp = temperatures[0][0];
  const latestTimestamp = temperatures[temperatures.length - 1][0];

  weatherHistories?.forEach((weatherHistoryData) => {
    if (
      oldestTimestamp < weatherHistoryData.timestamp &&
      latestTimestamp > weatherHistoryData.timestamp
    ) {
      weatherHistoriesData.icons.push({
        x: weatherHistoryData.timestamp,
        y: 5,
        marker: {
          symbol: `url(${
            import.meta.env.PUBLIC_URL ?? ''
          }/assets/weather_icon/${weatherHistoryData.icon})`,
          width: 56,
          height: 36,
        },
      });
      weatherHistoriesData.info[weatherHistoryData.timestamp] = {
        temperatureMax: 0,
        temperatureMin: 0,
        rainQuantity: 0,
      };
      weatherHistoriesData.info[weatherHistoryData.timestamp].temperatureMax =
        weatherHistoryData.temperatureMax;
      weatherHistoriesData.info[weatherHistoryData.timestamp].temperatureMin =
        weatherHistoryData.temperatureMin;
      weatherHistoriesData.info[weatherHistoryData.timestamp].rainQuantity =
        weatherHistoryData.rainQuantity;
    }
  });

  return weatherHistoriesData;
};

function useRedirectForError(
  sensorDataPartialResult: UseQueryResult<SensorDataPartialResponse, API.Error>,
  weatherHistoriesResult: UseQueryResult<WeatherHistoriesResponse, API.Error>,
) {
  const navigate = useNavigate();
  useEffect(() => {
    const error = sensorDataPartialResult.error || weatherHistoriesResult.error;
    if (!error) {
      return;
    }
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  }, [navigate, sensorDataPartialResult.error, weatherHistoriesResult.error]);
}
