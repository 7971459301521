import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BreadcrumbTitleV3,
  BreadcrumbsV3,
} from 'src/domains/root/commons/breadcrumbs';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import PaymentMethodListTable from 'src/domains/root/features/contracts/payment-method/PaymentMethodListTable';
import {
  showInvoiceSuccessMessageSelector,
  updateShowInvoiceSuccessMessage,
} from 'src/domains/root/features/contracts/paymentSlice';
import { activeWorkspacePaymentsSelector } from 'src/domains/root/features/users/slice';
import { useSelector } from 'src/domains/root/store';

export default function PaymentMethod() {
  const intl = useIntl();

  const dispatch = useDispatch();

  const payments = useSelector(activeWorkspacePaymentsSelector);
  const invoiceDescription = useSelector(showInvoiceSuccessMessageSelector);

  useEffect(() => {
    return () => {
      dispatch(updateShowInvoiceSuccessMessage(false));
    };
  }, [dispatch]);

  return (
    <MainContents noXPaddingIfMobile notAllowOverflow showContractExpiredAlert>
      <Box display="flex" flexDirection="column" gap={{ xs: 3, sm: 3.5 }}>
        <Box pl={{ xs: 2, sm: 0 }}>
          <BreadcrumbsV3>
            <Link to="/settings/others">
              <BreadcrumbTitleV3 color="primary">
                {intl.formatMessage({
                  id: 'pages.OtherSetting.title',
                })}
              </BreadcrumbTitleV3>
            </Link>
            <BreadcrumbTitleV3>
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.payment-method.title',
              })}
            </BreadcrumbTitleV3>
          </BreadcrumbsV3>
        </Box>
        <PaymentMethodListTable
          payments={payments}
          invoiceDescription={invoiceDescription}
        />
      </Box>
    </MainContents>
  );
}
