import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { default as _Dialog } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogCloseIcon from 'src/domains/root/commons/dialog/title/CloseIcon';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import {
  DeleteWorkspacesUser,
  deleteWorkspacesUser,
  targetUserSelector,
} from '../slice';

const dialogWidth = 572;

const UserDeleteDialog = styled(_Dialog)`
  & .MuiDialog-container .MuiDialog-paper {
    width: ${dialogWidth}px;
    ${(props) => props.theme.breakpoints.down('sm')} {
      width: ${dialogWidth / 2}px;
    }
  }
`;

const UserDeleteDialogTitle = styled(DialogTitle)`
  & h2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: space-between;
    line-height: 24px;
  }

  background-color: white;
  color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2.5)}
    ${(props) => props.theme.spacing(3)};
`;

const dialogCloseIconButtonStyle = css`
  left: 12px;
`;

const dialogCloseIconStyle = css`
  color: ${(props) => props.theme.palette.primary.main};
`;

const dialogActionsStyle = css`
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
  padding-right: ${(props) => props.theme.spacing(2.5)};
  padding-top: ${(props) => props.theme.spacing(2.5)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: flex;
    justify-content: center;
    padding-right: 0;
  }
`;

const actionButtonStyle = css`
  border-radius: 4px;
  height: 34px;
  width: 100px;
`;

const noButtonStyle = css`
  &:hover {
    background: #5a5a5a;
    color: white;
  }

  background: #e6e6e6;
  border: 2px solid #5a5a5a;
  color: #5a5a5a;
  font-weight: bold;
  ${actionButtonStyle}
`;

const yesButtonStyle = css`
  &:hover {
    background: ${(props) => props.theme.palette.nito.main};
  }

  background: ${(props) => props.theme.palette.primary.main};
  color: white;
  font-weight: bold;
  ${actionButtonStyle}
`;

interface DialogProps {
  userDeleteDialogOpen: boolean;
  setUserDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeletedUser: React.Dispatch<
    React.SetStateAction<DeleteWorkspacesUser | null>
  >;
}

const Dialog: React.FC<DialogProps> = (props) => {
  const { userDeleteDialogOpen, setUserDeleteDialogOpen, setDeletedUser } =
    props;
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const targetUser = useSelector(targetUserSelector);

  const handleDialogClose = () => {
    setUserDeleteDialogOpen(false);
  };

  const handleSubmit = () => {
    if (targetUser) {
      const token = getAccessTokenSilently();
      const promise = dispatch(
        deleteWorkspacesUser({
          token,
          workspaceId: workspace.workspaceId,
          userId: targetUser.userId,
          email: targetUser.email,
        }),
      );

      promise.unwrap().then((user) => {
        setDeletedUser(user);
      });
    }

    handleDialogClose();
  };

  return (
    <UserDeleteDialog
      open={userDeleteDialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="user-delete-dialog-title"
    >
      <UserDeleteDialogTitle id="user-delete-dialog-title">
        <Box
          display="flex"
          width="100%"
          height={18}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight={700}>
            {intl.formatMessage({
              id: 'pages.OtherSetting.users.management.delete.dialog.title',
            })}
          </Typography>
          <IconButton
            size="large"
            onClick={handleDialogClose}
            css={dialogCloseIconButtonStyle}
          >
            <DialogCloseIcon css={dialogCloseIconStyle} />
          </IconButton>
        </Box>
      </UserDeleteDialogTitle>
      <Divider />
      <DialogContent dividers sx={{ py: 2.5 }}>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {intl.formatMessage(
            {
              id: 'pages.OtherSetting.users.management.delete.dialog.content.normal',
            },
            {
              email: targetUser?.email,
              workspaceName: <b>{workspace.workspaceName}</b>,
            },
          )}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions css={dialogActionsStyle}>
        <Button onClick={handleDialogClose} css={noButtonStyle}>
          {intl.formatMessage({
            id: 'common.buttons.no',
          })}
        </Button>
        <Button
          data-testid="SubmitButton"
          onClick={handleSubmit}
          css={yesButtonStyle}
        >
          {intl.formatMessage({
            id: 'pages.OtherSetting.users.management.delete.dialog.button.yes',
          })}
        </Button>
      </DialogActions>
    </UserDeleteDialog>
  );
};

export default Dialog;
