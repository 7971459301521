import { createSvgIcon } from '@mui/material';
import React from 'react';

const CancelIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.966 15.651C14.4313 16.1163 15.1857 16.1163 15.651 15.651C16.1163 15.1857 16.1163 14.4313 15.651 13.966L9.68502 8L15.651 2.034C16.1163 1.56869 16.1163 0.814285 15.651 0.34898C15.1857 -0.116326 14.4313 -0.116326 13.966 0.34898L8 6.31498L2.034 0.348979C1.56869 -0.116326 0.814285 -0.116326 0.348979 0.348979C-0.116326 0.814285 -0.116326 1.56869 0.348979 2.034L6.31498 8L0.348979 13.966C-0.116326 14.4313 -0.116326 15.1857 0.348979 15.651C0.814285 16.1163 1.56869 16.1163 2.034 15.651L8 9.68502L13.966 15.651Z"
  />,
  'CancelIcon',
);

export default CancelIcon;
