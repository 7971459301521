import React from 'react';
import {
  newResultSelector,
  showResultSelector,
} from '../features/registrations/slice';
import { useSelector } from '../store';
import ErrorHandler, { ErrorHandlerProps } from './ErrorHandler';

const SetupErrorHandler: React.FC<Omit<ErrorHandlerProps, 'error'>> = ({
  redirectTo,
  children,
}) => {
  const error = [
    useSelector(newResultSelector),
    useSelector(showResultSelector),
  ].find((result) => !!result.error)?.error;

  return (
    <ErrorHandler error={error} redirectTo={redirectTo}>
      {children}
    </ErrorHandler>
  );
};

export default SetupErrorHandler;
