import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import AxiosUtils, { AxiosError } from 'axios';
import axios from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type DeleteUsersMeErrorCode =
  | 'unknown_error'
  | 'account_is_not_deletable'
  | 'emergency_maintenance';
export type DeletableCheckErrorCode = 'emergency_maintenance' | 'unknown_error';

type WorkspaceReasonCode =
  | 'YOU_ONLY_ADMIN_USER_AND_EXIST_OTHER_USER'
  | 'YOU_ADMIN_USER_BUT_NOT_EXIST_OTHER_USER_AND_EXIST_DEVICE'
  | 'YOU_ADMIN_USER_BUT_NOT_EXIST_OTHER_USER_AND_NOT_EXIST_DEVICE';

type DeviceReasonCode = 'EXIST_AGREEMENT_DEVICE';

type Reason<T extends string> = {
  name: string;
  reasonCode: T[];
  deletable: boolean;
};

export type DeletableCheck = {
  deletable: boolean;
  workspaces: Reason<WorkspaceReasonCode>[];
  devices: Reason<DeviceReasonCode>[];
};

export interface DeletableCheckParams {
  token: Promise<string>;
}

export const deleteUsersMe = createAsyncThunk<
  void,
  DeletableCheckParams,
  {
    state: RootState;
    rejectValue: RootError<DeleteUsersMeErrorCode>;
  }
>('users/deleteMe', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    await axios.delete<void>(`${API_ENDPOINT}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    if (!AxiosUtils.isAxiosError(error)) {
      throw error;
    }
    const meError = getDeleteUsersMeError(error);
    return thunkAPI.rejectWithValue(meError);
  }
});

const getDeleteUsersMeError = (
  error: AxiosError<RootErrorResponse>,
): RootError<DeleteUsersMeErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 400:
        return {
          code: 'account_is_not_deletable',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              // ここはtrueだがErrorHandlerコンポーネントに頼らずに、
              // DeleteAccount.tsxで処理しているため、動作不良はない。
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          // ここはtrueだがErrorHandlerコンポーネントに頼らずに、
          // DeleteAccount.tsxで処理しているため、動作不良はない。
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: false,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: false,
  };
};

export interface DeletableCheckParams {
  token: Promise<string>;
}

export const deletableCheck = createAsyncThunk<
  DeletableCheck,
  DeletableCheckParams,
  {
    state: RootState;
    rejectValue: RootError<DeletableCheckErrorCode>;
  }
>('users/me/getDeletableCheck', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const response = await axios.get<DeletableCheck>(
      `${API_ENDPOINT}/users/me/deletable-check`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    if (!AxiosUtils.isAxiosError(error)) {
      throw error;
    }
    const meError = getDeletableCheckError(error);
    return thunkAPI.rejectWithValue(meError);
  }
});

const getDeletableCheckError = (
  error: AxiosError<RootErrorResponse>,
): RootError<DeletableCheckErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              // ここはtrueだがErrorHandlerコンポーネントに頼らずに、
              // DeleteAccount.tsxで処理しているため、動作不良はない。
              recoverable: true,
            };
        }
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: false,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: false,
  };
};

interface DeleteAccountState {
  deleteMeResult: Result<void, RootError<DeleteUsersMeErrorCode>>;
  deletableCheckResult: Result<
    DeletableCheck,
    RootError<DeletableCheckErrorCode>
  >;
}

const initialState: DeleteAccountState = {
  deleteMeResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  deletableCheckResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

const deleteAccountSlice = createSlice({
  name: 'delete-account',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUsersMe.pending, (state) => {
        state.deleteMeResult.data = null;
        state.deleteMeResult.status = 'loading';
        state.deleteMeResult.error = null;
      })
      .addCase(deleteUsersMe.fulfilled, (state, action) => {
        state.deleteMeResult.data = action.payload;
        state.deleteMeResult.status = 'succeeded';
        state.deleteMeResult.error = null;
      })
      .addCase(deleteUsersMe.rejected, (state, action) => {
        state.deleteMeResult.data = null;
        state.deleteMeResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.deleteMeResult.error = action.payload!;
      })
      .addCase(deletableCheck.pending, (state) => {
        state.deletableCheckResult.data = null;
        state.deletableCheckResult.status = 'loading';
        state.deletableCheckResult.error = null;
      })
      .addCase(deletableCheck.fulfilled, (state, action) => {
        state.deletableCheckResult.data = action.payload;
        state.deletableCheckResult.status = 'succeeded';
        state.deletableCheckResult.error = null;
      })
      .addCase(deletableCheck.rejected, (state, action) => {
        state.deletableCheckResult.data = null;
        state.deletableCheckResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.deletableCheckResult.error = action.payload!;
      });
  },
});

const deleteAccountSelector = createSelector(
  (state: RootState) => state.deleteAccount,
  (state: DeleteAccountState) => state.deleteMeResult,
);

export const deleteAccountErrorCodeSelector = createSelector(
  deleteAccountSelector,
  (state) => state.error?.code,
);

const deletableCheckResultSelector = createSelector(
  (state: RootState) => state.deleteAccount,
  (state: DeleteAccountState) => state.deletableCheckResult,
);

export const deletableCheckErrorCodeSelector = createSelector(
  deletableCheckResultSelector,
  (result) => result.error?.code,
);

export const deletableCheckLoadingSelector = createSelector(
  deletableCheckResultSelector,
  (result) => result.status === 'loading',
);

export const deletableCheckWorkspaceReasonsSelector = createSelector(
  deletableCheckResultSelector,
  (result) => result.data?.workspaces,
);

export const dialogStatusSelector = createSelector(
  deletableCheckResultSelector,
  (
    result,
  ):
    | 'unknown'
    | 'not_deletable'
    | 'deletable'
    | 'deletable_with_workspace_deletion' => {
    const deletableCheck = result.data;
    if (!deletableCheck) {
      return 'unknown';
    }
    const { deletable, workspaces } = deletableCheck;
    if (!deletable) {
      return 'not_deletable';
    }
    if (workspaces?.find((workspace) => workspace.deletable)) {
      return 'deletable_with_workspace_deletion';
    }
    return 'deletable';
  },
);

export default deleteAccountSlice.reducer;

export const { reset } = deleteAccountSlice.actions;
