import { useState, useEffect } from 'react';
import { AlertHistory } from 'src/domains/root/features/alerts/histories/slice';

interface Selected {
  gatewayName: string;
  sensorUnitName: string;
  alertType: string;
  event: string;
}

const useAlertHistoriesCount = (
  alertHistories: AlertHistory[],
  selected: Selected,
): number => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const filteredCount = alertHistories.filter(
      (alertHistory: AlertHistory) => {
        if (
          selected.gatewayName !== 'all' &&
          selected.gatewayName !== alertHistory.gatewayName
        ) {
          return false;
        } else if (
          selected.sensorUnitName !== 'all' &&
          selected.sensorUnitName !== alertHistory.sensorUnitName
        ) {
          return false;
        } else if (
          selected.alertType !== 'all' &&
          selected.alertType !== alertHistory.alertType
        ) {
          return false;
        } else if (
          selected.event !== 'all' &&
          selected.event !== alertHistory.eventType
        ) {
          return false;
        } else {
          return true;
        }
      },
    ).length;
    setCount(filteredCount);
  }, [alertHistories, selected]);

  return count;
};

export default useAlertHistoriesCount;
