import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';

interface Values {
  email: string;
}

interface EmailFieldProps {
  label: string;
}

const EmailField: React.FC<EmailFieldProps> = (props) => {
  const { label } = props;
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, email: true });
  };

  return (
    <TextField
      data-testid="EmailInput"
      name="email"
      type="email"
      value={values.email}
      label={label}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={touched.email && Boolean(errors.email)}
      helperText={touched.email && errors.email}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      placeholder="user@example.com"
    />
  );
};

export default EmailField;
