import React from 'react';
import { useIntl } from 'react-intl';
import UnrecoverableError from './UnrecoverableError';

const RepeatedTokenExpirationError: React.FC = () => {
  const intl = useIntl();

  return (
    <UnrecoverableError
      message={intl.formatMessage(
        { id: 'common.errors.repeated_token_expiration' },
        { br: <br /> },
      )}
      buttonTextId="common.buttons.reload"
    />
  );
};

export default RepeatedTokenExpirationError;
