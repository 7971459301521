import React from 'react';
import useAxiosInterceptor from 'src/utils/api/use-axios-interceptor';
import Routes from './Routes';
import createProviders from './Providers';

const Providers = createProviders();

const App: React.FC = () => {
  useAxiosInterceptor();

  return (
    <Providers>
      <Routes />
    </Providers>
  );
};

export default App;
