import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Alert } from '@mui/material';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import ContractExpiredAt from 'src/domains/root/features/contracts/ContractExpiredAt';
import { awsRum } from 'src/utils/rum';
import { Workspace } from '../workspaces/slice';

interface CreatedProps {
  workspace: Workspace;
  /**
   * 契約終了時点のtimestamp(ms)
   * この値が 2024/01/02T00:00:00(+09:00) のtimestampであるとき、
   * 画面には「2024/1/1まで」と表示する。（つまり表示の際に-1日する）
   */
  contractExpiredAt: number | undefined;
}

const Created: React.FC<CreatedProps> = (props) => {
  const { workspace, contractExpiredAt } = props;

  const intl = useIntl();

  if (!contractExpiredAt)
    awsRum().then((rum) => rum.recordError('contractExpiredAt is undefined')); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。

  return (
    <Box mt={2.5}>
      <Box>
        <Alert
          sx={(theme) => ({
            wordBreak: 'break-all',
            fontSize: theme.typography.pxToRem(15),
            lineHeight: 1.43,
            letterSpacing: 0.16065,
          })}
        >
          {intl.formatMessage(
            {
              id: 'pages.Setup.Gateway.created.alert.success.registered',
            },
            { name: workspace.name },
          )}
          <br />
          {contractExpiredAt
            ? intl.formatMessage(
                {
                  id: 'features.workspaces.packages.new.completed.adminUser.expiredAt.message',
                },
                {
                  date: (
                    <ContractExpiredAt
                      expiredAt={contractExpiredAt}
                      formatType="japanese"
                    />
                  ),
                },
              )
            : ''}
        </Alert>
      </Box>
      <Box display="flex" justifyContent="center" mt={6} flex={1}>
        <PrimaryButton
          onClick={() => (window.location.href = '/')}
          sx={{
            width: 420,
            height: 44,
            fontSize: (theme) => theme.typography.pxToRem(18),
          }}
        >
          {intl.formatMessage({
            id: 'pages.Setup.Gateway.created.button.finish',
          })}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default Created;
