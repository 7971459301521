import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { Form, FormikProvider, useFormik } from 'formik';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import ProgressableSubmitButton from 'src/domains/root/commons/dialog/actions/ProgressableSubmitButton';
import { AppDispatch } from 'src/domains/root/store';
import { useDispatch } from 'react-redux';
import { putSensorUnitObserveMode } from 'src/domains/root/features/sensor-units/observe/slice';
import { useAuth0 } from '@auth0/auth0-react';
import { updateSensorUnitObserves } from 'src/domains/root/features/gateways/slice';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import GatewayName from 'src/domains/root/commons/settings/dialog/GatewayName';
import SensorUnitName from 'src/domains/root/commons/settings/dialog/SensorUnitName';
import Description from 'src/domains/root/commons/settings/dialog/Description';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Title from 'src/domains/root/commons/dialog/Title';
import ErrorAlert from 'src/domains/root/commons/dialog/alerts/ErrorAlert';
import SuccessAlert from 'src/domains/root/commons/dialog/alerts/SuccessAlert';
import DialogTitle from '@mui/material/DialogTitle';
import AccessControl from 'src/domains/root/commons/AccessControl';
import { ObserveMode } from 'src/domains/root/utils/highcharts/chart-utils';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import GraphItemList from './GraphItemList';
import ModeList from './ModeList';

interface EditModalProps {
  gatewayName: string;
  gatewayId: string;
  sensorUnitName: string;
  sensorUnitId: string;
  observeMode: ObserveMode;
  setOpen: (open: boolean) => void;
}

const EditModal: React.FC<EditModalProps> = (props) => {
  const {
    gatewayName,
    gatewayId,
    sensorUnitName,
    sensorUnitId,
    observeMode,
    setOpen,
  } = props;

  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const [savedObserveMode, setSavedObserveMode] = useState(observeMode);
  const intl = useIntl();

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const formik = useFormik({
    initialValues: { mode: savedObserveMode },
    onSubmit: (values) => {
      setComplete(false);
      setError(false);
      const token = getAccessTokenSilently();
      const promise = dispatch(
        putSensorUnitObserveMode({
          token,
          sensorUnitId,
          observeMode: values.mode,
        }),
      );

      promise
        .unwrap()
        .then(() => {
          dispatch(
            updateSensorUnitObserves({
              gateway: { deviceId: gatewayId },
              sensorUnits: [
                {
                  deviceId: sensorUnitId,
                  observe: values,
                },
              ],
            }),
          );
          setSavedObserveMode(values.mode);
          setComplete(true);
        })
        .catch((error) => {
          setError(true);
          awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        })
        .finally(() => {
          formik.resetForm();
        });

      return promise;
    },
    enableReinitialize: true,
  });

  const handleClose = () => {
    if (setOpen) setOpen(false);
    setComplete(false);
    setError(false);
    formik.resetForm();
  };

  const location = useLocation();
  useEffect(() => {
    awsRum().then((rum) => {
      rum.recordPageView(location.pathname + '#GatewaySettingsEditModalRender');
    });
  }, [location]);

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        aria-labelledby="mode-setting-dialog"
      >
        <FormikProvider value={formik}>
          <DialogTitle id="mode-setting-dialog-title">
            <Title onClose={handleClose}>
              {intl.formatMessage({
                id: 'modeSettingModal.dialogTitle',
              })}
            </Title>
            {error && (
              <Box mt={2.5} mb={0.5}>
                <ErrorAlert />
              </Box>
            )}
            {complete && (
              <Box mt={2.5} mb={0.5}>
                <SuccessAlert />
              </Box>
            )}
          </DialogTitle>
          <AccessControl
            permit={(permissions) =>
              permissions.includes('cabiotpack.observe.update')
            }
          >
            {(permitted) => (
              <>
                <DialogContent>
                  <GatewayName name={gatewayName} />
                  <Box mt={1.5} mx={1.5}>
                    <SensorUnitName
                      name={sensorUnitName}
                      observeMode={savedObserveMode}
                    />
                    <Box my={2.5}>
                      <Description>
                        {intl.formatMessage({
                          id: 'modeSettingModal.description',
                        })}

                        <AccessControl
                          permit={(permissions) =>
                            !permissions.includes('cabiotpack.observe.update')
                          }
                        >
                          <br />
                          {intl.formatMessage({
                            id: 'modeSettingModal.descriptionForNotAdmin',
                          })}
                        </AccessControl>
                      </Description>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flexWrap="wrap"
                      mt={3.5}
                      mb={2}
                    >
                      <Box py={2}>
                        <ModeList disabled={!permitted} />
                      </Box>
                      <Box
                        border={1}
                        borderRadius={1}
                        borderColor="#d2d2d2"
                        py={2}
                        px={3}
                      >
                        <Box display="flex" flexDirection="column">
                          <GraphItemList />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </DialogContent>
                <Form>
                  <DialogActions>
                    <Box display="flex" gap={1.5}>
                      <CancelButton onClick={handleClose} />
                      {permitted && (
                        <ProgressableSubmitButton
                          loading={formik.isSubmitting}
                        />
                      )}
                    </Box>
                  </DialogActions>
                </Form>
              </>
            )}
          </AccessControl>
        </FormikProvider>
      </Dialog>
    </>
  );
};

export default EditModal;
