import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';

interface UserDataProps {
  email: string;
}

const UserData: React.FC<UserDataProps> = ({ email }) => {
  const intl = useIntl();

  return (
    <Box
      component="dl"
      borderRadius={1}
      bgcolor="#EBE6E1"
      px={2}
      py={1.5}
      display="flex"
      flexWrap="wrap"
      m={0}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          rowGap: theme.spacing(1),
        },
      })}
    >
      <Box component="dt" flexBasis="36%" display="flex">
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: 'features.users.accountSetting.edit.dialog.form.currentEmail',
          })}
        </Typography>
      </Box>
      <Box
        component="dd"
        flexBasis="64%"
        width="100%"
        m={0}
        sx={{ wordBreak: 'break-all' }}
        display="flex"
      >
        <Typography width="100%">{email}</Typography>
      </Box>
    </Box>
  );
};

export default UserData;
