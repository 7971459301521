import { default as _axios } from 'axios';

export type { AxiosError } from 'axios';

const axios = _axios.create({
  timeout: 60 * 1000, // 60秒
});

export default axios;
export const isAxiosError = _axios.isAxiosError;
