import React from 'react';
import { useIntl } from 'react-intl';
import { Box, useTheme, useMediaQuery, Typography, Alert } from '@mui/material';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import ContractExpiredAt from 'src/domains/root/features/contracts/ContractExpiredAt';
import { WorkspacesUser } from 'src/domains/root/types';
import { useNavigate } from 'react-router-dom';
import { Workspace } from '../slice';

interface OtherProps {
  workspace: Workspace;
  user: WorkspacesUser;
}

const Other: React.FC<OtherProps> = (props) => {
  const { workspace, user } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box mb={isSmallScreen ? 4 : 6}>
        <Alert>
          <Typography fontSize={14}>
            {intl.formatMessage(
              {
                id: 'features.workspaces.packages.new.completed.message',
              },
              {
                workspace: (
                  <b style={{ wordBreak: 'break-all' }}>{workspace.name}</b>
                ),
              },
            )}
            {intl.formatMessage(
              {
                id: 'features.workspaces.packages.new.completed.adminUser.expiredAt.message',
              },
              {
                date: (
                  <ContractExpiredAt
                    expiredAt={workspace.contractExpiredAt}
                    formatType="japanese"
                  />
                ),
              },
            )}
          </Typography>
          <Typography fontSize={14}>
            {intl.formatMessage(
              {
                id: 'features.workspaces.packages.new.completed.adminUser.other.message',
              },
              {
                workspace: (
                  <b style={{ wordBreak: 'break-all' }}>{workspace.name}</b>
                ),
                user: (
                  <span
                    style={{
                      wordBreak: 'break-all',
                      WebkitLineBreak: 'anywhere',
                    }}
                  >
                    {user.email}
                  </span>
                ),
              },
            )}
          </Typography>
        </Alert>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box width={392}>
          <SecondaryButton fullWidth size="large" onClick={() => navigate('/')}>
            {intl.formatMessage({
              id: 'features.workspaces.packages.new.completed.adminUser.other.button',
            })}
          </SecondaryButton>
        </Box>
      </Box>
    </>
  );
};

export default Other;
