import React from 'react';
import { ThemeProvider } from 'styled-components';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import flattenMessages from '../../utils/i18n';
import theme from './styles/theme';
import { createStore } from './store';
import ja from './translations/ja.json';
import StyledSnackbarProvider from './notistack/StyledSnackbarProvider';

const messages = {
  ja: flattenMessages(ja),
};

const createProviders = (
  store = createStore(),
): React.FC<{ children: React.ReactNode }> => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 0,
        gcTime: 0,
        networkMode: 'always',
      },
      mutations: {
        networkMode: 'always',
      },
    },
  });

  const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <IntlProvider locale="ja" messages={messages.ja}>
              <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                  <QueryErrorResetBoundary>
                    <StyledSnackbarProvider maxSnack={3}>
                      {children}
                    </StyledSnackbarProvider>
                  </QueryErrorResetBoundary>
                </QueryClientProvider>
              </Provider>
            </IntlProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    );
  };
  return Providers;
};

export default createProviders;
