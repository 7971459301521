import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';

interface Props {
  onClick: () => void;
}

const LocationSettingButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SecondaryButton
      data-testid="LocationSettingButton"
      onClick={onClick}
      sx={{
        border: isSmallScreen ? 'unset' : undefined,
        px: isSmallScreen ? 0 : undefined,
        minWidth: isSmallScreen ? '90px' : undefined,
      }}
      startIcon={isSmallScreen && <FmdGoodIcon />}
    >
      {intl.formatMessage({
        id: 'common.locationSetting',
      })}
    </SecondaryButton>
  );
};

export default LocationSettingButton;
