import type { Gateway } from 'src/domains/root/features/gateways/slice';
import axios, { AxiosError, isAxiosError } from 'src/utils/api';
import { awsRum } from 'src/utils/rum';
import type {
  SensorData,
  SensorDataUrlResponse,
  AlertCount,
  WeatherHistoriesResponse,
  SensorDataPartialResponse,
  BeginAndEndTimestampResponse,
  ObserveModeType,
  PostSensorUnitCSVResponse,
  TargetType,
  PostGatewayCSVResponse,
} from './type';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type Error =
  | { code: 'unknown_error' }
  | { code: 'permission_denied' }
  | { code: 'emergency_maintenance' }
  | { code: 'bad_request' }
  | { code: 'not_found' };

export async function getSensorData(
  sensorUnitId: string,
  token: string,
): Promise<SensorData> {
  return axios
    .get(`${API_ENDPOINT}/sensor-units/${sensorUnitId}/sensor-data`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { weekAgo: 12 },
    })
    .then((res) => res.data.sensorData ?? {})
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getSensorDataUrl(
  sensorUnitId: string,
  token: string,
): Promise<SensorDataUrlResponse> {
  return axios
    .get(`${API_ENDPOINT}/sensor-units/${sensorUnitId}/sensor-data-url`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getSensorDataPartial(
  sensorUnitId: string,
  includeRemovedData: boolean,
  begin: number,
  end: number,
  token: string,
): Promise<SensorDataPartialResponse> {
  return axios
    .get(
      `${API_ENDPOINT}/sensor-units/${sensorUnitId}/sensor-data-partial?includeRemovedData=${includeRemovedData}&begin=${begin}&end=${end}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getWeatherHistories(
  areaId: string,
  begin: number,
  end: number,
  token: string,
): Promise<WeatherHistoriesResponse> {
  return axios
    .get(
      `${API_ENDPOINT}/weather-histories/${areaId}?begin=${begin}&end=${end}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getAlertCount(
  alertCountUrl: string,
): Promise<AlertCount> {
  return axios(alertCountUrl)
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getFullSensorData(
  sensorDataUrl: string,
): Promise<SensorData> {
  return axios(sensorDataUrl)
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getBeginAndEndTimestamp(
  gatewayId: string | undefined,
  token: string,
): Promise<BeginAndEndTimestampResponse> {
  return axios
    .get(
      `${API_ENDPOINT}/gateways/${gatewayId}/sensor-data-begin-end-timestamp`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function postSensorUnitCsv(
  sensorUnitId: string,
  token: string,
  begin: number,
  end: number,
  mode: ObserveModeType,
): Promise<PostSensorUnitCSVResponse> {
  const requestBody = {
    begin,
    end,
    mode,
  };
  return axios
    .post(
      `${API_ENDPOINT}/sensor-units/${sensorUnitId}/sensor-data-csv`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function postGatewayCsv(
  gatewayId: string,
  begin: number,
  end: number,
  targetType: TargetType,
  token: string,
): Promise<PostGatewayCSVResponse> {
  const requestBody = {
    begin,
    end,
    targetType,
  };
  return axios
    .post(
      `${API_ENDPOINT}/gateways/${gatewayId}/sensor-data-csv`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function getSensorDataWithParallel(
  gateway: Gateway,
  token: string,
): Promise<SensorData[]> {
  return Promise.all(
    gateway.sensorUnits.map((su) => getSensorData(su.deviceId, token)),
  );
}

export function getSensorDataUrlWithParallel(
  gateway: Gateway,
  token: string,
): Promise<SensorDataUrlResponse[]> {
  return Promise.all(
    gateway.sensorUnits.map((su) => getSensorDataUrl(su.deviceId, token)),
  );
}

export function getFullSensorDataWithParallel(
  sensorDataUrlResponses: SensorDataUrlResponse[] | undefined,
): Promise<SensorData[]> {
  return Promise.all(
    sensorDataUrlResponses?.map(({ sensorDataUrl }) =>
      getFullSensorData(sensorDataUrl),
    ) ?? [],
  );
}

function handleError(error: unknown): Error | AxiosError {
  if (!isAxiosError(error)) {
    awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    return { code: 'unknown_error' };
  }
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 400:
        return { code: 'bad_request' };
      case 403:
        return { code: 'permission_denied' };
      case 404:
        return { code: 'not_found' };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return { code: 'emergency_maintenance' };
        }
    }
    return error;
  }
  awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
  return { code: 'unknown_error' };
}
