import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Keys } from 'src/domains/internal-support/react-query-keys-factory';

import * as API from 'src/apis';

export type SucceededResult = {
  status: 'succeeded';
  data: API.GetServiceProviderWorkspacesResponse;
};

export type Result =
  | { status: 'hasError' }
  | { status: 'loading' }
  | { status: 'noData' }
  | SucceededResult;

export default function useGetServiceProviderWorkspaces(): Result {
  const { getAccessTokenSilently } = useAuth0();

  const result = useQuery<API.GetServiceProviderWorkspacesResponse, API.Error>({
    queryKey: Keys.workspaces.all(),
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      return API.getServiceProviderWorkspaces({
        token,
      });
    },
  });

  useRedirectForError(result);

  if (result.isLoading) {
    return { status: 'loading' };
  }

  if (result.isError) {
    result.error;
    const error = result.error;
    switch (error.code) {
      default:
        return { status: 'hasError' };
    }
  }

  if (!result.data?.workspaces?.length) {
    return { status: 'noData' };
  }

  return {
    status: 'succeeded',
    data: result.data,
  };
}

function useRedirectForError(
  result: UseQueryResult<API.GetServiceProviderWorkspacesResponse, API.Error>,
) {
  const navigate = useNavigate();
  useEffect(() => {
    const error = result.error;
    if (!error) {
      return;
    }
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  }, [result.error, navigate]);
}
