import { useMemo } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { AppDispatch } from '../../../../../store';
import { postInvitationsUser } from '../../slice';
import useValidationSchema from './useValidationSchema';

interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useFormikInstance = () => {
  const dispatch: AppDispatch = useDispatch();

  const { passwordValidationSchema, passwordConfirmationValidationSchema } =
    useValidationSchema();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password: passwordValidationSchema,
        passwordConfirmation: passwordConfirmationValidationSchema,
      }),
    [passwordValidationSchema, passwordConfirmationValidationSchema],
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { password } = values;
      return dispatch(postInvitationsUser({ password }));
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  return formik;
};

export default useFormikInstance;
