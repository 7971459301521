import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import {
  gatewaySelector,
  updateGatewayAndSensorNames,
} from 'src/domains/root/features/gateways/slice';
import {
  statusSelector as putStatusSelector,
  putGatewayAndSensorUnitNames,
} from 'src/domains/root/features/gateway-and-sensor-names/slice';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import * as yup from 'yup';
import ProgressableSubmitButton from 'src/domains/root/commons/dialog/actions/ProgressableSubmitButton';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import ContentHeader from 'src/domains/root/commons/settings/dialog/ContentHeader';
import ValidationField from 'src/domains/root/commons/form/ValidationField';
import Title from 'src/domains/root/commons/dialog/Title';
import ErrorAlert from 'src/domains/root/commons/dialog/alerts/ErrorAlert';
import SuccessAlert from 'src/domains/root/commons/dialog/alerts/SuccessAlert';
import DialogTitle from '@mui/material/DialogTitle';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import SensorUnitNameField from 'src/domains/root/commons/fields/SensorUnitNameField';
import SensorUnitNameFields from 'src/domains/root/commons/fields/SensorUnitNameFields';
import GatewayNameField from 'src/domains/root/commons/fields/GatewayNameField';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';

interface Props {
  deviceId: string;
  onClose: () => void;
}

export interface GatewayAndSensorNamesValues {
  gateway: {
    deviceId: string;
    name: string;
  };
  sensorUnits: {
    deviceId: string;
    number: number;
    name: string;
  }[];
}

const RenameModal: React.FC<Props> = (props) => {
  const { deviceId, onClose } = props;
  const intl = useIntl();
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);

  const gateway = useSelector((state) => gatewaySelector(state, deviceId));
  const putStatus = useSelector(putStatusSelector);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const validationSchema = yup.object().shape({
    gateway: yup.object().shape({
      name: yup
        .string()
        .required(intl.formatMessage({ id: 'validation.mixed.required' }))
        .max(200, intl.formatMessage({ id: 'validation.string.max' })),
    }),
    sensorUnits: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(intl.formatMessage({ id: 'validation.mixed.required' }))
          .max(200, intl.formatMessage({ id: 'validation.string.max' })),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      gateway: {
        deviceId: gateway.deviceId,
        name: gateway.name,
      },
      sensorUnits: gateway.sensorUnits.map((sensorUnit) => ({
        deviceId: sensorUnit.deviceId,
        number: sensorUnit.number,
        name: sensorUnit.name,
      })),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setComplete(false);
      setError(false);
      const token = getAccessTokenSilently();
      dispatch(putGatewayAndSensorUnitNames({ token, ...values }))
        .unwrap()
        .then(() => {
          dispatch(updateGatewayAndSensorNames(values));
          setComplete(true);
        })
        .catch((error) => {
          setError(true);
          awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        })
        .finally(() => {
          formik.resetForm();
        });
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleClose = () => {
    setComplete(false);
    setError(false);
    formik.resetForm();
    onClose();
  };

  const location = useLocation();
  useEffect(() => {
    awsRum().then((rum) => {
      rum.recordPageView(
        location.pathname + '#GatewaySettingsRenameModalRender',
      );
    });
  }, [location]);

  return (
    <Dialog
      open={true}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="gateway-name-setting-dialog-title"
    >
      <FormikProvider value={formik}>
        <DialogTitle id="gateway-name-setting-dialog-title">
          <Title onClose={handleClose}>
            {intl.formatMessage({
              id: 'nameSettingModal.dialogTitle',
            })}
          </Title>
          {error && (
            <Box mt={2.5} mb={0.5}>
              <ErrorAlert />
            </Box>
          )}
          {complete && (
            <Box mt={2.5} mb={0.5}>
              <SuccessAlert />
            </Box>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Box mb={3}>
            <ContentHeader>
              {intl.formatMessage({
                id: 'nameSettingModal.gateway',
              })}
            </ContentHeader>
          </Box>
          <ValidationField>
            <GatewayNameField />
          </ValidationField>
          <Box mb={3}>
            <ContentHeader>
              {intl.formatMessage({
                id: 'nameSettingModal.sensorUnits',
              })}
            </ContentHeader>
          </Box>
          <SensorUnitNameFields>
            {(index) => (
              <ValidationField key={index}>
                <SensorUnitNameField index={index} />
              </ValidationField>
            )}
          </SensorUnitNameFields>
        </DialogContent>
        <Form>
          <DialogActions>
            <Box display="flex" gap={1.5}>
              <CancelButton onClick={handleClose} />
              <ProgressableSubmitButton loading={putStatus === 'loading'} />
            </Box>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default RenameModal;
