import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { Result, RootState } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface AreaData {
  region: string;
  prefectures: {
    name: string;
    cities: {
      initial: string;
      areas: {
        areaId: string;
        pinpointName: string;
      }[];
    }[];
  }[];
}

export interface GetAreasParams {
  token: Promise<string>;
}

export type AreasErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

interface GetAreasResponse {
  areaData: AreaData[];
}

export const getAreas = createAsyncThunk<
  AreaData[],
  GetAreasParams,
  { state: RootState; rejectValue: RootError<AreasErrorCode> }
>('areas/getAreas', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const res = await axios.get<GetAreasResponse>(`${API_ENDPOINT}/areas`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.areaData;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const areasError = getAreasError(error);
    return thunkAPI.rejectWithValue(areasError);
  }
});

export const getAreasSilently = createAsyncThunk<
  AreaData[],
  GetAreasParams,
  { state: RootState }
>('areas/getAreasSilently', async (params) => {
  const token = await params.token;
  const res = await axios.get<GetAreasResponse>(`${API_ENDPOINT}/areas`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.areaData;
});

const getAreasError = (
  error: AxiosError<RootErrorResponse>,
): RootError<AreasErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface AreasState {
  result: Result<AreaData[], RootError<AreasErrorCode>>;
}

const initialState: AreasState = {
  result: {
    data: [],
    error: null,
    status: 'idle',
  },
};

export const areasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAreas.pending, (state) => {
        state.result.status = 'loading';
        state.result.error = null;
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.result.data = action.payload;
        state.result.error = null;
        state.result.status = 'succeeded';
      })
      .addCase(getAreas.rejected, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.result.error = action.payload!;
        state.result.status = 'failed';
      })
      .addCase(getAreasSilently.fulfilled, (state, action) => {
        state.result.data = action.payload;
      });
  },
});

export const resultSelector = createSelector(
  (state: RootState) => state.areas,
  (state: AreasState) => state.result,
);

export default areasSlice.reducer;
