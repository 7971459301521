import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useGateways from 'src/domains/root/features/gateways/useGateways';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import HookStatus from 'src/domains/root/commons/HookStatus';

/**
 * urlの一部にimeiが含まれているページで、imeiに一致するゲートウェイを取得するためのコンポーネント。
 */
export default function WithGateway({
  children,
}: {
  children: (gateway: Gateway) => React.ReactElement;
}) {
  const params = useParams();

  const findGateway = (gateways: Gateway[]) =>
    gateways.find((gateway) => gateway.physicalId === params.physicalId);

  const hasGateway = (gateways: Gateway[]) => !!findGateway(gateways);

  const { gateways, status } = useGateways({
    skipFetchCondition: hasGateway,
  });
  const gateway = findGateway(gateways);

  if (status === 'idle' || status === 'loading') {
    return (
      <HookStatus
        target="sensorDataChart"
        error={false}
        loading={status === 'loading'}
      />
    );
  }

  if (!gateway) {
    // imeiに一致するゲートウェイが見つからない場合はホームにリダイレクト
    return <Navigate to="/" />;
  }

  return children(gateway);
}
