import React from 'react';
import { useLocation } from 'react-router-dom';
import getAwsRum from './aws-rum-web';

export default function RumPageViewRecorder({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  React.useEffect(() => {
    getAwsRum().then((awsRum) => {
      awsRum.recordPageView(location.pathname);
    });
  }, [location]);

  return <>{children}</>;
}
