import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { WorkspacesUser } from 'src/domains/root/types';

interface UserSelectBoxProps {
  users: WorkspacesUser[];
}

export interface Values {
  adminUserId: string;
}

const UserSelectBox: React.FC<UserSelectBoxProps> = (props) => {
  const { users } = props;
  const intl = useIntl();

  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, adminUserId: true });
  };

  const errorMessage =
    (values.adminUserId || touched.adminUserId) && errors.adminUserId;

  return (
    <FormControl
      color="primary"
      error={Boolean(errorMessage)}
      fullWidth
      hiddenLabel={false}
      size="small"
      variant="outlined"
    >
      <InputLabel id="packages-admin-user-id-label">
        {intl.formatMessage({
          id: 'features.workspaces.packages.new.workspace.form.adminUserId.label',
        })}
      </InputLabel>
      <Select
        data-testid="AdminUserSelect"
        labelId="packages-admin-user-id-label"
        id="packages-admin-user-id"
        name="adminUserId"
        value={values.adminUserId}
        label={intl.formatMessage({
          id: 'features.workspaces.packages.new.workspace.form.adminUserId.label',
        })}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      >
        {users.map((user, index) => (
          <MenuItem
            data-testid={`AdminUserSelectMenuItem${index}`}
            key={user.userId}
            value={user.userId}
          >
            {user.email}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default UserSelectBox;
