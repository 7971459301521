import React, { useEffect } from 'react';
import { Auth0Provider, AppState, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { awsRum } from '../utils/rum';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN!;
const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID!;
const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE!;
/* eslint-enable */

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(
      {
        pathname: appState?.returnTo || window.location.pathname,
        search: appState?.search,
      },
      { replace: true },
    );
    // Auth0認証から帰ってきたことをRUMに記録する
    awsRum().then((rum) =>
      rum.recordPageView(
        location.pathname + '#AuthProvider.onRedirectCallback()',
      ),
    );
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
      }}
    >
      <UserSubObserver>{children}</UserSubObserver>
    </Auth0Provider>
  );
}

// libs

function UserSubObserver({ children }: { children: React.ReactNode }) {
  const { user } = useAuth0();

  useEffect(() => {
    const userSub = user?.sub;
    if (!userSub) return;

    awsRum().then((rum) => {
      rum.addSessionAttributes({ userSub });
    });
  }, [user?.sub]);

  return <>{children}</>;
}
