import React from 'react';
import { showResultSelector as registrationsShowResultSelector } from '../features/registrations/slice';
import { newResultSelector as registrationsUsersNewResultSelector } from '../features/registrations/users/slice';
import { useSelector } from '../store';
import ErrorHandler, { ErrorHandlerProps } from './ErrorHandler';

export default function AccountErrorHandler({
  redirectTo,
  children,
}: Omit<ErrorHandlerProps, 'error'>) {
  const error = [
    useSelector(registrationsShowResultSelector),
    useSelector(registrationsUsersNewResultSelector),
  ].find((result) => !!result.error)?.error;

  return (
    <ErrorHandler error={error} redirectTo={redirectTo}>
      {children}
    </ErrorHandler>
  );
}
