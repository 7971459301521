import { IntlShape } from 'react-intl';
import Highcharts from 'highcharts/highstock';
import type {
  SensorData,
  WeatherArea,
} from 'src/domains/root/features/gateways/sensor-data/type';
import {
  getTitleSubtitleOptions,
  getChartBaseOptions,
} from 'src/domains/root/utils/highcharts/base-options';
import {
  getGatewaySensorDataChartYAxisOptions,
  getYAxisOptions,
} from 'src/domains/root/utils/highcharts/y-axis-options';
import { getXAxisOptions } from 'src/domains/root/utils/highcharts/x-axis-options';
import {
  getSeriesOptions,
  WeatherHistoriesData,
} from 'src/domains/root/utils/highcharts/series-options';
import { getTooltipOptions } from 'src/domains/root/utils/highcharts/tooltip-options';
import { getNavigatorOptions } from 'src/domains/root/utils/highcharts/navigator-options';
import { getGatewaySeriesOptions } from 'src/domains/root/utils/highcharts/gateway-series-options';
import { CompareChartDataType } from 'src/domains/root/utils/highcharts/chart-utils';
import { Gateway, SensorUnit } from '../slice';

export type { WeatherHistoriesData } from 'src/domains/root/utils/highcharts/series-options';

export const createChartOptions = (props: {
  intl: IntlShape;
  gatewayName: string;
  sensorUnit: Pick<SensorUnit, 'name' | 'observe' | 'isAlive'>;
  sensorData: SensorData;
  weatherArea: WeatherArea;
  weatherHistories: WeatherHistoriesData;
  isFullData: boolean;
  isSmallScreen: boolean;
  graphRangeTouched: boolean;
  occurredAt?: Date;
  initialViewRange?: { start: Date; end: Date };
  touchGraphRange: () => void;
}): Highcharts.Options => {
  const {
    intl,
    gatewayName,
    sensorUnit,
    sensorData,
    weatherArea,
    weatherHistories,
    isFullData,
    isSmallScreen,
    graphRangeTouched,
    initialViewRange,
    occurredAt,
    touchGraphRange,
  } = props;

  return {
    ...getTitleSubtitleOptions(
      intl,
      sensorUnit.observe.mode,
      sensorUnit.isAlive,
      gatewayName,
      sensorUnit.name,
      weatherArea.displayAreaName,
      isSmallScreen,
    ),
    // 1は左上の期間選択ボタンのデフォルト１週間を意味する
    ...getChartBaseOptions({
      intl,
      chartType: 'sensorDataChart',
      isSmallScreen,
      graphRangeTouched,
      isFullData,
      initialViewRange,
    }),
    ...getTooltipOptions(intl),
    ...getYAxisOptions(intl, sensorUnit.observe.mode, isSmallScreen),
    ...getXAxisOptions({
      mode: sensorUnit.observe.mode,
      data: sensorData,
      occurredAt,
      touchGraphRange,
    }),
    ...getNavigatorOptions(isSmallScreen),
    ...getSeriesOptions(
      intl,
      sensorUnit.observe.mode,
      sensorData,
      weatherHistories,
      isSmallScreen,
    ),
  };
};

export const createChartOptionsForGateway = ({
  intl,
  sensorUnit,
  gateway,
  gatewaySensorData,
  graphRangeTouched,
  displayAreaName,
  type,
  isFullData,
  isSmallScreen,
  touchGraphRange,
}: {
  intl: IntlShape;
  sensorUnit: Pick<SensorUnit, 'observe' | 'isAlive'>;
  gateway: Gateway;
  gatewaySensorData: SensorData[];
  graphRangeTouched: boolean;
  displayAreaName: string;
  type: CompareChartDataType;
  isFullData: boolean;
  isSmallScreen: boolean;
  touchGraphRange: () => void;
}): Highcharts.Options => {
  return {
    ...getTitleSubtitleOptions(
      intl,
      sensorUnit.observe.mode,
      sensorUnit.isAlive,
      gateway.name,
      '',
      displayAreaName,
      isSmallScreen,
    ),
    ...getChartBaseOptions({
      intl,
      chartType: 'gatewaySensorDataChart',
      isSmallScreen,
      graphRangeTouched,
      isFullData,
    }),
    ...getTooltipOptions(intl, type),
    ...getGatewaySensorDataChartYAxisOptions(intl, type, isSmallScreen),
    ...getXAxisOptions({
      mode: sensorUnit.observe.mode,
      touchGraphRange,
    }),
    ...getNavigatorOptions(isSmallScreen),
    /**
     * ここのgatewaySensorDataは anyの配列が要求されている
     */
    ...getGatewaySeriesOptions(gateway, gatewaySensorData, type),
  };
};
