import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import type { AlertHistory as AlertHistoryType } from 'src/domains/root/features/alerts/histories/slice';
import type { Dropdown } from 'src/domains/root/hooks/use-alert-histories-dropdown';
import type {
  OptionState,
  UpdateOption,
} from 'src/domains/root/hooks/use-filter-option';
import { ClearButton, Selects, Table } from '.';

interface Props {
  gatewayNames: string[];
  sensorUnitNames: string[];
  gatewayDropdown: Dropdown;
  sensorUnitDropdown: Dropdown;
  alertHistories: AlertHistoryType[];
  option: OptionState;
  updateOption: UpdateOption;
  alertHistoriesCount: number;
}

const AlertHistory = ({
  gatewayNames,
  sensorUnitNames,
  gatewayDropdown,
  sensorUnitDropdown,
  alertHistories,
  option,
  updateOption,
  alertHistoriesCount,
}: Props) => {
  const intl = useIntl();

  const gatewayName = intl.formatMessage({
    id: 'common.gatewayName',
  });

  const sensorUnitName = intl.formatMessage({
    id: 'common.sensorUnitName',
  });

  const alertType = intl.formatMessage({
    id: 'alertHistoryTable.alertType',
  });

  const event = intl.formatMessage({
    id: 'alertHistoryTable.event',
  });

  return (
    <>
      <TopBox>
        <TitleBox option={option} updateOption={updateOption} />
        <Selects
          gatewayName={gatewayName}
          option={option}
          updateOption={updateOption}
          gatewayNames={gatewayNames}
          gatewayDropdown={gatewayDropdown}
          sensorUnitName={sensorUnitName}
          sensorUnitNames={sensorUnitNames}
          sensorUnitDropdown={sensorUnitDropdown}
          alertType={alertType}
          event={event}
        />
        <ResultCount alertHistoriesCount={alertHistoriesCount} />
      </TopBox>
      <Table
        alertHistories={alertHistories}
        option={option}
        alertHistoriesCount={alertHistoriesCount}
      />
    </>
  );
};

function TopBox({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          px: 2,
        },
      })}
    >
      {children}
    </Box>
  );
}

function TitleBox({
  option,
  updateOption,
}: {
  option: OptionState;
  updateOption: UpdateOption;
}) {
  const intl = useIntl();

  return (
    <Box display="flex" alignItems="center" mb={3} height={34}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          fontSize: theme.typography.pxToRem(18),
          height: 26,
          pl: 1.5,
          borderLeft: `5px solid ${theme.palette.primary.main}`,
        })}
      >
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: 'alertHistoryTable.conditions',
          })}
        </Typography>
      </Box>
      <ClearButton option={option} updateOption={updateOption} />
    </Box>
  );
}

function ResultCount({ alertHistoriesCount }: { alertHistoriesCount: number }) {
  const intl = useIntl();

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(18),
        height: 26,
        pl: 1.5,
        mb: 2.5,
        borderLeft: `5px solid ${theme.palette.primary.main}`,
      })}
    >
      <Typography fontWeight="bold">
        {intl.formatMessage({
          id: 'alertHistoryTable.results',
        })}
      </Typography>
      <Typography
        pl={1.5}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(14),
          },
        })}
      >
        {alertHistoriesCount}
        {intl.formatMessage({
          id: 'alertHistoryTable.counts',
        })}
      </Typography>
    </Box>
  );
}

export default AlertHistory;
