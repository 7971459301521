import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import CabiotLogo from '../../commons/CabiotLogo';
import MailIcon from './created/MailIcon';

interface CreatedProps {
  email: string;
}

const Created: React.FC<CreatedProps> = (props) => {
  const { email } = props;

  const intl = useIntl();

  return (
    <>
      <Box display="flex" justifyContent="center" mt={7.5}>
        <CabiotLogo />
      </Box>
      <Box mt={8.5} mb={7}>
        <Typography variant="h2" align="center">
          {intl.formatMessage({
            id: 'registrations.created.title',
          })}
        </Typography>
      </Box>
      <Box px={3}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={1.5}
          >
            <MailIcon />
          </Box>
          <Typography variant="subtitle1" align="center">
            {intl.formatMessage({
              id: 'registrations.created.subTitle',
            })}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="body2"
            sx={{ wordBreak: 'break-all' }}
            maxWidth={600}
          >
            {intl.formatMessage(
              {
                id: 'registrations.created.mailSent',
              },
              { email },
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Created;
