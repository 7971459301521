import { Box, ButtonProps } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { css } from 'styled-components/macro';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SecondaryButton from './Secondary';

const buttonStyle = css`
  &:hover {
    path {
      stroke: white;
    }
  }
`;

const QrReaderButton: React.FC<ButtonProps> = (props) => {
  const intl = useIntl();

  return (
    <Box>
      <SecondaryButton
        startIcon={<CameraAltIcon />}
        css={buttonStyle}
        {...props}
      >
        {intl.formatMessage({
          id: 'common.buttons.qrReader',
        })}
      </SecondaryButton>
    </Box>
  );
};

export default QrReaderButton;
