import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useMediaQuery,
  useTheme,
  Box,
  TableHead,
  TableContainer,
  TableRow,
  TableBody,
} from '@mui/material';
import { Gateway, SensorUnit } from 'src/domains/root/features/gateways/slice';
import AccessControl from 'src/domains/root/commons/AccessControl';
import SensorSettingCard from '../../sensor-data/SensorSettingCard';
import GatewayPaper from '../GatewayPaperV3';
import GatewayData from '../GatewayDataV3';
import GatewayName from '../GatewayNameV3';
import TableHeadCell from '../sensor-units/TableHeadCell';
import Table from '../sensor-units/Table';
import GatewaySettingTableRow from './GatewaySettingTableRow';
import AlarmSettingModal from './alarm/AlarmSettingModal';
import EditModal from './EditModal';
import LocationSettingButton from './LocationSettingButton';
import LocationSettingModal from './LocationSettingModal';
import RenameButton from './RenameButton';
import RenameModal from './RenameModal';

interface GatewaySettingTableProps {
  gateways: Gateway[];
}

const GatewaySettingTable: React.FC<GatewaySettingTableProps> = (props) => {
  const { gateways } = props;
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [alarmSettingModalData, setAlarmSettingModalData] = useState<{
    gateway: Gateway;
    sensorUnit: SensorUnit;
  } | null>(null);

  const hideAlarmSettingModal = () => setAlarmSettingModalData(null);

  const [editModalData, setEditModalData] = useState<{
    gateway: Gateway;
    sensorUnit: SensorUnit;
  } | null>(null);

  const hideEditModal = () => setEditModalData(null);

  const [deviceIdForRenameModal, setDeviceIdForRenameModal] = useState<
    string | null
  >(null);

  const hideRenameModal = () => setDeviceIdForRenameModal(null);

  const [deviceForGatewayModal, setDeviceForGatewayModal] = useState<Omit<
    Gateway,
    'sensorUnits'
  > | null>(null);

  const hideGatewayModal = () => setDeviceForGatewayModal(null);

  const gatewaySettingTable = gateways.map((gateway, index) => (
    <GatewayPaper data-testid={`GatewayPaper-${index}`} key={gateway.deviceId}>
      <GatewayName gateway={gateway} displayBattery={true} wrapChild>
        {!isSmallScreen && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            gap={1.5}
          >
            <AccessControl
              permit={(permissions) =>
                permissions.includes('cabiotpack.name.update')
              }
            >
              <RenameButton
                onClick={() => setDeviceIdForRenameModal(gateway.deviceId)}
              />
            </AccessControl>
            <AccessControl
              permit={(permissions) =>
                permissions.includes('cabiotpack.area.update')
              }
            >
              <LocationSettingButton
                onClick={() => setDeviceForGatewayModal(gateway)}
              />
            </AccessControl>
          </Box>
        )}
      </GatewayName>
      <Box
        p={(theme) => theme.spacing(3)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            pt: 2.5,
            px: 2,
            pb: 3.5,
          },
        })}
      >
        <Box
          mb={(theme) => theme.spacing(3.5)}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              mb: 2.5,
            },
          })}
        >
          <GatewayData
            removeLatestTimestamp={isSmallScreen}
            gateway={gateway}
          />
        </Box>
        {isSmallScreen ? (
          <Box display="flex" flexDirection="column" gap={1.5}>
            {gateway.sensorUnits.map((sensorUnit, index) => (
              <Box
                key={sensorUnit.deviceId}
                data-testid={`SensorSettingCard-${index}`}
              >
                <SensorSettingCard
                  gateway={gateway}
                  sensorUnit={sensorUnit}
                  setAlarmSettingModalData={setAlarmSettingModalData}
                  setEditModalData={setEditModalData}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: '60px', minWidth: '46px' }} />
                <col style={{ width: '50%' }} />
                <col style={{ minWidth: '80px' }} />
                <col style={{ minWidth: '80px' }} />
                <col style={{ minWidth: '80px' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewaySettingTable.sensorUnit.number',
                    })}
                  </TableHeadCell>
                  <TableHeadCell>
                    {intl.formatMessage({
                      id: 'common.sensorUnitName',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'common.status',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewaySettingTable.sensorUnit.alarm',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewaySettingTable.sensorUnit.modeChoice',
                    })}
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gateway.sensorUnits.map((sensorUnit, index) => (
                  <TableRow
                    data-testid={`GatewayTableRow-${index}`}
                    key={sensorUnit.deviceId}
                  >
                    <GatewaySettingTableRow
                      gateway={gateway}
                      sensorUnit={sensorUnit}
                      setAlarmSettingModalData={setAlarmSettingModalData}
                      setEditModalData={setEditModalData}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {isSmallScreen && (
        <Box
          gap={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mx: 2,
            mb: 2.5,
          }}
        >
          <AccessControl
            permit={(permissions) =>
              permissions.includes('cabiotpack.name.update')
            }
          >
            <RenameButton
              onClick={() => setDeviceIdForRenameModal(gateway.deviceId)}
            />
          </AccessControl>
          <AccessControl
            permit={(permissions) =>
              permissions.includes('cabiotpack.area.update')
            }
          >
            <LocationSettingButton
              onClick={() => setDeviceForGatewayModal(gateway)}
            />
          </AccessControl>
        </Box>
      )}
    </GatewayPaper>
  ));

  return (
    <>
      {gatewaySettingTable}
      {alarmSettingModalData && (
        <AccessControl
          permit={(permissions) =>
            permissions.includes('cabiotpack.alarm.update')
          }
        >
          {(permitted) => (
            <AlarmSettingModal
              permitted={permitted}
              setOpen={(open: boolean) => {
                if (!open) {
                  hideAlarmSettingModal();
                }
              }}
              sensorUnitId={alarmSettingModalData.sensorUnit.deviceId}
              observeMode={alarmSettingModalData.sensorUnit.observe.mode}
              gatewayName={alarmSettingModalData.gateway.name}
              sensorUnitName={alarmSettingModalData.sensorUnit.name}
              isAlarmEnabled={alarmSettingModalData.sensorUnit.isAlarmEnabled}
            />
          )}
        </AccessControl>
      )}
      {editModalData && (
        <EditModal
          setOpen={(open: boolean) => {
            if (!open) {
              hideEditModal();
            }
          }}
          sensorUnitId={editModalData.sensorUnit.deviceId}
          observeMode={editModalData.sensorUnit.observe.mode}
          gatewayName={editModalData.gateway.name}
          sensorUnitName={editModalData.sensorUnit.name}
          gatewayId={editModalData.gateway.deviceId}
        />
      )}
      {deviceIdForRenameModal && (
        <RenameModal
          onClose={hideRenameModal}
          deviceId={deviceIdForRenameModal}
        />
      )}
      {deviceForGatewayModal && (
        <LocationSettingModal
          onClose={hideGatewayModal}
          gateway={deviceForGatewayModal}
          modalOpen={true}
          toggleModalOpen={function (): boolean {
            return true;
          }}
        />
      )}
    </>
  );
};

export default GatewaySettingTable;
