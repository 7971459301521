import React from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from 'src/domains/root/commons/form/PrimaryFormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import BarChartIcon from '@mui/icons-material/BarChart';
import OpacityRoundedIcon from '@mui/icons-material/OpacityRounded';
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded';
import { Tooltip } from 'src/shared/ui';

type Props = {
  disabled: boolean;
};

const ModeList: React.FC<Props> = (props) => {
  const { disabled } = props;

  const { values, handleChange } = useFormikContext<{ mode: string }>();
  const intl = useIntl();

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="mode"
        name="mode"
        value={values.mode}
        onChange={handleChange}
      >
        <FormControlLabel
          value="temperatureHumidityObserve"
          control={<Radio color="primary" />}
          label={
            <Box display="flex" alignItems="center">
              <Tooltip
                title={intl.formatMessage({
                  id: 'modeSettingData.temperatureHumidityObserve',
                })}
                placement="top"
              >
                <BarChartIcon style={{ verticalAlign: 'middle' }} />
              </Tooltip>
              <Box component="span" pl={0.5}>
                {intl.formatMessage({
                  id: 'modeSettingData.temperatureHumidityObserve',
                })}
              </Box>
            </Box>
          }
          disabled={disabled}
        />
        <FormControlLabel
          value="condensationObserve"
          control={<Radio color="primary" />}
          label={
            <Box display="flex" alignItems="center">
              <Tooltip
                title={intl.formatMessage({
                  id: 'modeSettingData.condensationObserve',
                })}
                placement="top"
              >
                <OpacityRoundedIcon
                  style={{
                    color: disabled ? 'unset' : 'blue',
                    verticalAlign: 'middle',
                  }}
                />
              </Tooltip>
              <Box component="span" pl={0.5}>
                {intl.formatMessage({
                  id: 'modeSettingData.condensationObserve',
                })}
              </Box>
            </Box>
          }
          disabled={disabled}
        />
        <FormControlLabel
          value="connectorTemperatureObserve"
          control={<Radio color="primary" />}
          label={
            <Box display="flex" alignItems="center">
              <Tooltip
                title={intl.formatMessage({
                  id: 'modeSettingData.connectorTemperatureObserve',
                })}
                placement="top"
              >
                <WhatshotRoundedIcon
                  style={{
                    color: disabled ? 'unset' : 'red',
                    verticalAlign: 'middle',
                  }}
                />
              </Tooltip>
              <Box component="span" pl={0.5}>
                {intl.formatMessage({
                  id: 'modeSettingData.connectorTemperatureObserve',
                })}
              </Box>
            </Box>
          }
          disabled={disabled}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default ModeList;
