import { useEffect, useState } from 'react';
import * as API from 'src/apis';

type Image = API.MemoImage & { file: File };

export default function useThumbnail(img: Image) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();

  useEffect(() => {
    let reader: FileReader | undefined;

    if (img.type === 'new') {
      reader = new FileReader();
      reader.onload = (e) => {
        const url = e.target?.result as string;
        setThumbnailUrl(url);
      };
      reader.readAsDataURL(img.file);
      return;
    }

    /**
     * @TODO: thubnailUrlの対応が終わった時にimg.urlをimg.thumbnailUrlに変更する
     */
    setThumbnailUrl(img.url);

    return () => {
      reader?.abort();
    };
  }, [img]);

  return thumbnailUrl;
}
