import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import { Box, Alert, Typography } from '@mui/material';
import { useSelector, AppDispatch } from 'src/domains/root/store';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import QrReaderButton from 'src/domains/root/commons/buttons/QrReader';
import ContractExpiredAt from 'src/domains/root/features/contracts/ContractExpiredAt';
import { awsRum } from 'src/utils/rum';
import {
  statusSelector,
  errorSelector,
  resetStatus,
  resetError,
  createResultSelector,
} from './slice';
import Form from './new/Form';
import ImageBox from './new/ImageBox';

const New: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();

  const status = useSelector(statusSelector);
  const error = useSelector(errorSelector);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
      dispatch(resetError());
    };
  }, [dispatch]);

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  const { data } = useSelector(createResultSelector);

  if (status === 'succeeded' && !data?.contractExpiredAt)
    awsRum().then((rum) => rum.recordError('contractExpiredAt is undefined')); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。

  return (
    <>
      <LoadingOverlay open={status === 'loading'} />
      {status === 'failed' && (
        <Box mb={3.5}>
          <Alert severity="error">
            {intl.formatMessage({
              id: `pages.OtherSetting.gateways.new.alert.error.${error?.code}`,
            })}
          </Alert>
        </Box>
      )}
      {status === 'succeeded' && (
        <Box mb={3.5}>
          <Alert sx={{ wordBreak: 'break-word' }}>
            {intl.formatMessage(
              {
                id: 'pages.OtherSetting.gateways.new.alert.complete.title',
              },
              {
                workspaceName: workspace.workspaceName,
              },
            )}
            <br />
            {data?.contractExpiredAt
              ? intl.formatMessage(
                  {
                    id: 'features.workspaces.packages.new.completed.adminUser.expiredAt.message',
                  },
                  {
                    date: (
                      <ContractExpiredAt
                        expiredAt={data.contractExpiredAt}
                        formatType="japanese"
                      />
                    ),
                  },
                )
              : ''}
          </Alert>
        </Box>
      )}
      <Box mb={5} sx={{ wordBreak: 'break-all' }}>
        <Typography>
          {intl.formatMessage(
            {
              id: 'pages.OtherSetting.gateways.new.description',
            },
            {
              workspaceName: <b>{workspace.workspaceName}</b>,
            },
          )}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.new.inputDescription',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.new.qrCodeDescription',
          })}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        gap={6.5}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Box flexGrow={2} maxWidth={468}>
          <Box display="flex" flexDirection="column" mt={1} gap={3.5}>
            <QrReaderButton onClick={handleDialogOpen} />
            <Form dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
          </Box>
        </Box>
        <Box
          flexGrow={1}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              maxWidth: 304,
            },
          })}
        >
          <ImageBox />
        </Box>
      </Box>
    </>
  );
};

export default New;
