import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/domains/root/store';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { awsRum } from 'src/utils/rum';

interface Props {
  gatewayNewDialogOpen: boolean;
  handleGatewayNewDialogClose: () => void;
}

const _Dialog = (props: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { gatewayNewDialogOpen, handleGatewayNewDialogClose } = props;

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  const location = useLocation();
  useEffect(() => {
    if (gatewayNewDialogOpen) {
      awsRum().then((rum) => {
        rum.recordPageView(location.pathname + '#GatewayNewDialogRender');
      });
    }
  }, [location, gatewayNewDialogOpen]);

  return (
    <Dialog
      open={gatewayNewDialogOpen}
      onClose={handleGatewayNewDialogClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
      aria-labelledby="mode-setting-dialog"
    >
      <DialogTitle id="mode-setting-dialog-title">
        <Title onClose={handleGatewayNewDialogClose}>
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.new.dialog.title',
          })}
        </Title>
      </DialogTitle>
      <DialogContent>
        <Box pt={0.5}>
          <Typography>
            {intl.formatMessage(
              {
                id: 'pages.OtherSetting.gateways.new.dialog.current.description',
              },
              {
                workspaceName: <b>{workspace.workspaceName}</b>,
              },
            )}
          </Typography>
        </Box>
        <Box mt={2.5} mx="auto" maxWidth={320}>
          <SecondaryButton fullWidth onClick={() => navigate('gateways/new')}>
            {intl.formatMessage({
              id: 'pages.OtherSetting.gateways.new.dialog.current.button',
            })}
          </SecondaryButton>
        </Box>
        <Box py={5}>
          <Divider
            sx={{
              px: 3,
              '&::before, &::after': {
                borderColor: '#828282',
              },
            }}
          >
            <Typography
              fontSize={12}
              sx={{
                color: '#828282',
              }}
            >
              {intl.formatMessage({
                id: 'pages.OtherSetting.gateways.new.dialog.divider',
              })}
            </Typography>
          </Divider>
        </Box>
        <Typography>
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.new.dialog.new.description',
          })}
        </Typography>
        <Box mt={2.5} mb={2} mx="auto" maxWidth={320}>
          <SecondaryButton fullWidth onClick={() => navigate('packages/new')}>
            {intl.formatMessage({
              id: 'pages.OtherSetting.gateways.new.dialog.new.button',
            })}
          </SecondaryButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" gap={1.5}>
          <CancelButton onClick={handleGatewayNewDialogClose} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default _Dialog;
