import React from 'react';

const DrawerLeftButtonIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="18"
        cy="18"
        r="16"
        fill="#F05064"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M12.75 18.433C12.4167 18.2406 12.4167 17.7594 12.75 17.567L20.25 13.2369C20.5833 13.0444 21 13.285 21 13.6699L21 22.3301C21 22.715 20.5833 22.9556 20.25 22.7631L12.75 18.433Z"
        fill="white"
      />
    </svg>
  );
};

export default DrawerLeftButtonIcon;
