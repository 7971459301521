import React, { useState } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/domains/root/store';
import { meResultSelector } from 'src/domains/root/features/users/slice';
import { Text } from 'src/shared/ui';
import { NotificationsIcon } from 'src/domains/root/commons/icons';
import NotificationSettingsDialog from 'src/domains/root/features/users/notification-settings/Dialog';
import { usePutUsersMeNotificationSettings } from 'src/domains/root/features/users/notification-settings/usePutUsersMeNotificationSettings';

export default function NotificationSettingsMenuItem() {
  const intl = useIntl();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { data: me } = useSelector(meResultSelector);

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const notificationSettingsMutation = usePutUsersMeNotificationSettings();

  return (
    <>
      <MenuItem onClick={handleOpen} data-testid="NotificationSettingsMenuItem">
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText>
          <Text fontSize={{ xs: 16 }}>
            {intl.formatMessage({
              id: 'globalHeader.menu.notificationSettings.title',
            })}
          </Text>
        </ListItemText>
      </MenuItem>
      {me && dialogOpen && (
        <NotificationSettingsDialog
          me={me}
          open={dialogOpen}
          notificationSettingsMutation={notificationSettingsMutation}
          onClose={handleClose}
        />
      )}
    </>
  );
}
