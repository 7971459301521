import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getInvitation, errorSelector } from '../features/invitations/slice';
import { AppDispatch, useSelector } from '../store';
import { InvitationErrorCode } from '../utils/invitations/api';
import RecoverableError from '../pages/errors/RecoverableError';
import { invitationSelector, Invitation } from '../features/invitations/slice';

const FALLBACK_PATH = '/';

interface InvitationRequiredProps {
  redirectTo: {
    [key in InvitationErrorCode]?: string | null;
  };
  children: (invitation: Invitation) => React.ReactNode;
}

const InvitationRequired: React.FC<InvitationRequiredProps> = ({
  redirectTo,
  children,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const invitation = useSelector(invitationSelector);
  const error = useSelector(errorSelector);
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const invitationId = query.get('invitation_id') ?? '';
    const promise = dispatch(getInvitation({ invitationId }));
    return () => promise.abort();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    if (error.recoverable) {
      return <RecoverableError messageId="invitations.error.unknown_error" />;
    } else {
      const path = redirectTo[error.code] ?? FALLBACK_PATH;
      return <Navigate to={path} />;
    }
  }

  return <>{invitation ? <>{children(invitation)}</> : null}</>;
};

export default InvitationRequired;
