import React from 'react';
import Backdrop, { BackdropProps } from '@mui/material/Backdrop';
import { css } from 'styled-components/macro';
import CircularProgress from '@mui/material/CircularProgress';

const backdropStyle = css`
  color: ${(props) => props.theme.palette.common.white};
  z-index: ${(props) => props.theme.zIndex.modal + 1};
`;

const LoadingOverlay: React.FC<BackdropProps> = (props) => {
  return (
    <Backdrop css={backdropStyle} {...props}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingOverlay;
