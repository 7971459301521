import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AlarmThreshold } from 'src/apis';
import { API_ENDPOINT } from 'src/constants';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';

/**
 * @Deprecated `src/apis` のタイプを使ってください。
 * */
export type SensorUnitsAlarmThresholds = AlarmThreshold;

export interface GetSensorUnitsAlarmThresholdsParams {
  token: Promise<string>;
  sensorUnitId: string;
}

export type SensorUnitsAlarmThresholdsErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getSensorUnitsAlarmThresholds = createAsyncThunk<
  SensorUnitsAlarmThresholds,
  GetSensorUnitsAlarmThresholdsParams,
  {
    state: RootState;
    rejectValue: RootError<SensorUnitsAlarmThresholdsErrorCode>;
  }
>(
  'sensorUnitsAlarmThresholds/getSensorUnitsAlarmThresholds',
  async (params, thunkAPI) => {
    const token = await params.token;
    const { sensorUnitId } = params;
    try {
      const res = await axios.get<SensorUnitsAlarmThresholds>(
        `${API_ENDPOINT}/sensor-units/${sensorUnitId}/alarm-threshold`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }
      const sensorUnitsAlarmThresholdsError =
        getSensorUnitsAlarmThresholdsError(error);
      return thunkAPI.rejectWithValue(sensorUnitsAlarmThresholdsError);
    }
  },
);

export interface PutsensorUnitsAlarmThresholdsParams {
  token: Promise<string>;
  sensorUnitId: string;
  alarmThresholds: SensorUnitsAlarmThresholds;
}

export interface SensorUnitsAlarmThresholdsUpdateResult {
  gatewayId: string;
  sensorUnitId: string;
  isAlarmEnabled: boolean;
}

export const putSensorUnitsAlarmThresholds = createAsyncThunk<
  SensorUnitsAlarmThresholdsUpdateResult,
  PutsensorUnitsAlarmThresholdsParams,
  {
    state: RootState;
    rejectValue: RootError<SensorUnitsAlarmThresholdsErrorCode>;
  }
>(
  'sensorUnitsAlarmThresholds/putSensorUnitsAlarmThresholds',
  async (params, thunkAPI) => {
    const { sensorUnitId, alarmThresholds } = params;

    const token = await params.token;
    const body = alarmThresholds;

    try {
      const response = await axios.put<SensorUnitsAlarmThresholdsUpdateResult>(
        `${API_ENDPOINT}/sensor-units/${sensorUnitId}/alarm-threshold`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }
      const sensorUnitsAlarmThresholdsError =
        getSensorUnitsAlarmThresholdsError(error);
      return thunkAPI.rejectWithValue(sensorUnitsAlarmThresholdsError);
    }
  },
);

const getSensorUnitsAlarmThresholdsError = (
  error: AxiosError<RootErrorResponse>,
): RootError<SensorUnitsAlarmThresholdsErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;

    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
    }
  }

  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

const initialShowResultData: SensorUnitsAlarmThresholds = {
  temperature: {
    upperThreshold: { enabled: false },
    lowerThreshold: { enabled: false },
  },
  humidity: {
    upperThreshold: { enabled: false },
    lowerThreshold: { enabled: false },
  },
  objectTemperature: {
    upperThreshold: { enabled: false },
    lowerThreshold: { enabled: false },
  },
  condensationAlertTemperature: { enabled: false },
};

export interface SensorUnitsAlarmThresholdsState {
  showResult: Result<
    SensorUnitsAlarmThresholds,
    RootError<SensorUnitsAlarmThresholdsErrorCode>
  >;
  updateResult: Result<
    SensorUnitsAlarmThresholdsUpdateResult,
    RootError<SensorUnitsAlarmThresholdsErrorCode>
  >;
}

const initialState: SensorUnitsAlarmThresholdsState = {
  showResult: {
    data: initialShowResultData,
    error: null,
    status: 'idle',
  },
  updateResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const sensorUnitsAlarmThresholdsSlice = createSlice({
  name: 'sensorUnitsAlarmThresholds',
  initialState,
  reducers: {
    resetShowResult(state) {
      state.showResult.error = null;
      state.showResult.status = 'idle';
    },
    resetUpdateResult(state) {
      state.updateResult.error = null;
      state.updateResult.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSensorUnitsAlarmThresholds.pending, (state) => {
        state.showResult.data = initialShowResultData;
        state.showResult.status = 'loading';
        state.showResult.error = null;
      })
      .addCase(getSensorUnitsAlarmThresholds.fulfilled, (state, action) => {
        state.showResult.data = { ...initialShowResultData, ...action.payload };
        state.showResult.error = null;
        state.showResult.status = 'succeeded';
      })
      .addCase(getSensorUnitsAlarmThresholds.rejected, (state, action) => {
        state.showResult.data = initialShowResultData;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.showResult.error = action.payload!;
        state.showResult.status = 'failed';
      })
      .addCase(putSensorUnitsAlarmThresholds.pending, (state) => {
        state.updateResult.status = 'loading';
        state.updateResult.error = null;
      })
      .addCase(putSensorUnitsAlarmThresholds.fulfilled, (state, action) => {
        state.updateResult.data = action.payload;
        state.updateResult.error = null;
        state.updateResult.status = 'succeeded';
      })
      .addCase(putSensorUnitsAlarmThresholds.rejected, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.updateResult.error = action.payload!;
        state.updateResult.status = 'failed';
      });
  },
});

export const showResultSelector = createSelector(
  (state: RootState) => state.sensorUnitsAlarmThresholds,
  (state: SensorUnitsAlarmThresholdsState) => state.showResult,
);

export const updateResultSelector = createSelector(
  (state: RootState) => state.sensorUnitsAlarmThresholds,
  (state: SensorUnitsAlarmThresholdsState) => state.updateResult,
);

export const { resetShowResult, resetUpdateResult } =
  sensorUnitsAlarmThresholdsSlice.actions;

export default sensorUnitsAlarmThresholdsSlice.reducer;
