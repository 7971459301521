import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/domains/root/store';
import { AppDispatch } from 'src/domains/root/store';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import {
  BreadcrumbsV3,
  BreadcrumbTitleV3,
} from 'src/domains/root/commons/breadcrumbs';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import {
  getWorkspacesUsers,
  listStatusSelector,
  listErrorSelector,
  resetAll,
} from '../../../features/workspaces/users/slice';

import UsersManagement from '../../../features/workspaces/users/List';

const Management: React.FC = () => {
  const intl = useIntl();
  const dispatch: AppDispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  const userListStatus = useSelector(listStatusSelector);
  const userListError = useSelector(listErrorSelector);
  const isSmallScreen = useSmallScreen();

  useEffect(() => {
    if (!workspace.workspaceId) return;

    const token = getAccessTokenSilently();
    const promise = dispatch(
      getWorkspacesUsers({
        token,
        workspaceId: workspace.workspaceId,
      }),
    );

    return () => promise.abort();
  }, [workspace, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch]);

  return (
    <>
      <MainContents noXPaddingIfMobile notAllowOverflow>
        {userListStatus === 'loading' && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100px', padding: '10px' }}
          >
            <CircularProgress />
          </Box>
        )}
        {userListStatus === 'failed' && !!userListError && (
          <Alert severity="error">
            {intl.formatMessage({
              id: `pages.OtherSetting.users.management.alert.error.${userListError.code}`,
            })}
          </Alert>
        )}
        {userListStatus === 'succeeded' && (
          <>
            <Box mb={3.5}>
              <Box
                sx={{
                  mb: {
                    xs: 3,
                    sm: 3.5,
                    md: 0,
                  },
                  pl: isSmallScreen ? 2 : 0,
                }}
              >
                <BreadcrumbsV3>
                  <Link to="/settings/others">
                    <BreadcrumbTitleV3 color="primary">
                      {intl.formatMessage({
                        id: 'pages.OtherSetting.title',
                      })}
                    </BreadcrumbTitleV3>
                  </Link>
                  <BreadcrumbTitleV3>
                    {intl.formatMessage({
                      id: 'pages.OtherSetting.users.management.title',
                    })}
                  </BreadcrumbTitleV3>
                </BreadcrumbsV3>
              </Box>
            </Box>
            <Box
              bgcolor="white"
              sx={{
                px: isSmallScreen ? 2 : 3.5,
                py: isSmallScreen ? 2.5 : 3.5,
              }}
            >
              <UsersManagement />
            </Box>
          </>
        )}
      </MainContents>
    </>
  );
};

export default Management;
