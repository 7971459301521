import React from 'react';
import UnrecoverableError from './UnrecoverableError';

const DuplicateEmailOthersError: React.FC = () => {
  return (
    <UnrecoverableError messageId="features.users.emailChanges.update.errors.duplicate_email_others" />
  );
};

export default DuplicateEmailOthersError;
