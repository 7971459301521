import { createSvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

const _ProfileIcon = createSvgIcon(
  <>
    <path d="M10.9755 11.6111C14.1818 11.6111 16.781 9.01188 16.781 5.80556C16.781 2.59924 14.1818 0 10.9755 0C7.76916 0 5.16992 2.59924 5.16992 5.80556C5.16992 9.01188 7.76916 11.6111 10.9755 11.6111Z" />
    <path
      d="M10.9755 11.6111C14.1818 11.6111 16.781 9.01188 16.781 5.80556C16.781 2.59924 14.1818 0 10.9755 0C7.76916 0 5.16992 2.59924 5.16992 5.80556C5.16992 9.01188 7.76916 11.6111 10.9755 11.6111Z"
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9999 21.9995H15.3939C16.8259 21.9995 18.5761 21.9995 19.7021 21.1605C20.1609 20.862 20.5022 20.4118 20.6675 19.8872C20.8328 19.3626 20.8117 18.7963 20.6078 18.2856C20.1388 17.3314 19.3971 16.5407 18.4782 16.0154C16.3359 14.3486 13.7061 13.4445 10.9999 13.4445C8.29381 13.4445 5.66399 14.3486 3.52173 16.0154C2.60282 16.5407 1.86107 17.3314 1.39209 18.2856C1.18824 18.7963 1.16713 19.3626 1.33241 19.8872C1.49768 20.4118 1.83903 20.862 2.29779 21.1605C3.42381 22.0489 5.17399 21.9995 6.60599 21.9995H10.9999Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9999 21.9995H15.3939C16.8259 21.9995 18.5761 21.9995 19.7021 21.1605C20.1609 20.862 20.5022 20.4118 20.6675 19.8872C20.8328 19.3626 20.8117 18.7963 20.6078 18.2856C20.1388 17.3314 19.3971 16.5407 18.4782 16.0154C16.3359 14.3486 13.7061 13.4445 10.9999 13.4445C8.29381 13.4445 5.66399 14.3486 3.52173 16.0154C2.60282 16.5407 1.86107 17.3314 1.39209 18.2856C1.18824 18.7963 1.16713 19.3626 1.33241 19.8872C1.49768 20.4118 1.83903 20.862 2.29779 21.1605C3.42381 22.0489 5.17399 21.9995 6.60599 21.9995H10.9999Z"
      fillOpacity="0.2"
    />
  </>,
  'ProfileIcon',
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProfileIcon = styled(({ ...props }) => (
  <_ProfileIcon {...props} />
)).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 22 22',
}))<SvgIconProps>`
  height: 22px;
  width: 22px;
`;

export default ProfileIcon;
