import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useIntl } from 'react-intl';
import { FormikProps } from 'formik';
import { DialogTitle, Dialog, Alert, Box, Typography } from '@mui/material';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import { CODE_REGEXP, IMEI_REGEXP } from 'src/utils/gateways/validation';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';

interface FormValues {
  registrationCode: string;
  imei: string;
  gatewayName: string;
}

type Props = {
  formik: FormikProps<FormValues>;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
};

/**
 * getUserMedia()のエラー一覧
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
 */
const getUserMediaErrorList = [
  'AbortError',
  'NotAllowedError',
  'NotFoundError',
  'NotReadableError',
  'OverconstrainedError',
  'SecurityError',
  'TypeError',
];

const QrCodeReaderDialog = (props: Props) => {
  const { formik, dialogOpen, setDialogOpen } = props;

  const intl = useIntl();

  const [hasCamera, setHasCamera] = useState(true);
  const [isCameraPermission, setIsCameraPermission] = useState(true);
  const [isCabiotQrCode, setIsCabiotQrCode] = useState(true);
  const [isError, setIsError] = useState<Error>();

  function onCloseDialog() {
    setDialogOpen(false);
  }

  const location = useLocation();
  useEffect(() => {
    if (dialogOpen) {
      awsRum().then((rum) => {
        rum.recordPageView(location.pathname + '#QrCodeReaderDialogRender');
      });
    }
  }, [location, dialogOpen]);

  return (
    <Dialog open={dialogOpen} onClose={onCloseDialog}>
      <DialogTitle id="mode-qr-code-reader-dialog-title">
        <Title onClose={onCloseDialog}>
          {intl.formatMessage({
            id: 'qrCodeReaderDialog.title',
          })}
        </Title>
        {!isCabiotQrCode && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {intl.formatMessage({
              id: 'qrCodeReaderDialog.alert.notCabiotQrCode',
            })}
          </Alert>
        )}
      </DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="info">
            {intl.formatMessage({
              id: 'qrCodeReaderDialog.alert.unknown_error',
            })}
          </Alert>
        )}
        {!hasCamera && (
          <Alert severity="info">
            {intl.formatMessage({
              id: 'qrCodeReaderDialog.alert.noCamera',
            })}
          </Alert>
        )}
        {hasCamera && !isCameraPermission && (
          <Alert severity="info">
            {intl.formatMessage({
              id: 'qrCodeReaderDialog.alert.noCameraPermission',
            })}
          </Alert>
        )}
        {hasCamera && isCameraPermission && !isError && (
          <Typography
            display="flex"
            justifyContent="center"
            paddingBottom={2.5}
          >
            {intl.formatMessage({
              id: 'qrCodeReaderDialog.description',
            })}
          </Typography>
        )}
        <QrReader
          onResult={(result, error) => {
            if (result) {
              try {
                const url = new URL(result?.getText());

                const registrationCode =
                  url.searchParams.get('registrationCode');
                const imei = url.searchParams.get('imei');

                if (
                  registrationCode === null ||
                  !CODE_REGEXP.test(registrationCode) ||
                  imei === null ||
                  !IMEI_REGEXP.test(imei)
                ) {
                  setIsCabiotQrCode(false);
                } else {
                  formik.setFieldValue('registrationCode', registrationCode);
                  formik.setFieldValue('imei', imei);
                  setDialogOpen(false);
                }
              } catch (e) {
                awsRum().then((rum) => rum.recordError(e)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
                setIsCabiotQrCode(false);
              }
            }
            if (error) {
              if (error.name === 'NotFoundError') {
                setHasCamera(false);
                return;
              }

              if (error.name === 'NotAllowedError') {
                setIsCameraPermission(false);
                return;
              }

              /**
               * スキャンする時に画面にQRコードがないと出るエラーは処理したくないのでエラー一覧にあるエラーだけエラーに扱う
               * 上に引っかかってないエラーはunknown_errorエラーにする
               */
              if (getUserMediaErrorList.includes(error.name)) {
                setIsError(error);
                return;
              }
            }
          }}
          constraints={{ facingMode: 'environment' }}
          videoContainerStyle={{ paddingTop: 0 }}
          videoStyle={{
            position: 'unset',
            display:
              !hasCamera || !isCameraPermission || isError ? 'none' : 'block',
          }}
          scanDelay={300}
        />
      </DialogContent>
      <DialogActions>
        <Box display="flex" gap={1.5}>
          <CancelButton onClick={onCloseDialog} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default QrCodeReaderDialog;
