/**
 * このファイルはRange Selector Input（日付入力）の動作を変更するためのワークアラウンドです。
 * 問題があったRange Selector Inputの動作は以下のとおりです。
 *   - 表示範囲の始端と終端を選択した場合に、終端日付のデータが表示範囲に含まれない（選択日付の`00:00`が終端になる。）
 * 修正内容は以下のIssueをリスペクトしています。
 * https://github.com/highcharts/highcharts/issues/18076
 */

import Highstock from 'highcharts/highstock';

export const wrapRangeSelectorGetInputValue = (): void => {
  Highstock.wrap(
    // eslint-disable-next-line
    // @ts-ignore
    Highstock.RangeSelector.prototype,
    'getInputValue',
    function (proceed, name) {
      // eslint-disable-next-line
      // @ts-ignore
      const input = name === 'min' ? this.minInput : this.maxInput;
      // eslint-disable-next-line
      // @ts-ignore
      const options = this.chart.options.rangeSelector;
      // eslint-disable-next-line
      // @ts-ignore
      const time = this.chart.time;
      if (input) {
        return (
          options.inputDateParser ||
          // eslint-disable-next-line
          // @ts-ignore
          this.defaultInputDateParser
        )(input.value, time.useUTC, time, name);
      }
      return 0;
    },
  );
};

// eslint-disable-next-line
// @ts-ignore
export const inputDateParser = (value, useUTC, time, name) => {
  value = value.split(/[-]/);
  return name === 'max'
    ? new Date(
        parseInt(value[0], 10),
        parseInt(value[1], 10) - 1,
        parseInt(value[2], 10),
        23,
        59,
        59,
        999,
      ).getTime()
    : new Date(
        parseInt(value[0], 10),
        parseInt(value[1], 10) - 1,
        parseInt(value[2], 10),
      ).getTime();
};
