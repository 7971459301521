import React from 'react';

const CurrentAlertIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="CurrentAlertIcon"
      {...props}
    >
      <path
        d="M23.9998 19.6556C23.9556 19.1858 23.8101 18.7312 23.5732 18.3231L14.5332 2.85381C14.2948 2.45225 13.9773 2.10323 13.5999 1.82787C13.1311 1.50588 12.5754 1.3335 12.0065 1.3335C11.4376 1.3335 10.8821 1.50588 10.4132 1.82787C10.0359 2.10323 9.71826 2.45225 9.47982 2.85381L0.43994 18.3231C0.203036 18.7312 0.0573808 19.1858 0.0131824 19.6556C-0.0353274 20.2172 0.0903924 20.7801 0.373208 21.2678C0.658096 21.759 1.09261 22.1465 1.61328 22.3737C2.0321 22.5642 2.48647 22.6641 2.94661 22.6668H21.0132C21.4733 22.6641 21.9277 22.5642 22.3465 22.3737C22.8672 22.1465 23.3017 21.759 23.5866 21.2678C23.8817 20.7834 24.0256 20.2221 23.9998 19.6556Z"
        fill="#FFAA1E"
      />
      <path
        d="M10.8132 8.04134C10.8132 7.52134 10.8133 6.70801 11.9999 6.70801C13.1866 6.70801 13.2133 7.48134 13.2 8.04134L12.8533 15.8014C12.8533 16.0947 12.8533 16.668 12.0266 16.668C11.1999 16.668 11.1732 16.108 11.1599 15.8014L10.8132 8.04134Z"
        fill="black"
      />
      <path
        d="M12 19.9746C12.5523 19.9746 13 19.5269 13 18.9746C13 18.4223 12.5523 17.9746 12 17.9746C11.4477 17.9746 11 18.4223 11 18.9746C11 19.5269 11.4477 19.9746 12 19.9746Z"
        fill="black"
      />
    </svg>
  );
};

export default CurrentAlertIcon;
