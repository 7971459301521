import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Title from 'src/domains/root/commons/dialog/Title';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import { useLocation } from 'react-router-dom';
import { awsRum } from 'src/utils/rum';
import { DeletableCheck } from './../slice';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  workspaces?: DeletableCheck['workspaces'];
  onClickNextButton: () => void;
}

const ConfirmDialog: React.FC<DialogProps> = (props) => {
  const {
    open,
    onClose: _handleClose,
    workspaces,
    onClickNextButton: handleClickNextButton,
  } = props;

  const intl = useIntl();

  const handleClose = () => {
    _handleClose();
  };

  /** @temp remove after using api  */
  const loading = false;

  const location = useLocation();
  useEffect(() => {
    if (open) {
      awsRum().then((rum) => {
        rum.recordPageView(
          location.pathname + '#DeleteAccountNoticeDialogRender',
        );
      });
    }
  }, [location, open]);

  return (
    <Dialog
      data-testid="NoticeDialog"
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="account-delete-dialog"
      onClose={handleClose}
    >
      <LoadingOverlay open={loading} />
      <DialogTitle id="account-delete-dialog-title">
        <Title onClose={handleClose}>
          {intl.formatMessage({
            id: 'pages.DeleteAccount.dialogs.confirm.title',
          })}
        </Title>
      </DialogTitle>
      <DialogContent dividers>
        <Box mt={1} mb={2}>
          <Typography>
            {intl.formatMessage(
              {
                id: 'pages.DeleteAccount.dialogs.notice.description.workspacesWillBeDeleted',
              },
              { br: <br /> },
            )}
          </Typography>
        </Box>
        <Box>
          {workspaces?.map((workspace, index) => (
            <Workspace key={index} last={workspaces?.length - 1 === index}>
              {workspace.name}
            </Workspace>
          ))}
        </Box>
        <Box mt={2.25} mb={3}>
          <Typography fontWeight={700}>
            {intl.formatMessage({
              id: 'pages.DeleteAccount.dialogs.notice.description.areYouSureKeepDeleting',
            })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" gap={1.5} mx={1}>
          <NegativeButton
            data-testid="NoticeDialogCloseButton"
            onClick={handleClose}
          >
            {intl.formatMessage({
              id: 'common.cancel',
            })}
          </NegativeButton>
          <PrimaryButton
            data-testid="NoticeDialogSubmitButton"
            onClick={handleClickNextButton}
          >
            {intl.formatMessage({
              id: 'common.buttons.next',
            })}
          </PrimaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

function Workspace({
  children,
  last,
}: {
  children: React.ReactNode;
  last: boolean;
}) {
  const intl = useIntl();

  return (
    <>
      <Box
        py={2}
        pl={2}
        borderBottom={`${!last && '1px solid #DCDCDC'}`}
        sx={{
          wordBreak: 'break-all',
        }}
      >
        <Typography
          fontWeight={700}
          fontSize={(theme) => theme.typography.pxToRem(16)}
          lineHeight={(theme) => theme.typography.pxToRem(24)}
        >
          {intl.formatMessage({
            id: 'pages.DeleteAccount.dialogs.notice.description.workspace',
          })}
          ：{children}
        </Typography>
      </Box>
    </>
  );
}
