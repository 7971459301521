import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { IMEI_REGEXP, CODE_REGEXP } from 'src/utils/gateways/validation';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import BackButton from 'src/domains/root/commons/buttons/Back';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import QrCodeReaderDialog from 'src/domains/root/commons/dialog/QrCodeReaderDialog';
import { postWorkspace } from '../../slice';
import { TemporaryValues } from '../../New';
import GatewayNameField from './form/GatewayNameField';
import IMEIField from './form/IMEIField';
import CodeField from './form/RegistrationCodeField';

interface FormProps {
  values: TemporaryValues;
  onBack: (
    values: Pick<TemporaryValues, 'registrationCode' | 'imei' | 'gatewayName'>,
  ) => void;
  onSubmit: () => void;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}

interface FormValues {
  registrationCode: string;
  imei: string;
  gatewayName: string;
}

const Form: React.FC<FormProps> = (props) => {
  const {
    values: tmpValues,
    onBack: _handleBack,
    onSubmit: handleSubmit,
    dialogOpen,
    setDialogOpen,
  } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = yup.object().shape({
    registrationCode: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.form.registrationCode.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        CODE_REGEXP,
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.validation.registrationCode.matches',
        }),
      ),
    imei: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.form.imei.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        IMEI_REGEXP,
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.validation.imei.matches',
        }),
      ),
    gatewayName: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.form.gatewayName.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .max(200, intl.formatMessage({ id: 'validation.string.max' })),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      registrationCode: tmpValues.registrationCode,
      imei: tmpValues.imei,
      gatewayName: tmpValues.gatewayName,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { workspaceName, adminUserId } = tmpValues;
      const { registrationCode, imei, gatewayName } = values;
      const workspaceId = workspace.workspaceId;
      const token = getAccessTokenSilently();
      const promise = dispatch(
        postWorkspace({
          token,
          workspaceId,
          workspaceName,
          registrationCode,
          imei,
          gatewayName,
          adminUserId,
        }),
      );

      promise.unwrap().then(() => handleSubmit());

      return promise;
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleBack = () => {
    const { registrationCode, imei, gatewayName } = formik.values;
    _handleBack({ registrationCode, imei, gatewayName });
  };

  const qrCodeReaderDialogProps = {
    formik,
    dialogOpen,
    setDialogOpen,
  };

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <ValidationBox>
          <CodeField />
        </ValidationBox>
        <ValidationBox>
          <IMEIField />
        </ValidationBox>
        <ValidationBox>
          <GatewayNameField />
        </ValidationBox>
        <Box pt={1.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
            rowGap={2}
            columnGap={3}
          >
            <Box flex={1}>
              <BackButton size="large" onClick={handleBack}>
                {intl.formatMessage({
                  id: 'pages.OtherSetting.gateways.new.form.back',
                })}
              </BackButton>
            </Box>
            <Box flex={2}>
              <SubmitButton data-testid="SubmitButton" size="large" fullWidth>
                {intl.formatMessage({
                  id: 'pages.OtherSetting.gateways.new.form.button',
                })}
              </SubmitButton>
            </Box>
          </Box>
        </Box>
      </FormikForm>
      {dialogOpen && <QrCodeReaderDialog {...qrCodeReaderDialogProps} />}
    </FormikProvider>
  );
};

export default Form;
