import React from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface Values {
  termsOfService: boolean;
}

type Props = {
  isTermsOfServiceScrollEnd: boolean;
  isSmallScreen: boolean;
};

const AgreementField: React.FC<Props> = (props: Props) => {
  const { isTermsOfServiceScrollEnd, isSmallScreen } = props;

  const intl = useIntl();
  const { values, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({
      ...touched,
      termsOfService: true,
    });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={values.termsOfService}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          name="termsOfService"
          disabled={!isTermsOfServiceScrollEnd}
          data-testid="TermsOfServiceCheckbox"
          sx={{
            color: 'primary.main',
          }}
        />
      }
      label={
        <Typography
          variant="body2"
          sx={[
            !isTermsOfServiceScrollEnd && {
              opacity: 0.2,
            },
          ]}
        >
          <Box
            component="span"
            fontWeight="bold"
            fontSize={isSmallScreen ? 13 : 15}
          >
            {intl.formatMessage({
              id: 'registrations.new.agreement.termsOfService',
            })}
          </Box>
        </Typography>
      }
    />
  );
};

export default AgreementField;
