import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import * as API from './api';
import { PostGatewayCSVResponse, TargetType } from './type';

export type SucceededResult = {
  status: 'succeeded';
} & PostGatewayCSVResponse;

type ErrorCode = 'bad_request' | 'unknown_error';

export type Result =
  | { status: 'hasError'; code: ErrorCode }
  | { status: 'loading' }
  | { status: 'idle' }
  | { status: 'noData' }
  | SucceededResult;

export default function usePostGatewayCsv({
  gatewayId,
  targetType,
}: {
  gatewayId: string;
  targetType: TargetType;
}): {
  mutate: (begin: number, end: number) => Promise<PostGatewayCSVResponse>;
  reset: () => void;
  result: Result;
} {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleRedirect = (error: API.Error) => {
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  };

  const gatewayCSVMutation = useMutation<
    PostGatewayCSVResponse,
    API.Error,
    { begin: number; end: number }
  >({
    mutationFn: async ({ begin, end }) => {
      const token = await getAccessTokenSilently();
      return API.postGatewayCsv(gatewayId, begin, end, targetType, token);
    },
  });

  const mutate = (begin: number, end: number) => {
    return gatewayCSVMutation.mutateAsync({ begin, end });
  };

  if (gatewayCSVMutation.isIdle) {
    return {
      mutate,
      reset: gatewayCSVMutation.reset,
      result: { status: 'idle' },
    };
  }

  if (gatewayCSVMutation.isPending) {
    return {
      mutate,
      reset: gatewayCSVMutation.reset,
      result: { status: 'loading' },
    };
  }

  if (gatewayCSVMutation.isError) {
    const error = gatewayCSVMutation.error;
    handleRedirect(error);

    switch (error.code) {
      case 'bad_request':
        return {
          mutate,
          reset: gatewayCSVMutation.reset,
          result: { status: 'hasError', code: 'bad_request' },
        };
      default:
        return {
          mutate,
          reset: gatewayCSVMutation.reset,
          result: { status: 'hasError', code: 'unknown_error' },
        };
    }
  }

  const { data } = gatewayCSVMutation;

  if (!data.s3Url) {
    return {
      mutate,
      reset: gatewayCSVMutation.reset,
      result: { status: 'noData' },
    };
  }

  return {
    mutate,
    reset: gatewayCSVMutation.reset,
    result: { status: 'succeeded', ...data },
  };
}
