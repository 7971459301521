import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { Result, Status } from 'src/domains/root/store';
import { Role, WorkspacesUser } from 'src/domains/root/types';
import { RootState } from 'src/domains/root/store';
import { RootError, RootErrorResponse } from '../../../utils/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type WorkspacesUsersErrorCode =
  | GetWorkspacesUsersErrorCode
  | PatchWorkspaceUserErrorCode
  | DeleteWorkspacesUserErrorCode;

interface GetWorkspacesUsersParams {
  token: Promise<string>;
  workspaceId: string;
}

interface GetWorkspacesUsersResponse {
  users: WorkspacesUser[];
}

type GetWorkspacesUsersErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getWorkspacesUsers = createAsyncThunk<
  WorkspacesUser[],
  GetWorkspacesUsersParams,
  { state: RootState; rejectValue: RootError<GetWorkspacesUsersErrorCode> }
>('workspaces/users/getUsers', async (params, thunkAPI) => {
  const token = await params.token;

  try {
    const res = await axios.get<GetWorkspacesUsersResponse>(
      `${API_ENDPOINT}/workspaces/${params.workspaceId}/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res.data.users;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspacesUsersError = getWorkspacesUsersErrorCode(error);
    return thunkAPI.rejectWithValue(workspacesUsersError);
  }
});

export const getWorkspacesUsersErrorCode = (
  error: AxiosError<RootErrorResponse>,
): RootError<GetWorkspacesUsersErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface PatchUsersParams {
  token: Promise<string>;
  workspaceId: string;
  userId: string;
  role: Role;
}

type PatchWorkspaceUserErrorCode =
  | 'permission_denied'
  | 'change_own_role_denied'
  | 'admin_only'
  | 'not_found'
  | 'emergency_maintenance'
  | 'unknown_error';

export type UpdateWorkspacesUser = Pick<WorkspacesUser, 'userId' | 'role'>;

export const updateWorkspacesUser = createAsyncThunk<
  UpdateWorkspacesUser,
  PatchUsersParams,
  { state: RootState; rejectValue: RootError<PatchWorkspaceUserErrorCode> }
>('workspaces/users/patchUser', async (params, thunkAPI) => {
  const { workspaceId, userId, role } = params;

  const token = await params.token;
  const body = { role };

  try {
    await axios.patch<void>(
      encodeURI(`${API_ENDPOINT}/workspaces/${workspaceId}/users/${userId}`),
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return { userId, role };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspacesUserError = getPatchWorkspaceUserError(error);
    return thunkAPI.rejectWithValue(workspacesUserError);
  }
});

const getPatchWorkspaceUserError = (
  error: AxiosError<RootErrorResponse>,
): RootError<PatchWorkspaceUserErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'CHANGE_OWN_ROLE_DENIED':
            return {
              code: 'change_own_role_denied',
              noticeable: false,
              recoverable: true,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'not_found',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export type DeleteWorkspacesUser = Pick<WorkspacesUser, 'userId' | 'email'>;

interface DeleteWorkspacesUserParams {
  token: Promise<string>;
  workspaceId: string;
  userId: string;
  email: string;
}

type DeleteWorkspacesUserErrorCode =
  | 'permission_denied'
  | 'delete_yourself_denied'
  | 'admin_only'
  | 'not_found'
  | 'emergency_maintenance'
  | 'unknown_error';

interface DeleteWorkspacesUserError
  extends RootError<DeleteWorkspacesUserErrorCode> {
  email: string;
}

export const deleteWorkspacesUser = createAsyncThunk<
  DeleteWorkspacesUser,
  DeleteWorkspacesUserParams,
  { state: RootState; rejectValue: DeleteWorkspacesUserError }
>('workspaces/users/deleteUser', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId, userId, email } = params;

  try {
    await axios.delete<void>(
      encodeURI(`${API_ENDPOINT}/workspaces/${workspaceId}/users/${userId}`),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { userId, email };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const deleteWorkspacesUserError = {
      ...getDeleteWorkspacesUserErrorCode(error),
      email,
    };
    return thunkAPI.rejectWithValue(deleteWorkspacesUserError);
  }
});

export const getDeleteWorkspacesUserErrorCode = (
  error: AxiosError<RootErrorResponse>,
): RootError<DeleteWorkspacesUserErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'DELETE_YOURSELF_DENIED':
            return {
              code: 'delete_yourself_denied',
              noticeable: false,
              recoverable: true,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
        }
      case 404:
        return {
          code: 'not_found',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface WorkspacesUsersState {
  users: WorkspacesUser[] | null;
  targetUser: WorkspacesUser | null;
  listStatus: Status;
  listError: RootError<GetWorkspacesUsersErrorCode> | null;
  updateResult: Result<
    UpdateWorkspacesUser,
    RootError<PatchWorkspaceUserErrorCode>
  >;
  deleteStatus: Status;
  deleteError: DeleteWorkspacesUserError | null;
}

const initialState: WorkspacesUsersState = {
  users: null,
  targetUser: null,
  listStatus: 'idle',
  listError: null,
  updateResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  deleteStatus: 'idle',
  deleteError: null,
};

export const workspacesUsersSlice = createSlice({
  name: 'workspaces/users',
  initialState,
  reducers: {
    updateTargetUser(state, action: PayloadAction<WorkspacesUser>) {
      state.targetUser = action.payload;
    },
    resetAll(state) {
      state.users = null;
      state.targetUser = null;
      state.listStatus = 'idle';
      state.listError = null;
      state.updateResult = {
        data: null,
        error: null,
        status: 'idle',
      };
      state.deleteStatus = 'idle';
      state.deleteError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspacesUsers.pending, (state) => {
        state.users = null;
        state.listStatus = 'loading';
        state.listError = null;
      })
      .addCase(getWorkspacesUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.listStatus = 'succeeded';
        state.listError = null;
      })
      .addCase(getWorkspacesUsers.rejected, (state, action) => {
        state.users = null;
        state.listStatus = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.listError = action.payload!;
      })
      .addCase(updateWorkspacesUser.pending, (state) => {
        state.updateResult.data = null;
        state.updateResult.status = 'loading';
        state.updateResult.error = null;
      })
      .addCase(updateWorkspacesUser.fulfilled, (state, action) => {
        state.updateResult.data = action.payload;
        state.updateResult.status = 'succeeded';
        state.updateResult.error = null;

        const updatedUser = state.users?.find(
          (user) => user.userId === action.payload.userId,
        );
        if (updatedUser) {
          updatedUser.role = action.payload.role;
        }
      })
      .addCase(updateWorkspacesUser.rejected, (state, action) => {
        state.updateResult.data = null;
        state.updateResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.updateResult.error = action.payload!;
      })
      .addCase(deleteWorkspacesUser.pending, (state) => {
        state.deleteStatus = 'loading';
        state.deleteError = null;
      })
      .addCase(deleteWorkspacesUser.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.users = state.users!.filter(
          (user) => user.userId !== action.payload.userId,
        );
        state.deleteStatus = 'succeeded';
        state.deleteError = null;
      })
      .addCase(deleteWorkspacesUser.rejected, (state, action) => {
        state.deleteStatus = 'failed';
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.deleteError = action.payload!;
      });
  },
});

export const usersSelector = createSelector(
  (state: RootState) => state.workspacesUsers,
  (state: WorkspacesUsersState) => state.users,
);

export const targetUserSelector = createSelector(
  (state: RootState) => state.workspacesUsers,
  (state: WorkspacesUsersState) => state.targetUser,
);

export const listStatusSelector = createSelector(
  (state: RootState) => state.workspacesUsers,
  (state: WorkspacesUsersState) => state.listStatus,
);

export const listErrorSelector = createSelector(
  (state: RootState) => state.workspacesUsers,
  (state: WorkspacesUsersState) => state.listError,
);

export const updateResultSelector = createSelector(
  (state: RootState) => state.workspacesUsers,
  (state: WorkspacesUsersState) => state.updateResult,
);

export const deleteStatusSelector = createSelector(
  (state: RootState) => state.workspacesUsers,
  (state: WorkspacesUsersState) => state.deleteStatus,
);

export const deleteErrorSelector = createSelector(
  (state: RootState) => state.workspacesUsers,
  (state: WorkspacesUsersState) => state.deleteError,
);

export const { updateTargetUser, resetAll } = workspacesUsersSlice.actions;

export default workspacesUsersSlice.reducer;
