import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useIntl } from 'react-intl';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import { Tooltip } from 'src/shared/ui';
import AnnounceIcon from '../icons/AnnounceIcon';
import { readAnnounce, existsUnreadArticleSelector } from './slice';
import useGetAnnounce from './useGetAnnounce';

const ANNOUNCE_PAGE_URL = import.meta.env.VITE_ANNOUNCE_PAGE_URL;

const AnnounceIconButton: React.FC = () => {
  const existsUnreadArticle = useSelector(existsUnreadArticleSelector);
  const dispatch: AppDispatch = useDispatch();

  const intl = useIntl();

  useGetAnnounce(existsUnreadArticle);

  const handleClick = () => {
    dispatch(readAnnounce());
    window.open(ANNOUNCE_PAGE_URL, '_blank');
  };

  return (
    <Tooltip
      isArrow={true}
      title={intl.formatMessage({ id: 'common.announce' })}
    >
      <IconButton onClick={handleClick} size="large">
        <Badge
          data-testid="AnnounceIconBadge"
          invisible={!existsUnreadArticle}
          variant="dot"
          color="primary"
        >
          <AnnounceIcon data-testid="AnnounceIcon" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default AnnounceIconButton;
