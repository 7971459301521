const WORKSPACES = 'workspaces';
const USERS = 'users';

export const Keys = {
  workspaces: {
    all: () => {
      return [WORKSPACES, 'all'];
    },
  },

  users: {
    me: () => {
      return [USERS, 'me'];
    },
  },
};
