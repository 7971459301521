import React from 'react';
import { ThemeProvider } from 'styled-components';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import flattenMessages from 'src/utils/i18n';
import theme from './styles/theme';
import { store } from './store';
import ja from './translations/ja.json';

const messages = {
  ja: flattenMessages(ja),
};

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <IntlProvider locale="ja" messages={messages.ja}>
            <Provider store={store}>{children}</Provider>
          </IntlProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
