import Typography, { TypographyProps } from '@mui/material/Typography';
import styled from 'styled-components';

const ContentHeader = styled(Typography).attrs<TypographyProps>(() => ({
  component: 'h2',
}))`
  border-left: solid ${(props) => props.theme.palette.primary.main} 3px;
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  font-weight: bold;
  padding-left: ${(props) => props.theme.spacing(1.5)};
`;

export default ContentHeader;
