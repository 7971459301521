import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  imei: string;
}

const IMEIField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, imei: true });
  };

  const errorMessage = (values.imei || touched.imei) && errors.imei;

  return (
    <TextField
      data-testid="ImeiTextInput"
      name="imei"
      type="text"
      value={values.imei}
      label={intl.formatMessage({
        id: 'pages.Setup.Gateway.new.form.imei.label',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default IMEIField;
