import React from 'react';
import UnrecoverableError from './UnrecoverableError';

const DuplicateUserError: React.FC = () => {
  return (
    <UnrecoverableError
      messageId="registrations.users.new.error.duplicate_user"
      buttonId="common.buttons.login"
      buttonLink="/"
    />
  );
};

export default DuplicateUserError;
