import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type UsersEmailChangeErrorCode =
  | 'email_change_id_required'
  | 'invalid_email_change_id'
  | 'used_email_change_id'
  | 'duplicate_email_me'
  | 'duplicate_email_others'
  | 'expired_email_change_id'
  | 'emergency_maintenance'
  | 'unknown_error';

export interface PostUsersEmailChangeParams {
  token: Promise<string>;
  email: string;
}

export const postUsersEmailChange = createAsyncThunk<
  void,
  PostUsersEmailChangeParams,
  { state: RootState; rejectValue: RootError<UsersEmailChangeErrorCode> }
>('users/emailChanges/postEmailChange', async (params, thunkAPI) => {
  const token = await params.token;
  const { email } = params;
  const body = { email };
  try {
    await axios.post<void>(`${API_ENDPOINT}/users/me/email-changes`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspaceError = getPostUsersEmailChangeError(error);
    return thunkAPI.rejectWithValue(workspaceError);
  }
});

const getPostUsersEmailChangeError = (
  error: AxiosError<RootErrorResponse>,
): RootError<UsersEmailChangeErrorCode> => {
  error.response?.data.errorCode;
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 409:
        switch (errorCode) {
          case 'DUPLICATED_EMAIL_ME':
            return {
              code: 'duplicate_email_me',
              noticeable: false,
              recoverable: true,
            };
          case 'DUPLICATED_EMAIL_OTHERS':
            return {
              code: 'duplicate_email_others',
              noticeable: false,
              recoverable: true,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface PutUsersEmailChangeParams {
  token: Promise<string>;
  emailChangeId: string;
}

export const putUsersEmailChange = createAsyncThunk<
  void,
  PutUsersEmailChangeParams,
  { state: RootState; rejectValue: RootError<UsersEmailChangeErrorCode> }
>('users/emailChanges/putEmailChange', async (params, thunkAPI) => {
  const { emailChangeId } = params;
  if (!emailChangeId) {
    return thunkAPI.rejectWithValue({
      code: 'email_change_id_required',
      noticeable: false,
      recoverable: false,
    });
  }

  const token = await params.token;
  const body = {};

  try {
    await axios.put<void>(
      `${API_ENDPOINT}/users/me/email-changes/${emailChangeId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const emailChangeError = getPutUsersEmailChangeError(error);
    return thunkAPI.rejectWithValue(emailChangeError);
  }
});

const getPutUsersEmailChangeError = (
  error: AxiosError<RootErrorResponse>,
): RootError<UsersEmailChangeErrorCode> => {
  error.response?.data.errorCode;
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'DIFFERENT_USER':
            return {
              code: 'invalid_email_change_id',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'invalid_email_change_id',
          noticeable: false,
          recoverable: false,
        };
      case 409:
        switch (errorCode) {
          case 'USED_EMAIL_CHANGE_ID':
            return {
              code: 'used_email_change_id',
              noticeable: false,
              recoverable: false,
            };
          case 'DUPLICATED_EMAIL_OTHERS':
            return {
              code: 'duplicate_email_others',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 410:
        return {
          code: 'expired_email_change_id',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

interface UsersEmailChangesState {
  createResult: Result<void, RootError<UsersEmailChangeErrorCode>>;
  updateResult: Result<void, RootError<UsersEmailChangeErrorCode>>;
}

const initialState: UsersEmailChangesState = {
  createResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  updateResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const usersEmailChangesSlice = createSlice({
  name: 'users/emailChanges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postUsersEmailChange.pending, (state) => {
        state.createResult.status = 'loading';
        state.createResult.error = null;
      })
      .addCase(postUsersEmailChange.fulfilled, (state) => {
        state.createResult.status = 'succeeded';
        state.createResult.error = null;
      })
      .addCase(postUsersEmailChange.rejected, (state, action) => {
        state.createResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.createResult.error = action.payload!;
      })
      .addCase(putUsersEmailChange.pending, (state) => {
        state.updateResult.status = 'loading';
        state.updateResult.error = null;
      })
      .addCase(putUsersEmailChange.fulfilled, (state) => {
        state.updateResult.status = 'succeeded';
        state.updateResult.error = null;
      })
      .addCase(putUsersEmailChange.rejected, (state, action) => {
        state.updateResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.updateResult.error = action.payload!;
      });
  },
});

export const createResultSelector = createSelector(
  (state: RootState) => state.usersEmailChanges,
  (state: UsersEmailChangesState) => state.createResult,
);

export const updateResultSelector = createSelector(
  (state: RootState) => state.usersEmailChanges,
  (state: UsersEmailChangesState) => state.updateResult,
);

export default usersEmailChangesSlice.reducer;
