import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ImageBox: React.FC = () => {
  const intl = useIntl();

  return (
    <Box component="fieldset" border="2px solid #dcdcdc" borderRadius={1}>
      <Box component="legend" margin="0 auto">
        <Typography fontSize={15} fontWeight="bold">
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.new.imageBox.title',
          })}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            mx: 1.75,
            mt: 0,
            mb: 0.5,
          },
        })}
      >
        <Box>
          <Box display="flex" component="figure" m={0}>
            <img
              src="/assets/registration/gateway.jpg"
              alt="gateway"
              width="100%"
            />
          </Box>
        </Box>
        <Box>
          <Box display="flex" component="figure" m={0}>
            <img
              src="/assets/registration/sensor-unit.jpg"
              alt="sensor-unit"
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageBox;
