import Highcharts from 'highcharts';

/*
 * ナビゲータ設定を取得する
 */
export const getNavigatorOptions = (
  isSmallScreen: boolean,
): Highcharts.Options => {
  return {
    navigator: {
      enabled: isSmallScreen ? false : true,
      maskFill: 'rgba(102,133,194,0.2)',
      xAxis: {
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%b/%e',
          week: '%b/%e',
          month: '%Y/%b',
          year: '%Y',
        },
      },
    },
  };
};
