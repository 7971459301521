import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  BreadcrumbsV3,
  BreadcrumbTitleV3,
} from 'src/domains/root/commons/breadcrumbs';
import AccessControl from 'src/domains/root/commons/AccessControl';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import ContractTableContainer from 'src/domains/root/features/contracts/ContractTableContainer';

const Page: React.FC = () => {
  const intl = useIntl();

  return (
    <MainContents noXPaddingIfMobile notAllowOverflow showContractExpiredAlert>
      <Box display="flex" flexDirection="column" gap={{ xs: 3, sm: 3.5 }}>
        <Box ml={{ xs: 2, sm: 0 }}>
          <BreadcrumbsV3>
            <Link to="/settings/others">
              <BreadcrumbTitleV3 color="primary">
                {intl.formatMessage({
                  id: 'pages.OtherSetting.title',
                })}
              </BreadcrumbTitleV3>
            </Link>
            <BreadcrumbTitleV3>
              <AccessControl
                permit={(permissions) =>
                  permissions.includes('cabiotpack.contract.update')
                }
              >
                {(permitted) =>
                  intl.formatMessage({
                    id: permitted
                      ? 'pages.OtherSetting.contracts.list.title'
                      : 'pages.OtherSetting.contracts.list.normalUserTitle',
                  })
                }
              </AccessControl>
            </BreadcrumbTitleV3>
          </BreadcrumbsV3>
        </Box>

        <ContractTableContainer />
      </Box>
    </MainContents>
  );
};

export default Page;
