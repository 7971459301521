import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import AccessControl from 'src/domains/root/commons/AccessControl';
import Breadcrumbs from 'src/domains/root/commons/breadcrumbs/Breadcrumbs';
import BreadcrumbTitle from 'src/domains/root/commons/breadcrumbs/Title';
import Gateways from './Gateways';
import Users from './Users';
import Workspaces from './Workspaces';
import Contracts from './Contracts';

const OtherSetting: React.FC = () => {
  const intl = useIntl();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" flexDirection="column" gap={isSmallScreen ? 3 : 3.5}>
      <Box bgcolor="white" px={3.5} py={3}>
        <Breadcrumbs>
          <BreadcrumbTitle>
            {intl.formatMessage({
              id: 'pages.OtherSetting.title',
            })}
          </BreadcrumbTitle>
        </Breadcrumbs>
      </Box>

      <Gateways />

      <AccessControl
        permit={(permissions) => permissions.includes('workspace.name.update')}
      >
        <Workspaces />
      </AccessControl>

      <Users />

      <Contracts />
    </Box>
  );
};

export default OtherSetting;
