import { createSvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

const _CloseIcon = createSvgIcon(
  <g opacity="0.8">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976316 0.683418 -0.0976317 0.292894 0.292893C-0.0976305 0.683417 -0.0976306 1.31658 0.292894 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071Z"
    />
  </g>,
  'Close',
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CloseIcon = styled(({ ...props }) => (
  <_CloseIcon {...props} />
)).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 14 14',
}))<SvgIconProps>`
  height: 14px;
  width: 14px;
`;

export default CloseIcon;
