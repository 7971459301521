import React, { useEffect, useRef, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { AppDispatch } from 'src/domains/root/store';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import TermsoOfServiceDocumentContent from 'src/shared/TermsOfServiceDocumentContent';
import { getUsersMe } from '../slice';
import TermsOfService from './agreement/TermsOfService';
import { putAgreedTermsOfService } from './slice';

const TermsOfServiceDocument = () => {
  const [isTermsOfServiceScrollEnd, setIsTermsOfServiceScrollEnd] =
    useState(false);

  const [termsOfServiceChecked, setTermsOfServiceChecked] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useSmallScreen();

  useEffect(() => {
    const element = wrapperRef.current;

    function checkScrollEnd(element: HTMLDivElement | null) {
      if (!element) return;

      // 解像度によって微妙な誤差があるので10pxぐらいの余裕に計算する
      Math.ceil(element.scrollTop + element.offsetHeight) >=
        element?.scrollHeight - 10 && setIsTermsOfServiceScrollEnd(true);
    }

    if (!element) return;

    element.addEventListener('scroll', () => checkScrollEnd(element));

    return element.removeEventListener('scroll', () => checkScrollEnd(element));
  }, [setIsTermsOfServiceScrollEnd]);

  const handleClickNextButton = async () => {
    const token = getAccessTokenSilently();
    await dispatch(putAgreedTermsOfService({ token }));
    await dispatch(getUsersMe({ token }));
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          fontWeight={700}
          fontSize={(theme) =>
            isSmallScreen
              ? theme.typography.pxToRem(28)
              : theme.typography.pxToRem(36)
          }
          paddingBottom={2}
        >
          {intl.formatMessage({
            id: 'pages.TermsOfServiceReagreement.title',
          })}
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          rowGap={1}
          pb={{ xs: 4, sm: 5 }}
          fontSize={{ xs: 14, sm: 15 }}
        >
          <Typography fontSize="inherit">
            {intl.formatMessage({
              id: 'pages.TermsOfServiceReagreement.description',
            })}
          </Typography>
          <Typography fontSize="inherit">
            {intl.formatMessage(
              {
                id: 'pages.TermsOfServiceReagreement.linkDescription',
              },
              {
                link: (
                  <Link
                    href="https://cabiot.zendesk.com/hc/ja/sections/37921455213977"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({
                      id: 'pages.TermsOfServiceReagreement.link',
                    })}
                  </Link>
                ),
              },
            )}
          </Typography>
        </Box>
      </Box>
      <Box
        ref={wrapperRef}
        data-testid="TermsOfServiceDocument"
        py={isSmallScreen ? 0 : 1}
        px={isSmallScreen ? 2 : 4}
        margin="0 auto"
        overflow="scroll"
        maxWidth={720}
        minHeight={348}
        maxHeight={isSmallScreen ? 348 : 520}
        bgcolor="#F3F2F2"
        sx={{
          overflowWrap: 'anywhere',
        }}
      >
        <TermsoOfServiceDocumentContent />
      </Box>
      <Box
        data-testid="TermsOfServiceArea"
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={isSmallScreen ? 4 : 4.5}
        margin="0 auto"
        maxWidth={500}
      >
        <TermsOfService
          isTermsOfServiceScrollEnd={isTermsOfServiceScrollEnd}
          onChangeCheckbox={setTermsOfServiceChecked}
          isSmallScreen={isSmallScreen}
        />
        <Box sx={{ minWidth: isSmallScreen ? '328px' : '360px', fontSize: 18 }}>
          <PrimaryButton
            data-testid="NextButton"
            disabled={!termsOfServiceChecked}
            sx={{
              marginTop: isSmallScreen ? 3 : 4.5,
              width: '100%',
            }}
            onClick={handleClickNextButton}
          >
            {intl.formatMessage({
              id: 'pages.TermsOfServiceReagreement.next',
            })}
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsOfServiceDocument;
