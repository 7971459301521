import React from 'react';
import { useIntl } from 'react-intl';
import type { AlertHistory } from 'src/domains/root/features/alerts/histories/slice';
import {
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { SxProps } from '@mui/material';
import ChartLinkButton from 'src/domains/root/commons/ChartLinkButton';
import CustomTableCell from 'src/domains/root/commons/CustomTableCell';
import AlertTypeChip from '../../sensor-units/AlertTypeChip';
import FormattedTimestamp from '../../sensor-units/FormattedTimestamp';
import { GatewayLabelIcon, SensorUnitLabelIcon } from '.';

interface Props {
  alertHistory: AlertHistory;
  selected: {
    gatewayName: string;
    sensorUnitName: string;
    alertType: string;
    event: string;
  };
  onClickAlertChartButton: () => void;
}

const AlertHistoryTableRow = ({
  alertHistory,
  selected,
  onClickAlertChartButton,
}: Props) => {
  const intl = useIntl();
  const theme = useTheme();

  const isTabletSize = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (
    selected.gatewayName !== 'all' &&
    selected.gatewayName !== alertHistory.gatewayName
  ) {
    return null;
  }
  if (
    selected.sensorUnitName !== 'all' &&
    selected.sensorUnitName !== alertHistory.sensorUnitName
  ) {
    return null;
  }
  if (
    selected.alertType !== 'all' &&
    selected.alertType !== alertHistory.alertType
  ) {
    return null;
  }
  if (selected.event !== 'all' && selected.event !== alertHistory.eventType) {
    return null;
  }

  return (
    <TableRow>
      <StyledTableCell align="center">
        <FormattedTimestamp
          timestamp={alertHistory.timestamp}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.pxToRem(13),
            },
          })}
        />
      </StyledTableCell>
      {!isSmallScreen && (
        <StyledTableCell align="center">
          {alertHistory.sensorUnitNumber}
        </StyledTableCell>
      )}
      <StyledTableCell
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            minWidth: 200,
            fontSize: theme.typography.pxToRem(13),
            lineBreak: 'anywhere',
          },
        })}
      >
        {isTabletSize && <GatewayLabelIcon style={{ marginRight: 8 }} />}
        {alertHistory.gatewayName}
        <br />
        {isTabletSize && <SensorUnitLabelIcon style={{ marginRight: 8 }} />}
        {alertHistory.sensorUnitName}
      </StyledTableCell>
      <StyledTableCell align="center">
        <AlertTypeChip
          type={alertHistory.alertType}
          reason={alertHistory.alertReason}
          sx={[
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(11),
              },
            }),
          ]}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <Typography
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.pxToRem(14),
            },
          })}
        >
          {intl.formatMessage({
            id: `alertHistoryTable.${alertHistory.eventType}`,
          })}
        </Typography>
      </StyledTableCell>
      {!isSmallScreen && (
        <StyledTableCell align="center">
          <ChartLinkButton
            observeMode={alertHistory.observe.mode}
            onClickAlertChartButton={onClickAlertChartButton}
          />
        </StyledTableCell>
      )}
    </TableRow>
  );
};

function StyledTableCell({
  align,
  sx = [],
  children,
}: {
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}) {
  return (
    <CustomTableCell
      align={align}
      sx={[
        (theme) => ({
          [theme.breakpoints.down('sm')]: {
            px: 1,
            py: 1.5,

            ':first-child': {
              pl: 0,
            },

            ':last-child': {
              pr: 0,
            },
          },
        }),

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </CustomTableCell>
  );
}

export default AlertHistoryTableRow;
