import { Form as FormikForm, FormikProvider } from 'formik';
import React from 'react';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import ValidationBox from '../../../commons/form/ValidationBox';
import SubmitButton from '../../../commons/form/SubmitButton';
import EmailField from './form/EmailField';
import CodeField from './form/CodeField';
import useFormikInstance from './form/useFormikInstance';

const Form: React.FC = () => {
  const intl = useIntl();
  const formik = useFormikInstance();

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <ValidationBox>
          <CodeField />
        </ValidationBox>
        <ValidationBox>
          <EmailField />
        </ValidationBox>
        <Box mt={4} mb={4}>
          <SubmitButton data-testid="SubmitButton" fullWidth>
            <Box>
              {intl.formatMessage({
                id: 'registrations.new.form.submit',
              })}
            </Box>
          </SubmitButton>
        </Box>
      </FormikForm>
    </FormikProvider>
  );
};

export default Form;
