import { IntlShape } from 'react-intl';

/*
 * Highcharts内でMaterial UI iconを利用するためにhtmlを文字列として保持する
 * Material UI iconライブラリの仕様が変わった場合は要見直し
 */
export const indentLineIcnoSvgStirng = {
  indentLine: `<svg width="25" height="25" fill="none">
  <path d="M0.5 0C0.776142 0 1 0.223858 1 0.5V10H24.5C24.7761 10 25 10.2239 25 10.5C25 10.7761 24.7761 11 24.5 11H0V0.5C0 0.223858 0.223858 0 0.5 0Z" fill="black"/>
  </svg>`,
};

const modeIconSvgString = {
  condensationObserve:
    '<svg class="MuiSvgIcon-root" fill="#3F51B5" style="width: 24px; height: 24px; vertical-align: bottom;">' +
    '<path d="M17.66 8l-4.95-4.94a.9959.9959 0 00-1.41 0L6.34 8C4.78 9.56 4 11.64 4 13.64s.78 4.11 2.34 5.67 3.61 2.35 5.66 2.35 4.1-.79 5.66-2.35S20 15.64 20 13.64 19.22 9.56 17.66 8zM6 14c.01-2 .62-3.27 1.76-4.4L12 5.27l4.24 4.38C17.38 10.77 17.99 12 18 14H6z"></path>' +
    '</svg>',
  temperatureHumidityObserve:
    '<svg class="MuiSvgIcon-root" style="width: 24px; height: 24px; vertical-align: bottom;">' +
    '<path d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"></path>' +
    '</svg>',
  connectorTemperatureObserve:
    '<svg class="MuiSvgIcon-root" fill="#F50057" style="width: 24px; height: 24px; vertical-align: bottom;">' +
    '<path d="M17.09 4.56c-.7-1.03-1.5-1.99-2.4-2.85-.35-.34-.94-.02-.84.46.19.94.39 2.18.39 3.29 0 2.06-1.35 3.73-3.41 3.73-1.54 0-2.8-.93-3.35-2.26-.1-.2-.14-.32-.2-.54-.11-.42-.66-.55-.9-.18-.18.27-.35.54-.51.83C4.68 9.08 4 11.46 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8c0-3.49-1.08-6.73-2.91-9.44zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.47-.3 2.98-.93 4.03-1.92.28-.26.74-.14.82.23.23 1.02.35 2.08.35 3.15.01 2.65-2.14 4.8-4.79 4.8z"></path>' +
    '</svg>',
};

const heartBeatIconSvgString = {
  CheckIcon:
    '<svg class="MuiSvgIcon-root" style="width: 24px; height: 24px; vertical-align: bottom;">' +
    '<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>' +
    '</svg>',
  CloseIcon:
    '<svg class="MuiSvgIcon-root" style="width: 24px; height: 24px; vertical-align: bottom;">' +
    '<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>' +
    '</svg>',
};

const getValue = <T, U extends keyof T>(obj: T, key: U): T[U] => {
  return obj[key];
};

export type ObserveModeIcon = keyof typeof modeIconSvgString;

/*
 * 用途アイコンを表示するための関数
 */
export const renderObserveModeIcon = (mode: ObserveModeIcon): string => {
  return getValue(modeIconSvgString, mode);
};

/*
 * 死活監視アイコンを表示するための関数
 */
export const renderHeartbeatIcon = (
  intl: IntlShape,
  heartBeat?: boolean,
): string => {
  if (typeof heartBeat == 'boolean') {
    return getValue(
      heartBeatIconSvgString,
      heartBeat ? 'CheckIcon' : 'CloseIcon',
    );
  }

  if (heartBeat === undefined) {
    return '';
  }

  return (
    ' ' + // blank character
    intl.formatMessage({
      id: 'common.unconnected',
    }) +
    ' ' // blank character
  );
};
