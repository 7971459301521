import React from 'react';
import { DateTime } from 'luxon';
import HookStatus from 'src/domains/root/commons/HookStatus';
import type { AlertHistory } from 'src/domains/root/features/alerts/histories/slice';
import useAlertSensorDataChart from './sensor-data/useAlertSensorDataChart';
import SensorDataChart from './SensorDataChart';
import SensorDataModalFrame from './SensorDataModalFrame';

interface Props {
  alertHistory: AlertHistory;
  handleClose: () => void;
}

export default function AlertHistoryChartModal({
  alertHistory,
  handleClose,
}: Props) {
  const result = useAlertSensorDataChart({
    sensorUnitId: alertHistory.sensorUnitId,
    areaId: alertHistory.areaId,
    includeRemovedData: true,
    occuredTimestamp: alertHistory.timestamp,
  });

  const occurredAt = DateTime.fromMillis(alertHistory.timestamp);

  return (
    <SensorDataModalFrame
      menuContents={{ show: false }}
      handleClose={handleClose}
    >
      {result.status !== 'succeeded' ? (
        <HookStatus
          target="sensorDataChart"
          error={result.status === 'hasError'}
          loading={result.status === 'loading'}
          noData={result.status === 'noData'}
        />
      ) : (
        <SensorDataChart
          isFullData={result.phase === 'full_data_fetched'}
          gatewayName={alertHistory.gatewayName}
          sensorUnit={{
            name: alertHistory.sensorUnitName,
            observe: alertHistory.observe,
            isAlive: undefined,
          }}
          sensorData={result.sensorData}
          weatherArea={result.weatherArea}
          weatherHistories={result.weatherHistories}
          phase={result.phase}
          occurredAt={occurredAt.toJSDate()}
          initialViewRange={{
            start: occurredAt.minus({ day: 1 }).toJSDate(),
            end: occurredAt.plus({ day: 1 }).toJSDate(),
          }}
        />
      )}
    </SensorDataModalFrame>
  );
}
