import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import CustomTableCell from './CustomTableCell';

const SensorHeartbeatCell: React.FC<{ heartBeat?: boolean }> = (props) => {
  const { heartBeat } = props;
  const intl = useIntl();

  return (
    <CustomTableCell align="center">
      {heartBeat === undefined ? (
        intl.formatMessage({
          id: 'common.unconnected',
        })
      ) : heartBeat === true ? (
        <CheckIcon />
      ) : (
        <CloseIcon />
      )}
    </CustomTableCell>
  );
};

export default SensorHeartbeatCell;
