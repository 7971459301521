import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';

const _ArrowDownwardIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.36565 9.43433C1.05323 9.12192 0.546703 9.12192 0.234284 9.43433C-0.078134 9.74675 -0.0781339 10.2533 0.234285 10.5657L3.43427 13.7657C3.74669 14.0781 4.25322 14.0781 4.56564 13.7657L7.76562 10.5657C8.07804 10.2533 8.07804 9.74675 7.76562 9.43433C7.45321 9.12192 6.94668 9.12192 6.63426 9.43433L4.79995 11.2686L4.79995 0.799946C4.79995 0.35812 4.44178 -5.11873e-05 3.99995 -5.11487e-05C3.55813 -5.11101e-05 3.19996 0.35812 3.19996 0.799946L3.19996 11.2686L1.36565 9.43433Z"
  />,
  'ArrowDownward',
);

const ArrowDownwardIcon = styled(_ArrowDownwardIcon).attrs<SvgIconProps>(
  () => ({
    viewBox: '0 0 8 14',
  }),
)`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.pxToRem(15)};
`;

export default ArrowDownwardIcon;
