import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { CODE_REGEXP } from 'src/utils/gateways/validation';
import { emailLengthTester } from 'src/utils/users/validation/email';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useValidationSchema = () => {
  const intl = useIntl();

  const emailValidationSchema = useMemo(
    () =>
      yup
        .string()
        .label(intl.formatMessage({ id: 'registrations.new.form.email' }))
        .required(intl.formatMessage({ id: 'validation.mixed.required' }))
        .email(intl.formatMessage({ id: 'validation.string.email' }))
        .test({
          test: emailLengthTester,
          message: intl.formatMessage({ id: 'validation.string.emailLength' }),
        }),
    [intl],
  );

  const codeValidationSchema = useMemo(
    () =>
      yup
        .string()
        .label(intl.formatMessage({ id: 'registrations.new.form.code' }))
        .required(intl.formatMessage({ id: 'validation.mixed.required' }))
        .matches(
          CODE_REGEXP,
          intl.formatMessage({
            id: 'registrations.new.validation.code.matches',
          }),
        ),
    [intl],
  );

  return {
    emailValidationSchema,
    codeValidationSchema,
  };
};

export default useValidationSchema;
