import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import useSmallScreen from 'src/domains/invitation/hooks/use-small-screen';
import CabiotLogo from 'src/domains/invitation/commons/CabiotLogo';
import Form from './new/Form';
import TermsOfServiceDocument from './new/agreement/TermsOfServiceDocument';
import Agreement from './new/Agreement';

const New: React.FC = () => {
  const intl = useIntl();

  const isSmallScreen = useSmallScreen();
  const [customerAgreementPage, setCustomerAgreementPage] = useState(true);
  const [isTermsOfServiceScrollEnd, setIsTermsOfServiceScrollEnd] =
    useState(false);

  if (customerAgreementPage) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        py={isSmallScreen ? 2 : 6}
        px={2.5}
        minHeight={680}
        height="100vh"
        maxHeight={800}
      >
        <TermsOfServiceDocument
          setIsTermsOfServiceScrollEnd={setIsTermsOfServiceScrollEnd}
        />

        <Agreement
          onSubmitAgree={() => setCustomerAgreementPage(false)}
          isTermsOfServiceScrollEnd={isTermsOfServiceScrollEnd}
        />
      </Box>
    );
  } else {
    return (
      <>
        <Box display="flex" justifyContent="center" mt={7.5}>
          <CabiotLogo />
        </Box>
        <Box mt={8.5} mb={7}>
          <Typography variant="h2" align="center">
            {intl.formatMessage({
              id: 'users.new.form.title',
            })}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          px={2.5}
          margin="0 auto"
          maxWidth={500}
        >
          <Box
            sx={{
              form: { width: '100%' },
            }}
          >
            <Form />
          </Box>
        </Box>
      </>
    );
  }
};

export default New;
