import React from 'react';
import { Alert, Box } from '@mui/material';

type Props = { status: string; message: { success?: string; error?: string } };

export function DialogTitleAlert({ status, message }: Props) {
  const isAlert = status === 'succeeded' || status === 'hasError';

  return (
    <Box px={isAlert ? 3 : 0} pb={isAlert ? 3 : 0}>
      {status === 'succeeded' && message?.success && (
        <Alert severity="success" data-testid="SuccessAlert">
          {message.success}
        </Alert>
      )}
      {status === 'hasError' && message?.error && (
        <Alert severity="error" data-testid="ErrorAlert">
          {message.error}
        </Alert>
      )}
    </Box>
  );
}
