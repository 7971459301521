import React from 'react';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import _DeleteAccount from '../features/users/delete-account/DeleteAccount';
import TopButton from '../commons/TopButton';

const DeleteAccount: React.FC = () => {
  return (
    <MainContents recoverCssBaseLine>
      <_DeleteAccount />
      <TopButton />
    </MainContents>
  );
};

export default DeleteAccount;
