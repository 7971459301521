import React from 'react';
import { TableCellProps } from '@mui/material/TableCell';
import styled from 'styled-components';
import { Box } from '@mui/material';
import * as API from 'src/apis';

const Unit = styled.span`
  font-size: 0.75em;
`;

const units: {
  [key in API.AlertType]: string;
} = {
  temperature: '°C',
  humidity: '%',
  objectTemperature: '°C',
  condensationAlertTemperature: '°C',
};

interface UnitizedValueProps extends TableCellProps {
  value: number | null;
  type: API.AlertType;
}

const UnitizedValue: React.FC<UnitizedValueProps> = (props) => {
  const { value: strValue, type, ...rest } = props;

  const value = strValue?.toFixed(1);

  return (
    <Box {...rest}>
      <span>{value ?? '-'}</span>
      <Unit>{value ? units[type] : ''}</Unit>
    </Box>
  );
};

export default UnitizedValue;
