import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import PrimaryButton from '../../commons/buttons/PrimaryButton';
import CabiotLogo from '../../commons/CabiotLogo';

type UnrecoverableErrorProps =
  | {
      messageId?: string;
      message?: React.ReactNode;
      buttonId: string;
      buttonLink: string;
      noButton?: false;
      children?: React.ReactNode;
    }
  | {
      messageId?: string;
      message?: React.ReactNode;
      noButton: true;
      children?: React.ReactNode;
    };

const APP_VERSION = import.meta.env.VITE_WEBAPP_VERSION;

export default function UnrecoverableError(props: UnrecoverableErrorProps) {
  const intl = useIntl();
  const currentTime = useMemo(
    () => DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
    [],
  );

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={7.5}>
          <CabiotLogo />
        </Box>
        <Box mt={8.5} mb={10} maxWidth={560}>
          <Typography
            variant="h4"
            align="center"
            fontWeight={700}
            fontSize={(theme) => theme.typography.pxToRem(30)}
          >
            {props.message || intl.formatMessage({ id: props.messageId })}
          </Typography>
          {props.children}
        </Box>
        {!props.noButton && (
          <Box width={320}>
            <PrimaryButton
              data-testid="PrimaryButton"
              fullWidth
              href={props.buttonLink}
            >
              {intl.formatMessage({ id: props.buttonId })}
            </PrimaryButton>
          </Box>
        )}
        <Box mt={4}>
          <Typography
            align="center"
            fontSize={(theme) => theme.typography.pxToRem(18)}
            color="darkgrey"
          >
            {intl.formatMessage({ id: `common.versionLabel` })}: {APP_VERSION}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography
            align="center"
            fontSize={(theme) => theme.typography.pxToRem(18)}
            color="darkgrey"
          >
            {currentTime}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
