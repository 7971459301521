import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useMediaQuery,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  useTheme,
} from '@mui/material';
import CustomTableCell from 'src/domains/root/commons/CustomTableCell';
import AlertHistoryChartModal from 'src/domains/root/features/gateways/AlertHistoryChartModal';
import type { AlertHistory } from 'src/domains/root/features/alerts/histories/slice';
import type { OptionState } from 'src/domains/root/hooks/use-filter-option';
import AlertHistoryTableRow from './TableRow';

type Props = {
  alertHistories: AlertHistory[];
  option: OptionState;
  alertHistoriesCount: number;
};

export default function AlertHistoryTable({
  alertHistories,
  option,
  alertHistoriesCount,
}: Props) {
  const theme = useTheme();
  const intl = useIntl();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [clickedAlertHistory, setClickedAlertHistory] =
    useState<AlertHistory | null>(null);

  const hideModal = () => setClickedAlertHistory(null);

  return (
    <>
      <Paper
        sx={(theme) => ({
          boxShadow: 'unset',
          padding: '0 21px',
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
          mb: 2,
          border: '1px solid #828282',

          [theme.breakpoints.down('sm')]: {
            px: 2,
            pb: 2.5,
            border: 'none',
          },
        })}
        data-testid="AlertHistoryTable"
      >
        <TableContainer>
          <Table
            sx={(theme) => ({
              minWidth: 620,

              [theme.breakpoints.down('sm')]: {
                minWidth: 'unset',
                px: 2,
              },
            })}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell width="10%">
                  {intl.formatMessage({
                    id: 'alertHistoryTable.timestamp',
                  })}
                </StyledTableCell>
                {!isSmallScreen && (
                  <StyledTableCell width="7%">
                    {intl.formatMessage({
                      id: 'alertHistoryTable.sensorUnit.number',
                    })}
                  </StyledTableCell>
                )}
                <StyledTableCell width={isSmallScreen ? 216 : '57%'}>
                  {intl.formatMessage({
                    id: 'alertHistoryTable.sensorUnit.deviceName',
                  })}
                </StyledTableCell>
                <StyledTableCell width="15%">
                  {intl.formatMessage({
                    id: 'alertHistoryTable.alertType',
                  })}
                </StyledTableCell>
                <StyledTableCell width="15%">
                  {intl.formatMessage({
                    id: 'alertHistoryTable.event',
                  })}
                </StyledTableCell>
                {!isSmallScreen && (
                  <StyledTableCell width="15%">
                    {intl.formatMessage({
                      id: 'common.graph',
                    })}
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {alertHistories.map((alertHistory, index) => {
                return (
                  <AlertHistoryTableRow
                    key={`${alertHistory.alertId}-${index}`}
                    alertHistory={alertHistory}
                    selected={option}
                    onClickAlertChartButton={() => {
                      setClickedAlertHistory(alertHistory);
                    }}
                  />
                );
              })}
              {alertHistoriesCount === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      pb: {
                        xs: 0,
                        sm: 2,
                      },
                      textAlign: 'center',
                      borderBottom: 'none',
                    }}
                  >
                    <Typography>
                      {intl.formatMessage({
                        id: 'alertHistoryTable.emptyMessage',
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {clickedAlertHistory && (
        <AlertHistoryChartModal
          handleClose={hideModal}
          alertHistory={clickedAlertHistory}
        />
      )}
    </>
  );
}

export function StyledTableCell({
  width,
  children,
}: {
  width: string | number;
  children: React.ReactNode;
}) {
  return (
    <CustomTableCell
      width={width}
      align="center"
      sx={[
        (theme) => ({
          height: 49,
          fontWeight: 'bold',
          lineHeight: theme.typography.pxToRem(20),
          borderBottom: '1px solid #828282',
          whiteSpace: 'nowrap',

          [theme.breakpoints.down('sm')]: {
            height: 'unset',
            pt: 2,
            pb: 1.5,
            fontSize: theme.typography.pxToRem(13),
            /**
             * レイアウトがdisplay: table-cellなのでflexみたいにline-heightがピッタリしない16pxを意図している
             * display: flexに変更は難しいのでこれでやっている
             */
            lineHeight: theme.typography.pxToRem(16.5),
          },
        }),
      ]}
    >
      {children}
    </CustomTableCell>
  );
}
