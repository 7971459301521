import React from 'react';
import { Box, Container } from '@mui/material';

import { GetServiceProviderWorkspacesResponse } from 'src/apis';

import WorkspacesTable from '../features/access-workspace/WorkspacesTable';
import useGetServiceProviderWorkspaces from '../features/access-workspace/useGetServiceProviderWorkspaces';
import GuideMessage from '../features/access-workspace/GuidMessage';

export default function InternalSupport() {
  return (
    <Layout>
      <GuideMessage />
      <WithWorkspacesData>
        {({ workspaces }) => <WorkspacesTable workspaces={workspaces} />}
      </WithWorkspacesData>
    </Layout>
  );
}

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Box width="100%" height="100vh">
      <main>
        <Container maxWidth="lg">{children}</Container>
      </main>
    </Box>
  );
}

function WithWorkspacesData({
  children,
}: {
  children: (
    workspaces: GetServiceProviderWorkspacesResponse,
  ) => React.ReactNode;
}) {
  const workspacesReuslt = useGetServiceProviderWorkspaces();

  if (workspacesReuslt.status === 'loading') {
    return <div>loading...</div>;
  }

  if (workspacesReuslt.status === 'noData') {
    return <div>ワークスペースがありません。</div>;
  }

  if (workspacesReuslt.status === 'hasError') {
    return <div>エラーが発生しました。 画面を再読み込みしてください。</div>;
  }

  return <>{children({ workspaces: workspacesReuslt.data.workspaces })}</>;
}
