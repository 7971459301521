import React from 'react';
import styled from 'styled-components';
import OriginalIconButton from '@mui/material/IconButton';
import { IconButtonProps } from '@mui/material/IconButton';
import DialogCloseIcon from './CloseIcon';

const IconButton = styled(OriginalIconButton)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const CloseIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton {...props} size="large">
      <DialogCloseIcon />
    </IconButton>
  );
};

export default CloseIconButton;
