import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  TextField,
  DialogTitle,
  DialogActions,
  Alert,
  CircularProgress,
} from '@mui/material';
import { FormikProvider, Form, useFormik } from 'formik';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import Title from 'src/domains/root/commons/dialog/Title';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import useBeginAndEndTimestamp from './sensor-data/useBeginAndEndTimestamp';

import { SensorUnit } from './slice';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

type Props = {
  gatewayId: string;
  sensorUnit?: SensorUnit;
  submitResult:
    | { status: 'loading' | 'noData' | 'succeeded' | 'idle' }
    | {
        status: 'hasError';
        code: 'not_found' | 'bad_request' | 'unknown_error';
      };
  onClose: () => void;
  onSubmit: (begin: number, end: number) => Promise<void>;
};

export default function CSVDownloadDialog({
  gatewayId,
  sensorUnit,
  submitResult,
  onClose,
  onSubmit,
}: Props) {
  const intl = useIntl();

  const result = useBeginAndEndTimestamp({ gatewayId, sensorUnit });
  const location = useLocation();
  useEffect(() => {
    awsRum().then((rum) => {
      rum.recordPageView(location.pathname + '#CSVDownloadDialogRender');
    });
  }, [location]);

  return (
    <Dialog open={true} onClose={onClose} PaperProps={{ sx: { width: 600 } }}>
      <DialogTitle>
        <Title onClose={onClose}>
          {intl.formatMessage({
            id: 'sensorDataChart.contextMenu.csvDownload.dialog.title',
          })}
        </Title>
        {result.status === 'hasError' && (
          <Alert severity="error">
            {intl.formatMessage({
              id: `sensorDataChart.contextMenu.csvDownload.dialog.error.fetch.${result.code}`,
            })}
          </Alert>
        )}
        {result.status === 'noData' && (
          <Alert severity="info">
            {intl.formatMessage({
              id: 'sensorDataChart.contextMenu.csvDownload.dialog.noData',
            })}
          </Alert>
        )}
        {submitResult.status === 'succeeded' && (
          <Box pt={1.5}>
            <Alert severity="success">
              {intl.formatMessage({
                id: 'sensorDataChart.contextMenu.csvDownload.dialog.success',
              })}
            </Alert>
          </Box>
        )}
        {submitResult.status === 'noData' && (
          <Box pt={1.5}>
            <Alert severity="warning">
              {intl.formatMessage({
                id: 'sensorDataChart.contextMenu.csvDownload.dialog.noData',
              })}
            </Alert>
          </Box>
        )}
        {submitResult.status === 'hasError' && (
          <Box pt={1.5}>
            <Alert severity="error">
              {intl.formatMessage({
                id: `sensorDataChart.contextMenu.csvDownload.dialog.error.submit.${submitResult.code}`,
              })}
            </Alert>
          </Box>
        )}
      </DialogTitle>
      {result.status === 'loading' && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={100}
        >
          <CircularProgress />
        </Box>
      )}
      {result.status === 'succeeded' && (
        <CSVDownloadDialogForm
          beginOfRange={result.data.begin}
          endOfRange={result.data.end}
          loading={submitResult.status === 'loading'}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  );
}

function CSVDownloadDialogForm({
  beginOfRange,
  endOfRange,
  loading,
  onClose,
  onSubmit,
}: {
  beginOfRange: number;
  endOfRange: number;
  loading: boolean;
  onClose: () => void;
  onSubmit: (begin: number, end: number) => Promise<void>;
}) {
  const intl = useIntl();

  const _beginOfRange = fromUnixMs(beginOfRange);
  const _endOfRange = fromUnixMs(endOfRange);

  const validationSchema = yup.object().shape({
    begin: yup
      .date()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .min(
        toYmd(_beginOfRange),
        intl.formatMessage(
          {
            id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.validation.gte',
          },
          { date: toYmd(_beginOfRange) },
        ),
      )
      .max(
        yup.ref('end'),
        intl.formatMessage(
          {
            id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.validation.lte',
          },
          {
            date: intl.formatMessage({
              id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.end.label',
            }),
          },
        ),
      ),
    end: yup
      .date()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .min(
        yup.ref('begin'),
        intl.formatMessage(
          {
            id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.validation.gte',
          },
          {
            date: intl.formatMessage({
              id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.begin.label',
            }),
          },
        ),
      )
      .max(
        toYmd(_endOfRange),
        intl.formatMessage(
          {
            id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.validation.lte',
          },
          { date: toYmd(_endOfRange) },
        ),
      ),
  });

  const formik = useFormik({
    initialValues: {
      begin: toYmd(max(_beginOfRange, _endOfRange.minus({ month: 3 }))),
      end: toYmd(_endOfRange),
    },
    validationSchema,
    onSubmit: async ({ begin, end }) =>
      onSubmit(toUnixMs(fromYmd(begin)), toUnixMs(fromYmd(end).endOf('day'))),
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  return (
    <FormikProvider value={formik}>
      <DialogContent dividers>
        <Box pt={3.5} pb={2.5}>
          <Typography>
            {intl.formatMessage({
              id: 'sensorDataChart.contextMenu.csvDownload.dialog.description',
            })}
          </Typography>
          <Box
            pt={3.5}
            px={2}
            display="grid"
            gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
            gap={3}
          >
            <TextField
              type="date"
              name="begin"
              value={formik.values.begin}
              size="small"
              label={intl.formatMessage({
                id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.begin.label',
              })}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  min: toYmd(_beginOfRange),
                  max: toYmd(_endOfRange),
                },
                sx: { pr: 1 },
              }}
              helperText={formik.errors.begin}
              error={!!formik.errors.begin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              type="date"
              name="end"
              value={formik.values.end}
              size="small"
              label={intl.formatMessage({
                id: 'sensorDataChart.contextMenu.csvDownload.dialog.dateInput.end.label',
              })}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  min: toYmd(_beginOfRange),
                  max: toYmd(_endOfRange),
                },
                sx: { pr: 1 },
              }}
              helperText={formik.errors.end}
              error={!!formik.errors.end}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          {loading && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={{ xs: 3, sm: 4.5 }}
            >
              <CircularProgress size={24} />
              <Box pt={2.5}>
                <Typography>
                  {intl.formatMessage({
                    id: 'sensorDataChart.contextMenu.csvDownload.dialog.loading',
                  })}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <Form>
        <DialogActions>
          <Box
            py={3}
            px={2.5}
            display="grid"
            gridAutoFlow={{ xs: 'row', sm: 'column' }}
            rowGap={2}
            columnGap={3}
            width="100%"
            justifyContent={{ xs: 'stretch', sm: 'end' }}
          >
            <CancelButton onClick={onClose} sx={{ order: { xs: 1, sm: 0 } }} />
            <SubmitButton
              sx={{
                order: {
                  xs: 0,
                  sm: 1,
                },
                minWidth: 140,
              }}
            >
              {intl.formatMessage({
                id: 'sensorDataChart.contextMenu.csvDownload.dialog.actions.submit',
              })}
            </SubmitButton>
          </Box>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

function fromYmd(date: string): DateTime {
  return DateTime.fromFormat(date, DEFAULT_DATE_FORMAT).setZone('Asia/Tokyo');
}
function toYmd(date: DateTime): string {
  return date.toFormat(DEFAULT_DATE_FORMAT);
}
function fromUnixMs(unixMs: number): DateTime {
  return DateTime.fromMillis(unixMs).setZone('Asia/Tokyo');
}
function toUnixMs(date: DateTime): number {
  return date.toMillis();
}
function max(date1: DateTime, date2: DateTime): DateTime {
  return date1.diff(date2).milliseconds > 0 ? date1 : date2;
}
