/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import { compareChartColor } from './chart-utils';

/*
 * 用途に応じたグラフデータ設定を取得する
 */
export const getGatewaySeriesOptions = (
  gateway: any,
  data: any[],
  type: string,
): { series: Highcharts.SeriesOptionsType[] } => {
  if (data.length > 0) {
    const lines = data.map((chartData, idx) => {
      return createLineSeries(
        gateway.sensorUnits[idx].name,
        chartData[type] || [],
        0,
        idx,
      );
    });

    return {
      series: lines,
    };
  }

  return {
    series: [],
  };
};

/*
 * 線グラフの設定
 */
const createLineSeries = (
  key: string,
  data: [number, number][],
  yAxisIndex: number,
  zIndexNumber: number,
): Highcharts.SeriesLineOptions => {
  return {
    name: key,
    data: data,
    type: 'line',
    yAxis: yAxisIndex,
    color: compareChartColor[zIndexNumber],
    zIndex: data?.length - zIndexNumber || 0,
    marker: {
      enabled: true,
      symbol: 'circle',
    },
    showInNavigator: true,
  };
};
