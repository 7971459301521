import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { ThemeProvider } from 'styled-components';

import theme from './styles/theme';

export default function createProviders() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
        gcTime: 0,
      },
    },
  });

  function Providers({ children }: { children: React.ReactNode }) {
    return (
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <QueryErrorResetBoundary>{children}</QueryErrorResetBoundary>
            </QueryClientProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    );
  }

  return Providers;
}
