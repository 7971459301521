import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'src/domains/root/store';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getAreasSilently } from 'src/domains/root/features/areas/slice';

export default function Initializer({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.key]);

  useEffect(() => {
    const token = getAccessTokenSilently();
    const promise = dispatch(getAreasSilently({ token }));

    return () => promise.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
}
