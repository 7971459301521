import React from 'react';
import {
  PowerSupply,
  SensorUnitBattery,
} from 'src/domains/root/features/gateways/slice';
import BatteryEmptyIcon from './BatteryEmptyIcon';
import BatteryLowIcon from './BatteryLowIcon';
import BatteryHighIcon from './BatteryHighIcon';
import BatteryChargingIcon from './BatteryFullIcon';
import BatteryUnknownIcon from './BatteryUnknownIcon';
import BatteryUSBIcon from './BatteryUSBIcon';

const BatteryIconMapping: Record<
  Extract<PowerSupply, 'usb'> | SensorUnitBattery,
  React.ReactElement
> = {
  usb: <BatteryUSBIcon />,
  empty: <BatteryEmptyIcon />,
  low: <BatteryLowIcon />,
  high: <BatteryHighIcon />,
  full: <BatteryChargingIcon />,
  unknown: <BatteryUnknownIcon />,
};

interface Props {
  powerSupply: PowerSupply | undefined;
  battery: SensorUnitBattery | undefined;
}

const BatteryIcon: React.FC<Props> = (props) => {
  const { powerSupply, battery } = props;

  /**
   * @see https://github.com/Nitto-CABIoT/cabiot-docs/wiki/Battery%E3%81%A8PowerSupply%E3%81%AE%E4%BB%95%E6%A7%98
   */
  switch (powerSupply) {
    case 'usb':
      switch (battery) {
        case 'empty':
        case 'low':
        case 'high':
        case 'full':
          return BatteryIconMapping['usb'];
        case 'unknown':
          return BatteryIconMapping['usb'];
        case undefined:
          return BatteryIconMapping['usb'];
        default:
          return BatteryIconMapping['unknown'];
      }
    case 'battery':
      switch (battery) {
        case 'empty':
        case 'low':
        case 'high':
        case 'full':
          return BatteryIconMapping[battery];
        case 'unknown':
          return BatteryIconMapping['unknown'];
        case undefined:
          return BatteryIconMapping['unknown'];
        default:
          return BatteryIconMapping['unknown'];
      }
    case 'unknown':
      switch (battery) {
        case 'empty':
        case 'low':
        case 'high':
        case 'full':
          return BatteryIconMapping['unknown'];
        case 'unknown':
          return BatteryIconMapping['unknown'];
        case undefined:
          return BatteryIconMapping['unknown'];
        default:
          return BatteryIconMapping['unknown'];
      }
    case undefined:
      switch (battery) {
        case 'empty':
        case 'low':
        case 'high':
        case 'full':
          return BatteryIconMapping[battery];
        case 'unknown':
          return BatteryIconMapping['unknown'];
        case undefined:
          return BatteryIconMapping['unknown'];
        default:
          return BatteryIconMapping['unknown'];
      }
    default:
      return BatteryIconMapping['unknown'];
  }
};

export default BatteryIcon;
