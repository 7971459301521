/*
 * 各チャートデータのデータ構造
 */
export interface ChartData {
  temperature?: [number, number][];
  humidity?: [number, number][];
  objectTemperature?: [number, number][];
  condensationAlertTemperature?: [number, number][];
  objectTemperatureAlertCount?: [number, number][];
}

export type CompareChartDataType =
  | 'temperature'
  | 'humidity'
  | 'objectTemperature';

export interface CompareChartData {
  sensorData: ChartData;
}

/*
 * チャートの種類
 */
export type ChartKind =
  | 'temperature'
  | 'humidity'
  | 'objectTemperature'
  | 'condensationAlertTemperature'
  | 'objectTemperatureAlertCount'
  | 'weatherIcons';

/*
 * 用途の種類
 */
export type ObserveMode =
  | 'temperatureHumidityObserve'
  | 'condensationObserve'
  | 'connectorTemperatureObserve';

/*
 * ChartKindごとの色設定
 */
export interface ChartColor {
  temperature: string;
  objectTemperature: string;
  humidity: string;
  condensationAlertTemperature: string;
  objectTemperatureAlertCount: string;
}

export const chartColor: ChartColor = {
  temperature: '#dea72d',
  objectTemperature: '#ac6258',
  humidity: '#2a4f64',
  condensationAlertTemperature: '#52ac97',
  objectTemperatureAlertCount: '#52ac97',
};

/*
 * 対象オブジェクトから指定のkeyのデータを取得する
 */
export const getValue = <T, U extends keyof T>(obj: T, key: U): T[U] => {
  return obj[key];
};

/*
 *  グラフ比較チャート用のカラー
 */
export const compareChartColor = [
  '#F55A82',
  '#5AD264',
  '#78B9E6',
  '#5A5A64',
  '#FA9646',
  '#A06EF0',
];
