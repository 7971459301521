import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import {
  getAlertHistories,
  alertHistoriesSelector,
  errorSelector,
  statusSelector,
} from 'src/domains/root/features/alerts/histories/slice';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import useAlertHistoriesDropdown from 'src/domains/root/hooks/use-alert-histories-dropdown';
import useFilterOption from 'src/domains/root/hooks/use-filter-option';
import useAlertHistoriesCount from 'src/domains/root/hooks/use-alert-histories-count';
import HookStatus from 'src/domains/root/commons/HookStatus';
import AlertHistory from '.';

const AlertHistoryContainer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const alertHistories = useSelector(alertHistoriesSelector);
  const error = useSelector(errorSelector);
  const status = useSelector(statusSelector);

  const { sensorUnitNames, gatewayNames, sensorUnitDropdown, gatewayDropdown } =
    useAlertHistoriesDropdown(alertHistories);

  const [option, updateOption] = useFilterOption();
  const alertHistoriesCount = useAlertHistoriesCount(alertHistories, option);

  useEffect(() => {
    if (!workspace?.workspaceId) return;

    const token = getAccessTokenSilently();

    const promise = dispatch(
      getAlertHistories({ token, workspaceId: workspace.workspaceId }),
    );

    return () => {
      promise.abort();
    };
  }, [getAccessTokenSilently, dispatch, workspace.workspaceId]);

  const _alertHistoryProps = {
    gatewayNames,
    sensorUnitNames,
    gatewayDropdown,
    sensorUnitDropdown,
    alertHistories,
    option,
    updateOption,
    alertHistoriesCount,
  };

  const loading = status === 'loading';
  const noData = !alertHistories.length;

  if (loading || !!error || noData) {
    return (
      <HookStatus
        target="alertHistoryTable"
        loading={loading}
        error={!!error}
        noData={noData}
      />
    );
  }

  return <AlertHistory {..._alertHistoryProps} />;
};

export default AlertHistoryContainer;
