import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import withRepeatedTokenExpirationBreaker from 'src/utils/withRepeatedTokenExpirationBreaker';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import InvitationRequired from './routes/InvitationRequired';
import SigninErrorHandler from './routes/SigninErrorHandler';
import SignupErrorHandler from './routes/SignupErrorHandler';
import { Invitation } from './features/invitations/slice';
import UnrecoverableError from './pages/errors/UnrecoverableError';
import OverlayManager from './routes/OverlayManager';

const toSignupPage = (invitation: Invitation) => {
  return {
    pathname: '/invitation/signup',
    search: `?invitation_id=${invitation.invitationId}`,
  };
};

const toSigninPage = (invitation: Invitation) => {
  return {
    pathname: '/invitation/signin',
    search: `?invitation_id=${invitation.invitationId}`,
  };
};

const toSigninPageOptions = (invitation: Invitation) => {
  return {
    appState: {
      returnTo: '/invitation/signin',
      search: `?invitation_id=${invitation.invitationId}`,
    },
  };
};

const withSigninPageOptions = (invitation: Invitation) => {
  return {
    returnTo: '/invitation/signin',
    loginOptions: {
      appState: {
        search: `?invitation_id=${invitation.invitationId}`,
      },
    },
  };
};

const invitationErrorMapping = {
  invitation_required: '/invitation/errors/invalid-invitation',
  invalid_invitation: '/invitation/errors/invalid-invitation',
  used_invitation: '/invitation/errors/used-invitation',
  expired_invitation: '/invitation/errors/expired-invitation',
  emergency_maintenance: '/invitation/errors/emergency-maintenance',
};

const _Routes: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const intl = useIntl();

  return (
    <OverlayManager>
      <Routes>
        <Route path="errors/*">
          <Route
            path="invalid-invitation"
            element={
              <UnrecoverableError messageId="invitations.error.invalid_invitation" />
            }
          />
          <Route
            path="used-invitation"
            element={
              <UnrecoverableError messageId="invitations.error.used_invitation" />
            }
          />
          <Route
            path="expired-invitation"
            element={
              <UnrecoverableError messageId="invitations.error.expired_invitation" />
            }
          />
          <Route
            path="emergency-maintenance"
            element={
              <UnrecoverableError
                noButton
                message={intl.formatMessage(
                  { id: 'invitations.error.emergency_maintenance' },
                  { br: <br /> },
                )}
              />
            }
          />
        </Route>
        <Route
          path="entry"
          element={
            <InvitationRequired redirectTo={invitationErrorMapping}>
              {(invitation) =>
                !invitation.isExistUser ? (
                  <Navigate to={toSignupPage(invitation)} replace={true} />
                ) : (
                  <Navigate to={toSigninPage(invitation)} replace={true} />
                )
              }
            </InvitationRequired>
          }
        />
        <Route
          path="signup"
          element={
            <InvitationRequired redirectTo={invitationErrorMapping}>
              {(invitation) =>
                !invitation.isExistUser ? (
                  <SignupErrorHandler
                    redirectTo={{
                      ...invitationErrorMapping,
                      duplicate_user: () => {
                        loginWithRedirect(toSigninPageOptions(invitation));
                      },
                    }}
                  >
                    <Signup />
                  </SignupErrorHandler>
                ) : (
                  <Navigate to={toSigninPage(invitation)} />
                )
              }
            </InvitationRequired>
          }
        />
        <Route
          path="signin"
          element={
            <InvitationRequired redirectTo={invitationErrorMapping}>
              {(invitation) =>
                !invitation.isExistUser ? (
                  <Navigate to={toSignupPage(invitation)} />
                ) : (
                  <SigninErrorHandler redirectTo={invitationErrorMapping}>
                    {React.createElement(
                      withRepeatedTokenExpirationBreaker(
                        withAuthenticationRequired(
                          Signin,
                          withSigninPageOptions(invitation),
                        ),
                      ),
                      { invitation },
                    )}
                  </SigninErrorHandler>
                )
              }
            </InvitationRequired>
          }
        />
      </Routes>
    </OverlayManager>
  );
};

export default _Routes;
