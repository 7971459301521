import { TextField, type TextFieldProps } from '@mui/material';

type Props = TextFieldProps;

export function InputField({
  error,
  variant = 'outlined',
  size = 'small',
  sx = [],
  ...rest
}: Props) {
  return (
    <TextField
      variant={variant}
      size={size}
      error={error}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      sx={[
        {
          '& .MuiFormHelperText-root': {
            fontWeight: error ? 'bold' : 'normal',
            mx: 0,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  );
}
