import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SubTtitle: React.FC = () => {
  const intl = useIntl();

  return (
    <Box display="flex">
      <Box flex="1 1 auto">
        <Typography
          fontWeight={700}
          fontSize={(theme) => theme.typography.pxToRem(20)}
          lineHeight={1.75}
        >
          {intl.formatMessage({
            id: 'pages.Setup.Gateway.new.registerGateway',
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default SubTtitle;
