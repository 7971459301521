import React from 'react';
import {
  Box,
  Alert,
  Typography,
  CircularProgress,
  Fab,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { KeyboardArrowDownRounded, Add } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { css } from 'styled-components';
import { Memo } from 'src/apis';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import HookStatus from 'src/domains/root/commons/HookStatus';
import SettingIcon from 'src/domains/root/commons/layout/drawer/icons/SettingIcon';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import ConfirmDialog from 'src/domains/root/commons/dialog/ConfirmDialog';
import MemoDialog from './MemoDialog';
import { useDeleteGatewaysMemos } from './useDeleteGatewaysMemos';
import { useGetGatewaysMemos } from './useGetGatewaysMemos';
import { usePostGatewaysMemos } from './usePostGatewaysMemos';
import { usePutGatewaysMemos } from './usePutGatewaysMemos';
import BiggerImage from './BiggerImage';
import MemoImage from './MemoImage';

export default function MemoList({ gatewayId }: { gatewayId: string }) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);

  const resultOfGet = useGetGatewaysMemos(gatewayId);
  const resultOfPost = usePostGatewaysMemos(gatewayId, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: Keys.gateway.memos(gatewayId),
      });
      setDialogOpen(false);
    },
    nuetralizeResults,
  });
  const resultOfPut = usePutGatewaysMemos(gatewayId, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: Keys.gateway.memos(gatewayId),
      });
    },
    nuetralizeResults,
  });
  const resultOfDelete = useDeleteGatewaysMemos(gatewayId, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: Keys.gateway.memos(gatewayId),
      });
    },
    nuetralizeResults,
  });

  if (resultOfGet.status === 'loading') {
    return <HookStatus target="sensorDataChart" error={false} loading={true} />;
  }
  if (resultOfGet.status === 'hasError') {
    return <RedirectionForGetError errorCode={resultOfGet.errorCode} />;
  }
  if (resultOfPost.status === 'hasError') {
    return <RedirectionForPostError errorCode={resultOfPost.errorCode} />;
  }
  if (resultOfPut.status === 'hasError') {
    return <RedirectionForPutError errorCode={resultOfPut.errorCode} />;
  }
  if (resultOfDelete.status === 'hasError') {
    return <RedirectionForDeleteError errorCode={resultOfDelete.errorCode} />;
  }

  const { gatewayName, memos } = resultOfGet.data;

  function nuetralizeResults() {
    resultOfPost.reset();
    resultOfPut.reset();
    resultOfDelete.reset();
  }

  const handleAddMemoDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleAddMemoDialogClose = () => {
    resultOfPost.reset();
    setDialogOpen(false);
  };

  return (
    <Box
      bgcolor="white"
      px={{ xs: 2, sm: 3 }}
      py={{ xs: 2.5, sm: 3 }}
      mb={2.5}
      display="flex"
      flexDirection="column"
      gap={4}
      data-testid="MemoList"
    >
      {resultOfPost.status === 'succeeded' && (
        <Alert severity="success">
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.message.successPost',
          })}
        </Alert>
      )}
      {resultOfPut.status === 'succeeded' && (
        <Alert severity="success">
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.message.successPut',
          })}
        </Alert>
      )}
      {resultOfDelete.status === 'succeeded' && (
        <Alert severity="success">
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.message.successDelete',
          })}
        </Alert>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          px={1.5}
          borderLeft={(props) => `5px solid ${props.palette.nito.main}`}
        >
          <Typography
            fontSize={{ xs: 16, sm: 18 }}
            fontWeight={700}
            sx={{ wordBreak: 'break-all' }}
          >
            {intl.formatMessage({
              id: 'pages.GatewaysMemos.memoList.table.title',
            })}
            ：{gatewayName}
          </Typography>
        </Box>
        <NewMemoButton handleAddMemoDialogOpen={handleAddMemoDialogOpen} />
      </Box>
      <MemoListTable
        memos={memos}
        emphasisMemoId={resultOfPost.data?.memoId}
        editMemo={resultOfPut}
        deleteMemo={resultOfDelete}
      />
      {resultOfGet.hasNextPage && (
        <Box textAlign="center">
          <SecondaryButton
            sx={{ width: 174, height: 48 }}
            onClick={resultOfGet.fetchNextPage}
          >
            {resultOfGet.isFetchingNextPage ? (
              <CircularProgress color="inherit" size={28} />
            ) : (
              <Box display="flex" py={0.5}>
                <KeyboardArrowDownRounded />
                <Typography fontSize={18} fontWeight={700} pl={0.75} pr={1}>
                  {intl.formatMessage({
                    id: 'pages.GatewaysMemos.memoList.loadMoreButton',
                  })}
                </Typography>
              </Box>
            )}
          </SecondaryButton>
        </Box>
      )}
      <MemoDialog
        open={dialogOpen}
        title={intl.formatMessage({
          id: 'pages.GatewaysMemos.memoList.memoDialog.addTitle',
        })}
        initialValues={{ content: '', images: [] }}
        submitting={resultOfPost.status === 'loading'}
        onSubmit={resultOfPost.mutate}
        onClose={handleAddMemoDialogClose}
      />
    </Box>
  );
}

function NewMemoButton({
  handleAddMemoDialogOpen,
}: {
  handleAddMemoDialogOpen: () => void;
}) {
  const intl = useIntl();
  const isSmallScreen = useSmallScreen();

  return isSmallScreen ? (
    <Fab
      onClick={handleAddMemoDialogOpen}
      sx={{
        position: 'fixed',
        bottom: 28,
        right: 16,
        width: 72,
        height: 72,
        pb: 1,
        bgcolor: (theme) => theme.palette.primary.main,
        color: 'white',
        zIndex: 100,
        '&:hover': {
          bgcolor: (theme) => theme.palette.nito.main,
        },
      }}
      data-testid="NewMemoButton"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Add sx={{ fontSize: 28 }} />
        <Typography fontWeight={700} fontSize={14} mt={-0.25}>
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.memoList.newMemoButton',
          })}
        </Typography>
      </Box>
    </Fab>
  ) : (
    <PrimaryButton
      onClick={handleAddMemoDialogOpen}
      data-testid="NewMemoButton"
    >
      {intl.formatMessage({
        id: 'pages.GatewaysMemos.memoList.newMemoButton',
      })}
    </PrimaryButton>
  );
}

function MemoListTable({
  memos,
  emphasisMemoId,
  editMemo,
  deleteMemo,
}: {
  memos: Memo[];
  emphasisMemoId?: string;
  editMemo: ReturnType<typeof usePutGatewaysMemos>;
  deleteMemo: ReturnType<typeof useDeleteGatewaysMemos>;
}) {
  const intl = useIntl();

  return (
    <TableContainer>
      <Table>
        <MemoListTableHead />
        {memos.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography fontSize={14} textAlign="center" pl={2}>
                  {intl.formatMessage({
                    id: 'pages.GatewaysMemos.memoList.table.noData',
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {memos.map((memo: Memo) => (
              <MemoListTableRow
                memo={memo}
                emphasis={memo.memoId === emphasisMemoId}
                editMemo={editMemo}
                deleteMemo={deleteMemo}
                key={memo.memoId}
              />
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

function MemoListTableHead() {
  const intl = useIntl();

  return (
    <TableHead>
      <TableRow sx={{ borderBottom: '2px solid #828282' }}>
        <TableCell
          align="left"
          sx={{
            pr: 0,
            fontWeight: 700,
            fontSize: { xs: 14, sm: 15 },
            width: 80,
          }}
        >
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.memoList.table.head.createdAt',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            fontWeight: 700,
            fontSize: { xs: 14, sm: 15 },
          }}
        >
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.memoList.table.head.memo',
          })}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            pl: 0,
            fontWeight: 700,
            fontSize: { xs: 14, sm: 15 },
            width: 60,
          }}
        >
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.memoList.table.head.setting',
          })}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

/**
 * 最初の要素に対してアニメーションを適用する。
 * 背景をピンク色にして強調したあと、白色に戻す。
 */
const rowEmphasisStyle = css`
  & {
    animation-name: fadeEmphasis;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-fill-mode: backwards;
  }
  @keyframes fadeEmphasis {
    0% {
      background-color: ${(props) => props.theme.palette.primary.light};
    }
    100% {
      background-color: 'white';
    }
  }
`;

function MemoListTableRow({
  memo,
  emphasis,
  editMemo,
  deleteMemo,
}: {
  memo: Memo;
  emphasis: boolean;
  editMemo: ReturnType<typeof usePutGatewaysMemos>;
  deleteMemo: ReturnType<typeof useDeleteGatewaysMemos>;
}) {
  const intl = useIntl();

  const [editMemoDialogOpen, setEditMemoDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteMemoDialogOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const hanldeMemoMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMemoMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEditMemoDialogOpen = () => {
    setEditMemoDialogOpen(true);
    handleMemoMenuClose();
  };

  const handleEditMemoDialogClose = () => {
    setEditMemoDialogOpen(false);
  };

  const handleEditMemo = async (values: {
    content: string;
    images: Memo['images'];
  }) => {
    const result = await editMemo.mutate({
      memoId: memo.memoId,
      content: values.content,
      images: values.images,
    });

    handleEditMemoDialogClose();

    return result;
  };

  const handleDeleteMemoDialogOpen = () => {
    setDeleteMemoDialogOpen(true);
    handleMemoMenuClose();
  };

  const handleDeleteMemoDialogClose = () => {
    setDeleteMemoDialogOpen(false);
  };

  const handleDeleteMemo = async () => {
    await deleteMemo.mutate(memo.memoId);

    handleDeleteMemoDialogClose();
  };

  return (
    <TableRow key={memo.memoId} css={emphasis ? rowEmphasisStyle : undefined}>
      <TableCell sx={{ pl: 2.5, pr: 1 }}>
        <Typography fontSize={{ xs: 13, sm: 14 }}>
          {DateTime.fromMillis(memo.timestamp).toFormat('yyyy/MM/dd')}
          <br />
          {DateTime.fromMillis(memo.timestamp).toFormat('HH:mm')}
        </Typography>
      </TableCell>
      <TableCell
        sx={{ pr: 0, wordBreak: 'break-all', fontSize: { xs: 14, sm: 16 } }}
      >
        <pre style={{ whiteSpace: 'pre-wrap' }}>
          {memo.content.trim().length !== 0 ? (
            <Typography>{memo.content}</Typography>
          ) : (
            <Typography color="lightslategray">
              {intl.formatMessage({
                id: 'pages.GatewaysMemos.memoList.table.noContent',
              })}
            </Typography>
          )}
        </pre>
        <MemoImages memo={memo} />
      </TableCell>
      <TableCell align="right" sx={{ p: 1 }}>
        <IconButton onClick={hanldeMemoMenuOpen} data-testid="MemoMenu">
          <SettingIcon />
        </IconButton>
      </TableCell>
      <MemoMenu
        anchorEl={menuAnchorEl}
        closeMenu={handleMemoMenuClose}
        openEditMemoDialog={handleEditMemoDialogOpen}
        openDeleteMemoDialog={handleDeleteMemoDialogOpen}
      />
      <MemoDialog
        open={editMemoDialogOpen}
        title={intl.formatMessage({
          id: 'pages.GatewaysMemos.memoList.memoDialog.editTitle',
        })}
        initialValues={{ content: memo.content, images: memo.images }}
        submitting={editMemo.status === 'loading'}
        onSubmit={handleEditMemo}
        onClose={handleEditMemoDialogClose}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        title={intl.formatMessage({
          id: 'pages.GatewaysMemos.memoList.memoDialog.deleteTitle',
        })}
        content={
          <Box display="flex" flexDirection="column" rowGap={3}>
            <Typography>
              {intl.formatMessage({
                id: 'pages.GatewaysMemos.memoList.memoDialog.deleteDescriptions.description',
              })}
            </Typography>
            <Typography fontWeight={700}>
              {intl.formatMessage({
                id: 'pages.GatewaysMemos.memoList.memoDialog.deleteDescriptions.reConfirm',
              })}
            </Typography>
          </Box>
        }
        isConfirming={deleteMemo.status === 'loading'}
        confirm={handleDeleteMemo}
        close={handleDeleteMemoDialogClose}
      />
    </TableRow>
  );
}

function MemoImages({ memo }: { memo: Memo }) {
  const [showBiggerImage, setShowBiggerImage] = useState<number | null>(null);

  const toggleBiggerImage = (selectedImage: number | null) => {
    if (showBiggerImage !== null) {
      setShowBiggerImage(null);
      return;
    }

    setShowBiggerImage(selectedImage);
    return;
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {memo.images.map((image, index) => (
          <MemoImage
            key={image.fileId}
            src={image.url}
            alt={image.name}
            width={{ xs: 125, sm: 180 }}
            height={{ xs: 125, sm: 180 }}
            onClick={() => toggleBiggerImage(index)}
          />
        ))}
      </Box>
      {showBiggerImage !== null && (
        <BiggerImage
          images={memo.images}
          selectedImage={showBiggerImage}
          closeImage={() => toggleBiggerImage(null)}
        />
      )}
    </>
  );
}

function MemoMenu({
  anchorEl,
  closeMenu,
  openEditMemoDialog,
  openDeleteMemoDialog,
}: {
  anchorEl: HTMLElement | null;
  closeMenu: () => void;
  openEditMemoDialog: () => void;
  openDeleteMemoDialog: () => void;
}) {
  const intl = useIntl();

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closeMenu}
      sx={{
        '& .MuiMenu-paper': {
          borderRadius: 1,
          boxShadow: '0px 3px 12px 0px #00000066',
          width: 100,

          '& > ul': {
            py: 1.5,
            px: 1,

            li: {
              borderRadius: 1,

              '&:hover, &:focus-visible': {
                bgcolor: 'primary.light',
              },
            },
          },
        },
      }}
    >
      <MenuItem onClick={openEditMemoDialog}>
        <Typography>
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.memoList.memoMenu.edit',
          })}
        </Typography>
      </MenuItem>
      <MenuItem onClick={openDeleteMemoDialog}>
        <Typography>
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.memoList.memoMenu.delete',
          })}
        </Typography>
      </MenuItem>
    </Menu>
  );
}

function RedirectionForGetError(props: { errorCode: string }) {
  switch (props.errorCode) {
    case 'not_found':
      return <Navigate to="/" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}

function RedirectionForPostError(props: { errorCode: string }) {
  switch (props.errorCode) {
    case 'not_found':
      return <Navigate to="/" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'bad_request':
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}

function RedirectionForPutError(props: { errorCode: string }) {
  switch (props.errorCode) {
    case 'not_found':
      return <Navigate to="/" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'bad_request':
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}

function RedirectionForDeleteError(props: { errorCode: string }) {
  switch (props.errorCode) {
    case 'not_found':
      return <Navigate to="/" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'bad_request':
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}
