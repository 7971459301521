import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountSettingDialog from 'src/domains/root/features/users/account-setting/Dialog';
import ProfileIcon from './icons/ProfileIcon';

interface EmailChangeMenuItemProps {
  currentEmail?: string;
}

const AccountSettingMenuItem: React.FC<EmailChangeMenuItemProps> = (props) => {
  const intl = useIntl();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { currentEmail } = props;

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  return (
    <>
      <MenuItem
        data-testid="AccountSettingMenuItem"
        sx={{ height: 44 }}
        onClick={handleOpen}
      >
        <ListItemIcon>
          <ProfileIcon color="primary" />
        </ListItemIcon>
        <ListItemText>
          {intl.formatMessage({
            id: `globalHeader.menu.accountSetting.title`,
          })}
        </ListItemText>
      </MenuItem>
      {currentEmail && dialogOpen && (
        <AccountSettingDialog
          open={dialogOpen}
          currentEmail={currentEmail}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default AccountSettingMenuItem;
