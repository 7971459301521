import React from 'react';
import { useSelector } from '../store';
import LoadingOverlay from '../commons/LoadingOverlay';
import { loadingSelector as invitationsLoadingSelector } from '../features/invitations/slice';
import { loadingSelector as invitationsUsersLoadingSelector } from '../features/invitations/users/slice';

export default function OverlayManager({
  children,
}: {
  children: React.ReactNode;
}) {
  const invitationsLoading = useSelector(invitationsLoadingSelector);
  const invitationsUsersLoading = useSelector(invitationsUsersLoadingSelector);

  const isOpen = [invitationsLoading, invitationsUsersLoading].some(
    (loading) => loading,
  );

  return (
    <>
      <LoadingOverlay open={isOpen} />
      {children}
    </>
  );
}
