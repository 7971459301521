import { createSvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

const _MailIcon = createSvgIcon(
  <path
    d="M41 4.51709C41 3.31908 40.5227 2.17014 39.6732 1.32302C38.8237 0.475906 37.6714 0 36.47 0H4.54001C3.33766 -2.90837e-06 2.1844 0.475582 1.33327 1.32242C0.482139 2.16925 0.00264835 3.31817 0 4.51709V23.463C0.00325096 24.1106 0.147047 24.7498 0.421509 25.3368C0.695971 25.9237 1.09459 26.4445 1.59 26.8632C1.59 26.8632 1.59 26.8632 1.59 26.9231L1.72 27.0028C2.50733 27.6384 3.48706 27.9898 4.5 28H36.43C37.3556 27.9997 38.2592 27.718 39.02 27.1923C39.1829 27.1467 39.3334 27.0649 39.46 26.953C39.5642 26.8492 39.6489 26.7276 39.71 26.594C40.5098 25.7582 40.9573 24.6481 40.96 23.4929L41 4.51709ZM27.9 13.2023L38.69 3.67949C38.7901 3.94777 38.8441 4.23092 38.85 4.51709V23.463C38.8463 23.808 38.7679 24.1481 38.62 24.4601L27.9 13.2023ZM36.47 2.15384C36.6726 2.15453 36.8743 2.18134 37.07 2.23362L20.87 16.4829C20.7478 16.58 20.5962 16.6328 20.44 16.6328C20.2838 16.6328 20.1322 16.58 20.01 16.4829L3.85001 2.25356C4.07276 2.18091 4.30564 2.14389 4.54001 2.14388L36.47 2.15384ZM2.47 24.4801C2.29297 24.1521 2.2002 23.7855 2.2 23.4131V4.51709C2.20081 4.26229 2.24476 4.00944 2.33 3.76923L13.08 13.312L2.47 24.4801ZM4.58 25.7764C4.46 25.7764 4.35001 25.7764 4.24001 25.7764L14.78 14.8077L18.62 18.198C19.1373 18.6423 19.7973 18.8867 20.48 18.8867C21.1627 18.8867 21.8227 18.6423 22.34 18.198L26.34 14.688L36.98 25.7863C36.8304 25.8021 36.6796 25.8021 36.53 25.7863L4.58 25.7764Z"
    fill="#141414"
  />,
  'Mail',
);

const MailIcon = styled(_MailIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 41 28',
}))`
  fill: none;
  height: 28px;
  width: 41px;
`;

export default MailIcon;
