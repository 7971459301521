import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  BreadcrumbsV3,
  BreadcrumbTitleV3,
} from 'src/domains/root/commons/breadcrumbs';

export default function BreadCrumb() {
  const intl = useIntl();

  return (
    <Box
      pl={{
        xs: 2,
        sm: 0,
      }}
    >
      <BreadcrumbsV3>
        <Link to="/">
          <BreadcrumbTitleV3 color="primary">
            {intl.formatMessage({
              id: 'pages.Home.title',
            })}
          </BreadcrumbTitleV3>
        </Link>
        <BreadcrumbTitleV3>
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.title',
          })}
        </BreadcrumbTitleV3>
      </BreadcrumbsV3>
    </Box>
  );
}
