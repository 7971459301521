import React from 'react';
import { useSelector } from '../store';
import LoadingOverlay from '../commons/LoadingOverlay';
import {
  newResultSelector as registrationsNewResultSelector,
  showResultSelector as registrationsShowResultSelector,
} from '../features/registrations/slice';
import { newResultSelector as registrationsUsersNewResultSelector } from '../features/registrations/users/slice';

export default function OverlayManager({
  children,
}: {
  children: React.ReactNode;
}) {
  const isLoading = [
    useSelector(registrationsNewResultSelector),
    useSelector(registrationsShowResultSelector),
    useSelector(registrationsUsersNewResultSelector),
  ].some((result) => result.status === 'loading');

  return (
    <>
      <LoadingOverlay open={isLoading} />
      {children}
    </>
  );
}
