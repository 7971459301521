import React from 'react';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';

interface RefreshButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const RefreshButton: React.FC<RefreshButtonProps> = (props) => {
  const { onClick } = props;
  const intl = useIntl();

  return (
    <Button
      size="small"
      aria-label="refresh"
      variant="contained"
      color="inherit"
      onClick={onClick}
    >
      {intl.formatMessage({
        id: 'common.reload',
      })}
    </Button>
  );
};

export default RefreshButton;
