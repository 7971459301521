import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { default as _Menu } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccessControl from 'src/domains/root/commons/AccessControl';

const SettingMenu = styled(_Menu)`
  & .MuiPopover-paper {
    border-radius: 4px;
    box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.4);

    & > ul {
      padding-bottom: ${(props) => props.theme.spacing(1.5)};
      padding-left: ${(props) => props.theme.spacing(1)};
      padding-right: ${(props) => props.theme.spacing(1)};
      padding-top: ${(props) => props.theme.spacing(1.5)};
      width: 302px;

      li {
        border-radius: 4px;

        &:hover {
          background: ${(props) => props.theme.palette.primary.light};
        }
      }
    }
  }
`;

interface MenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  handleUserRoleUpdateDialogOpen: () => void;
  handleUserDeleteDialogOpen: () => void;
}

const Menu: React.FC<MenuProps> = (props) => {
  const {
    anchorEl,
    setAnchorEl,
    handleUserRoleUpdateDialogOpen,
    handleUserDeleteDialogOpen,
  } = props;
  const intl = useIntl();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <SettingMenu
      id="user-management-setting-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <AccessControl
        permit={(permissions) => permissions.includes('workspace.user.update')}
      >
        <MenuItem
          data-testid="UserRoleUpdateMenu"
          onClick={handleUserRoleUpdateDialogOpen}
        >
          <Typography>
            {intl.formatMessage({
              id: 'pages.OtherSetting.users.management.table.menu.userRoleUpdate',
            })}
          </Typography>
        </MenuItem>
      </AccessControl>
      <AccessControl
        permit={(permissions) => permissions.includes('workspace.user.delete')}
      >
        <MenuItem
          data-testid="UserDeleteMenu"
          onClick={handleUserDeleteDialogOpen}
        >
          <Typography>
            {intl.formatMessage({
              id: 'pages.OtherSetting.users.management.table.menu.userDelete',
            })}
          </Typography>
        </MenuItem>
      </AccessControl>
    </SettingMenu>
  );
};

export default Menu;
