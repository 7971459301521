import React from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { createSvgIcon } from '@mui/material/utils';
import AccessControl from '../../commons/AccessControl';
import SettingGroupBox from './SettingGroupBox';
import SettingGroupBoxItem from './SettingGroupBoxItem';

const Users: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <SettingGroupBox
      icon={<HumanIcon />}
      title={intl.formatMessage({
        id: 'pages.OtherSetting.users.title',
      })}
      description={
        <AccessControl
          permit={(permissions) => permissions.includes('invitation.read')}
        >
          {(permitted) =>
            intl.formatMessage({
              id: permitted
                ? 'pages.OtherSetting.users.description'
                : 'pages.OtherSetting.users.userDescription',
            })
          }
        </AccessControl>
      }
    >
      <AccessControl
        permit={(permissions) =>
          permissions.includes('invitation.create') ||
          permissions.includes('invitation.read') ||
          permissions.includes('invitation.delete')
        }
      >
        {(permitted) => (
          <SettingGroupBoxItem
            onClickButton={() => navigate('users/management')}
            buttonText={intl.formatMessage({
              id: 'pages.OtherSetting.users.management.button',
            })}
            descriptions={
              permitted
                ? [
                    intl.formatMessage({
                      id: 'pages.OtherSetting.users.management.subDescription.changeUserRole',
                    }),
                    intl.formatMessage({
                      id: 'pages.OtherSetting.users.management.subDescription.deleteFromWorkspace',
                    }),
                  ]
                : []
            }
          />
        )}
      </AccessControl>

      <AccessControl
        permit={(permissions) =>
          permissions.includes('invitation.create') ||
          permissions.includes('invitation.read') ||
          permissions.includes('invitation.delete')
        }
      >
        <SettingGroupBoxItem
          onClickButton={() => navigate('users/invitation')}
          buttonText={intl.formatMessage({
            id: 'pages.OtherSetting.users.invitation.button',
          })}
          descriptions={[
            intl.formatMessage({
              id: 'pages.OtherSetting.users.invitation.subDescription.invite',
            }),
            intl.formatMessage({
              id: 'pages.OtherSetting.users.invitation.subDescription.invitations',
            }),
          ]}
        />
      </AccessControl>
    </SettingGroupBox>
  );
};

export default Users;

const _HumanIcon = createSvgIcon(
  <>
    <path
      d="M21.5 21C26.7467 21 31 16.7467 31 11.5C31 6.25329 26.7467 2 21.5 2C16.2533 2 12 6.25329 12 11.5C12 16.7467 16.2533 21 21.5 21Z"
      fill="#5A5A5A"
    />
    <path
      d="M13 25H31C32.8565 25 34.637 25.7375 35.9497 27.0503C37.2625 28.363 38 30.1435 38 32V41C38 41.2652 37.8946 41.5196 37.7071 41.7071C37.5196 41.8946 37.2652 42 37 42H7C6.73478 42 6.48043 41.8946 6.29289 41.7071C6.10536 41.5196 6 41.2652 6 41V32C6 30.1435 6.7375 28.363 8.05025 27.0503C9.36301 25.7375 11.1435 25 13 25Z"
      fill="#5A5A5A"
    />
  </>,
  'Human',
);

const HumanIcon = styled(_HumanIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 44 44',
}))`
  fill: none;
  height: 44px;
  width: 44px;
`;
