export const MIN_LENGTH = 8;
export const MAX_LENGTH = 50;
const REQUIRED_CHARACTER_REGEXPS = [/[a-z]/, /[A-Z]/, /[0-9]/];
const UNAVAILABLE_CHARACTERS_REGEXP =
  /[^a-zA-Z0-9`~!@#$%^&*()_+\-={}[\]\\|:;"'<>,.?/]/;

export const requiredCharactersTester = (
  value: string | undefined,
): boolean => {
  return (
    !!value &&
    value.length >= MIN_LENGTH &&
    value.length <= MAX_LENGTH &&
    REQUIRED_CHARACTER_REGEXPS.every((regex) => regex.test(value))
  );
};

export const unavailableCharactersTester = (
  value: string | undefined,
): boolean => {
  return !!value && !UNAVAILABLE_CHARACTERS_REGEXP.test(value);
};
