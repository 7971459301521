import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { createSvgIcon } from '@mui/material/utils';
import AccessControl from 'src/domains/root/commons/AccessControl';
import GatewayNewDialog from '../workspaces/gateways/new/Dialog';
import SettingGroupBox from './SettingGroupBox';
import SettingGroupBoxItem from './SettingGroupBoxItem';

const Gateways: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [gatewayNewDialogOpen, setGatewayNewDialogOpen] = useState(false);

  const handleAddGatewayDialogOpen = () => {
    setGatewayNewDialogOpen(true);
  };

  const handleGatewayNewDialogClose = () => {
    setGatewayNewDialogOpen(false);
  };

  return (
    <SettingGroupBox
      icon={<GatewayIcon />}
      title={intl.formatMessage({
        id: 'pages.OtherSetting.gateways.title',
      })}
      description={
        <AccessControl
          permit={(permissions) =>
            permissions.includes('workspace.create') ||
            permissions.includes('cabiotpack.create') ||
            permissions.includes('cabiotpack.data.delete')
          }
        >
          {(permitted) =>
            intl.formatMessage({
              id: permitted
                ? 'pages.OtherSetting.gateways.description'
                : 'pages.OtherSetting.gateways.normalUserDescription',
            })
          }
        </AccessControl>
      }
    >
      <AccessControl
        permit={(permissions) =>
          permissions.includes('cabiotpack.create') &&
          permissions.includes('workspace.create') &&
          permissions.includes('cabiotpack.data.delete')
        }
      >
        <SettingGroupBoxItem
          onClickButton={handleAddGatewayDialogOpen}
          buttonText={intl.formatMessage({
            id: 'pages.OtherSetting.gateways.new.button',
          })}
          descriptions={[
            intl.formatMessage({
              id: 'pages.OtherSetting.gateways.new.subDescription.addToCurrentWorkspace',
            }),
            intl.formatMessage({
              id: 'pages.OtherSetting.gateways.new.subDescription.addToNewWorkspace',
            }),
          ]}
        />
        <SettingGroupBoxItem
          onClickButton={() => navigate('gateways/sensor-data')}
          buttonText={intl.formatMessage({
            id: 'pages.OtherSetting.gateways.sensorData.button',
          })}
        />
      </AccessControl>
      <SettingGroupBoxItem
        onClickButton={() => navigate('gateways/mac-address')}
        buttonText={intl.formatMessage({
          id: 'pages.OtherSetting.gateways.macAddress.button',
        })}
      />

      <GatewayNewDialog
        gatewayNewDialogOpen={gatewayNewDialogOpen}
        handleGatewayNewDialogClose={handleGatewayNewDialogClose}
      />
    </SettingGroupBox>
  );
};

export default Gateways;

const _GatewayIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 42L32 42C34.4853 42 36.5 39.9853 36.5 37.5L36.5 6.5C36.5 4.01471 34.4853 2 32 2L12 2C9.51472 2 7.5 4.01472 7.5 6.5L7.5 37.5C7.5 39.9853 9.51472 42 12 42ZM32 43.5C35.3137 43.5 38 40.8137 38 37.5L38 6.5C38 3.18629 35.3137 0.499996 32 0.499997L12 0.499998C8.68629 0.499999 6 3.18629 6 6.5L6 37.5C6 40.8137 8.68629 43.5 12 43.5L32 43.5Z"
      fill="#5A5A5A"
    />
    <circle cx="22" cy="19.5" r="2" fill="#5A5A5A" />
    <circle cx="22" cy="11.5034" r="2" fill="#5A5A5A" />
  </>,
  'Gateway',
);

const GatewayIcon = styled(_GatewayIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 44 44',
}))`
  fill: none;
  height: 44px;
  width: 44px;
`;
