import React from 'react';
import {
  SnackbarProvider,
  SnackbarProviderProps,
  MaterialDesignContent,
} from 'notistack';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) =>
  theme.unstable_sx({
    '&.notistack-MuiContent': {
      backgroundColor: 'white',
      color: 'black',
    },
    '& #notistack-snackbar': {
      width: { sm: 300 },
    },

    '&.notistack-MuiContent-success': {
      borderLeft: 'solid',
      borderLeftColor: '#43a047',
      '& #notistack-snackbar > svg': {
        color: '#43a047',
      },
    },
    '&.notistack-MuiContent-error': {
      borderLeft: 'solid',
      borderLeftColor: '#d32f2f',
      '& #notistack-snackbar > svg': {
        color: '#d32f2f',
      },
    },
    '&.notistack-MuiContent-warning': {
      borderLeft: 'solid',
      borderLeftColor: '#ff9800',
      '& #notistack-snackbar > svg': {
        color: '#ff9800',
      },
    },
    '&.notistack-MuiContent-info': {
      borderLeft: 'solid',
      borderLeftColor: '#2196f3',
      '& #notistack-snackbar > svg': {
        color: '#2196f3',
      },
    },
  }),
);

const StyledSnackbarProvider: React.FC<SnackbarProviderProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <SnackbarProvider
      iconVariant={{
        success: <CheckCircleOutlineIcon />,
        error: <ErrorOutlineIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      {...rest}
    >
      {children}
    </SnackbarProvider>
  );
};

export default StyledSnackbarProvider;
