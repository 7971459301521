import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'src/domains/root/commons/snackbar';
import * as API from 'src/apis';
import axios from 'src/utils/api';
import { awsRum } from 'src/utils/rum';

type ErrorCode =
  | 'bad_request'
  | 'not_found'
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

type MutationProps = {
  memoId: string;
  content: string;
  images: (API.MemoImage & { file?: File })[];
};

type Response = API.MemoImageUploadResponse;

export type Result = {
  mutate: ({ memoId, content }: MutationProps) => Promise<Response>;
  reset: () => void;
  data?: Response;
} & (
  | { status: 'hasError'; errorCode: ErrorCode }
  | { status: 'loading' }
  | { status: 'idle' }
  | { status: 'succeeded' }
);

export function usePutGatewaysMemos(
  gatewayId: string,
  props: { nuetralizeResults: () => void; onSuccess: () => void },
): Result {
  const { getAccessTokenSilently } = useAuth0();

  const mutation = useMutation<
    Response & { hasUploadError?: boolean },
    API.Error,
    MutationProps
  >({
    mutationFn: async ({ memoId, content, images }) => {
      const token = await getAccessTokenSilently();
      const response = await API.putGatewaysMemos({
        gatewayId,
        memoId,
        content,
        images,
        token,
      });

      try {
        await Promise.all(
          response.images.map(async (image, i) => {
            const uploadImage = images.filter((image) => image.type === 'new')[
              i
            ];

            const imageFile = uploadImage.file;

            await axios.put(image.uploadUrl, imageFile, {
              headers: {
                'Content-Type': imageFile?.type,
              },
            });
          }),
        );
      } catch (error) {
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        return { ...response, hasUploadError: true };
      }

      return response;
    },
    onSuccess: props.onSuccess,
  });

  const { enqueueSnackbar } = useSnackbar('SNACKBAR_IMAGE_UPLOAD_ERROR', {
    noRefreshButton: true,
  });

  const responseBase = {
    mutate: async ({ memoId, content, images }: MutationProps) => {
      props.nuetralizeResults();

      const typeAddedImages: API.MemoImage[] = images.map((image) => {
        if (!image.type) {
          return { ...image, type: 'keep' };
        }

        return image;
      });
      const result = await mutation.mutateAsync({
        memoId,
        content,
        images: typeAddedImages,
      });
      return result;
    },
    reset: mutation.reset,
    data: mutation.data,
  };

  if (mutation.isIdle) {
    return {
      ...responseBase,
      status: 'idle',
    };
  }
  // Handle loading and no data states
  if (mutation.isPending) {
    return {
      ...responseBase,
      status: 'loading',
    };
  }

  if (mutation.isError) {
    const error = mutation.error as API.Error;

    switch (error.code) {
      case 'bad_request':
      case 'permission_denied':
      case 'emergency_maintenance':
      case 'not_found':
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: error.code,
        };
      case 'unknown_error':
      default:
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: 'unknown_error',
        };
    }
  }

  if (mutation.data.hasUploadError) {
    enqueueSnackbar('pages.GatewaysMemos.message.uploadFailed');
  }

  return {
    ...responseBase,
    status: 'succeeded',
  };
}
