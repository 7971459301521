import { useEffect, useRef, useState } from 'react';

// 要素のwidthが変わったときにrerenderするhooks
const useResizeObserverRef = () => {
  const ref = useRef();

  // rerenderするための仕掛け
  // reactでは、hooksのstateが更新されるとrerenderが走る
  const [, setChartSize] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;

      setChartSize(width);
    });

    const referredElement = ref.current;

    if (referredElement) {
      observer.observe(referredElement);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return ref;
};

export default useResizeObserverRef;
