import { type Permission } from 'src/apis';

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT as string;

export const LATEST_TERMS_OF_SERVICE_VERSION = '20240925a';

export const CONDENSATION_ALERT_TEMPERATURE_FAQ_URL =
  'https://cabiot.zendesk.com/hc/ja/articles/16065092501657';

/**
 * サービスのユーザー権限
 *
 * @see https://github.com/Nitto-CABIoT/cabiot-docs/wiki/%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E6%A8%A9%E9%99%90#%E3%83%AD%E3%83%BC%E3%83%AB%E3%81%8C%E9%96%A2%E4%BF%82%E3%81%99%E3%82%8B%E6%93%8D%E4%BD%9C
 */
export const USER_ROLE: {
  normal: Permission[];
  admin: Permission[];
} = {
  normal: [
    'alert.current.read',
    'alert.history.read',
    'cabiotpack.alarm.read',
    'cabiotpack.data.read',
    'cabiotpack.list.read',
    'cabiotpack.observe.read',
    'workspace.user.read',
  ],
  admin: [
    'alert.current.read',
    'alert.current.update',
    'alert.history.read',
    'cabiotpack.alarm.read',
    'cabiotpack.alarm.update',
    'cabiotpack.area.update',
    'cabiotpack.contract.list',
    'cabiotpack.contract.update',
    'cabiotpack.create',
    'cabiotpack.data.delete',
    'cabiotpack.data.read',
    'cabiotpack.list.read',
    'cabiotpack.name.update',
    'cabiotpack.observe.read',
    'cabiotpack.observe.update',
    'cabiotpack.payment.list',
    'invitation.create',
    'invitation.delete',
    'invitation.read',
    'workspace.create',
    'workspace.payment.invoice.create',
    'workspace.payment.status.read',
    'workspace.name.update',
    'workspace.user.delete',
    'workspace.user.read',
    'workspace.user.update',
    'stripe.customer.name.read',
    'stripe.customer.name.update',
  ],
};

export const LOCAL_STORAGE_KEY = {
  HAS_LATEST_WORKSPACE_NEAR_CONTRACT_EXPIRATION:
    'cabiot_has_latest_workspace_near_contract_expiration',
};

export const IS_ENV = {
  MOCK: import.meta.env.MODE === 'mock',
  DEMO: import.meta.env.MODE === 'demo',

  DEV: import.meta.env.MODE === 'development',
  REV: import.meta.env.MODE === 'review',
  STG: import.meta.env.MODE === 'staging',
  PRD: import.meta.env.MODE === 'production',
};

const isInternal = IS_ENV.DEV || IS_ENV.REV || IS_ENV.MOCK;

// ユーザーに見せる時はtrue, 見せないならfalse
export const FEATURE_FLAG = {
  'contract.payment-method': isInternal,
  'workspace.report': isInternal,
} as const satisfies Record<string, boolean>;

// @NOTE: query stringで動作確認用のマッピング、簡単な言葉で切り替える
export const QUERY_STRING_FEATURE_FLAG = {
  invoice: 'contract.payment-method',
  report: 'workspace.report',
} as const satisfies Record<string, keyof typeof FEATURE_FLAG>;

export const FEATURE_FLAG_SESSION_STORAGE_KEY = 'feature_flag';
