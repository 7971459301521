import React from 'react';
import { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import OpacityRoundedIcon from '@mui/icons-material/OpacityRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded';
import { Tooltip } from 'src/shared/ui';

const iconStyle = css`
  vertical-align: bottom;
`;
const connectorTemperatureObserveIconStyle = css`
  color: #f50057;
`;
const condensationObserveIconStyle = css`
  color: #3f51b5;
`;

const ObserveModeIcon: React.FC<{ mode: string }> = (props) => {
  const { mode } = props;
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: `modeSettingData.${mode}`,
      })}
      placement="top"
    >
      {mode === 'temperatureHumidityObserve' ? (
        <BarChartIcon css={iconStyle} />
      ) : mode === 'condensationObserve' ? (
        <OpacityRoundedIcon css={[iconStyle, condensationObserveIconStyle]} />
      ) : (
        <WhatshotRoundedIcon
          css={[iconStyle, connectorTemperatureObserveIconStyle]}
        />
      )}
    </Tooltip>
  );
};

export default ObserveModeIcon;
