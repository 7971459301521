import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';

type Props = {
  onClickButton: () => void;
  buttonText: React.ReactNode;
  descriptions?: string[];
};
const SettingGroupBoxItem: React.FC<Props> = (props) => {
  const { onClickButton, buttonText, descriptions } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box width={isSmallScreen ? undefined : 216}>
      <SecondaryButton fullWidth onClick={onClickButton}>
        {buttonText}
      </SecondaryButton>
      {descriptions && (
        <Box component="ul" mt={1.5} mb={0} pl={2.5} fontSize={12}>
          {descriptions.map((description, index) => (
            <li key={index}>{description}</li>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SettingGroupBoxItem;
