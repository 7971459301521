import React from 'react';
import styled from 'styled-components/macro';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { createSvgIcon } from '@mui/material/utils';

const _AnnounceIcon = createSvgIcon(
  <>
    <path d="M14.6523 4.39292V19.6471L5.85521 13.6415V10.4385L14.6523 4.39292ZM16.3518 2.29097C16.3492 2.16435 16.2979 2.04363 16.2084 1.95408C16.119 1.86452 15.9984 1.81308 15.8719 1.81052C15.8128 1.79648 15.7512 1.79648 15.692 1.81052L4.15576 9.44762V14.5924L15.692 22.1895C15.7512 22.2035 15.8128 22.2035 15.8719 22.1895C15.9984 22.1869 16.119 22.1355 16.2084 22.0459C16.2979 21.9563 16.3492 21.8356 16.3518 21.709V2.29097Z" />
    <path d="M0.00696654 13.4714C-0.00962677 13.6155 0.00349786 13.7616 0.045529 13.9004C0.0875601 14.0393 0.157604 14.1681 0.251331 14.2788C0.345057 14.3895 0.460474 14.4798 0.590445 14.544C0.720415 14.6083 0.862178 14.6452 1.00697 14.6525H3.00697V9.3876H1.00697C0.862178 9.39486 0.720415 9.43177 0.590445 9.49605C0.460474 9.56033 0.345057 9.65061 0.251331 9.76131C0.157604 9.87201 0.0875601 10.0008 0.045529 10.1397C0.00349786 10.2785 -0.00962677 10.4245 0.00696654 10.5687V13.4714Z" />
    <path d="M19.3107 17.9155C19.1429 17.9229 18.9787 17.8661 18.8513 17.7565C18.724 17.6469 18.6432 17.4929 18.6253 17.3257C18.6075 17.1585 18.654 16.9909 18.7553 16.8568C18.8566 16.7228 19.0052 16.6324 19.1707 16.6042C20.183 16.3439 21.0784 15.7502 21.713 14.9188C22.3476 14.0873 22.6845 13.0663 22.6696 12.02C22.6845 10.9736 22.3476 9.95268 21.713 9.12122C21.0784 8.28976 20.183 7.69611 19.1707 7.43573C18.9984 7.39989 18.8473 7.29698 18.7508 7.14963C18.6542 7.00227 18.6201 6.82255 18.6559 6.65C18.6917 6.47745 18.7945 6.3262 18.9416 6.22953C19.0888 6.13286 19.2683 6.09869 19.4406 6.13452C20.7563 6.44897 21.9254 7.20377 22.7543 8.27402C23.5833 9.34427 24.0224 10.6658 23.9991 12.02C24.0224 13.3742 23.5833 14.6957 22.7543 15.766C21.9254 16.8362 20.7563 17.591 19.4406 17.9055L19.3107 17.9155Z" />
    <path d="M18.3512 15.4632C18.2044 15.4664 18.0611 15.4181 17.9461 15.3267C17.8312 15.2353 17.7517 15.1066 17.7214 14.9628C17.7039 14.8799 17.7029 14.7944 17.7186 14.7112C17.7343 14.628 17.7663 14.5487 17.8128 14.478C17.8593 14.4073 17.9193 14.3465 17.9894 14.2991C18.0595 14.2517 18.1383 14.2187 18.2212 14.2021C18.7427 14.103 19.2132 13.8248 19.5517 13.4154C19.8902 13.0061 20.0754 12.4914 20.0754 11.96C20.0754 11.4286 19.8902 10.9138 19.5517 10.5045C19.2132 10.0952 18.7427 9.817 18.2212 9.71789C18.0556 9.68191 17.911 9.5816 17.8192 9.43898C17.7273 9.29636 17.6958 9.12308 17.7314 8.95718C17.7479 8.87442 17.7809 8.79583 17.8284 8.72606C17.8758 8.6563 17.9368 8.59678 18.0076 8.55104C18.0785 8.50531 18.1578 8.47427 18.2409 8.45979C18.3239 8.44531 18.409 8.44767 18.4911 8.46673C19.3081 8.61884 20.0462 9.05242 20.5774 9.69228C21.1086 10.3321 21.3993 11.1379 21.3993 11.97C21.3993 12.802 21.1086 13.6078 20.5774 14.2477C20.0462 14.8875 19.3081 15.3211 18.4911 15.4732L18.3512 15.4632Z" />
  </>,
  'AnnounceIcon',
);

const AnnounceIcon = styled(_AnnounceIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 24 24',
}))`
  fill: ${(props) => props.theme.palette.primary.main};
  height: 24px;
  width: 24px;
`;

export default AnnounceIcon;
