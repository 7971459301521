import Highcharts from 'highcharts';
import { IntlShape } from 'react-intl';
import theme from 'src/domains/root/styles/theme';
import type { ChartType } from 'src/domains/root/types';
import { getRangeSelectorButtons } from './base-option-range-selector';
import {
  wrapRangeSelectorGetInputValue,
  inputDateParser,
} from './input-date-parser';
import {
  indentLineIcnoSvgStirng,
  renderObserveModeIcon,
  renderHeartbeatIcon,
  ObserveModeIcon,
} from './icon-svg-string';

wrapRangeSelectorGetInputValue();

export const getGlobalOptions = (intl: IntlShape): Highcharts.Options => {
  return {
    lang: {
      months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      shortMonths: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ],
      weekdays: [
        intl.formatMessage({
          id: 'highchartsOptions.globalOptions.weekdays.monday',
        }),
        intl.formatMessage({
          id: 'highchartsOptions.globalOptions.weekdays.tuesday',
        }),
        intl.formatMessage({
          id: 'highchartsOptions.globalOptions.weekdays.wednesday',
        }),
        intl.formatMessage({
          id: 'highchartsOptions.globalOptions.weekdays.thursday',
        }),
        intl.formatMessage({
          id: 'highchartsOptions.globalOptions.weekdays.friday',
        }),
        intl.formatMessage({
          id: 'highchartsOptions.globalOptions.weekdays.saturday',
        }),
        intl.formatMessage({
          id: 'highchartsOptions.globalOptions.weekdays.sunday',
        }),
      ],
      rangeSelectorZoom: intl.formatMessage({
        id: 'highchartsOptions.globalOptions.rangeSelectorZoom',
      }),
      viewData: intl.formatMessage({
        id: 'highchartsOptions.globalOptions.viewData',
      }),
      hideData: intl.formatMessage({
        id: 'highchartsOptions.globalOptions.hideData',
      }),
      // 下からはハンバーガーメニューのintl
      contextButtonTitle: intl.formatMessage({
        id: 'highchartsOptions.globalOptions.contextButtonTitle',
      }),
      downloadCSV: intl.formatMessage({
        id: 'highchartsOptions.globalOptions.downloadCSV',
      }),
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      line: { lineWidth: 2 },
    },
    legend: {
      itemStyle: { fontWeight: 'bold' },
      itemMarginBottom: 0,
      itemMarginTop: 0,
    },
  };
};

type GetChartBaseOptionsProps = {
  intl: IntlShape;
  chartType: ChartType;
  isSmallScreen: boolean;
  graphRangeTouched: boolean;
  isFullData: boolean;
  initialViewRange?: { start: Date; end: Date };
};
/*
 * 全てのチャートで共通の設定
 */
export const getChartBaseOptions = ({
  intl,
  chartType,
  isSmallScreen,
  graphRangeTouched,
  isFullData,
  initialViewRange,
}: GetChartBaseOptionsProps): Highcharts.Options => {
  return {
    time: { useUTC: false },
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        animation: false,
        dataGrouping: {
          // プロットは常にgroupingされ平均が表示される
          forced: true,
          // プロットは分単位とし、秒の値は切り捨てられる
          units: [
            ['millisecond', []],
            ['second', []],
            ['minute', [5, 10, 15, 30]],
            ['hour', [1, 2, 3, 4, 6, 8, 12]],
            ['day', [1]],
            ['week', [1]],
            ['month', [1, 3, 6]],
            ['year', null],
          ],
        },
      },
    },
    chart: {
      events: {
        load: initialViewRange
          ? function () {
              this.xAxis[0].setExtremes(
                initialViewRange.start.getTime(),
                initialViewRange.end.getTime(),
              );
            }
          : undefined,
      },
      animation: false,
      height:
        chartType === 'gatewaySensorDataChart'
          ? // mac, win, mobileのすべてで、ツールチップが6個同時に表示できる高さを確保する
            568
          : chartType === 'sensorDataChart' && isSmallScreen
            ? 440
            : Math.min(window.innerHeight - 60, 700),
      spacing:
        chartType === 'sensorDataChart' ? [5, 10, 10, 15] : [5, 29, 10, 15],
      marginTop:
        chartType === 'gatewaySensorDataChart' && isSmallScreen
          ? /**
             * 期間選択ボックス高さ + (marginTop - 5) = 20.5
             * 期間選択ボックス高さ: 15.5
             * 引き抜き5はどっこから来たのか不明
             * FigmaにはmarginTopが20だが変わらない0.5があるのでピッタリできない
             */
            10
          : undefined,
    },
    rangeSelector: {
      buttonPosition: { y: 10 },
      inputPosition: { y: 10 },
      // textはPC表示のボタン、titleはスマホ表示のプルダウンメニューに使用される
      buttons: initialViewRange
        ? []
        : getRangeSelectorButtons(isFullData, intl),
      inputDateFormat: '%Y/%b/%e',
      // eslint-disable-next-line
      // @ts-ignore
      inputDateParser: inputDateParser,
      /**
       * グラフの範囲の初期値は1（1週間）を指定する。
       * ただし、highchartsを操作してグラフ範囲を変更したあとは、undefinedを渡すことで
       * 状態管理をhighchartsに任せる。
       */
      selected: graphRangeTouched ? undefined : 1,
    },
  };
};

/*
 * title, subtilte関連の設定
 */
export const getTitleSubtitleOptions = (
  intl: IntlShape,
  mode: string,
  heartBeat?: boolean,
  gatewayName?: string,
  sensorUnitName?: string,
  location?: string,
  isSmallScreen?: boolean,
): Highcharts.Options => {
  if (isSmallScreen) {
    return {
      title: { useHTML: true, text: '' },
      subtitle: { useHTML: true, text: '' },
    };
  }
  return {
    title: {
      useHTML: true,
      align: 'left',
      text: `
        <div style='display: flex; align-items: center; margin-top: 20px;'>
          <span style='vertical-align: middle; margin: 0; padding-top: 1px; max-width: max-content; line-height: 1.5; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;'>
            ${gatewayName}
          </span>
          ${
            location
              ? `
              <div style='display: inline-flex; max-width: max-content; height: 18px; min-width: max-content; margin-left: 12px; background-color: ${theme.palette.chip.main}; color: white; border-radius: 16px; font-size: 12px; font-weight: bold;'>
                <span style='padding: 0 8px;'>
                  ${location}
                </span>
              </div>
              `
              : ''
          }
        </div>
      `,
      style: {
        padding: '0 5px',
        color: theme.palette.primary.main,
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
        maxWidth: '65%',
      },
    },
    subtitle: {
      useHTML: true,
      align: 'left',
      text: sensorUnitName
        ? `
      <div style='display: flex; margin-top: 12px; margin-left: 12px; min-width: 25px; padding-right: 36px;'>
        ${indentLineIcnoSvgStirng.indentLine}
        <div style='margin-left: 12px; margin-right: 8px;'>
          ${renderObserveModeIcon(mode as ObserveModeIcon)}
        </div>
        <span style='vertical-align: middle; margin: 0; padding-top: 1px; max-width: max-content; line-height: 1.6; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;'>
          ${sensorUnitName}
        </span>
        <div style='margin: 0 12px;'>
          ${renderHeartbeatIcon(intl, heartBeat)}
        </div>
      </div>
      `
        : '',
      style: {
        padding: '0 5px',
        fontSize: '14px',
        fontFamily: theme.typography.fontFamily,
      },
    },
  };
};
