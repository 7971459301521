import React from 'react';
import Paper from '@mui/material/Paper';

type Props = {
  bgColor?: string;
  children: React.ReactNode;
};

const _Paper = (props: Props) => {
  const { bgColor, children } = props;

  return (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        backgroundColor: bgColor,
      }}
    >
      {children}
    </Paper>
  );
};

export default _Paper;
