import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';

const PrimaryFormControlLabel = styled(FormControlLabel)`
  .MuiIconButton-label {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default PrimaryFormControlLabel;
