import React from 'react';
import { css } from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { AppDispatch } from 'src/domains/root/store';
import theme from 'src/domains/root/styles/theme';
import { putWorkspace } from 'src/domains/root/features/current-workspace/slice';
import { getUsersMe, Workspace } from 'src/domains/root/features/users/slice';
import { getAreasSilently } from 'src/domains/root/features/areas/slice';

const menuItemStyle = css`
  padding-left: ${theme.spacing(1)};
  padding-right: ${theme.spacing(1)};
`;

interface WorkspaceMenuItemProps {
  handleClose: () => void;
  handleDrawerToggle?: () => void;
  workspace: Workspace;
  selected: boolean;
}

const WorkspaceMenuItem: React.FC<WorkspaceMenuItemProps> = (props) => {
  const { handleClose, handleDrawerToggle, workspace, selected } = props;
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const token = getAccessTokenSilently();

  const handleClick = async () => {
    const { workspaceId, workspaceName: name } = workspace;
    dispatch(putWorkspace({ token, workspace: { workspaceId, name } }))
      .unwrap()
      .then(() => {
        dispatch(getUsersMe({ token, silent: true }));
        dispatch(getAreasSilently({ token }));
        handleClose();
        if (handleDrawerToggle) handleDrawerToggle();
        navigate('/');
      });
  };

  return (
    <MenuItem onClick={handleClick} selected={selected} css={menuItemStyle}>
      <Typography noWrap>{workspace.workspaceName}</Typography>
    </MenuItem>
  );
};

export default WorkspaceMenuItem;
