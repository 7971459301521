import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';

const _ArrowUpwardIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.63429 4.56567C6.94671 4.87809 7.45324 4.87809 7.76565 4.56567C8.07807 4.25325 8.07807 3.74672 7.76565 3.4343L4.56567 0.234314C4.25325 -0.0781045 3.74672 -0.0781045 3.4343 0.234314L0.234314 3.4343C-0.0781046 3.74672 -0.0781046 4.25325 0.234314 4.56567C0.546732 4.87808 1.05326 4.87808 1.36568 4.56567L3.19999 2.73136V13.2001C3.19999 13.6419 3.55816 14.0001 3.99998 14.0001C4.44181 14.0001 4.79998 13.6419 4.79998 13.2001V2.73136L6.63429 4.56567Z"
  />,
  'ArrowUpward',
);

const ArrowUpwardIcon = styled(_ArrowUpwardIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 8 14',
}))`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.pxToRem(15)};
`;

export default ArrowUpwardIcon;
