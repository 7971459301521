import React, { useCallback, useReducer } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

type ActionType =
  | 'gatewayName'
  | 'sensorUnitName'
  | 'alertType'
  | 'event'
  | 'clear';

export type UpdateOption = (event: SelectChangeEvent<unknown>) => void;

export interface OptionState {
  gatewayName: string;
  sensorUnitName: string;
  alertType: string;
  event: string;
  clear: string;
}

interface Action {
  type: ActionType;
  value: string;
}

const reducer: React.Reducer<OptionState, Action> = (state, action) => {
  switch (action.type) {
    case 'gatewayName':
      return { ...state, gatewayName: action.value };
    case 'sensorUnitName':
      return { ...state, sensorUnitName: action.value };
    case 'alertType':
      return { ...state, alertType: action.value };
    case 'event':
      return { ...state, event: action.value };
    case 'clear':
      return {
        gatewayName: 'all',
        sensorUnitName: 'all',
        alertType: 'all',
        event: 'all',
        clear: '',
      };
    default:
      return state;
  }
};

const useFilterOption = (): [OptionState, UpdateOption] => {
  const initial: OptionState = {
    gatewayName: 'all',
    sensorUnitName: 'all',
    alertType: 'all',
    event: 'all',
    clear: '',
  };
  const [option, dispatch] = useReducer(reducer, initial);

  const updateOption = useCallback((event: SelectChangeEvent<unknown>) => {
    dispatch({
      type: event.target.name as ActionType,
      value: event.target.value as string,
    });
  }, []);

  return [option, updateOption];
};

export default useFilterOption;
