import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios, { isAxiosError } from 'src/utils/api';
import {
  getRegistrationError,
  RegistrationError,
  RegistrationErrorCode,
  ResponseErrorHandler,
} from '../../../utils/registrations/api';
import { Result, RegistrationState } from '../../../store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

interface PostUserParams {
  registrationId: string;
  password: string;
  onResponseError: ResponseErrorHandler;
}

export const postUser = createAsyncThunk<
  void,
  PostUserParams,
  {
    state: RegistrationState;
    rejectValue: RegistrationError<RegistrationErrorCode>;
  }
>('users/postUser', async (params, thunkAPI) => {
  const {
    registrationId,
    password,
    onResponseError: handleResponseError,
  } = params;
  const body = { password };

  try {
    await axios.post<void>(
      `${API_ENDPOINT}/registrations/${registrationId}/users`,
      body,
    );
    return;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const registrationError = getRegistrationError(error, handleResponseError);
    return thunkAPI.rejectWithValue(registrationError);
  }
});

export interface UsersState {
  newResult: Result<void, RegistrationError<RegistrationErrorCode>>;
}

const initialState: UsersState = {
  newResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postUser.pending, (state) => {
        state.newResult.status = 'loading';
        state.newResult.error = null;
      })
      .addCase(postUser.fulfilled, (state, action) => {
        state.newResult.data = action.payload;
        state.newResult.error = null;
        state.newResult.status = 'succeeded';
      })
      .addCase(postUser.rejected, (state, action) => {
        state.newResult.data = null;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.newResult.error = action.payload!;
        state.newResult.status = 'failed';
      });
  },
});

export const newResultSelector = createSelector(
  (state: RegistrationState) => state.registrationsUsers,
  (state: UsersState) => state.newResult,
);

export default usersSlice.reducer;
