import React, { useState } from 'react';
import { ImageNotSupportedRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Text } from 'src/shared/ui';
import { useIntl } from 'react-intl';

export default function MemoImage({
  src,
  alt,
  width,
  height,
  onClick,
  ...rest
}: {
  src: string | undefined;
  alt: string;
  width: { [key: string]: string | number };
  height: { [key: string]: string | number };
  onClick?: () => void;
}) {
  const intl = useIntl();

  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return (
    <Box
      width={width}
      height={height}
      onClick={onClick}
      bgcolor="white"
      sx={{
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            cursor: 'pointer',
          },
        },
        img: {
          width: width,
          height: height,
        },
      }}
    >
      {error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <Box display="flex" fontSize={48} pb={{ xs: 1, md: 2 }}>
            <ImageNotSupportedRounded color="primary" fontSize="inherit" />
          </Box>
          <Text variant="Caption_B" textAlign="center">
            {intl.formatMessage(
              { id: 'pages.GatewaysMemos.image.loadFailed' },
              { br: <br /> },
            )}
          </Text>
        </Box>
      ) : (
        <img
          src={src}
          alt={alt}
          onError={handleError}
          style={{ display: 'block', objectFit: 'contain' }}
          {...rest}
        />
      )}
    </Box>
  );
}
