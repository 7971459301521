import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  RegistrationError,
  RegistrationErrorCode,
} from '../utils/registrations/api';

type CallbackFunction = () => RegistrationErrorCode | void;

export interface ErrorHandlerProps {
  error?: RegistrationError<RegistrationErrorCode> | null;
  redirectTo: {
    [key in RegistrationErrorCode]?: CallbackFunction | string;
  };
  children: React.ReactNode;
}

const FALLBACK_PATH = '/registration/signup';

export default function ErrorHandler({
  error,
  redirectTo: redirectionMapping,
  children,
}: ErrorHandlerProps) {
  if (!error || error.recoverable) {
    return <>{children}</>;
  }

  const redirectTo = redirectionMapping[error.code] ?? FALLBACK_PATH;
  const path = typeof redirectTo === 'function' ? redirectTo() : redirectTo;
  return path ? <Navigate to={path} /> : null;
}
