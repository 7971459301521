import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Status } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type SensorUnitObserveModeErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'emergency_maintenance'
  | 'unknown_error';

export interface PutSensorUnitObserveModeParams {
  token: Promise<string>;
  sensorUnitId: string;
  observeMode: string;
}

export const putSensorUnitObserveMode = createAsyncThunk<
  void,
  PutSensorUnitObserveModeParams,
  { state: RootState; rejectValue: RootError<SensorUnitObserveModeErrorCode> }
>(
  'sensorUnitObserveMode/putSensorUnitObserveMode',
  async (params, thunkAPI) => {
    const token = await params.token;
    const { sensorUnitId, observeMode } = params;
    const body = {
      observe: { mode: observeMode },
    };

    try {
      await axios.put<void>(
        `${API_ENDPOINT}/sensor-units/${sensorUnitId}/observe`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }
      const sensorUnitObserveModeError = getSensorUnitObserveModeError(error);
      return thunkAPI.rejectWithValue(sensorUnitObserveModeError);
    }
  },
);

const getSensorUnitObserveModeError = (
  error: AxiosError<RootErrorResponse>,
): RootError<SensorUnitObserveModeErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;

    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface SensorUnitObserveModeState {
  error: RootError<SensorUnitObserveModeErrorCode> | null;
  status: Status;
}

const initialState: SensorUnitObserveModeState = {
  error: null,
  status: 'idle',
};

export const sensorUnitObserveModeSlice = createSlice({
  name: 'sensorUnitObserveMode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putSensorUnitObserveMode.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putSensorUnitObserveMode.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(putSensorUnitObserveMode.rejected, (state, action) => {
        state.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.error = action.payload!;
      });
  },
});

export const errorSelector = createSelector(
  (state: RootState) => state.sensorUnitObserveMode,
  (state: SensorUnitObserveModeState) => state.error,
);

export const statusSelector = createSelector(
  (state: RootState) => state.sensorUnitObserveMode,
  (state: SensorUnitObserveModeState) => state.status,
);

export default sensorUnitObserveModeSlice.reducer;
