import React, { useEffect } from 'react';
import { Dialog as _Dialog, DialogProps } from '@mui/material';
import { awsRum } from 'src/utils/rum';

type Props = {
  open: boolean;
  loggingId: string;
  maxWidth?: DialogProps['maxWidth'] | number;
  children: React.ReactNode;
} & Omit<DialogProps, 'maxWidth'>;

export function Dialog({
  open,
  loggingId,
  children,
  maxWidth,
  sx = [],
  ...props
}: Props) {
  const isMaxWidthNumber = typeof maxWidth === 'number';

  useEffect(() => {
    if (open) {
      awsRum().then((rum) => {
        rum.recordPageView(location.pathname + '#' + loggingId);
      });
    }
  }, [open, loggingId]);

  return (
    <_Dialog
      maxWidth={isMaxWidthNumber ? false : maxWidth}
      open={open}
      sx={[
        {
          '& .MuiDialog-paper': {
            maxWidth: isMaxWidthNumber ? maxWidth : undefined,
          },
        },

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {children}
    </_Dialog>
  );
}
