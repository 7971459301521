import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Form from '../features/setup/workspaces/Form';
import MainContents from '../commons/layout/MainContents';

const SetupWorkspace: React.FC = () => {
  const intl = useIntl();

  return (
    <MainContents
      containerMaxWidth="md"
      backgroundColor="white"
      showTopButton={false}
    >
      <Box maxWidth={560} pt={4} mx="auto">
        <Box display="grid" justifyContent="center" pb={5}>
          <Typography
            fontWeight={700}
            fontSize={(theme) => theme.typography.pxToRem(18)}
            lineHeight={1.57}
          >
            STEP 1 / 2
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={(theme) => theme.typography.pxToRem(32)}
            lineHeight={1.167}
          >
            {intl.formatMessage({
              id: 'pages.Setup.Workspace.title',
            })}
          </Typography>
        </Box>
        <Form />
      </Box>
    </MainContents>
  );
};

export default SetupWorkspace;
