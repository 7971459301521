import React from 'react';
import { DateTime } from 'luxon';
import { Box } from '@mui/material';
import type { TableCellProps } from '@mui/material';

interface Props extends TableCellProps {
  timestamp: number;
}

const FormattedTimestamp = ({ timestamp, ...rest }: Props) => {
  const dateTimeStrings = DateTime.fromMillis(timestamp)
    .toFormat('yyyy/M/d HH:mm:ss')
    .split(' ');

  return timestamp === 0 ? (
    <Box {...rest}>-</Box>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      fontSize={(theme) => theme.typography.pxToRem(14)}
      {...rest}
    >
      <Box display="flex" flexDirection="column" alignItems="start">
        <Box>{dateTimeStrings[0]}</Box>
        <Box>{dateTimeStrings[1]}</Box>
      </Box>
    </Box>
  );
};

export default FormattedTimestamp;
