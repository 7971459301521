import React from 'react';
import { useIntl } from 'react-intl';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { default as MuiTableHead } from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AccessControl from 'src/domains/root/commons/AccessControl';
import { Order, WorkspacesUserTableHead } from '../List';

interface TabelHeadProps {
  order: Order;
  orderBy: WorkspacesUserTableHead;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: WorkspacesUserTableHead,
  ) => void;
  isSmallScreen: boolean;
}

const TableHead: React.FC<TabelHeadProps> = (props) => {
  const { order, orderBy, handleRequestSort, isSmallScreen } = props;
  const intl = useIntl();

  const defaultHeadCells: {
    id: WorkspacesUserTableHead;
    label: string;
  }[] = [
    {
      id: 'email',
      label: intl.formatMessage({
        id: 'pages.OtherSetting.users.management.table.headCell.email',
      }),
    },
    {
      id: 'createdAt',
      label: intl.formatMessage({
        id: 'pages.OtherSetting.users.management.table.headCell.createdAt',
      }),
    },
    {
      id: 'role',
      label: intl.formatMessage({
        id: 'pages.OtherSetting.users.management.table.headCell.role',
      }),
    },
  ];

  const smallScreenHeadCells = defaultHeadCells.filter(
    (cell) => cell.id !== 'createdAt',
  );

  const headCells = isSmallScreen ? smallScreenHeadCells : defaultHeadCells;

  const createSortHandler =
    (property: WorkspacesUserTableHead) =>
    (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return (
    <MuiTableHead>
      <TableRow sx={{ borderBottom: '2px solid #828282' }}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center" padding="normal">
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={(theme) => ({
                pl: 3,
                wordBreak: 'keep-all',
                fontWeight: 700,
                fontSize: theme.typography.pxToRem(15),
              })}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <AccessControl
          permit={(permissions) =>
            permissions.includes('workspace.user.update') ||
            permissions.includes('workspace.user.delete')
          }
        >
          <TableCell
            align="center"
            sx={(theme) => ({
              fontWeight: 700,
              fontSize: theme.typography.pxToRem(15),
              minWidth: 88,
            })}
          >
            {intl.formatMessage({
              id: 'pages.OtherSetting.users.management.table.headCell.setting',
            })}
          </TableCell>
        </AccessControl>
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
