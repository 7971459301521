import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as API from 'src/apis';
import { Text } from 'src/shared/ui';
import {
  Dialog,
  DialogTitle,
  DialogTitleAlert,
  DialogContent,
  DialogContentHeader,
  DialogActions,
  CancelButton,
  SubmitButton,
} from 'src/domains/root/commons/dialog';
import { AppDispatch } from 'src/domains/root/store';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { updateNotificationSettings } from '../slice';
import { usePutUsersMeNotificationSettings } from './usePutUsersMeNotificationSettings';

const validationSchema = yup.object().shape({
  alert: yup.boolean(),
  alertReport: yup.boolean(),
  heartbeat: yup.boolean(),
});

export default function NotificationSettingsDialog({
  me,
  open,
  notificationSettingsMutation,
  onClose,
}: {
  me: API.Me;
  open: boolean;
  notificationSettingsMutation: ReturnType<
    typeof usePutUsersMeNotificationSettings
  >;
  onClose: () => void;
}) {
  const intl = useIntl();
  const dispatch: AppDispatch = useDispatch();

  const { alert, alertReport, heartbeat } = me.notificationSettings.email;
  const initialValues = {
    alert,
    alertReport,
    heartbeat,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const request: API.NotificationSettings = {
        email: values,
      };

      await notificationSettingsMutation.mutate(request);

      dispatch(updateNotificationSettings(request));
    },
  });

  if (notificationSettingsMutation.status === 'hasError') {
    return (
      <RedirectionError errorCode={notificationSettingsMutation.errorCode} />
    );
  }

  const handleClose = () => {
    onClose();
    notificationSettingsMutation.reset();
  };

  return (
    <Dialog open={open} fullWidth loggingId="NotificationSettingsDialog">
      <DialogTitle onClose={handleClose}>
        {intl.formatMessage({
          id: 'features.users.notificationSettings.dialog.title',
        })}
      </DialogTitle>
      <DialogTitleAlert
        status={notificationSettingsMutation.status}
        message={{
          success: intl.formatMessage({
            id: 'features.users.notificationSettings.dialog.alert.success.completed',
          }),
        }}
      />
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentHeader mt={1}>
            {intl.formatMessage({
              id: 'features.users.notificationSettings.dialog.form.email.title',
            })}
          </DialogContentHeader>
          <Box pl={2} pt={2} display="flex" flexDirection="column" rowGap={2}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="alert"
                    checked={formik.values.alert}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    data-testid="AlertCheckbox"
                  />
                }
                label={
                  <Text fontSize={{ xs: 16 }}>
                    {intl.formatMessage({
                      id: 'features.users.notificationSettings.dialog.form.email.alert.label',
                    })}
                  </Text>
                }
              />
              <Text
                variant="Caption_B"
                pl={4}
                color="#505050"
                fontSize={{ xs: 12 }}
              >
                {intl.formatMessage({
                  id: 'features.users.notificationSettings.dialog.form.email.alert.description',
                })}
              </Text>
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="alertReport"
                    checked={formik.values.alertReport}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    data-testid="AlertReportCheckbox"
                  />
                }
                label={
                  <Text fontSize={{ xs: 16 }}>
                    {intl.formatMessage({
                      id: 'features.users.notificationSettings.dialog.form.email.alertReport.label',
                    })}
                  </Text>
                }
              />
              <Text
                variant="Caption_B"
                pl={4}
                color="#505050"
                fontSize={{ xs: 12 }}
              >
                {intl.formatMessage({
                  id: 'features.users.notificationSettings.dialog.form.email.alertReport.description',
                })}
              </Text>
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="heartbeat"
                    checked={formik.values.heartbeat}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    data-testid="HeartbeatCheckbox"
                  />
                }
                label={
                  <Text fontSize={{ xs: 16 }}>
                    {intl.formatMessage({
                      id: 'features.users.notificationSettings.dialog.form.email.heartbeat.label',
                    })}
                  </Text>
                }
              />
              <Text
                variant="Caption_B"
                pl={4}
                color="#505050"
                fontSize={{ xs: 12 }}
              >
                {intl.formatMessage({
                  id: 'features.users.notificationSettings.dialog.form.email.heartbeat.description',
                })}
              </Text>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" columnGap={2}>
            <CancelButton onClick={handleClose} data-testid="CloseButton" />
            <SubmitButton
              loading={notificationSettingsMutation.status === 'loading'}
              disalbed={notificationSettingsMutation.status === 'loading'}
            >
              {intl.formatMessage({
                id: 'features.users.notificationSettings.dialog.buttons.submit',
              })}
            </SubmitButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function RedirectionError({ errorCode }: { errorCode: string }) {
  switch (errorCode) {
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'bad_request':
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}
