import styled from 'styled-components';
import { TableCell as _TableCell } from '@mui/material';

const TableHeadCell = styled(_TableCell)`
  border-bottom-color: #828282;
  border-bottom-width: 1px;
  border-top: solid;
  border-top-color: #000;
  border-top-width: 2px;
  font-size: ${(props) => props.theme.typography.pxToRem(15)};
  font-weight: bold;
  padding-bottom: ${(props) => props.theme.spacing(1.5)};
  padding-left: ${(props) => props.theme.spacing(1)};
  padding-right: ${(props) => props.theme.spacing(1)};
  padding-top: ${(props) => props.theme.spacing(1.5)};
  white-space: nowrap;
`;

export default TableHeadCell;
