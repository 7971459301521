import { Form as FormikForm, FormikProvider } from 'formik';
import React from 'react';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import ValidationBox from '../../../../commons/form/ValidationBox';
import SubmitButton from '../../../../commons/form/SubmitButton';
import useFormikInstance from './form/useFormikInstance';
import PasswordField from './form/PasswordField';
import PasswordConfirmationField from './form/PasswordConfirmationField';

interface FormProps {
  registrationId: string;
}

const Form: React.FC<FormProps> = ({ registrationId }) => {
  const intl = useIntl();
  const formik = useFormikInstance(registrationId);

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <ValidationBox>
          <PasswordField />
        </ValidationBox>
        <ValidationBox>
          <PasswordConfirmationField />
        </ValidationBox>
        <Box mt={4} mb={4}>
          <SubmitButton data-testid="SubmitButton" fullWidth>
            <Box>
              {intl.formatMessage({
                id: 'registrations.users.new.form.submit',
              })}
            </Box>
          </SubmitButton>
        </Box>
      </FormikForm>
    </FormikProvider>
  );
};

export default Form;
