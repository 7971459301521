import React from 'react';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import OtherSettingComponent from 'src/domains/root/features/other-setting';

const OtherSetting: React.FC = () => {
  return (
    <MainContents
      notAllowOverflow
      showTopButton={false}
      showContractExpiredAlert
    >
      <OtherSettingComponent />
    </MainContents>
  );
};

export default OtherSetting;
