import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import {
  alertRestoreDialogOpenSelector,
  handleAlertRestoreDialogClose,
  putAlert,
  targetAlertSelector,
  updateAlerts,
  updateResultSelector,
} from 'src/domains/root/features/alerts/slice';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import IndentLineIcon from 'src/domains/root/commons/settings/dialog/IndentLineIcon';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import AlertTypeChip from '../../sensor-units/AlertTypeChip';
import { getAlertState } from '../../current-alert/slice';

const AlertRestoreDialog: React.FC = () => {
  const intl = useIntl();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);

  const targetAlert = useSelector(targetAlertSelector);
  const { gatewayName, sensorUnit } = targetAlert;

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  const { status: updateStatus, error: updateError } =
    useSelector(updateResultSelector);

  const open = useSelector(alertRestoreDialogOpenSelector);

  const handleClose = () => {
    dispatch(handleAlertRestoreDialogClose());
    setCompleted(false);
    setError(false);
  };

  const handleSubmit = () => {
    setCompleted(false);
    setError(false);

    if (sensorUnit) {
      const token = getAccessTokenSilently();

      dispatch(
        putAlert({
          token,
          workspaceId: workspace.workspaceId,
          alertId: sensorUnit.alertId,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(updateAlerts({ alertId: sensorUnit.alertId }));

          dispatch(
            getAlertState({
              token,
              workspaceId: workspace.workspaceId,
              silent: true,
            }),
          );
          setCompleted(true);
        })
        .catch((error) => {
          setError(true);
          awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        });
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (open) {
      awsRum().then((rum) => {
        rum.recordPageView(location.pathname + '#AlertRestoreDialogRender');
      });
    }
  }, [location, open]);

  return (
    sensorUnit && (
      <Dialog
        open={open}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-restore-dialog"
        onClose={handleClose}
      >
        <LoadingOverlay open={updateStatus === 'loading'} />
        <DialogTitle id="alert-restore-dialog-title">
          <Title onClose={handleClose}>
            {intl.formatMessage({
              id: 'pages.CurrentAlert.restore.dialog.title',
            })}
          </Title>
          {error && updateError && (
            <Box mt={2.5} mb={0.5}>
              <Alert severity="error">
                {intl.formatMessage({
                  id: `pages.CurrentAlert.restore.dialog.alert.error.${updateError.code}`,
                })}
              </Alert>
            </Box>
          )}
          {completed && (
            <Box mt={2.5} mb={0.5}>
              <Alert severity="success">
                {intl.formatMessage({
                  id: 'pages.CurrentAlert.restore.dialog.alert.success.completed',
                })}
              </Alert>
            </Box>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1} mb={3.5}>
            <Typography>
              {intl.formatMessage({
                id: 'pages.CurrentAlert.restore.dialog.description',
              })}
            </Typography>
            <Typography
              paddingTop={3}
              fontSize={14}
              sx={{ wordBreak: 'break-all' }}
            >
              {gatewayName}
            </Typography>
            <Box display="flex" gap={1.5} paddingTop={0.5} paddingLeft={1.5}>
              <Box position="relative" top={6}>
                <IndentLineIcon />
              </Box>
              <Typography fontWeight={700} sx={{ wordBreak: 'break-all' }}>
                {sensorUnit.name}
              </Typography>
            </Box>
            <Box display="flex" paddingTop={2.5} alignItems="baseline">
              <Typography fontSize={14}>
                {intl.formatMessage({
                  id: 'pages.CurrentAlert.restore.dialog.alertType',
                })}
              </Typography>
              <Box minWidth={116}>
                <AlertTypeChip
                  type={sensorUnit.alertType}
                  reason={sensorUnit.reason}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" gap={1.5}>
            <CancelButton onClick={handleClose} />
            <PrimaryButton
              data-testid="RestoreAlertButton"
              onClick={handleSubmit}
              disabled={completed}
            >
              {intl.formatMessage({
                id: 'pages.CurrentAlert.restore.dialog.button.yes',
              })}
            </PrimaryButton>
          </Box>
        </DialogActions>
      </Dialog>
    )
  );
};

export default AlertRestoreDialog;
