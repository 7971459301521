import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthProvider from './auth/AuthProvider';
import RootApp from './domains/root/App';
import RegistrationApp from './domains/registration/App';
import GlobalErrorBoundary from './utils/error-boundary/GlobalErrorBoundary';
import { RumErrorRecorder, RumPageViewRecorder } from './utils/rum';
import InvitationApp from './domains/invitation/App';
import InternalSupportApp from './domains/internal-support/App';

const root = createRoot(document.getElementById('root')!);

if (import.meta.env.MODE === 'mock') {
  Promise.all([
    import('./mock/api'),
    import('./mock/auth/MockedAuthProvider'),
  ]).then(([{ mockApiCall }, { default: MockedAuthProvider }]) => {
    mockApiCall();
    root.render(
      <GlobalErrorBoundary>
        <BrowserRouter>
          <MockedAuthProvider>
            <Routes>
              <Route
                path="/internal-support"
                element={<InternalSupportApp />}
              />
              <Route path="/registration/*" element={<RegistrationApp />} />
              <Route path="/invitation/*" element={<InvitationApp />} />
              <Route path="/*" element={<RootApp />} />
            </Routes>
          </MockedAuthProvider>
        </BrowserRouter>
      </GlobalErrorBoundary>,
    );
  });
} else if (import.meta.env.MODE === 'demo') {
  Promise.all([
    import('./mock/api'),
    import('./mock/auth/MockedAuthProvider'),
  ]).then(([{ demoApiCall }, { default: MockedAuthProvider }]) => {
    demoApiCall();
    root.render(
      <GlobalErrorBoundary>
        <BrowserRouter>
          <MockedAuthProvider>
            <Routes>
              <Route path="/registration/*" element={<RegistrationApp />} />
              <Route path="/invitation/*" element={<InvitationApp />} />
              <Route path="/*" element={<RootApp />} />
            </Routes>
          </MockedAuthProvider>
        </BrowserRouter>
      </GlobalErrorBoundary>,
    );
  });
} else {
  root.render(
    <GlobalErrorBoundary>
      <RumErrorRecorder>
        <BrowserRouter>
          <RumPageViewRecorder>
            <AuthProvider>
              <Routes>
                <Route
                  path="/internal-support"
                  element={<InternalSupportApp />}
                />
                <Route path="/registration/*" element={<RegistrationApp />} />
                <Route path="/invitation/*" element={<InvitationApp />} />
                <Route path="/*" element={<RootApp />} />
              </Routes>
            </AuthProvider>
          </RumPageViewRecorder>
        </BrowserRouter>
      </RumErrorRecorder>
    </GlobalErrorBoundary>,
  );
}
