import { configureStore } from '@reduxjs/toolkit';
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import registrationsReducer from '../features/registrations/slice';
import registrationsUsersReducer from '../features/registrations/users/slice';
import usersReducer from '../features/users/slice';

export const store = configureStore({
  reducer: {
    registrations: registrationsReducer,
    registrationsUsers: registrationsUsersReducer,
    users: usersReducer,
  },
});

export type RegistrationState = ReturnType<typeof store.getState>;
export type Status = 'idle' | 'loading' | 'succeeded' | 'failed';
export type AppDispatch = typeof store.dispatch;

export interface Result<DATA, ERROR> {
  data: DATA | null;
  error: ERROR | null;
  status: Status;
}

export const useSelector: TypedUseSelectorHook<RegistrationState> =
  rawUseSelector;
