import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getRegistration,
  showResultSelector,
  Registration,
} from '../features/registrations/slice';
import { AppDispatch, useSelector } from '../store';
import { handleShowResponseError } from '../utils/registrations/api';

interface RegistrationRequiredProps {
  children: (registration: Registration | null) => React.ReactNode;
}

const RegistrationRequired: React.FC<RegistrationRequiredProps> = ({
  children,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { data, error } = useSelector(showResultSelector);
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const registrationId = query.get('registration_id') ?? '';
    const promise = dispatch(
      getRegistration({
        registrationId,
        onResponseError: handleShowResponseError,
      }),
    );
    return () => promise.abort();
  }, [dispatch, search]);

  if (error?.recoverable) {
    return <>{children(null)}</>;
  }

  if (data) {
    return <>{children(data)}</>;
  }

  return null;
};

export default RegistrationRequired;
