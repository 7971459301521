import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../store';
import { Invitation, putInvitation } from '../features/invitations/slice';

const HOME_PATH = '/';

interface SigninProps {
  invitation: Invitation;
}

const Signin: React.FC<SigninProps> = ({ invitation }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { invitationId } = invitation;
    const token = getAccessTokenSilently();
    const promise = dispatch(putInvitation({ token, invitationId }));
    promise.unwrap().then(() => navigate(HOME_PATH));
    // return () => promise.abort(); // It seems better not to abort.
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

export default Signin;
