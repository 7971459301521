import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme, Box, Alert } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { IMEI_REGEXP, CODE_REGEXP } from 'src/utils/gateways/validation';
import QrCodeReaderDialog from 'src/domains/root/commons/dialog/QrCodeReaderDialog';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import BackButton from 'src/domains/root/commons/buttons/Back';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import { AppDispatch } from 'src/domains/root/store';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import {
  postWorkspace,
  saveWorkspaceParams,
  workspaceParamsSelector,
  resetError,
  createResultSelector,
} from '../../workspaces/slice';
import CodeField from './form/RegistrationCodeField';
import IMEIField from './form/IMEIField';
import GatewayNameField from './form/GatewayNameField';

interface FormValues {
  registrationCode: string;
  imei: string;
  gatewayName: string;
}

type Props = {
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const Form: React.FC<Props> = (props) => {
  const { dialogOpen, setDialogOpen } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const { status, error } = useSelector(createResultSelector);
  const workspaceParams = useSelector(workspaceParamsSelector);
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = yup.object().shape({
    registrationCode: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.Setup.Gateway.new.form.registrationCode.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        CODE_REGEXP,
        intl.formatMessage({
          id: 'pages.Setup.Gateway.new.validation.registrationCode.matches',
        }),
      ),
    imei: yup
      .string()
      .label(
        intl.formatMessage({ id: 'pages.Setup.Gateway.new.form.imei.label' }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        IMEI_REGEXP,
        intl.formatMessage({
          id: 'pages.Setup.Gateway.new.validation.imei.matches',
        }),
      ),
    gatewayName: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.Setup.Gateway.new.form.registrationCode.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .max(200, intl.formatMessage({ id: 'validation.string.max' })),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      registrationCode: workspaceParams?.registrationCode ?? '',
      imei: workspaceParams?.imei ?? '',
      gatewayName: workspaceParams?.gatewayName ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { registrationCode, imei, gatewayName } = values;
      const token = getAccessTokenSilently();
      return dispatch(
        postWorkspace({ token, registrationCode, imei, gatewayName }),
      );
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleBack = () => {
    const { registrationCode, imei, gatewayName } = formik.values;
    dispatch(saveWorkspaceParams({ registrationCode, imei, gatewayName }));
    dispatch(resetError());
    navigate('/setup/workspace');
  };

  const qrCodeReaderDialogProps = {
    formik,
    dialogOpen,
    setDialogOpen,
  };

  return (
    <>
      <LoadingOverlay open={status === 'loading'} />
      {status === 'failed' && error && (
        <Box mb={2}>
          <Alert severity="error">
            {intl.formatMessage({
              id: `pages.Setup.Gateway.new.error.${error.code}`,
            })}
          </Alert>
        </Box>
      )}
      <FormikProvider value={formik}>
        <FormikForm>
          <ValidationBox>
            <CodeField />
          </ValidationBox>
          <ValidationBox>
            <IMEIField />
          </ValidationBox>
          <ValidationBox>
            <GatewayNameField />
          </ValidationBox>
          <Box
            display="flex"
            mt={4}
            justifyContent="space-between"
            flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
            rowGap={2}
            columnGap={3}
          >
            <Box flex={1}>
              <BackButton
                sx={{
                  height: 44,
                  fontSize: (theme) => theme.typography.pxToRem(18),
                }}
                onClick={handleBack}
              />
            </Box>
            <Box flex={2}>
              <SubmitButton
                data-testid="SubmitButton"
                fullWidth
                sx={{
                  height: 44,
                  fontSize: (theme) => theme.typography.pxToRem(18),
                }}
              >
                {intl.formatMessage({
                  id: 'pages.Setup.Gateway.new.form.submit',
                })}
              </SubmitButton>
            </Box>
          </Box>
        </FormikForm>
        {dialogOpen && <QrCodeReaderDialog {...qrCodeReaderDialogProps} />}
      </FormikProvider>
    </>
  );
};

export default Form;
