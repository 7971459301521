import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';
import * as API from 'src/apis';
import { API_ENDPOINT } from 'src/constants';

/**
 * @deprecated `src/apis` のタイプを使ってください。
 * */
export type Alert = API.Alert;

/**
 * @deprecated `src/apis` のタイプを使ってください。
 * */
export type AlertSensorUnit = API.AlertSensorUnits;

/**
 * @deprecated `src/apis` のタイプを使ってください。
 * */
export type Reason = API.AlertReason;

/**
 * @deprecated `src/apis` のタイプを使ってください。
 * */
export type AlertType = API.AlertType;

export interface GetAlertsParams {
  token: Promise<string>;
  workspaceId: string;
}

interface GetAlertsResponse {
  alerts: API.Alert[];
}

export type AlertsErrorCode = GetAlertsErrorCode | PutAlertErrorCode;

type GetAlertsErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getAlerts = createAsyncThunk<
  API.Alert[],
  GetAlertsParams,
  { state: RootState; rejectValue: RootError<GetAlertsErrorCode> }
>('gateways/getAlerts', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const res = await axios.get<GetAlertsResponse>(
      `${API_ENDPOINT}/alerts/${params.workspaceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res.data.alerts;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const gatewaysError = getAlertsError(error);
    return thunkAPI.rejectWithValue(gatewaysError);
  }
});

const getAlertsError = (
  error: AxiosError<RootErrorResponse>,
): RootError<GetAlertsErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }

  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

interface PutAlertParams {
  token: Promise<string>;
  workspaceId: string;
  alertId: string;
}

type PutAlertErrorCode =
  | 'restored_alert'
  | 'permission_denied'
  | 'admin_only'
  | 'not_found'
  | 'emergency_maintenance'
  | 'unknown_error';

export const putAlert = createAsyncThunk<
  void,
  PutAlertParams,
  {
    state: RootState;
    rejectValue: RootError<PutAlertErrorCode>;
  }
>('gateways/putAlert', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId, alertId } = params;

  try {
    await axios.put<void>(
      `${API_ENDPOINT}/alerts/${workspaceId}/alerts/${alertId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const alertError = getPutAlertError(error);
    return thunkAPI.rejectWithValue(alertError);
  }
});

const getPutAlertError = (
  error: AxiosError<RootErrorResponse>,
): RootError<PutAlertErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;

    switch (status) {
      case 400: {
        switch (errorCode) {
          case 'RESTORED_ALERT':
            return {
              code: 'restored_alert',
              noticeable: false,
              recoverable: true,
            };
        }
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
      }

      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }

      case 404: {
        return {
          code: 'not_found',
          noticeable: false,
          recoverable: true,
        };
      }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
    }
  }

  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }

  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

type TargetAlert = {
  gatewayName: string | null;
  sensorUnit: API.AlertSensorUnits | null;
};

export interface AlertsState {
  listResult: Result<API.Alert[], RootError<GetAlertsErrorCode>>;
  updateResult: Result<void, RootError<PutAlertErrorCode>>;
  targetAlert: TargetAlert;
  alertRestoreDialogOpen: boolean;
}

const initialState: AlertsState = {
  listResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  updateResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  targetAlert: {
    gatewayName: null,
    sensorUnit: null,
  },
  alertRestoreDialogOpen: false,
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    updateTargetAlert(state, action: PayloadAction<TargetAlert>) {
      const { gatewayName, sensorUnit } = action.payload;

      state.targetAlert.gatewayName = gatewayName;
      state.targetAlert.sensorUnit = sensorUnit;
    },
    updateAlerts(state, action) {
      const { alertId } = action.payload;

      state.listResult.data =
        state.listResult.data?.map((gateway) => {
          gateway.sensorUnits = gateway.sensorUnits.filter(
            (sensorUnit) => sensorUnit.alertId !== alertId,
          );

          return gateway;
        }) ?? [];

      state.listResult.data = state.listResult.data.filter(
        (gateway) => gateway.sensorUnits.length !== 0,
      );
    },
    handleAlertRestoreDialogOpen(state) {
      state.alertRestoreDialogOpen = true;
    },
    handleAlertRestoreDialogClose(state) {
      state.alertRestoreDialogOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlerts.pending, (state) => {
        state.listResult.data = null;
        state.listResult.status = 'loading';
        state.listResult.error = null;
      })
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.listResult.data = action.payload;
        state.listResult.status = 'succeeded';
        state.listResult.error = null;
      })
      .addCase(getAlerts.rejected, (state, action) => {
        state.listResult.data = null;
        state.listResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.listResult.error = action.payload!;
      })
      .addCase(putAlert.pending, (state) => {
        state.updateResult.data = null;
        state.updateResult.status = 'loading';
        state.updateResult.error = null;
      })
      .addCase(putAlert.fulfilled, (state, action) => {
        state.updateResult.data = action.payload;
        state.updateResult.status = 'succeeded';
        state.updateResult.error = null;
      })
      .addCase(putAlert.rejected, (state, action) => {
        state.updateResult.data = null;
        state.updateResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.updateResult.error = action.payload!;
      });
  },
});

export const listResultSelector = createSelector(
  (state: RootState) => state.alerts,
  (state: AlertsState) => state.listResult,
);

export const updateResultSelector = createSelector(
  (state: RootState) => state.alerts,
  (state: AlertsState) => state.updateResult,
);

export const targetAlertSelector = createSelector(
  (state: RootState) => state.alerts,
  (state: AlertsState) => state.targetAlert,
);

export const alertRestoreDialogOpenSelector = createSelector(
  (state: RootState) => state.alerts,
  (state: AlertsState) => state.alertRestoreDialogOpen,
);

export const {
  updateTargetAlert,
  updateAlerts,
  handleAlertRestoreDialogOpen,
  handleAlertRestoreDialogClose,
} = alertsSlice.actions;

export default alertsSlice.reducer;
