import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function GuideMessage() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <Box pt={3} pb={4}>
      <Typography component="h1" variant="h4">
        ワークスペースへアクセス
      </Typography>
      <Typography variant="body1" pb={2}>
        本画面からアクセスするワークスペースには、当日中のみアクセス可能です
      </Typography>
      <Button
        variant="contained"
        onClick={goToHome}
        sx={{ display: 'block', ml: 'auto' }}
      >
        ホームに戻る
      </Button>
    </Box>
  );
}
