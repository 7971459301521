import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import theme from 'src/domains/root/styles/theme';
import FormikSubmitLoadingButton from 'src/domains/root/commons/FormkiSubmitLoadingButton';
import type { Location } from '../LocationSettingModal';

const buttonBaseStyle = css`
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(1)};
  min-width: 100px;
`;

const dividerStyle = css`
  background: #828282;
  opacity: 0.5;
`;

const dialogActionStyle = css`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(3.5)};

  ${theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
    justify-content: center;

    > * {
      margin: ${theme.spacing(0.5)};
    }
  }
`;

interface StyledNoSettingButtonProps {
  valuesAreaId: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledNoSettingButton = styled(({ valuesAreaId, ...props }) => (
  <Button {...props} />
)).attrs(() => ({
  varinat: 'text',
}))<StyledNoSettingButtonProps>`
  &:hover {
    background: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.common.white};
  }

  color: ${(props) => {
    return props.valuesAreaId === 'noLocation'
      ? 'white'
      : `${props.theme.palette.primary.main}`;
  }};

  background-color: ${(props) => {
    return props.valuesAreaId === 'noLocation'
      ? `${props.theme.palette.primary.main}`
      : 'white';
  }};

  border: 2px solid ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  ${buttonBaseStyle}
`;

const closeButtonStyle = css`
  &:hover {
    background: #5a5a5a;
    color: white;
  }

  background: #e6e6e6;
  border: 2px solid #5a5a5a;
  color: #5a5a5a;
  font-weight: bold;
  ${buttonBaseStyle}
`;

const backButtonStyle = css`
  &:hover {
    background: ${(props) => props.theme.palette.primary.main};
    color: white;
  }

  border: 2px solid ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  ${buttonBaseStyle}
`;

interface ModalActionProps {
  handleClose: () => void;
  toggleIsRegionPage: () => void;
  isRegionPage: boolean;
  contentLoading: boolean;
  putLoading: boolean;
}

const ModalAction: React.FC<ModalActionProps> = (props) => {
  const {
    handleClose,
    toggleIsRegionPage,
    isRegionPage,
    contentLoading,
    putLoading,
  } = props;
  const intl = useIntl();
  const { values, setValues, handleSubmit } = useFormikContext<Location>();

  const handleBack = () => {
    setValues({
      ...values,
      areaId: '',
      area: '',
    });
    toggleIsRegionPage();
  };

  const handleNoLocationClick = () => {
    setValues({
      ...values,
      prefecture: isRegionPage ? 'noLocation' : values.prefecture,
      areaId: 'noLocation',
      area: intl.formatMessage({ id: 'gatewayInfo.noLocation' }),
    });
  };

  return (
    <>
      {!contentLoading && (
        <form onSubmit={handleSubmit}>
          <Divider css={dividerStyle} />
          <DialogActions css={dialogActionStyle}>
            <Box>
              <StyledNoSettingButton
                valuesAreaId={values.areaId}
                onClick={handleNoLocationClick}
              >
                {intl.formatMessage({
                  id: 'gatewayInfo.noSettingLocation',
                })}
              </StyledNoSettingButton>
            </Box>
            <Box display="flex">
              <Button onClick={handleClose} css={closeButtonStyle}>
                {intl.formatMessage({
                  id: 'common.cancel',
                })}
              </Button>
              {!isRegionPage && (
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  css={backButtonStyle}
                >
                  {intl.formatMessage({
                    id: 'gatewayInfo.back',
                  })}
                </Button>
              )}
              <FormikSubmitLoadingButton loading={putLoading} />
            </Box>
          </DialogActions>
        </form>
      )}
    </>
  );
};

export default ModalAction;
