import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';

const _CityInitialDividerIcon = createSvgIcon(
  <rect width="40" height="1" fill="#F05064" />,
  'CityInitialDivider',
);

const CityInitialDividerIcon = styled(({ ...props }) => (
  <_CityInitialDividerIcon {...props} />
)).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 40 1',
}))<SvgIconProps>`
  height: 1px;
  width: 40px;
`;

export default CityInitialDividerIcon;
