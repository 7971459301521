import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/system';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CardNumber from 'src/domains/root/commons/cards/Number';
import CardPaper from 'src/domains/root/commons/cards/Paper';
import { Gateway, SensorUnit } from 'src/domains/root/features/gateways/slice';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from 'src/domains/root/features/gateways/settings/alarm/NotificationsIcon';
import SettingsIcon from '../gateways/settings/mode/SettingsIcon';
import SensorUnitName from '../sensor-units/SensorUnitName';
import UnitizedValue from '../sensor-units/UnitizedValue';
import Chip from '../gateways/data/ChipV3';

type Props = {
  sensorUnit: SensorUnit;
  gateway: Gateway;
  setAlarmSettingModalData: (props: {
    sensorUnit: SensorUnit;
    gateway: Gateway;
  }) => void;
  setEditModalData: (props: {
    sensorUnit: SensorUnit;
    gateway: Gateway;
  }) => void;
};

/** 全体的に使っているbreakpointsではないが、レイアウトが崩れるので対応 */
const smallMobileWidth = 340;

const SensorSettingCard = (props: Props) => {
  const { sensorUnit, gateway, setAlarmSettingModalData, setEditModalData } =
    props;

  return (
    <>
      <CardNumber number={sensorUnit.number} />
      <CardPaper>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ overflowWrap: 'anywhere' }}
        >
          <SensorUnitName
            batteryOnTop
            powerSupply={sensorUnit.powerSupply}
            battery={sensorUnit.battery}
          >
            {sensorUnit.name}
          </SensorUnitName>
          <Box display="flex" pl={1} height="100%" gap={1.5}>
            <IconButton
              data-testid="AlarmSettingModalButton"
              onClick={() => setAlarmSettingModalData({ sensorUnit, gateway })}
              size="large"
              sx={{
                p: 1,
                width: 38,
                height: 38,
                bgcolor: '#DCDCDC',
              }}
            >
              <NotificationsIcon isEnabled={sensorUnit.isAlarmEnabled} />
            </IconButton>
            <IconButton
              data-testid="EditModeDialogButton"
              onClick={() => setEditModalData({ sensorUnit, gateway })}
              size="large"
              sx={{
                p: 1,
                width: 38,
                height: 38,
                bgcolor: '#DCDCDC',
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        </Box>
        <SensorStatusBox>
          <ChipWithValue sensorUnit={sensorUnit} type="status" />
        </SensorStatusBox>
      </CardPaper>
    </>
  );
};

function SensorStatusBox({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      pt={1.5}
      gap={1.5}
      sx={(theme) => ({
        [theme.breakpoints.down(smallMobileWidth)]: {
          flexWrap: 'wrap',
        },
      })}
    >
      {children}
    </Box>
  );
}

function ChipWithValue({
  sensorUnit,
  type,
}: {
  sensorUnit: SensorUnit;
  type: 'status';
}) {
  const intl = useIntl();
  const theme = useTheme();

  const bgColor = {
    status: '#E2DCD2',
  };

  return (
    <Box display="flex" gap={1.5} width="100%">
      <Chip
        textColor={theme.palette.text.primary}
        bgColor={bgColor[type]}
        fontWeight="bold"
        maxWidth="112px"
        width="100%"
        label={intl.formatMessage({
          id: `common.${type}`,
        })}
      />
      <Box display="flex" justifyContent="center" width="100%" maxWidth={50}>
        {type === 'status' ? (
          sensorUnit.isAlive === undefined ? (
            intl.formatMessage({
              id: 'common.unconnected',
            })
          ) : sensorUnit.isAlive === true ? (
            <CheckIcon />
          ) : (
            <CloseIcon />
          )
        ) : (
          <UnitizedValue value={sensorUnit[type]} type={type} />
        )}
      </Box>
    </Box>
  );
}

export default SensorSettingCard;
