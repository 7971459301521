import React from 'react';
import { useIntl } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type Props = {
  isTermsOfServiceScrollEnd: boolean;
  onChangeCheckbox: (checked: boolean) => void;
  isSmallScreen: boolean;
};

const TermsOfService: React.FC<Props> = (props: Props) => {
  const { isTermsOfServiceScrollEnd, onChangeCheckbox, isSmallScreen } = props;

  const intl = useIntl();

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(event) => {
            onChangeCheckbox(event.target.checked);
          }}
          name="termsOfService"
          disabled={!isTermsOfServiceScrollEnd}
          data-testid="TermsOfServiceCheckbox"
          sx={{
            color: 'primary.main',
          }}
        />
      }
      label={
        <Typography
          variant="body2"
          sx={[
            !isTermsOfServiceScrollEnd && {
              opacity: 0.2,
            },
          ]}
        >
          <Box
            component="span"
            fontWeight="bold"
            fontSize={isSmallScreen ? 13 : 15}
          >
            {intl.formatMessage({
              id: 'pages.TermsOfServiceReagreement.agreeTermsOfService',
            })}
          </Box>
        </Typography>
      }
    />
  );
};

export default TermsOfService;
