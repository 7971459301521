import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QrReaderButton from 'src/domains/root/commons/buttons/QrReader';
import QrCodeWaterMark from 'src/domains/root/commons/QrCodeWaterMarkV3';
import SubTitle from './new/SubTtitle';
import Form from './new/Form';
import ImageBox from './new/ImageBox';

const New: React.FC = () => {
  const intl = useIntl();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Box pt={2}>
        <Typography>
          {intl.formatMessage({
            id: 'pages.Setup.Gateway.new.description.normal',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'pages.Setup.Gateway.new.description.qrCode',
          })}
        </Typography>
      </Box>
      <Box mt={5}>
        <Box
          display="flex"
          gap={6.5}
          justifyContent="space-evenly"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          })}
        >
          <Box flexGrow={2} maxWidth={468}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <SubTitle />
              <QrReaderButton onClick={handleDialogOpen} />
            </Box>
            <Box mt={4.5}>
              <Form dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
            </Box>
          </Box>
          <Box
            flexGrow={1}
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                maxWidth: 304,
              },
            })}
          >
            <ImageBox />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" pt={2}>
        <QrCodeWaterMark />
      </Box>
    </>
  );
};

export default New;
