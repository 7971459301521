import React from 'react';
import { useFormikContext } from 'formik';
import Button, { ButtonProps } from '@mui/material/Button';
import PrimaryButton from '../buttons/PrimaryButton';

const SubmitButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <PrimaryButton type="submit" disabled={!isValid || isSubmitting} {...rest}>
      {children}
    </PrimaryButton>
  );
};

export default SubmitButton as typeof Button;
