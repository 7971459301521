import React from 'react';
import Box from '@mui/material/Box';
import { css } from 'styled-components/macro';
import Typography from '@mui/material/Typography';
import CloseIconButton from './title/CloseIconButton';

const titleBoxStyle = css`
  position: relative;
`;

const closeIconButtonStyle = css`
  position: absolute;
  right: -${(props) => props.theme.spacing(1.5)};
  top: -${(props) => props.theme.spacing(1)};
`;

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

/**
 * @deprecated './DialogTitle.tsx' を使ってください。
 * */
export default function Title(props: Props) {
  const { onClose: handleClose, children } = props;

  return (
    <Box color={(theme) => theme.palette.primary.main} css={titleBoxStyle}>
      <Typography
        fontWeight="bold"
        fontSize={(theme) => theme.typography.pxToRem(18)}
        pr={3}
      >
        {children}
      </Typography>
      <CloseIconButton onClick={handleClose} css={closeIconButtonStyle} />
    </Box>
  );
}
