import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/domains/root/store';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import Typography from '@mui/material/Typography';
import { createResultSelector, resetCreateInvitation } from './slice';
import Form from './new/Form';

const New: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();

  const { data: invitation, error, status } = useSelector(createResultSelector);

  useEffect(() => {
    return () => {
      dispatch(resetCreateInvitation());
    };
  }, [dispatch]);

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  return (
    <>
      <LoadingOverlay open={status === 'loading'} />
      {error && (
        <Box mb={3.5}>
          <Alert severity="error" sx={{ wordBreak: 'break-all' }}>
            {intl.formatMessage(
              {
                id: `pages.OtherSetting.users.invitation.alert.error.${error.code}`,
              },
              {
                email: error.email,
                workspaceName: workspace.workspaceName,
              },
            )}
          </Alert>
        </Box>
      )}
      {invitation && (
        <Box data-testid="InvitationSentAlert" mb={3.5}>
          <Alert>
            {import.meta.env.MODE === 'demo' ? (
              intl.formatMessage({
                id: 'pages.OtherSetting.users.invitation.alert.complete.demo',
              })
            ) : (
              <>
                {intl.formatMessage(
                  {
                    id: 'pages.OtherSetting.users.invitation.alert.complete.title',
                  },
                  {
                    email: (
                      <span
                        style={{
                          wordBreak: 'break-all',
                          WebkitLineBreak: 'anywhere',
                        }}
                      >
                        {invitation.email}
                      </span>
                    ),
                    workspaceName: (
                      <span
                        style={{
                          wordBreak: 'break-all',
                          WebkitLineBreak: 'anywhere',
                        }}
                      >
                        {workspace.workspaceName}
                      </span>
                    ),
                  },
                )}
                <br />
                {intl.formatMessage(
                  {
                    id: 'pages.OtherSetting.users.invitation.alert.complete.description',
                  },
                  {
                    workspaceName: workspace.workspaceName,
                  },
                )}
              </>
            )}
          </Alert>
        </Box>
      )}
      <Box mb={5} sx={{ wordBreak: 'break-all' }}>
        <Typography>
          {intl.formatMessage(
            {
              id: 'pages.OtherSetting.users.invitation.description',
            },
            {
              workspaceName: <b>{workspace.workspaceName}</b>,
            },
          )}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'pages.OtherSetting.users.invitation.mailDescription',
          })}
        </Typography>
      </Box>
      <Box mx="auto" maxWidth={468}>
        <Form workspace={workspace} />
      </Box>
    </>
  );
};

export default New;
