import { useMemo } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { AppDispatch } from '../../../../../store';
import { postUser } from '../../slice';
import { handleShowResponseError } from '../../../../../utils/registrations/api';
import useValidationSchema from './useValidationSchema';

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useFormikInstance = (registrationId: string) => {
  const dispatch: AppDispatch = useDispatch();

  const { passwordValidationSchema, passwordConfirmationValidationSchema } =
    useValidationSchema();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password: passwordValidationSchema,
        passwordConfirmation: passwordConfirmationValidationSchema,
      }),
    [passwordValidationSchema, passwordConfirmationValidationSchema],
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { password } = values;
      return dispatch(
        postUser({
          registrationId,
          password,
          onResponseError: handleShowResponseError,
        }),
      );
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  return formik;
};

export default useFormikInstance;
