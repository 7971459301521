import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import * as API from 'src/apis';
import { handleError } from './error';

type Props = {
  workspaceId: string;
  workspaceInvoicePaymentActivating: API.WorkspaceInvoicePaymentActivating;
  token: string;
};

export async function putWorkspacesPaymentsInvoicesActivating({
  workspaceId,
  workspaceInvoicePaymentActivating,
  token,
}: Props): Promise<void> {
  return axios
    .put(
      `${API_ENDPOINT}/workspaces/${workspaceId}/payments/invoices/activating`,
      workspaceInvoicePaymentActivating,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
