import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box, Typography, Alert } from '@mui/material';
import useSmallScreen from 'src/domains/registration/hooks/use-small-screen';
import CabiotLogo from '../../commons/CabiotLogo';
import Form from './new/Form';
import Agreement from './new/Agreement';
import { newResultSelector as registrationsNewSelector } from './slice';
import TermsOfServiceDocument from './new/agreement/TermsOfServiceDocument';

export interface Values {
  email: string;
  password: string;
  passwordConfirmation: string;
  code: string;
  agreement: boolean;
}

const New: React.FC = () => {
  const { error } = useSelector(registrationsNewSelector);
  const intl = useIntl();

  const isSmallScreen = useSmallScreen();
  const [customerAgreementPage, setCustomerAgreementPage] = useState(true);
  const [isTermsOfServiceScrollEnd, setIsTermsOfServiceScrollEnd] =
    useState(false);

  if (customerAgreementPage) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        py={isSmallScreen ? 2 : 6}
        px={2.5}
        minHeight={680}
        height="100vh"
        maxHeight={800}
      >
        <TermsOfServiceDocument
          setIsTermsOfServiceScrollEnd={setIsTermsOfServiceScrollEnd}
        />
        <Agreement
          onSubmitAgree={() => setCustomerAgreementPage(false)}
          isTermsOfServiceScrollEnd={isTermsOfServiceScrollEnd}
        />
      </Box>
    );
  } else {
    return (
      <>
        <Box display="flex" justifyContent="center" mt={7.5}>
          <CabiotLogo />
        </Box>
        <Box mt={8.5} mb={7}>
          <Typography variant="h2" align="center">
            {intl.formatMessage({
              id: 'registrations.new.form.title',
            })}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          px={2.5}
          margin="0 auto"
          maxWidth={500}
        >
          {error && error.recoverable && (
            <Box mb={4}>
              <Alert severity="error">
                {intl.formatMessage({
                  id: `registrations.new.error.${error.code}`,
                })}
              </Alert>
            </Box>
          )}
          <Box
            sx={{
              form: { width: '100%' },
            }}
          >
            <Form />
          </Box>
        </Box>
      </>
    );
  }
};

export default New;
