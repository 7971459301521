import * as yup from 'yup';

const EMAIL_MAX_LENGTH = 254;
const LOCAL_PART_MAX_LENGTH = 64;
const DOMAIN_MAX_LENGTH = 255;
const EMAIL_PART_LENGTH_REGEX = new RegExp(
  `^.{1,${LOCAL_PART_MAX_LENGTH}}@.{1,${DOMAIN_MAX_LENGTH}}`,
);

export const emailLengthTester = (value: string | undefined): boolean => {
  return yup
    .string()
    .matches(EMAIL_PART_LENGTH_REGEX)
    .max(EMAIL_MAX_LENGTH)
    .isValidSync(value);
};
