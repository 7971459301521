import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Gateway, SensorUnit } from 'src/domains/root/features/gateways/slice';
import SensorUnitCard from 'src/domains/root/features/gateways/SensorUnitCard';
import NormalChartModal from 'src/domains/root/features/gateways/NormalChartModal';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import TextButton from 'src/domains/root/commons/buttons/Text';
import GatewayTableRow from './GatewayTableRow';
import GatewayData from './GatewayDataV3';
import GatewayName from './GatewayNameV3';
import GatewayPaper from './GatewayPaperV3';
import TableHeadCell from './sensor-units/TableHeadCell';
import Table from './sensor-units/Table';

type NormalGraphInput = {
  gateway: Gateway;
  sensorUnit: SensorUnit;
};

interface GatewayListProps {
  gateways: Gateway[];
}

const GatewayList: React.FC<GatewayListProps> = (props) => {
  const { gateways } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [normalGraphInput, setNormalGraphInput] =
    useState<NormalGraphInput | null>(null);

  const hideModal = () => setNormalGraphInput(null);

  const displayBattery = isSmallScreen ? false : true;

  const gatewayList = gateways.map((gateway, index) => (
    <GatewayPaper data-testid={`GatewayPaper${index}`} key={gateway.deviceId}>
      <GatewayName
        gateway={gateway}
        displayBattery={displayBattery}
        flexColumn={isSmallScreen}
        gatewayNameBoxFullWidth={isSmallScreen}
        childrenBoxFullWidth={isSmallScreen}
      >
        <Box
          display="flex"
          justifyContent={isSmallScreen ? 'start' : 'end'}
          gap={2}
        >
          {!isSmallScreen && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: '#828282' }}
            />
          )}
          <TextButton
            onClick={() => navigate(`/gateways/${gateway.physicalId}/memos`)}
            startIcon={<ModeEditIcon />}
            sx={{ minWidth: '70px' }}
          >
            {intl.formatMessage({ id: 'pages.GatewaysMemos.title' })}
          </TextButton>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: '#828282' }}
          />
          <SecondaryButton
            onClick={() => navigate(`/gateways/${gateway.physicalId}/chart`)}
          >
            {intl.formatMessage({ id: 'pages.GatewaySensorData.title' })}
          </SecondaryButton>
        </Box>
      </GatewayName>
      <Box
        p={(theme) => theme.spacing(3)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            pt: 2.5,
            px: 2,
            pb: 3.5,
          },
        })}
      >
        <Box
          mb={(theme) => theme.spacing(3.5)}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              mb: 2.5,
            },
          })}
        >
          <GatewayData gateway={gateway} />
        </Box>
        {isSmallScreen ? (
          <Box display="flex" flexDirection="column" gap={1.5}>
            {gateway.sensorUnits.map((sensorUnit, index) => (
              <Box
                key={sensorUnit.deviceId}
                data-testid={`SensorUnitCard-${index}`}
              >
                <SensorUnitCard
                  sensorUnit={sensorUnit}
                  onClickChartLinkButton={() => {
                    setNormalGraphInput({ gateway, sensorUnit });
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: '60px', minWidth: '46px' }} />
                <col style={{ width: '40%' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewayTable.sensorUnit.number',
                    })}
                  </TableHeadCell>
                  <TableHeadCell>
                    {intl.formatMessage({
                      id: 'common.sensorUnitName',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'common.status',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewayTable.sensorUnit.temperature',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewayTable.sensorUnit.humidity',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewayTable.sensorUnit.objectTemperature',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'common.graph',
                    })}
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gateway.sensorUnits.map((sensorUnit, index) => (
                  <TableRow
                    key={sensorUnit.deviceId}
                    data-testid={`GatewayTableRow-${index}`}
                  >
                    <GatewayTableRow
                      powerSupply={sensorUnit.powerSupply}
                      sensorUnit={sensorUnit}
                      onClickChartLinkButton={() => {
                        setNormalGraphInput({ gateway, sensorUnit });
                      }}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </GatewayPaper>
  ));

  return (
    <>
      {gatewayList}
      {normalGraphInput && (
        <NormalChartModal
          gateway={normalGraphInput.gateway}
          sensorUnit={normalGraphInput.sensorUnit}
          handleClose={hideModal}
        />
      )}
    </>
  );
};

export default GatewayList;
