import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import CabiotLogo from '../../commons/CabiotLogo';
import DemoSiteAlertNotification from '../../commons/layout/global-header/DemoSiteAlertNotification';

interface UnrecoverableErrorProps {
  messageId?: string;
  message?: React.ReactNode;
  buttonTextId?: string;
  buttonHref?: string;
  noButton?: boolean;
  backToPage?: boolean;
}

const APP_VERSION = import.meta.env.VITE_WEBAPP_VERSION;

const UnrecoverableError: React.FC<UnrecoverableErrorProps> = ({
  messageId,
  message,
  buttonTextId,
  buttonHref = '/',
  noButton = false,
  backToPage = false,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const currentTime = useMemo(
    () => DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
    [],
  );

  const handleBackToPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!backToPage) {
      return;
    }

    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      {import.meta.env.MODE === 'demo' && <DemoSiteAlertNotification />}
      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={7.5}>
            <CabiotLogo />
          </Box>
          <Box mt={8.5} mb={10} maxWidth={560}>
            <Typography
              variant="h4"
              align="center"
              fontWeight={700}
              fontSize={(theme) => theme.typography.pxToRem(30)}
            >
              {message ?? intl.formatMessage({ id: messageId })}
            </Typography>
          </Box>
          {!noButton && (
            <Box width={320}>
              <PrimaryButton
                data-testid="PrimaryButton"
                fullWidth
                size="large"
                href={buttonHref}
                onClick={handleBackToPage}
              >
                {intl.formatMessage({
                  id:
                    buttonTextId ??
                    (backToPage ? 'common.buttons.back' : 'common.buttons.top'),
                })}
              </PrimaryButton>
            </Box>
          )}
          <Box mt={4}>
            <Typography
              align="center"
              fontSize={(theme) => theme.typography.pxToRem(18)}
              color="darkgrey"
            >
              {intl.formatMessage({ id: `common.versionLabel` })}: {APP_VERSION}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              align="center"
              fontSize={(theme) => theme.typography.pxToRem(18)}
              color="darkgrey"
            >
              {currentTime}
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default UnrecoverableError;
