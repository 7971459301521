import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

const GraphItemList: React.FC = () => {
  const { values } = useFormikContext<{ mode: string }>();
  const intl = useIntl();

  const [checked, setChecked] = useState({
    temperature: true,
    humidity: true,
    objectTemperature: true,
    objectTemperatureAlertCount: false,
    condensationAlertTemperature: true,
  });

  const [disabled] = useState({
    temperature: true,
    humidity: true,
    objectTemperature: true,
    objectTemperatureAlertCount: true,
    condensationAlertTemperature: true,
  });

  useEffect(() => {
    if (values.mode === 'temperatureHumidityObserve') {
      setChecked({
        temperature: true,
        humidity: true,
        objectTemperature: false,
        objectTemperatureAlertCount: false,
        condensationAlertTemperature: false,
      });
    } else if (values.mode === 'condensationObserve') {
      setChecked({
        temperature: true,
        humidity: true,
        objectTemperature: true,
        objectTemperatureAlertCount: false,
        condensationAlertTemperature: true,
      });
    } else if (values.mode === 'connectorTemperatureObserve') {
      setChecked({
        temperature: true,
        humidity: true,
        objectTemperature: true,
        objectTemperatureAlertCount: true,
        condensationAlertTemperature: false,
      });
    }
  }, [values.mode]);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            name="temperature"
            checked={checked.temperature}
            disabled={disabled.temperature}
            color="primary"
          />
        }
        label={
          <Box component="span" fontWeight="bold">
            {intl.formatMessage({
              id: 'common.temperature',
            })}
          </Box>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            name="humidity"
            checked={checked.humidity}
            disabled={disabled.humidity}
            color="primary"
          />
        }
        label={
          <Box component="span" fontWeight="bold">
            {intl.formatMessage({
              id: 'common.humidity',
            })}
          </Box>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            name="objectTemperature"
            checked={checked.objectTemperature}
            disabled={disabled.objectTemperature}
            color="primary"
          />
        }
        label={
          <Box component="span" fontWeight="bold">
            {intl.formatMessage({
              id: 'common.objectTemperature',
            })}
          </Box>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            name="objectTemperatureAlertCount"
            checked={checked.objectTemperatureAlertCount}
            disabled={disabled.objectTemperatureAlertCount}
            color="primary"
          />
        }
        label={
          <Box component="span" fontWeight="bold">
            {intl.formatMessage({
              id: 'common.objectTemperatureAlertCount',
            })}
          </Box>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            name="condensationAlertTemperature"
            checked={checked.condensationAlertTemperature}
            disabled={disabled.condensationAlertTemperature}
            color="primary"
          />
        }
        label={
          <Box component="span" fontWeight="bold">
            {intl.formatMessage({
              id: 'common.condensationAlertTemperature',
            })}
          </Box>
        }
      />
    </FormGroup>
  );
};

export default GraphItemList;
