import { useEffect } from 'react';
import { useSelector } from 'src/domains/root/store';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import {
  Gateway,
  getGateways,
  gatewaysSelector,
  statusSelector as gatewaysStatusSelector,
  errorSelector as gatewaysErrorSelector,
} from 'src/domains/root/features/gateways/slice';
import { useAuth0 } from '@auth0/auth0-react';

type Props = { skipFetchCondition?: (gateways: Gateway[]) => boolean };
export default function useGateways(props?: Props) {
  const { skipFetchCondition } = props || {};

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const gateways = useSelector(gatewaysSelector);
  const error = useSelector(gatewaysErrorSelector);
  const status = useSelector(gatewaysStatusSelector);

  const shouldSkip = skipFetchCondition?.(gateways) ?? false;

  useEffect(() => {
    if (!workspace.workspaceId) return;

    if (shouldSkip) return;

    const token = getAccessTokenSilently();
    const promise = dispatch(
      getGateways({ token, workspaceId: workspace.workspaceId }),
    );

    return () => {
      promise.abort();
    };
  }, [dispatch, getAccessTokenSilently, shouldSkip, workspace.workspaceId]);

  return { gateways, error, status };
}
