import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { css } from 'styled-components/macro';

interface GatewayNameProps {
  name: string;
}

const nameStyle = css`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  font-weight: bold;
`;

const GatewayName: React.FC<GatewayNameProps> = (props) => {
  const { name } = props;

  return (
    <Box>
      <Typography css={nameStyle} sx={{ wordBreak: 'break-all' }}>
        {name}
      </Typography>
    </Box>
  );
};

export default GatewayName;
