import React from 'react';

const LogoutIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4836 23H3.4986C2.60609 23.0217 1.74095 22.6907 1.09034 22.0788C0.439724 21.4668 0.0559676 20.623 0.0220947 19.73V17.18C0.0220947 17.0881 0.040182 16.9971 0.075325 16.9121C0.110468 16.8272 0.161978 16.75 0.226914 16.685C0.29185 16.62 0.36894 16.5685 0.453782 16.5333C0.538625 16.4981 0.62956 16.48 0.721393 16.48C0.813226 16.48 0.904161 16.4981 0.989004 16.5333C1.07385 16.5685 1.15093 16.62 1.21587 16.685C1.2808 16.75 1.33231 16.8272 1.36746 16.9121C1.4026 16.9971 1.42069 17.0881 1.42069 17.18V19.76C1.45413 20.2872 1.69455 20.7797 2.08947 21.13C2.48439 21.4804 3.00174 21.6601 3.52857 21.63H18.5135C19.0718 21.6274 19.6064 21.4042 20.0011 21.0091C20.3959 20.614 20.6188 20.0788 20.6214 19.52V4.52001C20.6188 3.96213 20.3955 3.42801 20.0005 3.03447C19.6055 2.64093 19.0708 2.42001 18.5135 2.42002H3.52857C3.00174 2.3899 2.48439 2.56963 2.08947 2.91999C1.69455 3.27034 1.45413 3.76285 1.42069 4.29001V6.86C1.42069 6.95193 1.4026 7.04296 1.36746 7.12789C1.33231 7.21281 1.2808 7.28998 1.21587 7.35498C1.15093 7.41998 1.07385 7.47155 0.989004 7.50673C0.904161 7.54191 0.813226 7.56002 0.721393 7.56002C0.62956 7.56002 0.538625 7.54191 0.453782 7.50673C0.36894 7.47155 0.29185 7.41998 0.226914 7.35498C0.161978 7.28998 0.110468 7.21281 0.075325 7.12789C0.040182 7.04296 0.0220947 6.95193 0.0220947 6.86V4.28002C0.0533627 3.37988 0.440157 2.52894 1.09757 1.91399C1.75498 1.29904 2.62929 0.970334 3.52857 1.00002H18.5135C19.4392 1.00791 20.3245 1.38087 20.9772 2.03798C21.6299 2.6951 21.9974 3.58336 22 4.51001V19.51C21.9947 20.4392 21.6216 21.3283 20.9624 21.9826C20.3032 22.6368 19.4118 23.0027 18.4836 23Z"
        fill="#F05064"
      />
      <path
        d="M14.8672 11.3H2.40968L3.92816 9.77998C4.06063 9.64738 4.13506 9.46752 4.13506 9.27998C4.13506 9.09245 4.06063 8.91259 3.92816 8.77998C3.79568 8.64738 3.61601 8.57288 3.42866 8.57288C3.24131 8.57288 3.06163 8.64738 2.92916 8.77998L0.211887 11.5C0.142035 11.5637 0.087405 11.6423 0.0520473 11.73C-0.0173491 11.8998 -0.0173491 12.0901 0.0520473 12.26C0.087405 12.3477 0.142035 12.4263 0.211887 12.49L2.93915 15.18C3.0024 15.2462 3.07837 15.2989 3.16249 15.335C3.2466 15.3711 3.33714 15.3898 3.42866 15.39C3.52168 15.39 3.61378 15.3714 3.69955 15.3354C3.78532 15.2993 3.86304 15.2465 3.92816 15.18C3.99586 15.1155 4.04977 15.038 4.08661 14.952C4.12344 14.8661 4.14244 14.7735 4.14244 14.68C4.14244 14.5864 4.12344 14.4939 4.08661 14.4079C4.04977 14.322 3.99586 14.2444 3.92816 14.18L2.40968 12.66H14.8672C15.0395 12.6394 15.1983 12.5563 15.3136 12.4263C15.4288 12.2964 15.4924 12.1287 15.4924 11.955C15.4924 11.7812 15.4288 11.6135 15.3136 11.4836C15.1983 11.3537 15.0395 11.2706 14.8672 11.25V11.3Z"
        fill="#F05064"
      />
    </svg>
  );
};

export default LogoutIcon;
