import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { createSvgIcon } from '@mui/material/utils';
import WorkspaceDialog from '../workspaces/update/Dialog';
import SettingGroupBox from './SettingGroupBox';
import SettingGroupBoxItem from './SettingGroupBoxItem';

const Workspaces: React.FC = () => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <SettingGroupBox
      icon={<WorkspaceIcon />}
      title={intl.formatMessage({
        id: 'pages.OtherSetting.workspaces.title',
      })}
      description={intl.formatMessage({
        id: 'pages.OtherSetting.workspaces.description',
      })}
    >
      <SettingGroupBoxItem
        onClickButton={handleDialogOpen}
        buttonText={intl.formatMessage({
          id: 'pages.OtherSetting.workspaces.edit.button',
        })}
      />

      <WorkspaceDialog open={open} onClose={handleDialogClose} />
    </SettingGroupBox>
  );
};

export default Workspaces;

const _WorkspaceIcon = createSvgIcon(
  <>
    <path
      d="M4 3H13.5V13.5H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3V3Z"
      fill="#5A5A5A"
    />
    <path d="M27.25 3H16.75V13.5H27.25V3Z" fill="#5A5A5A" />
    <path
      d="M30.5 3H40C40.2652 3 40.5196 3.10536 40.7071 3.29289C40.8946 3.48043 41 3.73478 41 4V13.5H30.5V3Z"
      fill="#5A5A5A"
    />
    <path d="M13.5 16.75H3V27.25H13.5V16.75Z" fill="#5A5A5A" />
    <path d="M27.25 16.75H16.75V27.25H27.25V16.75Z" fill="#5A5A5A" />
    <path d="M41 16.75H30.5V27.25H41V16.75Z" fill="#5A5A5A" />
    <path
      d="M3 30.5H13.5V41H4C3.73478 41 3.48043 40.8946 3.29289 40.7071C3.10536 40.5196 3 40.2652 3 40V30.5Z"
      fill="#5A5A5A"
    />
    <path d="M27.25 30.5H16.75V41H27.25V30.5Z" fill="#5A5A5A" />
    <path
      d="M30.5 30.5H41V40C41 40.2652 40.8946 40.5196 40.7071 40.7071C40.5196 40.8946 40.2652 41 40 41H30.5V30.5Z"
      fill="#5A5A5A"
    />
  </>,
  'WorkspaceIcon',
);

const WorkspaceIcon = styled(_WorkspaceIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 44 44',
}))`
  fill: none;
  height: 44px;
  width: 44px;
`;
