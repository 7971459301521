import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import { updateTargetAlert } from 'src/domains/root/features/alerts/slice';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import SettingIcon from 'src/domains/root/commons/layout/drawer/icons/SettingIcon';
import AccessControl from 'src/domains/root/commons/AccessControl';
import ChartLinkButton from 'src/domains/root/commons/ChartLinkButton';
import CustomTableCell from 'src/domains/root/commons/CustomTableCell';
import SensorHeartbeatCell from 'src/domains/root/commons/SensorHeartbeatCell';
import * as API from 'src/apis';
import AlertTypeChip from '../../sensor-units/AlertTypeChip';
import FormattedTimestamp from '../../sensor-units/FormattedTimestamp';
import UnitizedValue from '../../sensor-units/UnitizedValue';
import AlertListMenu from './AlertListMenu';

interface AlertListTableRowProps {
  gatewayName: string;
  sensorUnit: API.AlertSensorUnits;
  onClickAlertChartButton: () => void;
}

const AlertUnitizedValue = styled(UnitizedValue)`
  color: ${(props) => props.theme.palette.nito.main};
  font-weight: bold;
`;

const AlertListTableRow: React.FC<AlertListTableRowProps> = (props) => {
  const { gatewayName, sensorUnit, onClickAlertChartButton } = props;

  const dispatch: AppDispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(updateTargetAlert({ gatewayName, sensorUnit }));
  };

  return (
    <>
      <CustomTableCell align="center">{sensorUnit.number}</CustomTableCell>
      <CustomTableCell align="left">{sensorUnit.name}</CustomTableCell>
      <SensorHeartbeatCell heartBeat={sensorUnit.isAlive} />
      <CustomTableCell align="center">
        <AlertTypeChip type={sensorUnit.alertType} reason={sensorUnit.reason} />
      </CustomTableCell>
      <CustomTableCell align="center">
        <FormattedTimestamp timestamp={sensorUnit.occurredTimestamp} />
      </CustomTableCell>
      <CustomTableCell align="center">
        <AlertUnitizedValue
          value={sensorUnit.latestValue}
          type={sensorUnit.alertType}
        />
      </CustomTableCell>
      <CustomTableCell align="center">
        <ChartLinkButton
          observeMode={sensorUnit.observe.mode}
          onClickAlertChartButton={onClickAlertChartButton}
        />
      </CustomTableCell>
      <AccessControl
        permit={(permissions) => permissions.includes('alert.current.update')}
      >
        <TableCell align="center">
          <IconButton
            data-testid="AlertMenuButton"
            size="large"
            onClick={(e) => handleOpenMenu(e)}
          >
            <SettingIcon />
          </IconButton>
        </TableCell>
      </AccessControl>
      <AlertListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default AlertListTableRow;
