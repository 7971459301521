import React from 'react';
import { css } from 'styled-components/macro';
import Menu from '@mui/material/Menu';
import HambugerMenuList from './HambugerMenuList';

const menuStyle = css`
  & .MuiPopover-paper {
    box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.4);
  }

  border-radius: 4px;
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: ${(props) => props.theme.spacing(2)};
`;

interface HambugerMenu {
  anchorEl: HTMLElement | null;
  handleHambugerMenuClose: () => void;
}

const HambugerMenu: React.FC<HambugerMenu> = (props) => {
  const { anchorEl, handleHambugerMenuClose } = props;

  return (
    <Menu
      data-testid="HambugerMenu"
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handleHambugerMenuClose}
      css={menuStyle}
    >
      <HambugerMenuList />
    </Menu>
  );
};

export default HambugerMenu;
