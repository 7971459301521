import React from 'react';
import UnrecoverableError from './UnrecoverableError';

const UsedRegistrationIdError: React.FC = () => {
  return (
    <UnrecoverableError
      messageId="registrations.users.new.error.used_registration_id"
      buttonId="common.buttons.top"
      buttonLink="/registration/signup"
    />
  );
};

export default UsedRegistrationIdError;
