import React from 'react';
import { Gateway, SensorUnit } from 'src/domains/root/features/gateways/slice';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from 'src/domains/root/features/gateways/settings/alarm/NotificationsIcon';
import CustomTableCell from 'src/domains/root/commons/CustomTableCell';
import SensorHeartbeatCell from 'src/domains/root/commons/SensorHeartbeatCell';
import SensorUnitName from '../../sensor-units/SensorUnitName';
import SettingsIcon from './mode/SettingsIcon';

interface GatewaySettingTableRowProps {
  gateway: Gateway;
  sensorUnit: SensorUnit;
  setAlarmSettingModalData: (props: {
    sensorUnit: SensorUnit;
    gateway: Gateway;
  }) => void;
  setEditModalData: (props: {
    sensorUnit: SensorUnit;
    gateway: Gateway;
  }) => void;
}

const GatewaySettingTableRow: React.FC<GatewaySettingTableRowProps> = (
  props,
) => {
  const { gateway, sensorUnit, setAlarmSettingModalData, setEditModalData } =
    props;

  return (
    <>
      <CustomTableCell component="th" scope="SensorUnit" align="center">
        {sensorUnit.number}
      </CustomTableCell>
      <CustomTableCell align="left">
        <SensorUnitName
          powerSupply={sensorUnit.powerSupply}
          battery={sensorUnit.battery}
        >
          {sensorUnit.name}
        </SensorUnitName>
      </CustomTableCell>
      <SensorHeartbeatCell heartBeat={sensorUnit.isAlive} />
      <CustomTableCell align="center">
        <IconButton
          data-testid="AlarmSettingModalButton"
          onClick={() => setAlarmSettingModalData({ sensorUnit, gateway })}
          size="large"
        >
          <NotificationsIcon isEnabled={sensorUnit.isAlarmEnabled} />
        </IconButton>
      </CustomTableCell>
      <CustomTableCell align="center">
        <IconButton
          data-testid="EditModeDialogButton"
          onClick={() => setEditModalData({ sensorUnit, gateway })}
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </CustomTableCell>
    </>
  );
};

export default GatewaySettingTableRow;
