/**
 * README
 * このhooksでは、redux toolkitではなく、@tanstack/react-queryを使用している。
 * この修正の「理由」「調査内容」「今後の@tanstack/react-query利用」については以下のPBIを参照すること。
 *
 * 参考：
 * @see https://www.pivotaltracker.com/story/show/186107857
 */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import type { ObserveMode } from 'src/domains/root/utils/highcharts/chart-utils';
import type {
  WeatherArea,
  WeatherHistoryItem,
} from 'src/domains/root/features/gateways/sensor-data/type';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import type { WeatherHistoriesData } from '../SensorDataChart';
import * as API from './api';
import type { SensorData, SensorDataUrlResponse, AlertCount } from './type';

interface Props {
  sensorUnitId: string;
  observeMode?: ObserveMode;
}

export type Result =
  | { status: 'hasError' }
  | { status: 'loading' }
  | { status: 'noData' }
  | {
      status: 'succeeded';
      phase:
        | 'partial_data_fetched'
        | 'url_fetched'
        | 'alert_count_fetched'
        | 'full_data_fetched';
      sensorData: SensorData;
      isAlive: boolean | undefined;
      weatherArea: WeatherArea;
      weatherHistories: WeatherHistoriesData;
      fullDataButtonClicked: boolean;
      handleFullDataButtonClicked: () => void;
    };

/**
 * 通常グラフのユースケースを知りつつ、Viewに関心を持たないモジュール。
 * API呼び出しや状態合成の複雑性を吸収しつつテスタビリティを担保する。
 */
export const useSensorDataChart = (props: Props): Result => {
  const { sensorUnitId, observeMode } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [fullDataButtonClicked, setFullDataButtonClicked] = useState(false);

  /**
   * 12週分のセンサーデータを取得する。
   */
  const sensorDataResult = useQuery<SensorData, API.Error>({
    queryKey: Keys.sensorData.su12w(sensorUnitId),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return API.getSensorData(sensorUnitId, token);
    },
    refetchOnWindowFocus: false,
  });

  /**
   * S3からすべてのセンサーデータを取得するためのURLを取得する。
   */
  const sensorDataUrlResult = useQuery<SensorDataUrlResponse, API.Error>({
    queryKey: Keys.sensorData.suUrl(sensorUnitId),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return API.getSensorDataUrl(sensorUnitId, token);
    },
    enabled: sensorDataResult.isSuccess,
    refetchOnWindowFocus: false,
  });

  /**
   * S3から異常発生回数を取得する。
   */
  const alertCountResult = useQuery<AlertCount, API.Error>({
    queryKey: Keys.sensorData.suAlertCount(sensorUnitId),
    queryFn: async () => {
      return API.getAlertCount(sensorDataUrlResult.data!.alertCountUrl);
    },
    enabled:
      sensorDataUrlResult.isSuccess &&
      observeMode === 'connectorTemperatureObserve',
    refetchOnWindowFocus: false,
  });

  /**
   * S3からすべてのセンサーデータを取得する。
   */
  const fullDataResult = useQuery<SensorData, API.Error>({
    queryKey: Keys.sensorData.suFull(sensorUnitId),
    queryFn: async () => {
      return API.getFullSensorData(sensorDataUrlResult.data!.sensorDataUrl);
    },
    enabled: sensorDataUrlResult.isSuccess,
    refetchOnWindowFocus: false,
  });

  /**
   * リダイレクト処理
   */
  useRedirectForError(
    sensorDataResult,
    sensorDataUrlResult,
    alertCountResult,
    fullDataResult,
  );

  if (sensorDataResult.isPending) {
    return { status: 'loading' };
  }
  if (sensorDataResult.isError) {
    return { status: 'hasError' };
  }
  if (Object.keys(sensorDataResult.data).length === 0) {
    return { status: 'noData' };
  }

  const objectTemperatureAlertCount =
    alertCountResult.data?.objectTemperatureAlertCount ?? [];

  const sensorDataForResponse =
    fullDataResult.isSuccess && fullDataButtonClicked
      ? { ...fullDataResult.data, objectTemperatureAlertCount }
      : sensorDataResult.data;

  const weatherHistoriesData = createWeatherHistoriesData(
    sensorDataUrlResult.data?.weatherHistories,
    sensorDataForResponse,
  );

  return {
    status: 'succeeded',
    sensorData: sensorDataForResponse,
    isAlive: sensorDataUrlResult.data?.isAlive,
    weatherArea: sensorDataUrlResult.data?.weatherArea ?? {
      displayAreaName: '',
    },
    weatherHistories: weatherHistoriesData,
    phase: fullDataResult.isSuccess
      ? 'full_data_fetched'
      : alertCountResult.isSuccess
        ? 'alert_count_fetched'
        : sensorDataUrlResult.isSuccess
          ? 'url_fetched'
          : 'partial_data_fetched',
    fullDataButtonClicked,
    handleFullDataButtonClicked: () => {
      setFullDataButtonClicked(true);
    },
  };
};

// ==============================
// utils

/**
 * 表示用の天気データを生成する処理
 * selectorにしても良いけど、ユースケース的にmemoizeが有効なケースはないので、
 * とりあえず関数として実装しておく。
 */
const createWeatherHistoriesData = (
  weatherHistories: WeatherHistoryItem[] | undefined,
  sensorData: SensorData,
) => {
  const weatherHistoriesData: WeatherHistoriesData = {
    icons: [],
    info: {},
  };

  const temperatures = sensorData?.temperature;

  if (!temperatures || temperatures.length === 0) {
    return weatherHistoriesData;
  }

  const oldestTimestamp = temperatures[0][0];
  const latestTimestamp = temperatures[temperatures.length - 1][0];

  weatherHistories?.forEach((weatherHistoryData) => {
    if (
      oldestTimestamp < weatherHistoryData.timestamp &&
      latestTimestamp > weatherHistoryData.timestamp
    ) {
      weatherHistoriesData.icons.push({
        x: weatherHistoryData.timestamp,
        y: 5,
        marker: {
          symbol: `url(${
            import.meta.env.PUBLIC_URL ?? ''
          }/assets/weather_icon/${weatherHistoryData.icon})`,
          width: 56,
          height: 36,
        },
      });
      weatherHistoriesData.info[weatherHistoryData.timestamp] = {
        temperatureMax: 0,
        temperatureMin: 0,
        rainQuantity: 0,
      };
      weatherHistoriesData.info[weatherHistoryData.timestamp].temperatureMax =
        weatherHistoryData.temperatureMax;
      weatherHistoriesData.info[weatherHistoryData.timestamp].temperatureMin =
        weatherHistoryData.temperatureMin;
      weatherHistoriesData.info[weatherHistoryData.timestamp].rainQuantity =
        weatherHistoryData.rainQuantity;
    }
  });

  return weatherHistoriesData;
};

function useRedirectForError(
  sensorDataResult: UseQueryResult<SensorData, API.Error>,
  sensorDataUrlResult: UseQueryResult<SensorDataUrlResponse, API.Error>,
  alertCountResult: UseQueryResult<AlertCount, API.Error>,
  fullDataResult: UseQueryResult<SensorData, API.Error>,
) {
  const navigate = useNavigate();
  useEffect(() => {
    const error = (sensorDataResult.error ||
      sensorDataUrlResult.error ||
      alertCountResult.error ||
      fullDataResult.error) as API.Error;
    if (!error) {
      return;
    }
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  }, [
    sensorDataResult.error,
    sensorDataUrlResult.error,
    alertCountResult.error,
    fullDataResult.error,
  ]);
}
