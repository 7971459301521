import React from 'react';
import { DateTime } from 'luxon';
import HookStatus from 'src/domains/root/commons/HookStatus';
import type {
  Alert,
  AlertSensorUnit,
} from 'src/domains/root/features/alerts/slice';
import SensorDataChart from './SensorDataChart';
import SensorDataModalFrame from './SensorDataModalFrame';
import useAlertSensorDataChart from './sensor-data/useAlertSensorDataChart';

interface Props {
  alert: Alert;
  alertSensorUnit: AlertSensorUnit;
  handleClose: () => void;
}

export default function AlertChartModal({
  alert,
  alertSensorUnit,
  handleClose,
}: Props) {
  const result = useAlertSensorDataChart({
    sensorUnitId: alertSensorUnit.deviceId,
    areaId: alert.areaId,
    includeRemovedData: false,
    occuredTimestamp: alertSensorUnit.occurredTimestamp,
  });

  const occurredAt = DateTime.fromMillis(alertSensorUnit.occurredTimestamp);

  return (
    <SensorDataModalFrame
      menuContents={{ show: false }}
      handleClose={handleClose}
    >
      {result.status !== 'succeeded' ? (
        <HookStatus
          target="sensorDataChart"
          error={result.status === 'hasError'}
          loading={result.status === 'loading'}
          noData={result.status === 'noData'}
        />
      ) : (
        <SensorDataChart
          isFullData={result.phase === 'full_data_fetched'}
          gatewayName={alert.name}
          sensorUnit={{
            name: alertSensorUnit.name,
            observe: alertSensorUnit.observe,
            isAlive: alertSensorUnit.isAlive,
          }}
          sensorData={result.sensorData}
          weatherArea={result.weatherArea}
          weatherHistories={result.weatherHistories}
          phase={result.phase}
          occurredAt={occurredAt.toJSDate()}
          initialViewRange={{
            start: occurredAt.minus({ day: 1 }).toJSDate(),
            end: occurredAt.plus({ day: 1 }).toJSDate(),
          }}
        />
      )}
    </SensorDataModalFrame>
  );
}
