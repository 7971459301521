import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import judgeRepeated from './judgeRepeated';

const STORAGE_KEY = 'IdTokenExpirationHistory';

/**
 * token期限切れエラーが連続して発生することで、CABIoTとAuth0を無限に行ったり来たりする問題を解消するためのモジュール。
 * 単位時間あたりの発生回数がしきい値に達したら、通常の画面表示処理を止めてエラー画面を表示する。
 * @see https://www.pivotaltracker.com/story/show/182849884
 */
export default function withRepeatedTokenExpirationBreaker<P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> {
  return function WithRepeatedTokenExpirationBreaker(props: P) {
    const { error } = useAuth0();

    if (!isTokenExpiredError(error)) {
      return <Component {...props} />;
    }

    const result = judgeRepeated(localStorage.getItem(STORAGE_KEY), new Date());

    if (result.repeated) {
      return <Navigate to="/errors/repeated-token-expiration" />;
    }

    localStorage.setItem(STORAGE_KEY, result.history);

    return <Component {...props} />;
  };
}

function isTokenExpiredError(error?: Error) {
  return error?.message.includes(
    'Expiration Time (exp) claim error in the ID token;',
  );
}
