import React from 'react';

const CabiotLogo: React.FC = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="88"
      height="28"
      viewBox="0 0 88 28"
      fill="#E10A1E"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.1554 19.9171V21.6188C13.1554 22.4696 12.9827 23.2818 12.6372 24.0553C12.3183 24.803 11.8665 25.4733 11.2818 26.0663C10.6971 26.6593 10.0061 27.1363 9.2088 27.4972C8.43808 27.8324 7.60092 28 6.69731 28C5.92659 28 5.14258 27.8969 4.34528 27.6906C3.54798 27.4843 2.83041 27.1234 2.19257 26.6077C1.55473 26.0921 1.0232 25.4346 0.597975 24.6354C0.199326 23.8103 0 22.7661 0 21.5028V6.34254C0 5.44015 0.159461 4.60221 0.47838 3.82873C0.7973 3.05525 1.2491 2.3849 1.83379 1.81768C2.41847 1.25046 3.10947 0.812156 3.90677 0.502763C4.73064 0.167588 5.63425 0 6.61758 0C8.5311 0 10.0858 0.605894 11.2818 1.81768C11.8665 2.41068 12.3183 3.11971 12.6372 3.94475C12.9827 4.74402 13.1554 5.62063 13.1554 6.57459V8.12155H9.08921V6.80663C9.08921 6.03315 8.86331 5.3628 8.4115 4.79558C7.9597 4.22836 7.34844 3.94475 6.57772 3.94475C5.5678 3.94475 4.8901 4.25414 4.54461 4.87293C4.22569 5.46593 4.06623 6.22652 4.06623 7.1547V21.232C4.06623 22.0313 4.23897 22.7017 4.58447 23.2431C4.95654 23.7845 5.60767 24.0553 6.53785 24.0553C6.80362 24.0553 7.08267 24.0166 7.37502 23.9392C7.69394 23.8361 7.98628 23.6814 8.25205 23.4751C8.49124 23.2689 8.69056 22.9853 8.85002 22.6243C9.00948 22.2634 9.08921 21.8122 9.08921 21.2707V19.9171H13.1554Z" />
      <path
        d="M13.1554 19.9171V21.6188C13.1554 22.4696 12.9827 23.2818 12.6372 24.0553C12.3183 24.803 11.8665 25.4733 11.2818 26.0663C10.6971 26.6593 10.0061 27.1363 9.2088 27.4972C8.43808 27.8324 7.60092 28 6.69731 28C5.92659 28 5.14258 27.8969 4.34528 27.6906C3.54798 27.4843 2.83041 27.1234 2.19257 26.6077C1.55473 26.0921 1.0232 25.4346 0.597975 24.6354C0.199326 23.8103 0 22.7661 0 21.5028V6.34254C0 5.44015 0.159461 4.60221 0.47838 3.82873C0.7973 3.05525 1.2491 2.3849 1.83379 1.81768C2.41847 1.25046 3.10947 0.812156 3.90677 0.502763C4.73064 0.167588 5.63425 0 6.61758 0C8.5311 0 10.0858 0.605894 11.2818 1.81768C11.8665 2.41068 12.3183 3.11971 12.6372 3.94475C12.9827 4.74402 13.1554 5.62063 13.1554 6.57459V8.12155H9.08921V6.80663C9.08921 6.03315 8.86331 5.3628 8.4115 4.79558C7.9597 4.22836 7.34844 3.94475 6.57772 3.94475C5.5678 3.94475 4.8901 4.25414 4.54461 4.87293C4.22569 5.46593 4.06623 6.22652 4.06623 7.1547V21.232C4.06623 22.0313 4.23897 22.7017 4.58447 23.2431C4.95654 23.7845 5.60767 24.0553 6.53785 24.0553C6.80362 24.0553 7.08267 24.0166 7.37502 23.9392C7.69394 23.8361 7.98628 23.6814 8.25205 23.4751C8.49124 23.2689 8.69056 22.9853 8.85002 22.6243C9.00948 22.2634 9.08921 21.8122 9.08921 21.2707V19.9171H13.1554Z"
        fillOpacity="0.2"
      />
      <path d="M25.4827 18.1381L23.4895 8.16022H23.4097L21.4165 18.1381H25.4827ZM15.4368 27.768L21.7753 0.232046H25.1638L31.5023 27.768H27.4361L26.2402 21.8508H20.6989L19.503 27.768H15.4368Z" />
      <path
        d="M25.4827 18.1381L23.4895 8.16022H23.4097L21.4165 18.1381H25.4827ZM15.4368 27.768L21.7753 0.232046H25.1638L31.5023 27.768H27.4361L26.2402 21.8508H20.6989L19.503 27.768H15.4368Z"
        fillOpacity="0.2"
      />
      <path d="M34.3019 27.768V0.232046H40.2418C41.544 0.232046 42.647 0.425416 43.5506 0.812156C44.4808 1.1989 45.2382 1.71455 45.8229 2.35912C46.4076 3.00368 46.8195 3.75138 47.0587 4.60221C47.3245 5.42726 47.4573 6.29098 47.4573 7.19337V8.1989C47.4573 8.94659 47.3909 9.57827 47.258 10.0939C47.1517 10.6096 46.979 11.0608 46.7398 11.4475C46.288 12.1694 45.597 12.7882 44.6668 13.3039C45.6235 13.7422 46.3278 14.3867 46.7796 15.2376C47.2314 16.0884 47.4573 17.2486 47.4573 18.7182V20.2652C47.4573 22.6888 46.8461 24.5451 45.6236 25.8343C44.4276 27.1234 42.5008 27.768 39.8431 27.768H34.3019ZM38.3681 15.0055V23.8232H40.1222C40.9461 23.8232 41.5839 23.7072 42.0357 23.4751C42.5141 23.2431 42.8729 22.9208 43.1121 22.5083C43.3513 22.0958 43.4974 21.6059 43.5506 21.0387C43.6037 20.4715 43.6303 19.8527 43.6303 19.1823C43.6303 18.4862 43.5904 17.8803 43.5107 17.3646C43.431 16.849 43.2715 16.4107 43.0323 16.0497C42.7666 15.6888 42.4078 15.4309 41.956 15.2762C41.5042 15.0958 40.9062 15.0055 40.1621 15.0055H38.3681ZM38.3681 3.94475V11.5249H40.2019C41.5573 11.5249 42.4609 11.2026 42.9127 10.558C43.3911 9.88766 43.6303 8.92081 43.6303 7.65746C43.6303 6.41989 43.3645 5.49171 42.833 4.87293C42.3281 4.25414 41.3979 3.94475 40.0425 3.94475H38.3681Z" />
      <path
        d="M34.3019 27.768V0.232046H40.2418C41.544 0.232046 42.647 0.425416 43.5506 0.812156C44.4808 1.1989 45.2382 1.71455 45.8229 2.35912C46.4076 3.00368 46.8195 3.75138 47.0587 4.60221C47.3245 5.42726 47.4573 6.29098 47.4573 7.19337V8.1989C47.4573 8.94659 47.3909 9.57827 47.258 10.0939C47.1517 10.6096 46.979 11.0608 46.7398 11.4475C46.288 12.1694 45.597 12.7882 44.6668 13.3039C45.6235 13.7422 46.3278 14.3867 46.7796 15.2376C47.2314 16.0884 47.4573 17.2486 47.4573 18.7182V20.2652C47.4573 22.6888 46.8461 24.5451 45.6236 25.8343C44.4276 27.1234 42.5008 27.768 39.8431 27.768H34.3019ZM38.3681 15.0055V23.8232H40.1222C40.9461 23.8232 41.5839 23.7072 42.0357 23.4751C42.5141 23.2431 42.8729 22.9208 43.1121 22.5083C43.3513 22.0958 43.4974 21.6059 43.5506 21.0387C43.6037 20.4715 43.6303 19.8527 43.6303 19.1823C43.6303 18.4862 43.5904 17.8803 43.5107 17.3646C43.431 16.849 43.2715 16.4107 43.0323 16.0497C42.7666 15.6888 42.4078 15.4309 41.956 15.2762C41.5042 15.0958 40.9062 15.0055 40.1621 15.0055H38.3681ZM38.3681 3.94475V11.5249H40.2019C41.5573 11.5249 42.4609 11.2026 42.9127 10.558C43.3911 9.88766 43.6303 8.92081 43.6303 7.65746C43.6303 6.41989 43.3645 5.49171 42.833 4.87293C42.3281 4.25414 41.3979 3.94475 40.0425 3.94475H38.3681Z"
        fillOpacity="0.2"
      />
      <path d="M52.1926 27.768V0.232046H56.2588V27.768H52.1926Z" />
      <path
        d="M52.1926 27.768V0.232046H56.2588V27.768H52.1926Z"
        fillOpacity="0.2"
      />
      <path d="M60.4088 14.7348C60.4088 13.9355 60.4619 13.2007 60.5682 12.5304C60.6745 11.86 60.8739 11.267 61.1662 10.7514C61.6446 9.90055 62.3223 9.21731 63.1993 8.70166C64.1029 8.18601 65.1926 7.92818 66.4683 7.92818C67.7439 7.92818 68.8203 8.18601 69.6973 8.70166C70.6009 9.21731 71.2919 9.90055 71.7703 10.7514C72.0626 11.267 72.262 11.86 72.3683 12.5304C72.4746 13.2007 72.5277 13.9355 72.5277 14.7348V21.1934C72.5277 21.9926 72.4746 22.7274 72.3683 23.3978C72.262 24.0681 72.0626 24.6611 71.7703 25.1768C71.2919 26.0276 70.6009 26.7109 69.6973 27.2265C68.8203 27.7422 67.7439 28 66.4683 28C65.1926 28 64.1029 27.7422 63.1993 27.2265C62.3223 26.7109 61.6446 26.0276 61.1662 25.1768C60.8739 24.6611 60.6745 24.0681 60.5682 23.3978C60.4619 22.7274 60.4088 21.9926 60.4088 21.1934V14.7348ZM64.475 21.8122C64.475 22.5599 64.6478 23.1271 64.9933 23.5138C65.3653 23.8748 65.857 24.0553 66.4683 24.0553C67.0795 24.0553 67.5579 23.8748 67.9034 23.5138C68.2755 23.1271 68.4615 22.5599 68.4615 21.8122V14.116C68.4615 13.3683 68.2755 12.814 67.9034 12.453C67.5579 12.0663 67.0795 11.8729 66.4683 11.8729C65.857 11.8729 65.3653 12.0663 64.9933 12.453C64.6478 12.814 64.475 13.3683 64.475 14.116V21.8122Z" />
      <path
        d="M60.4088 14.7348C60.4088 13.9355 60.4619 13.2007 60.5682 12.5304C60.6745 11.86 60.8739 11.267 61.1662 10.7514C61.6446 9.90055 62.3223 9.21731 63.1993 8.70166C64.1029 8.18601 65.1926 7.92818 66.4683 7.92818C67.7439 7.92818 68.8203 8.18601 69.6973 8.70166C70.6009 9.21731 71.2919 9.90055 71.7703 10.7514C72.0626 11.267 72.262 11.86 72.3683 12.5304C72.4746 13.2007 72.5277 13.9355 72.5277 14.7348V21.1934C72.5277 21.9926 72.4746 22.7274 72.3683 23.3978C72.262 24.0681 72.0626 24.6611 71.7703 25.1768C71.2919 26.0276 70.6009 26.7109 69.6973 27.2265C68.8203 27.7422 67.7439 28 66.4683 28C65.1926 28 64.1029 27.7422 63.1993 27.2265C62.3223 26.7109 61.6446 26.0276 61.1662 25.1768C60.8739 24.6611 60.6745 24.0681 60.5682 23.3978C60.4619 22.7274 60.4088 21.9926 60.4088 21.1934V14.7348ZM64.475 21.8122C64.475 22.5599 64.6478 23.1271 64.9933 23.5138C65.3653 23.8748 65.857 24.0553 66.4683 24.0553C67.0795 24.0553 67.5579 23.8748 67.9034 23.5138C68.2755 23.1271 68.4615 22.5599 68.4615 21.8122V14.116C68.4615 13.3683 68.2755 12.814 67.9034 12.453C67.5579 12.0663 67.0795 11.8729 66.4683 11.8729C65.857 11.8729 65.3653 12.0663 64.9933 12.453C64.6478 12.814 64.475 13.3683 64.475 14.116V21.8122Z"
        fillOpacity="0.2"
      />
      <path d="M79.2297 27.768V3.94475H74.5256V0.232046H88V3.94475H83.2959V27.768H79.2297Z" />
      <path
        d="M79.2297 27.768V3.94475H74.5256V0.232046H88V3.94475H83.2959V27.768H79.2297Z"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default CabiotLogo;
