import React from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  gateway: {
    deviceId: string;
    name: string;
  };
}

const GatewayNameField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, handleChange } = useFormikContext<Values>();

  return (
    <TextField
      data-testid="GatewayNameTextInput"
      name="gateway.name"
      value={values.gateway.name}
      label={intl.formatMessage({
        id: 'common.gatewayName',
      })}
      onChange={handleChange}
      error={Boolean(errors.gateway?.name)}
      helperText={errors.gateway?.name}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default GatewayNameField;
