import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'src/domains/root/store';
import { Navigate } from 'react-router-dom';
import { usersSelector } from '../users/slice';
import { createResultSelector } from './slice';
import Self from './created/Self';
import Other from './created/Other';

const Completed: React.FC = () => {
  const { user } = useAuth0();

  const { data: workspace } = useSelector(createResultSelector);

  const users = useSelector(usersSelector) || [];

  if (!workspace) {
    return <Navigate to="../new" />;
  }

  const adminUser = users.find((user) => user.userId === workspace.adminUserId);

  return (
    <>
      {workspace.adminUserId === user?.sub ? (
        <Self workspace={workspace} />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Other workspace={workspace} user={adminUser!} />
      )}
    </>
  );
};

export default Completed;
