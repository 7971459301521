import React from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead as _TableHead,
  TableBody as _TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useIntl } from 'react-intl';
import HookStatus from 'src/domains/root/commons/HookStatus';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import useGateways from '../gateways/useGateways';
import Chip from './data/Chip';

const MacAddressTable: React.FC = () => {
  const { gateways, error, status } = useGateways();

  const loading = status === 'loading';
  const noData = !gateways.length;

  if (loading || !!error || noData) {
    return (
      <HookStatus
        target="gatewaySettingTable"
        loading={loading}
        error={!!error}
        noData={noData}
      />
    );
  }

  return (
    <Box bgcolor="white">
      <TableContainer
        sx={{
          px: { xs: 1, sm: 2 },
          py: { xs: 0.5, sm: 2 },
          overflowX: 'auto',
        }}
      >
        <Table aria-label="simple table">
          <TableHead />
          <TableBody gateways={gateways} />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MacAddressTable;

// libs

function TableHead() {
  const intl = useIntl();
  return (
    <_TableHead>
      <TableRow
        sx={{
          borderBottom: '2px solid #828282',
          '& th': {
            fontWeight: 700,
            fontSize: { xs: 14, sm: 16 },
          },
        }}
      >
        <TableCell
          sx={{
            p: 1,
            minWidth: { xs: 144, sm: 300 },
            borderRight: '1px solid #D2D2D2',
          }}
          align="center"
        >
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.macAddress.table.header.gatewayName',
          })}
        </TableCell>
        <TableCell
          sx={{ p: 1, borderRight: '1px solid #D2D2D2' }}
          align="center"
        >
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.macAddress.table.header.no',
          })}
        </TableCell>
        <TableCell sx={{ p: 1 }} align="center">
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.macAddress.table.header.macAddress',
          })}
        </TableCell>
        <TableCell sx={{ p: 1, minWidth: 320 }} align="center">
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.macAddress.table.header.sensorUnitName',
          })}
        </TableCell>
      </TableRow>
    </_TableHead>
  );
}

function TableBody({ gateways }: { gateways: Gateway[] }) {
  const intl = useIntl();
  return (
    <_TableBody>
      {gateways.flatMap((gateway) =>
        gateway.sensorUnits.map((sensorUnit, index) => (
          <TableRow
            key={sensorUnit.deviceId}
            sx={{
              '& td': {
                fontSize: { xs: 14, sm: 16 },
                p: { xs: 1, sm: 2 },
              },
            }}
          >
            {index === 0 && (
              <TableCell
                sx={{
                  overflowWrap: 'anywhere',
                  borderRight: '1px solid #D2D2D2',
                }}
                rowSpan={gateway.sensorUnits.length}
              >
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  gap={{ xs: 0.5, sm: 1 }}
                  alignItems="center"
                  mb={{ xs: 1.5, sm: 2 }}
                >
                  <Chip
                    label={intl.formatMessage({
                      id: 'gatewayInfo.number',
                    })}
                    sx={{ lineHeight: 1 }}
                  />
                  <Box sx={{ lineHeight: 1, height: 15 }}>
                    {gateway.physicalId}
                  </Box>
                </Box>
                <Box>{gateway.name}</Box>
              </TableCell>
            )}
            <TableCell align="center" sx={{ borderRight: '1px solid #D2D2D2' }}>
              {sensorUnit.number}
            </TableCell>
            <TableCell align="center">{sensorUnit.macAddress}</TableCell>
            <TableCell>{sensorUnit.name}</TableCell>
          </TableRow>
        )),
      )}
    </_TableBody>
  );
}
