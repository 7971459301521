import React from 'react';

const DividerIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="228"
      height="1"
      viewBox="0 0 228 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="8.15094" height="1" fill="#F05064" />
      <rect x="36.6792" width="8.15094" height="1" fill="#F05064" />
      <rect x="12.2264" width="8.15094" height="1" fill="#F05064" />
      <rect x="48.9057" width="8.15094" height="1" fill="#F05064" />
      <rect x="24.4528" width="8.15094" height="1" fill="#F05064" />
      <rect x="61.1321" width="8.15094" height="1" fill="#F05064" />
      <rect x="73.3585" width="8.15095" height="1" fill="#F05064" />
      <rect x="85.5849" width="8.15095" height="1" fill="#F05064" />
      <rect x="122.264" width="8.15095" height="1" fill="#F05064" />
      <rect x="195.623" width="8.15092" height="1" fill="#F05064" />
      <rect x="207.849" width="8.15094" height="1" fill="#F05064" />
      <rect x="97.8113" width="8.15094" height="1" fill="#F05064" />
      <rect x="171.17" width="8.15096" height="1" fill="#F05064" />
      <rect x="134.491" width="8.15094" height="1" fill="#F05064" />
      <rect x="110.038" width="8.15094" height="1" fill="#F05064" />
      <rect x="183.396" width="8.15096" height="1" fill="#F05064" />
      <rect x="146.717" width="8.15094" height="1" fill="#F05064" />
      <rect x="158.943" width="8.15094" height="1" fill="#F05064" />
      <rect x="220" width="8.15096" height="1" fill="#F05064" />
    </svg>
  );
};

export default DividerIcon;
