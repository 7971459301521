import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import theme from 'src/domains/root/styles/theme';
import GatewaySensorDataChartContainer from 'src/domains/root/features/gateways/GatewaySensorDataChartContainer';
import WithGateway from 'src/domains/root/features/gateways/WithGateway';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import {
  BreadcrumbsV3,
  BreadcrumbTitleV3,
} from 'src/domains/root/commons/breadcrumbs';
import { CompareChartDataType } from 'src/domains/root/utils/highcharts/chart-utils';

const emphasisStyle = css`
  color: white;
  font-weight: bold;
`;

const formControlStyle = css`
  background: white;
  border-radius: 4px;
  height: 45px;

  &:not(:last-child) {
    margin-right: ${theme.spacing(2)};
  }
`;

const StyledShortFormControl = styled(FormControl)`
  ${formControlStyle}

  flex: 0.5 1 auto;
  max-width: 200px;
  min-width: 154px;
`;

const StyledSelect = styled(Select)`
  height: 45px;

  & fieldset {
    border: 1px solid #828282;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  :hover {
    background: ${theme.palette.primary.light};
  }

  :focus-visible {
    background: ${theme.palette.primary.light};
  }

  &.Mui-selected {
    ${emphasisStyle}

    background: ${theme.palette.primary.main};

    :hover {
      background: ${theme.palette.primary.main}50;
    }

    :focus-visible {
      background: ${theme.palette.primary.main};
    }

    :focus-visible:hover {
      background: ${theme.palette.primary.main}50;
    }
  }

  border-radius: 4px;
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
  padding: ${theme.spacing(1)};
`;

const GatewayDataPage: React.FC = () => {
  const intl = useIntl();

  const [type, setType] = useState<CompareChartDataType>('temperature');

  const handleChangeType = (e: SelectChangeEvent<unknown>) => {
    setType(e.target.value as CompareChartDataType);
  };

  return (
    <MainContents
      noXPaddingIfMobile
      notAllowOverflow
      smallScreenPaddingTop={3}
      showTopButton={false}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={(theme) => ({
          px: {
            xs: 2,
            sm: 0,
          },
          pb: {
            xs: 2.5,
            sm: 3,
            md: 3.5,
          },
          alignItems: {
            md: 'center',
          },

          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <Box
          sx={{
            mb: {
              xs: 3,
              sm: 3.5,
              md: 0,
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}
        >
          <BreadcrumbsV3 small>
            <Link to="/">
              <BreadcrumbTitleV3 color="primary">
                {intl.formatMessage({
                  id: 'pages.Home.title',
                })}
              </BreadcrumbTitleV3>
            </Link>
            <BreadcrumbTitleV3>
              {intl.formatMessage({
                id: 'pages.GatewaySensorData.title',
              })}
            </BreadcrumbTitleV3>
          </BreadcrumbsV3>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="fit-content"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              justifyContent: 'space-between',
            },
          })}
        >
          <Box
            pl={1.5}
            mr={2.5}
            height="max-content"
            sx={(theme) => ({
              fontWeight: 700,
              fontSize: {
                xs: theme.typography.pxToRem(16),
                sm: theme.typography.pxToRem(18),
              },
              borderLeft: `solid ${theme.palette.primary.main} 5px`,
            })}
          >
            {intl.formatMessage({
              id: 'pages.GatewaySensorData.option',
            })}
          </Box>
          <StyledShortFormControl variant="outlined">
            <StyledSelect
              data-testid="TypeSelector"
              value={type}
              onChange={handleChangeType}
            >
              <StyledMenuItem value="temperature">
                {intl.formatMessage({
                  id: 'common.temperature',
                })}
              </StyledMenuItem>
              <StyledMenuItem value="humidity">
                {intl.formatMessage({
                  id: 'common.humidity',
                })}
              </StyledMenuItem>
              <StyledMenuItem value="objectTemperature">
                {intl.formatMessage({
                  id: 'common.objectTemperature',
                })}
              </StyledMenuItem>
            </StyledSelect>
          </StyledShortFormControl>
        </Box>
      </Box>
      <WithGateway>
        {(gateway) => (
          <GatewaySensorDataChartContainer gateway={gateway} type={type} />
        )}
      </WithGateway>
    </MainContents>
  );
};

// ページタイトルの取得のために設定
GatewayDataPage.displayName = 'SensorDataPage';
export default GatewayDataPage;
