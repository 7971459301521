import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/domains/root/store';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { useLocation } from 'react-router-dom';
import { awsRum } from 'src/utils/rum';
import {
  deleteWorkspacesInvitation,
  getWorkspacesInvitations,
  removeResultSelector,
  resetCreateInvitation,
  WorkspacesInvitation,
} from '../slice';

interface Props {
  open: boolean;
  handleClose: () => void;
  workspaceId: string;
  invitation: WorkspacesInvitation;
}

const _Dialog = (props: Props) => {
  const { open, handleClose, workspaceId, invitation } = props;

  const intl = useIntl();
  const dispatch: AppDispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { status } = useSelector(removeResultSelector);

  const handleSubmit = () => {
    const token = getAccessTokenSilently();
    dispatch(
      deleteWorkspacesInvitation({
        token,
        workspaceId,
        invitation,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          getWorkspacesInvitations({
            token,
            workspaceId: workspaceId,
          }),
        );
      })
      .finally(() => {
        dispatch(resetCreateInvitation());
        handleClose();
      });
  };

  const location = useLocation();
  useEffect(() => {
    if (open) {
      awsRum().then((rum) => {
        rum.recordPageView(location.pathname + '#InvitationRemoveDialogRender');
      });
    }
  }, [location, open]);

  return (
    <>
      <LoadingOverlay open={status === 'loading'} />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth
        maxWidth="sm"
        aria-labelledby="invitation-remove-dialog"
      >
        <DialogTitle id="invitation-remove-dialog-title">
          <Title onClose={handleClose}>
            {intl.formatMessage({
              id: 'features.workspaces.invitations.list.dialog.title',
            })}
          </Title>
        </DialogTitle>
        <DialogContent>
          <Box py={1}>
            <Typography sx={{ wordBreak: 'break-all' }}>
              {intl.formatMessage(
                {
                  id: 'features.workspaces.invitations.list.dialog.message',
                },
                {
                  email: invitation?.email,
                },
              )}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" gap={1.5}>
            <NegativeButton onClick={handleClose}>
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.dialog.button.no',
              })}
            </NegativeButton>
            <PrimaryButton data-testid="SubmitButton" onClick={handleSubmit}>
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.dialog.button.yes',
              })}
            </PrimaryButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default _Dialog;
