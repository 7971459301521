import React from 'react';
import { GatewayBattery } from 'src/domains/root/features/gateways/slice';
import BatteryEmptyIcon from './BatteryEmptyIcon';
import BatteryLowIcon from './BatteryLowIcon';
import BatteryHighIcon from './BatteryHighIcon';
import BatteryChargingIcon from './BatteryChargingIcon';
import BatteryUnknownIcon from './BatteryUnknownIcon';

const BatteryIconMapping = {
  empty: <BatteryEmptyIcon />,
  low: <BatteryLowIcon />,
  high: <BatteryHighIcon />,
  charging: <BatteryChargingIcon />,
  unknown: <BatteryUnknownIcon />,
};

interface BatteryIconProps {
  battery: GatewayBattery | undefined;
}

const BatteryIcon: React.FC<BatteryIconProps> = (props) => {
  const { battery } = props;

  return BatteryIconMapping[battery ?? 'unknown'];
};

export default BatteryIcon;
