import React from 'react';
import { useIntl } from 'react-intl';
import CreateIcon from '@mui/icons-material/Create';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';

interface Props {
  onClick: () => void;
}

const RenameButton: React.FC<Props> = (props) => {
  const { onClick } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();
  return (
    <SecondaryButton
      data-testid="RenameButton"
      onClick={onClick}
      sx={{
        border: isSmallScreen ? 'unset' : undefined,
        px: isSmallScreen ? 0 : undefined,
        minWidth: isSmallScreen ? '90px' : undefined,
      }}
      startIcon={isSmallScreen && <CreateIcon />}
    >
      {intl.formatMessage({
        id: 'common.nameSetting',
      })}
    </SecondaryButton>
  );
};

export default RenameButton;
