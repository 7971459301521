import React from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  useMediaQuery,
} from '@mui/material';

export const Tooltip = React.memo(function Tooltip({
  isArrow = false,
  sx = [],
  children,
  ...rest
}: TooltipProps & { isArrow?: boolean }) {
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  if (isTouchDevice) {
    return children;
  }

  return (
    <MuiTooltip
      arrow={isArrow}
      componentsProps={{
        arrow: {
          sx: {
            color: '#505d6b',
          },
        },
        tooltip: {
          sx: {
            backgroundColor: '#505d6b',
            fontSize: 12,
            fontWeight: 400,
          },
        },
      }}
      sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
});
