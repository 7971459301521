/**
 * README
 * このhooksでは、redux toolkitではなく、@tanstack/react-queryを使用している。
 * この修正の「理由」「調査内容」「今後の@tanstack/react-query利用」については以下のPBIを参照すること。
 *
 * 参考：
 * @see https://www.pivotaltracker.com/story/show/186107857
 */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import type { Gateway } from 'src/domains/root/features/gateways/slice';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import * as API from './api';
import type { SensorData, SensorDataUrlResponse } from './type';

export type SucceededResult = {
  status: 'succeeded';
  phase:
    | 'partial_data_fetched'
    | 'url_fetched'
    | 'alert_count_fetched'
    | 'full_data_fetched';
  gatewaySensorData: SensorData[];
  fullDataButtonClicked: boolean;
  handleFullDataButtonClicked: () => void;
};

export type Result =
  | { status: 'hasError' }
  | { status: 'loading' }
  | SucceededResult;

export default function useGatewaysDataChart({
  gateway,
}: {
  gateway: Gateway;
}): Result {
  const { getAccessTokenSilently } = useAuth0();

  const [fullDataButtonClicked, setFullDataButtonClicked] = useState(false);

  /**
   * 12週分のセンサーデータを取得する。
   */
  const sensorDataResult = useQuery<SensorData[], API.Error>({
    queryKey: Keys.sensorData.gw12w(gateway.deviceId),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return API.getSensorDataWithParallel(gateway, token);
    },
    refetchOnWindowFocus: false,
  });

  /**
   * S3からすべてのセンサーデータを取得するためのURLを取得する。
   */
  const sensorDataUrlResult = useQuery<SensorDataUrlResponse[], API.Error>({
    queryKey: Keys.sensorData.gwUrl(gateway.deviceId),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return API.getSensorDataUrlWithParallel(gateway, token);
    },
    enabled: sensorDataResult.isSuccess,
    refetchOnWindowFocus: false,
  });

  /**
   * S3からすべてのセンサーデータを取得する。
   */
  const fullDataResult = useQuery<SensorData[], API.Error>({
    queryKey: Keys.sensorData.gwFull(gateway.deviceId),
    queryFn: async () => {
      return API.getFullSensorDataWithParallel(sensorDataUrlResult.data);
    },
    enabled: sensorDataUrlResult.isSuccess,
    refetchOnWindowFocus: false,
  });

  /**
   * リダイレクト処理
   */
  useRedirectForError(sensorDataResult, sensorDataUrlResult, fullDataResult);

  if (sensorDataResult.isPending) return { status: 'loading' };
  if (sensorDataResult.isError) return { status: 'hasError' };

  const phase = fullDataResult.isSuccess
    ? 'full_data_fetched'
    : sensorDataUrlResult.isSuccess
      ? 'url_fetched'
      : 'partial_data_fetched';

  return {
    status: 'succeeded',
    gatewaySensorData:
      fullDataResult.isSuccess && fullDataButtonClicked
        ? fullDataResult.data
        : sensorDataResult.data,
    phase,
    fullDataButtonClicked,
    handleFullDataButtonClicked: () => {
      setFullDataButtonClicked(true);
    },
  };
}

function useRedirectForError(
  sensorDataResult: UseQueryResult<SensorData[], API.Error>,
  sensorDataUrlResult: UseQueryResult<SensorDataUrlResponse[], API.Error>,
  fullDataResult: UseQueryResult<SensorData[], API.Error>,
) {
  const navigate = useNavigate();
  useEffect(() => {
    const error =
      sensorDataResult.error ||
      sensorDataUrlResult.error ||
      fullDataResult.error;
    if (!error) {
      return;
    }
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  }, [sensorDataResult.error, sensorDataUrlResult.error, fullDataResult.error]);
}
