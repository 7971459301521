import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { IMEI_REGEXP, CODE_REGEXP } from 'src/utils/gateways/validation';
import { AppDispatch } from 'src/domains/root/store';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import QrCodeReaderDialog from 'src/domains/root/commons/dialog/QrCodeReaderDialog';
import { awsRum } from 'src/utils/rum';
import {
  postWorkspacesGateway,
  workspacesGatewayParamsSelector,
} from '../slice';
import GatewayNameField from './form/GatewayNameField';
import IMEIField from './form/IMEIField';
import CodeField from './form/RegistrationCodeField';

interface FormValues {
  registrationCode: string;
  imei: string;
  gatewayName: string;
}

type Props = {
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const Form: React.FC<Props> = (props) => {
  const { dialogOpen, setDialogOpen } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const workspacesParams = useSelector(workspacesGatewayParamsSelector);
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    registrationCode: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.form.registrationCode.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        CODE_REGEXP,
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.validation.registrationCode.matches',
        }),
      ),
    imei: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.form.imei.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        IMEI_REGEXP,
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.validation.imei.matches',
        }),
      ),
    gatewayName: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.OtherSetting.gateways.new.form.gatewayName.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .max(200, intl.formatMessage({ id: 'validation.string.max' })),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      registrationCode: workspacesParams?.registrationCode ?? '',
      imei: workspacesParams?.imei ?? '',
      gatewayName: workspacesParams?.gatewayName ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, validateForm }) => {
      const { registrationCode, imei, gatewayName } = values;
      const token = getAccessTokenSilently();
      const promise = dispatch(
        postWorkspacesGateway({
          token,
          registrationCode,
          imei,
          gatewayName,
          workspaceId: workspace.workspaceId,
        }),
      );

      promise
        .unwrap()
        .then(() => resetForm())
        .catch((error) => {
          awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        })
        .finally(() => validateForm());

      return promise;
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const qrCodeReaderDialogProps = {
    formik,
    dialogOpen,
    setDialogOpen,
  };

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <ValidationBox>
          <CodeField />
        </ValidationBox>
        <ValidationBox>
          <IMEIField />
        </ValidationBox>
        <ValidationBox>
          <GatewayNameField />
        </ValidationBox>
        <Box display="flex" pt={1.5} justifyContent="space-between">
          <SubmitButton data-testid="SubmitButton" size="large" fullWidth>
            {intl.formatMessage({
              id: 'pages.OtherSetting.gateways.new.form.button',
            })}
          </SubmitButton>
        </Box>
      </FormikForm>
      {dialogOpen && <QrCodeReaderDialog {...qrCodeReaderDialogProps} />}
    </FormikProvider>
  );
};

export default Form;
