import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LATEST_TERMS_OF_SERVICE_VERSION } from 'src/constants';
import {
  getUsersMe,
  meResultSelector,
  Workspace,
} from '../features/users/slice';
import TermsOfServiceReagreement from '../pages/TermsOfServiceReagreement';

interface Props {
  /**
   * falseの場合利用契約再同意ページからchildrenページに移動しても強制に利用契約再同意ページに戻らない
   * @default true
   */
  reagreement?: boolean;
  children: (workspaces: Workspace[]) => React.ReactNode;
}

const WorkspaceRequired: React.FC<Props> = ({
  reagreement = true,
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const { data, status } = useSelector(meResultSelector);

  useEffect(() => {
    const token = getAccessTokenSilently();
    const promise = dispatch(getUsersMe({ token }));

    return () => promise.abort();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (status === 'idle' || status === 'loading') {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  } else if (status === 'succeeded' && data) {
    if (
      LATEST_TERMS_OF_SERVICE_VERSION !== data.agreedTermsOfServiceVersion &&
      reagreement
    ) {
      return <TermsOfServiceReagreement />;
    }

    return <>{children(data.workspaces)}</>;
  } else {
    // when status is 'faild', UnrecoverableErrorHandler handles errors.
    return null;
  }
};

export default WorkspaceRequired;
