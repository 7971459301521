import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import {
  requiredCharactersTester,
  unavailableCharactersTester,
  MIN_LENGTH,
  MAX_LENGTH,
} from 'src/utils/users/validation/password';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useValidationSchema = () => {
  const intl = useIntl();

  const passwordValidationSchema = useMemo(
    () =>
      yup
        .string()
        .label(intl.formatMessage({ id: 'users.new.form.password' }))
        .required(intl.formatMessage({ id: 'validation.mixed.required' }))
        .test(
          'passwordUnuseableCharactersTest',
          intl.formatMessage({
            id: 'users.new.validation.password.unavailableCharactersTest',
          }),
          unavailableCharactersTester,
        )
        .test(
          'passwordRequiredCharactersTest',
          intl.formatMessage(
            {
              id: 'users.new.validation.password.requiredCharactersTest',
            },
            { minLength: MIN_LENGTH, maxLength: MAX_LENGTH },
          ),
          requiredCharactersTester,
        ),
    [intl],
  );

  const passwordConfirmationValidationSchema = useMemo(
    () =>
      yup
        .string()
        .label(
          intl.formatMessage({ id: 'users.new.form.passwordConfirmation' }),
        )
        .required(intl.formatMessage({ id: 'validation.mixed.required' }))
        .oneOf(
          [yup.ref('password')],
          intl.formatMessage({
            id: 'users.new.validation.passwordConfirmation.notMatch',
          }),
        )
        .test(
          'passwordUnuseableCharactersTest',
          intl.formatMessage({
            id: 'users.new.validation.password.unavailableCharactersTest',
          }),
          unavailableCharactersTester,
        )
        .test(
          'passwordRequiredCharactersTest',
          intl.formatMessage(
            {
              id: 'users.new.validation.password.requiredCharactersTest',
            },
            { minLength: MIN_LENGTH, maxLength: MAX_LENGTH },
          ),
          requiredCharactersTester,
        ),
    [intl],
  );

  return {
    passwordValidationSchema,
    passwordConfirmationValidationSchema,
  };
};

export default useValidationSchema;
