import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TaskAltRounded, ErrorOutlineRounded } from '@mui/icons-material';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as API from 'src/apis';
import { IS_ENV } from 'src/constants';

interface Props {
  payments: API.Payment[];
  invoiceDescription?: boolean;
}

export default function PaymentMethodListTable({
  payments,
  invoiceDescription,
}: Props) {
  const intl = useIntl();

  const invoice = payments.find((payment) => payment.type === 'invoice');

  return (
    <Box bgcolor="white" p={3.5}>
      <Box pb={4}>
        {invoice?.status === 'disabled' && (
          <Alert severity="error">
            {IS_ENV.DEMO
              ? intl.formatMessage({ id: 'common.errors.on_demo_site' })
              : intl.formatMessage({
                  id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.alert.invoiceDisabled',
                })}
          </Alert>
        )}
        {invoiceDescription && (
          <Alert severity="info">
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.alert.invoiceDescription',
            })}
          </Alert>
        )}
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 150 }}>
                <Typography fontWeight="bold">
                  {intl.formatMessage({
                    id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.header.paymentMethod',
                  })}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: 158,
                  minWidth: 158,
                  boxSizing: 'content-box',
                }}
              >
                {/* 領域用 */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {intl.formatMessage({
                  id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.method.credit',
                })}
              </TableCell>
              <TableCell>
                <PaymentState type="credit" status="enabled" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  color: invoice?.status === 'activating' ? 'gray' : 'black',
                }}
              >
                {intl.formatMessage({
                  id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.method.invoice',
                })}
              </TableCell>
              <TableCell>
                <PaymentState
                  type="invoice"
                  status={invoice?.status ?? 'disabled'}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function PaymentState(payment: API.Payment) {
  const { type, status } = payment;

  if (type === 'credit') {
    return <PaymentStateStatement payment={payment} />;
  }

  if (type === 'invoice') {
    const stateMap = {
      ['notActivation']: <ActivateButton payment={payment} />,
      ['activating']: <ActivateButton payment={payment} />,
      ['enabled']: <PaymentStateStatement payment={payment} />,
      ['disabled']: <PaymentStateStatement payment={payment} />,
    };

    return stateMap[status];
  }
}

function ActivateButton({ payment }: { payment: API.Payment }) {
  const { status } = payment;

  const navigate = useNavigate();

  const intl = useIntl();

  const handleClick = () => {
    if (payment.type === 'invoice' && status === 'notActivation') {
      navigate('invoice');
    }
  };

  return (
    <PrimaryButton
      fullWidth
      disabled={status === 'activating'}
      onClick={handleClick}
    >
      {status === 'activating'
        ? intl.formatMessage({
            id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.state.activating',
          })
        : intl.formatMessage({
            id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.state.notActivation',
          })}
    </PrimaryButton>
  );
}

function PaymentStateStatement({ payment }: { payment: API.Payment }) {
  const intl = useIntl();

  return (
    <Box
      display="flex"
      justifyContent="left"
      alignItems="center"
      gap={1}
      pl={2}
    >
      {payment.status === 'enabled' ? (
        <>
          <TaskAltRounded
            sx={{
              color: '#5AD75F',
            }}
          />
          <Typography fontSize={14}>
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.state.enabled',
            })}
          </Typography>
        </>
      ) : (
        <>
          <ErrorOutlineRounded color="error" />
          <Typography fontSize={14}>
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.paymentMethodListTable.state.disabled',
            })}
          </Typography>
        </>
      )}
    </Box>
  );
}
