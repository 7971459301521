import React from 'react';
import { Box } from '@mui/material';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import WithGateway from 'src/domains/root/features/gateways/WithGateway';
import BreadCrumb from 'src/domains/root/features/gateways/memos/BreadCrumb';
import MemoList from 'src/domains/root/features/gateways/memos/MemoList';

export default function GatewaysMemos() {
  const isSmallScreen = useSmallScreen();

  return (
    <MainContents noXPaddingIfMobile showTopButton={!isSmallScreen}>
      <Box display="flex" flexDirection="column" rowGap={3.5}>
        <BreadCrumb />
        <WithGateway>
          {(gateway) => <MemoList gatewayId={gateway.deviceId} />}
        </WithGateway>
      </Box>
    </MainContents>
  );
}
