import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import BaseDrawerContainer from './BaseDrawer';
import GlobalHeader from './global-header/GlobalHeader';

export default function BaseLayoutContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
  const [hambugerMenuOpened, setHambugerMenuOpened] = useState(false);

  const handleMobileDrawerToggle = () => {
    setOpenMobileDrawer(!openMobileDrawer);
    handleHambugerMenuToggle();
  };

  const handleHambugerMenuToggle = () => {
    setHambugerMenuOpened(!hambugerMenuOpened);
  };

  return (
    <Box display="flex">
      <CssBaseline />
      <GlobalHeader
        handleMobileDrawerToggle={handleMobileDrawerToggle}
        hambugerMenuOpened={hambugerMenuOpened}
        handleHambugerMenuToggle={handleHambugerMenuToggle}
      />
      <BaseDrawerContainer
        openMobileDrawer={openMobileDrawer}
        handleMobileDrawerToggle={handleMobileDrawerToggle}
      />
      {children}
    </Box>
  );
}
