import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { css } from 'styled-components/macro';
import ObserveModeIcon from '../../icons/ObserveModeIcon';
import IndentLineIcon from './IndentLineIcon';

interface SensorUnitNameProps {
  name: string;
  observeMode: string;
}

const boxStyle = css`
  > * {
    margin-left: ${(props) => props.theme.spacing(0.5)};
    margin-right: ${(props) => props.theme.spacing(0.5)};
  }
`;

const nameStyle = css`
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
`;

const SensorUnitName: React.FC<SensorUnitNameProps> = (props) => {
  const { name, observeMode } = props;

  return (
    <Box display="flex" alignItems="center" css={boxStyle}>
      <IndentLineIcon />
      <ObserveModeIcon mode={observeMode} />
      <Typography css={nameStyle} sx={{ wordBreak: 'break-all' }}>
        {name}
      </Typography>
    </Box>
  );
};

export default SensorUnitName;
