import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { Registration } from '../slice';
import Form from './new/Form';
import RegistrationData from './new/RegistrationData';
import { newResultSelector } from './slice';

interface NewProps {
  registration: Registration;
}

const New: React.FC<NewProps> = ({ registration }) => {
  const { error } = useSelector(newResultSelector);
  const intl = useIntl();

  return (
    <>
      <Box mt={8.5} mb={7}>
        <Typography variant="h2" align="center">
          {intl.formatMessage({
            id: 'registrations.users.new.title',
          })}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: 500,
          },
        })}
      >
        {error && error.recoverable && (
          <Box mb={2}>
            <Alert severity="error">
              {intl.formatMessage({
                id: `registrations.users.new.error.${error.code}`,
              })}
            </Alert>
          </Box>
        )}
        <Box mb={7}>
          <RegistrationData
            registrationCode={registration.registrationCode}
            email={registration.email}
          />
        </Box>
        <Form registrationId={registration.registrationId} />
      </Box>
    </>
  );
};

export default New;
