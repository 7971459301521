import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import { getAlertState } from 'src/domains/root/features/current-alert/slice';
import useSnackbar from 'src/domains/root/commons/snackbar';
import { RootError } from '../utils/api';

export default function AlertStatus({
  children,
}: {
  children: React.ReactNode;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(
    'SNACKBAR_CURRENT_ALERT_ERROR',
  );
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  useEffect(() => {
    if (!workspace.workspaceId) return;

    const token = getAccessTokenSilently();

    const promise = dispatch(
      getAlertState({ token, workspaceId: workspace.workspaceId }),
    );

    promise
      .unwrap()
      .then(closeSnackbar)
      .catch((error) => {
        if (!(error as RootError).noticeable) {
          return;
        }
        enqueueSnackbar('common.alertErrorWithReload');
      });

    return () => promise.abort();
  }, [
    getAccessTokenSilently,
    dispatch,
    enqueueSnackbar,
    closeSnackbar,
    location.pathname,
    location.key,
    workspace.workspaceId,
  ]);

  return <>{children}</>;
}
