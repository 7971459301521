import { configureStore } from '@reduxjs/toolkit';
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { save, load } from 'redux-localstorage-simple';
import gatewaysReducer from 'src/domains/root/features/gateways/slice';
import alertsReducer from 'src/domains/root/features/alerts/slice';
import alertsHistoriesReducer from 'src/domains/root/features/alerts/histories/slice';
import gatewaysAreaIdReducer from 'src/domains/root/features/gateways/area-id/slice';
import gatewayAndSensorNamesReducer from 'src/domains/root/features/gateway-and-sensor-names/slice';
import currentAlertReducer from 'src/domains/root/features/current-alert/slice';
import currentWorkspaceReducer from 'src/domains/root/features/current-workspace/slice';
import sensorUnitObserveModeReducer from 'src/domains/root/features/sensor-units/observe/slice';
import sensorUnitsAlarmThresholdsReducer from 'src/domains/root/features/sensor-units/alarm-threshold/slice';
import newWorkspacesReducer from 'src/domains/root/features/workspaces/packages/slice';
import workspacesReducer from '../features/workspaces/slice';
import areasReducer from '../features/areas/slice';
import workspacesUsersReducer from '../features/workspaces/users/slice';
import workspacesInvitationsReducer from '../features/workspaces/invitations/slice';
import workspaceGatewayReducer from '../features/workspaces/gateways/slice';
import workspacesGatewaysSensorDataReducer from '../features/workspaces/gateways/sensor-data/slice';
import workspacesStripeCustomerReducer from '../features/workspaces/stripe-customer/slice';
import workspacesStripeCustomerPortalSessionReducer from '../features/workspaces/stripe-customer-portal-session/slice';
import usersEmailChangesReducer from '../features/users/email-changes/slice';
import usersReducer from '../features/users/slice';
import deleteAccountReducer from '../features/users/delete-account/slice';
import termsOfServiceReagreementReducer from '../features/users/terms-of-service-reagreement/slice';
import announceReducer from '../features/layout/global-header/announce/slice';
import setupWorkspaceReducer from '../features/setup/workspaces/slice';
import paymentsReducer from '../features/contracts/paymentSlice';

const reduxLocalstorageSimpleConfig = {
  states: ['announce'],
  namespace: 'cabiot_redux_state',
  // for only save
  debounce: 1000,
  // for only load
  disableWarnings: true,
};

export const createStore = () =>
  configureStore({
    reducer: {
      gateways: gatewaysReducer,
      alerts: alertsReducer,
      alertsHistories: alertsHistoriesReducer,
      areas: areasReducer,
      gatewaysAreaId: gatewaysAreaIdReducer,
      gatewayAndSensorNames: gatewayAndSensorNamesReducer,
      workspaces: workspacesReducer,
      currentWorkspace: currentWorkspaceReducer,
      currentAlert: currentAlertReducer,
      sensorUnitObserveMode: sensorUnitObserveModeReducer,
      sensorUnitsAlarmThresholds: sensorUnitsAlarmThresholdsReducer,
      workspacesUsers: workspacesUsersReducer,
      workspacesInvitations: workspacesInvitationsReducer,
      workspacesGateway: workspaceGatewayReducer,
      workspacesGatewaysSensorData: workspacesGatewaysSensorDataReducer,
      workspacesStripeCustomer: workspacesStripeCustomerReducer,
      workspacesStripeCustomerPortalSession:
        workspacesStripeCustomerPortalSessionReducer,
      users: usersReducer,
      usersEmailChanges: usersEmailChangesReducer,
      deleteAccount: deleteAccountReducer,
      termsOfServiceReagreement: termsOfServiceReagreementReducer,
      newWorkspaces: newWorkspacesReducer,
      announce: announceReducer,
      setupWorkspace: setupWorkspaceReducer,
      payments: paymentsReducer,
    },
    preloadedState: load(reduxLocalstorageSimpleConfig),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(save(reduxLocalstorageSimpleConfig)),
  });

export type Store = ReturnType<typeof createStore>;

export type RootState = ReturnType<Store['getState']>;
export type Status = 'idle' | 'loading' | 'succeeded' | 'failed';
export type AppDispatch = Store['dispatch'];

export interface Result<DATA, ERROR> {
  data: DATA | null;
  error: ERROR | null;
  status: Status;
}

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
