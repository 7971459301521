import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

const APP_VERSION = import.meta.env.VITE_WEBAPP_VERSION;

interface AppVersionMenuItemProps {
  isHambugerMenu?: boolean;
}

const AppVersionMenuItem: React.FC<AppVersionMenuItemProps> = ({
  isHambugerMenu,
}) => {
  const intl = useIntl();

  return (
    <MenuItem
      disabled={true}
      sx={
        isHambugerMenu
          ? {
              paddingTop: 1.5,
              paddingBottom: 0,
            }
          : {}
      }
    >
      <ListItemText>
        <Typography fontSize={14}>
          {intl.formatMessage({ id: `common.versionLabel` })}: {APP_VERSION}
        </Typography>
      </ListItemText>
    </MenuItem>
  );
};

export default AppVersionMenuItem;
