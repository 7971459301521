import React from 'react';
import { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import { useSelector } from 'react-redux';
import { meResultSelector } from 'src/domains/root/features/users/slice';
import { IS_ENV } from 'src/constants';
import AccountSettingMenuItem from './items/AccountSettingMenuItem';
import LogoutMenuItem from './items/LogoutMenuItem';
import TermsOfServiceMenuItem from './items/TermsOfServiceMenuItem';
import PrivacyPolicyMenuItem from './items/PrivacyPolicyMenuItem';
import AppVersionMenuItem from './items/AppVersionMenuItem';
import NotificationSettingsMenuItem from './items/NotificationSettingsMenuItem';
import ASCTMenuItem from './items/ASCTMenuItem';

const userInfoStyle = css`
  &:hover {
    background-color: white;
  }

  cursor: default;
  flex-direction: column;
  padding-top: 0;
`;

const userInfoBoxStyle = css`
  display: flex;
  height: 18px;
  justify-content: flex-start;
  width: 100%;
`;

const userEmailBoxStyle = css`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;
`;

const accountNameTypographyStyle = css`
  color: #969696;
  display: contents;
  font-size: 12px;
  font-weight: bold;
`;

const userEmailTypographyStyle = css`
  font-size: 14px;
  margin-bottom: 10px;
  white-space: break-spaces;
  width: 100%;
  word-break: break-all;
`;

const dividerStyle = css`
  &.MuiDivider-root {
    margin-top: 0;
  }

  margin-left: ${(props) => props.theme.spacing(2)};
  width: 90%;
`;

const HambugerMenuList: React.FC = () => {
  const intl = useIntl();

  const { data: me } = useSelector(meResultSelector);

  return (
    <MenuList sx={{ width: 332 }}>
      <MenuItem disableRipple css={userInfoStyle}>
        <Box css={userInfoBoxStyle}>
          <Typography variant="body2" css={accountNameTypographyStyle}>
            {intl.formatMessage({
              id: `appBar.email`,
            })}
          </Typography>
        </Box>
        <Box css={userEmailBoxStyle}>
          <Typography css={userEmailTypographyStyle}>{me?.email}</Typography>
        </Box>
      </MenuItem>
      <Divider css={dividerStyle} />
      <AccountSettingMenuItem currentEmail={me?.email} />
      <NotificationSettingsMenuItem />
      <TermsOfServiceMenuItem />
      <PrivacyPolicyMenuItem />
      {!IS_ENV.DEMO && <ASCTMenuItem />}
      <LogoutMenuItem />
      <AppVersionMenuItem isHambugerMenu={true} />
    </MenuList>
  );
};

export default HambugerMenuList;
