import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';

import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';
import { LATEST_TERMS_OF_SERVICE_VERSION } from 'src/constants';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

type ErrorCodeOfPut = 'emergency_maintenance' | 'unknown_error';

interface ParamsOfPut {
  token: Promise<string>;
  silent?: boolean;
}

export const putAgreedTermsOfService = createAsyncThunk<
  void,
  ParamsOfPut,
  { state: RootState; rejectValue: RootError<ErrorCodeOfPut> }
>('users/me/agreed-terms-of-service', async (params, thunkAPI) => {
  const token = await params.token;
  const body = { version: LATEST_TERMS_OF_SERVICE_VERSION };
  try {
    await axios.put<void>(
      `${API_ENDPOINT}/users/me/agreed-terms-of-service`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (error) {
    if (!isAxiosError(error)) throw error;
    return thunkAPI.rejectWithValue(putAgreedTermsOfServiceError(error));
  }
});

const putAgreedTermsOfServiceError = (
  error: AxiosError<RootErrorResponse>,
): RootError<ErrorCodeOfPut> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: false,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: false,
  };
};

interface State {
  resultOfPut: Result<null, RootError<ErrorCodeOfPut>>;
}

const initialState: State = {
  resultOfPut: {
    data: null,
    error: null,
    status: 'idle',
  },
};

const slice = createSlice({
  name: 'users/agreed-terms-of-service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putAgreedTermsOfService.pending, (state, action) => {
        if (!action.meta.arg.silent) {
          state.resultOfPut.data = null;
          state.resultOfPut.status = 'loading';
          state.resultOfPut.error = null;
        }
      })
      .addCase(putAgreedTermsOfService.fulfilled, (state) => {
        state.resultOfPut.data = null;
        state.resultOfPut.status = 'succeeded';
        state.resultOfPut.error = null;
      })
      .addCase(putAgreedTermsOfService.rejected, (state, action) => {
        if (!action.meta.arg.silent) {
          state.resultOfPut.data = null;
          state.resultOfPut.status = 'failed';
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          state.resultOfPut.error = action.payload!;
        }
      });
  },
});

export const resultSelector = createSelector(
  (state: RootState) => state.termsOfServiceReagreement,
  (state: State) => state.resultOfPut,
);

export default slice.reducer;
