import React from 'react';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import { useIntl } from 'react-intl';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  BreadcrumbTitleV3,
  BreadcrumbsV3,
} from 'src/domains/root/commons/breadcrumbs';
import PackagesCreated from '../../../features/workspaces/packages/Created';

const New: React.FC = () => {
  const intl = useIntl();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContents notAllowOverflow showTopButton={false}>
      <Box display="flex" flexDirection="column" gap={isSmallScreen ? 3 : 3.5}>
        <BreadcrumbsV3>
          <Link to="/settings/others">
            <BreadcrumbTitleV3 color="primary">
              {intl.formatMessage({
                id: 'pages.OtherSetting.title',
              })}
            </BreadcrumbTitleV3>
          </Link>
          <BreadcrumbTitleV3>
            {intl.formatMessage({
              id: 'features.workspaces.packages.new.title',
            })}
          </BreadcrumbTitleV3>
        </BreadcrumbsV3>

        <Box
          bgcolor="white"
          px={isSmallScreen ? 2 : 3.5}
          pt={isSmallScreen ? 2.5 : 3.5}
          pb={isSmallScreen ? 2.5 : 6}
        >
          <PackagesCreated />
        </Box>
      </Box>
    </MainContents>
  );
};

export default New;
