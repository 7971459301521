import { IntlShape } from 'react-intl';
import * as yup from 'yup';

export const companySchema = (intl: IntlShape) =>
  yup.object().shape({
    companyName: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .min(1)
      .max(30, intl.formatMessage({ id: 'validation.string.max' })),
    zipCode: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        /^[0-9]{7}$/,
        intl.formatMessage({ id: 'validation.string.zipCode' }),
      ),
    address: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .min(1)
      .max(50, intl.formatMessage({ id: 'validation.string.max' })),
    telNo: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .matches(
        /^[0-9]{10,11}$/,
        intl.formatMessage({ id: 'validation.string.telNo' }),
      ),
    email: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .min(1)
      .max(100, intl.formatMessage({ id: 'validation.string.emailLength' }))
      /**
       * yupのメール検査はdomain部に文字数制限があるためcabiotのvalidationを利用
       * @see https://github.com/jquense/yup/blob/v1.4.0/src/string.ts#L19-L22
       */
      .matches(
        /^(.+)@([^.]+(?:\.[^.]+)+)$/,
        intl.formatMessage({ id: 'validation.string.email' }),
      )
      .matches(
        /^[0-9a-zA-Z._!#$*+\-/=?^`{|}~%&]+@[0-9a-zA-Z.-]+$/,
        intl.formatMessage({ id: 'validation.string.email' }),
      ),
  });

export type CompanySchema = yup.InferType<ReturnType<typeof companySchema>>;
