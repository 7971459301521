import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import { DateTime } from 'luxon';
import CabiotLogo from '../../commons/CabiotLogo';
import DemoSiteAlertNotification from '../../commons/layout/global-header/DemoSiteAlertNotification';

interface RecoverableErrorProps {
  messageId: string;
}

const APP_VERSION = import.meta.env.VITE_WEBAPP_VERSION;

const RecoverableError: React.FC<RecoverableErrorProps> = ({ messageId }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const currentTime = useMemo(
    () => DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
    [],
  );

  return (
    <>
      {import.meta.env.MODE === 'demo' && <DemoSiteAlertNotification />}
      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={7.5}>
            <CabiotLogo />
          </Box>
          <Box mt={8.5} mb={10} maxWidth={560}>
            <Typography variant="h4" align="center" fontWeight={700}>
              {intl.formatMessage({ id: messageId })}
            </Typography>
          </Box>
          <Box width={320}>
            <PrimaryButton
              data-testid="PrimaryButton"
              fullWidth
              size="large"
              onClick={() => navigate(0)}
            >
              {intl.formatMessage({ id: 'common.buttons.reload' })}
            </PrimaryButton>
          </Box>
          <Box mt={4}>
            <Typography
              align="center"
              fontSize={(theme) => theme.typography.pxToRem(18)}
              color="darkgrey"
            >
              {intl.formatMessage({ id: `common.versionLabel` })}: {APP_VERSION}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              align="center"
              fontSize={(theme) => theme.typography.pxToRem(18)}
              color="darkgrey"
            >
              {currentTime}
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default RecoverableError;
