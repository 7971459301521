import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import axios from 'src/utils/api';

const API_ENDPOINT_ORIGIN = import.meta.env.VITE_APP_ENDPOINT;
const WEBAPP_VERSION = import.meta.env.VITE_WEBAPP_VERSION;
const requestHandler = (
  request: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  if (!API_ENDPOINT_ORIGIN || !WEBAPP_VERSION || !request.url) {
    return request;
  }

  const { origin, pathname } = new URL(request.url);

  if (
    origin === API_ENDPOINT_ORIGIN &&
    !pathname.startsWith('/help_center') &&
    !pathname.startsWith('/data')
  ) {
    request.headers.set({
      'X-Cabiot-Webapp-Version': WEBAPP_VERSION,
    });
  }
  return request;
};

const useAxiosInterceptor = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    const errorResponseHandler = (error: AxiosError) => {
      /**
       * デモサイトにmswでモックを設定している場合、ユーザーがタブを開いたまま動作をしないとservice workerがstopするケースがある。
       * その場合、ネットワークエラーが発生するため、その場合はページをリロードする。
       */
      if (
        import.meta.env.MODE === 'demo' &&
        error.message === 'Network Error'
      ) {
        location.reload();
      }

      if (error.response?.status === 401) {
        logout({
          logoutParams: { returnTo: `${location.origin}/errors/unauthorized` },
        });

        return Promise.reject(error);
      }

      return Promise.reject(error);
    };

    const requestInterceptor = axios.interceptors.request.use(
      requestHandler,
      (error) => error,
    );
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      errorResponseHandler,
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [logout]);
};

export default useAxiosInterceptor;
