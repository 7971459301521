import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _BatteryChargingIcon = createSvgIcon(
  <>
    <path
      d="M34 11H36.5C36.7761 11 37 11.2239 37 11.5V16.5C37 16.7761 36.7761 17 36.5 17H34V11Z"
      fill="#50D25A"
    />
    <path
      d="M34 11H36.5C36.7761 11 37 11.2239 37 11.5V16.5C37 16.7761 36.7761 17 36.5 17H34V11Z"
      fill="#50D25A"
      fillOpacity="0.2"
    />
    <rect y="4" width="34" height="20" rx="2" fill="#50D25A" />
    <rect
      y="4"
      width="34"
      height="20"
      rx="2"
      fill="#50D25A"
      fillOpacity="0.2"
    />
    <rect x="2" y="6" width="30" height="16" rx="2" fill="white" />
    <path
      d="M22.3792 11.7627H16.9589L18.9177 3.17002C18.9247 3.13763 18.9206 3.10377 18.9059 3.07411C18.8912 3.04445 18.867 3.02077 18.8372 3.00703C18.8081 2.99752 18.7768 2.99766 18.7479 3.00744C18.7189 3.01721 18.6938 3.0361 18.6762 3.06136L11.5207 14.6601C11.5077 14.6808 11.5006 14.7047 11.5 14.7293C11.4995 14.7538 11.5054 14.778 11.5173 14.7994C11.5293 14.8207 11.5467 14.8384 11.5678 14.8506C11.5888 14.8627 11.6127 14.8688 11.637 14.8683H15.6351L12.1826 24.8282C12.174 24.8586 12.1755 24.8909 12.1868 24.9203C12.1981 24.9497 12.2186 24.9746 12.2452 24.9912C12.2683 24.9995 12.2936 24.9995 12.3167 24.9912C12.3485 25.0029 12.3833 25.0029 12.4151 24.9912L22.4775 11.9528C22.4922 11.9316 22.5 11.9063 22.5 11.8804C22.5 11.8545 22.4922 11.8292 22.4775 11.8079C22.4651 11.7939 22.45 11.7826 22.433 11.7748C22.4161 11.767 22.3978 11.7629 22.3792 11.7627V11.7627Z"
      fill="white"
      stroke="white"
      strokeWidth="4"
      strokeLinejoin="bevel"
    />
    <path
      d="M22.3792 11.7627H16.9589L18.9177 3.17002C18.9247 3.13763 18.9206 3.10377 18.9059 3.07411C18.8912 3.04445 18.867 3.02077 18.8372 3.00703C18.8081 2.99752 18.7768 2.99766 18.7479 3.00744C18.7189 3.01721 18.6938 3.0361 18.6762 3.06136L11.5207 14.6601C11.5077 14.6808 11.5006 14.7047 11.5 14.7293C11.4995 14.7538 11.5054 14.778 11.5173 14.7994C11.5293 14.8207 11.5467 14.8384 11.5678 14.8506C11.5888 14.8627 11.6127 14.8688 11.637 14.8683H15.6351L12.1826 24.8282C12.174 24.8586 12.1755 24.8909 12.1868 24.9203C12.1981 24.9497 12.2186 24.9746 12.2452 24.9912C12.2683 24.9995 12.2936 24.9995 12.3167 24.9912C12.3485 25.0029 12.3833 25.0029 12.4151 24.9912L22.4775 11.9528C22.4922 11.9316 22.5 11.9063 22.5 11.8804C22.5 11.8545 22.4922 11.8292 22.4775 11.8079C22.4651 11.7939 22.45 11.7826 22.433 11.7748C22.4161 11.767 22.3978 11.7629 22.3792 11.7627V11.7627Z"
      fill="#50D25A"
    />
    <path
      d="M22.3792 11.7627H16.9589L18.9177 3.17002C18.9247 3.13763 18.9206 3.10377 18.9059 3.07411C18.8912 3.04445 18.867 3.02077 18.8372 3.00703C18.8081 2.99752 18.7768 2.99766 18.7479 3.00744C18.7189 3.01721 18.6938 3.0361 18.6762 3.06136L11.5207 14.6601C11.5077 14.6808 11.5006 14.7047 11.5 14.7293C11.4995 14.7538 11.5054 14.778 11.5173 14.7994C11.5293 14.8207 11.5467 14.8384 11.5678 14.8506C11.5888 14.8627 11.6127 14.8688 11.637 14.8683H15.6351L12.1826 24.8282C12.174 24.8586 12.1755 24.8909 12.1868 24.9203C12.1981 24.9497 12.2186 24.9746 12.2452 24.9912C12.2683 24.9995 12.2936 24.9995 12.3167 24.9912C12.3485 25.0029 12.3833 25.0029 12.4151 24.9912L22.4775 11.9528C22.4922 11.9316 22.5 11.9063 22.5 11.8804C22.5 11.8545 22.4922 11.8292 22.4775 11.8079C22.4651 11.7939 22.45 11.7826 22.433 11.7748C22.4161 11.767 22.3978 11.7629 22.3792 11.7627V11.7627Z"
      fill="#50D25A"
      fillOpacity="0.2"
    />
  </>,
  'BatteryChargingIcon',
);

const BatteryChargingIcon = styled(_BatteryChargingIcon).attrs<SvgIconProps>(
  () => ({
    viewBox: '0 0 37 28',
  }),
)`
  fill: none;
  height: 28px;
  width: 37px;
`;

export default BatteryChargingIcon;
