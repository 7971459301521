import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import useAxiosInterceptor from 'src/utils/api/use-axios-interceptor';
import Routes from './Routes';
import Providers from './Providers';
import DemoSiteAlertNotification from './commons/alert-notification/DemoSiteAlertNotification';

const App: React.FC = () => {
  useAxiosInterceptor();

  return (
    <>
      <CssBaseline />
      <Providers>
        {import.meta.env.MODE === 'demo' && <DemoSiteAlertNotification />}
        <Routes />
      </Providers>
    </>
  );
};

export default App;
