import React from 'react';
import { Permission, Role } from 'src/apis/types';
import { activeWorkspaceSelector } from '../features/users/slice';
import { useSelector } from '../store';

interface Props {
  permit: (permissions: Permission[], role: Role) => boolean;
  children: React.ReactNode | ((permitted: boolean) => React.ReactNode);
}

const AccessControl: React.FC<Props> = (props) => {
  const { permit, children } = props;
  const activeWorkspace = useSelector(activeWorkspaceSelector);

  if (!activeWorkspace) return null;

  const { permissions, role } = activeWorkspace;
  const permitted = permit(permissions, role);

  if (children instanceof Function) {
    return <>{children(permitted)}</>;
  }

  return permitted ? <>{children}</> : null;
};

export default AccessControl;
