import { createSvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

const _IndentLineIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.5 0C0.776142 0 1 0.223858 1 0.5V10H24.5C24.7761 10 25 10.2239 25 10.5C25 10.7761 24.7761 11 24.5 11H0V0.5C0 0.223858 0.223858 0 0.5 0Z"
    fill="black"
  />,
  'IndentLine',
);

const IndentLineIcon = styled(_IndentLineIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 25 21',
}))`
  fill: none;
  height: 21px;
  width: 25px;
`;

export default IndentLineIcon;
