import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from './icons/LogoutIcon';
import LogoutModal from './LogoutModal';

const LogoutMenuItem: React.FC = () => {
  const { logout } = useAuth0();
  const intl = useIntl();

  const [logoutOpen, setLogoutOpen] = useState(false);

  const handleLogoutOpen = () => {
    setLogoutOpen(true);
  };

  const handleClose = () => {
    setLogoutOpen(false);
  };

  const logoutAndClose = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      <MenuItem
        data-testid="LogoutMenuItem"
        onClick={handleLogoutOpen}
        divider={true}
        sx={{ height: 44 }}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>
          {intl.formatMessage({
            id: `globalHeader.menu.logout.title`,
          })}
        </ListItemText>
      </MenuItem>
      <LogoutModal
        logoutOpen={logoutOpen}
        handleClose={handleClose}
        logoutAndClose={logoutAndClose}
      />
    </>
  );
};

export default LogoutMenuItem;
