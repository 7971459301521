import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

type Props = {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
  children: React.ReactNode;
};
export default function SettingGroupBox(props: Props) {
  const { icon, title, description, children } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box
        display="flex"
        bgcolor="white"
        px={isMediumScreen ? 2 : 3.5}
        py={isMediumScreen ? 3 : 2.5}
      >
        <Box
          display="flex"
          justifyContent="center"
          mr={isMediumScreen ? 2 : 3}
          minWidth={40}
        >
          {icon}
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignContent="stretch"
        >
          <Box mb={2.5}>
            <Box
              display="flex"
              flexDirection={isSmallScreen ? 'column' : 'row'}
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Box>
                <Typography fontWeight="bold">{title}</Typography>
              </Box>
              <Box>
                <Typography fontSize={12}>{description}</Typography>
              </Box>
            </Box>
            {!isSmallScreen && (
              <Box mt={0.5}>
                <Divider />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            columnGap={3}
            rowGap={3.5}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignContent="stretch"
            flexWrap="wrap"
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
}
