import React from 'react';
import { css } from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import {
  useMediaQuery,
  useTheme,
  Box,
  Divider,
  Switch,
  FormControlLabel,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Text } from 'src/shared/ui';

const rootBoxStyle = css`
  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const alarmNameBoxStyle = css`
  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;

    > h6 {
      text-align: start;
    }
  }
`;

const formControlLabelBoxStyle = css`
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-right: 0;
    margin-top: ${(props) => props.theme.spacing(3)};
    width: 100%;
  }
`;

const commonTextFieldStyle = css`
  width: 180px;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }

  & .MuiFormHelperText-root {
    font-size: ${(props) => props.theme.typography.pxToRem(10)};
    font-weight: bold;
    margin-left: 0;
    margin-right: 0;
  }

  &.Mui-focuesd fieldset {
    border-width: 2px;
  }
`;

const lowerTextFieldStyle = css`
  height: 81px;
  ${commonTextFieldStyle}
`;

const upperTextFieldStyle = css`
  height: 41px;
  ${commonTextFieldStyle}
`;

const Line = css`
  background-color: #dcdcdc;
  flex-grow: 1;
  height: 1px;
  top: 210px;
`;

type FormValueItem = {
  upperThresholdEnabled: boolean;
  upperThreshold: number | undefined;
  lowerThresholdEnabled: boolean;
  lowerThreshold: number | undefined;
};
export type FormValue = {
  temperature: FormValueItem;
  humidity: FormValueItem;
  objectTemperature: FormValueItem;
  condensationAlertTemperature: { enabled: boolean };
};

interface Props {
  permitted: boolean;
  target: 'temperature' | 'humidity' | 'objectTemperature';
}
export default function AlarmThresholdInputForm(props: Props) {
  const {
    values,
    errors,
    handleChange: formikHandleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext<FormValue>();
  const { permitted, target } = props;
  const intl = useIntl();
  const targetKey: keyof FormValue = target;
  const targetValues = values[targetKey];
  const targetErrors = errors[targetKey];

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, type, name } = e.target;

    formikHandleChange(e);

    // 数字 or undefinedにタイプを管理するため ''の空文字はundefinedに変換
    if (value === '' && type === 'number') {
      setFieldValue(name, undefined);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="baseline"
      justifyContent="center"
      flexDirection="row"
      gap={1}
      css={rootBoxStyle}
    >
      <Box
        css={alarmNameBoxStyle}
        display="flex"
        gap={3}
        alignItems="center"
        mr={isMediumScreen ? 2 : undefined}
      >
        <Text
          variant="Basic_A"
          sx={{ mr: 4, width: { md: 82 }, textAlign: 'right' }}
        >
          {intl.formatMessage({
            id: `common.${target}`,
          })}
        </Text>
        {isMediumScreen && <Divider css={Line} />}
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        mt="10px"
        mr={4}
        css={formControlLabelBoxStyle}
      >
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={targetValues.upperThresholdEnabled}
              onChange={handleChange}
              name={`${target}.upperThresholdEnabled`}
              disabled={!permitted}
            />
          }
          label={''}
        />
        <TextField
          label={intl.formatMessage({
            id: 'alarmThresholdData.upperThreshold',
          })}
          variant="outlined"
          size="small"
          type="number"
          fullWidth
          css={upperTextFieldStyle}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {target === 'humidity' ? '%' : '°C'}{' '}
                {intl.formatMessage({
                  id: 'alarmThresholdData.moreThanOrEqual',
                })}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={
            target === 'humidity'
              ? {
                  min: '0',
                  max: '100',
                  step: '0.1',
                }
              : { min: '-100', max: '1000', step: '0.1' }
          }
          name={`${target}.upperThreshold`}
          helperText={targetErrors?.upperThreshold}
          error={
            targetValues.upperThresholdEnabled && !!targetErrors?.upperThreshold
          }
          value={targetValues.upperThreshold ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!targetValues.upperThresholdEnabled || !permitted}
        />
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        mt="10px"
        css={formControlLabelBoxStyle}
      >
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={targetValues.lowerThresholdEnabled}
              onChange={handleChange}
              name={`${target}.lowerThresholdEnabled`}
              disabled={!permitted}
            />
          }
          label={''}
        />
        <TextField
          label={intl.formatMessage({
            id: 'alarmThresholdData.lowerThreshold',
          })}
          variant="outlined"
          size="small"
          type="number"
          fullWidth
          css={lowerTextFieldStyle}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {target === 'humidity' ? '%' : '°C'}{' '}
                {intl.formatMessage({
                  id: 'alarmThresholdData.lessThanOrEqual',
                })}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={
            target === 'humidity'
              ? {
                  min: '0',
                  max: '100',
                  step: '0.1',
                }
              : { min: '-100', max: '1000', step: '0.1' }
          }
          name={`${target}.lowerThreshold`}
          helperText={targetErrors?.lowerThreshold}
          error={
            targetValues.lowerThresholdEnabled && !!targetErrors?.lowerThreshold
          }
          value={targetValues.lowerThreshold ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!targetValues.lowerThresholdEnabled || !permitted}
        />
      </Box>
    </Box>
  );
}
