import styled from 'styled-components';
import BaseButton, { BaseButtonProps } from './Base';

export type PrimaryButtonProps = BaseButtonProps;

const PrimaryButton = styled(BaseButton)`
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};

  :hover {
    background: ${(props) => props.theme.palette.nito.main};
    color: ${(props) => props.theme.palette.common.white};
  }

  :disabled {
    background: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export default PrimaryButton;
