import { Navigate, Outlet } from 'react-router-dom';
import AccessControl from './AccessControl';

export default function AdminOnly() {
  return (
    <AccessControl permit={(_, role) => role === 'admin'}>
      {(permitted) => (permitted ? <Outlet /> : <Navigate to="/" />)}
    </AccessControl>
  );
}
