import styled from 'styled-components';
import { Chip as _Chip } from '@mui/material';

const Chip = styled(_Chip).attrs(() => ({
  size: 'small',
}))`
  background-color: ${(props) => props.theme.palette.chip.main};
  color: ${(props) => props.theme.palette.common.white};
  font-size: ${(props) => props.theme.typography.pxToRem(12)};
`;

export default Chip;
