import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { Result, RootState } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface Workspace {
  workspaceId?: string;
  name: string;
}

interface PostWorkspaceParams {
  token: Promise<string>;
  registrationCode: string;
  imei: string;
  gatewayName: string;
}

interface PostWorkspaceResponse {
  workspaceId: string;
  name: string;
  /**
   * 契約終了時点のtimestamp(ms)
   * この値が 2024/01/02T00:00:00(+09:00) のtimestampであるとき、
   * 画面には「2024/1/1まで」と表示する。（つまり表示の際に-1日する）
   */
  contractExpiredAt: number;
}

export type PostWorkspaceResponseErrorCode =
  | 'invalid_workspace_name'
  | 'invalid_gateway_name'
  | 'joined_workspace'
  | 'emergency_maintenance'
  | 'not_found'
  | 'conflict'
  | 'unknown_error';

export const postWorkspace = createAsyncThunk<
  PostWorkspaceResponse,
  PostWorkspaceParams,
  {
    state: RootState;
    rejectValue: RootError<PostWorkspaceResponseErrorCode>;
  }
>('workspaces/postWorkspace', async (params, thunkAPI) => {
  const { workspace } = thunkAPI.getState().setupWorkspace;

  const token = await params.token;
  const { registrationCode, imei, gatewayName } = params;
  const workspaceName = workspace?.name;
  const body = { workspaceName, registrationCode, imei, gatewayName };

  try {
    const response = await axios.post<PostWorkspaceResponse>(
      `${API_ENDPOINT}/workspaces`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const { workspaceId, name, contractExpiredAt } = response.data;
    return { workspaceId, name, contractExpiredAt };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const _error = getPostWorkspaceResponseErrorCode(error);
    return thunkAPI.rejectWithValue(_error);
  }
});

const getPostWorkspaceResponseErrorCode = (
  error: AxiosError<RootErrorResponse>,
): RootError<PostWorkspaceResponseErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;
    switch (status) {
      case 400:
        switch (errorCode) {
          case 'INVALID_WORKSPACE_NAME':
            return {
              code: 'invalid_workspace_name',
              noticeable: false,
              recoverable: true,
            };
          case 'INVALID_GATEWAY_NAME':
            return {
              code: 'invalid_gateway_name',
              noticeable: false,
              recoverable: true,
            };
          case 'JOINED_WORKSPACE':
            return {
              code: 'joined_workspace',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'not_found',
          noticeable: false,
          recoverable: true,
        };
      case 409:
        return {
          code: 'conflict',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface WorkspacesState {
  workspace: Workspace | null;
  workspaceParams: Omit<PostWorkspaceParams, 'token'> | null;
  error: RootError<PostWorkspaceResponseErrorCode> | null;
  loading: boolean;
  createResult: Result<
    PostWorkspaceResponse,
    RootError<PostWorkspaceResponseErrorCode>
  >;
}

const initialState: WorkspacesState = {
  workspace: null,
  workspaceParams: null,
  error: null,
  loading: false,
  createResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    saveWorkspace(
      state,
      action: PayloadAction<Omit<Workspace, 'workspaceId'>>,
    ) {
      const { name } = action.payload;
      state.workspace = { name };
    },
    saveWorkspaceParams(
      state,
      action: PayloadAction<Omit<PostWorkspaceParams, 'token'>>,
    ) {
      const { registrationCode, imei, gatewayName } = action.payload;
      state.workspaceParams = { registrationCode, imei, gatewayName };
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postWorkspace.pending, (state) => {
        state.createResult.status = 'loading';
        state.createResult.error = null;
      })
      .addCase(postWorkspace.fulfilled, (state, action) => {
        state.createResult.data = action.payload;
        state.createResult.error = null;
        state.createResult.status = 'succeeded';
      })
      .addCase(postWorkspace.rejected, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.createResult.error = action.payload!;
        state.createResult.status = 'failed';
      });
  },
});

export const workspaceSelector = createSelector(
  (state: RootState) => state.setupWorkspace,
  (state: WorkspacesState) => state.workspace,
);

export const workspaceParamsSelector = createSelector(
  (state: RootState) => state.setupWorkspace,
  (state: WorkspacesState) => state.workspaceParams,
);

export const errorSelector = createSelector(
  (state: RootState) => state.setupWorkspace,
  (state: WorkspacesState) => state.error,
);

export const loadingSelector = createSelector(
  (state: RootState) => state.setupWorkspace,
  (state: WorkspacesState) => state.loading,
);

export const createResultSelector = createSelector(
  (state: RootState) => state.setupWorkspace,
  (state: WorkspacesState) => state.createResult,
);

export const { saveWorkspace, saveWorkspaceParams, resetError } =
  workspacesSlice.actions;

export default workspacesSlice.reducer;
