import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { css } from 'styled-components/macro';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import theme from 'src/domains/root/styles/theme';
import WorkspaceInfo from 'src/domains/root/features/workspaces/WorkspaceInfo';
import MenuList from './MenuList';
import DividerIcon from './icons/DividerIcon';
import ShortDividerIcon from './icons/ShortDividerIcon';
import DrawerLeftButtonIcon from './icons/DrawerLeftButtonIcon';
import DrawerRightButtonIcon from './icons/DrawerRightButtonIcon';

const drawerWidth = 240;

const Root = styled.div`
  display: flex;

  & .MuiDrawer-paperAnchorDockedLeft {
    border-right: none;
  }
`;

const drawerListStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 0;
`;

const drawerBoxStyle = css`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: ${theme.spacing(6)};
`;

const dividerBoxStyle = css`
  height: 20px;
`;

const dividerStyle = css`
  margin-bottom: ${theme.spacing(2.5)};
  margin-left: ${theme.spacing(1.5)};
`;

const drawerButtonIconWrapper = css`
  position: fixed;
  top: 55%;
  transform: translateY(-55%);
  z-index: 1300;
`;

const drawerLeftButtonIconWrapperStyle = css`
  left: 210px;
  ${drawerButtonIconWrapper}
`;

const drawerRightButtonIconWrapperStyle = css`
  left: 35px;
  ${drawerButtonIconWrapper}
`;

interface MiniVariantDrawerProps {
  handleDrawerToggle: () => void;
  currentAlertCount: number;
  drawerOpen: boolean;
}

const MiniVariantDrawer: React.FC<MiniVariantDrawerProps> = (props) => {
  const { handleDrawerToggle, currentAlertCount, drawerOpen } = props;

  return (
    <>
      <Root>
        <Drawer
          variant="permanent"
          sx={[
            {
              width: drawerOpen ? drawerWidth : 65,
              flexShrink: 0,
              whiteSpace: 'nowrap',
              '.MuiDrawer-paper': {
                width: drawerOpen ? drawerWidth : 65,
                mt: 7,
              },
            },
          ]}
        >
          <List css={drawerListStyle}>
            <WorkspaceInfo drawerOpen={drawerOpen} />
            <Box css={dividerBoxStyle}>
              {drawerOpen === true ? (
                <DividerIcon css={dividerStyle} />
              ) : (
                <ShortDividerIcon css={dividerStyle} />
              )}
            </Box>
            <Box css={drawerBoxStyle} data-testid="MenuList">
              <MenuList alert={currentAlertCount} drawerOpen={drawerOpen} />
            </Box>
          </List>
        </Drawer>
      </Root>
      {drawerOpen === true ? (
        <IconButton
          data-testid="DrawerToggleButton"
          onClick={handleDrawerToggle}
          css={drawerLeftButtonIconWrapperStyle}
          size="large"
        >
          <DrawerLeftButtonIcon />
        </IconButton>
      ) : (
        <IconButton
          data-testid="DrawerToggleButton"
          onClick={handleDrawerToggle}
          css={drawerRightButtonIconWrapperStyle}
          size="large"
        >
          <DrawerRightButtonIcon />
        </IconButton>
      )}
    </>
  );
};

export default MiniVariantDrawer;
