import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { updatePaymentInvoiceStatusActivating } from 'src/domains/root/features/users/slice';
import { AppDispatch } from 'src/domains/root/store';
import UnrecoverableError from 'src/domains/root/pages/errors/UnrecoverableError';
import { updateShowInvoiceSuccessMessage } from '../paymentSlice';
import InvoiceTermsOfService from './InvoiceTermsOfService';
import CompanyForm from './CompanyForm';
import EnableInvoiceDialog from './EnableInvoiceDialog';
import { type CompanySchema, companySchema } from './companySchema';
import { usePutWorkspacesPaymentsInvoicesActivating } from './usePutWorkspacesPaymentsInvoicesActivating';

const PAYMENT_METHOD_PAGE = '/settings/others/contracts/payment-method';

type Step = 1 | 2;

const STEPS: Step[] = [1, 2];

const initialValues: CompanySchema = {
  companyName: '',
  zipCode: '',
  address: '',
  telNo: '',
  email: '',
};

export default function EnableInvoice() {
  const [step, setStep] = useState<Step>(1);
  const [isEnableInvoiceDialogOpen, setIsEnableInvoiceDialogOpen] =
    useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const formik = useFormik<CompanySchema>({
    initialValues,
    validationSchema: companySchema(intl),
    onSubmit: () => {
      openEnableInvoiceDialog();
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const enableInvoiceResult = usePutWorkspacesPaymentsInvoicesActivating({
    onError: () => {
      setIsEnableInvoiceDialogOpen(false);
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });
    },
  });

  if (enableInvoiceResult.status === 'hasError') {
    return <RedirectionForPutError errorCode={enableInvoiceResult.errorCode} />;
  }

  const goStep = (to: 'back' | 'next') => {
    setStep((prevStep) => {
      const current = STEPS.indexOf(prevStep);

      window.scrollTo(0, 0);

      if (to === 'back') {
        return STEPS[Math.max(current - 1, 0)];
      }

      if (to === 'next') {
        return STEPS[Math.min(current + 1, STEPS.length - 1)];
      }

      return prevStep;
    });
  };

  const openEnableInvoiceDialog = () => {
    setIsEnableInvoiceDialogOpen(true);
  };

  const closeEnableInvoiceDialog = () => {
    enableInvoiceResult.reset();
    setIsEnableInvoiceDialogOpen(false);

    if (showSuccessMessage) {
      dispatch(updateShowInvoiceSuccessMessage(true));
      dispatch(updatePaymentInvoiceStatusActivating());

      navigate(PAYMENT_METHOD_PAGE);
      return;
    }
  };

  const handleEnableInvoice = async () => {
    await enableInvoiceResult.mutate(formik.values);
    setShowSuccessMessage(true);
  };

  return (
    <>
      <Box bgcolor="white" p={3.5} borderRadius={1}>
        <Typography fontWeight="bold">STEP {step} / 2</Typography>
        <Typography fontWeight="bold">
          <StepDescription step={step} />
        </Typography>

        <Box display="flex" justifyContent="center">
          <Step
            formik={formik}
            step={step}
            npErrors={
              enableInvoiceResult.status === 'hasNpError'
                ? enableInvoiceResult.npErrorCodes
                : []
            }
            goStep={goStep}
          />
        </Box>
      </Box>
      <EnableInvoiceDialog
        open={isEnableInvoiceDialogOpen}
        submitting={enableInvoiceResult.status === 'loading'}
        showSuccessMessage={showSuccessMessage}
        handleClose={closeEnableInvoiceDialog}
        handleSubmit={handleEnableInvoice}
        {...formik.values}
      />
    </>
  );
}

function StepDescription({ step }: { step: Step }) {
  const intl = useIntl();

  const stepDescriptionMap = {
    1: intl.formatMessage({
      id: 'pages.OtherSetting.contracts.payment-method.invoice.termsOfService.description',
    }),
    2: intl.formatMessage({
      id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.description',
    }),
  };
  return stepDescriptionMap[step];
}

function Step({
  formik,
  step,
  npErrors,
  goStep,
}: {
  formik: FormikProps<CompanySchema>;
  step: Step;
  npErrors: string[] | undefined;
  goStep: (to: 'back' | 'next') => void;
}) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const stepMap = {
    1: <InvoiceTermsOfService goStep={goStep} />,
    2: <CompanyForm formik={formik} npErrors={npErrors} goBack={goBack} />,
  };

  return stepMap[step];
}

function RedirectionForPutError(props: { errorCode: string }) {
  switch (props.errorCode) {
    case 'not_found':
      return <Navigate to="/" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'gone':
      return (
        <Navigate
          replace
          to={PutWorkspacesPaymentsInvoicesActivatingGoneErrorPageRoute}
        />
      );
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'bad_request':
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}

export function PutWorkspacesPaymentsInvoicesActivatingGoneErrorPage() {
  return (
    <UnrecoverableError
      messageId="pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.errors.gone"
      backToPage
    />
  );
}

export const PutWorkspacesPaymentsInvoicesActivatingGoneErrorPageRoute =
  '/errors/contracts/payment-method/invoice/gone';
