import React from 'react';
import GatewaySettingTableContainer from 'src/domains/root/features/gateways/settings/GatewaySettingTableContainer';
import MainContents from 'src/domains/root/commons/layout/MainContents';

const Setting: React.FC = () => {
  return (
    <MainContents noXPaddingIfMobile notAllowOverflow showContractExpiredAlert>
      <GatewaySettingTableContainer />
    </MainContents>
  );
};

// ページタイトルの取得のために設定
Setting.displayName = 'Setting';
export default Setting;
