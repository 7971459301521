import React from 'react';
import { useIntl } from 'react-intl';
import { Chip } from '@mui/material';
import { Tooltip } from 'src/shared/ui';
import type { SvgIconProps, ChipProps } from '@mui/material';
import * as API from 'src/apis';
import ArrowDownwardIcon from './icons/ArrowDownwardIcon';
import ArrowUpwardIcon from './icons/ArrowUpwardIcon';

const reasonIcons: { [key in API.AlertReason]: React.FC<SvgIconProps> } = {
  upper: ArrowUpwardIcon,
  lower: ArrowDownwardIcon,
};

interface Props extends ChipProps {
  type: API.AlertType;
  reason: API.AlertReason;
}

const bgColors: { [type in API.AlertType]: string } = {
  temperature: '#FAF5BE',
  humidity: '#C8FAFF',
  objectTemperature: '#F5C8C8',
  condensationAlertTemperature: '#C8CAFF',
};

const AlertTypeChip = ({ type, reason, sx = [], ...rest }: Props) => {
  const intl = useIntl();

  const ArrowIcon = reasonIcons[reason];
  const bgColor = bgColors[type];

  const noArrowIcon = type === 'condensationAlertTemperature';

  return (
    <Chip
      size="medium"
      icon={
        noArrowIcon ? undefined : (
          <Tooltip
            title={intl.formatMessage({
              id: `alertTypeCell.${type}.${reason}`,
            })}
            placement="top"
          >
            <ArrowIcon />
          </Tooltip>
        )
      }
      label={intl.formatMessage({
        id: `common.${type}`,
      })}
      sx={[
        (theme) => ({
          width: '100%',
          height: 24,
          fontSize: theme.typography.pxToRem(12),
          px: 1,
          fontWeight: 'bold',
          textAlign: 'center',
          bgcolor: bgColor,
          color: theme.palette.text.primary,

          '> .MuiChip-label': {
            lineHeight: theme.typography.pxToRem(24),
            minWidth: 80,
            p: 0,
            overflow: 'unset',
          },
        }),

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  );
};

export default AlertTypeChip;
