import React, { useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import { useIntl } from 'react-intl';
import Highcharts, { Chart } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getGlobalOptions } from 'src/domains/root/utils/highcharts/base-options';
import ErrorBoundary from 'src/domains/root/commons/ErrorBoundary';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import { awsRum } from 'src/utils/rum';
import { CompareChartDataType } from '../../utils/highcharts/chart-utils';
import { Gateway } from './slice';
import { createChartOptionsForGateway } from './createChartOptions';
import { SucceededResult } from './sensor-data/useGatewaysSensorDataChart';
import useResizeObserverRef from './useResizeObserverRef';
import ChartContextMenu from './ChartContextMenu';
import CSVDownloadDialog from './CSVDownloadDialog';
import usePostGatewayCsv from './sensor-data/usePostGatewayCsv';

interface GatewaySensorDataChartProps {
  type: CompareChartDataType;
  gateway: Gateway;
  gatewaySensorDataResult: SucceededResult;
}

const GatewaySensorDataChart: React.FC<GatewaySensorDataChartProps> = (
  props,
) => {
  const { type, gateway, gatewaySensorDataResult } = props;
  const intl = useIntl();

  const {
    mutate,
    reset,
    result: submitResult,
  } = usePostGatewayCsv({
    gatewayId: gateway.deviceId,
    targetType: type,
  });

  const [csvDownloadDialogOpen, setCSVDownloadDialogOpen] = useState(false);
  const openCSVDownloadDialog = () => {
    setCSVDownloadDialogOpen(true);
    reset();
  };
  const closeCSVDownloadDialog = () => {
    setCSVDownloadDialogOpen(false);
  };

  const onSubmitCSVDownload = async (begin: number, end: number) => {
    try {
      const data = await mutate(begin, end);

      if (!data.s3Url) {
        return;
      }

      downloadFile({ fileURL: data.s3Url });
    } catch (error) {
      awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    }
  };

  // highchartsの基本設定
  Highcharts.setOptions(getGlobalOptions(intl));

  // 「highchartsの表示領域が変更されたか？」のフラグを管理する
  const [graphRangeTouched, setGraphRangeTouched] = useState(false);
  const touchGraphRange = () => setGraphRangeTouched(true);

  // highchartsの設定

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const sensorUnit = gateway.sensorUnits[0];
  const displayAreaName = gateway.area ? gateway.area.displayAreaName : '';

  const options: Highcharts.Options = createChartOptionsForGateway({
    intl,
    sensorUnit,
    gateway,
    gatewaySensorData: gatewaySensorDataResult.gatewaySensorData,
    graphRangeTouched,
    displayAreaName,
    type,
    isFullData:
      gatewaySensorDataResult.fullDataButtonClicked &&
      gatewaySensorDataResult.phase === 'full_data_fetched',
    isSmallScreen,
    touchGraphRange,
  });

  function handleFullSensorData() {
    gatewaySensorDataResult.handleFullDataButtonClicked();
  }

  // サイドバーを折りたたんだ際にグラフがresizeされるようにする
  const resizeObserverRef = useResizeObserverRef();

  // データタイプを変更したときにグラフデータが空のとき、ナビゲーターが非表示になってしまうバグの対応
  const highchartsRef = useRef(null);
  useEffect(() => {
    const highcharts = highchartsRef.current as unknown as Chart;
    const rendered = highcharts?.chart.navigator.rendered;

    if (!rendered) {
      highcharts?.chart.rangeSelector.clickButton(6, true);
    }
  }, [type]);

  return (
    <ErrorBoundary
      message={intl.formatMessage({
        id: 'gatewaySensorDataChart.errorBoundary',
      })}
    >
      <Box ref={resizeObserverRef}>
        <Box
          display="flex"
          position="relative"
          justifyContent="flex-end"
          sx={{
            zIndex: 1001,
          }}
        >
          <Box
            display="grid"
            justifyContent="right"
            alignItems="center"
            gridAutoFlow="column"
            columnGap={2}
            pt={1}
            pr={3}
            mb={{ xs: 0, sm: -2 }}
            width="100%"
            bgcolor="white"
          >
            <FullSensorDataButton
              result={gatewaySensorDataResult}
              handleFullSensorData={handleFullSensorData}
            />
            <ChartContextMenu onClickCsvDownload={openCSVDownloadDialog} />
            {csvDownloadDialogOpen && (
              <CSVDownloadDialog
                gatewayId={gateway.deviceId}
                submitResult={submitResult}
                onClose={closeCSVDownloadDialog}
                onSubmit={onSubmitCSVDownload}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ userSelect: 'none', touchAction: 'pan-x pan-y' }}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={options}
            ref={highchartsRef}
          />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default GatewaySensorDataChart;

function FullSensorDataButton({
  result,
  handleFullSensorData,
}: {
  result: SucceededResult;
  handleFullSensorData: () => void;
}) {
  const intl = useIntl();

  return (
    <SecondaryButton
      data-testid="FullSensorDataButton"
      onClick={handleFullSensorData}
      disabled={result.fullDataButtonClicked}
      style={{
        height: 24,
      }}
    >
      {result.fullDataButtonClicked && result.phase !== 'full_data_fetched' && (
        <Box display="flex" pr={0.5}>
          <CircularProgress size={12} thickness={8} />
        </Box>
      )}
      {intl.formatMessage({
        id:
          result.fullDataButtonClicked && result.phase === 'full_data_fetched'
            ? 'sensorDataChart.fullSensorDataButton.done'
            : 'sensorDataChart.fullSensorDataButton.yet',
      })}
    </SecondaryButton>
  );
}

function downloadFile({ fileURL }: { fileURL: string }) {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = fileURL;

  // fileNameはurlのheaderからブラウザが取得
  tempLink.setAttribute('download', '');

  // download attributeがない場合のサーポット、ほぼないが念の為
  // @see https://caniuse.com/download
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  document.body.removeChild(tempLink);
}
