import React from 'react';
import { errorSelector } from '../features/invitations/slice';
import { useSelector } from '../store';
import ErrorHandler, { ErrorHandlerProps } from './ErrorHandler';

export default function Initializer({
  redirectTo,
  children,
}: Omit<ErrorHandlerProps, 'error'> & {
  children: React.ReactNode;
}) {
  const error = useSelector(errorSelector);

  return (
    <ErrorHandler error={error} redirectTo={redirectTo}>
      {children}
    </ErrorHandler>
  );
}
