import React from 'react';
import { useIntl } from 'react-intl';
import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import BackButton from 'src/domains/root/commons/buttons/Back';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { AppDispatch } from 'src/domains/root/store';
import { saveWorkspace, resetError } from './slice';
import { workspaceSelector, loadingSelector, errorSelector } from './slice';
import WorkspaceField from './form/WorkspaceField';

interface FormValues {
  name: string;
}

const Form: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const workspace = useSelector(workspaceSelector);
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const intl = useIntl();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .label(
        intl.formatMessage({ id: 'pages.Setup.Workspace.form.name.label' }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .max(200, intl.formatMessage({ id: 'validation.string.max' })),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: workspace?.name ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { name } = values;
      dispatch(saveWorkspace({ name }));
      navigate('/setup/gateway');
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleBack = () => {
    const { name } = formik.values;
    dispatch(saveWorkspace({ name }));
    dispatch(resetError());
    navigate('/setup/welcome');
  };

  return (
    <>
      <LoadingOverlay open={loading} />
      {error && (
        <Box mb={2}>
          <Alert severity="error">
            {intl.formatMessage({
              id: `pages.Setup.Workspace.error.${error.code}`,
            })}
          </Alert>
        </Box>
      )}
      <FormikProvider value={formik}>
        <FormikForm>
          <ValidationBox>
            <WorkspaceField />
          </ValidationBox>
          <Box display="flex" my={4} justifyContent="space-between">
            <Box flex={1} mr={3}>
              <BackButton
                sx={{
                  height: 44,
                  fontSize: (theme) => theme.typography.pxToRem(18),
                }}
                onClick={handleBack}
              />
            </Box>
            <Box flex={2}>
              <SubmitButton
                data-testid="NextButton"
                type="submit"
                fullWidth
                sx={{
                  height: 44,
                  fontSize: (theme) => theme.typography.pxToRem(18),
                }}
              >
                {intl.formatMessage({
                  id: 'pages.Setup.Workspace.form.submit',
                })}
              </SubmitButton>
            </Box>
          </Box>
        </FormikForm>
      </FormikProvider>
    </>
  );
};

export default Form;
