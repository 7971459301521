import React from 'react';

const SensorUnitLabelIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="28"
      height="12"
      viewBox="0 0 28 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="28" height="12" rx="1" fill="#E2DCD2" />
      <path
        d="M7.98673 8.02429L7.20001 8.92038C8.04279 9.64013 9.05015 10 10.2221 10C12.0343 9.97841 12.9602 9.21548 13 7.7112C13 7.1534 12.8246 6.66397 12.4737 6.24292C12.1193 5.81467 11.5713 5.55016 10.8298 5.44939C10.4536 5.40261 10.157 5.36122 9.93996 5.32524C9.54931 5.25326 9.26537 5.12371 9.08813 4.93657C8.91089 4.75304 8.82228 4.54791 8.82228 4.32119C8.82951 3.94332 8.96153 3.65902 9.21835 3.46829C9.46431 3.28475 9.77176 3.19298 10.1407 3.19298C10.8533 3.20738 11.5062 3.40891 12.0994 3.79757L12.7667 2.81511C12.0397 2.2933 11.1842 2.02159 10.2004 2C9.38292 2.0072 8.75174 2.21952 8.30684 2.63698C7.84747 3.05803 7.61779 3.61224 7.61779 4.2996C7.61779 4.8718 7.80045 5.35763 8.16577 5.75709C8.52025 6.14575 9.03749 6.39586 9.71751 6.50742C10.0973 6.5614 10.4463 6.60999 10.7647 6.65317C11.4591 6.77193 11.8028 7.12461 11.7955 7.7112C11.7811 8.42735 11.2602 8.79262 10.2329 8.80702C9.36484 8.79982 8.6161 8.53891 7.98673 8.02429Z"
        fill="black"
      />
      <path
        d="M15.3 7.26823C15.3071 8.09594 15.5761 8.75271 16.107 9.23854C16.6273 9.73877 17.275 9.99248 18.05 9.99968C18.8393 9.99248 19.4923 9.73877 20.009 9.23854C20.5222 8.75271 20.7859 8.09594 20.8 7.26823V2.06445H19.6268V7.13868C19.6197 7.67489 19.4711 8.08335 19.1808 8.36405C18.8835 8.65915 18.5066 8.80669 18.05 8.80669C17.5935 8.80669 17.2201 8.65915 16.9299 8.36405C16.6326 8.08335 16.4804 7.67489 16.4733 7.13868V2.06445H15.3V7.26823Z"
        fill="black"
      />
    </svg>
  );
};

export default SensorUnitLabelIcon;
