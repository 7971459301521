import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import pMemoize from 'p-memoize';
import axios, { isAxiosError } from 'src/utils/api';
import { RootState } from '../../store';
import {
  getInvitationError,
  InvitationError,
  InvitationErrorCode,
} from '../../utils/invitations/api';

const memoizedPut = pMemoize(axios.put);

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface Invitation {
  invitationId: string;
  email: string;
  isExistUser: boolean;
}
interface GetInvitationParams {
  invitationId: string;
}

interface GetInvitationResponse {
  email: string;
  isExistUser: boolean;
}

export const getInvitation = createAsyncThunk<
  Invitation,
  GetInvitationParams,
  { state: RootState; rejectValue: InvitationError<InvitationErrorCode> }
>('invitations/getInvitation', async (params, thunkAPI) => {
  try {
    const { invitationId } = params;
    if (!invitationId) {
      return thunkAPI.rejectWithValue({
        code: 'invitation_required',
        recoverable: false,
      });
    }

    const response = await axios.get<GetInvitationResponse>(
      `${API_ENDPOINT}/invitations/${invitationId}`,
    );

    const { email, isExistUser } = response.data;
    return { invitationId, email, isExistUser };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const invitationError = getInvitationError(error);
    return thunkAPI.rejectWithValue(invitationError);
  }
});

interface PutInvitationParams {
  token: Promise<string>;
  invitationId: string;
}

export const putInvitation = createAsyncThunk<
  void,
  PutInvitationParams,
  { state: RootState; rejectValue: InvitationError<InvitationErrorCode> }
>('invitations/putInvitation', async (params, thunkAPI) => {
  try {
    const { invitationId } = params;
    if (!invitationId) {
      return thunkAPI.rejectWithValue({
        code: 'invitation_required',
        recoverable: false,
      });
    }

    const token = await params.token;
    const body = null;

    /**
     * このPUTリクエストをmemoizeする。（1度しか呼び出さない）
     * このメソッドは useEffect の中で使われることが想定される。
     * useEffect dependencyListを制御しても、unmount -> remount のケースは制御することができない。
     * （実際にこれが発生している @see https://www.pivotaltracker.com/story/show/181862501 ）
     * memoizeすることで、rerender制御に依らず、1度しか呼び出されないことを保証する。
     * このAPIの特性として、1回のWebアプリ起動で複数回呼び出されることはない。
     */
    await memoizedPut<void>(
      `${API_ENDPOINT}/invitations/${invitationId}`,
      body,
      { headers: { Authorization: `Bearer ${token}` } },
    );

    return;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const invitationError = getInvitationError(error);
    return thunkAPI.rejectWithValue(invitationError);
  }
});

export interface InvitationsState {
  invitation: Invitation | null;
  error: InvitationError<InvitationErrorCode> | null;
  loading: boolean;
}

const initialState: InvitationsState = {
  invitation: null,
  error: null,
  loading: false,
};

export const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvitation.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getInvitation.fulfilled, (state, action) => {
        state.invitation = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(getInvitation.rejected, (state, action) => {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.error = action.payload!;
        state.loading = false;
      })
      .addCase(putInvitation.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(putInvitation.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(putInvitation.rejected, (state, action) => {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.error = action.payload!;
        state.loading = false;
      });
  },
});

export const invitationSelector = createSelector(
  (state: RootState) => state.invitations,
  (state: InvitationsState) => state.invitation,
);

export const errorSelector = createSelector(
  (state: RootState) => state.invitations,
  (state: InvitationsState) => state.error,
);

export const loadingSelector = createSelector(
  (state: RootState) => state.invitations,
  (state: InvitationsState) => state.loading,
);

export default invitationsSlice.reducer;
