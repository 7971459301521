import axios from 'src/utils/api';
import * as API from 'src/apis';
import { API_ENDPOINT } from 'src/constants';
import { handleError } from './error';

export { type Error, isApiError } from './error';

export async function getUsersMe({
  token,
}: {
  token: string;
}): Promise<API.Me> {
  return axios
    .get(`${API_ENDPOINT}/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
