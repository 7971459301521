import { createSvgIcon } from '@mui/material';
import React from 'react';

const SelectBoxArrowIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.93934 8.93934C6.52513 8.35355 7.47487 8.35355 8.06066 8.93934L12 12.8787L15.9393 8.93934C16.5251 8.35355 17.4749 8.35355 18.0607 8.93934C18.6464 9.52513 18.6464 10.4749 18.0607 11.0607L13.0607 16.0607C12.4749 16.6464 11.5251 16.6464 10.9393 16.0607L5.93934 11.0607C5.35355 10.4749 5.35355 9.52513 5.93934 8.93934Z"
    fill="#F05064"
  />,
  'SelectBoxArrowIcon',
);

export default SelectBoxArrowIcon;
