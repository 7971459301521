import React from 'react';
import { useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DocumentIcon from './icons/DocumentIcon';

const PrivacyPolicyMenuItem: React.FC = () => {
  const intl = useIntl();

  return (
    <MenuItem
      sx={{ height: 44 }}
      onClick={() => window.open('https://www.nito.co.jp/privacy/', '_blank')}
    >
      <ListItemIcon>
        <DocumentIcon color="primary" />
      </ListItemIcon>
      <ListItemText>
        {intl.formatMessage({
          id: `globalHeader.menu.privacyPolicy.title`,
        })}
      </ListItemText>
    </MenuItem>
  );
};

export default PrivacyPolicyMenuItem;
