import React from 'react';
import UnrecoverableError from './UnrecoverableError';

const JoinedWorkspaceError: React.FC = () => {
  return (
    <UnrecoverableError
      messageId="pages.Setup.Gateway.new.error.joined_workspace"
      buttonTextId="common.buttons.top"
    />
  );
};

export default JoinedWorkspaceError;
