import { useAuth0 } from '@auth0/auth0-react';
import { useInfiniteQuery, type InfiniteData } from '@tanstack/react-query';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import * as API from 'src/apis';
import { awsRum } from 'src/utils/rum';

type ErrorCode =
  | 'not_found'
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

type Result =
  | { status: 'hasError'; errorCode: ErrorCode }
  | { status: 'loading' }
  | {
      status: 'succeeded';
      fetchNextPage: () => Promise<void>;
      hasNextPage: boolean | undefined;
      isFetchingNextPage: boolean;
      data: API.GetGatewaysMemosResponse;
    };

export function useGetGatewaysMemos(gatewayId: string): Result {
  const { getAccessTokenSilently } = useAuth0();

  const result = useInfiniteQuery<
    API.GetGatewaysMemosResponse,
    API.Error,
    InfiniteData<API.GetGatewaysMemosResponse, unknown>,
    string[],
    string | undefined
  >({
    queryKey: Keys.gateway.memos(gatewayId),
    queryFn: async ({ pageParam }) => {
      const token = await getAccessTokenSilently();

      return API.getGatewaysMemos({ gatewayId, offset: pageParam, token });
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastEvaluatedKey) {
        return undefined;
      }

      return lastPage.lastEvaluatedKey;
    },
    refetchOnWindowFocus: false,
  });

  if (result.isLoading) {
    return { status: 'loading' };
  }

  if (result.isError) {
    const error = result.error as API.Error;
    switch (error.code) {
      case 'not_found':
      case 'permission_denied':
      case 'emergency_maintenance':
        return { status: 'hasError', errorCode: error.code };
      case 'bad_request':
      case 'unknown_error':
      default:
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        return { status: 'hasError', errorCode: 'unknown_error' };
    }
  }

  if (result.data === undefined) {
    return { status: 'hasError', errorCode: 'not_found' };
  }

  return {
    status: 'succeeded',
    data: {
      gatewayName: result.data.pages[0].gatewayName,
      memos: result.data.pages.flatMap((page) => page.memos),
    },
    fetchNextPage: async () => {
      await result.fetchNextPage();
    },
    hasNextPage: result.hasNextPage,
    isFetchingNextPage: result.isFetchingNextPage,
  };
}
