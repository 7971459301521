import React from 'react';
import { ButtonProps } from '@mui/material';
import { useIntl } from 'react-intl';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';

const CancelButton: React.FC<ButtonProps> = (props) => {
  const intl = useIntl();

  return (
    <NegativeButton {...props}>
      {intl.formatMessage({
        id: 'common.cancel',
      })}
    </NegativeButton>
  );
};

export default CancelButton;
