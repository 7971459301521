import React from 'react';
import { useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DocumentIcon from './icons/DocumentIcon';

const TermsOfServiceMenuItem: React.FC = () => {
  const intl = useIntl();

  return (
    <MenuItem
      sx={{ height: 44 }}
      onClick={() =>
        window.open(
          import.meta.env.MODE === 'demo'
            ? /**
               * demo環境は本番環境の利用契約ページに飛ばす
               */
              'https://cabiot.jp/ja/terms-of-service.html'
            : '/ja/terms-of-service.html',
          '_blank',
        )
      }
    >
      <ListItemIcon>
        <DocumentIcon color="primary" />
      </ListItemIcon>
      <ListItemText>
        {intl.formatMessage({
          id: `globalHeader.menu.termsOfService.title`,
        })}
      </ListItemText>
    </MenuItem>
  );
};

export default TermsOfServiceMenuItem;
