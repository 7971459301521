import React from 'react';
import { useSelector } from 'react-redux';
import New from '../features/registrations/New';
import Created from '../features/registrations/Created';
import { newResultSelector } from '../features/registrations/slice';

const Signup: React.FC = () => {
  const { data, status } = useSelector(newResultSelector);

  return (
    <>
      {status == 'succeeded' && data ? <Created email={data.email} /> : <New />}
    </>
  );
};

export default Signup;
