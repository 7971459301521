import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { CircularProgress, Container } from '@mui/material';

import { ServiceProviderRole } from 'src/apis';

import InternalSupport from './pages/InternalSupport';
import useGetUsersMe from './features/user/useGetUsersMe';

export const ALLOWED_ROLES: ServiceProviderRole = 'support';

export default function _Routes() {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ServiceProviderAccessControl
            permit={(serviceProviderRoles) =>
              serviceProviderRoles?.includes(ALLOWED_ROLES) ?? false
            }
          >
            {(permitted) => (permitted ? <Outlet /> : <Navigate to="/" />)}
          </ServiceProviderAccessControl>
        }
      >
        <Route index element={<InternalSupport />} />
      </Route>
    </Routes>
  );
}

export function ServiceProviderAccessControl({
  permit,
  children,
}: {
  permit: (serviceProviderRoles?: ServiceProviderRole[]) => boolean;
  children: (permiited: boolean) => React.ReactNode;
}) {
  const me = useGetUsersMe();

  if (me.status === 'loading') {
    return (
      <Container
        sx={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }
  const permitted =
    me.status === 'succeeded' && permit(me.data.serviceProviderRoles);

  return <>{children(permitted)}</>;
}
