import React, { useEffect } from 'react';
import { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import theme from 'src/domains/root/styles/theme';
import DialogCloseIcon from 'src/domains/root/commons/dialog/title/CloseIcon';
import { useLocation } from 'react-router-dom';
import { awsRum } from 'src/utils/rum';

const dialogWidth = 572;

const dialogStyle = css`
  & .MuiDialog-container .MuiDialog-paper {
    width: ${dialogWidth}px;
    ${theme.breakpoints.down('sm')} {
      width: ${dialogWidth / 2}px;
    }
  }
`;

const dialogTitleStyle = css`
  & h2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: space-between;
    line-height: 24px;
  }

  background-color: white;
  color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2.5)}
    ${(props) => props.theme.spacing(3)};
`;

const dialogTitleTypographyStyle = css`
  font-size: ${(props) => props.theme.typography.pxToRem(18)};
  font-weight: bold;
`;

const dialogCloseIconButtonStyle = css`
  left: 12px;
`;

const dialogCloseIconStyle = css`
  color: ${(props) => props.theme.palette.primary.main};
`;

const dialogContentStyle = css`
  align-items: center;
  display: flex;
  overflow: hidden;
  padding-bottom: ${theme.spacing(4)};
  padding-top: ${theme.spacing(4)};
`;

const dialogContentTypographyStyle = css`
  font-size: ${theme.typography.pxToRem(15)};
  line-height: 24px;
`;

const dividerStyle = css`
  background-color: #828282;
  opacity: 0.5;
`;

const dialogActionsStyle = css`
  padding-bottom: ${theme.spacing(2.5)};
  padding-right: ${theme.spacing(2.5)};
  padding-top: ${theme.spacing(2.5)};

  ${theme.breakpoints.down('sm')} {
    display: flex;
    justify-content: center;
    padding-right: 0;
  }
`;

const actionButtonStyle = css`
  border-radius: 4px;
  height: 34px;
  width: 100px;
`;

const noButtonStyle = css`
  &:hover {
    background: #5a5a5a;
    color: white;
  }

  background: #e6e6e6;
  border: 2px solid #5a5a5a;
  color: #5a5a5a;
  font-weight: bold;
  ${actionButtonStyle}
`;

const yesButtonStyle = css`
  &:hover {
    background: ${theme.palette.nito.main};
  }

  background: ${theme.palette.primary.main};
  color: white;
  font-weight: bold;
  ${actionButtonStyle}
`;

interface LogoutModalProps {
  logoutOpen: boolean;
  handleClose: () => void;
  logoutAndClose: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = (props) => {
  const { logoutOpen, handleClose, logoutAndClose } = props;
  const intl = useIntl();

  const location = useLocation();
  useEffect(() => {
    if (logoutOpen) {
      awsRum().then((rum) => {
        rum.recordPageView(location.pathname + '#LogoutModalRender');
      });
    }
  }, [location, logoutOpen]);

  return (
    <Dialog
      open={logoutOpen}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      css={dialogStyle}
    >
      <DialogTitle id="logout-dialog-title" css={dialogTitleStyle}>
        <Box
          display="flex"
          width="100%"
          height={18}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography css={dialogTitleTypographyStyle}>
            {intl.formatMessage({
              id: 'logout.modalTitle',
            })}
          </Typography>
          <IconButton
            onClick={handleClose}
            css={dialogCloseIconButtonStyle}
            size="large"
          >
            <DialogCloseIcon css={dialogCloseIconStyle} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider css={dividerStyle} />
      <DialogContent dividers css={dialogContentStyle}>
        <Typography variant="body2" css={dialogContentTypographyStyle}>
          {intl.formatMessage({
            id: 'logout.content',
          })}
        </Typography>
      </DialogContent>
      <Divider css={dividerStyle} />
      <DialogActions css={dialogActionsStyle}>
        <Button onClick={handleClose} css={noButtonStyle}>
          {intl.formatMessage({
            id: 'common.buttons.no',
          })}
        </Button>
        <Button onClick={logoutAndClose} css={yesButtonStyle}>
          {intl.formatMessage({
            id: 'common.buttons.yes',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
