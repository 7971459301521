import axios from 'src/utils/api';
import * as API from 'src/apis';
import { API_ENDPOINT } from 'src/constants';
import { handleError } from './error';

export { type Error, isApiError } from './error';

export async function putUsersMeNotificationSettings({
  notificationSettings,
  token,
}: {
  notificationSettings: API.NotificationSettings;
  token: string;
}): Promise<void> {
  return axios
    .put(
      `${API_ENDPOINT}/users/me/notification-settings`,
      { notificationSettings },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
