import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import { handleError } from './error';
import { Memo } from './types';

type Props = {
  gatewayId: string;
  offset?: string;
  token: string;
};
export type GetGatewaysMemosResponse = {
  gatewayName: string;
  memos: Memo[];
  lastEvaluatedKey?: string;
};
export async function getGatewaysMemos({
  gatewayId,
  offset,
  token,
}: Props): Promise<GetGatewaysMemosResponse> {
  return axios
    .get(`${API_ENDPOINT}/gateways/${gatewayId}/memos`, {
      params: { offset },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
