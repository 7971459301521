import React from 'react';
import { useFormikContext } from 'formik';

export interface Values {
  sensorUnits: SensorUnit[];
}

interface SensorUnit {
  deviceId: string;
  number: number;
  name: string;
}

type SensorNameFieldsProps = {
  children: (index: number) => React.ReactNode;
};

const SensorUnitNameFields: React.FC<SensorNameFieldsProps> = ({
  children,
}) => {
  const { values } = useFormikContext<Values>();

  return <>{values.sensorUnits.map((_, index) => children(index))}</>;
};

export default SensorUnitNameFields;
