import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../commons/buttons/Primary';
import Note from '../features/setup/welcome/Note';
import MainContents from '../commons/layout/MainContents';

const SetupWelcome: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <MainContents
      containerMaxWidth="sm"
      backgroundColor="white"
      showTopButton={false}
    >
      <Box pt={4}>
        <Typography
          fontWeight={700}
          fontSize={(theme) => theme.typography.pxToRem(40)}
          lineHeight={1.2}
          align="center"
        >
          {intl.formatMessage({
            id: 'pages.Setup.Welcome.title',
          })}
        </Typography>
        <Box mt={7}>
          <Typography
            fontWeight={700}
            fontSize={(theme) => theme.typography.pxToRem(24)}
            lineHeight={1.75}
            align="center"
          >
            {intl.formatMessage({
              id: 'pages.Setup.Welcome.subTitle',
            })}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography
            fontSize={(theme) => theme.typography.pxToRem(15)}
            lineHeight={1.43}
            align="center"
          >
            {intl.formatMessage({
              id: 'pages.Setup.Welcome.description',
            })}
          </Typography>
        </Box>
        <Box mt={4}>
          <PrimaryButton
            data-testid="PrimaryButton"
            fullWidth
            sx={{
              height: 44,
              fontSize: (theme) => theme.typography.pxToRem(18),
              lineHeight: 1.43,
            }}
            onClick={() => navigate('/setup/workspace')}
          >
            {intl.formatMessage({
              id: 'pages.Setup.Welcome.button.new',
            })}
          </PrimaryButton>
        </Box>
        <Box mt={10} mb={3}>
          <Note />
        </Box>
      </Box>
    </MainContents>
  );
};

export default SetupWelcome;
