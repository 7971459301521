import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { Result, RootState } from 'src/domains/root/store';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { activeWorkspaceSelector } from '../../users/slice';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

interface PostParams {
  token: Promise<string>;
}

interface StripeCustomerPortalSession {
  url: string;
}

export type WorkspacesStripeCustomerPortalSessionsErrorCode = PostErrorCode;

type PostErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'workspace_not_found'
  | 'emergency_maintenance'
  | 'on_demo_site'
  | 'unknown_error';

export const postWorkspacesStripeCustomerPortalSessions = createAsyncThunk<
  StripeCustomerPortalSession,
  PostParams,
  { state: RootState; rejectValue: RootError<PostErrorCode> }
>(
  'workspaces/gateways/postWorkspacesStripeCustomerPortalSessions',
  async (params, thunkAPI) => {
    const token = await params.token;

    const workspace = activeWorkspaceSelector(thunkAPI.getState());
    if (!workspace) {
      throw new Error('No workspaceId is activated');
    }

    const body = null;

    try {
      const response = await axios.post<StripeCustomerPortalSession>(
        `${API_ENDPOINT}/workspaces/${workspace.workspaceId}/stripe-customer-portal-sessions`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }
      return thunkAPI.rejectWithValue(
        getPostWorkspacesStripeCustomerPortalSessionError(error),
      );
    }
  },
);

const getPostWorkspacesStripeCustomerPortalSessionError = (
  error: AxiosError<RootErrorResponse>,
): RootError<PostErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const errorCode = data?.errorCode;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'workspace_not_found',
          noticeable: false,
          recoverable: false,
        };
      case 418: // デモサイト用のエラー
        return {
          code: 'on_demo_site',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface WorkspacesStripeCustomerPortalSessionsState {
  createResult: Result<StripeCustomerPortalSession, RootError<PostErrorCode>>;
}

const initialState: WorkspacesStripeCustomerPortalSessionsState = {
  createResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const workspacesStripeCustomerPortalSessionSlice = createSlice({
  name: 'workspaces/gateways',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postWorkspacesStripeCustomerPortalSessions.pending, (state) => {
        state.createResult.data = null;
        state.createResult.status = 'loading';
        state.createResult.error = null;
      })
      .addCase(
        postWorkspacesStripeCustomerPortalSessions.fulfilled,
        (state, action) => {
          state.createResult.data = action.payload;
          state.createResult.status = 'succeeded';
          state.createResult.error = null;
        },
      )
      .addCase(
        postWorkspacesStripeCustomerPortalSessions.rejected,
        (state, action) => {
          state.createResult.data = null;
          state.createResult.status = 'failed';
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          state.createResult.error = action.payload!;
        },
      );
  },
});

export const createResultSelector = createSelector(
  (state: RootState) => state.workspacesStripeCustomerPortalSession,
  (state: WorkspacesStripeCustomerPortalSessionsState) => state.createResult,
);

export default workspacesStripeCustomerPortalSessionSlice.reducer;
