import Highcharts from 'highcharts';
import { IntlShape } from 'react-intl';

export const getGatewaySensorDataChartYAxisOptions = (
  intl: IntlShape,
  dataType: string,
  isSmallScreen: boolean,
): Highcharts.Options => {
  const celsiusSignLabel: Highcharts.YAxisLabelsOptions | undefined = {
    format: isSmallScreen ? '{value}℃' : undefined,
  };

  const percentSignLabel: Highcharts.YAxisLabelsOptions | undefined = {
    format: isSmallScreen ? '{value}%' : undefined,
  };

  if (dataType === 'temperature') {
    return {
      yAxis: [
        {
          title: isSmallScreen
            ? undefined
            : {
                text: intl.formatMessage({
                  id: 'highchartsOptions.chartOptions.yAxis.temperature',
                }),
              },
          lineWidth: 1,
          opposite: false,
          min: null,
          max: null,
          height: '100%',
          labels: celsiusSignLabel,
        },
        {
          lineWidth: 1,
          opposite: true,
          height: '100%',
        },
      ],
    };
  }

  if (dataType === 'humidity') {
    return {
      yAxis: [
        {
          title: isSmallScreen
            ? undefined
            : {
                text: intl.formatMessage({
                  id: 'highchartsOptions.chartOptions.yAxis.humidity',
                }),
              },
          lineWidth: 1,
          opposite: false,
          min: 0,
          max: 100,
          height: '100%',
          labels: percentSignLabel,
          showLastLabel: true,
        },
        {
          lineWidth: 1,
          opposite: true,
          height: '100%',
        },
      ],
    };
  }

  // 赤外線温度
  return {
    yAxis: [
      {
        title: {
          text: isSmallScreen
            ? undefined
            : intl.formatMessage({
                id: 'highchartsOptions.chartOptions.yAxis.objectTemperature',
              }),
        },
        lineWidth: 1,
        opposite: false,
        min: null,
        max: null,
        height: '100%',
        labels: celsiusSignLabel,
      },
      {
        lineWidth: 1,
        opposite: true,
        height: '100%',
      },
    ],
  };
};

/*
 * 用途(mode)に応じたY軸の設定を返す
 */
export const getYAxisOptions = (
  intl: IntlShape,
  mode: string,
  isSmallScreen: boolean,
): Highcharts.Options => {
  const leftLabel: Highcharts.YAxisLabelsOptions | undefined = {
    format: isSmallScreen ? '{value}℃' : undefined,
  };
  const rightLabel: Highcharts.YAxisLabelsOptions | undefined = {
    format: isSmallScreen ? '{value}%' : undefined,
  };

  // 温湿度監視
  if (mode === 'temperatureHumidityObserve') {
    return {
      yAxis: [
        {
          title: {
            text: isSmallScreen
              ? undefined
              : intl.formatMessage({
                  id: 'highchartsOptions.chartOptions.yAxis.temperature',
                }),
          },
          lineWidth: 1,
          opposite: false,
          height: '100%',
          labels: leftLabel,
        },
        {
          title: {
            text: isSmallScreen
              ? undefined
              : intl.formatMessage({
                  id: 'highchartsOptions.chartOptions.yAxis.humidity',
                }),
          },
          lineWidth: 1,
          opposite: true,
          min: 0,
          max: 100,
          height: '100%',
          labels: rightLabel,
        },
      ],
    };
  }

  // 表面温度監視
  if (mode === 'connectorTemperatureObserve') {
    return {
      yAxis: [
        {
          title: {
            text: isSmallScreen
              ? undefined
              : intl.formatMessage({
                  id: 'highchartsOptions.chartOptions.yAxis.temperatureAndObjectTemperature',
                }),
          },
          lineWidth: 1,
          opposite: false,
          height: '60%',
          labels: leftLabel,
        },
        {
          title: {
            text: isSmallScreen
              ? undefined
              : intl.formatMessage({
                  id: 'highchartsOptions.chartOptions.yAxis.humidity',
                }),
          },
          lineWidth: 1,
          opposite: true,
          min: 0,
          max: 100,
          height: '60%',
          labels: rightLabel,
        },
        {
          title: {
            text: isSmallScreen
              ? undefined
              : intl.formatMessage({
                  id: 'common.objectTemperatureAlertCount',
                }),
          },
          allowDecimals: false,
          lineWidth: 1,
          opposite: false,
          top: '70%',
          height: '30%',
          offset: 0,
          min: 0,
          zoomEnabled: false,
        },
        {
          lineWidth: 1,
          opposite: true,
          top: '70%',
          height: '30%',
          offset: 0,
        },
      ],
    };
  }

  // 結露監視
  return {
    yAxis: [
      {
        title: {
          text: isSmallScreen
            ? undefined
            : intl.formatMessage({
                id: 'highchartsOptions.chartOptions.yAxis.temperatureAndObjectTemperature',
              }),
        },
        lineWidth: 1,
        opposite: false,
        height: '60%',
        labels: leftLabel,
      },
      {
        title: {
          text: isSmallScreen
            ? undefined
            : intl.formatMessage({
                id: 'highchartsOptions.chartOptions.yAxis.humidity',
              }),
        },
        lineWidth: 1,
        opposite: true,
        min: 0,
        max: 100,
        height: '60%',
        labels: rightLabel,
      },
      {
        title: {
          text: isSmallScreen
            ? undefined
            : intl.formatMessage({
                id: 'highchartsOptions.chartOptions.yAxis.condensationAlertTemperature',
              }),
        },
        lineWidth: 1,
        opposite: false,
        top: '70%',
        height: '30%',
        offset: 0,
        plotLines: [{ value: 0, color: 'red', zIndex: 1, dashStyle: 'Dash' }],
        labels: leftLabel,
      },
      {
        lineWidth: 1,
        opposite: true,
        top: '70%',
        height: '30%',
        offset: 0,
      },
    ],
  };
};
