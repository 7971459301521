import React from 'react';
import Box from '@mui/material/Box';
import { css } from 'styled-components/macro';

const boxStyle = css`
  background: #e2dcd2;
  border-radius: 4px;
  font-size: ${(props) => props.theme.typography.pxToRem(12)};
`;

export default function Description(props: { children: React.ReactNode }) {
  const { children } = props;

  return (
    <Box px={2} py={1.5} css={boxStyle}>
      {children}
    </Box>
  );
}
