import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  passwordConfirmation: string;
}

const PasswordConfirmationField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, passwordConfirmation: true });
  };

  return (
    <TextField
      data-testid="PasswordConfirmationTextInput"
      name="passwordConfirmation"
      type="password"
      value={values.passwordConfirmation}
      label={intl.formatMessage({
        id: 'registrations.users.new.form.passwordConfirmation',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={
        touched.passwordConfirmation && Boolean(errors.passwordConfirmation)
      }
      helperText={touched.passwordConfirmation && errors.passwordConfirmation}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default PasswordConfirmationField;
