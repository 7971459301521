import { AxiosError } from 'axios';

export type InvitationConflictErrorCode =
  | 'ALREADY_USED'
  | 'ALREADY_REGISTERED'
  | 'EMERGENCY_MAINTENANCE';

export interface InvitationErrorResponse {
  errorCode?: InvitationConflictErrorCode;
}

type InvitationIdErrorCode =
  | 'invalid_invitation'
  | 'used_invitation'
  | 'expired_invitation';

type InvitedUserErrorCode = 'duplicate_user';

export type InvitationErrorCode =
  | 'invitation_required'
  | InvitationIdErrorCode
  | InvitedUserErrorCode
  | 'emergency_maintenance'
  | 'unknown_error';

export interface InvitationError<T> {
  code: T;
  recoverable: boolean;
}

export const getInvitationError = (
  error: AxiosError<InvitationErrorResponse>,
): InvitationError<InvitationErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    return getInvitationResponseError(status, data.errorCode);
  }
  if (error.request) {
    return { code: 'unknown_error', recoverable: true };
  }
  return { code: 'unknown_error', recoverable: true };
};

export const getInvitationResponseError = (
  status: number,
  errorCode?: InvitationConflictErrorCode,
): InvitationError<InvitationErrorCode> => {
  switch (status) {
    case 404:
      return { code: 'invalid_invitation', recoverable: false };
    case 409:
      if (!errorCode) {
        return { code: 'used_invitation', recoverable: false };
      }
      switch (errorCode) {
        case 'ALREADY_REGISTERED':
          return { code: 'duplicate_user', recoverable: false };
        case 'ALREADY_USED':
        default:
          return { code: 'used_invitation', recoverable: false };
      }
    case 410:
      return { code: 'expired_invitation', recoverable: false };
    case 503:
      switch (errorCode) {
        case 'EMERGENCY_MAINTENANCE':
          return { code: 'emergency_maintenance', recoverable: false };
        default:
          return { code: 'unknown_error', recoverable: true };
      }
    default:
      return { code: 'unknown_error', recoverable: true };
  }
};
