import { createTheme } from '@mui/material/styles';

// @see: https://mui.com/customization/default-theme/

const theme = createTheme({
  palette: {
    primary: { main: '#F05064', light: 'rgba(240, 80, 100, 0.3)' },
    secondary: { main: '#E2DCD2' },
    nito: { main: '#E10A1E' },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    text: {
      primary: '#141414',
      disabled: '#D2D2D2',
    },
  },
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Noto Sans JP"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      lineHeight: 1.2, // default
      letterSpacing: '-0.00833em', // default
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.167, // default
      letterSpacing: '0em', // default
    },
    h4: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
      lineHeight: 1.33,
      letterSpacing: '0.00735em', // default
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      letterSpacing: '0.00938em', // default
    },
    subtitle2: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      lineHeight: 1.57, // default
      letterSpacing: '0.00714em', // default
    },
    body2: {
      fontSize: '0.9375rem', // 15px
      fontWeight: 400,
      lineHeight: 1.43, // default
      letterSpacing: '0.01071em', // default
    },
  },
});

export default theme;
