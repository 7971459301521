import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import Title from 'src/domains/root/commons/dialog/Title';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import { Typography } from '@mui/material';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import Agreement from './Agreement';

interface DialogProps {
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmDialog: React.FC<DialogProps> = (props) => {
  const { onClose: _handleClose, onSubmit: _handleSubmit } = props;

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    agreement: yup
      .boolean()
      .label(
        intl.formatMessage({
          id: 'pages.DeleteAccount.dialogs.confirm.description.agreeToDelete',
        }),
      )
      .isTrue(),
  });

  const formik = useFormik({
    initialValues: {
      agreement: false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      _handleSubmit();
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleClose = () => {
    _handleClose();

    formik.setValues({
      agreement: false,
    });
  };

  const location = useLocation();
  useEffect(() => {
    awsRum().then((rum) => {
      rum.recordPageView(
        location.pathname + '#DeleteAccountConfirmDialogRender',
      );
    });
  }, [location]);

  return (
    <Dialog
      data-testid="ConfirmDialog"
      open={true}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="account-delete-dialog"
      onClose={handleClose}
    >
      <LoadingOverlay open={formik.isSubmitting} />
      <DialogTitle id="account-delete-dialog-title">
        <Title onClose={handleClose}>
          {intl.formatMessage({
            id: 'pages.DeleteAccount.dialogs.confirm.title',
          })}
        </Title>
      </DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent dividers>
            <Box mt={1} mb={2}>
              <Typography>
                {intl.formatMessage({
                  id: 'pages.DeleteAccount.dialogs.confirm.description.cannotRestore',
                })}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography fontWeight={700}>
                {intl.formatMessage({
                  id: 'pages.DeleteAccount.dialogs.confirm.description.areYouSureToDelete',
                })}
              </Typography>
            </Box>
            <Box>
              <Agreement />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" gap={1.5} mx={1}>
              <NegativeButton
                data-testid="ConfirmDialogCloseButton"
                onClick={handleClose}
              >
                {intl.formatMessage({
                  id: 'common.buttons.no',
                })}
              </NegativeButton>
              <SubmitButton data-testid="ConfirmDialogSubmitButton">
                {intl.formatMessage({
                  id: 'common.buttons.yes',
                })}
              </SubmitButton>
            </Box>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default ConfirmDialog;
