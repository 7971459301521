import styled from 'styled-components';
import BaseButton, { BaseButtonProps } from './Base';

export type SecondaryButtonProps = Omit<BaseButtonProps, 'variant'>;

const SecondaryButton = styled(BaseButton).attrs<SecondaryButtonProps>(() => ({
  variant: 'outlined',
}))<SecondaryButtonProps>`
  background: ${(props) => props.theme.palette.common.white};
  border-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};

  :hover {
    background: ${(props) => props.theme.palette.primary.main};
    border-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.common.white};
  }

  :disabled {
    background: ${(props) => props.theme.palette.common.white};
    border-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default SecondaryButton;
