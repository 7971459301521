import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Status } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type GatewaysAreaIdErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'emergency_maintenance'
  | 'unknown_error';

export interface PutGatewaysAreaIdParams {
  token: Promise<string>;
  gatewayId: string;
  areaId: string | undefined;
}

export const putGatewaysAreaId = createAsyncThunk<
  void,
  PutGatewaysAreaIdParams,
  {
    state: RootState;
    rejectValue: RootError<GatewaysAreaIdErrorCode>;
  }
>('gatewaysAreaId/putGatewaysAreaId', async (params, thunkAPI) => {
  const token = await params.token;
  const { gatewayId, areaId } = params;
  const data = areaId === 'noLocation' ? {} : { areaId };

  try {
    await axios.put<void>(
      `${API_ENDPOINT}/gateways/area-id/${gatewayId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const gatewaysAreaIdError = getGatewaysAreaIdError(error);
    return thunkAPI.rejectWithValue(gatewaysAreaIdError);
  }
});

const getGatewaysAreaIdError = (
  error: AxiosError<RootErrorResponse>,
): RootError<GatewaysAreaIdErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;

    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface GatewaysAreaIdState {
  error: RootError<GatewaysAreaIdErrorCode> | null;
  status: Status;
}

const initialState: GatewaysAreaIdState = {
  error: null,
  status: 'idle',
};

export const gatewaysAreaIdSlice = createSlice({
  name: 'gatewaysAreaId',
  initialState,
  reducers: {
    reset(state) {
      state.error = null;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(putGatewaysAreaId.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putGatewaysAreaId.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(putGatewaysAreaId.rejected, (state, action) => {
        state.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.error = action.payload!;
      });
  },
});

export const errorSelector = createSelector(
  (state: RootState) => state.gatewaysAreaId,
  (state: GatewaysAreaIdState) => state.error,
);

export const statusSelector = createSelector(
  (state: RootState) => state.gatewaysAreaId,
  (state: GatewaysAreaIdState) => state.status,
);

export const { reset } = gatewaysAreaIdSlice.actions;

export default gatewaysAreaIdSlice.reducer;
