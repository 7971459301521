import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export function NotificationsIcon({ sx = [], ...props }: SvgIconProps) {
  return (
    <SvgIcon
      fontSize="medium"
      color="primary"
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      {...props}
    >
      <g>
        <path d="M17.8433 17.9431H4.14981C3.43441 17.9431 1.75598 17.9431 1.2607 16.5236C1.16898 16.2496 1.1316 15.9603 1.15064 15.672C1.17489 15.3871 1.25596 15.11 1.3891 14.8569C1.64193 14.3804 2.03591 13.9934 2.51723 13.7488C2.62729 13.6756 2.74653 13.6023 2.84742 13.5199C3.13444 13.2902 3.37426 13.0072 3.55365 12.6865C3.75227 12.3167 3.89154 11.9181 3.96638 11.5052C4.12412 10.3548 4.20685 9.19546 4.21402 8.03439C4.37911 5.4977 5.21375 3.73941 6.70875 2.77785C7.33281 2.36957 8.01662 2.06063 8.73571 1.86207C8.8235 1.40398 9.0472 0.982811 9.37775 0.653252C9.81934 0.233874 10.4055 0 11.0149 0C11.6243 0 12.2105 0.233874 12.6521 0.653252C12.9859 0.980352 13.2102 1.40262 13.2941 1.86207C14.007 2.04834 14.6873 2.34168 15.3119 2.73206C17.238 3.99583 17.6691 6.30358 17.8158 7.9245C17.8208 9.12855 17.9066 10.3309 18.0726 11.5235C18.154 11.933 18.2962 12.3281 18.4945 12.6957C18.6771 13.0088 18.913 13.2877 19.1916 13.5199L19.4942 13.7214C19.9774 13.9815 20.3777 14.3718 20.6499 14.8478C20.7747 15.1052 20.8435 15.3861 20.8517 15.672C20.8659 15.9564 20.819 16.2405 20.7141 16.5053C20.2555 17.9431 18.6321 17.9431 17.8433 17.9431Z" />
        <path d="M13.5326 19.3168H8.49724C8.47292 19.3168 8.44959 19.3264 8.43239 19.3436C8.41519 19.3608 8.40552 19.3841 8.40552 19.4084C8.40706 20.0953 8.68073 20.7537 9.16678 21.2399C9.65593 21.7219 10.3139 21.9945 11.0011 22C11.6929 21.9952 12.355 21.7187 12.8442 21.2303C13.3334 20.7418 13.6103 20.0808 13.6151 19.39C13.61 19.3711 13.5994 19.3541 13.5848 19.3411C13.5701 19.3281 13.552 19.3196 13.5326 19.3168Z" />
      </g>
    </SvgIcon>
  );
}
