import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import CabiotLogo from '../commons/CabiotLogo';

const DeleteAccountSuccess: React.FC = () => {
  const intl = useIntl();

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={7.5}>
          <CabiotLogo />
        </Box>
        <Box mt={6}>
          <Typography
            variant="h4"
            align="center"
            fontWeight={700}
            fontSize={(theme) => theme.typography.pxToRem(32)}
            lineHeight={(theme) => theme.typography.pxToRem(48)}
          >
            {intl.formatMessage({
              id: 'pages.DeleteAccountSuccess.title',
            })}
          </Typography>
        </Box>
        <Box mt={2.5}>
          <Typography
            align="center"
            fontSize={(theme) => theme.typography.pxToRem(16)}
            lineHeight={(theme) => theme.typography.pxToRem(24)}
          >
            {intl.formatMessage(
              { id: 'pages.DeleteAccountSuccess.description' },
              { br: <br /> },
            )}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default DeleteAccountSuccess;
