import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

const QrCodeWaterMark = () => {
  const intl = useIntl();
  return (
    <Box
      data-testid="QrCodeWaterMark"
      display="flex"
      justifyContent="end"
      paddingTop={2}
      mr={3}
    >
      <Typography fontSize={(props) => props.typography.pxToRem(10)}>
        {intl.formatMessage({
          id: 'common.qrCodeWaterMark',
        })}
      </Typography>
    </Box>
  );
};

export default QrCodeWaterMark;
