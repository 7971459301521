import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Status } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

type AlertType = 'temperature' | 'humidity' | 'objectTemperature';
type ReasonType = 'upper' | 'lower';
type EventType = 'occured' | 'restored';
type ObserveModeType =
  | 'temperatureHumidityObserve'
  | 'condensationObserve'
  | 'connectorTemperatureObserve';

export interface AlertHistory {
  alertId: string;
  gatewayId: string;
  gatewayName: string;
  sensorUnitId: string;
  sensorUnitNumber: number;
  sensorUnitName: string;
  alertType: AlertType;
  alertReason: ReasonType;
  eventType: EventType;
  timestamp: number;
  observe: {
    mode: ObserveModeType;
  };
  areaId?: string;
  displayAreaName?: string;
}

export interface GetAlertHistoriesParams {
  token: Promise<string>;
  workspaceId: string;
}

interface GetAlertHistoriesResponse {
  alertHistories: AlertHistory[];
}

export type AlertHistoriesErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getAlertHistories = createAsyncThunk<
  AlertHistory[],
  GetAlertHistoriesParams,
  { state: RootState; rejectValue: RootError<AlertHistoriesErrorCode> }
>('gateways/getAlertHistories', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const res = await axios.get<GetAlertHistoriesResponse>(
      `${API_ENDPOINT}/alerts/${params.workspaceId}/histories`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res.data.alertHistories;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const gatewaysError = getAlertHistoriesError(error);
    return thunkAPI.rejectWithValue(gatewaysError);
  }
});

const getAlertHistoriesError = (
  error: AxiosError<RootErrorResponse>,
): RootError<AlertHistoriesErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface AlertHistoriesState {
  data: AlertHistory[];
  error: RootError<AlertHistoriesErrorCode> | null;
  status: Status;
}

const initialState: AlertHistoriesState = {
  data: [],
  error: null,
  status: 'idle',
};

export const alertHistoriesSlice = createSlice({
  name: 'alertHistories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAlertHistories.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getAlertHistories.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getAlertHistories.rejected, (state, action) => {
        state.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.error = action.payload!;
      });
  },
});

export const alertHistoriesSelector = createSelector(
  (state: RootState) => state.alertsHistories,
  (state: AlertHistoriesState) => state.data,
);

export const errorSelector = createSelector(
  (state: RootState) => state.alertsHistories,
  (state: AlertHistoriesState) => state.error,
);

export const statusSelector = createSelector(
  (state: RootState) => state.alertsHistories,
  (state: AlertHistoriesState) => state.status,
);

export default alertHistoriesSlice.reducer;
