import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _BatteryLowIcon = createSvgIcon(
  <>
    <g clipPath="url(#clip0_9721_35214)">
      <rect opacity="0.5" y="2" width="12" height="22" rx="1" fill="#D2D2D2" />
      <rect opacity="0.5" x="4" width="4" height="2" fill="#D2D2D2" />
      <path
        d="M0 15H12V23C12 23.5523 11.5523 24 11 24H1C0.447715 24 0 23.5523 0 23V15Z"
        fill="#5AD75F"
      />
    </g>
    <defs>
      <clipPath id="clip0_9721_35214">
        <rect width="12" height="24" fill="white" />
      </clipPath>
    </defs>
  </>,
  'BatteryLow',
);

const BatteryLowIcon = styled(_BatteryLowIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 12 24',
}))`
  fill: none;
  height: 24px;
  width: 12px;
`;

export default BatteryLowIcon;
