import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _BatteryUnknownIcon = createSvgIcon(
  <>
    <path
      d="M34 11H36.5C36.7761 11 37 11.2239 37 11.5V16.5C37 16.7761 36.7761 17 36.5 17H34V11Z"
      fill="#50D25A"
    />
    <path
      d="M34 11H36.5C36.7761 11 37 11.2239 37 11.5V16.5C37 16.7761 36.7761 17 36.5 17H34V11Z"
      fill="#50D25A"
      fillOpacity="0.2"
    />
    <rect y="4" width="34" height="20" rx="2" fill="#50D25A" />
    <rect
      y="4"
      width="34"
      height="20"
      rx="2"
      fill="#50D25A"
      fillOpacity="0.2"
    />
    <rect x="2" y="6" width="30" height="16" rx="2" fill="white" />
    <path
      d="M19.6667 25H15.3333V20.6H19.6667V25ZM22.4833 13.318C22.4833 13.318 21.66 14.242 21.0317 14.88C19.9917 15.936 19.2333 17.41 19.2333 18.4H15.7667C15.7667 16.574 16.7633 15.056 17.7817 14L19.7967 11.932C20.3817 11.338 20.75 10.502 20.75 9.6C20.75 7.774 19.2983 6.3 17.5 6.3C15.7017 6.3 14.25 7.774 14.25 9.6H11C11 5.948 13.9033 3 17.5 3C21.0967 3 24 5.948 24 9.6C24 11.052 23.415 12.372 22.4833 13.318Z"
      fill="white"
      stroke="white"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M19.6667 25H15.3333V20.6H19.6667V25ZM22.4833 13.318C22.4833 13.318 21.66 14.242 21.0317 14.88C19.9917 15.936 19.2333 17.41 19.2333 18.4H15.7667C15.7667 16.574 16.7633 15.056 17.7817 14L19.7967 11.932C20.3817 11.338 20.75 10.502 20.75 9.6C20.75 7.774 19.2983 6.3 17.5 6.3C15.7017 6.3 14.25 7.774 14.25 9.6H11C11 5.948 13.9033 3 17.5 3C21.0967 3 24 5.948 24 9.6C24 11.052 23.415 12.372 22.4833 13.318Z"
      fill="#50D25A"
    />
    <path
      d="M19.6667 25H15.3333V20.6H19.6667V25ZM22.4833 13.318C22.4833 13.318 21.66 14.242 21.0317 14.88C19.9917 15.936 19.2333 17.41 19.2333 18.4H15.7667C15.7667 16.574 16.7633 15.056 17.7817 14L19.7967 11.932C20.3817 11.338 20.75 10.502 20.75 9.6C20.75 7.774 19.2983 6.3 17.5 6.3C15.7017 6.3 14.25 7.774 14.25 9.6H11C11 5.948 13.9033 3 17.5 3C21.0967 3 24 5.948 24 9.6C24 11.052 23.415 12.372 22.4833 13.318Z"
      fill="#50D25A"
      fillOpacity="0.2"
    />
  </>,
  'BatteryUnknownIcon',
);

const BatteryUnknownIcon = styled(_BatteryUnknownIcon).attrs<SvgIconProps>(
  () => ({
    viewBox: '0 0 37 28',
  }),
)`
  fill: none;
  height: 28px;
  width: 37px;
`;

export default BatteryUnknownIcon;
