import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { Status } from 'src/domains/root/store';
import { Result, RootState } from 'src/domains/root/store';
import { RootError, RootErrorResponse } from '../../../utils/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

interface PostWorkspacesGatewayParams {
  token: Promise<string>;
  registrationCode: string;
  imei: string;
  gatewayName: string;
  workspaceId: string;
}

interface PostWorkspaceGatewayResponse {
  /**
   * 契約終了時点のtimestamp(ms)
   * この値が 2024/01/02T00:00:00(+09:00) のtimestampであるとき、
   * 画面には「2024/1/1まで」と表示する。（つまり表示の際に-1日する）
   */
  contractExpiredAt: number;
}

export type WorkspacesGatewaysErrorCode = PostWorkspacesGatewayErrorCode;

export type PostWorkspacesGatewayErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'invalid_registration_code_and_imei_pair'
  | 'already_registered'
  | 'emergency_maintenance'
  | 'unknown_error';

export const postWorkspacesGateway = createAsyncThunk<
  PostWorkspaceGatewayResponse,
  PostWorkspacesGatewayParams,
  { state: RootState; rejectValue: RootError<PostWorkspacesGatewayErrorCode> }
>('workspaces/gateways/postWorkspacesGateway', async (params, thunkAPI) => {
  const token = await params.token;
  const { registrationCode, imei, gatewayName, workspaceId } = params;
  const body = { registrationCode, imei, gatewayName };

  try {
    const response = await axios.post<PostWorkspaceGatewayResponse>(
      `${API_ENDPOINT}/workspaces/${workspaceId}/gateways`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspacesGatewaysError = getPostWorkspacesGatewayError(error);
    return thunkAPI.rejectWithValue(workspacesGatewaysError);
  }
});

const getPostWorkspacesGatewayError = (
  error: AxiosError<RootErrorResponse>,
): RootError<PostWorkspacesGatewayErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'invalid_registration_code_and_imei_pair',
          noticeable: false,
          recoverable: true,
        };
      case 409:
        return {
          code: 'already_registered',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface WorkspacesGatewayState {
  workspacesGatewayParams: Omit<
    PostWorkspacesGatewayParams,
    'token' | 'workspaceId'
  > | null;
  status: Status;
  error: RootError<WorkspacesGatewaysErrorCode> | null;
  loading: boolean;
  createResult: Result<
    PostWorkspaceGatewayResponse,
    RootError<PostWorkspacesGatewayErrorCode>
  >;
}

const initialState: WorkspacesGatewayState = {
  workspacesGatewayParams: null,
  status: 'idle',
  error: null,
  loading: false,
  createResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const workspacesGatewaySlice = createSlice({
  name: 'workspaces/gateways',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postWorkspacesGateway.pending, (state) => {
        state.createResult.data = null;
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postWorkspacesGateway.fulfilled, (state, action) => {
        state.createResult.data = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(postWorkspacesGateway.rejected, (state, action) => {
        state.createResult.data = null;
        state.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.error = action.payload!;
      });
  },
});

export const workspacesGatewayParamsSelector = createSelector(
  (state: RootState) => state.workspacesGateway,
  (state: WorkspacesGatewayState) => state.workspacesGatewayParams,
);

export const statusSelector = createSelector(
  (state: RootState) => state.workspacesGateway,
  (state: WorkspacesGatewayState) => state.status,
);

export const errorSelector = createSelector(
  (state: RootState) => state.workspacesGateway,
  (state: WorkspacesGatewayState) => state.error,
);

export const loadingSelector = createSelector(
  (state: RootState) => state.workspacesGateway,
  (state: WorkspacesGatewayState) => state.loading,
);

export const createResultSelector = createSelector(
  (state: RootState) => state.workspacesGateway,
  (state: WorkspacesGatewayState) => state.createResult,
);

export const { resetStatus, resetError } = workspacesGatewaySlice.actions;

export default workspacesGatewaySlice.reducer;
