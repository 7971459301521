import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import * as API from 'src/apis';

import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';
import { API_ENDPOINT } from 'src/constants';

export type UsersMeErrorCode = 'unknown_error' | 'emergency_maintenance';

/**
 * @deprecated `src/apis` のタイプを使ってください。
 * */
export type Workspace = API.Workspace;

export interface GetUsersMeParams {
  token: Promise<string>;
  silent?: boolean;
}

export const getUsersMe = createAsyncThunk<
  API.Me,
  GetUsersMeParams,
  { state: RootState; rejectValue: RootError<UsersMeErrorCode> }
>('users/me', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const response = await API.getUsersMe({ token });
    return response;
  } catch (error) {
    if (API.isApiError<UsersMeErrorCode>(error)) {
      switch (error.code) {
        case 'emergency_maintenance':
          return thunkAPI.rejectWithValue({
            code: 'emergency_maintenance',
            noticeable: false,
            recoverable: false,
          });
        default:
          return thunkAPI.rejectWithValue({
            code: 'unknown_error',
            noticeable: false,
            recoverable: false,
          });
      }
    }

    return thunkAPI.rejectWithValue({
      code: 'unknown_error',
      noticeable: false,
      recoverable: false,
    });
  }
});

export type ActivateWorkspaceErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export interface ActivateWorkspaceParams {
  token: Promise<string>;
  workspaceId: string;
  silent?: boolean;
}

export const activateWorkspace = createAsyncThunk<
  Pick<API.Workspace, 'workspaceId'>,
  ActivateWorkspaceParams,
  { state: RootState; rejectValue: RootError<ActivateWorkspaceErrorCode> }
>('users/activateWorkspace', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId } = params;
  try {
    // May be desirable to contain workspaceId in request body.
    await axios.put(
      `${API_ENDPOINT}/workspaces/${workspaceId}/latest`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return { workspaceId };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspaceError = getActivateWorkspaceError(error);
    return thunkAPI.rejectWithValue(workspaceError);
  }
});

const getActivateWorkspaceError = (
  error: AxiosError<RootErrorResponse>,
): RootError<ActivateWorkspaceErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

interface UsersState {
  meResult: Result<API.Me, RootError<UsersMeErrorCode>>;
  workspaceActivationResult: Result<
    Pick<API.Workspace, 'workspaceId'>,
    RootError<ActivateWorkspaceErrorCode>
  >;
}

const initialState: UsersState = {
  meResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  workspaceActivationResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateWorkspace(
      state,
      action: PayloadAction<
        Pick<API.Workspace, 'workspaceId' | 'workspaceName'>
      >,
    ) {
      const { data, status } = state.meResult;
      if (status !== 'succeeded') return;
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      const { workspaces } = data!;
      const targetWorkspace = workspaces.find(
        (workspace) => workspace.workspaceId == action.payload.workspaceId,
      );
      if (targetWorkspace) {
        targetWorkspace.workspaceName = action.payload.workspaceName;
      }
    },
    updateNotificationSettings(
      state,
      action: PayloadAction<API.Me['notificationSettings']>,
    ) {
      const { data, status } = state.meResult;
      if (status !== 'succeeded') return;
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      data!.notificationSettings = action.payload;
    },
    updatePaymentInvoiceStatusActivating(state) {
      const { data, status } = state.meResult;
      if (status !== 'succeeded' || !data) {
        return;
      }

      const { latestWorkspaceId, workspaces } = data;

      const activeWorkspace = workspaces.find(
        (workspace) => workspace.workspaceId == latestWorkspaceId,
      );

      if (!activeWorkspace) {
        return;
      }

      const invoice = activeWorkspace.payments.find(
        (payment) => payment.type === 'invoice',
      );

      if (!invoice) {
        return;
      }

      invoice.status = 'activating';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersMe.pending, (state, action) => {
        if (!action.meta.arg.silent) {
          state.meResult.data = null;
          state.meResult.status = 'loading';
          state.meResult.error = null;
        }
      })
      .addCase(getUsersMe.fulfilled, (state, action) => {
        state.meResult.data = action.payload;
        state.meResult.status = 'succeeded';
        state.meResult.error = null;
      })
      .addCase(getUsersMe.rejected, (state, action) => {
        if (!action.meta.arg.silent) {
          state.meResult.data = null;
          state.meResult.status = 'failed';
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          state.meResult.error = action.payload!;
        }
      })
      .addCase(activateWorkspace.pending, (state, action) => {
        if (!action.meta.arg.silent) {
          state.workspaceActivationResult.data = null;
          state.workspaceActivationResult.status = 'loading';
          state.workspaceActivationResult.error = null;
        }
      })
      .addCase(activateWorkspace.fulfilled, (state, action) => {
        state.workspaceActivationResult.data = action.payload;
        state.workspaceActivationResult.status = 'succeeded';
        state.workspaceActivationResult.error = null;

        if (state.meResult.status !== 'succeeded') return;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.meResult.data!.latestWorkspaceId = action.payload.workspaceId;
      })
      .addCase(activateWorkspace.rejected, (state, action) => {
        if (!action.meta.arg.silent) {
          state.workspaceActivationResult.data = null;
          state.workspaceActivationResult.status = 'failed';
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          state.workspaceActivationResult.error = action.payload!;
        }
      });
  },
});

export const meResultSelector = createSelector(
  (state: RootState) => state.users,
  (state: UsersState) => state.meResult,
);

export const workspaceActivationResultSelector = createSelector(
  (state: RootState) => state.users,
  (state: UsersState) => state.workspaceActivationResult,
);

export const activeWorkspaceSelector = createSelector(
  (state: RootState) => state.users,
  (state: UsersState) => {
    const { data, status } = state.meResult;
    if (status !== 'succeeded') return;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    const { latestWorkspaceId, workspaces } = data!;
    const activeWorkspace = workspaces.find(
      (workspace) => workspace.workspaceId == latestWorkspaceId,
    );
    return activeWorkspace;
  },
);

export const activeWorkspacePaymentsSelector = createSelector(
  activeWorkspaceSelector,
  (activeWorkspace: API.Workspace | undefined) => {
    return activeWorkspace?.payments ?? [];
  },
);

export const workspacesSelector = createSelector(
  (state: RootState) => state.users,
  (state: UsersState) => {
    const { data, status } = state.meResult;
    if (status !== 'succeeded') return [];
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return data!.workspaces;
  },
);

export const {
  updateWorkspace,
  updateNotificationSettings,
  updatePaymentInvoiceStatusActivating,
} = usersSlice.actions;

export default usersSlice.reducer;
