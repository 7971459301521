import React from 'react';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { css } from 'styled-components/macro';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import theme from 'src/domains/root/styles/theme';
import Divider from '@mui/material/Divider';
import { useSelector } from 'src/domains/root/store';
import { meResultSelector } from 'src/domains/root/features/users/slice';
import WorkspaceInfo from 'src/domains/root/features/workspaces/WorkspaceInfo';
import { IS_ENV } from 'src/constants';
import AccountSettingMenuItem from '../global-header/menu/items/AccountSettingMenuItem';
import LogoutMenuItem from '../global-header/menu/items/LogoutMenuItem';
import TermsOfServiceMenuItem from '../global-header/menu/items/TermsOfServiceMenuItem';
import PrivacyPolicyMenuItem from '../global-header/menu/items/PrivacyPolicyMenuItem';
import AppVersionMenuItem from '../global-header/menu/items/AppVersionMenuItem';
import NotificationSettingsMenuItem from '../global-header/menu/items/NotificationSettingsMenuItem';
import ASCTMenuItem from '../global-header/menu/items/ASCTMenuItem';
import MenuList from './MenuList';
import DividerIcon from './icons/DividerIcon';

const drawerWidth = 260;

const drawerStyle = css`
  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
  }
`;

const drawerListStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 0;
`;

const dividerBoxStyle = css`
  height: 20px;
`;

const dividerStyle = css`
  margin-bottom: ${theme.spacing(2.5)};
  margin-left: ${theme.spacing(1.5)};
`;

const menuBottomDividerStyle = css`
  margin-bottom: 0;
`;

const userInfoListItemStyle = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const accountNameTypographyStyle = css`
  color: #969696;
  display: contents;
  font-size: 12px;
  font-weight: bold;
`;

const userEmailTypographyStyle = css`
  font-size: 14px;
  margin-top: 6px;
  white-space: break-spaces;
  width: 100%;
  word-break: break-all;
`;

interface MobileDrawerProps {
  currentAlertCount: number;
  openMobileDrawer: boolean;
  handleMobileDrawerToggle: () => void;
  drawerOpen: boolean;
}

const MobileDrawer: React.FC<MobileDrawerProps> = (props) => {
  const {
    openMobileDrawer,
    handleMobileDrawerToggle,
    currentAlertCount,
    drawerOpen,
  } = props;

  const intl = useIntl();
  const theme = useTheme();

  const { data: me } = useSelector(meResultSelector);

  return (
    <>
      <Drawer
        data-testid="MobileDrawer"
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={openMobileDrawer}
        onClose={handleMobileDrawerToggle}
        css={drawerStyle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <List css={drawerListStyle}>
          <Box>
            <WorkspaceInfo
              drawerOpen={true}
              handleDrawerToggle={handleMobileDrawerToggle}
            />
            <Box css={dividerBoxStyle}>
              <DividerIcon css={dividerStyle} />
            </Box>
            <MenuList
              alert={currentAlertCount}
              drawerOpen={drawerOpen}
              handleDrawerToggle={handleMobileDrawerToggle}
            />
          </Box>
          <Box>
            <DividerIcon css={[dividerStyle, menuBottomDividerStyle]} />
            <ListItem css={userInfoListItemStyle}>
              <ListItemText>
                <Typography variant="body2" css={accountNameTypographyStyle}>
                  {intl.formatMessage({
                    id: `appBar.email`,
                  })}
                </Typography>
              </ListItemText>
              <ListItemText sx={{ width: '100%' }}>
                <Typography css={userEmailTypographyStyle}>
                  {me?.email}
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider variant="middle" />
            <AccountSettingMenuItem currentEmail={me?.email} />
            <NotificationSettingsMenuItem />
            <TermsOfServiceMenuItem />
            <PrivacyPolicyMenuItem />
            {!IS_ENV.DEMO && <ASCTMenuItem />}
            <LogoutMenuItem />
            <AppVersionMenuItem />
          </Box>
        </List>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
