import React from 'react';
import SensorDataDialog from '@mui/material/Dialog';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import { useIntl } from 'react-intl';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Box from '@mui/material/Box';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import SensorUnitNameList from 'src/domains/root/commons/settings/dialog/SensorUnitNameList';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import { useAuth0 } from '@auth0/auth0-react';
import { getGatewaysSilently } from 'src/domains/root/features/gateways/slice';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import { awsRum } from 'src/utils/rum';
import { useSelector } from 'src/domains/root/store';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { gatewaySelector } from 'src/domains/root/features/gateways/slice';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { getAlertState } from '../../../current-alert/slice';
import Agreement from './Agreement';
import {
  deleteWorkspacesGatewaysSensorData,
  removeResultSelector,
  resetRemoveResult,
} from './slice';

interface DialogProps {
  targetGatewayId: string;
  onClose: () => void;
}

const Dialog: React.FC<DialogProps> = (props) => {
  const { targetGatewayId: gatewayId, onClose: _handleClose } = props;
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const removeResult = useSelector(removeResultSelector);
  const gateway = useSelector((state) => gatewaySelector(state, gatewayId));

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    agreement: yup.boolean().isTrue(),
  });

  const formik = useFormik({
    initialValues: {
      agreement: false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmit();
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleClose = () => {
    dispatch(resetRemoveResult());
    _handleClose();
  };

  const handleSubmit = () => {
    const token = getAccessTokenSilently();
    dispatch(
      deleteWorkspacesGatewaysSensorData({
        token,
        workspaceId: workspace.workspaceId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        gatewayId: gatewayId!,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          getGatewaysSilently({
            token,
            workspaceId: workspace.workspaceId,
          }),
        );

        dispatch(
          getAlertState({
            token,
            workspaceId: workspace.workspaceId,
            silent: true,
          }),
        );
      })
      .catch((error) => {
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
      })
      .finally(() => {
        formik.setValues({ agreement: false });
        formik.resetForm();
      });
  };

  return (
    <SensorDataDialog
      open={!!gatewayId}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="sensor-data-deletion-dialog-title"
      onClose={handleClose}
    >
      <DialogTitle id="sensor-data-deletion-dialog-title">
        <Title onClose={handleClose}>
          {intl.formatMessage({
            id: 'pages.OtherSetting.gateways.sensorData.dialog.title',
          })}
        </Title>
        {removeResult.error && (
          <Box mt={2.5} mb={0.5}>
            <Alert severity="error">
              {intl.formatMessage({
                id: `pages.OtherSetting.gateways.sensorData.dialog.alert.error.${removeResult.error.code}`,
              })}
            </Alert>
          </Box>
        )}
        {removeResult.status === 'succeeded' && (
          <Box mt={2.5} mb={0.5}>
            <Alert severity="success">
              {intl.formatMessage({
                id: 'pages.OtherSetting.gateways.sensorData.dialog.alert.success.completed',
              })}
            </Alert>
          </Box>
        )}
      </DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent dividers>
            <Box my={1}>
              <Box mb={2.5}>
                <Typography>
                  {intl.formatMessage({
                    id: 'pages.OtherSetting.gateways.sensorData.dialog.messages.0',
                  })}
                </Typography>
                <Typography>
                  {intl.formatMessage({
                    id: 'pages.OtherSetting.gateways.sensorData.dialog.messages.1',
                  })}
                </Typography>
                <Typography>
                  {intl.formatMessage({
                    id: 'pages.OtherSetting.gateways.sensorData.dialog.messages.2',
                  })}
                </Typography>
              </Box>
              <Box mb={2.5}>
                <Typography
                  fontWeight="bold"
                  sx={{ wordBreak: 'break-all' }}
                  mb={1.5}
                >
                  {intl.formatMessage(
                    {
                      id: 'pages.OtherSetting.gateways.sensorData.dialog.messages.3',
                    },
                    { gatewayName: gateway.name },
                  )}
                </Typography>
                <SensorUnitNameList sensorUnits={gateway.sensorUnits} />
              </Box>
              <Box display="flex" justifyContent="center">
                <Agreement />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" gap={1.5}>
              <CancelButton onClick={handleClose} />
              <SubmitButton data-testid="SubmitDeletionButton">
                {intl.formatMessage({
                  id: 'pages.OtherSetting.gateways.sensorData.dialog.form.button',
                })}
              </SubmitButton>
            </Box>
          </DialogActions>
        </Form>
      </FormikProvider>
    </SensorDataDialog>
  );
};

export default Dialog;
