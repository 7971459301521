import React from 'react';
import { TextField } from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  sensorUnits: SensorUnit[];
}

interface SensorUnit {
  deviceId: string;
  number: number;
  name: string;
}

interface SensorNameFieldProps {
  index: number;
}

const SensorUnitNameField: React.FC<SensorNameFieldProps> = (props) => {
  const { index } = props;

  const intl = useIntl();
  const { values, errors, handleChange } = useFormikContext<Values>();

  const sensorUnit = values.sensorUnits[index];

  const sensorUnitErrors = errors.sensorUnits as FormikErrors<SensorUnit>[];
  const sensorUnitError = sensorUnitErrors
    ? sensorUnitErrors[index]
    : undefined;

  return (
    <TextField
      data-testid={`SensorUnitNameTextInput-${index}`}
      name={`sensorUnits.${index}.name`}
      value={sensorUnit.name}
      label={
        intl.formatMessage({
          id: 'common.sensorUnitName',
        }) + sensorUnit.number
      }
      onChange={handleChange}
      error={Boolean(sensorUnitError?.name)}
      helperText={sensorUnitError?.name}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default SensorUnitNameField;
