import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootState, Result } from 'src/domains/root/store';
import { RootError, RootErrorResponse } from '../../../../utils/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

interface DeleteWorkspacesGatewaysSensorDataParams {
  token: Promise<string>;
  workspaceId: string;
  gatewayId: string;
}

export type WorkspacesGatewaysSensorDataErrorCode =
  DeleteWorkspacesGatewaysSensorDataErrorCode;

export type DeleteWorkspacesGatewaysSensorDataErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'not_found'
  | 'emergency_maintenance'
  | 'unknown_error';

export const deleteWorkspacesGatewaysSensorData = createAsyncThunk<
  void,
  DeleteWorkspacesGatewaysSensorDataParams,
  {
    state: RootState;
    rejectValue: RootError<DeleteWorkspacesGatewaysSensorDataErrorCode>;
  }
>('workspaces/gateways/sensor-data/delete', async (params, thunkAPI) => {
  const token = await params.token;
  const { gatewayId } = params;

  try {
    await axios.delete<void>(
      `${API_ENDPOINT}/gateways/${gatewayId}/sensor-data`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const sensorDataError = getSensorDataError(error);
    return thunkAPI.rejectWithValue(sensorDataError);
  }
});

const getSensorDataError = (
  error: AxiosError<RootErrorResponse>,
): RootError<DeleteWorkspacesGatewaysSensorDataErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'not_found',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface WorkspacesGatewaysSensorDataState {
  removeResult: Result<void, RootError<WorkspacesGatewaysSensorDataErrorCode>>;
}

const initialState: WorkspacesGatewaysSensorDataState = {
  removeResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const workspacesGatewaysSensorDataSlice = createSlice({
  name: 'workspaces/gateways/sensor-data',
  initialState,
  reducers: {
    resetRemoveResult(state) {
      state.removeResult.error = null;
      state.removeResult.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteWorkspacesGatewaysSensorData.pending, (state) => {
        state.removeResult.status = 'loading';
        state.removeResult.error = null;
      })
      .addCase(deleteWorkspacesGatewaysSensorData.fulfilled, (state) => {
        state.removeResult.status = 'succeeded';
        state.removeResult.error = null;
      })
      .addCase(deleteWorkspacesGatewaysSensorData.rejected, (state, action) => {
        state.removeResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.removeResult.error = action.payload!;
      });
  },
});

export const removeResultSelector = createSelector(
  (state: RootState) => state.workspacesGatewaysSensorData,
  (state: WorkspacesGatewaysSensorDataState) => state.removeResult,
);

export const { resetRemoveResult } = workspacesGatewaysSensorDataSlice.actions;

export default workspacesGatewaysSensorDataSlice.reducer;
