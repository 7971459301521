import React, { useState } from 'react';
import { Hidden, useTheme, useMediaQuery } from '@mui/material';
import { useSelector } from 'src/domains/root/store';
import { currentAlertCountSelector } from 'src/domains/root/features/current-alert/slice';
import MobileDrawer from './drawer/MobileDrawer';
import MiniVariantDrawer from './drawer/MiniVariantDrawer';

interface BaseDrawerProps {
  pcDrawer?: boolean;
  mobileDrawer?: boolean;
  openMobileDrawer: boolean;
  handleMobileDrawerToggle: () => void;
}

const BaseDrawerContainer: React.FC<BaseDrawerProps> = (props) => {
  const {
    pcDrawer = true,
    mobileDrawer = true,
    openMobileDrawer,
    handleMobileDrawerToggle,
  } = props;
  const currentAlertCount = useSelector(currentAlertCountSelector);

  const { shouldUnfold, toggleDrawer } = useDrawerState();

  return (
    <>
      {mobileDrawer ? (
        <Hidden smUp implementation="css">
          <MobileDrawer
            currentAlertCount={currentAlertCount}
            openMobileDrawer={openMobileDrawer}
            handleMobileDrawerToggle={handleMobileDrawerToggle}
            drawerOpen={shouldUnfold}
          />
        </Hidden>
      ) : null}
      {pcDrawer ? (
        <Hidden smDown implementation="css">
          <MiniVariantDrawer
            handleDrawerToggle={toggleDrawer}
            currentAlertCount={currentAlertCount}
            drawerOpen={shouldUnfold}
          />
        </Hidden>
      ) : null}
    </>
  );
};

export default BaseDrawerContainer;

// hooks

/**
 * サイドナビゲーションの状態を管理するcustom hook。
 * toggleボタンを押していないときは、画面サイズに応じて自動的に開閉する。
 * toggleボタンを押したときは、画面サイズに関わらずtoggleボタンによる指示が優先される。
 */
const useDrawerState = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerStatus, setDrawerStatus] = useState<
    'default' | 'fold' | 'unfold'
  >('default');

  const shouldUnfold =
    drawerStatus === 'default' ? !isMediumScreen : drawerStatus === 'unfold';

  const toggleDrawer = () => {
    setDrawerStatus(shouldUnfold ? 'fold' : 'unfold');
  };

  return { shouldUnfold, toggleDrawer };
};
