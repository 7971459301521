import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import * as API from './api';
import { ObserveModeType, PostSensorUnitCSVResponse } from './type';

export type SucceededResult = {
  status: 'succeeded';
} & PostSensorUnitCSVResponse;

type ErrorCode = 'not_found' | 'unknown_error';

export type Result =
  | { status: 'hasError'; code: ErrorCode }
  | { status: 'loading' }
  | { status: 'idle' }
  | { status: 'noData' }
  | SucceededResult;

export default function usePostSensorUnitCsv({
  sensorUnitId,
  mode,
}: {
  sensorUnitId: string;
  mode: ObserveModeType;
}): {
  mutate: (begin: number, end: number) => Promise<PostSensorUnitCSVResponse>;
  reset: () => void;
  result: Result;
} {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleRedirect = (error: API.Error) => {
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  };

  // Define a mutation for making the POST request
  const sensorUnitCSVMutation = useMutation<
    PostSensorUnitCSVResponse,
    API.Error,
    { begin: number; end: number }
  >({
    mutationFn: async ({ begin, end }) => {
      const token = await getAccessTokenSilently();
      return API.postSensorUnitCsv(sensorUnitId, token, begin, end, mode);
    },
  });

  const mutate = (begin: number, end: number) => {
    return sensorUnitCSVMutation.mutateAsync({ begin, end });
  };

  if (sensorUnitCSVMutation.isIdle) {
    return {
      mutate,
      reset: sensorUnitCSVMutation.reset,
      result: { status: 'idle' },
    };
  }
  // Handle loading and no data states
  if (sensorUnitCSVMutation.isPending) {
    return {
      mutate,
      reset: sensorUnitCSVMutation.reset,
      result: { status: 'loading' },
    };
  }

  if (sensorUnitCSVMutation.isError) {
    const error = sensorUnitCSVMutation.error;
    handleRedirect(error);

    switch (error.code) {
      case 'not_found':
        return {
          mutate,
          reset: sensorUnitCSVMutation.reset,
          result: { status: 'hasError', code: 'not_found' },
        };
      default:
        return {
          mutate,
          reset: sensorUnitCSVMutation.reset,
          result: { status: 'hasError', code: 'unknown_error' },
        };
    }
  }

  const data = sensorUnitCSVMutation.data;

  if (!data.s3Url) {
    return {
      mutate,
      reset: sensorUnitCSVMutation.reset,
      result: { status: 'noData' },
    };
  }

  return {
    mutate,
    reset: sensorUnitCSVMutation.reset,
    result: { status: 'succeeded', ...data },
  };
}
