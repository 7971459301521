import React from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface Values {
  agreement: boolean;
}

const Agreement: React.FC = () => {
  const intl = useIntl();
  const { values, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({
      ...touched,
      agreement: true,
    });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={values.agreement}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          name="agreement"
          data-testid="DeleteAccountAgreementCheckbox"
          sx={{
            color: 'primary.main',
          }}
        />
      }
      label={
        <Typography variant="body2">
          <Box component="span">
            {intl.formatMessage({
              id: 'pages.DeleteAccount.dialogs.confirm.description.agreeToDelete',
            })}
          </Box>
        </Typography>
      }
    />
  );
};

export default Agreement;
