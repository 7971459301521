import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';

interface Option {
  gatewayName: string;
  sensorUnitName: string;
  alertType: string;
  event: string;
}

interface Props {
  option: Option;
  updateOption: (event: SelectChangeEvent<unknown>) => void;
}

const AlertHistoryClearButton = ({ option, updateOption }: Props) => {
  const intl = useIntl();

  const [disabled, setDisabled] = useState(true);

  const handleClearButton = (
    e: React.ChangeEvent<{ name?: string; value: unknown }> &
      React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.target.name = 'clear';
    updateOption(e as unknown as SelectChangeEvent<unknown>);
  };

  useEffect(() => {
    if (
      option.gatewayName === 'all' &&
      option.sensorUnitName === 'all' &&
      option.alertType === 'all' &&
      option.event === 'all'
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [option]);

  return (
    <Box ml={2}>
      <SecondaryButton disabled={disabled} onClick={handleClearButton}>
        {intl.formatMessage({
          id: 'alertHistoryTable.clear',
        })}
      </SecondaryButton>
    </Box>
  );
};

export default AlertHistoryClearButton;
