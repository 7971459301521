export default function ReportIcon(
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17 1.5C17 1.22386 16.7761 1 16.5 1H3.5C3.22386 1 3 1.22386 3 1.5V16.5C3 16.7761 3.22386 17 3.5 17H4.5C4.77614 17 5 16.7761 5 16.5V3.5C5 3.22386 5.22386 3 5.5 3H16.5C16.7761 3 17 2.77614 17 2.5V1.5ZM15.7929 5C15.9255 5 16.0527 5.05268 16.1464 5.14645L21.8536 10.8536C21.9473 10.9473 22 11.0745 22 11.2071V22.5C22 22.7761 21.7761 23 21.5 23H7.5C7.22386 23 7 22.7761 7 22.5V5.5C7 5.22386 7.22386 5 7.5 5H15.7929ZM15 11.5C15 11.7761 15.2239 12 15.5 12H19.2929C19.7383 12 19.9614 11.4614 19.6464 11.1464L15.8536 7.35355C15.5386 7.03857 15 7.26165 15 7.70711V11.5Z"
        fill="#787878"
      />
    </svg>
  );
}
