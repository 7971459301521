import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import { AppDispatch } from 'src/domains/root/store';
import { Workspace } from 'src/domains/root/features/users/slice';
import Box from '@mui/material/Box';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import { emailLengthTester } from 'src/utils/users/validation/email';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import {
  getWorkspacesInvitations,
  postWorkspacesInvitation,
  resetRemoveInvitation,
} from '../slice';
import RoleSelectBox from '../../users/update/role/form/RoleSelectBox';
import EmailField from './form/EmailField';
import ConfirmDialog from './form/ConfirmDialog';

interface FormValues {
  email: string;
  role: string;
}

type Props = {
  workspace: Workspace;
};
const Form: React.FC<Props> = ({ workspace }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'pages.OtherSetting.users.invitation.form.email.textFieldLabel',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .email(intl.formatMessage({ id: 'validation.string.email' }))
      .test({
        test: emailLengthTester,
        message: intl.formatMessage({ id: 'validation.string.emailLength' }),
      }),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      role: 'normal',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, validateForm }) => {
      const { email } = values;
      const token = getAccessTokenSilently();
      handleModalClose();

      const promise = dispatch(
        postWorkspacesInvitation({
          token,
          email,
          role: values.role,
          workspaceId: workspace.workspaceId,
        }),
      );

      promise
        .unwrap()
        .then(() => {
          resetForm();
          dispatch(
            getWorkspacesInvitations({
              token,
              workspaceId: workspace.workspaceId,
            }),
          );
        })
        .finally(() => {
          validateForm();
          dispatch(resetRemoveInvitation());
        });

      return promise;
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  useEffect(() => {
    dispatch(resetRemoveInvitation());
  }, [dispatch]);

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <ValidationBox>
          <EmailField />
        </ValidationBox>
        <ValidationBox>
          <RoleSelectBox
            label={intl.formatMessage({
              id: 'features.workspaces.users.update.role.dialog.form.role.label',
            })}
            roles={['admin', 'normal']}
          />
        </ValidationBox>
        <Box my={4}>
          <Box display="flex" justifyContent="space-between">
            <PrimaryButton
              data-testid="PrimaryButton"
              fullWidth
              size="large"
              onClick={handleModalOpen}
              disabled={!formik.isValid}
            >
              {intl.formatMessage({
                id: 'pages.OtherSetting.users.invitation.form.email.button',
              })}
            </PrimaryButton>
          </Box>
        </Box>
      </FormikForm>
      <ConfirmDialog
        isOpen={modalOpen}
        workspaceName={workspace.workspaceName}
        onClose={handleModalClose}
      />
    </FormikProvider>
  );
};

export default Form;
