import React from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  number: number;
};

const Number = (props: Props) => {
  const { number } = props;

  return <Typography fontSize={10}>No.{number}</Typography>;
};

export default Number;
