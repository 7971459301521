import React from 'react';

const DrawerRightButtonIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        r="16"
        transform="matrix(-1 0 0 1 18 18)"
        fill="#F05064"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M23.25 18.433C23.5833 18.2406 23.5833 17.7594 23.25 17.567L15.75 13.2369C15.4167 13.0444 15 13.285 15 13.6699L15 22.3301C15 22.715 15.4167 22.9556 15.75 22.7631L23.25 18.433Z"
        fill="white"
      />
    </svg>
  );
};

export default DrawerRightButtonIcon;
