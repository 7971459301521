import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { css } from 'styled-components/macro';

const boxStyle = css`
  & .MuiFormHelperText-root {
    font-size: ${(props) => props.theme.typography.pxToRem(10)};
    font-weight: bold;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ValidationField: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box mx={2} my={3} height={52} css={boxStyle} {...rest}>
      {children}
    </Box>
  );
};

export default ValidationField;
