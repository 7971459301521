import React from 'react';
import { useIntl } from 'react-intl';
import UnrecoverableError from './UnrecoverableError';

const UsedRegistrationCodeError: React.FC = () => {
  const intl = useIntl();
  return (
    <UnrecoverableError
      noButton
      message={intl.formatMessage(
        { id: 'common.error.emergency_maintenance' },
        { br: <br /> },
      )}
    />
  );
};

export default UsedRegistrationCodeError;
