import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Status } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface Workspace {
  workspaceId: string;
  name: string;
}

export type CurrentWorkspaceErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export interface PutCurrentWorkspaceParams {
  token: Promise<string>;
  workspace: Workspace;
}

export const putWorkspace = createAsyncThunk<
  void,
  PutCurrentWorkspaceParams,
  { state: RootState; rejectValue: RootError<CurrentWorkspaceErrorCode> }
>('currnetWorkspace/putWorkspace', async (params, thunkAPI) => {
  const token = await params.token;
  const workspace = params.workspace;
  try {
    // May be desirable to contain workspaceId in request body.
    await axios.put(
      `${API_ENDPOINT}/workspaces/${workspace.workspaceId}/latest`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspaceError = getCurrentWorkspaceError(error);
    return thunkAPI.rejectWithValue(workspaceError);
  }
});

const getCurrentWorkspaceError = (
  error: AxiosError<RootErrorResponse>,
): RootError<CurrentWorkspaceErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface CurrentWorkspaceState {
  error: RootError<CurrentWorkspaceErrorCode> | null;
  status: Status;
}

const initialState: CurrentWorkspaceState = {
  error: null,
  status: 'idle',
};

export const currentWorkspaceSlice = createSlice({
  name: 'currentWorkspace',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putWorkspace.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putWorkspace.fulfilled, (state) => {
        state.error = null;
        state.status = 'succeeded';
      })
      .addCase(putWorkspace.rejected, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.error = action.payload!;
        state.status = 'failed';
      });
  },
});

export const errorSelector = createSelector(
  (state: RootState) => state.currentWorkspace,
  (state: CurrentWorkspaceState) => state.error,
);

export const statusSelector = createSelector(
  (state: RootState) => state.currentWorkspace,
  (state: CurrentWorkspaceState) => state.status,
);

export default currentWorkspaceSlice.reducer;
