import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { awsRum } from 'src/utils/rum';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Status } from 'src/domains/root/store';
import { ObserveMode } from 'src/domains/root/utils/highcharts/chart-utils';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type GatewayBattery = 'empty' | 'low' | 'high' | 'charging' | 'unknown';

export type SensorUnitBattery = 'empty' | 'low' | 'high' | 'full' | 'unknown';

export type PowerSupply = 'usb' | 'battery' | 'unknown';

export interface Gateway {
  deviceId: string;
  latestTimestamp: number;
  area?: Area;
  name: string;
  physicalId: string;
  battery: GatewayBattery;
  /**
   * 契約終了時点のtimestamp(ms)
   * この値が 2024/01/02T00:00:00(+09:00) のtimestampであるとき、
   * 画面には「2024/1/1まで」と表示する。（つまり表示の際に-1日する）
   */
  contractExpiredAt: number;
  numOfRemainingDays: number | null;
  sensorUnits: SensorUnit[];
}

export interface Area {
  areaId: string;
  prefectureName: string;
  displayAreaName: string;
}

export interface SensorUnit {
  deviceId: string;
  number: number;
  name: string;
  macAddress: string;
  isAlarmEnabled: boolean;
  isAlive?: boolean;
  observe: {
    mode: ObserveMode;
  };
  temperature: number;
  humidity: number;
  battery?: SensorUnitBattery;
  powerSupply?: PowerSupply;
  objectTemperature: number;
}

export interface GetGatewaysParams {
  token: Promise<string>;
  workspaceId: string;
}

interface GetGatewaysResponse {
  gateways: Gateway[];
}

export type GatewaysErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getGateways = createAsyncThunk<
  Gateway[],
  GetGatewaysParams,
  { state: RootState; rejectValue: RootError<GatewaysErrorCode> }
>('gateways/getGateways', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const res = await axios.get<GetGatewaysResponse>(
      `${API_ENDPOINT}/workspaces/${params.workspaceId}/gateways`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res.data.gateways;
  } catch (error) {
    if (!isAxiosError(error)) {
      awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
      throw error;
    }
    const gatewaysError = getGatewaysError(error);
    if (gatewaysError.code === 'unknown_error') {
      awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    }
    return thunkAPI.rejectWithValue(gatewaysError);
  }
});

export const getGatewaysSilently = createAsyncThunk<
  Gateway[],
  GetGatewaysParams,
  { state: RootState }
>('gateways/getGatewaysSilentry', async (params) => {
  const token = await params.token;
  const res = await axios.get<GetGatewaysResponse>(
    `${API_ENDPOINT}/workspaces/${params.workspaceId}/gateways`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return res.data.gateways;
});

const getGatewaysError = (
  error: AxiosError<RootErrorResponse>,
): RootError<GatewaysErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

interface GatewayAndSensorUnitNames {
  gateway: { deviceId: string; name: string };
  sensorUnits: { deviceId: string; number: number; name: string }[];
}

interface GatewayAndArea {
  gateway: { deviceId: string };
  area: {
    areaId: string;
    prefectureName: string;
    displayAreaName: string;
  };
}

interface GatewayAndSensorUnitAlarms {
  gateway: { deviceId: string };
  sensorUnits: [
    {
      deviceId: string;
      isAlarmEnabled: boolean;
    },
  ];
}

interface GatewayAndSensorUnitObserve {
  gateway: { deviceId: string };
  sensorUnits: [
    {
      deviceId: string;
      observe: { mode: ObserveMode };
    },
  ];
}

export interface GatewaysState {
  gateways: Gateway[];
  error: RootError<GatewaysErrorCode> | null;
  status: Status;
}

const initialState: GatewaysState = {
  gateways: [],
  error: null,
  status: 'idle',
};

export const gatewaysSlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    updateGatewayAndSensorNames(
      state,
      action: PayloadAction<GatewayAndSensorUnitNames>,
    ) {
      const { gateway: nextGateway, sensorUnits: nextSensorUnits } =
        action.payload;

      const gateway = state.gateways.find(
        (gateway) => gateway.deviceId == nextGateway.deviceId,
      );
      if (!gateway) return;

      gateway.name = nextGateway.name;

      nextSensorUnits.forEach((nextSensorUnit) => {
        const sensorUnit = gateway.sensorUnits.find(
          (sensorUnit) => sensorUnit.deviceId == nextSensorUnit.deviceId,
        );
        if (!sensorUnit) return;

        sensorUnit.name = nextSensorUnit.name;
      });
    },
    updateArea(state, action: PayloadAction<GatewayAndArea>) {
      const { gateway: nextGateway, area: nextArea } = action.payload;

      const gateway = state.gateways.find(
        (gateway) => gateway.deviceId == nextGateway.deviceId,
      );
      if (!gateway) return;

      gateway.area = {
        areaId: nextArea.areaId,
        prefectureName: nextArea.prefectureName,
        displayAreaName: nextArea.displayAreaName,
      };
    },
    updateSensorUnitAlarms(
      state,
      action: PayloadAction<GatewayAndSensorUnitAlarms>,
    ) {
      const { gateway: nextGateway, sensorUnits: nextSensorUnits } =
        action.payload;

      const gateway = state.gateways.find(
        (gateway) => gateway.deviceId == nextGateway.deviceId,
      );
      if (!gateway) return;

      nextSensorUnits.forEach((nextSensorUnit) => {
        const sensorUnit = gateway.sensorUnits.find(
          (sensorUnit) => sensorUnit.deviceId == nextSensorUnit.deviceId,
        );
        if (!sensorUnit) return;

        sensorUnit.isAlarmEnabled = nextSensorUnit.isAlarmEnabled;
      });
    },
    updateSensorUnitObserves(
      state,
      action: PayloadAction<GatewayAndSensorUnitObserve>,
    ) {
      const { gateway: nextGateway, sensorUnits: nextSensorUnits } =
        action.payload;

      const gateway = state.gateways.find(
        (gateway) => gateway.deviceId == nextGateway.deviceId,
      );
      if (!gateway) return;

      nextSensorUnits.forEach((nextSensorUnit) => {
        const sensorUnit = gateway.sensorUnits.find(
          (sensorUnit) => sensorUnit.deviceId == nextSensorUnit.deviceId,
        );
        if (!sensorUnit) return;

        sensorUnit.observe = nextSensorUnit.observe;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGateways.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getGateways.fulfilled, (state, action) => {
        state.gateways = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getGateways.rejected, (state, action) => {
        state.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.error = action.payload!;
      })
      .addCase(getGatewaysSilently.fulfilled, (state, action) => {
        state.gateways = action.payload;
      });
  },
});

export const gatewaysSelector = createSelector(
  (state: RootState) => state.gateways,
  (state: GatewaysState) => state.gateways,
);

export const gatewaySelector = createSelector(
  (state: RootState) => state.gateways,
  (_: RootState, deviceId: string) => deviceId,
  (state: GatewaysState, deviceId: string) => {
    const gateway = state.gateways.find(
      (gateway) => gateway.deviceId == deviceId,
    );
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return gateway!;
  },
);

export const errorSelector = createSelector(
  (state: RootState) => state.gateways,
  (state: GatewaysState) => state.error,
);

export const statusSelector = createSelector(
  (state: RootState) => state.gateways,
  (state: GatewaysState) => state.status,
);

export const {
  updateGatewayAndSensorNames,
  updateArea,
  updateSensorUnitAlarms,
  updateSensorUnitObserves,
} = gatewaysSlice.actions;

export default gatewaysSlice.reducer;
