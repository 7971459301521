/**
 * このファイル名の `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import GatewayDataChip from './ChipV3';

type Props = {
  latestTimestamp: number;
};

const GatewayLatestTimestamp: React.FC<Props> = (props) => {
  const { latestTimestamp } = props;
  const intl = useIntl();

  return (
    <Box
      pb={0.25}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      })}
    >
      <GatewayDataChip
        label={intl.formatMessage({
          id: 'gatewayInfo.latestTimestamp',
        })}
      />
      <Box
        pt={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            pt: 0,
            pl: 2,
          },
        })}
      >
        <Typography>
          {latestTimestamp === 0
            ? '-'
            : DateTime.fromMillis(latestTimestamp).toFormat(
                'yyyy/M/d HH:mm:ss',
              )}
        </Typography>
      </Box>
    </Box>
  );
};

export default GatewayLatestTimestamp;
