import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import * as API from 'src/apis';
import { handleError } from './error';

type Props = {
  workspaceId: string;
  status: API.WorkspaceGatewayPaymentInvoiceStatus;
  token: string;
};

export type GetWorkspacesGatewaysPaymentsInvoicesResponse = {
  gatewayIds: string[];
};

export async function getWorkspacesGatewaysPaymentsInvoices({
  workspaceId,
  status,
  token,
}: Props): Promise<GetWorkspacesGatewaysPaymentsInvoicesResponse> {
  return axios
    .get(
      `${API_ENDPOINT}/workspaces/${workspaceId}/gateways/payments/invoices?status=${status}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
