import { AxiosError } from 'axios';

export type RegistrationConflictErrorCode =
  | 'USED_REGISTRATION_ID'
  | 'REGISTERED_USER'
  | 'USED_REGISTRATION_CODE'
  | 'INVALID_WORKSPACE_NAME'
  | 'INVALID_GATEWAY_NAME'
  | 'JOINED_WORKSPACE'
  | 'EMERGENCY_MAINTENANCE';

export interface RegistrationErrorResponse {
  errorCode?: RegistrationConflictErrorCode;
}

export type RegistrationErrorCode =
  | 'registration_id_required'
  | 'invalid_registration_id'
  | 'used_registration_id'
  | 'expired_registration_id'
  | 'duplicate_user'
  | 'invalid_registration_code'
  | 'used_registration_code'
  | 'invalid_workspace_name'
  | 'invalid_gateway_name'
  | 'joined_workspace'
  | 'emergency_maintenance'
  | 'not_found'
  | 'conflict'
  | 'unknown_error';

export interface RegistrationError<T> {
  code: T;
  recoverable: boolean;
}

export type ResponseErrorHandler = (
  status: number,
  data: RegistrationErrorResponse,
) => RegistrationError<RegistrationErrorCode>;

export const getRegistrationError = (
  error: AxiosError<RegistrationErrorResponse>,
  onResponseError: ResponseErrorHandler,
): RegistrationError<RegistrationErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    return onResponseError(status, data);
  }
  if (error.request) {
    return { code: 'unknown_error', recoverable: true };
  }
  return { code: 'unknown_error', recoverable: true };
};

export const handleNewResponseError = (
  status: number,
  data: RegistrationErrorResponse,
): RegistrationError<RegistrationErrorCode> => {
  const { errorCode } = data ?? {};
  switch (status) {
    case 404:
      return { code: 'invalid_registration_code', recoverable: true };
    case 409:
      if (!errorCode) {
        return { code: 'unknown_error', recoverable: true };
      }
      switch (errorCode) {
        case 'REGISTERED_USER':
          return { code: 'duplicate_user', recoverable: true };
        case 'USED_REGISTRATION_CODE':
          return { code: 'used_registration_code', recoverable: true };
        default:
          return { code: 'unknown_error', recoverable: true };
      }
    case 503:
      switch (errorCode) {
        case 'EMERGENCY_MAINTENANCE':
          return { code: 'emergency_maintenance', recoverable: false };
        default:
          return { code: 'unknown_error', recoverable: true };
      }
    default:
      return { code: 'unknown_error', recoverable: true };
  }
};

export const handleShowResponseError = (
  status: number,
  data: RegistrationErrorResponse,
): RegistrationError<RegistrationErrorCode> => {
  const { errorCode } = data;
  switch (status) {
    case 404:
      return { code: 'invalid_registration_id', recoverable: false };
    case 409:
      if (!errorCode) {
        return { code: 'unknown_error', recoverable: false };
      }
      switch (errorCode) {
        case 'REGISTERED_USER':
          return { code: 'duplicate_user', recoverable: false };
        case 'USED_REGISTRATION_ID':
          return { code: 'used_registration_id', recoverable: false };
        default:
          return { code: 'unknown_error', recoverable: false };
      }
    case 410:
      return { code: 'expired_registration_id', recoverable: false };
    case 503:
      switch (errorCode) {
        case 'EMERGENCY_MAINTENANCE':
          return { code: 'emergency_maintenance', recoverable: false };
        default:
          return { code: 'unknown_error', recoverable: true };
      }
    default:
      return { code: 'unknown_error', recoverable: true };
  }
};
