import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import {
  getAlerts,
  listResultSelector,
} from 'src/domains/root/features/alerts/slice';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import HookStatus from 'src/domains/root/commons/HookStatus';
import AlertListTable from './AlertListTable';

const AlertListTableContainer: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const {
    data: alerts,
    status: alertsStatus,
    error: alertsError,
  } = useSelector(listResultSelector);

  useEffect(() => {
    if (!workspace.workspaceId) return;

    const token = getAccessTokenSilently();

    const promise = dispatch(
      getAlerts({ token, workspaceId: workspace.workspaceId }),
    );

    return () => {
      promise.abort();
    };
  }, [getAccessTokenSilently, dispatch, workspace.workspaceId]);

  const loading = alertsStatus === 'loading';
  const noData = !alerts?.length;

  if (loading || !!alertsError || noData) {
    return (
      <HookStatus
        target="alertListTable"
        loading={loading}
        error={!!alertsError}
        noData={noData}
      />
    );
  }

  return <AlertListTable alerts={alerts || []} />;
};

export default AlertListTableContainer;
