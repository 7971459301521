import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/system';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CardNumber from 'src/domains/root/commons/cards/Number';
import CardPaper from 'src/domains/root/commons/cards/Paper';
import ChartLinkButton from 'src/domains/root/commons/ChartLinkButton';
import { SensorUnit } from 'src/domains/root/features/gateways/slice';
import SensorUnitName from '../sensor-units/SensorUnitName';
import UnitizedValue from '../sensor-units/UnitizedValue';
import Chip from './data/ChipV3';

type Props = {
  sensorUnit: SensorUnit;
  onClickChartLinkButton: () => void;
};

/** 全体的に使っているbreakpointsではないが、レイアウトが崩れるので対応 */
const smallMobileWidth = 340;

const SensorUnitCard = (props: Props) => {
  const { sensorUnit, onClickChartLinkButton } = props;

  return (
    <>
      <CardNumber number={sensorUnit.number} />
      <CardPaper>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ overflowWrap: 'anywhere' }}
        >
          <SensorUnitName
            batteryOnTop
            powerSupply={sensorUnit.powerSupply}
            battery={sensorUnit.battery}
          >
            {sensorUnit.name}
          </SensorUnitName>
          <Box pl={2} height="100%">
            <ChartLinkButton
              fullHeight
              p={1}
              bgColor="#DCDCDC"
              observeMode={sensorUnit.observe.mode}
              onClickAlertChartButton={onClickChartLinkButton}
            />
          </Box>
        </Box>
        <SensorStatusBox>
          <SensorStatusRow>
            <ChipWithValue sensorUnit={sensorUnit} type="temperature" />
            <ChipWithValue sensorUnit={sensorUnit} type="humidity" />
          </SensorStatusRow>
          <SensorStatusRow>
            <ChipWithValue sensorUnit={sensorUnit} type="objectTemperature" />
            <ChipWithValue sensorUnit={sensorUnit} type="status" />
          </SensorStatusRow>
        </SensorStatusBox>
      </CardPaper>
    </>
  );
};

function SensorStatusBox({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      pt={1.5}
      gap={1.5}
      sx={(theme) => ({
        [theme.breakpoints.down(smallMobileWidth)]: {
          flexWrap: 'wrap',
        },
      })}
    >
      {children}
    </Box>
  );
}

function SensorStatusRow({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      gap={1.5}
      width="100%"
      sx={(theme) => ({
        [theme.breakpoints.down(smallMobileWidth)]: {
          flexDirection: 'column',
        },
      })}
    >
      {children}
    </Box>
  );
}

function ChipWithValue({
  sensorUnit,
  type,
}: {
  sensorUnit: SensorUnit;
  type: 'temperature' | 'humidity' | 'objectTemperature' | 'status';
}) {
  const intl = useIntl();
  const theme = useTheme();

  const bgColor = {
    temperature: '#FAF5BE',
    humidity: '#C8FAFF',
    objectTemperature: '#F5C8C8',
    status: '#E2DCD2',
  };

  return (
    <Box
      display="flex"
      gap={1.5}
      width="100%"
      justifyContent={
        type === 'humidity' || type === 'status' ? 'end' : undefined
      }
      sx={(theme) => ({
        [theme.breakpoints.down(smallMobileWidth)]: {
          justifyContent: 'center',
        },
      })}
    >
      <Chip
        textColor={theme.palette.text.primary}
        bgColor={bgColor[type]}
        fontWeight="bold"
        maxWidth="112px"
        width="100%"
        label={intl.formatMessage({
          id: `common.${type}`,
        })}
      />
      <Box display="flex" justifyContent="center" width="100%" maxWidth={50}>
        {type === 'status' ? (
          sensorUnit.isAlive === undefined ? (
            intl.formatMessage({
              id: 'common.unconnected',
            })
          ) : sensorUnit.isAlive === true ? (
            <CheckIcon />
          ) : (
            <CloseIcon />
          )
        ) : (
          <UnitizedValue value={sensorUnit[type]} type={type} />
        )}
      </Box>
    </Box>
  );
}

export default SensorUnitCard;
