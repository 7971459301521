import Button from '@mui/material/Button';
import styled from 'styled-components';

const PrimaryButton = styled(Button)`
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: ${(props) => props.theme.typography.pxToRem(18)};
  font-weight: bold;
  height: 44px;
  min-width: 100px;

  :hover {
    background: ${(props) => props.theme.palette.nito.main};
    color: ${(props) => props.theme.palette.common.white};
  }

  :disabled {
    background: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.common.white};
    opacity: 0.4;
  }
`;

export default PrimaryButton;
