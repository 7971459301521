import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _BatteryUnknownIcon = createSvgIcon(
  <>
    <g clipPath="url(#clip0_9721_35207)">
      <rect opacity="0.5" y="2" width="12" height="22" rx="1" fill="#D2D2D2" />
      <rect opacity="0.5" x="4" width="4" height="2" fill="#D2D2D2" />
      <path
        d="M9.06667 12.2533C9.06667 12.2533 8.56 12.8133 8.17333 13.2C7.53333 13.84 7.06667 14.7333 7.06667 15.3333H4.93333C4.93333 14.2267 5.54667 13.3067 6.17333 12.6667L7.41333 11.4133C7.77333 11.0533 8 10.5467 8 10C8 8.89333 7.10667 8 6 8C4.89333 8 4 8.89333 4 10H2C2 7.78667 3.78667 6 6 6C8.21333 6 10 7.78667 10 10C10 10.88 9.64 11.68 9.06667 12.2533Z"
        fill="#505050"
      />
      <rect x="5" y="17" width="2" height="2" fill="#505050" />
    </g>
    <defs>
      <clipPath id="clip0_9721_35207">
        <rect width="12" height="24" fill="white" />
      </clipPath>
    </defs>
  </>,
  'BatteryUnknown',
);

const BatteryUnknownIcon = styled(_BatteryUnknownIcon).attrs<SvgIconProps>(
  () => ({
    viewBox: '0 0 12 24',
  }),
)`
  fill: none;
  height: 24px;
  width: 12px;
`;

export default BatteryUnknownIcon;
