import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface Workspace {
  workspaceId: string;
  name: string;
  adminUserId: string;
  /**
   * 契約終了時点のtimestamp(ms)
   * この値が 2024/01/02T00:00:00(+09:00) のtimestampであるとき、
   * 画面には「2024/1/1まで」と表示する。（つまり表示の際に-1日する）
   */
  contractExpiredAt: number;
}

export interface GetWorkspacesParams {
  token: Promise<string>;
}

export type NewWorkspaceErrorCode =
  | 'invalid_workspace_name'
  | 'invalid_gateway_name'
  | 'workspace_admin_is_member_only'
  | 'not_joined_workspace'
  | 'admin_only'
  | 'permission_denied'
  | 'invalid_registration_code_and_imei_pair'
  | 'already_registerd_gateway'
  | 'emergency_maintenance'
  | 'unknown_error';

interface PostWorkspaceParams {
  token: Promise<string>;
  workspaceId: string;
  workspaceName: string;
  registrationCode: string;
  imei: string;
  gatewayName: string;
  adminUserId: string;
}

interface PostWorkspaceResponse {
  workspaceId: string;
  name: string;
  adminUserId: string;
  contractExpiredAt: number;
}

export const postWorkspace = createAsyncThunk<
  Workspace,
  PostWorkspaceParams,
  { state: RootState; rejectValue: RootError<NewWorkspaceErrorCode> }
>('newWorkspaces/postWorkspace', async (params, thunkAPI) => {
  const token = await params.token;
  const {
    workspaceId,
    workspaceName,
    registrationCode,
    imei,
    gatewayName,
    adminUserId,
  } = params;

  const body = {
    workspaceId,
    workspaceName,
    registrationCode,
    imei,
    gatewayName,
    adminUserId,
  };

  try {
    const response = await axios.post<PostWorkspaceResponse>(
      `${API_ENDPOINT}/workspaces/${workspaceId}/new-workspaces`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspaceError = getPostWorkspaceError(error);
    return thunkAPI.rejectWithValue(workspaceError);
  }
});

const getPostWorkspaceError = (
  error: AxiosError<RootErrorResponse>,
): RootError<NewWorkspaceErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;

    switch (status) {
      case 400:
        switch (errorCode) {
          case 'INVALID_WORKSPACE_NAME':
            return {
              code: 'invalid_workspace_name',
              noticeable: false,
              recoverable: true,
            };
          case 'INVALID_GATEWAY_NAME':
            return {
              code: 'invalid_gateway_name',
              noticeable: false,
              recoverable: true,
            };
          case 'WORKSPACE_ADMIN_IS_MEMBER_ONLY':
            return {
              code: 'workspace_admin_is_member_only',
              noticeable: false,
              recoverable: true,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'invalid_registration_code_and_imei_pair',
          noticeable: false,
          recoverable: true,
        };
      case 409:
        return {
          code: 'already_registerd_gateway',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface WorkspacesState {
  createResult: Result<Workspace, RootError<NewWorkspaceErrorCode>>;
}

const initialState: WorkspacesState = {
  createResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    resetCreateResult(state) {
      state.createResult.data = null;
      state.createResult.error = null;
      state.createResult.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postWorkspace.pending, (state) => {
        state.createResult.data = null;
        state.createResult.status = 'loading';
        state.createResult.error = null;
      })
      .addCase(postWorkspace.fulfilled, (state, action) => {
        state.createResult.data = action.payload;
        state.createResult.status = 'succeeded';
        state.createResult.error = null;
      })
      .addCase(postWorkspace.rejected, (state, action) => {
        state.createResult.data = null;
        state.createResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.createResult.error = action.payload!;
      });
  },
});

export const createResultSelector = createSelector(
  (state: RootState) => state.newWorkspaces,
  (state: WorkspacesState) => state.createResult,
);

export const { resetCreateResult } = workspaceSlice.actions;

export default workspaceSlice.reducer;
