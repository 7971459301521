import React, { ForwardedRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
import type { Dropdown } from 'src/domains/root/hooks/use-alert-histories-dropdown';
import type {
  OptionState,
  UpdateOption,
} from 'src/domains/root/hooks/use-filter-option';

type Props = {
  gatewayName: string;
  option: OptionState;
  updateOption: UpdateOption;
  gatewayNames: string[];
  gatewayDropdown: Dropdown;
  sensorUnitName: string;
  sensorUnitNames: string[];
  sensorUnitDropdown: Dropdown;
  alertType: string;
  event: string;
};

export default function Selects({
  gatewayName,
  option,
  updateOption,
  gatewayNames,
  gatewayDropdown,
  sensorUnitName,
  sensorUnitNames,
  sensorUnitDropdown,
  alertType,
  event,
}: Props) {
  const intl = useIntl();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flex="1"
      mb={1}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          pb: 4,
          mb: 0,
          gap: 2,
        },
      })}
    >
      <SelectFormControl>
        <SelectInputLabel htmlFor="gateway-name-simple">
          {gatewayName}
        </SelectInputLabel>
        <StyledSelect
          value={option.gatewayName}
          onChange={updateOption}
          labelId="gateway-name-simple"
          label={gatewayName}
          name="gatewayName"
        >
          <SelectMenuItem value="all">
            <MenuItemText>
              {intl.formatMessage({
                id: 'alertHistoryTable.all',
              })}
            </MenuItemText>
          </SelectMenuItem>
          {option.sensorUnitName === 'all'
            ? gatewayNames.map((gatewayName, idx) => (
                <SelectMenuItem
                  key={`${gatewayName}-${idx}`}
                  value={gatewayName}
                >
                  <MenuItemText>{gatewayName}</MenuItemText>
                </SelectMenuItem>
              ))
            : gatewayDropdown[option.sensorUnitName].map((gatewayName, idx) => (
                <SelectMenuItem
                  key={`${gatewayName}-${idx}`}
                  value={gatewayName}
                >
                  <MenuItemText>{gatewayName}</MenuItemText>
                </SelectMenuItem>
              ))}
        </StyledSelect>
      </SelectFormControl>
      <SelectFormControl>
        <SelectInputLabel htmlFor="sensor-unit-name-simple">
          {sensorUnitName}
        </SelectInputLabel>
        <StyledSelect
          value={option.sensorUnitName}
          onChange={updateOption}
          labelId="sensor-unit-name-simple"
          label={sensorUnitName}
          name="sensorUnitName"
        >
          <SelectMenuItem value="all">
            <MenuItemText>
              {intl.formatMessage({
                id: 'alertHistoryTable.all',
              })}
            </MenuItemText>
          </SelectMenuItem>
          {option.gatewayName === 'all'
            ? sensorUnitNames.map((sensorUnitName, idx) => (
                <SelectMenuItem
                  key={`${sensorUnitName}-${idx}`}
                  value={sensorUnitName}
                >
                  <MenuItemText>{sensorUnitName}</MenuItemText>
                </SelectMenuItem>
              ))
            : sensorUnitDropdown[option.gatewayName].map(
                (sensorUnitName, idx) => (
                  <SelectMenuItem
                    key={`${sensorUnitName}-${idx}`}
                    value={sensorUnitName}
                  >
                    <MenuItemText>{sensorUnitName}</MenuItemText>
                  </SelectMenuItem>
                ),
              )}
        </StyledSelect>
      </SelectFormControl>
      <SelectFormControl type="short">
        <SelectInputLabel htmlFor="alert-type-simple">
          {alertType}
        </SelectInputLabel>
        <StyledSelect
          value={option.alertType}
          onChange={updateOption}
          labelId="alert-type-simple"
          label={alertType}
          name="alertType"
        >
          <SelectMenuItem value="all">
            <MenuItemText>
              {intl.formatMessage({
                id: 'alertHistoryTable.all',
              })}
            </MenuItemText>
          </SelectMenuItem>
          <SelectMenuItem value="temperature">
            <MenuItemText>
              {intl.formatMessage({
                id: 'common.temperature',
              })}
            </MenuItemText>
          </SelectMenuItem>
          <SelectMenuItem value="humidity">
            <MenuItemText>
              {intl.formatMessage({
                id: 'common.humidity',
              })}
            </MenuItemText>
          </SelectMenuItem>
          <SelectMenuItem value="objectTemperature">
            <MenuItemText>
              {intl.formatMessage({
                id: 'common.objectTemperature',
              })}
            </MenuItemText>
          </SelectMenuItem>
          <SelectMenuItem value="condensationAlertTemperature">
            <MenuItemText>
              {intl.formatMessage({
                id: 'common.condensationAlertTemperature',
              })}
            </MenuItemText>
          </SelectMenuItem>
        </StyledSelect>
      </SelectFormControl>
      <SelectFormControl type="short">
        <SelectInputLabel htmlFor="event-type-simple">{event}</SelectInputLabel>
        <StyledSelect
          value={option.event}
          onChange={updateOption}
          labelId="event-type-simple"
          label={event}
          name="event"
        >
          <SelectMenuItem value="all">
            <MenuItemText>
              {intl.formatMessage({
                id: 'alertHistoryTable.all',
              })}
            </MenuItemText>
          </SelectMenuItem>
          <SelectMenuItem value="occurred">
            <MenuItemText>
              {intl.formatMessage({
                id: 'alertHistoryTable.occurred',
              })}
            </MenuItemText>
          </SelectMenuItem>
          <SelectMenuItem value="restored">
            <MenuItemText>
              {intl.formatMessage({
                id: 'alertHistoryTable.restored',
              })}
            </MenuItemText>
          </SelectMenuItem>
        </StyledSelect>
      </SelectFormControl>
    </Box>
  );
}

function SelectFormControl({
  type = 'long',
  children,
}: {
  type?: 'long' | 'short';
  children: React.ReactNode;
}) {
  return (
    <FormControl
      variant="outlined"
      sx={(theme) => ({
        flex: type === 'long' ? '2 1 auto' : '0.5 1 auto',
        mb: 3,
        height: 45,
        borderRadius: 1,
        minWidth: type === 'long' ? 302 : 154,
        maxWidth: type === 'long' ? 432 : 200,
        backgroundColor: 'white',

        ':not(:last-child)': {
          mr: 2,
        },

        [theme.breakpoints.down('sm')]: {
          m: 0,
          width: '100%',
          minWidth: 'unset',
          maxWidth: 'unset',
          height: 41,

          ':not(:last-child)': {
            mr: 0,
          },
        },
      })}
    >
      {children}
    </FormControl>
  );
}

function SelectInputLabel({
  htmlFor,
  children,
}: {
  htmlFor: string;
  children: React.ReactNode;
}) {
  return (
    <InputLabel
      htmlFor={htmlFor}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(11),
          // muiがscale(0.75)に設定したせいでカスタムしづらい、のでscale(1)にする
          transform: 'translate(14px, -7px) scale(1)',
        },
      })}
    >
      {children}
    </InputLabel>
  );
}

function StyledSelect({
  value,
  onChange,
  labelId,
  label,
  name,
  children,
}: {
  value: string;
  onChange: UpdateOption;
  labelId: string;
  label: string;
  name: string;
  children: React.ReactNode;
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      labelId={labelId}
      label={label}
      inputProps={{
        name: name,
        id: labelId,
      }}
      sx={(theme) => ({
        height: 45,

        fieldset: {
          border: '1px solid #828282',
        },

        [theme.breakpoints.down('sm')]: {
          height: 41,

          'fieldset > legend': {
            fontSize: theme.typography.pxToRem(11),
          },
        },
      })}
    >
      {children}
    </Select>
  );
}

const SelectMenuItem = forwardRef<
  HTMLLIElement,
  { value: string; children?: React.ReactNode }
>(
  (
    props: { value: string; children?: React.ReactNode },
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { children, ...rest } = props;

    return (
      <MenuItem
        {...rest}
        ref={ref}
        sx={(theme) => ({
          p: 1,
          mx: 1,
          borderRadius: 1,

          ':hover': {
            backgroundColor: theme.palette.primary.light,
          },

          ':focus-visible': {
            backgroundColor: theme.palette.primary.light,
          },

          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontWeight: 'bold',

            ':hover': {
              backgroundColor: `${theme.palette.primary.main}50`,
            },

            ':focus-visible': {
              backgroundColor: `${theme.palette.primary.main}`,
            },

            ':focus-visible:hover': {
              backgroundColor: `${theme.palette.primary.main}50`,
            },
          },
        })}
      >
        {children}
      </MenuItem>
    );
  },
);

SelectMenuItem.displayName = 'SelectMenuItem';

function MenuItemText({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      variant="inherit"
      noWrap
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(14),
        },
      })}
    >
      {children}
    </Typography>
  );
}
