import React from 'react';
import HookStatus from 'src/domains/root/commons/HookStatus';
import useGateways from '../useGateways';
import GatewaySettingTable from './GatewaySettingTable';

const GatewaySettingTableContainer: React.FC = () => {
  const { gateways, error, status } = useGateways();

  const loading = status === 'loading';
  const noData = !gateways.length;

  if (loading || !!error || noData) {
    return (
      <HookStatus
        target="gatewaySettingTable"
        loading={loading}
        error={!!error}
        noData={noData}
      />
    );
  }
  return <GatewaySettingTable gateways={gateways} />;
};

export default GatewaySettingTableContainer;
