import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Navigate } from 'react-router-dom';

import {
  GetServiceProviderWorkspacesResponse,
  PutServiceProviderWorkspacesUsersMeRequestBody,
} from 'src/apis';
import { awsRum } from 'src/utils/rum';

import usePutServiceProviderWorkspacesUsersMe from './usePutServiceProviderWorkspacesUsersMe';

type AccessRole = PutServiceProviderWorkspacesUsersMeRequestBody['role'];
type Workspaces = GetServiceProviderWorkspacesResponse['workspaces'];

export default function WorkspacesTable({
  workspaces,
}: {
  workspaces: Workspaces;
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <WorkspacesTableHead />
        <WorkspaceTableBody workspaces={workspaces} />
      </Table>
    </TableContainer>
  );
}

function WorkspacesTableHead() {
  return (
    <TableHead>
      <TableRow>
        <WorkspacesTableHeadCell>ワークスペース名</WorkspacesTableHeadCell>
        <WorkspacesTableHeadCell>
          一般ユーザーとしてアクセスする
        </WorkspacesTableHeadCell>
        <WorkspacesTableHeadCell>
          管理者ユーザーとしてアクセスする
        </WorkspacesTableHeadCell>
      </TableRow>
    </TableHead>
  );
}

function WorkspacesTableHeadCell({ children }: { children: React.ReactNode }) {
  return (
    <TableCell align="center" sx={{ fontSize: 22 }}>
      {children}
    </TableCell>
  );
}

function WorkspaceTableBody({ workspaces }: { workspaces: Workspaces }) {
  return (
    <TableBody>
      {workspaces.map((workspace) => (
        <TableRow key={workspace.workspaceId}>
          <TableCell sx={{ maxWidth: 480, wordBreak: 'break-word' }}>
            <Typography fontSize={20}>{workspace.workspaceName}</Typography>
          </TableCell>
          <TableCell>
            <WorkspacesTableAccessButton
              workspaceId={workspace.workspaceId}
              accessRole="normal"
            >
              ワークスペースにアクセスする
            </WorkspacesTableAccessButton>
          </TableCell>
          <TableCell>
            <WorkspacesTableAccessButton
              workspaceId={workspace.workspaceId}
              accessRole="admin"
            >
              ワークスペースにアクセスする
            </WorkspacesTableAccessButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

function WorkspacesTableAccessButton({
  workspaceId,
  accessRole,
  children,
}: {
  workspaceId: string;
  accessRole: AccessRole;
  children: React.ReactNode;
}) {
  const me = usePutServiceProviderWorkspacesUsersMe();

  const meStatus = me.result.status;

  const accessWorkspace = async () => {
    try {
      await me.mutate({ workspaceId, role: accessRole });
    } catch (error) {
      awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    }
  };

  if (meStatus === 'hasError') {
    alert('エラーが発生しました、もう一度お試しください。');
  }

  if (meStatus === 'succeeded') {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        disabled={meStatus === 'loading'}
        onClick={accessWorkspace}
        sx={{ fontSize: 20 }}
      >
        {children}
      </Button>
      {meStatus === 'loading' && (
        <Backdrop open={true} sx={{ zIndex: 1000 }}>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
}
