import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';

export interface Values {
  name: string;
}

const WorkspaceField: React.FC = () => {
  const intl = useIntl();

  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, name: true });
  };

  const errorMessage = (values.name || touched.name) && errors.name;

  return (
    <TextField
      data-testid="WorkspaceNameTextInput"
      name="name"
      type="text"
      value={values.name}
      label={intl.formatMessage({
        id: 'features.workspaces.packages.new.workspace.form.name.label',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default WorkspaceField;
