import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { WorkspacesUser } from 'src/domains/root/types';
import { TemporaryValues } from '../New';
import Form from './workspace/Form';

interface WorkspaceProps {
  adminUsers: WorkspacesUser[];
  values: TemporaryValues;
  onForward: (
    values: Pick<TemporaryValues, 'workspaceName' | 'adminUserId'>,
  ) => void;
}

const Workspace: React.FC<WorkspaceProps> = (props) => {
  const { adminUsers, values, onForward: handleForward } = props;
  const intl = useIntl();

  return (
    <>
      <Box mb={5}>
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: 'features.workspaces.packages.new.workspace.step',
          })}
        </Typography>
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: 'features.workspaces.packages.new.workspace.title',
          })}
        </Typography>
      </Box>
      <Box mx="auto" maxWidth={600}>
        <Form
          adminUsers={adminUsers}
          values={values}
          onForward={handleForward}
        />
      </Box>
    </>
  );
};

export default Workspace;
