import React from 'react';

const WorkspaceIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="8" height="8" fill="#F05064" />
      <rect x="11" width="8" height="8" fill="#F05064" />
      <rect x="22" width="8" height="8" fill="#F05064" />
      <rect y="11" width="8" height="8" fill="#F05064" />
      <rect y="22" width="8" height="8" fill="#F05064" />
      <rect x="11" y="11" width="8" height="8" fill="#F05064" />
      <rect x="11" y="22" width="8" height="8" fill="#F05064" />
      <rect x="22" y="11" width="8" height="8" fill="#F05064" />
      <rect x="22" y="22" width="8" height="8" fill="#F05064" />
    </svg>
  );
};

export default WorkspaceIcon;
