import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _BatteryFullIcon = createSvgIcon(
  <>
    <g clipPath="url(#clip0_9721_35198)">
      <rect y="2" width="12" height="22" rx="1" fill="#50D25A" />
      <rect x="4" width="4" height="2" fill="#50D25A" />
    </g>
    <defs>
      <clipPath id="clip0_9721_35198">
        <rect width="12" height="24" fill="white" />
      </clipPath>
    </defs>
  </>,
  'BatteryFull',
);

const BatteryFullIcon = styled(_BatteryFullIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 12 24',
}))`
  fill: none;
  height: 24px;
  width: 12px;
`;

export default BatteryFullIcon;
