import React from 'react';
import UnrecoverableError from './UnrecoverableError';

const InvalidEmailChangeIdError: React.FC = () => {
  return (
    <UnrecoverableError messageId="features.users.emailChanges.update.errors.invalid_email_change_id" />
  );
};

export default InvalidEmailChangeIdError;
