import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { DateTime } from 'luxon';
import _Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import { WorkspacesInvitation } from '../slice';
import Dialog from '../remove/Dialog';
import CancelIcon from '../../../../commons/icons/Cancel';

interface InvitationsProps {
  workspaceId: string;
  invitations: WorkspacesInvitation[];
}

const Table: React.FC<InvitationsProps> = (props) => {
  const { workspaceId, invitations } = props;
  const intl = useIntl();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [targetInvitation, setTargetInvitation] =
    useState<WorkspacesInvitation | null>(null);

  const openDialog = (invitation: WorkspacesInvitation) => {
    setTargetInvitation(invitation);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setTargetInvitation(null);
  };

  return (
    <>
      <_Table>
        <TableHead
          sx={{
            borderBottom: '2px solid #828282',
            '& th': {
              fontSize: 15,
              fontWeight: 'bold',
            },
          }}
        >
          <TableRow>
            <TableCell align="center" padding="normal">
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.table.head.email',
              })}
            </TableCell>
            <TableCell align="center" padding="normal">
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.table.head.authority',
              })}
            </TableCell>
            <TableCell align="center" padding="normal">
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.table.head.invitedAt',
              })}
            </TableCell>
            <TableCell align="center" padding="normal">
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.table.head.expiredAt',
              })}
            </TableCell>
            <TableCell align="center" padding="normal">
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.table.head.cancel',
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& td': {
              fontSize: 16,
              paddingY: 1,
            },
          }}
        >
          {invitations.map((invitation) => (
            <TableRow key={invitation.invitationId}>
              <TableCell align="left" padding="normal">
                {invitation.email}
              </TableCell>
              <TableCell align="center" padding="normal">
                {invitation.role === 'admin'
                  ? intl.formatMessage({
                      id: 'pages.OtherSetting.users.invitation.dialog.description.role.admin',
                    })
                  : intl.formatMessage({
                      id: 'pages.OtherSetting.users.invitation.dialog.description.role.normal',
                    })}
              </TableCell>
              <TableCell align="center" padding="normal">
                {DateTime.fromMillis(invitation.invitedAt).toFormat(
                  'M/d HH:mm',
                )}
              </TableCell>
              <TableCell align="center" padding="normal">
                {DateTime.fromMillis(invitation.expiredAt).toFormat(
                  'M/d HH:mm',
                )}
              </TableCell>
              <TableCell align="center" padding="normal">
                <IconButton
                  data-testid="CancelButton"
                  size="large"
                  onClick={() => openDialog(invitation)}
                >
                  <CancelIcon
                    color="primary"
                    viewBox="0 0 16 16"
                    sx={{ height: 16, width: 16 }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </_Table>
      {targetInvitation && (
        <Dialog
          open={dialogOpen}
          handleClose={handleClose}
          workspaceId={workspaceId}
          invitation={targetInvitation}
        />
      )}
    </>
  );
};

export default Table;
