import React from 'react';
import { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import DialogCloseIcon from 'src/domains/root/commons/dialog/title/CloseIcon';

const dialogTitleStyle = css`
  & h2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: space-between;
    width: 100%;
  }

  background-color: white;
  color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2.5)};
  ${(props) => props.theme.spacing(3)}
`;

const dialogTitleTypographyStyle = css`
  font-size: ${(props) => props.theme.typography.pxToRem(18)};
  font-weight: bold;
`;

const dialogCloseIconButtonStyle = css`
  left: 12px;
`;

const dialogCloseIconStyle = css`
  color: ${(props) => props.theme.palette.primary.main};
`;

const alertStyle = css`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

const dividerStyle = css`
  background-color: #828282;
  opacity: 0.5;
`;

interface ModalHeaderProps {
  handleClose: () => void;
  isRegionPage: boolean;
  error: boolean;
  complete: boolean;
}

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { handleClose, error, complete } = props;
  const intl = useIntl();

  return (
    <>
      <DialogTitle css={dialogTitleStyle}>
        <Box
          display="flex"
          width="100%"
          height={18}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography css={dialogTitleTypographyStyle}>
            {intl.formatMessage({
              id: 'common.locationSetting',
            })}
          </Typography>
          <IconButton
            onClick={handleClose}
            css={dialogCloseIconButtonStyle}
            size="large"
          >
            <DialogCloseIcon css={dialogCloseIconStyle} />
          </IconButton>
        </Box>
        <Box width="100%">
          {error && (
            <Alert severity="error" css={alertStyle}>
              {intl.formatMessage({
                id: 'common.failUpdate',
              })}
            </Alert>
          )}
          {complete && (
            <Alert severity="success" css={alertStyle}>
              {intl.formatMessage({
                id: 'common.successUpdate',
              })}
            </Alert>
          )}
        </Box>
      </DialogTitle>
      <Divider css={dividerStyle} />
    </>
  );
};

export default ModalHeader;
