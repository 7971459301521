import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import Signup from './pages/Signup';
import Account from './pages/Account';
import QrWelcome from './pages/qr/Welcome';
import RegistrationRequired from './routes/RegistrationRequired';
import OverlayManager from './routes/OverlayManager';
import InvalidRegistrationIdError from './pages/errors/InvalidRegistrationIdError';
import UsedRegistrationIdError from './pages/errors/UsedRegistrationIdError';
import ExpiredRegistrationIdError from './pages/errors/ExpiredRegistrationIdError';
import DuplicateUserError from './pages/errors/DuplicateUserError';
import UsedRegistrationCodeError from './pages/errors/UsedRegistrationCodeError';
import RecoverableError from './pages/errors/RecoverableError';
import EmergencyMaintenanceError from './pages/errors/EmergencyMaintenanceError';
import AccountErrorHandler from './routes/AccountErrorHandler';
import SignupErrorHandler from './routes/SignupErrorHandler';

const accountErrorMapping = {
  registration_id_required: '/registration/errors/invalid-registration-id',
  invalid_registration_id: '/registration/errors/invalid-registration-id',
  used_registration_id: '/registration/errors/used-registration-id',
  expired_registration_id: '/registration/errors/expired-registration-id',
  used_registration_code: '/registration/errors/used-registration-code',
  duplicate_user: '/registration/errors/duplicate-user',
  emergency_maintenance: '/registration/errors/emergency-maintenance',
};

const signupErrorMapping = {
  emergency_maintenance: '/registration/errors/emergency-maintenance',
};

const _Routes: React.FC = () => {
  return (
    <Routes>
      <Route path="errors/*">
        <Route
          path="invalid-registration-id"
          element={<InvalidRegistrationIdError />}
        />
        <Route
          path="used-registration-id"
          element={<UsedRegistrationIdError />}
        />
        <Route
          path="expired-registration-id"
          element={<ExpiredRegistrationIdError />}
        />
        <Route path="duplicate-user" element={<DuplicateUserError />} />
        <Route
          path="used-registration-code"
          element={<UsedRegistrationCodeError />}
        />
        <Route
          path="emergency-maintenance"
          element={<EmergencyMaintenanceError />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route path="qr">
        <Route path="welcome" element={<QrWelcome />} />
      </Route>
      <Route
        path="signup"
        element={
          <SignupErrorHandler redirectTo={signupErrorMapping}>
            <OverlayManager>
              <Signup />
            </OverlayManager>
          </SignupErrorHandler>
        }
      />
      <Route
        path="account"
        element={
          <OverlayManager>
            <AccountErrorHandler redirectTo={accountErrorMapping}>
              <RegistrationRequired>
                {(registration) =>
                  registration ? (
                    <Account registration={registration} />
                  ) : (
                    <RecoverableError messageId="common.error.unknown_error" />
                  )
                }
              </RegistrationRequired>
            </AccountErrorHandler>
          </OverlayManager>
        }
      />
      <Route path="*" element={<Navigate to="signup" />} />
    </Routes>
  );
};

export default _Routes;
