import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled, { css } from 'styled-components/macro';
import TopButton from '../TopButton';
import ContractExpiredAlertNotification from './global-header/ContractExpiredAlertNotification';
import DemoSiteAlertNotification from './global-header/DemoSiteAlertNotification';

const Wrapper = styled(Box)`
  flex-grow: 1;
  height: 100vh;
  width: 100%;
`;

const dummyToolbarStyle = css`
  height: ${(props) => props.theme.mixins.toolbar.minHeight}px;
`;

type Props = {
  /**
   * モバイルにて横方向の余白を取らない画面ではtrueにする。
   * 一覧画面などで、コンテンツが横方向に張り付くデザインで使用する。
   */
  noXPaddingIfMobile?: boolean;
  /**
   * AccountDelete 画面がbodyに余計なpaddingを持っているため、それを打ち消すためのフラグ。
   * CssBaseLineを正しく使えば不要になる。
   */
  recoverCssBaseLine?: boolean;
  /**
   * ページが伸びないようにする
   */
  notAllowOverflow?: boolean;
  /**
   * @type number の場合MUIの単位を使う (1 === 8px)
   */
  smallScreenPaddingTop?: number | string;
  showContractExpiredAlert?: boolean;
  backgroundColor?: string;
  showTopButton?: boolean;
  /**
   * @default 'lg'
   */
  containerMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children: React.ReactNode;
};

export default function MainContents(props: Props) {
  const {
    noXPaddingIfMobile,
    recoverCssBaseLine,
    notAllowOverflow,
    backgroundColor,
    smallScreenPaddingTop,
    showContractExpiredAlert = false,
    showTopButton = true,
    containerMaxWidth = 'lg',
    children,
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const px = noXPaddingIfMobile ? 0 : 1.5;
  const pt = (() => {
    if (smallScreenPaddingTop !== undefined) return smallScreenPaddingTop;

    if (recoverCssBaseLine) return 3;

    return 4;
  })();

  const wrapperStyle = {
    height: notAllowOverflow ? '100%' : undefined,
    overflowX: notAllowOverflow ? ('hidden' as const) : undefined,
  };

  return (
    <Wrapper
      /* chromiumとwebkitのmain tagの扱いが異なるの Wrapper, main両方にbgColorを設定する */
      bgcolor={backgroundColor}
      /**
       * 基本heightをoverwriteするためsxではなくstyleにする
       */
      style={{
        height: wrapperStyle.height,
      }}
      sx={{
        overflowX: wrapperStyle.overflowX,
      }}
    >
      <main
        /* chromiumとwebkitのmain tagの扱いが異なるの Wrapper, main両方にbgColorを設定する */
        style={{
          backgroundColor,
        }}
      >
        <Box css={dummyToolbarStyle} />
        {import.meta.env.MODE === 'demo' && <DemoSiteAlertNotification />}
        {showContractExpiredAlert && <ContractExpiredAlertNotification />}
        <Box
          /**
           * スマホでは左右のpaddingは20px取りたいので、本来は`px: 2.5`であるべきだが、
           * 現在は`<CssBaseline />`がThemeProviderより上位に定義されていないため、すでにbodyに8pxのpaddingが設定されている。
           * よって、ここでは12pxのpaddingとし、合わせて20pxの余白を設ける。
           * `<CssBaseline />`の対応: @see https://www.pivotaltracker.com/story/show/182683813
           */
          px={isSmallScreen ? px : 3}
          pt={isSmallScreen ? pt : 5}
          pb={5}
        >
          <Container
            maxWidth={containerMaxWidth}
            sx={{
              px: isSmallScreen ? 0 : undefined,
            }}
          >
            <>{children}</>
          </Container>
        </Box>
      </main>
      {showTopButton && <TopButton />}
    </Wrapper>
  );
}
