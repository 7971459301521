import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';
import theme from 'src/domains/root/styles/theme';

const _OtherSettingIcon = createSvgIcon(
  <>
    <circle cx="4" cy="12" r="2" fill={theme.palette.primary.main} />
    <circle cx="12" cy="12" r="2" fill={theme.palette.primary.main} />
    <circle cx="20" cy="12" r="2" fill={theme.palette.primary.main} />
  </>,
  'OtherSetting',
);

const OtherSettingIcon = styled(({ ...props }) => (
  <_OtherSettingIcon {...props} />
)).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 24 24',
}))<SvgIconProps>`
  height: 24px;
  width: 24px;
`;

export default OtherSettingIcon;
