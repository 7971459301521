import Highcharts from 'highcharts';
import { IntlShape } from 'react-intl';
import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = 'ja';

const getUnit = (intl: IntlShape, name: string, dataType?: string) => {
  if (dataType === intl.formatMessage({ id: 'common.humidity' })) {
    return intl.formatMessage({ id: 'common.unit.percent' });
  }
  if (
    name === intl.formatMessage({ id: 'common.objectTemperatureAlertCount' })
  ) {
    return intl.formatMessage({ id: 'common.unit.count' });
  }
  if (
    name === intl.formatMessage({ id: 'common.humidity' }) ||
    dataType === 'humidity'
  ) {
    return intl.formatMessage({ id: 'common.unit.percent' });
  }
  return intl.formatMessage({ id: 'common.unit.degrees' });
};

/*
 * ツールチップの設定を返す
 * series.nameに応じた条件分岐が必要な場合は修正が必要
 */
export const getTooltipOptions = (
  intl: IntlShape,
  dataType?: string,
): Highcharts.Options => {
  return {
    tooltip: {
      split: true,
      // 日付のチールチップをカスタマイズするための関数
      // データのチールチップも一緒にフォーマットしないといけない
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        // サーバーから受け取るグラフデータの仕様上`number`以外が来ることはないので、
        // パフォーマンス劣化を防ぐために`as`でcastする。
        const time = DateTime.fromMillis(this.x as number).toFormat(
          'yyyy/M/d EEEE HH:mm',
        );
        const contents = this.points
          ? this.points.map(
              (point: Highcharts.TooltipFormatterContextObject) => {
                // weatherの場合は何も表示しない
                if (
                  point.series.name ===
                  intl.formatMessage({ id: 'common.weather' })
                ) {
                  return '';
                }
                // objectTemperatureAlertCountの場合は小数表記にしない
                const isInteger =
                  point.series.name ===
                  intl.formatMessage({
                    id: 'common.objectTemperatureAlertCount',
                  });
                return `
                  <span style="color: ${point.color}">\u25CF</span>
                    ${point.series.name}: ${
                      isInteger ? point.y : point.y?.toFixed(1)
                    } ${getUnit(intl, point.series.name, dataType)}
                `;
              },
            )
          : [];
        return [time].concat(...contents);
      },
    },
  };
};
