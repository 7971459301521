import React from 'react';
import { Navigate } from 'react-router-dom';
import { InvitationError, InvitationErrorCode } from '../utils/invitations/api';

type CallbackFunction = () => InvitationErrorCode | void;

export interface ErrorHandlerProps {
  error: InvitationError<InvitationErrorCode> | null;
  redirectTo: {
    [key in InvitationErrorCode]?: CallbackFunction | string;
  };
  children: React.ReactNode;
}

const FALLBACK_PATH = '/';

export default function ErrorHandler({
  error,
  redirectTo: redirectionMapping,
  children,
}: ErrorHandlerProps) {
  if (!error || error.recoverable) {
    return <>{children}</>;
  }

  const redirectTo = redirectionMapping[error.code] ?? FALLBACK_PATH;
  const path = typeof redirectTo === 'function' ? redirectTo() : redirectTo;
  return path ? <Navigate to={path} /> : null;
}
