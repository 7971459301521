import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createSvgIcon } from '@mui/material/utils';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { css } from 'styled-components/macro';
import AnnounceIconButton from 'src/domains/root/features/layout/global-header/announce/AnnounceIconButton';
import { Menu, MenuItem } from '@mui/material';
import { Tooltip } from 'src/shared/ui';
import CabiotIcon from './icons/CabiotIcon';
import HambugerMenuCloseIcon from './icons/HambugerMenuCloseIcon';
import HambugerMenu from './menu/HambugerMenu';

const FAQ_PAGE_URL = import.meta.env.VITE_FAQ_URL;

const menuStyle = css`
  & .MuiPopover-paper {
    box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.4);
  }

  border-radius: 4px;
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: ${(props) => props.theme.spacing(2)};
`;

const _HelpIcon = createSvgIcon(
  <>
    <path d="M12 1.7C14.0372 1.7 16.0285 2.30408 17.7224 3.43586C19.4162 4.56764 20.7364 6.17628 21.516 8.05836C22.2955 9.94044 22.4995 12.0114 22.1021 14.0094C21.7047 16.0074 20.7237 17.8427 19.2832 19.2832C17.8427 20.7237 16.0074 21.7047 14.0094 22.1021C12.0114 22.4995 9.94045 22.2955 8.05837 21.516C6.17629 20.7364 4.56765 19.4162 3.43587 17.7224C2.30409 16.0285 1.7 14.0371 1.7 12C1.70265 9.26908 2.78868 6.65077 4.71973 4.71972C6.65078 2.78867 9.26909 1.70265 12 1.7V1.7ZM12 0C9.62663 0 7.30655 0.703789 5.33316 2.02237C3.35977 3.34094 1.82171 5.21509 0.913457 7.4078C0.00520509 9.60051 -0.232443 12.0133 0.23058 14.3411C0.693603 16.6689 1.8365 18.8071 3.51473 20.4853C5.19296 22.1635 7.33115 23.3064 9.65893 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0V0Z" />
    <path
      d="M12 1.7C14.0372 1.7 16.0285 2.30408 17.7224 3.43586C19.4162 4.56764 20.7364 6.17628 21.516 8.05836C22.2955 9.94044 22.4995 12.0114 22.1021 14.0094C21.7047 16.0074 20.7237 17.8427 19.2832 19.2832C17.8427 20.7237 16.0074 21.7047 14.0094 22.1021C12.0114 22.4995 9.94045 22.2955 8.05837 21.516C6.17629 20.7364 4.56765 19.4162 3.43587 17.7224C2.30409 16.0285 1.7 14.0371 1.7 12C1.70265 9.26908 2.78868 6.65077 4.71973 4.71972C6.65078 2.78867 9.26909 1.70265 12 1.7V1.7ZM12 0C9.62663 0 7.30655 0.703789 5.33316 2.02237C3.35977 3.34094 1.82171 5.21509 0.913457 7.4078C0.00520509 9.60051 -0.232443 12.0133 0.23058 14.3411C0.693603 16.6689 1.8365 18.8071 3.51473 20.4853C5.19296 22.1635 7.33115 23.3064 9.65893 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0V0Z"
      fillOpacity="0.2"
    />
    <path d="M10.8698 19H13.1198V16.7338H10.8698V19ZM12.0098 5.00001C11.4185 4.99868 10.8327 5.11501 10.2861 5.34231C9.73958 5.56961 9.24298 5.90342 8.82486 6.32455C8.40673 6.74569 8.07531 7.24586 7.84964 7.79635C7.62396 8.34684 7.50846 8.93681 7.50978 9.53239H9.75978C9.75978 8.93136 9.99683 8.35494 10.4188 7.92995C10.8407 7.50496 11.413 7.2662 12.0098 7.2662C12.6065 7.2662 13.1788 7.50496 13.6008 7.92995C14.0227 8.35494 14.2598 8.93136 14.2598 9.53239C14.2598 11.7986 10.8798 11.5468 10.8798 15.1928H13.1298C13.1298 12.6446 16.5098 12.3626 16.5098 9.53239C16.5111 8.93681 16.3956 8.34684 16.1699 7.79635C15.9442 7.24586 15.6128 6.74569 15.1947 6.32455C14.7766 5.90342 14.28 5.56961 13.7334 5.34231C13.1868 5.11501 12.6011 4.99868 12.0098 5.00001Z" />
    <path
      d="M10.8698 19H13.1198V16.7338H10.8698V19ZM12.0098 5.00001C11.4185 4.99868 10.8327 5.11501 10.2861 5.34231C9.73958 5.56961 9.24298 5.90342 8.82486 6.32455C8.40673 6.74569 8.07531 7.24586 7.84964 7.79635C7.62396 8.34684 7.50846 8.93681 7.50978 9.53239H9.75978C9.75978 8.93136 9.99683 8.35494 10.4188 7.92995C10.8407 7.50496 11.413 7.2662 12.0098 7.2662C12.6065 7.2662 13.1788 7.50496 13.6008 7.92995C14.0227 8.35494 14.2598 8.93136 14.2598 9.53239C14.2598 11.7986 10.8798 11.5468 10.8798 15.1928H13.1298C13.1298 12.6446 16.5098 12.3626 16.5098 9.53239C16.5111 8.93681 16.3956 8.34684 16.1699 7.79635C15.9442 7.24586 15.6128 6.74569 15.1947 6.32455C14.7766 5.90342 14.28 5.56961 13.7334 5.34231C13.1868 5.11501 12.6011 4.99868 12.0098 5.00001Z"
      fillOpacity="0.2"
    />
  </>,
  'HelpIcon',
);

const HelpIcon = styled(_HelpIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 24 24',
}))`
  fill: ${(props) => props.theme.palette.primary.main};
  height: 24px;
  width: 24px;
`;

const _ReloadIcon = createSvgIcon(
  <>
    <path d="M23.0373 3.39782C23.0297 3.34985 23.0101 3.30456 22.9804 3.26612C22.9507 3.22768 22.9118 3.19732 22.8673 3.17782C22.8257 3.15593 22.7794 3.14449 22.7323 3.14449C22.6853 3.14449 22.639 3.15593 22.5973 3.17782L20.7773 4.55782C19.151 2.61659 16.9351 1.25978 14.4667 0.693859C11.9983 0.127941 9.41264 0.383896 7.10311 1.42278C4.79358 2.46167 2.88666 4.22661 1.67254 6.44902C0.458413 8.67143 0.00355345 11.2296 0.377174 13.7343C0.750795 16.2391 1.93243 18.5532 3.74229 20.3245C5.55215 22.0958 7.89114 23.2274 10.4033 23.5471C12.9155 23.8667 15.4633 23.3569 17.6591 22.0953C19.8549 20.8336 21.5784 18.8892 22.5673 16.5578C22.6246 16.436 22.6567 16.3039 22.6618 16.1695C22.6668 16.035 22.6446 15.9009 22.5965 15.7751C22.5485 15.6494 22.4756 15.5347 22.3821 15.4379C22.2887 15.341 22.1767 15.264 22.0527 15.2115C21.9288 15.1589 21.7956 15.132 21.661 15.1322C21.5264 15.1324 21.3933 15.1597 21.2695 15.2126C21.1458 15.2655 21.0339 15.3428 20.9408 15.4399C20.8476 15.537 20.775 15.652 20.7273 15.7778C19.9143 17.712 18.4925 19.3282 16.6777 20.381C14.8628 21.4338 12.754 21.8658 10.6714 21.6113C8.58872 21.3569 6.64595 20.4299 5.13794 18.9711C3.62993 17.5124 2.63903 15.6014 2.31564 13.5284C1.99224 11.4553 2.35401 9.33335 3.34603 7.48456C4.33806 5.63577 5.90617 4.16111 7.81235 3.28441C9.71854 2.40771 11.8587 2.17683 13.908 2.62683C15.9573 3.07683 17.8038 4.18314 19.1673 5.77782L17.4073 7.10782C17.3684 7.13595 17.3377 7.17394 17.3184 7.21789C17.2991 7.26183 17.2918 7.31013 17.2973 7.35782C17.3047 7.40467 17.3243 7.44875 17.3542 7.48561C17.384 7.52248 17.423 7.55083 17.4673 7.56782L23.3273 9.94782C23.3707 9.96261 23.417 9.96654 23.4622 9.95926C23.5074 9.95199 23.5502 9.93373 23.5867 9.90609C23.6232 9.87845 23.6524 9.84228 23.6717 9.80074C23.691 9.75919 23.6998 9.71356 23.6973 9.66782L23.0373 3.39782Z" />
    <path
      d="M23.0373 3.39782C23.0297 3.34985 23.0101 3.30456 22.9804 3.26612C22.9507 3.22768 22.9118 3.19732 22.8673 3.17782C22.8257 3.15593 22.7794 3.14449 22.7323 3.14449C22.6853 3.14449 22.639 3.15593 22.5973 3.17782L20.7773 4.55782C19.151 2.61659 16.9351 1.25978 14.4667 0.693859C11.9983 0.127941 9.41264 0.383896 7.10311 1.42278C4.79358 2.46167 2.88666 4.22661 1.67254 6.44902C0.458413 8.67143 0.00355345 11.2296 0.377174 13.7343C0.750795 16.2391 1.93243 18.5532 3.74229 20.3245C5.55215 22.0958 7.89114 23.2274 10.4033 23.5471C12.9155 23.8667 15.4633 23.3569 17.6591 22.0953C19.8549 20.8336 21.5784 18.8892 22.5673 16.5578C22.6246 16.436 22.6567 16.3039 22.6618 16.1695C22.6668 16.035 22.6446 15.9009 22.5965 15.7751C22.5485 15.6494 22.4756 15.5347 22.3821 15.4379C22.2887 15.341 22.1767 15.264 22.0527 15.2115C21.9288 15.1589 21.7956 15.132 21.661 15.1322C21.5264 15.1324 21.3933 15.1597 21.2695 15.2126C21.1458 15.2655 21.0339 15.3428 20.9408 15.4399C20.8476 15.537 20.775 15.652 20.7273 15.7778C19.9143 17.712 18.4925 19.3282 16.6777 20.381C14.8628 21.4338 12.754 21.8658 10.6714 21.6113C8.58872 21.3569 6.64595 20.4299 5.13794 18.9711C3.62993 17.5124 2.63903 15.6014 2.31564 13.5284C1.99224 11.4553 2.35401 9.33335 3.34603 7.48456C4.33806 5.63577 5.90617 4.16111 7.81235 3.28441C9.71854 2.40771 11.8587 2.17683 13.908 2.62683C15.9573 3.07683 17.8038 4.18314 19.1673 5.77782L17.4073 7.10782C17.3684 7.13595 17.3377 7.17394 17.3184 7.21789C17.2991 7.26183 17.2918 7.31013 17.2973 7.35782C17.3047 7.40467 17.3243 7.44875 17.3542 7.48561C17.384 7.52248 17.423 7.55083 17.4673 7.56782L23.3273 9.94782C23.3707 9.96261 23.417 9.96654 23.4622 9.95926C23.5074 9.95199 23.5502 9.93373 23.5867 9.90609C23.6232 9.87845 23.6524 9.84228 23.6717 9.80074C23.691 9.75919 23.6998 9.71356 23.6973 9.66782L23.0373 3.39782Z"
      fillOpacity="0.2"
    />
  </>,
  'ReloadIcon',
);

const ReloadIcon = styled(_ReloadIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 24 24',
}))`
  fill: ${(props) => props.theme.palette.primary.main};
  height: 24px;
  width: 24px;
`;

const _HambugerMenuIcon = createSvgIcon(
  <>
    <rect y="3" width="24" height="2" rx="1" />
    <rect y="3" width="24" height="2" rx="1" fillOpacity="0.2" />
    <rect y="11" width="24" height="2" rx="1" />
    <rect y="11" width="24" height="2" rx="1" fillOpacity="0.2" />
    <rect y="19" width="24" height="2" rx="1" />
    <rect y="19" width="24" height="2" rx="1" fillOpacity="0.2" />
  </>,
  'HambugerMenuIcon',
);

const HambugerMenuIcon = styled(_HambugerMenuIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 24 24',
}))`
  fill: ${(props) => props.theme.palette.primary.main};
  height: 24px;
  width: 24px;
`;

interface GlobalHeaderProps {
  handleMobileDrawerToggle: () => void;
  hambugerMenuOpened: boolean;
  handleHambugerMenuToggle: () => void;
  withMobileDrawer?: boolean;
}

const GlobalHeader: React.FC<GlobalHeaderProps> = (props) => {
  const {
    handleMobileDrawerToggle,
    hambugerMenuOpened,
    handleHambugerMenuToggle,
    withMobileDrawer = true,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const intl = useIntl();

  const handleReload = () => window.location.reload();

  const handleHelp = () =>
    window.open(
      import.meta.env.MODE === 'demo'
        ? /**
           *  demo環境はmanualがないので、cabiot.jpのmanualを開く
           */
          'https://cabiot.jp/manual/ja/index.html'
        : '/manual/ja/index.html',
      '_blank',
    );

  const handleHambugerMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    handleHambugerMenuToggle();
    isSmallScreen && withMobileDrawer
      ? handleMobileDrawerToggle()
      : setAnchorEl(event.currentTarget);
  };

  const handleHambugerMenuClose = () => {
    if (anchorEl) {
      setAnchorEl(null);
      handleHambugerMenuToggle();
    }
  };

  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenuEl(null);
  };

  const handleClickLink = () => {
    window.open(FAQ_PAGE_URL);
  };

  return (
    <AppBar
      position="fixed"
      sx={(theme) => ({
        height: theme.mixins.toolbar.minHeight,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid red',
        boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.1)',
      })}
    >
      <Box ml={4} my={1.75}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <CabiotIcon />
        </Link>
      </Box>
      <Box display="flex" my={0.5} mr={1.5}>
        <Tooltip
          isArrow={true}
          title={intl.formatMessage({ id: 'common.reload' })}
        >
          <IconButton onClick={handleReload} size="large">
            <ReloadIcon />
          </IconButton>
        </Tooltip>
        <AnnounceIconButton />
        <Tooltip
          data-testid="HelpMenuIconClick"
          isArrow={true}
          title={intl.formatMessage({ id: 'common.help' })}
        >
          <IconButton onClick={handleClick} size="large">
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorMenuEl}
          open={Boolean(anchorMenuEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          css={menuStyle}
        >
          <MenuItem sx={{ height: 44 }} onClick={handleHelp}>
            {intl.formatMessage({ id: `globalHeader.help.manual.title` })}
          </MenuItem>
          <MenuItem sx={{ height: 44 }} onClick={handleClickLink}>
            {intl.formatMessage({ id: `globalHeader.help.faq.title` })}
          </MenuItem>
        </Menu>

        <Tooltip
          data-testid="HamburgerMenuButton"
          isArrow={true}
          title={intl.formatMessage({ id: 'common.menu' })}
        >
          <IconButton onClick={handleHambugerMenuOpen} size="large">
            {hambugerMenuOpened ? (
              <HambugerMenuCloseIcon />
            ) : (
              <HambugerMenuIcon />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <HambugerMenu
        anchorEl={anchorEl}
        handleHambugerMenuClose={handleHambugerMenuClose}
      />
    </AppBar>
  );
};

export default GlobalHeader;
