import React from 'react';

const HambugerMenuCloseIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70711 0.492905C4.31658 0.102381 3.68342 0.102381 3.29289 0.492905C2.90237 0.88343 2.90237 1.5166 3.29289 1.90712L10.5858 9.20001L3.70711 16.0787C3.31658 16.4692 3.31658 17.1024 3.70711 17.4929C4.09763 17.8834 4.7308 17.8834 5.12132 17.4929L12 10.6142L18.8492 17.4635C19.2398 17.854 19.8729 17.854 20.2635 17.4635C20.654 17.0729 20.654 16.4398 20.2635 16.0493L13.4142 9.20001L20.6777 1.93656C21.0682 1.54603 21.0682 0.912868 20.6777 0.522343C20.2871 0.131819 19.654 0.131819 19.2635 0.522344L12 7.7858L4.70711 0.492905Z"
        fill="#F05064"
      />
    </svg>
  );
};

export default HambugerMenuCloseIcon;
