import React from 'react';
import IconButton from '@mui/material/IconButton';
import ObserveModeIcon from 'src/domains/root/commons/icons/ObserveModeIcon';

interface ChartLinkButtonProps {
  fullHeight?: boolean;
  bgColor?: string;
  /** MUIの単位を使う 1 === 8px */
  p?: string | number;
  width?: string | number;
  height?: string | number;
  observeMode: string;
  onClickAlertChartButton: () => void;
}

const ChartLinkButton: React.FC<ChartLinkButtonProps> = (props) => {
  const {
    fullHeight = false,
    bgColor,
    p,
    width,
    height,
    observeMode,
    onClickAlertChartButton,
  } = props;

  const IconButtonHeight = fullHeight ? '100%' : height;

  return (
    <IconButton
      data-testid="SensorUnitGraphButton"
      onClick={onClickAlertChartButton}
      size="large"
      sx={{
        p: p,
        width: width,
        height: IconButtonHeight,
        bgcolor: bgColor,
      }}
    >
      <ObserveModeIcon mode={observeMode} />
    </IconButton>
  );
};

export default ChartLinkButton;
