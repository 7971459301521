import axios from 'src/utils/api';
import { handleError } from './error';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type GetServiceProviderWorkspacesResponse = {
  workspaces: {
    workspaceId: string;
    workspaceName: string;
  }[];
};
export async function getServiceProviderWorkspaces({
  token,
}: {
  token: string;
}): Promise<GetServiceProviderWorkspacesResponse> {
  return axios
    .get(`${API_ENDPOINT}/serviceProvider/workspaces`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
