import styled from 'styled-components';
import BaseButton, { BaseButtonProps } from './Base';

export type NativeButtonProps = Omit<BaseButtonProps, 'variant'>;

const NegativeButton = styled(BaseButton).attrs<NativeButtonProps>(() => ({
  variant: 'outlined',
}))`
  background: #e6e6e6;
  border-color: #5a5a5a;
  color: #5a5a5a;

  :hover {
    background: #5a5a5a;
    border-color: #5a5a5a;
    color: ${(props) => props.theme.palette.common.white};
  }

  :disabled {
    background: #e6e6e6;
    border-color: #5a5a5a;
    color: #5a5a5a;
  }
`;

export default NegativeButton;
