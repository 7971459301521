import React from 'react';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import AlertListTableContainer from '../features/alerts/lists/AlertListTableContainer';
import AlertRestoreDialog from '../features/alerts/lists/AlertRestoreDialog';

const CurrentAlert: React.FC = () => {
  return (
    <MainContents noXPaddingIfMobile notAllowOverflow showContractExpiredAlert>
      <AlertListTableContainer />
      <AlertRestoreDialog />
    </MainContents>
  );
};

// ページタイトルの取得のために設定
CurrentAlert.displayName = 'CurrentAlert';
export default CurrentAlert;
