import { useEffect, useState } from 'react';
import { AlertHistory } from 'src/domains/root/features/alerts/histories/slice';

export interface Dropdown {
  [key: string]: string[];
}

interface AlertHistoriesDropdownState {
  sensorUnitNames: string[];
  gatewayNames: string[];
  sensorUnitDropdown: Dropdown;
  gatewayDropdown: Dropdown;
}

const gatewayNames = (alertHistories: AlertHistory[]) => {
  return Array.from(
    new Set(alertHistories.map((alertHistory) => alertHistory.gatewayName)),
  );
};

const sensorUnitNames = (alertHistories: AlertHistory[]) => {
  return Array.from(
    new Set(alertHistories.map((alertHistory) => alertHistory.sensorUnitName)),
  );
};

const makeGatewayDropdown = (alertHistories: AlertHistory[]) => {
  return alertHistories.reduce((acc, alertHitory) => {
    if (!acc[alertHitory.sensorUnitName]) acc[alertHitory.sensorUnitName] = [];

    if (!acc[alertHitory.sensorUnitName].includes(alertHitory.gatewayName))
      acc[alertHitory.sensorUnitName].push(alertHitory.gatewayName);

    return acc;
  }, {} as Dropdown);
};

const makeSensorUnitDropdown = (alertHistories: AlertHistory[]) => {
  return alertHistories.reduce((acc, alertHitory) => {
    if (!acc[alertHitory.gatewayName]) acc[alertHitory.gatewayName] = [];

    if (!acc[alertHitory.gatewayName].includes(alertHitory.sensorUnitName))
      acc[alertHitory.gatewayName].push(alertHitory.sensorUnitName);

    return acc;
  }, {} as Dropdown);
};

const useAlertHistoriesDropdown = (
  alertHistories: AlertHistory[],
): AlertHistoriesDropdownState => {
  const [state, setState] = useState<AlertHistoriesDropdownState>({
    gatewayNames: [],
    sensorUnitNames: [],
    gatewayDropdown: {},
    sensorUnitDropdown: {},
  });

  useEffect(() => {
    (async () => {
      if (alertHistories.length !== 0) {
        setState({
          ...state,
          gatewayNames: gatewayNames(alertHistories),
          sensorUnitNames: sensorUnitNames(alertHistories),
          gatewayDropdown: makeGatewayDropdown(alertHistories),
          sensorUnitDropdown: makeSensorUnitDropdown(alertHistories),
        });
      }
    })();
  }, [alertHistories]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...state };
};

export default useAlertHistoriesDropdown;
