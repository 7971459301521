import React from 'react';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';

const CustomDialogContent: React.FC<DialogContentProps> = (props) => {
  const { sx = [] } = props;

  return (
    <DialogContent
      dividers={true}
      sx={[
        {
          '&.MuiDialogContent-dividers': {
            borderBottomColor: 'rgba(130, 130, 130, 0.5)',
            borderTopColor: 'rgba(130, 130, 130, 0.5)',
          },
          padding: (theme) => `${theme.spacing(2.5)} ${theme.spacing(3)}`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {props.children}
    </DialogContent>
  );
};

export default CustomDialogContent;
