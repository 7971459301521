import React from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';

interface Values {
  privacyPolicy: boolean;
}

type Props = {
  isSmallScreen: boolean;
};

const PrivacyPolicy: React.FC<Props> = (props: Props) => {
  const { isSmallScreen } = props;
  const intl = useIntl();
  const { values, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({
      ...touched,
      privacyPolicy: true,
    });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid="PrivacyPolicyCheckbox"
          checked={values.privacyPolicy}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          name="privacyPolicy"
          sx={{
            color: 'primary.main',
          }}
        />
      }
      label={
        <Typography variant="body2">
          <Box
            component="span"
            fontWeight="bold"
            fontSize={isSmallScreen ? 13 : 15}
          >
            <Link
              underline="always"
              href="https://www.nito.co.jp/privacy/"
              target="_blank"
            >
              {intl.formatMessage({
                id: 'users.new.agreement.privacyPolicy',
              })}
            </Link>
            {intl.formatMessage({
              id: 'users.new.agreement.agree',
            })}
          </Box>
        </Typography>
      }
    />
  );
};

export default PrivacyPolicy;
