import React from 'react';
import {
  PowerSupply,
  SensorUnitBattery,
} from 'src/domains/root/features/gateways/slice';
import { TableCellProps } from '@mui/material/TableCell';
import { Box } from '@mui/material';
import BatteryIcon from './battery/BatteryIcon';

interface Props extends TableCellProps {
  batteryOnTop?: boolean;
  powerSupply: PowerSupply | undefined;
  battery: SensorUnitBattery | undefined;
}

const SensorUnitName: React.FC<Props> = (props) => {
  const {
    batteryOnTop = false,
    powerSupply,
    battery,
    children,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '21px',
        alignItems: 'center',
      }}
      {...rest}
    >
      <Box
        display="flex"
        pt={batteryOnTop ? 1 : undefined}
        height={batteryOnTop ? '100%' : undefined}
      >
        <BatteryIcon powerSupply={powerSupply} battery={battery} />
      </Box>
      {children}
    </Box>
  );
};

export default SensorUnitName;
