import React from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { createSvgIcon } from '@mui/material/utils';
import AccessControl from 'src/domains/root/commons/AccessControl';
import FeatureFlag, {
  useCheckFeatureFlag,
} from 'src/utils/feature-flag/FeatureFlag';
import SettingGroupBox from './SettingGroupBox';
import SettingGroupBoxItem from './SettingGroupBoxItem';

const Users: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const isFlagEnabled = useCheckFeatureFlag('contract.payment-method');

  return (
    <SettingGroupBox
      icon={<HandshakeIcon />}
      title={intl.formatMessage({
        id: 'pages.OtherSetting.contracts.title',
      })}
      description={
        <AccessControl permit={(_, role) => role === 'admin'}>
          {(permitted) =>
            intl.formatMessage({
              id:
                isFlagEnabled && permitted
                  ? 'pages.OtherSetting.contracts.paymentMethodFeatureDescription'
                  : permitted
                    ? 'pages.OtherSetting.contracts.description'
                    : 'pages.OtherSetting.contracts.normalUserDescription',
            })
          }
        </AccessControl>
      }
    >
      <AccessControl permit={(_, role) => role === 'admin'}>
        {(permitted) => (
          <SettingGroupBoxItem
            onClickButton={() => navigate('contracts')}
            buttonText={intl.formatMessage({
              id: permitted
                ? 'pages.OtherSetting.contracts.list.title'
                : 'pages.OtherSetting.contracts.list.normalUserTitle',
            })}
            descriptions={
              isFlagEnabled && permitted
                ? [
                    intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.list.subDescription.updateContract',
                    }),
                    intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.list.subDescription.paymentHistory',
                    }),
                  ]
                : undefined
            }
          />
        )}
      </AccessControl>

      <FeatureFlag flag="contract.payment-method">
        <AccessControl permit={(_, role) => role === 'admin'}>
          {(permitted) =>
            permitted ? (
              <SettingGroupBoxItem
                onClickButton={() => navigate('contracts/payment-method')}
                buttonText={intl.formatMessage({
                  id: 'pages.OtherSetting.contracts.payment-method.title',
                })}
                descriptions={[
                  intl.formatMessage({
                    id: 'pages.OtherSetting.contracts.payment-method.subDescription.enableInvoice',
                  }),
                ]}
              />
            ) : null
          }
        </AccessControl>
      </FeatureFlag>
    </SettingGroupBox>
  );
};

export default Users;

const _HandshakeIcon = createSvgIcon(
  <>
    <path
      d="M38.33,15.11a2.16,2.16,0,0,0-.73-1.27,2.21,2.21,0,0,0-1.36-.54c-2.49-.1-9.75,1-11.39.75-2-.32-4.81,1.35-8.43,4.55l-.32.25a21.87,21.87,0,0,0-4,4.31,2.23,2.23,0,0,0-.39,1.39,2.27,2.27,0,0,0,.56,1.33L19,33.52a2.18,2.18,0,0,0,1.16.68c2.18.46,5.43-1.9,7.65-3.37a37.81,37.81,0,0,0,4.8-3.94c1.3-1.27,5.23-3.53,6-4.13a2.26,2.26,0,0,0,.67-1,2.18,2.18,0,0,0,.08-1.16Z"
      fill="white"
    />
    <path
      d="M39,15A2.85,2.85,0,0,0,38,13.37a2.9,2.9,0,0,0-1.74-.68,55.37,55.37,0,0,0-5.75.41,38.26,38.26,0,0,1-5.56.35c-2.13-.34-4.89,1.11-8.93,4.64a1,1,0,0,1-.24.17,22.77,22.77,0,0,0-4.18,4.48,2.89,2.89,0,0,0-.5,1.78,2.77,2.77,0,0,0,.72,1.7l6.77,7.64a2.73,2.73,0,0,0,1.55.88h.45c2.18.16,4.89-1.67,7.1-3.09l.56-.37a38.42,38.42,0,0,0,4.89-4,34.44,34.44,0,0,1,4.11-2.88c.85-.54,1.55-1,1.81-1.2a2.83,2.83,0,0,0,1-2.71Zm-.72,7.3c-.23.18-.94.63-1.68,1.11a32,32,0,0,0-4.32,3.09,38.23,38.23,0,0,1-4.64,3.87l-.57.38C25,32.13,22.18,34,20.43,33.66a1.63,1.63,0,0,1-.84-.51l-6.76-7.62a1.56,1.56,0,0,1-.42-1,1.53,1.53,0,0,1,.3-1,23.14,23.14,0,0,1,3.82-4.15,2.27,2.27,0,0,0,.4-.29c3.65-3.23,6.19-4.64,7.94-4.41a33.79,33.79,0,0,0,5.86-.34,52.15,52.15,0,0,1,5.59-.4,1.6,1.6,0,0,1,1,.36,1.57,1.57,0,0,1,.53.94l1,5.54a1.48,1.48,0,0,1-.08.83,1.64,1.64,0,0,1-.5.67Z"
      fill="#505050"
    />
    <path
      d="M27,17.73c-4.24-3.26-6.93-5.27-9-5.55-3.1-.4-8.9-.63-8.9-.63l-4.89,11a37.71,37.71,0,0,1,6,5.72,17.41,17.41,0,0,0,4.46,3.29,49.28,49.28,0,0,0,6.46,3.43,4.51,4.51,0,0,0,1.6.82,4.69,4.69,0,0,0,1.79.13,2,2,0,0,0,1.55-2,2.54,2.54,0,0,0,1.47.39,2.59,2.59,0,0,0,1.44-.51,1.73,1.73,0,0,0,.67-.88,1.79,1.79,0,0,0,0-1.12A2.46,2.46,0,0,0,31,32a2.49,2.49,0,0,0,1.23-.57,2.13,2.13,0,0,0,.4-2.75,2.19,2.19,0,0,0,1.43-.3,2.29,2.29,0,0,0,.92-1.14,2.83,2.83,0,0,0,0-1.68A2.92,2.92,0,0,0,34,24.15S28.47,18.88,27,17.73Z"
      fill="white"
    />
    <path
      d="M35.59,25.59a3.36,3.36,0,0,0-1.07-1.67c-.23-.22-5.66-5.4-7.21-6.56C22.87,13.93,20.24,12,18,11.69c-3.11-.4-8.71-.63-8.94-.63a.6.6,0,0,0-.35.08.57.57,0,0,0-.24.27l-4.89,11a.69.69,0,0,0,0,.4.64.64,0,0,0,.23.33,38.13,38.13,0,0,1,5.87,5.63,15.77,15.77,0,0,0,3.79,2.9l.77.47c1.24.74,2.47,1.47,3.76,2.13.65.33,1.31.64,2,1l1,.5c.28.14.56.3.85.42l.14.06a6.41,6.41,0,0,0,2,.43,2.77,2.77,0,0,0,.95-.09,2.74,2.74,0,0,0,.83-.47A2.67,2.67,0,0,0,26.54,35,3.42,3.42,0,0,0,28,35a3.49,3.49,0,0,0,1.34-.58,2.67,2.67,0,0,0,.68-.74,2.54,2.54,0,0,0,.32-1A2.72,2.72,0,0,0,32.63,32a2.52,2.52,0,0,0,.8-1.18,2.58,2.58,0,0,0,0-1.43,2.92,2.92,0,0,0,1.27-.66,3,3,0,0,0,.82-1.17A3.37,3.37,0,0,0,35.59,25.59Zm-1.18,1.47a1.53,1.53,0,0,1-.64.8,1.59,1.59,0,0,1-1,.22.63.63,0,0,0-.6.2.69.69,0,0,0-.14.3.62.62,0,0,0,0,.33c.32.82.48,1.38-.27,2.07a1.84,1.84,0,0,1-.92.41,1.78,1.78,0,0,1-1-.16.74.74,0,0,0-.36,0,.73.73,0,0,0-.31.17.65.65,0,0,0-.16.32.62.62,0,0,0,0,.35,1.24,1.24,0,0,1-.05.71,1.16,1.16,0,0,1-.43.57,2,2,0,0,1-1.05.38,3,3,0,0,1-1.86-.7l-2-1.26L22.56,31a.53.53,0,0,0-.22-.08.59.59,0,0,0-.23,0,.55.55,0,0,0-.22.1.44.44,0,0,0-.16.17.62.62,0,0,0-.09.46.68.68,0,0,0,.27.39l3.51,2.23a1.19,1.19,0,0,1-.26.57,1.29,1.29,0,0,1-.8.46,3.78,3.78,0,0,1-1.57-.12,6,6,0,0,1-.9-.39c-.29-.12-.53-.33-.82-.45L20.28,34c-.53-.25-1.05-.51-1.57-.78-1.28-.65-2.52-1.38-3.74-2.13l-.84-.5a14.57,14.57,0,0,1-3.51-2.69A39,39,0,0,0,4.91,22.3L9.44,12.14c1.36.06,5.85.28,8.42.62,1.9.23,4.55,2.21,8.71,5.42,1.42,1.09,6.85,6.23,7.1,6.47a2.31,2.31,0,0,1,.75,1.09A2.2,2.2,0,0,1,34.41,27.06Z"
      fill="#505050"
    />
    <path
      d="M29.69,32.45a.63.63,0,0,1-.37-.12l-5.09-4.1a.64.64,0,0,1-.22-.41.6.6,0,0,1,.13-.45.55.55,0,0,1,.18-.16.88.88,0,0,1,.23-.07.58.58,0,0,1,.23,0,.7.7,0,0,1,.21.11l5.09,4.1a.61.61,0,0,1,.23.42.62.62,0,0,1-.14.45.65.65,0,0,1-.21.16A.81.81,0,0,1,29.69,32.45Z"
      fill="#505050"
    />
    <path
      d="M32.83,29.64a.56.56,0,0,1-.4-.17l-5.1-4.64a.56.56,0,0,1-.14-.2.59.59,0,0,1-.05-.23.69.69,0,0,1,.05-.24.52.52,0,0,1,.14-.19.6.6,0,0,1,.86,0L33.3,28.6a.66.66,0,0,1,.17.44.63.63,0,0,1-.17.43A.71.71,0,0,1,32.83,29.64Z"
      fill="#505050"
    />
    <path
      d="M39,11l-3.72.72a6.55,6.55,0,0,1-2.75-.12l-8.73-2a4.84,4.84,0,0,0-3.09.37l-3.51,1.1c-.56.23-1.43.46-2.09.8a2,2,0,0,0-.64.51,2.17,2.17,0,0,0-.4.71l-.62,2.07a1.58,1.58,0,0,1-.31.62,3.94,3.94,0,0,1-.49.59l-1.32,1.47a1.11,1.11,0,0,0,0,1.54c.55.59,1.66,1,3.87-.15a8.36,8.36,0,0,0,2-1.7c.69-.67.86-.88,1.47-1l2.12-.56a.71.71,0,0,1,.54.11,12.82,12.82,0,0,0,9.47,1.55Z"
      fill="white"
    />
    <path
      d="M12.75,20.5a2.53,2.53,0,0,1-1-.18,2.59,2.59,0,0,1-.86-.59,1.72,1.72,0,0,1,0-2.38l1.3-1.46a3,3,0,0,0,.41-.51A1.35,1.35,0,0,0,12.8,15l.62-2.07a2.71,2.71,0,0,1,1.35-1.55,10.55,10.55,0,0,1,1.45-.55l.7-.25,3.46-1.1A9.28,9.28,0,0,1,22,9a4.54,4.54,0,0,1,1.8,0l8.72,2a6.33,6.33,0,0,0,2.49.11l1.89-.3a.64.64,0,0,1,.7.51.65.65,0,0,1,0,.24.88.88,0,0,1-.1.22.69.69,0,0,1-.18.16.63.63,0,0,1-.23.08l-1.87.29a7.48,7.48,0,0,1-3-.12l-8.57-2a4.43,4.43,0,0,0-2.71.35L17.46,11.6l-.75.26a8.24,8.24,0,0,0-1.28.49,1.47,1.47,0,0,0-.46.36,1.56,1.56,0,0,0-.27.51l-.62,2.07a2.68,2.68,0,0,1-.4.79,3.73,3.73,0,0,1-.54.65l-1.31,1.45a.49.49,0,0,0-.15.35.47.47,0,0,0,.15.35c.69.74,2.07.28,3.09-.26a6.91,6.91,0,0,0,1.55-1.3l.31-.33.18-.17a3.1,3.1,0,0,1,1.55-1l2.12-.54a1.3,1.3,0,0,1,.52,0,1.18,1.18,0,0,1,.48.19A12.09,12.09,0,0,0,30.2,17a.65.65,0,0,1,.24,0,.88.88,0,0,1,.22.1.69.69,0,0,1,.16.18.58.58,0,0,1,.08.22.65.65,0,0,1,0,.24.62.62,0,0,1-.1.22.69.69,0,0,1-.18.16.53.53,0,0,1-.22.08A13.28,13.28,0,0,1,21,16.54h-.11l-2.1.55c-.37.09-.48.18-1,.69l-.2.17-.25.26a7.62,7.62,0,0,1-1.84,1.55A6,6,0,0,1,12.75,20.5Z"
      fill="#505050"
    />
    <path
      d="M19.31,29.14a2.27,2.27,0,0,1,1.07-.54,2.1,2.1,0,0,1,1.19.1,1.41,1.41,0,0,1,.52.26,1.47,1.47,0,0,1,.37.46,1.59,1.59,0,0,1,.14.57,1.62,1.62,0,0,1-.11.58s-1.35,2.67-1.38,2.75a1.79,1.79,0,0,1-.34.62,2,2,0,0,1-.56.44,1.83,1.83,0,0,1-.69.2,1.69,1.69,0,0,1-.7-.09,1.57,1.57,0,0,1-.94-.53,1.65,1.65,0,0,1-.39-1,1.94,1.94,0,0,1-1.11.7,2,2,0,0,1-1.31-.19,1.59,1.59,0,0,1-.82-.67,1.57,1.57,0,0,1-.24-1,1.89,1.89,0,0,1-1.16.44,1.81,1.81,0,0,1-1.18-.37c-.83-.49-1.22-.85-1.08-1.77a2.11,2.11,0,0,1-1.22,0,2.19,2.19,0,0,1-1-.72A2.08,2.08,0,0,1,8.25,27l1-1.31a1.94,1.94,0,0,1,1-.62,2,2,0,0,1,1.2.09c.68.34,1.47,1,1.36,1.55a2.34,2.34,0,0,1,1-.63A2.29,2.29,0,0,1,15,26a1.54,1.54,0,0,1,1,.67,1.52,1.52,0,0,1,.22,1.14,1.84,1.84,0,0,1,.94-.45,1.93,1.93,0,0,1,1,.14,1.78,1.78,0,0,1,1.11,1.65Z"
      fill="white"
    />
    <path
      d="M19.41,35.19a2.41,2.41,0,0,1-.76-.11,2.5,2.5,0,0,1-1.47-1,2.9,2.9,0,0,1-1.16.24,2.87,2.87,0,0,1-1.16-.24,2.43,2.43,0,0,1-.82-.52,2.28,2.28,0,0,1-.53-.8,2.45,2.45,0,0,1-1.11.07,2.61,2.61,0,0,1-1-.41,2.4,2.4,0,0,1-.86-.62,2.71,2.71,0,0,1-.52-.92A3.1,3.1,0,0,1,8,29.92a2.62,2.62,0,0,1-.71-1.54,2.66,2.66,0,0,1,.38-1.65l1-1.31a2.45,2.45,0,0,1,1.36-.81,2.53,2.53,0,0,1,1.58.16,4.2,4.2,0,0,1,1.36,1.08,2.38,2.38,0,0,1,1-.38,2.45,2.45,0,0,1,2,.63,2.35,2.35,0,0,1,.57.86,3.06,3.06,0,0,1,1.67.1,2.19,2.19,0,0,1,.78.49,2.1,2.1,0,0,1,.52.76,3.39,3.39,0,0,1,2.09,0,2,2,0,0,1,.73.4,2.09,2.09,0,0,1,.51.67,1.93,1.93,0,0,1,0,1.54c-.5,1-1.27,2.52-1.36,2.72a2.47,2.47,0,0,1-.86,1.1A2.44,2.44,0,0,1,19.41,35.19Zm-1.92-2.83h.14a.65.65,0,0,1,.32.19.64.64,0,0,1,.14.35c0,.62.34.82.91,1a1.18,1.18,0,0,0,.95-.06,1.36,1.36,0,0,0,.38-.3,1.17,1.17,0,0,0,.22-.43,29.06,29.06,0,0,1,1.39-2.8.78.78,0,0,0,0-.6.77.77,0,0,0-.23-.28.86.86,0,0,0-.32-.15,1.62,1.62,0,0,0-1.55.2.57.57,0,0,1-.3.23.62.62,0,0,1-.38,0,.68.68,0,0,1-.31-.22.65.65,0,0,1-.12-.36,1.3,1.3,0,0,0-.19-.66A1.22,1.22,0,0,0,18,28a1.4,1.4,0,0,0-.67-.11,1.26,1.26,0,0,0-.64.24.59.59,0,0,1-.33.2.62.62,0,0,1-.38,0,.64.64,0,0,1-.36-.64,1,1,0,0,0-.15-.73,1,1,0,0,0-.62-.43c-.78-.28-1.55.53-1.55.53a.57.57,0,0,1-.34.18.59.59,0,0,1-.39-.06.65.65,0,0,1-.26-.26.61.61,0,0,1-.06-.37,2.46,2.46,0,0,0-1-.88A1.31,1.31,0,0,0,9.75,26l-1,1.26a1.41,1.41,0,0,0-.22.89,1.43,1.43,0,0,0,.37.83c.41.4,1.09.67,1.38.51a.71.71,0,0,1,.32-.07.68.68,0,0,1,.31.11.56.56,0,0,1,.21.25.65.65,0,0,1,.06.33c-.08.57,0,.73.8,1.17a1.27,1.27,0,0,0,1.64,0,.69.69,0,0,1,.68,0,.74.74,0,0,1,.24.26.7.7,0,0,1,.06.35,1,1,0,0,0,.16.64,1,1,0,0,0,.52.41c.75.28,1.4.18,1.68-.25a.55.55,0,0,1,.2-.25A.56.56,0,0,1,17.49,32.36Z"
      fill="#505050"
    />
    <path
      d="M17.49,33.61a.66.66,0,0,1-.28,0,.63.63,0,0,1-.32-.36.69.69,0,0,1,0-.49l1.82-3.83a.67.67,0,0,1,.37-.27.65.65,0,0,1,.79.82l-1.82,3.84a.78.78,0,0,1-.26.22A.55.55,0,0,1,17.49,33.61Z"
      fill="#505050"
    />
    <path
      d="M14,32.38a.61.61,0,0,1-.3-.08.65.65,0,0,1-.18-.15.53.53,0,0,1-.11-.21.62.62,0,0,1,0-.23.48.48,0,0,1,.07-.23l2.22-4a.65.65,0,0,1,.15-.18.71.71,0,0,1,.21-.11.65.65,0,0,1,.24,0,.58.58,0,0,1,.22.08.7.7,0,0,1,.19.15.53.53,0,0,1,.11.21.58.58,0,0,1,0,.23.62.62,0,0,1-.07.23l-2.23,4a.6.6,0,0,1-.22.23A.64.64,0,0,1,14,32.38Z"
      fill="#505050"
    />
    <path
      d="M10.59,30.69a.6.6,0,0,1-.34-.11A.57.57,0,0,1,10,30.2a.59.59,0,0,1,.09-.45l2.27-3.43a.59.59,0,0,1,.38-.26.62.62,0,0,1,.46.09.67.67,0,0,1,.26.39.63.63,0,0,1-.09.46L11.1,30.41a.63.63,0,0,1-.22.21A.59.59,0,0,1,10.59,30.69Z"
      fill="#505050"
    />
    <path
      d="M34.93,8.66l1.2-.44,1.57-.57a.47.47,0,0,1,.35,0,.44.44,0,0,1,.24.26L43.78,23a.45.45,0,0,1,0,.36.51.51,0,0,1-.26.24l-3.09,1.12a1.56,1.56,0,0,1-2-.93L33.68,10.73a1.58,1.58,0,0,1,0-1.19,1.5,1.5,0,0,1,.64-.68Z"
      fill="#505050"
    />
    <path
      d="M9.07,8.66l-1.2-.44L6.3,7.65a.47.47,0,0,0-.35,0,.44.44,0,0,0-.24.26L.22,23a.45.45,0,0,0,0,.36.51.51,0,0,0,.26.24l3.09,1.12a1.56,1.56,0,0,0,2-.93l4.76-13.07a1.58,1.58,0,0,0-.05-1.19,1.5,1.5,0,0,0-.64-.68Z"
      fill="#505050"
    />
  </>,
  'Handshake',
);

const HandshakeIcon = styled(_HandshakeIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 44 44',
}))`
  fill: none;
  height: 44px;
  width: 44px;
`;
