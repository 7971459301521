import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { Result, RootState } from 'src/domains/root/store';
import { RootError, RootErrorResponse } from '../../../utils/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type WorkspacesStripeCustomerErrorCode =
  | GetWorkspacesStripeCustomerErrorCode
  | PutWorkspacesStripeCustomerErrorCode;

interface GetWorkspacesStripeCustomerParams {
  token: Promise<string>;
  workspaceId: string;
}
interface GetWorkspacesStripeCustomerResponse {
  name: string;
}

export type GetWorkspacesStripeCustomerErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getWorkspacesStripeCustomer = createAsyncThunk<
  GetWorkspacesStripeCustomerResponse,
  GetWorkspacesStripeCustomerParams,
  {
    state: RootState;
    rejectValue: RootError<GetWorkspacesStripeCustomerErrorCode>;
  }
>('workspaces/GetWorkspacesStripeCustomer', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId } = params;

  try {
    const response = await axios.get<GetWorkspacesStripeCustomerResponse>(
      `${API_ENDPOINT}/workspaces/${workspaceId}/stripe-customer`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    return thunkAPI.rejectWithValue(getGetWorkspacesStripeCustomerError(error));
  }
});

const getGetWorkspacesStripeCustomerError = (
  error: AxiosError<RootErrorResponse>,
): RootError<GetWorkspacesStripeCustomerErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: true,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: true,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

interface PutWorkspacesStripeCustomerParams {
  token: Promise<string>;
  workspaceId: string;
  name: string;
}

type PutWorkspacesStripeCustomerErrorCode =
  | 'invalid_name'
  | 'permission_denied'
  | 'admin_only'
  | 'emergency_maintenance'
  | 'unknown_error';

export const putWorkspacesStripeCustomer = createAsyncThunk<
  void,
  PutWorkspacesStripeCustomerParams,
  {
    state: RootState;
    rejectValue: RootError<PutWorkspacesStripeCustomerErrorCode>;
  }
>('workspaces/PutWorkspacesStripeCustomer', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId, name } = params;
  const body = { name };

  try {
    const response = await axios.put<void>(
      `${API_ENDPOINT}/workspaces/${workspaceId}/stripe-customer`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    return thunkAPI.rejectWithValue(putGetWorkspacesStripeCustomerError(error));
  }
});

const putGetWorkspacesStripeCustomerError = (
  error: AxiosError<RootErrorResponse>,
): RootError<PutWorkspacesStripeCustomerErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 400:
        return {
          code: 'invalid_name',
          noticeable: true,
          recoverable: true,
        };
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: true,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: true,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface WorkspacesStripeCustomerState {
  getResult: Result<
    GetWorkspacesStripeCustomerResponse,
    RootError<GetWorkspacesStripeCustomerErrorCode>
  >;
  updateResult: Result<void, RootError<PutWorkspacesStripeCustomerErrorCode>>;
}

const initialState: WorkspacesStripeCustomerState = {
  getResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  updateResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const WorkspacesStripeCustomerSlice = createSlice({
  name: 'workspaces/stripe-customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspacesStripeCustomer.pending, (state) => {
        state.getResult.data = null;
        state.getResult.status = 'loading';
        state.getResult.error = null;
      })
      .addCase(getWorkspacesStripeCustomer.fulfilled, (state, action) => {
        state.getResult.data = action.payload;
        state.getResult.status = 'succeeded';
        state.getResult.error = null;
      })
      .addCase(getWorkspacesStripeCustomer.rejected, (state, action) => {
        state.getResult.data = null;
        state.getResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.getResult.error = action.payload!;
      })
      .addCase(putWorkspacesStripeCustomer.pending, (state) => {
        state.updateResult.data = null;
        state.updateResult.status = 'loading';
        state.updateResult.error = null;
      })
      .addCase(putWorkspacesStripeCustomer.fulfilled, (state, action) => {
        state.updateResult.data = action.payload;
        state.updateResult.status = 'succeeded';
        state.updateResult.error = null;
      })
      .addCase(putWorkspacesStripeCustomer.rejected, (state, action) => {
        state.updateResult.data = null;
        state.updateResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.updateResult.error = action.payload!;
      });
  },
});

export const getResultSelector = createSelector(
  (state: RootState) => state.workspacesStripeCustomer,
  (state: WorkspacesStripeCustomerState) => state.getResult,
);

export const updateResultSelector = createSelector(
  (state: RootState) => state.workspacesStripeCustomer,
  (state: WorkspacesStripeCustomerState) => state.updateResult,
);

export default WorkspacesStripeCustomerSlice.reducer;
