import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import { useTheme } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AccessControl from 'src/domains/root/commons/AccessControl';
import SettingIcon from 'src/domains/root/commons/layout/drawer/icons/SettingIcon';
import CardNumber from 'src/domains/root/commons/cards/Number';
import CardPaper from 'src/domains/root/commons/cards/Paper';
import ArrowUpwardIcon from 'src/domains/root/features/sensor-units/icons/ArrowUpwardIcon';
import ArrowDownwardIcon from 'src/domains/root/features/sensor-units/icons/ArrowDownwardIcon';
import ChartLinkButton from 'src/domains/root/commons/ChartLinkButton';
import { updateTargetAlert } from 'src/domains/root/features/alerts/slice';
import * as API from 'src/apis';
import Chip from '../../gateways/data/ChipV3';
import UnitizedValue from '../../sensor-units/UnitizedValue';
import AlertListMenu from './AlertListMenu';

type Props = {
  gatewayName: string;
  sensorUnit: API.AlertSensorUnits;
  onClickAlertChartButton: () => void;
};

/** 全体的に使っているbreakpointsではないが、レイアウトが崩れるので対応 */
const smallMobileWidth = 340;

const bgColor: { [type in API.AlertType]: string } = {
  temperature: '#FAF5BE',
  humidity: '#C8FAFF',
  objectTemperature: '#F5C8C8',
  condensationAlertTemperature: '#C8CAFF',
};

const AlertListCard = (props: Props) => {
  const { gatewayName, sensorUnit, onClickAlertChartButton } = props;

  const dispatch: AppDispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(updateTargetAlert({ gatewayName, sensorUnit }));
  };

  return (
    <>
      <CardNumber number={sensorUnit.number} />
      <CardPaper bgColor={bgColor[sensorUnit.alertType]}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ overflowWrap: 'anywhere' }}
        >
          <Typography
            display="flex"
            alignItems="center"
            fontSize={(theme) => theme.typography.pxToRem(14)}
          >
            {sensorUnit.name}
          </Typography>
          <Box display="flex" pl={2} height="100%" gap={1.5}>
            <ChartLinkButton
              fullHeight
              p={0.5}
              width={32}
              height={32}
              bgColor="white"
              observeMode={sensorUnit.observe.mode}
              onClickAlertChartButton={onClickAlertChartButton}
            />

            <AlertMenuButton handleOpenMenu={handleOpenMenu} />
            <AlertListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          </Box>
        </Box>
        <SensorStatusBox>
          <SensorStatusRow>
            <ChipWithValue
              variant="type"
              type={sensorUnit.alertType}
              sensorUnit={sensorUnit}
            />
          </SensorStatusRow>
          <SensorStatusRow>
            <ChipWithValue
              variant="occurredTimestamp"
              sensorUnit={sensorUnit}
            />
          </SensorStatusRow>
          <SensorStatusRow near>
            <ChipWithValue
              variant="latestValue"
              type={sensorUnit.alertType}
              sensorUnit={sensorUnit}
            />
            <ChipWithValue variant="status" sensorUnit={sensorUnit} />
          </SensorStatusRow>
        </SensorStatusBox>
      </CardPaper>
    </>
  );
};

/**
 * テストするためexportする
 * @see ./AlertCardList.test.tsx
 */
export function AlertMenuButton({
  handleOpenMenu,
}: {
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <AccessControl
      permit={(permissions) => permissions.includes('alert.current.update')}
    >
      <IconButton
        data-testid="AlertMenuButton"
        size="large"
        onClick={(e) => handleOpenMenu(e)}
        sx={{
          p: 7 / 8,
          width: 32,
          height: 32,
          backgroundColor: 'white',
        }}
      >
        <SettingIcon />
      </IconButton>
    </AccessControl>
  );
}

function SensorStatusBox({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      pt={1.5}
      gap={1.5}
      sx={{
        flexWrap: 'wrap',
      }}
    >
      {children}
    </Box>
  );
}

function SensorStatusRow({
  near = false,
  children,
}: {
  near?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Box
      display="flex"
      rowGap={1.5}
      columnGap={near ? 3 : 1.5}
      width={near ? undefined : '100%'}
      sx={(theme) => ({
        [theme.breakpoints.down(smallMobileWidth)]: {
          flexDirection: 'column',
        },
      })}
    >
      {children}
    </Box>
  );
}

function ChipWithValue({
  sensorUnit,
  variant,
  type,
}: {
  sensorUnit: API.AlertSensorUnits;
  variant: 'type' | 'occurredTimestamp' | 'latestValue' | 'status';
  type?: API.AlertType;
}) {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      gap={1.5}
      width="100%"
      sx={(theme) => ({
        [theme.breakpoints.down(smallMobileWidth)]: {
          justifyContent: 'start',
          width: 'unset',
        },
      })}
    >
      <Chip
        textColor={theme.palette.text.primary}
        bgColor="white"
        fontWeight="bold"
        maxWidth="112px"
        smMinWidth="80px"
        fontSize="11px"
        label={intl.formatMessage({
          id: `pages.CurrentAlert.chip.${variant}`,
        })}
        sx={{
          height: 20,
        }}
      />
      <>
        {variant === 'type' && type && (
          <Type type={type} reason={sensorUnit.reason} />
        )}
        {variant === 'occurredTimestamp' && (
          <OccurredTimestamp occurredTimestamp={sensorUnit.occurredTimestamp} />
        )}
        {variant === 'latestValue' && type && (
          <LatestValue type={type} latestValue={sensorUnit.latestValue} />
        )}
        {variant === 'status' && <Status isAlive={sensorUnit.isAlive} />}
      </>
    </Box>
  );
}

function Type({
  type,
  reason,
}: {
  type: API.AlertType;
  reason: API.AlertReason;
}) {
  const intl = useIntl();

  const reasonIcons: { [reason in API.AlertReason]: React.FC } = {
    upper: ArrowUpwardIcon,
    lower: ArrowDownwardIcon,
  };

  const ArrowIcon = reasonIcons[reason];

  const noArrowIcon = type === 'condensationAlertTemperature';
  const noReason = type === 'condensationAlertTemperature';

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      {!noArrowIcon && <ArrowIcon />}
      <Typography
        fontSize={(theme) => theme.typography.pxToRem(14)}
        fontWeight="bold"
        lineHeight="normal"
      >
        {intl.formatMessage({
          id: `common.${type}`,
        })}{' '}
        {!noReason && (
          <>
            （
            {intl.formatMessage({
              id: `pages.CurrentAlert.chip.value.${reason}`,
            })}
            ）
          </>
        )}
      </Typography>
    </Box>
  );
}

function OccurredTimestamp({
  occurredTimestamp,
}: {
  occurredTimestamp: number;
}) {
  const dateTimeStrings =
    DateTime.fromMillis(occurredTimestamp).toFormat('yyyy/M/d HH:mm:ss');

  return <Typography>{dateTimeStrings}</Typography>;
}

function LatestValue({
  type,
  latestValue,
}: {
  type: API.AlertType;
  latestValue: number;
}) {
  return (
    <Box display="flex" justifyContent="center" maxWidth={50}>
      <UnitizedValue
        type={type}
        value={latestValue}
        sx={(theme) => ({
          fontWeight: 'bold',
          fontSize: theme.typography.pxToRem(14),
          color: 'nito.main',
        })}
      />
    </Box>
  );
}

function Status({ isAlive }: { isAlive?: boolean }) {
  const intl = useIntl();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="inherit"
      height={20}
      maxWidth={50}
    >
      {isAlive === undefined ? (
        intl.formatMessage({
          id: 'common.unconnected',
        })
      ) : isAlive === true ? (
        <CheckIcon />
      ) : (
        <CloseIcon />
      )}
    </Box>
  );
}

export default AlertListCard;
