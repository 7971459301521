import { Alert } from '@mui/material';
import React, { Component, ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface Props {
  children: ReactNode;
  message?: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props & WrappedComponentProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public render() {
    const { intl, message } = this.props;

    if (this.state.hasError) {
      return (
        <Alert severity="error">
          {message ??
            intl.formatMessage({
              id: 'common.errorBoundary.default',
            })}
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundary);
