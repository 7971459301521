import { createTheme } from '@mui/material/styles';

const nitoColor = '#E10A1E';

const theme = createTheme({
  palette: {
    primary: { main: '#F05064', light: 'rgba(240, 80, 100, 0.3)' },
    secondary: { main: '#E2DCD2' },
    nito: { main: nitoColor },
    background: {
      default: '#F3F2F2',
    },
    text: {
      primary: '#141414',
      disabled: '#D2D2D2',
    },
    chip: {
      main: '#505050',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Noto Sans JP"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          background: nitoColor,
        },
      },
    },
  },
});

export default theme;
