import { DateTime, Interval, DurationLikeObject } from 'luxon';

/** 単位時間 */
const RANGE: DurationLikeObject = { minute: 1 };
/** 判定のしきい値 */
const THRESHOLD_COUNT = 5;

type Result = { repeated: true } | { repeated: false; history: string };

/**
 * token期限切れエラーが連続して発生しているかどうかを判定する関数。
 * 単位時間あたりの発生回数がしきい値に達したら repeated: true を返す。
 */
export default function judgeRepeated(
  history: string | null,
  currentDate: Date,
): Result {
  const now = DateTime.fromJSDate(currentDate);
  const expirationHistory = toExpirationHistory(history).concat(now);

  const inRangeHistory = expirationHistory.filter(createRangeFilter(now));

  if (inRangeHistory.length >= THRESHOLD_COUNT) {
    return { repeated: true };
  }

  return { repeated: false, history: fromExpirationHistory(inRangeHistory) };
}

function createRangeFilter(now: DateTime) {
  const range = Interval.fromDateTimes(
    now.minus(RANGE),
    now.plus({ millisecond: 1 }),
  );
  return (datetime: DateTime) => range.contains(datetime);
}

function toExpirationHistory(str: string | null): DateTime[] {
  return str?.split(',').map((str) => DateTime.fromISO(str)) ?? [];
}

function fromExpirationHistory(history: DateTime[]): string {
  return history
    .map((datetime) =>
      datetime.setZone('UTC').toISO({ suppressMilliseconds: true }),
    )
    .join(',');
}
