import React from 'react';

const ShortDividerIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="44"
      height="1"
      viewBox="0 0 44 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="8" height="1" fill="#F05064" />
      <rect x="12" width="8" height="1" fill="#F05064" />
      <rect x="24" width="8" height="1" fill="#F05064" />
      <rect x="36" width="8" height="1" fill="#F05064" />
    </svg>
  );
};

export default ShortDividerIcon;
