import React, { useCallback, useEffect, useRef } from 'react';

const useCheckScrollTop = (): [boolean] => {
  const [isTop, setIsTop] = React.useState<boolean>(true);
  const mountedRef = useRef(true);

  const onScroll = useCallback(() => {
    // unmount後の更新を防ぐ
    if (!mountedRef.current) return;
    const position = Math.max(
      window.scrollY,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    );
    if (position > 0) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => {
      document.removeEventListener('scroll', onScroll);
      mountedRef.current = false;
    };
  }, [onScroll]);

  return [isTop];
};

export default useCheckScrollTop;
