import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import IndentLineIcon from './IndentLineIcon';

interface SensorUnit {
  deviceId: string;
  name: string;
}

interface Props {
  sensorUnits: SensorUnit[];
}

const SensorUnitName: React.FC<Props> = (props) => {
  const { sensorUnits } = props;

  return (
    <Box display="flex" alignItems="start" pl={1.5} columnGap={1.5}>
      <IndentLineIcon />
      <Box display="flex" flexDirection="column" rowGap={1.5}>
        {sensorUnits.map((sensorUnit) => (
          <Typography
            key={sensorUnit.deviceId}
            fontSize={14}
            lineHeight={(theme) => theme.typography.pxToRem(20)}
            sx={{ wordBreak: 'break-all' }}
          >
            {sensorUnit.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default SensorUnitName;
