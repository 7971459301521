import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError } from 'src/domains/root/utils/api';
import { RootState } from 'src/domains/root/store';
import { awsRum } from 'src/utils/rum';

const APP_ENDPOINT = import.meta.env.VITE_APP_ENDPOINT;
const ANNOUNCE_CATEGORY_ID = import.meta.env.VITE_ANNOUNCE_CATEGORY_ID;

interface Article {
  id: number;
  created_at: string;
}

interface Response {
  articles: Article[];
}

type GetArticlesErrorCode = 'unknown_error';

export const getArticles = createAsyncThunk<
  Article[],
  undefined,
  { state: RootState; rejectValue: RootError<GetArticlesErrorCode> }
>('announce/getArticles', async (_, thunkAPI) => {
  try {
    const res = await axios.get<Response>(
      `${APP_ENDPOINT}/help_center/ja/categories/${ANNOUNCE_CATEGORY_ID}/articles.json?sort_by=created_at&sort_order=desc&per_page=10`,
    );
    return res.data.articles;
  } catch (error) {
    awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    if (!isAxiosError(error)) {
      throw error;
    }
    return thunkAPI.rejectWithValue({
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    });
  }
});

interface AnnounceState {
  articles: Article[];
  latestReadArticle: Article | null;
}

const initialState: AnnounceState = {
  articles: [],
  latestReadArticle: null,
};

const announceSlice = createSlice({
  name: 'announce',
  initialState,
  reducers: {
    readAnnounce(state) {
      const {
        articles: [latestArticle],
      } = state;
      state.latestReadArticle = latestArticle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArticles.fulfilled, (state, action) => {
      state.articles = action.payload;
    });
  },
});

export const existsUnreadArticleSelector = createSelector(
  (state: RootState) => state.announce,
  (state: AnnounceState) => {
    const {
      articles: [latestArticle],
      latestReadArticle,
    } = state;

    if (!latestArticle) return false;
    if (!latestReadArticle) return true;

    return (
      latestArticle.id !== latestReadArticle.id &&
      latestArticle.created_at > latestReadArticle.created_at
    );
  },
);

export const { readAnnounce } = announceSlice.actions;

export default announceSlice.reducer;
