import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { LATEST_TERMS_OF_SERVICE_VERSION } from 'src/constants';
import axios, { isAxiosError } from 'src/utils/api';
import { Result, RegistrationState } from '../../store';
import {
  getRegistrationError,
  RegistrationError,
  RegistrationErrorCode,
  ResponseErrorHandler,
} from '../../utils/registrations/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface Registration {
  registrationId: string;
  registrationCode: string;
  email: string;
}

interface PostRegistrationParams {
  registrationCode: string;
  email: string;
  onResponseError: ResponseErrorHandler;
}

export const postRegistration = createAsyncThunk<
  Omit<Registration, 'registrationId'>,
  PostRegistrationParams,
  {
    state: RegistrationState;
    rejectValue: RegistrationError<RegistrationErrorCode>;
  }
>('registrations/postRegistration', async (params, thunkAPI) => {
  const {
    registrationCode,
    email,
    onResponseError: handleResponseError,
  } = params;
  try {
    const body = {
      registrationCode,
      email,
      agreedTermsOfServiceVersion: LATEST_TERMS_OF_SERVICE_VERSION,
    };
    await axios.post<void>(`${API_ENDPOINT}/registrations`, body);
    return { registrationCode, email };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const registrationError = getRegistrationError(error, handleResponseError);
    return thunkAPI.rejectWithValue(registrationError);
  }
});

interface GetRegistrationParams {
  registrationId: string;
  onResponseError: ResponseErrorHandler;
}

interface GetRegistrationResponse {
  email: string;
  registrationCode: string;
}

export const getRegistration = createAsyncThunk<
  Registration,
  GetRegistrationParams,
  {
    state: RegistrationState;
    rejectValue: RegistrationError<RegistrationErrorCode>;
  }
>('registrations/getRegistration', async (params, thunkAPI) => {
  const { registrationId, onResponseError: handleResponseError } = params;
  try {
    if (!registrationId) {
      return thunkAPI.rejectWithValue({
        code: 'registration_id_required',
        recoverable: false,
      });
    }

    const response = await axios.get<GetRegistrationResponse>(
      `${API_ENDPOINT}/registrations/${registrationId}`,
    );

    const { email, registrationCode } = response.data;
    return { registrationId, registrationCode, email };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const registrationError = getRegistrationError(error, handleResponseError);
    return thunkAPI.rejectWithValue(registrationError);
  }
});

export interface RegistrationsState {
  newResult: Result<
    Omit<Registration, 'registrationId'>,
    RegistrationError<RegistrationErrorCode>
  >;
  showResult: Result<Registration, RegistrationError<RegistrationErrorCode>>;
}

const initialState: RegistrationsState = {
  newResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  showResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const registrationsSlice = createSlice({
  name: 'registrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegistration.pending, (state) => {
        state.showResult.status = 'loading';
        state.showResult.error = null;
      })
      .addCase(getRegistration.fulfilled, (state, action) => {
        state.showResult.data = action.payload;
        state.showResult.error = null;
        state.showResult.status = 'succeeded';
      })
      .addCase(getRegistration.rejected, (state, action) => {
        state.showResult.data = null;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.showResult.error = action.payload!;
        state.showResult.status = 'failed';
      })
      .addCase(postRegistration.pending, (state) => {
        state.newResult.status = 'loading';
        state.newResult.error = null;
      })
      .addCase(postRegistration.fulfilled, (state, action) => {
        state.newResult.data = action.payload;
        state.newResult.error = null;
        state.newResult.status = 'succeeded';
      })
      .addCase(postRegistration.rejected, (state, action) => {
        state.newResult.data = null;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.newResult.error = action.payload!;
        state.newResult.status = 'failed';
      });
  },
});

export const newResultSelector = createSelector(
  (state: RegistrationState) => state.registrations,
  (state: RegistrationsState) => state.newResult,
);

export const showResultSelector = createSelector(
  (state: RegistrationState) => state.registrations,
  (state: RegistrationsState) => state.showResult,
);

export default registrationsSlice.reducer;
