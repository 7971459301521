import React, { useState } from 'react';
import HookStatus from 'src/domains/root/commons/HookStatus';
import { Gateway, SensorUnit } from 'src/domains/root/features/gateways/slice';
import { awsRum } from 'src/utils/rum';
import { useSensorDataChart } from './sensor-data/useSensorDataChart';
import SensorDataChart from './SensorDataChart';
import SensorDataModalFrame from './SensorDataModalFrame';
import CSVDownloadDialog from './CSVDownloadDialog';
import usePostSensorUnitCsv from './sensor-data/usePostSensorUnitCsv';

interface Props {
  gateway: Gateway;
  sensorUnit: SensorUnit;
  handleClose: () => void;
}

export default function NormalChartModal({
  gateway,
  sensorUnit,
  handleClose,
}: Props) {
  const result = useSensorDataChart({
    sensorUnitId: sensorUnit.deviceId,
    observeMode: sensorUnit.observe.mode,
  });

  const {
    mutate,
    reset,
    result: submitResult,
  } = usePostSensorUnitCsv({
    sensorUnitId: sensorUnit.deviceId,
    mode: sensorUnit.observe.mode,
  });

  const [csvDownloadDialogOpen, setCSVDownloadDialogOpen] = useState(false);
  const openCSVDownloadDialog = () => {
    setCSVDownloadDialogOpen(true);
    reset();
  };
  const closeCSVDownloadDialog = () => {
    setCSVDownloadDialogOpen(false);
  };

  const onSubmitCSVDownload = async (begin: number, end: number) => {
    try {
      const data = await mutate(begin, end);

      if (!data.s3Url) {
        return;
      }

      downloadFile({ fileURL: data.s3Url });
    } catch (error) {
      awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    }
  };

  function handleFullSensorData() {
    result.status === 'succeeded' && result.handleFullDataButtonClicked();
  }

  return (
    <SensorDataModalFrame
      handleFullSensorButton={
        result.status === 'succeeded'
          ? {
              clicked: result.fullDataButtonClicked,
              fetched: result.phase === 'full_data_fetched',
              onClick: handleFullSensorData,
            }
          : undefined
      }
      menuContents={{
        show: result.status === 'succeeded',
        csvDownload: { onClick: openCSVDownloadDialog },
      }}
      handleClose={handleClose}
    >
      {result.status !== 'succeeded' ? (
        <HookStatus
          target="sensorDataChart"
          error={result.status === 'hasError'}
          loading={result.status === 'loading'}
          noData={result.status === 'noData'}
        />
      ) : (
        <SensorDataChart
          isFullData={
            result.fullDataButtonClicked && result.phase === 'full_data_fetched'
          }
          gatewayName={gateway.name}
          sensorUnit={{
            name: sensorUnit.name,
            observe: sensorUnit.observe,
            isAlive: result.isAlive,
          }}
          sensorData={result.sensorData}
          weatherArea={result.weatherArea}
          weatherHistories={result.weatherHistories}
          phase={result.phase}
        />
      )}
      {csvDownloadDialogOpen && (
        <CSVDownloadDialog
          gatewayId={gateway.deviceId}
          sensorUnit={sensorUnit}
          submitResult={submitResult}
          onClose={closeCSVDownloadDialog}
          onSubmit={onSubmitCSVDownload}
        />
      )}
    </SensorDataModalFrame>
  );
}

function downloadFile({ fileURL }: { fileURL: string }) {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = fileURL;

  // fileNameはurlのheaderからブラウザが取得
  tempLink.setAttribute('download', '');

  // download attributeがない場合のサーポット、ほぼないが念の為
  // @see https://caniuse.com/download
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  document.body.removeChild(tempLink);
}
