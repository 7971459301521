import React from 'react';
import { useIntl } from 'react-intl';
import { Box, useTheme, useMediaQuery, Alert } from '@mui/material';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'src/domains/root/store';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  putWorkspace,
  errorSelector as currentWorkspaceErrorSelector,
  statusSelector as currentWorkspaceStatusSelector,
} from 'src/domains/root/features/current-workspace/slice';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { getUsersMe } from 'src/domains/root/features/users/slice';
import ContractExpiredAt from 'src/domains/root/features/contracts/ContractExpiredAt';
import { Workspace } from '../slice';

interface SelfProps {
  workspace: Workspace;
}

const Self: React.FC<SelfProps> = (props) => {
  const { workspace } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const currentWorkspaceError = useSelector(currentWorkspaceErrorSelector);
  const currentWorkspaceStatus = useSelector(currentWorkspaceStatusSelector);
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    const token = getAccessTokenSilently();
    dispatch(putWorkspace({ token, workspace }))
      .unwrap()
      .then(() => {
        dispatch(getUsersMe({ token, silent: true }));
        navigate('/');
      });
  };

  return (
    <>
      <LoadingOverlay open={currentWorkspaceStatus === 'loading'} />
      <Box mb={isSmallScreen ? 4 : 6}>
        {currentWorkspaceStatus === 'failed' && currentWorkspaceError && (
          <Box mb={3.5}>
            <Alert severity="error">
              {intl.formatMessage({
                id: `features.workspaces.packages.new.completed.adminUser.self.alert.${currentWorkspaceError.code}`,
              })}
            </Alert>
          </Box>
        )}
        <Alert>
          {intl.formatMessage(
            {
              id: 'features.workspaces.packages.new.completed.message',
            },
            {
              workspace: (
                <b style={{ wordBreak: 'break-all' }}>{workspace.name}</b>
              ),
            },
          )}
          <br />
          {intl.formatMessage(
            {
              id: 'features.workspaces.packages.new.completed.adminUser.expiredAt.message',
            },
            {
              date: (
                <ContractExpiredAt
                  expiredAt={workspace.contractExpiredAt}
                  formatType="japanese"
                />
              ),
            },
          )}
        </Alert>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box width={392}>
          <PrimaryButton fullWidth size="large" onClick={handleClick}>
            {intl.formatMessage({
              id: 'features.workspaces.packages.new.completed.adminUser.self.button',
            })}
          </PrimaryButton>
        </Box>
      </Box>
    </>
  );
};

export default Self;
