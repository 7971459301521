import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { AppDispatch } from 'src/domains/root/store';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import _TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import CabiotIcon from 'src/domains/root/commons/layout/global-header/icons/CabiotIcon';
import useSnackbar from 'src/domains/root/commons/snackbar';
import { awsRum } from 'src/utils/rum';
import CurrentAlertIcon from '../../../commons/layout/drawer/icons/CurrentAlertIcon';
import LoadingOverlay from '../../../commons/LoadingOverlay';
import ConfirmDialog from '../../../features/users/delete-account/confirm/Dialog';
import NoticeDialog from '../../../features/users/delete-account/notice/Dialog';
import NotDeletableDialog from '../../../features/users/delete-account/not-deletable/Dialog';
import { meResultSelector, Workspace } from '../../../features/users/slice';
import {
  deletableCheck,
  deleteUsersMe,
  reset,
  deletableCheckWorkspaceReasonsSelector,
  deletableCheckLoadingSelector,
  dialogStatusSelector,
  deletableCheckErrorCodeSelector,
  deleteAccountErrorCodeSelector,
} from './slice';

const DeleteAccount: React.FC = () => {
  const intl = useIntl();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { getAccessTokenSilently, logout } = useAuth0();

  const [agreeingForNoticed, setAgreeingForNoticed] = useState(false);

  const { data: me } = useSelector(meResultSelector);
  const deletableCheckErrorCode = useSelector(deletableCheckErrorCodeSelector);
  const workspaceReasons = useSelector(deletableCheckWorkspaceReasonsSelector);
  const loading = useSelector(deletableCheckLoadingSelector);
  const dialogStatus = useSelector(dialogStatusSelector);
  const deleteAccountErrorCode = useSelector(deleteAccountErrorCodeSelector);
  const { enqueueSnackbar } = useSnackbar('SNACKBAR_DELETE_ACCOUNT_DEMO', {
    noRefreshButton: true,
  });
  const handleClose = () => {
    setAgreeingForNoticed(false);
    dispatch(reset());
  };

  const handleClickDeleteButton = () => {
    if (import.meta.env.MODE === 'demo') {
      enqueueSnackbar(
        intl.formatMessage({
          id: 'pages.DeleteAccount.error.deleteAccount.on_demo_site',
        }),
      );
      return;
    }
    const token = getAccessTokenSilently();
    dispatch(deletableCheck({ token }));
  };

  const deleteAccount = () => {
    const token = getAccessTokenSilently();
    dispatch(deleteUsersMe({ token }))
      .unwrap()
      .then(() => {
        dispatch(reset());

        logout({
          logoutParams: {
            returnTo: `${location.origin}/delete-account/success`,
          },
        });
      })
      .catch((error) => {
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
      });
  };

  useEffect(() => {
    switch (deletableCheckErrorCode) {
      case 'emergency_maintenance':
        return navigate('/errors/emergency-maintenance');
      default:
        break;
    }
    switch (deleteAccountErrorCode) {
      case 'account_is_not_deletable':
        return navigate('/errors/account-is-not-deletable');
      case 'emergency_maintenance':
        return navigate('/errors/emergency-maintenance');
      case 'unknown_error':
        return navigate('/errors/unknown-error');
      default:
        break;
    }
  }, [deletableCheckErrorCode, deleteAccountErrorCode, dispatch, navigate]);

  return (
    <>
      <LoadingOverlay open={loading} />
      <Header />
      <Title />
      <Box pt={isSmallScreen ? 2 : 5}>
        <Description />
      </Box>
      <Box mt={isSmallScreen ? 4 : 6} mb={isSmallScreen ? 2 : 3}>
        <SectionTitle>
          {intl.formatMessage({
            id: 'pages.DeleteAccount.deleteAccountInformation.title',
          })}
        </SectionTitle>
      </Box>
      <DeleteAccountTable email={me?.email} createdAt={me?.createdAt} />
      <Box mt={isSmallScreen ? 4 : 6} mb={isSmallScreen ? 2 : 3}>
        <SectionTitle>
          {intl.formatMessage({
            id: 'pages.DeleteAccount.joinedWorkspaces.title',
          })}
        </SectionTitle>
      </Box>
      <JoinedWorkspacesTable workspaces={me?.workspaces ?? []} />
      <Box mt={isSmallScreen ? 4.5 : 8.5}>
        {deletableCheckErrorCode && (
          <Box
            data-testid="DeletableCheckErrorAlert"
            mb={isSmallScreen ? 4 : 6}
          >
            <Alert severity="error">
              {intl.formatMessage({
                id: `pages.DeleteAccount.error.deletableCheck.${deletableCheckErrorCode}`,
              })}
            </Alert>
          </Box>
        )}
        <ActionButtons
          onClickBack={() => navigate('/')}
          onClickDelete={handleClickDeleteButton}
        />
      </Box>
      {
        /**
         * 以下の問題でUnit Testが落ちる。
         * @see https://github.com/jaredpalmer/formik/issues/3418
         * - 対応策
         *   - render時にはConfirmDialogはrenderされないようにする。
         *     - （これにより、render時に`useFormik()`が実行されない）
         *   - その上で、ConfirmDialogを表示するための`fireEvent.click()`を`await act()`でwrapした。
         * - 試したこと
         *   - テストコードにて、renderをactでwrapしても解決しなかった
         * - 長期的に
         *   - formikの開発が止まっているため、他のライブラリを検討する必要がある。
         */
        (dialogStatus === 'deletable' || agreeingForNoticed) && (
          <ConfirmDialog onClose={handleClose} onSubmit={deleteAccount} />
        )
      }
      <NoticeDialog
        open={
          dialogStatus === 'deletable_with_workspace_deletion' &&
          !agreeingForNoticed
        }
        onClose={handleClose}
        workspaces={workspaceReasons}
        onClickNextButton={() => setAgreeingForNoticed(true)}
      />
      <NotDeletableDialog
        open={dialogStatus === 'not_deletable'}
        workspaces={workspaceReasons}
        onClose={handleClose}
      />
    </>
  );
};

export default DeleteAccount;

// ==========================
// parts

const Header: React.FC = () => (
  <AppBar
    position="fixed"
    sx={(theme) => ({
      height: theme.mixins.toolbar.minHeight,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid red',
      boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.1)',
    })}
  >
    <Box ml={4} my={1.75}>
      <CabiotIcon />
    </Box>
  </AppBar>
);

const Title: React.FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography
      variant="h4"
      fontWeight={700}
      fontSize={isSmallScreen ? 28 : 36}
    >
      {intl.formatMessage({
        id: 'pages.DeleteAccount.title',
      })}
    </Typography>
  );
};

const Description: React.FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Alert
      severity="warning"
      icon={<CurrentAlertIcon />}
      sx={{ fontSize: isSmallScreen ? 14 : 16 }}
    >
      {intl.formatMessage({
        id: 'pages.DeleteAccount.description',
      })}
    </Alert>
  );
};
function SectionTitle({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      pl={1.5}
      borderLeft={(props) =>
        isSmallScreen
          ? `4px solid ${props.palette.nito.main}`
          : `5px solid ${props.palette.nito.main}`
      }
    >
      <Typography fontSize={isSmallScreen ? 16 : 18} fontWeight={700}>
        {children}
      </Typography>
    </Box>
  );
}

function DeleteAccountTable({
  email,
  createdAt,
}: {
  email?: string;
  createdAt?: number;
}) {
  const intl = useIntl();
  return (
    <TableContainer>
      <Table
        aria-label="delete account information table"
        data-testid="DeleteAccountTable"
      >
        <TableHead>
          <TableHeaderRow>
            <TableCell sx={{ minWidth: 120 }} align="center">
              {intl.formatMessage({
                id: 'pages.DeleteAccount.deleteAccountInformation.email',
              })}
            </TableCell>
            <TableCell sx={{ minWidth: 120 }} align="center">
              {intl.formatMessage({
                id: 'pages.DeleteAccount.deleteAccountInformation.createdAt',
              })}
            </TableCell>
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          <TableBodyRow>
            <TableCell
              align="center"
              sx={{ maxWidth: 400, overflowWrap: 'anywhere' }}
            >
              {email}
            </TableCell>
            <TableCell align="center">
              {createdAt
                ? DateTime.fromMillis(createdAt).toFormat('yyyy/M/d')
                : ''}
            </TableCell>
          </TableBodyRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function JoinedWorkspacesTable({ workspaces }: { workspaces: Workspace[] }) {
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (workspaces.length === 0) {
    return (
      <Typography fontSize={isSmallScreen ? 14 : 16} mt={4} ml={2}>
        {intl.formatMessage({
          id: 'pages.DeleteAccount.joinedWorkspaces.no_workspaces',
        })}
      </Typography>
    );
  }

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableHeaderRow>
            <TableCell sx={{ minWidth: 120 }} align="center">
              {intl.formatMessage({
                id: 'pages.DeleteAccount.joinedWorkspaces.workspace',
              })}
            </TableCell>
            <TableCell sx={{ minWidth: 48 }} align="center">
              {intl.formatMessage({
                id: 'pages.DeleteAccount.joinedWorkspaces.role',
              })}
            </TableCell>
            <TableCell sx={{ minWidth: 88 }} align="center">
              {intl.formatMessage({
                id: 'pages.DeleteAccount.joinedWorkspaces.joinedAt',
              })}
            </TableCell>
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {workspaces.map((workspace) => (
            <TableBodyRow key={workspace.workspaceId}>
              <TableCell
                align="center"
                sx={{
                  maxWidth: 400,
                  overflowWrap: 'anywhere',
                }}
              >
                {workspace.workspaceName}
              </TableCell>
              <TableCell align="center">
                {intl.formatMessage({
                  id: `common.role.${workspace.role}`,
                })}
              </TableCell>
              <TableCell align="center">
                {DateTime.fromMillis(workspace.joinedAt).toFormat('yyyy/M/d')}
              </TableCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableContainer({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <_TableContainer
      sx={{
        px: isSmallScreen ? 0 : 4,
        pt: isSmallScreen ? 0 : 1.5,
        pb: isSmallScreen ? 0 : 3.5,
        width: 'unset',
        borderWidth: isSmallScreen ? '3px 3px 2px 3px' : '48px 40px 48px 40px',
        borderColor: '#F3F2F2',
        borderStyle: 'solid',
      }}
    >
      {children}
    </_TableContainer>
  );
}

function TableHeaderRow({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableRow
      sx={{
        backgroundColor: isSmallScreen ? '#F3F2F2' : undefined,
        borderBottom: '2px solid #828282',
        '& th': {
          fontWeight: 700,
          fontSize: isSmallScreen ? 14 : 16,
        },
        '& :not(:last-child)': {
          borderRight: '1px solid #D2D2D2',
        },
      }}
    >
      {children}
    </TableRow>
  );
}

function TableBodyRow({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableRow
      sx={{
        '& td': {
          fontSize: isSmallScreen ? 14 : 16,
        },
        '& :not(:last-child)': {
          borderRight: '1px solid #D2D2D2',
        },
      }}
    >
      {children}
    </TableRow>
  );
}

function ActionButtons({
  onClickBack: handleClickBack,
  onClickDelete: handleClickDelete,
}: {
  onClickBack: () => void;
  onClickDelete: () => void;
}) {
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      data-testid="ActionButtons"
      display="flex"
      justifyContent="center"
      gap={isSmallScreen ? 2 : 3}
    >
      <SecondaryButton
        data-testid="BackButton"
        onClick={handleClickBack}
        size="large"
        sx={{ width: 220 }}
      >
        {intl.formatMessage({
          id: 'pages.DeleteAccount.buttons.back',
        })}
      </SecondaryButton>
      <PrimaryButton
        data-testid="DeleteAccountButton"
        size="large"
        sx={{ width: 220 }}
        onClick={handleClickDelete}
      >
        {intl.formatMessage({
          id: 'pages.DeleteAccount.buttons.delete',
        })}
      </PrimaryButton>
    </Box>
  );
}
