import { useMemo } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { AppDispatch } from '../../../../store';
import { postRegistration } from '../../slice';
import { handleNewResponseError } from '../../../../utils/registrations/api';
import useValidationSchema from './useValidationSchema';

interface FormValues {
  email: string;
  code: string;
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useFormikInstance = () => {
  const dispatch: AppDispatch = useDispatch();
  const registrationCode = new URLSearchParams(location.search).get(
    'registrationCode',
  );

  const { emailValidationSchema, codeValidationSchema } = useValidationSchema();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: emailValidationSchema,
        code: codeValidationSchema,
      }),
    [emailValidationSchema, codeValidationSchema],
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      code: registrationCode || '',
    },
    initialTouched: {
      code: registrationCode ? true : false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { email, code } = values;
      return dispatch(
        postRegistration({
          email,
          registrationCode: code,
          onResponseError: handleNewResponseError,
        }),
      );
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  return formik;
};

export default useFormikInstance;
