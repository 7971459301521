import styled from 'styled-components';
import BaseButton, { BaseButtonProps } from './Base';

export type SecondaryButtonProps = Omit<BaseButtonProps, 'variant'>;

const TextButton = styled(BaseButton).attrs<SecondaryButtonProps>(() => ({
  variant: 'text',
}))<SecondaryButtonProps>`
  color: #505050;

  :hover {
    background: ${(props) => props.theme.palette.common.white};
  }
`;

export default TextButton;
