import { IntlShape } from 'react-intl';
import {
  getValue,
  chartColor,
  ChartColor,
  ObserveMode,
  ChartData,
} from './chart-utils';

export interface WeatherHistoriesData {
  icons: {
    x: number;
    y: number;
    marker: Highcharts.PointMarkerOptionsObject;
  }[];
  info: {
    [timestamp: number]: {
      temperatureMax: number;
      temperatureMin: number;
      rainQuantity: number;
    };
  };
}

/*
 * 用途に応じたグラフデータ設定を取得する
 */
export const getSeriesOptions = (
  intl: IntlShape,
  mode: ObserveMode,
  data: ChartData,
  weatherHistories: WeatherHistoriesData,
  isSmallScreen: boolean,
): { series: Highcharts.SeriesOptionsType[] } => {
  if (mode === 'temperatureHumidityObserve') {
    return {
      series: [
        createLineSeries('temperature', intl, data.temperature, 0, 1),
        createLineSeries('humidity', intl, data.humidity, 1, 2),
        isSmallScreen
          ? null
          : createWeatherSeries(
              mode,
              intl,
              weatherHistories.icons ?? [],
              weatherHistories.info ?? {},
            ),
      ]
        .filter(isNonNullish)
        .filter((d) => d.data?.length),
    };
  }

  if (mode === 'connectorTemperatureObserve') {
    return {
      series: [
        createLineSeries('temperature', intl, data.temperature, 0, 1),
        createLineSeries('humidity', intl, data.humidity, 1, 2),
        createLineSeries(
          'objectTemperature',
          intl,
          data.objectTemperature ?? [],
          0,
          0,
        ),
        createColumnSeries({
          key: 'objectTemperatureAlertCount',
          intl: intl,
          data: data.objectTemperatureAlertCount ?? [],
          yAxis: 2,
          zIndex: 2, // 0の基準線より上になるようにする
          pointWidth: isSmallScreen ? 2 : undefined,
        }),
        isSmallScreen
          ? null
          : createWeatherSeries(
              mode,
              intl,
              weatherHistories.icons ?? [],
              weatherHistories.info ?? {},
            ),
      ]
        .filter(isNonNullish)
        .filter((d) => d.data?.length),
    };
  }

  return {
    series: [
      createLineSeries('temperature', intl, data.temperature, 0, 1),
      createLineSeries('humidity', intl, data.humidity, 1, 2),
      createLineSeries(
        'objectTemperature',
        intl,
        data.objectTemperature ?? [],
        0,
        0,
      ),
      createLineSeries(
        'condensationAlertTemperature',
        intl,
        data.condensationAlertTemperature ?? [],
        2,
        2,
      ),
      isSmallScreen
        ? null
        : createWeatherSeries(
            mode,
            intl,
            weatherHistories.icons ?? [],
            weatherHistories.info ?? {},
          ),
    ]
      .filter(isNonNullish)
      .filter((d) => d.data?.length),
  };
};

/*
 * 線グラフの設定
 */
const createLineSeries = (
  key: string,
  intl: IntlShape,
  data: [number, number][] = [],
  yAxisIndex: number,
  zIndexNumber: number,
): Highcharts.SeriesLineOptions => {
  return {
    name: intl.formatMessage({
      id: `common.${key}`,
    }),
    data: data,
    type: 'line',
    yAxis: yAxisIndex,
    color: getValue(chartColor, key as keyof ChartColor),
    zIndex: zIndexNumber,
    marker: {
      enabled: true,
      symbol: 'circle',
    },
    showInNavigator: true,
  };
};

/*
 * ヒストグラムの設定
 */
const createColumnSeries = (props: {
  key: string;
  intl: IntlShape;
  data: [number, number][];
  yAxis: number;
  zIndex: number;
  pointWidth?: number;
}): Highcharts.SeriesColumnOptions => {
  return {
    name: props.intl.formatMessage({
      id: `common.${props.key}`,
    }),
    data: props.data,
    type: 'column',
    yAxis: props.yAxis,
    color: getValue(chartColor, props.key as keyof ChartColor),
    zIndex: props.zIndex,
    pointWidth: props.pointWidth,
  };
};

// グラフに表示する天気情報の最大数
const WEATHER_INFO_MAX_DISPLAY = 10;

/*
 * 天気アイコンと情報の設定
 */
const createWeatherSeries = (
  mode: string,
  intl: IntlShape,
  icons: WeatherHistoriesData['icons'],
  info: WeatherHistoriesData['info'],
): Highcharts.SeriesLineOptions => {
  return {
    name: intl.formatMessage({ id: 'common.weather' }),
    data: icons,
    type: 'line',
    states: {
      inactive: {
        opacity: 1,
      },
    },
    showInLegend: false,
    showInNavigator: false,
    includeInDataExport: false,
    marker: {
      enabled: true,
    },
    yAxis: 1,
    color: 'white',
    opacity: 1,
    lineWidth: 0,
    zIndex: 100,
    dataLabels: {
      enabled: true,
      verticalAlign: 'top',
      y: mode === 'temperatureHumidityObserve' ? -10 : 15,
      style: {
        fontFamily: 'Helvetica, sans-serif',
        fontSize: '13px',
        fontWeight: 'normal',
      },
      useHTML: true,
      formatter: function (this: Highcharts.PointLabelObject) {
        // データポイント数が上限値を超えたら何も表示しない
        if (WEATHER_INFO_MAX_DISPLAY < this.series.points.length) return '';
        return `
          <div style="display: flex;">
            <div style="width: 30px; padding: 2px; background-color: #e26a6a; color: #fff; text-align: center;">
              <b>${info[this.point.x]?.temperatureMax ?? '-'}</b>
            </div>
            <div style="width: 30px; padding: 2px; background-color: #2c82c9; color: #fff; text-align: center;">
              <b>${info[this.point.x]?.temperatureMin ?? '-'}</b>
            </div>
          </div>
          <div style="padding: 2px; text-align: center;">
            <b>${info[this.point.x]?.rainQuantity ?? '-'}</b> mm
          </div>
        `;
      },
    },
  };
};

function isNonNullish<T>(val: T): val is NonNullable<T> {
  return val != null;
}
