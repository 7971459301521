import { LATEST_TERMS_OF_SERVICE_VERSION } from 'src/constants';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios, { isAxiosError } from 'src/utils/api';
import { RootState } from '../../../store';
import {
  getInvitationError,
  InvitationError,
  InvitationErrorCode,
} from '../../../utils/invitations/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface InvitationsUser {
  email: string;
}
interface PostInvitationsUserParams {
  password: string;
}

export const postInvitationsUser = createAsyncThunk<
  InvitationsUser,
  PostInvitationsUserParams,
  { state: RootState; rejectValue: InvitationError<InvitationErrorCode> }
>('invitations/users/postUser', async (params, thunkAPI) => {
  try {
    const { invitation } = thunkAPI.getState().invitations;
    if (!invitation) {
      return thunkAPI.rejectWithValue({
        code: 'invitation_required',
        recoverable: true,
      });
    }

    const { password } = params;
    const { invitationId, email } = invitation;
    const body = {
      password,
      agreedTermsOfServiceVersion: LATEST_TERMS_OF_SERVICE_VERSION,
    };

    await axios.post<void>(
      `${API_ENDPOINT}/invitations/${invitationId}/users`,
      body,
    );

    return { email };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const invitationError = getInvitationError(error);
    return thunkAPI.rejectWithValue(invitationError);
  }
});

export interface InvitationsUserState {
  createdUser: InvitationsUser | null;
  error: InvitationError<InvitationErrorCode> | null;
  loading: boolean;
}

const initialState: InvitationsUserState = {
  createdUser: null,
  error: null,
  loading: false,
};

export const invitationsUsersSlice = createSlice({
  name: 'invitationsUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postInvitationsUser.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(postInvitationsUser.fulfilled, (state, action) => {
        state.createdUser = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(postInvitationsUser.rejected, (state, action) => {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.error = action.payload!;
        state.loading = false;
      });
  },
});

export const createdUserSelector = createSelector(
  (state: RootState) => state.invitationsUsers,
  (state: InvitationsUserState) => state.createdUser,
);

export const errorSelector = createSelector(
  (state: RootState) => state.invitationsUsers,
  (state: InvitationsUserState) => state.error,
);

export const loadingSelector = createSelector(
  (state: RootState) => state.invitationsUsers,
  (state: InvitationsUserState) => state.loading,
);

export default invitationsUsersSlice.reducer;
