import { Typography } from '@mui/material';

export default function TextLink({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <Typography
      component="a"
      color="primary"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Typography>
  );
}
