import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  code: string;
}

const CodeField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, code: true });
  };

  return (
    <TextField
      data-testid="RegistrationCodeInput"
      name="code"
      type="text"
      value={values.code}
      label={intl.formatMessage({
        id: 'registrations.new.form.code',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={touched.code && Boolean(errors.code)}
      helperText={touched.code && errors.code}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      placeholder="A12345"
    />
  );
};

export default CodeField;
