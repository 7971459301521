/**
 * このファイル名の `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import React from 'react';
import { Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material';

type Props = {
  children: React.ReactNode;
} & TypographyProps;

export default function Title({ children, sx = [], ...rest }: Props) {
  return (
    <Typography
      fontWeight="bold"
      color={(theme) => theme.palette.text.primary}
      sx={[
        (theme) => ({
          fontSize: {
            xs: theme.typography.pxToRem(14),
            sm: theme.typography.pxToRem(18),
          },
          lineHeight: {
            xs: theme.typography.pxToRem(20),
            sm: theme.typography.pxToRem(26),
          },
        }),

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Typography>
  );
}
