import Highcharts from 'highcharts';
import { ChartData } from './chart-utils';

/*
 * 用途(mode)に応じたX軸の設定を返す
 */
export const getXAxisOptions = ({
  mode,
  data,
  occurredAt,
  touchGraphRange,
}: {
  mode: string;
  data?: ChartData;
  occurredAt?: Date;
  touchGraphRange?: () => void;
}): Highcharts.Options => {
  const baseOptions: Highcharts.XAxisOptions = {
    ordinal: false,
    gridLineWidth: 1,
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%b/%e',
      week: '%b/%e',
      month: '%Y/%b',
      year: '%Y',
    },
    plotLines: !occurredAt
      ? undefined
      : [
          {
            color: '#ff0000',
            width: 2,
            value: occurredAt.getTime(),
            zIndex: 1, // 背景の罫線よりは手前、チャートのlineよりは奥
          },
        ],
  };

  const events = {
    setExtremes() {
      if (!touchGraphRange) return;
      touchGraphRange();
    },
  };

  // 結露監視
  if (mode === 'condensationObserve' && data) {
    return {
      xAxis: {
        ...baseOptions,

        plotBands: createPlotBands(data.condensationAlertTemperature ?? []),
        events,
      },
    };
  }

  return {
    xAxis: {
      ...baseOptions,
      events,
    },
  };
};

/*
 * 結露警戒温度を全件チェックして強調表示期間を特定する
 */
const createPlotBands = (
  data: [number, number][],
): Highcharts.XAxisPlotBandsOptions[] => {
  const amount = data.length;
  const result = [];
  for (let i = 0; i < amount; i++) {
    if (data[i][1] < 0) {
      const from = data[i][0];
      while (i < amount && data[i][1] < 0) {
        i++;
      }
      const to = data[i - 1][0];
      result.push({
        acrossPanes: true,
        from: from,
        to: to,
        color: '#FAE2E1',
        zIndex: -100,
      });
    }
  }
  return result;
};
