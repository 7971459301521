import React from 'react';
import {
  DialogActions as _DialogActions,
  DialogActionsProps,
} from '@mui/material';

export default function DialogActions({
  children,
  sx = [],
  ...rest
}: DialogActionsProps) {
  return (
    <_DialogActions
      sx={[
        {
          justifyContent: {
            xs: 'center',
            sm: 'flex-end',
          },
          p: (theme) => `${theme.spacing(3.5)} ${theme.spacing(2.5)}`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </_DialogActions>
  );
}
