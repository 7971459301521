import React from 'react';
import { DateTime } from 'luxon';

const ContractExpiredAt: React.FC<{
  expiredAt: number;
  formatType: 'slash' | 'japanese';
}> = ({ expiredAt, formatType }) => {
  const format = formatType === 'slash' ? 'yyyy/M/d' : 'yyyy年M月d日';
  return (
    <span>
      {DateTime.fromMillis(expiredAt).minus({ day: 1 }).toFormat(format)}
    </span>
  );
};

export default ContractExpiredAt;
