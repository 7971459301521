import { Form as FormikForm, FormikProvider } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import useSmallScreen from 'src/domains/registration/hooks/use-small-screen';
import SubmitButton from '../../../commons/form/SubmitButton';
import useFormikInstance from './agreement/useFormikInstance';
import TermsOfService from './agreement/TermsOfService';
import PrivacyPolicy from './agreement/PrivacyPolicy';

type Props = {
  onSubmitAgree: () => void;
  isTermsOfServiceScrollEnd: boolean;
};

const Agreement: React.FC<Props> = (props: Props) => {
  const { onSubmitAgree, isTermsOfServiceScrollEnd } = props;

  const intl = useIntl();
  const formik = useFormikInstance(onSubmitAgree);
  const isSmallScreen = useSmallScreen();

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={isSmallScreen ? 4 : 4.5}
          margin="0 auto"
          maxWidth={500}
        >
          <Box>
            <Box display="flex" data-testid="TermsOfServiceArea">
              <TermsOfService
                isTermsOfServiceScrollEnd={isTermsOfServiceScrollEnd}
                isSmallScreen={isSmallScreen}
              />
            </Box>
            <Box display="flex" data-testid="PrivacyPolicyArea">
              <PrivacyPolicy isSmallScreen={isSmallScreen} />
            </Box>
          </Box>
          <Box
            sx={{ minWidth: isSmallScreen ? '343px' : '360px', fontSize: 18 }}
          >
            <SubmitButton
              data-testid="NextButton"
              sx={{
                marginTop: isSmallScreen ? 3 : 4.5,
                width: '100%',
              }}
            >
              {intl.formatMessage({
                id: 'registrations.new.agreement.next',
              })}
            </SubmitButton>
          </Box>
        </Box>
      </FormikForm>
    </FormikProvider>
  );
};

export default Agreement;
