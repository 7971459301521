import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';

const _NotificationsIcon = createSvgIcon(
  <g>
    <path d="M24.36 18.91C24.04 18.29 23.44 18.06 22.91 17.66C22.4783 17.3087 22.1184 16.8775 21.85 16.39C21.5695 15.8631 21.3705 15.2966 21.26 14.71C21.0619 13.2985 20.9583 11.8753 20.95 10.45C20.77 8.45 20.28 6.26999 18.5 5.07999C17.6511 4.55846 16.7113 4.2022 15.73 4.02999V3.71999C15.7221 3.27484 15.5388 2.85079 15.22 2.53999C15.0632 2.37763 14.8752 2.24873 14.6672 2.16108C14.4593 2.07342 14.2357 2.02883 14.01 2.02999C13.7845 2.02997 13.5612 2.07508 13.3534 2.16267C13.1456 2.25026 12.9575 2.37855 12.8 2.53999C12.4803 2.86164 12.3006 3.29652 12.3 3.74999V4.05999C11.3162 4.21741 10.3722 4.56379 9.52 5.07999C7.73 6.24999 7.19 8.44 7.06 10.45C7.05497 11.8751 6.95475 13.2983 6.76 14.71C6.64036 15.2941 6.44188 15.8593 6.17 16.39C5.88856 16.8743 5.5228 17.3044 5.09 17.66C4.58 18.06 3.93 18.29 3.66 18.91C3.57329 19.1091 3.52576 19.323 3.52 19.54C3.50642 19.74 3.53364 19.9408 3.6 20.13C3.94 21.13 5.18 21.13 6.03 21.13H22.03C22.88 21.13 24.12 21.13 24.46 20.13C24.5191 19.9393 24.5395 19.7387 24.52 19.54C24.509 19.3398 24.4615 19.1432 24.38 18.96L24.36 18.91Z" />
    <path d="M14.01 26C14.8057 26 15.5687 25.6839 16.1313 25.1213C16.6939 24.5587 17.01 23.7956 17.01 23H11.01C11.01 23.7956 11.3261 24.5587 11.8887 25.1213C12.4513 25.6839 13.2144 26 14.01 26Z" />
  </g>,
  'Notifications',
);

interface NotificationsIconProps extends SvgIconProps {
  isEnabled: boolean;
}

const NotificationsIcon = styled((props: NotificationsIconProps) => (
  <_NotificationsIcon {...props} />
)).attrs<NotificationsIconProps>(() => ({
  viewBox: '0 0 28 28',
}))<NotificationsIconProps>`
  color: ${(props) => (props.isEnabled ? '#FFAA1E' : '#787878')};
  font-size: ${(props) => props.theme.typography.pxToRem(28)};
`;

export default NotificationsIcon;
