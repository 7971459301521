import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import useSmallScreen from 'src/domains/invitation/hooks/use-small-screen';
import TermsoOfServiceDocumentContent from 'src/shared/TermsOfServiceDocumentContent';

type Props = {
  setIsTermsOfServiceScrollEnd: Dispatch<SetStateAction<boolean>>;
};

const TermsOfServiceDocument = (props: Props) => {
  const { setIsTermsOfServiceScrollEnd } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useSmallScreen();

  useEffect(() => {
    const element = wrapperRef.current;

    function checkScrollEnd(element: HTMLDivElement | null) {
      if (!element) return;

      // 解像度によって微妙な誤差があるので10pxぐらいの余裕に計算する
      Math.ceil(element.scrollTop + element.offsetHeight) >=
        element?.scrollHeight - 10 && setIsTermsOfServiceScrollEnd(true);
    }

    if (!element) return;

    element.addEventListener('scroll', () => checkScrollEnd(element));

    return element.removeEventListener('scroll', () => checkScrollEnd(element));
  }, [setIsTermsOfServiceScrollEnd]);

  return (
    <Box
      ref={wrapperRef}
      data-testid="TermsOfServiceDocument"
      py={isSmallScreen ? 0 : 1}
      px={isSmallScreen ? 2 : 4}
      margin="0 auto"
      overflow="scroll"
      maxWidth={720}
      minHeight={348}
      maxHeight={isSmallScreen ? 348 : 520}
      bgcolor="#F3F2F2"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      <TermsoOfServiceDocumentContent />
    </Box>
  );
};

export default TermsOfServiceDocument;
