import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Role } from 'src/domains/root/types';
import SelectBoxArrowIcon from 'src/domains/root/commons/icons/SelectBoxArrowIcon';

interface RoleSelectBoxProps {
  label: string;
  roles: Role[];
}

export interface Values {
  role: string;
}

const RoleSelectBox: React.FC<RoleSelectBoxProps> = (props) => {
  const { label, roles } = props;
  const intl = useIntl();

  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, role: true });
  };

  const errorMessage = (values.role || touched.role) && errors.role;

  return (
    <FormControl
      color="primary"
      error={Boolean(errorMessage)}
      fullWidth
      hiddenLabel={false}
      size="small"
      variant="outlined"
    >
      <InputLabel id="users-role-label">{label}</InputLabel>
      <Select
        labelId="users-role-label"
        id="users-role"
        name="role"
        value={values.role}
        label={label}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        IconComponent={SelectBoxArrowIcon}
      >
        {roles.map((role) => (
          <MenuItem
            key={role}
            value={role}
            sx={(theme) => ({
              borderRadius: 1,
              mx: 1,
              '&.Mui-selected': {
                color: theme.palette.common.white,
                fontWeight: 'bold',
                background: theme.palette.primary.main,
                '&:hover': {
                  background: theme.palette.primary.main,
                },
                '&:focus-visible': {
                  background: theme.palette.primary.main,
                },
              },
              '&:hover': {
                background: theme.palette.primary.light,
              },

              '&:focus-visible': {
                background: theme.palette.primary.light,
              },
            })}
          >
            {intl.formatMessage({
              id: `common.role.${role}`,
            })}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default RoleSelectBox;
