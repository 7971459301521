import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import EmailField from './form/EmailField';

const EmailChanges: React.FC = () => {
  const intl = useIntl();

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
          gap: 2.5,
        },
        [theme.breakpoints.down('sm')]: {
          gap: 4,
          flexDirection: 'column',
        },
      })}
    >
      <Box flexGrow={1}>
        <ValidationBox
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              mb: 0,
            },
          })}
        >
          <EmailField
            label={intl.formatMessage({
              id: 'features.users.accountSetting.edit.dialog.form.newEmail',
            })}
          />
        </ValidationBox>
      </Box>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            /**
             * TextField の高さとSubmitButtonの高さが異なる。
             * 横に並べた中心線を揃えるためにmargin-topを入れる。
             */
            mt: 0.4,
          },
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            pb: 4,
          },
        })}
      >
        <SubmitButton data-testid="EmailChangesSubmitButton">
          {intl.formatMessage({
            id: 'features.users.accountSetting.edit.dialog.buttons.submit',
          })}
        </SubmitButton>
      </Box>
    </Box>
  );
};

export default EmailChanges;
