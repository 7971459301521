import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useIntl } from 'react-intl';
import DocumentIcon from './icons/DocumentIcon';

/**
 * @description ASCT: Act on specified commercial transactions.
 * 特定商取引法に基づく表記の英語化です
 */
export default function ASCTMenuItem() {
  const intl = useIntl();

  return (
    <MenuItem
      sx={{ height: 44 }}
      onClick={() => window.open('/ja/asct.html', '_blank')}
    >
      <ListItemIcon>
        <DocumentIcon color="primary" />
      </ListItemIcon>
      <ListItemText>
        {intl.formatMessage({
          id: `globalHeader.menu.asct.title`,
        })}
      </ListItemText>
    </MenuItem>
  );
}
