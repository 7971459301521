import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import * as API from 'src/apis';
import { awsRum } from 'src/utils/rum';

type ErrorCode =
  | 'bad_request'
  | 'not_found'
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export type Result = {
  mutate: (memoId: string) => Promise<void>;
  reset: () => void;
  data?: void;
} & (
  | { status: 'hasError'; errorCode: ErrorCode }
  | { status: 'loading' }
  | { status: 'idle' }
  | { status: 'succeeded' }
);

export function useDeleteGatewaysMemos(
  gatewayId: string,
  props: { nuetralizeResults: () => void; onSuccess: () => void },
): Result {
  const { getAccessTokenSilently } = useAuth0();

  const mutation = useMutation<void, API.Error, string>({
    mutationFn: async (memoId) => {
      const token = await getAccessTokenSilently();
      return API.deleteGatewaysMemos({ gatewayId, memoId, token });
    },
    onSuccess: props.onSuccess,
  });

  const responseBase = {
    mutate: async (memoId: string) => {
      props.nuetralizeResults();
      await mutation.mutateAsync(memoId);
    },
    reset: mutation.reset,
    data: mutation.data,
  };

  if (mutation.isIdle) {
    return {
      ...responseBase,
      status: 'idle',
    };
  }
  // Handle loading and no data states
  if (mutation.isPending) {
    return {
      ...responseBase,
      status: 'loading',
    };
  }

  if (mutation.isError) {
    const error = mutation.error;

    switch (error.code) {
      case 'bad_request':
      case 'permission_denied':
      case 'emergency_maintenance':
      case 'not_found':
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: error.code,
        };
      case 'unknown_error':
      default:
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: 'unknown_error',
        };
    }
  }

  return {
    ...responseBase,
    status: 'succeeded',
  };
}
