import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'src/domains/root/store';
import useSnackbar from 'src/domains/root/commons/snackbar';
import { RootError } from 'src/domains/root/utils/api';
import { getArticles } from './slice';

const useGetAnnounce = (existsUnreadArticle: boolean): void => {
  const dispatch: AppDispatch = useDispatch();

  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(
    'SNACKBAR_ANNOUNCE_ERROR',
  );

  useEffect(() => {
    if (existsUnreadArticle) return;

    const promise = dispatch(getArticles());

    promise
      .unwrap()
      .then(closeSnackbar)
      .catch((error) => {
        if (!(error as RootError).noticeable) {
          return;
        }
        enqueueSnackbar('common.announceErrorWithReload');
      });

    return () => promise.abort();
  }, [
    existsUnreadArticle,
    dispatch,
    enqueueSnackbar,
    closeSnackbar,
    location.pathname,
    location.key,
  ]);
};

export default useGetAnnounce;
