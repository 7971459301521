import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { useAuth0 } from '@auth0/auth0-react';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import ContentHeader from 'src/domains/root/commons/dialog/ContentHeader';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import Title from 'src/domains/root/commons/dialog/Title';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { emailLengthTester } from 'src/utils/users/validation/email';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import {
  postUsersEmailChange,
  createResultSelector,
} from '../email-changes/slice';
import UserData from './dialog/UserData';
import EmailChanges from './dialog/EmailChanges';

interface Props {
  open: boolean;
  currentEmail: string;
  onClose: () => void;
}

const AccountSettingDialog: React.FC<Props> = (props) => {
  const { open, currentEmail, onClose: _handleClose } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const { error: createResultError } = useSelector(createResultSelector);

  const intl = useIntl();
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'features.users.accountSetting.edit.dialog.form.newEmail',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .email(intl.formatMessage({ id: 'validation.string.email' }))
      .test({
        test: emailLengthTester,
        message: intl.formatMessage({ id: 'validation.string.emailLength' }),
      })
      .not(
        [currentEmail],
        intl.formatMessage({
          id: 'features.users.accountSetting.edit.dialog.validation.email.not',
        }),
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, validateForm }) => {
      setCompleted(false);
      setError(false);
      const { email } = values;
      const token = getAccessTokenSilently();
      const promise = dispatch(postUsersEmailChange({ token, email }))
        .unwrap()
        .then(() => {
          setCompleted(true);
          resetForm();
        })
        .catch(() => setError(true))
        .finally(() => validateForm());

      return promise;
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleClose = () => {
    _handleClose();
    setCompleted(false);
    setError(false);
    formik.resetForm();
  };

  const location = useLocation();
  useEffect(() => {
    if (open) {
      awsRum().then((rum) => {
        rum.recordPageView(
          location.pathname + '#UserAccountSettingDialogRender',
        );
      });
    }
  }, [location, open]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="account-setting-dialog-title"
      onClose={handleClose}
    >
      <LoadingOverlay open={formik.isSubmitting} />
      <DialogTitle id="account-setting-dialog-title">
        <Title onClose={handleClose}>
          {intl.formatMessage({
            id: 'features.users.accountSetting.edit.dialog.title',
          })}
        </Title>
        {error && createResultError && (
          <Box data-testid="Alert" mt={2.5} mb={0.5}>
            <Alert severity="error">
              {intl.formatMessage({
                id: `features.users.accountSetting.edit.dialog.alert.error.${createResultError.code}`,
              })}
            </Alert>
          </Box>
        )}
        {completed && (
          <Box data-testid="Alert" mt={2.5} mb={0.5}>
            <Alert severity="success">
              {intl.formatMessage({
                id:
                  import.meta.env.MODE === 'demo'
                    ? 'features.users.accountSetting.edit.dialog.alert.success.demo'
                    : 'features.users.accountSetting.edit.dialog.alert.success.completed',
              })}
            </Alert>
          </Box>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Box mt={1} mb={3}>
          <ContentHeader>
            {intl.formatMessage({
              id: 'features.users.accountSetting.edit.dialog.form.emailChanges',
            })}
          </ContentHeader>
        </Box>
        <Box mx={2}>
          <Box mt={1} mb={3.5}>
            <UserData email={currentEmail} />
          </Box>
          <FormikProvider value={formik}>
            <Form>
              <EmailChanges />
            </Form>
          </FormikProvider>
          <Box display="flex" justifyContent="flex-end">
            <DeleteAccountLink />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mx={2.5}>
          <CancelButton onClick={handleClose} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AccountSettingDialog;

const DeleteAccountLink: React.FC = () => {
  const intl = useIntl();

  return (
    <Link underline="always" href="/delete-account">
      <Typography
        fontSize={(theme) => theme.typography.pxToRem(12)}
        lineHeight={(theme) => theme.typography.pxToRem(16)}
        fontWeight={700}
        color={(theme) => theme.palette.primary.main}
      >
        {intl.formatMessage({
          id: 'features.users.accountSetting.edit.dialog.link.deleteAccount',
        })}
      </Typography>
    </Link>
  );
};
