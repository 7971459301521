import React from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

const CustomTableCell: React.FC<TableCellProps> = (props) => {
  const { align, sx = [], ...rest } = props;

  return (
    <TableCell
      align={align ?? 'left'}
      sx={[
        (theme) => ({
          fontSize: theme.typography.pxToRem(16),
          p: 1,
        }),

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {props.children}
    </TableCell>
  );
};

export default CustomTableCell;
