import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import DemoSiteAlertNotification from 'src/domains/root/commons/layout/global-header/DemoSiteAlertNotification';
import CabiotLogo from '../../../commons/CabiotLogo';

const Updated: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      {import.meta.env.MODE === 'demo' && <DemoSiteAlertNotification />}
      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={7.5}>
            <CabiotLogo />
          </Box>
          <Box mt={8.5} mb={10} maxWidth={560}>
            <Typography
              variant="h4"
              align="center"
              fontWeight={700}
              fontSize={(theme) => theme.typography.pxToRem(30)}
            >
              {intl.formatMessage({
                id: 'features.users.emailChanges.update.success.completed',
              })}
            </Typography>
          </Box>
          <Box width={320}>
            <PrimaryButton
              data-testid="PrimaryButton"
              fullWidth
              size="large"
              href="/"
            >
              {intl.formatMessage({ id: 'common.buttons.top' })}
            </PrimaryButton>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Updated;
