import React, { useState } from 'react';
import WorkspaceDialog from '@mui/material/Dialog';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import { useIntl } from 'react-intl';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Box from '@mui/material/Box';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import { useAuth0 } from '@auth0/auth0-react';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import { useSelector } from 'src/domains/root/store';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import ValidationField from 'src/domains/root/commons/form/ValidationField';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { updateWorkspace } from 'src/domains/root/features/users/slice';
import { patchWorkspace, updateResultSelector } from '../slice';
import WorkspaceNameField from './form/WorkspaceNameField';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const Dialog: React.FC<DialogProps> = (props) => {
  const { open, onClose: _handleClose } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const { error: updateResultError, status: updateResultStatus } =
    useSelector(updateResultSelector);

  const intl = useIntl();
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .max(200, intl.formatMessage({ id: 'validation.string.max' })),
  });

  const formik = useFormik({
    initialValues: {
      name: workspace.workspaceName,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setCompleted(false);
      setError(false);
      const token = getAccessTokenSilently();
      const promise = dispatch(
        patchWorkspace({
          token,
          workspaceId: workspace.workspaceId,
          name: values.name,
        }),
      );

      promise
        .unwrap()
        .then((workspace) => {
          const { workspaceId, name: workspaceName } = workspace;
          dispatch(updateWorkspace({ workspaceId, workspaceName }));
          setCompleted(true);
          formik.resetForm();
        })
        .catch(() => setError(true));

      return promise;
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleClose = () => {
    _handleClose();
    setCompleted(false);
    setError(false);
    formik.resetForm();
  };

  return (
    <WorkspaceDialog
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="workspace-edit-dialog-title"
      onClose={handleClose}
    >
      <LoadingOverlay open={updateResultStatus === 'loading'} />
      <FormikProvider value={formik}>
        <DialogTitle id="workspace-edit-dialog-title">
          <Title onClose={handleClose}>
            {intl.formatMessage({
              id: 'pages.OtherSetting.workspaces.edit.dialog.title',
            })}
          </Title>
          {error && updateResultError && (
            <Box mt={2.5} mb={0.5}>
              <Alert severity="error">
                {intl.formatMessage({
                  id: `pages.OtherSetting.workspaces.edit.dialog.alert.error.${updateResultError.code}`,
                })}
              </Alert>
            </Box>
          )}
          {completed && (
            <Box mt={2.5} mb={0.5}>
              <Alert severity="success">
                {intl.formatMessage({
                  id: 'pages.OtherSetting.workspaces.edit.dialog.alert.success.completed',
                })}
              </Alert>
            </Box>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <ValidationField>
            <WorkspaceNameField />
          </ValidationField>
        </DialogContent>
        <Form>
          <DialogActions>
            <Box display="flex" gap={1.5}>
              <CancelButton onClick={handleClose} />
              <SubmitButton>
                {intl.formatMessage({
                  id: 'pages.OtherSetting.workspaces.edit.dialog.form.button',
                })}
              </SubmitButton>
            </Box>
          </DialogActions>
        </Form>
      </FormikProvider>
    </WorkspaceDialog>
  );
};

export default Dialog;
