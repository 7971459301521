import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface CloseIconButtonProps extends IconButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CloseIconButton: React.FC<CloseIconButtonProps> = (props) => {
  const { onClick, ...others } = props;

  return (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={onClick}
      {...others}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export default CloseIconButton;
