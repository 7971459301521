import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Dialog from '@mui/material/Dialog';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import { useDispatch } from 'react-redux';
import { Gateway, updateArea } from 'src/domains/root/features/gateways/slice';
import { useAuth0 } from '@auth0/auth0-react';
import {
  putGatewaysAreaId,
  reset as resetGatewaysAreaId,
  errorSelector as gatewaysAreaIdErrorSelector,
  statusSelector as gatewaysAreaIdStatusSelector,
} from 'src/domains/root/features/gateways/area-id/slice';
import {
  getAreas,
  resultSelector as areasResultSelector,
} from 'src/domains/root/features/areas/slice';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import ModalHeader from './gateway-location/ModalHeader';
import ModalContent from './gateway-location/ModalContent';
import ModalAction from './gateway-location/ModalAction';

export interface Location {
  areaId: string;
  region: string;
  prefecture?: string;
  area?: string;
}

interface Props {
  gateway: Omit<Gateway, 'sensorUnits'>;
  modalOpen: boolean;
  toggleModalOpen: () => void;
  onClose: () => void;
}

const areaIdValidation = yup.object().shape({
  areaId: yup.string().required('required'),
});

const LocationSettingModal: React.FC<Props> = (props) => {
  const { gateway, modalOpen, toggleModalOpen, onClose } = props;

  const modalRef = useRef<HTMLDivElement>(null);
  const [isRegionPage, setIsRegionPage] = useState(true);
  const {
    data: areasData,
    status: areasStatus,
    error: areasError,
  } = useSelector(areasResultSelector);
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  const areas = areasData!;

  const gatewaysAreaIdError = useSelector(gatewaysAreaIdErrorSelector);
  const gatewaysAreaIdStatus = useSelector(gatewaysAreaIdStatusSelector);

  const [displayLocation, setDisplayLocation] = useState<Location>({
    areaId: gateway.area?.areaId ?? '',
    region: '',
    prefecture: gateway.area?.prefectureName,
    area: gateway.area?.displayAreaName,
  });

  const dispatch: AppDispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useLayoutEffect(() => {
    if (modalRef.current) modalRef.current.scrollIntoView(true);
  }, [isRegionPage]);

  useEffect(() => {
    if (!modalOpen || areas.length > 0) return;

    const token = getAccessTokenSilently();
    const promise = dispatch(getAreas({ token }));

    return () => promise.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      setIsRegionPage(true);

      if (gateway.area?.areaId === 'noLocation')
        setDisplayLocation({
          areaId: gateway.area?.areaId,
          region: '',
          prefecture: '',
          area: '',
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const toggleIsRegionPage = () => {
    setIsRegionPage(!isRegionPage);
  };

  const handlePut = async (location: Location) => {
    const token = getAccessTokenSilently();
    await dispatch(
      putGatewaysAreaId({
        token,
        gatewayId: gateway.deviceId,
        areaId: location.areaId,
      }),
    );

    const prefectureName =
      location.prefecture !== 'noLocation' ? location.prefecture : undefined;

    setDisplayLocation({ ...location, prefecture: prefectureName || '' });

    dispatch(
      updateArea({
        gateway: gateway,
        area: {
          areaId: location.areaId,
          prefectureName: prefectureName || '',
          displayAreaName: location.area || '',
        },
      }),
    );
  };

  const locationInitialValue: Location = {
    areaId: '',
    region: displayLocation.region,
    prefecture: displayLocation.prefecture,
    area: displayLocation.area,
  };

  const handleClose = () => {
    toggleModalOpen();
    dispatch(resetGatewaysAreaId());
    onClose();
  };

  const headerProps = {
    handleClose,
    isRegionPage,
    error: !!gatewaysAreaIdError,
    complete: gatewaysAreaIdStatus === 'succeeded',
  };

  const contentProps = {
    gatewayName: gateway.name,
    areas,
    modalRef,
    isRegionPage,
    toggleIsRegionPage,
    error: areasError,
    loading: areasStatus === 'loading',
  };

  const actionPorps = {
    handleClose,
    isRegionPage,
    toggleIsRegionPage,
    contentLoading: areasStatus === 'loading',
    putLoading: gatewaysAreaIdStatus === 'loading',
  };

  const location = useLocation();
  useEffect(() => {
    if (modalOpen) {
      awsRum().then((rum) => {
        rum.recordPageView(
          location.pathname + '#GatewaySettingsLocationSettingModalRender',
        );
      });
    }
  }, [location, modalOpen]);

  return (
    <Dialog onClose={handleClose} open={modalOpen} fullWidth>
      <Formik
        initialValues={locationInitialValue}
        onSubmit={handlePut}
        validateOnMount
        validationSchema={areaIdValidation}
        initialTouched={{
          areaId: true,
        }}
        enableReinitialize
      >
        <>
          <ModalHeader {...headerProps} />
          <ModalContent {...contentProps} />
          <ModalAction {...actionPorps} />
        </>
      </Formik>
    </Dialog>
  );
};

export default LocationSettingModal;
