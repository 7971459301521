import React, { useState } from 'react';
import _Dialog from '@mui/material/Dialog';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import { useIntl } from 'react-intl';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Box from '@mui/material/Box';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'src/domains/root/store';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import { Form, FormikProvider, useFormik } from 'formik';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import Typography from '@mui/material/Typography';
import { Role } from 'src/domains/root/types';
import { activeWorkspaceSelector, Workspace } from '../../../../users/slice';
import {
  updateWorkspacesUser,
  updateResultSelector,
  targetUserSelector,
} from '../../slice';
import RoleSelectBox from './form/RoleSelectBox';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const Dialog: React.FC<DialogProps> = (props) => {
  const { open, onClose: _handleClose } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const targetUser = useSelector(targetUserSelector);
  const { error: updateResultError } = useSelector(updateResultSelector);

  const intl = useIntl();
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik<{ role?: Role }>({
    initialValues: {
      role: targetUser?.role,
    },
    onSubmit: (values) => {
      setCompleted(false);
      setError(false);
      const { role } = values;
      const token = getAccessTokenSilently();
      const promise = dispatch(
        updateWorkspacesUser({
          token,
          workspaceId: workspace.workspaceId,
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          userId: targetUser!.userId,
          role: role!,
          /* eslint-enable */
        }),
      )
        .unwrap()
        .then(() => setCompleted(true))
        .catch(() => setError(true));

      return promise;
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });

  const handleClose = () => {
    _handleClose();
    setCompleted(false);
    setError(false);
    formik.resetForm();
  };

  return (
    <_Dialog
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="users-role-update-dialog-title"
      onClose={handleClose}
    >
      <LoadingOverlay open={formik.isSubmitting} />
      <FormikProvider value={formik}>
        <DialogTitle id="users-role-update-dialog-title">
          <Title onClose={handleClose}>
            {intl.formatMessage({
              id: 'features.workspaces.users.update.role.dialog.title',
            })}
          </Title>
          {error && updateResultError && (
            <Box mt={2.5} mb={0.5}>
              <Alert severity="error">
                {intl.formatMessage(
                  {
                    id: `features.workspaces.users.update.role.dialog.alert.error.${updateResultError.code}`,
                  },
                  {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    email: targetUser!.email,
                  },
                )}
              </Alert>
            </Box>
          )}
          {completed && (
            <Box mt={2.5} mb={0.5}>
              <Alert severity="success">
                {intl.formatMessage({
                  id: 'features.workspaces.users.update.role.dialog.alert.success.completed',
                })}
              </Alert>
            </Box>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1} mb={3.5}>
            <Typography sx={{ wordBreak: 'break-all' }}>
              {intl.formatMessage(
                {
                  id: 'features.workspaces.users.update.role.dialog.messages.0',
                },
                { name: targetUser?.email },
              )}
            </Typography>
          </Box>
          <Box mb={1.5}>
            <RoleSelectBox
              label={intl.formatMessage({
                id: 'features.workspaces.users.update.role.dialog.form.role.label',
              })}
              roles={['admin', 'normal']}
            />
          </Box>
        </DialogContent>
        <Form>
          <DialogActions>
            <Box display="flex" gap={1.5}>
              <CancelButton onClick={handleClose} />
              <SubmitButton data-testid="SubmitButton">
                {intl.formatMessage({
                  id: 'features.workspaces.users.update.role.dialog.button.submit',
                })}
              </SubmitButton>
            </Box>
          </DialogActions>
        </Form>
      </FormikProvider>
    </_Dialog>
  );
};

export default Dialog;
