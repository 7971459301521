import React, { Component, ReactNode } from 'react';
import { awsRum } from '../rum';
import GenericError from './GenericError';

interface State {
  hasError: boolean;
}

interface Props {
  children: ReactNode;
}

class GlobalErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
  }

  public render() {
    if (this.state.hasError) {
      return <GenericError />;
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;
