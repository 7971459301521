import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  gatewayName: string;
}

const GatewayNameField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, gatewayName: true });
  };

  const errorMessage =
    (values.gatewayName || touched.gatewayName) && errors.gatewayName;

  return (
    <TextField
      data-testid="GatewayNameTextInput"
      name="gatewayName"
      type="text"
      value={values.gatewayName}
      label={intl.formatMessage({
        id: 'pages.OtherSetting.gateways.new.form.gatewayName.label',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      placeholder={intl.formatMessage({
        id: 'pages.OtherSetting.gateways.new.form.gatewayName.placeholder',
      })}
    />
  );
};

export default GatewayNameField;
