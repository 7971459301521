import React from 'react';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

/**
 * src/domains/root/commons/QrCodeWaterMark.tsxのコンポーネントはレイアウトを内部で指定しているたこん
 * 外部でレイアウト変更可能ようなコンポーネントを新しく作る。
 * 前のバージョンのコンポーネントを使っている場所が全部このコンポーネントに切り替えると前のバージョンを消してこのコンポーネントが基準になる
 */
const QrCodeWaterMark = () => {
  const intl = useIntl();
  return (
    <Typography
      data-testid="QrCodeWaterMark"
      fontSize={(props) => props.typography.pxToRem(10)}
    >
      {intl.formatMessage({
        id: 'common.qrCodeWaterMark',
      })}
    </Typography>
  );
};

export default QrCodeWaterMark;
