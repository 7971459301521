import React, { useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Gateway, SensorUnit } from 'src/domains/root/features/gateways/slice';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SensorUnitCard from 'src/domains/root/features/gateways/SensorUnitCard';
import NormalChartModal from 'src/domains/root/features/gateways/NormalChartModal';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import GatewayTableRow from './GatewayTableRow_for_experimental_20231004';
import GatewayData from './GatewayDataV3';
import GatewayName from './GatewayNameV3';
import GatewayPaper from './GatewayPaperV3';
import TableHeadCell from './sensor-units/TableHeadCell';
import Table from './sensor-units/Table';

type NormalGraphInput = {
  gateway: Gateway;
  sensorUnit: SensorUnit;
};

interface GatewayListProps {
  gateways: Gateway[];
}

const GatewayList: React.FC<GatewayListProps> = (props) => {
  const { gateways } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [normalGraphInput, setNormalGraphInput] =
    useState<NormalGraphInput | null>(null);

  const hideModal = () => setNormalGraphInput(null);

  const displayBattery = isSmallScreen ? false : true;
  const compareChartButtonName = intl.formatMessage({
    id: isSmallScreen
      ? 'pages.GatewaySensorData.mobileTitle'
      : 'pages.GatewaySensorData.title',
  });
  const compareChartButtonWidth = isSmallScreen ? '62px' : undefined;

  const gatewayList = gateways.map((gateway, index) => (
    <GatewayPaper data-testid={`GatewayPaper${index}`} key={gateway.deviceId}>
      <GatewayName gateway={gateway} displayBattery={displayBattery}>
        <Box display="flex" justifyContent="end">
          <SecondaryButton
            onClick={() => navigate(`/gateways/${gateway.physicalId}/chart`)}
            sx={{
              minWidth: compareChartButtonWidth,
            }}
          >
            {compareChartButtonName}
          </SecondaryButton>
        </Box>
      </GatewayName>
      <Box
        p={(theme) => theme.spacing(3)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            pt: 2.5,
            px: 2,
            pb: 3.5,
          },
        })}
      >
        <Box
          mb={(theme) => theme.spacing(3.5)}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              mb: 2.5,
            },
          })}
        >
          <GatewayData gateway={gateway} />
        </Box>
        {isSmallScreen ? (
          <Box display="flex" flexDirection="column" gap={1.5}>
            {gateway.sensorUnits.map((sensorUnit, index) => (
              <Box
                key={sensorUnit.deviceId}
                data-testid={`SensorUnitCard-${index}`}
              >
                <SensorUnitCard
                  sensorUnit={sensorUnit}
                  onClickChartLinkButton={() => {
                    setNormalGraphInput({ gateway, sensorUnit });
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: '60px', minWidth: '46px' }} />
                <col style={{ width: '40%' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
                <col style={{ minWidth: '92px' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'gatewayTable.sensorUnit.number',
                    })}
                  </TableHeadCell>
                  <TableHeadCell>
                    {intl.formatMessage({
                      id: 'common.sensorUnitName',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'common.status',
                    })}
                  </TableHeadCell>
                  <TableHeadCell align="center">浸水</TableHeadCell>
                  <TableHeadCell align="center">気圧</TableHeadCell>
                  <TableHeadCell align="center">外気温</TableHeadCell>
                  <TableHeadCell align="center">
                    {intl.formatMessage({
                      id: 'common.graph',
                    })}
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gateway.sensorUnits.map((sensorUnit, index) => (
                  <TableRow
                    key={sensorUnit.deviceId}
                    data-testid={`GatewayTableRow-${index}`}
                  >
                    <GatewayTableRow
                      powerSupply={sensorUnit.powerSupply}
                      sensorUnit={sensorUnit}
                      onClickChartLinkButton={() => {
                        setNormalGraphInput({ gateway, sensorUnit });
                      }}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </GatewayPaper>
  ));

  return (
    <>
      {gatewayList}
      {normalGraphInput && (
        <NormalChartModal
          gateway={normalGraphInput.gateway}
          sensorUnit={normalGraphInput.sensorUnit}
          handleClose={hideModal}
        />
      )}
    </>
  );
};

export default GatewayList;
