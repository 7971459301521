import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { CssBaseline } from '@mui/material';
import BaseDrawerContainer from './BaseDrawer';
import GlobalHeader from './global-header/GlobalHeader';

const HeaderOnlyLayout = ({
  children,
  withMobileDrawer = false,
}: {
  children: React.ReactNode;
  withMobileDrawer?: boolean;
}) => {
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
  const [hambugerMenuOpened, setHambugerMenuOpened] = useState(false);

  const handleMobileDrawerToggle = () => {
    setOpenMobileDrawer(!openMobileDrawer);
    handleHambugerMenuToggle();
  };

  const handleHambugerMenuToggle = () => {
    setHambugerMenuOpened(!hambugerMenuOpened);
  };

  return (
    <Box display="flex">
      <CssBaseline />
      <GlobalHeader
        handleMobileDrawerToggle={handleMobileDrawerToggle}
        hambugerMenuOpened={hambugerMenuOpened}
        handleHambugerMenuToggle={handleHambugerMenuToggle}
        withMobileDrawer={withMobileDrawer}
      />
      {withMobileDrawer && (
        <BaseDrawerContainer
          pcDrawer={false}
          openMobileDrawer={openMobileDrawer}
          handleMobileDrawerToggle={handleMobileDrawerToggle}
        />
      )}
      {children}
    </Box>
  );
};

export default HeaderOnlyLayout;
