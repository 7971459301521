import React from 'react';

import { Typography, type TypographyProps } from '@mui/material';

type Variant =
  | 'Title_A'
  | 'Title_B'
  | 'Basic_A'
  | 'Basic_B'
  | 'Heading'
  | 'Button_A'
  | 'Button_B'
  | 'Caption_A'
  | 'Caption_B'
  | 'Validation';

type Props = {
  variant?: Variant;
  children: React.ReactNode;
} & Omit<TypographyProps, 'variant'>;

const variants = {
  Title_A: {
    fontSize: { sm: 18, xs: 16 },
    fontWeight: 'bold',
  },
  Title_B: {
    fontSize: { sm: 18, xs: 16 },
    fontWeight: 'regular',
  },
  Basic_A: {
    fontSize: { sm: 16, xs: 14 },
    fontWeight: 'bold',
  },
  Basic_B: {
    fontSize: { sm: 16, xs: 14 },
    fontWeight: 'regular',
  },
  Heading: {
    fontSize: { sm: 15, xs: 13 },
    fontWeight: 'bold',
  },
  Button_A: {
    fontSize: { sm: 14, xs: 14 },
    fontWeight: 'bold',
  },
  Button_B: {
    fontSize: { sm: 14, xs: 14 },
    fontWeight: 'regular',
  },
  Caption_A: {
    fontSize: { sm: 12, xs: 11 },
    fontWeight: 'bold',
  },
  Caption_B: {
    fontSize: { sm: 12, xs: 11 },
    fontWeight: 'regular',
  },
  Validation: {
    fontSize: { sm: 10, xs: 10 },
    fontWeight: 'bold',
  },
};

export function Text({
  variant = 'Basic_B',
  sx = [],
  children,
  ...rest
}: Props) {
  return (
    <Typography
      {...variants[variant]}
      sx={[
        {
          wordBreak: 'break-all',
        },

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Typography>
  );
}
