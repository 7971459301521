import React from 'react';
import styled from 'styled-components/macro';
import Box from '@mui/material/Box';
import _CircularProgress from '@mui/material/CircularProgress';

const CircularProgress = styled(_CircularProgress)`
  color: white;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
`;

export default function ProgressOverlay(props: {
  children: React.ReactNode;
  loading: boolean;
}) {
  const { loading, children } = props;

  return (
    <Box position="relative">
      {children}
      {loading && <CircularProgress size={24} />}
    </Box>
  );
}
