import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  TextField,
  IconButton,
} from '@mui/material';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import ContentHeader from 'src/domains/root/commons/dialog/ContentHeader';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import Title from 'src/domains/root/commons/dialog/Title';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import { useFormik, useFormikContext, FormikProvider } from 'formik';
import { AddPhotoAlternateRounded, CancelOutlined } from '@mui/icons-material';
import * as API from 'src/apis';
import useThumbnail from './useThumbnail';
import BiggerImage from './BiggerImage';
import MemoImage from './MemoImage';

const ALLOW_IMAGE_EXTENSIONS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
];

const ALLOW_IMAGE_EXTENSIONS_STRING = ALLOW_IMAGE_EXTENSIONS.join(',');

type InitialValues = {
  content: string;
  images: API.MemoImage[];
};

type FormValues = Omit<InitialValues, 'images'> & {
  images: (API.MemoImage & { file: File })[];
};

interface Props {
  open: boolean;
  title: string;
  initialValues: InitialValues;
  submitting: boolean;
  onSubmit: (values: FormValues) => Promise<API.MemoImageUploadResponse>;
  onClose: () => void;
}

const IMAGE_MAX_COUNT = 4;

/**
 * メモの新規作成と編集を行うダイアログ
 */
export default function MemoDialog({
  open,
  title,
  initialValues,
  submitting,
  onSubmit,
  onClose,
}: Props) {
  const [showBiggerImage, setShowBiggerImage] = useState<number | null>(null);

  const intl = useIntl();
  const isSmallScreen = useSmallScreen();

  const initialFormValues: FormValues = {
    content: initialValues.content,
    images: initialValues.images.map((image) => ({
      ...image,
      file: new File([], image.name),
    })),
  };

  const formik = useFormik<FormValues>({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      content: yup
        .string()
        .max(160, intl.formatMessage({ id: 'validation.string.max' })),
      images: yup.array().max(IMAGE_MAX_COUNT),
    }),
    onSubmit: async (values) => {
      const result = await onSubmit(values);
      formik.resetForm();

      if (!result) {
        return;
      }
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const toggleBiggerImage = (selectedImage: number | null) => {
    if (showBiggerImage !== null) {
      setShowBiggerImage(null);
      return;
    }

    setShowBiggerImage(selectedImage);
    return;
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        scroll="body"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 732,
          },
        }}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              <Title onClose={handleClose}>{title}</Title>
            </DialogTitle>
            <DialogContent>
              <ContentHeader mt={1}>
                {intl.formatMessage({
                  id: 'pages.GatewaysMemos.memoList.memoDialog.textField.sectionTitle',
                })}
              </ContentHeader>
              <ValidationBox height="inherit" pt={2}>
                <TextField
                  placeholder={intl.formatMessage({
                    id: 'pages.GatewaysMemos.memoList.memoDialog.textField.placeholder',
                  })}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={isSmallScreen ? 10 : 4}
                  data-testid="MemoTextField"
                  name="content"
                  onChange={formik.handleChange}
                  value={formik.values.content}
                  error={Boolean(formik.errors.content)}
                  helperText={formik.errors.content}
                />
              </ValidationBox>
              <ImageList toggleBiggerImage={toggleBiggerImage} />
            </DialogContent>
            <DialogActions>
              <Box display="flex" columnGap={2}>
                <CancelButton onClick={handleClose} />
                <PrimaryButton
                  data-testid="SubmitButton"
                  disabled={!formik.dirty || !formik.isValid || submitting}
                  type="submit"
                >
                  {!submitting ? (
                    intl.formatMessage({
                      id: 'pages.GatewaysMemos.memoList.memoDialog.saveButton',
                    })
                  ) : (
                    <CircularProgress color="inherit" size={20} />
                  )}
                </PrimaryButton>
              </Box>
            </DialogActions>
          </form>
        </FormikProvider>
      </Dialog>
      {showBiggerImage !== null && (
        <BiggerImage
          images={formik.values.images}
          selectedImage={showBiggerImage}
          closeImage={() => toggleBiggerImage(null)}
        />
      )}
    </>
  );
}

function ImageList({
  toggleBiggerImage,
}: {
  toggleBiggerImage: (index: number) => void;
}) {
  const intl = useIntl();

  const formik = useFormikContext<{
    images: FormValues['images'];
  }>();
  const images = formik.values.images;

  const handleAddImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const toLowerCaseExtension = (filename: string) => {
      if (!filename.includes('.')) {
        return filename;
      }

      const lastDotIndex = filename.lastIndexOf('.');
      const name = filename.substring(0, lastDotIndex);
      const extension = filename.substring(lastDotIndex + 1);

      return name + '.' + extension.toLowerCase();
    };

    const newImages = Array.from(e.target.files ?? [])
      .filter((file) => ALLOW_IMAGE_EXTENSIONS.includes(file.type))
      .map((file) => {
        return {
          file,
          name: toLowerCaseExtension(file.name),
          type: 'new',
        };
      });

    const slicedImages = [...images, ...newImages].slice(0, IMAGE_MAX_COUNT);

    formik.setFieldValue('images', slicedImages);

    // 同じ名前のファイル名を追加するためにvalueをリセットする
    e.target.value = '';
  };

  const handleClickRemove = (index: number) => {
    formik.setFieldValue(
      'images',
      images.filter((_, i) => i !== index),
    );
  };

  return (
    <Box>
      <ContentHeader mb={2}>
        {intl.formatMessage(
          {
            id: 'pages.GatewaysMemos.memoList.memoDialog.images.sectionTitle',
          },
          { number: IMAGE_MAX_COUNT },
        )}
      </ContentHeader>
      {images.length > 0 && (
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          gap={1}
          pb={2}
        >
          {images.map((img, i) => (
            <ImageItem
              key={img.name + i}
              index={i}
              img={img}
              onShowBiggerImage={toggleBiggerImage}
              onClickRemove={handleClickRemove}
            />
          ))}
        </Box>
      )}
      <Box display="flex" justifyContent="center" pb={1}>
        <SecondaryButton
          component="label"
          tabIndex={-1}
          disabled={IMAGE_MAX_COUNT <= images.length}
        >
          <AddPhotoAlternateRounded fontSize="small" sx={{ mr: 1 }} />
          {intl.formatMessage({
            id: 'pages.GatewaysMemos.memoList.memoDialog.images.uploadButton',
          })}
          <input
            onChange={handleAddImages}
            type="file"
            accept={ALLOW_IMAGE_EXTENSIONS_STRING}
            multiple
            style={{ position: 'absolute', width: 0, height: 0, opacity: 0 }}
          />
        </SecondaryButton>
      </Box>
    </Box>
  );
}

const ImageItem = React.memo(function ImageItem({
  img,
  index,
  onShowBiggerImage,
  onClickRemove,
}: {
  img: FormValues['images'][number];
  index: number;
  onShowBiggerImage: (index: number) => void;
  onClickRemove: (index: number) => void;
}) {
  const thumbnailUrl = useThumbnail(img);

  const handleClickRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickRemove(index);
  };

  return (
    <Box
      position="relative"
      sx={{
        opacity: thumbnailUrl ? 1 : 0,
        transition: 'opacity 0.5s',
      }}
    >
      <IconButton
        onClick={handleClickRemove}
        sx={{
          position: 'absolute',
          top: -5,
          right: -5,
          '&:hover': {
            opacity: 0.5,
          },
        }}
      >
        <CancelOutlined color="primary" fontSize="large" sx={{ zIndex: 99 }} />
        <Box
          position="absolute"
          display="flex"
          bgcolor="white"
          width={28}
          height={28}
          borderRadius="50%"
          zIndex={9}
        ></Box>
      </IconButton>
      <MemoImage
        src={thumbnailUrl}
        alt={img.name}
        width={{ xs: 125, md: 165 }}
        height={{ xs: 125, md: 165 }}
        onClick={() => onShowBiggerImage(index)}
      />
    </Box>
  );
});
