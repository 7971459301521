import { createSvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

const _DocumentIcon = createSvgIcon(
  <g clipPath="url(#clip0_9343_28781)">
    <path d="M19.9634 1.30906V18.9927L16.2652 22.6909H4.03614V1.30906H19.9634ZM20.6507 -2.4627e-05H3.34886C3.26772 -0.00147377 3.1871 0.0132532 3.11172 0.0433048C3.03634 0.0733564 2.96771 0.118134 2.90982 0.175008C2.85193 0.231881 2.80596 0.29972 2.77457 0.374559C2.74319 0.449398 2.72704 0.52973 2.72705 0.610882V23.3782C2.72705 23.5431 2.79256 23.7012 2.90917 23.8179C3.02579 23.9345 3.18394 24 3.34886 24H16.5489C16.7167 23.9999 16.8775 23.9332 16.9961 23.8145L21.0871 19.7127C21.1465 19.6568 21.1937 19.5891 21.2256 19.514C21.2575 19.4389 21.2735 19.358 21.2725 19.2763V0.610882C21.2696 0.447863 21.2029 0.292501 21.0866 0.178237C20.9702 0.0639724 20.8137 -4.97203e-05 20.6507 -2.4627e-05V-2.4627e-05Z" />
    <path
      d="M19.9634 1.30906V18.9927L16.2652 22.6909H4.03614V1.30906H19.9634ZM20.6507 -2.4627e-05H3.34886C3.26772 -0.00147377 3.1871 0.0132532 3.11172 0.0433048C3.03634 0.0733564 2.96771 0.118134 2.90982 0.175008C2.85193 0.231881 2.80596 0.29972 2.77457 0.374559C2.74319 0.449398 2.72704 0.52973 2.72705 0.610882V23.3782C2.72705 23.5431 2.79256 23.7012 2.90917 23.8179C3.02579 23.9345 3.18394 24 3.34886 24H16.5489C16.7167 23.9999 16.8775 23.9332 16.9961 23.8145L21.0871 19.7127C21.1465 19.6568 21.1937 19.5891 21.2256 19.514C21.2575 19.4389 21.2735 19.358 21.2725 19.2763V0.610882C21.2696 0.447863 21.2029 0.292501 21.0866 0.178237C20.9702 0.0639724 20.8137 -4.97203e-05 20.6507 -2.4627e-05V-2.4627e-05Z"
      fillOpacity="0.2"
    />
    <path d="M15.4253 21.36V18.1418H18.6435L15.4253 21.36Z" />
    <path d="M15.4253 21.36V18.1418H18.6435L15.4253 21.36Z" fillOpacity="0.2" />
    <path d="M17.465 5.60721H6.55592C6.38232 5.60721 6.21583 5.53825 6.09308 5.4155C5.97033 5.29275 5.90137 5.12627 5.90137 4.95268C5.90137 4.77908 5.97033 4.61259 6.09308 4.48984C6.21583 4.36709 6.38232 4.29813 6.55592 4.29813H17.465C17.6386 4.29813 17.8051 4.36709 17.9279 4.48984C18.0506 4.61259 18.1196 4.77908 18.1196 4.95268C18.1196 5.12627 18.0506 5.29275 17.9279 5.4155C17.8051 5.53825 17.6386 5.60721 17.465 5.60721V5.60721Z" />
    <path
      d="M17.465 5.60721H6.55592C6.38232 5.60721 6.21583 5.53825 6.09308 5.4155C5.97033 5.29275 5.90137 5.12627 5.90137 4.95268C5.90137 4.77908 5.97033 4.61259 6.09308 4.48984C6.21583 4.36709 6.38232 4.29813 6.55592 4.29813H17.465C17.6386 4.29813 17.8051 4.36709 17.9279 4.48984C18.0506 4.61259 18.1196 4.77908 18.1196 4.95268C18.1196 5.12627 18.0506 5.29275 17.9279 5.4155C17.8051 5.53825 17.6386 5.60721 17.465 5.60721V5.60721Z"
      fillOpacity="0.2"
    />
    <path d="M17.465 9.82903H6.55592C6.38232 9.82903 6.21583 9.76007 6.09308 9.63732C5.97033 9.51457 5.90137 9.34808 5.90137 9.17448C5.90137 9.00088 5.97033 8.83439 6.09308 8.71164C6.21583 8.58889 6.38232 8.51993 6.55592 8.51993H17.465C17.6386 8.51993 17.8051 8.58889 17.9279 8.71164C18.0506 8.83439 18.1196 9.00088 18.1196 9.17448C18.1196 9.34808 18.0506 9.51457 17.9279 9.63732C17.8051 9.76007 17.6386 9.82903 17.465 9.82903V9.82903Z" />
    <path
      d="M17.465 9.82903H6.55592C6.38232 9.82903 6.21583 9.76007 6.09308 9.63732C5.97033 9.51457 5.90137 9.34808 5.90137 9.17448C5.90137 9.00088 5.97033 8.83439 6.09308 8.71164C6.21583 8.58889 6.38232 8.51993 6.55592 8.51993H17.465C17.6386 8.51993 17.8051 8.58889 17.9279 8.71164C18.0506 8.83439 18.1196 9.00088 18.1196 9.17448C18.1196 9.34808 18.0506 9.51457 17.9279 9.63732C17.8051 9.76007 17.6386 9.82903 17.465 9.82903V9.82903Z"
      fillOpacity="0.2"
    />
    <path d="M17.465 14.0618H6.55592C6.38232 14.0618 6.21583 13.9928 6.09308 13.8701C5.97033 13.7473 5.90137 13.5808 5.90137 13.4072C5.90137 13.2336 5.97033 13.0671 6.09308 12.9444C6.21583 12.8216 6.38232 12.7527 6.55592 12.7527H17.465C17.6386 12.7527 17.8051 12.8216 17.9279 12.9444C18.0506 13.0671 18.1196 13.2336 18.1196 13.4072C18.1196 13.5808 18.0506 13.7473 17.9279 13.8701C17.8051 13.9928 17.6386 14.0618 17.465 14.0618V14.0618Z" />
    <path
      d="M17.465 14.0618H6.55592C6.38232 14.0618 6.21583 13.9928 6.09308 13.8701C5.97033 13.7473 5.90137 13.5808 5.90137 13.4072C5.90137 13.2336 5.97033 13.0671 6.09308 12.9444C6.21583 12.8216 6.38232 12.7527 6.55592 12.7527H17.465C17.6386 12.7527 17.8051 12.8216 17.9279 12.9444C18.0506 13.0671 18.1196 13.2336 18.1196 13.4072C18.1196 13.5808 18.0506 13.7473 17.9279 13.8701C17.8051 13.9928 17.6386 14.0618 17.465 14.0618V14.0618Z"
      fillOpacity="0.2"
    />
  </g>,
  'DocumentIcon',
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DocumentIcon = styled(({ ...props }) => (
  <_DocumentIcon {...props} />
)).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 24 24',
}))<SvgIconProps>`
  height: 24px;
  width: 24px;
`;

export default DocumentIcon;
