import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _BatteryUSBIcon = createSvgIcon(
  <>
    <g clipPath="url(#clip0_14748_67714)">
      <path
        d="M13.53 7.00737C13.3694 6.84187 13.1764 6.71119 12.9631 6.62349C12.7498 6.53579 12.5207 6.49295 12.29 6.49762H11.03V1.5H9.28003V6.49762H4.73004V1.5H2.98004V6.49762H1.75003C1.51984 6.4963 1.29168 6.54063 1.07876 6.62807C0.865845 6.7155 0.672409 6.84429 0.509642 7.00698C0.346875 7.16967 0.217998 7.36303 0.130522 7.57584C0.0430462 7.78866 -0.00129783 8.01671 2.89143e-05 8.24679V14.5138L4.15002 19.0616V22.5H9.86001V19.0616L14 14.5138V8.24679C14.0099 8.01913 13.9733 7.79185 13.8925 7.57877C13.8117 7.36569 13.6884 7.17128 13.53 7.00737Z"
        fill="#50D25A"
      />
    </g>
    <defs>
      <clipPath id="clip0_14748_67714">
        <rect width="14" height="24" fill="white" />
      </clipPath>
    </defs>
  </>,
  'BatteryUSB',
);

const BatteryUSBIcon = styled(_BatteryUSBIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 14 24',
}))`
  fill: none;
  height: 24px;
  width: 14px;
`;

export default BatteryUSBIcon;
