import { Form as FormikForm, FormikProvider } from 'formik';
import React from 'react';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import ValidationBox from '../../../../commons/form/ValidationBox';
import SubmitButton from '../../../../commons/form/SubmitButton';
import { errorSelector } from '../slice';
import EmailField from './form/EmailField';
import PasswordField from './form/PasswordField';
import PasswordConfirmationField from './form/PasswordConfirmationField';
import useFormikInstance from './form/useFormikInstance';

const Form: React.FC = () => {
  const error = useSelector(errorSelector);
  const intl = useIntl();
  const formik = useFormikInstance();

  return (
    <>
      {error && error.recoverable && (
        <Box mb={4}>
          <Alert severity="error">
            {intl.formatMessage({
              id: `users.new.error.${error.code}`,
            })}
          </Alert>
        </Box>
      )}
      <Box mb={7}>
        <EmailField />
      </Box>
      <FormikProvider value={formik}>
        <FormikForm>
          <ValidationBox>
            <PasswordField />
          </ValidationBox>
          <ValidationBox>
            <PasswordConfirmationField />
          </ValidationBox>
          <Box mt={4} mb={4}>
            <SubmitButton data-testid="SubmitButton" fullWidth>
              <Box>
                {intl.formatMessage({
                  id: 'users.new.form.submit',
                })}
              </Box>
            </SubmitButton>
          </Box>
        </FormikForm>
      </FormikProvider>
    </>
  );
};

export default Form;
