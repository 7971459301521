import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface Workspace {
  workspaceId: string;
  name: string;
}

export type WorkspaceErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'workspace_not_found'
  | 'emergency_maintenance'
  | 'unknown_error';

export interface PatchWorkspacesParams {
  token: Promise<string>;
  workspaceId: string;
  name: string;
}

export const patchWorkspace = createAsyncThunk<
  Workspace,
  PatchWorkspacesParams,
  { state: RootState; rejectValue: RootError<WorkspaceErrorCode> }
>('workspaces/patchWorkspace', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId, name } = params;
  const body = { name };
  try {
    const response = await axios.patch<Omit<Workspace, 'workspaceId'>>(
      `${API_ENDPOINT}/workspaces/${workspaceId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const { name: updatedName } = response.data;
    return { workspaceId, name: updatedName };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspaceError = getPatchWorkspaceError(error);
    return thunkAPI.rejectWithValue(workspaceError);
  }
});

const getPatchWorkspaceError = (
  error: AxiosError<RootErrorResponse>,
): RootError<WorkspaceErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'workspace_not_found',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface WorkspacesState {
  updateResult: Result<
    Omit<Workspace, 'workspaceId'>,
    RootError<WorkspaceErrorCode>
  >;
}

const initialState: WorkspacesState = {
  updateResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(patchWorkspace.pending, (state) => {
        state.updateResult.data = null;
        state.updateResult.status = 'loading';
        state.updateResult.error = null;
      })
      .addCase(patchWorkspace.fulfilled, (state, action) => {
        state.updateResult.data = action.payload;
        state.updateResult.status = 'succeeded';
        state.updateResult.error = null;
      })
      .addCase(patchWorkspace.rejected, (state, action) => {
        state.updateResult.data = null;
        state.updateResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.updateResult.error = action.payload!;
      });
  },
});

export const updateResultSelector = createSelector(
  (state: RootState) => state.workspaces,
  (state: WorkspacesState) => state.updateResult,
);

export default workspaceSlice.reducer;
