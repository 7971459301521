import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'src/domains/root/store';
import Box from '@mui/material/Box';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import HookStatus from 'src/domains/root/commons/HookStatus';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import GatewayData from '../../gateways/GatewayDataV3';
import GatewayName from '../../gateways/GatewayNameV3';
import GatewayPaper from '../../gateways/GatewayPaperV3';
import useGateways from '../../gateways/useGateways';
import Dialog from './sensor-data/Dialog';
import { removeResultSelector as sensorDataRemoveResultSelector } from './sensor-data/slice';

const SensorData: React.FC = () => {
  const intl = useIntl();
  const [targetGatewayId, setTargetGatewayId] = useState<string | null>(null);
  const location = useLocation();
  useEffect(() => {
    if (targetGatewayId) {
      awsRum().then((rum) => {
        rum.recordPageView(
          location.pathname + '#GatewaysSensorDataDialogRender',
        );
      });
    }
  }, [location, targetGatewayId]);

  const { status: sensorDataRemoveResultStatus } = useSelector(
    sensorDataRemoveResultSelector,
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { gateways, error, status } = useGateways();

  const loading = status === 'loading';
  const noData = !gateways.length;

  if (loading || !!error || noData) {
    return (
      <HookStatus
        target="gatewaySettingTable"
        loading={loading}
        error={!!error}
        noData={noData}
      />
    );
  }

  const handleOpen = (gatewayId: string) => {
    setTargetGatewayId(gatewayId);
  };

  const handleClose = () => setTargetGatewayId(null);

  return (
    <>
      {gateways.map((gateway, index) => (
        <GatewayPaper data-testid={`Gateway${index}`} key={gateway.deviceId}>
          <GatewayName
            gateway={gateway}
            displayBattery={isSmallScreen ? false : true}
            flexColumn={isSmallScreen ? true : false}
            gatewayNameBoxFullWidth={isSmallScreen ? true : false}
            childrenBoxFullWidth={isSmallScreen ? true : false}
          >
            <Box
              display="flex"
              whiteSpace="nowrap"
              justifyContent="end"
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                },
              })}
            >
              <SecondaryButton
                data-testid="DeleteSensorDataButton"
                onClick={() => handleOpen(gateway.deviceId)}
                sx={{
                  width: isSmallScreen ? '100%' : undefined,
                }}
              >
                {intl.formatMessage({
                  id: 'pages.OtherSetting.gateways.sensorData.bulkDeleteButton',
                })}
              </SecondaryButton>
            </Box>
          </GatewayName>
          <Box p={(theme) => theme.spacing(3)}>
            <GatewayData gateway={gateway} />
          </Box>
        </GatewayPaper>
      ))}
      {!!targetGatewayId && (
        <Dialog targetGatewayId={targetGatewayId} onClose={handleClose} />
      )}
      <LoadingOverlay open={sensorDataRemoveResultStatus === 'loading'} />
    </>
  );
};

export default SensorData;
