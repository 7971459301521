import React from 'react';
import { useIntl } from 'react-intl';
import Alert, { AlertProps } from '@mui/material/Alert';

const ErrorAlert: React.FC<AlertProps> = (props) => {
  const intl = useIntl();

  return (
    <Alert severity="error" {...props}>
      {intl.formatMessage({
        id: 'common.failUpdate',
      })}
    </Alert>
  );
};

export default ErrorAlert;
