import { InputField } from 'src/shared/ui/InputField';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import BackButton from 'src/domains/root/commons/buttons/Back';
import { Alert, Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { FormikProps } from 'formik';
import { CompanySchema } from './companySchema';

export default function CompanyForm({
  formik,
  npErrors,
  goBack,
}: {
  formik: FormikProps<CompanySchema>;
  npErrors: string[] | undefined;
  goBack: () => void;
}) {
  const intl = useIntl();

  const handleClickBack = () => {
    goBack();
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      formik.setFieldTouched(event.target.name, true);
    }
  };

  return (
    <Box width={544} pt={5}>
      <ErrorMessages npErrors={npErrors} />

      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column" gap={4}>
          <InputField
            label={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.name.label',
            })}
            type="text"
            placeholder={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.name.placeholder',
            })}
            name="companyName"
            onChange={formik.handleChange}
            onFocus={handleFocus}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
            sx={{ height: 60 }}
          />
          <InputField
            label={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.zipCode.label',
            })}
            type="text"
            inputMode="numeric"
            placeholder="0000000"
            name="zipCode"
            onChange={formik.handleChange}
            onFocus={handleFocus}
            onBlur={formik.handleBlur}
            value={formik.values.zipCode}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
            FormHelperTextProps={{
              sx: { position: 'relative', top: 18 },
            }}
            sx={{ height: 85 }}
          />
          <Typography
            height={0}
            fontSize={12}
            color="#969696"
            position="relative"
            mt={-4}
            top={-42}
          >
            {intl.formatMessage({ id: 'validation.helperText.noHyphen' })}
          </Typography>
          <InputField
            label={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.address.label',
            })}
            type="text"
            placeholder={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.address.placeholder',
            })}
            name="address"
            onChange={formik.handleChange}
            onFocus={handleFocus}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            FormHelperTextProps={{
              sx: { position: 'relative', top: 18 },
            }}
            sx={{ height: 85 }}
          />
          <Typography
            height={0}
            fontSize={12}
            color="#969696"
            position="relative"
            mt={-4}
            top={-42}
          >
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.address.helperText',
            })}
          </Typography>
          <InputField
            label={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.tel.label',
            })}
            type="tel"
            placeholder="00000000"
            name="telNo"
            onChange={formik.handleChange}
            onFocus={handleFocus}
            onBlur={formik.handleBlur}
            value={formik.values.telNo}
            error={formik.touched.telNo && Boolean(formik.errors.telNo)}
            helperText={formik.touched.telNo && formik.errors.telNo}
            FormHelperTextProps={{
              sx: { position: 'relative', top: 18 },
            }}
            sx={{ height: 85 }}
          />
          <Typography
            height={0}
            fontSize={12}
            color="#969696"
            position="relative"
            mt={-4}
            top={-42}
          >
            {intl.formatMessage({
              id: 'validation.helperText.noHyphen_WithDescription',
            })}
          </Typography>
          <InputField
            label={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.email.label',
            })}
            type="email"
            placeholder="nito@example.jp"
            name="email"
            onChange={formik.handleChange}
            onFocus={handleFocus}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ height: 60 }}
          />
          <Box display="flex" height={44} gap={3}>
            <BackButton
              fullWidth={false}
              onClick={handleClickBack}
              sx={{ fontSize: 18 }}
            />
            <PrimaryButton fullWidth type="submit" disabled={!formik.isValid}>
              <Typography fontSize={{ xs: 16, sm: 18 }} fontWeight="bold">
                {intl.formatMessage({
                  id: 'pages.OtherSetting.contracts.payment-method.invoice.companyForm.submitButton',
                })}
              </Typography>
            </PrimaryButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

function ErrorMessages({ npErrors }: { npErrors?: string[] }) {
  const intl = useIntl();

  const npErrorsOrder = [
    'E1999311',
    'E1999507',
    'E1999320',
    'E1999323',
    'E1999328',
  ];
  const orderedNpErrors = npErrorsOrder.filter((npError) =>
    npErrors?.includes(npError),
  );

  return orderedNpErrors?.length ? (
    <Box pb={5} display="grid" rowGap={1}>
      {orderedNpErrors.map((error, i) => (
        <Alert key={i} severity="error">
          {intl.formatMessage({
            id: `pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.errors.${error}`,
          })}
        </Alert>
      ))}
    </Box>
  ) : null;
}
