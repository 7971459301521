import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { awsRum } from 'src/utils/rum';
import { AppDispatch } from '../../store';
import { activateWorkspace } from '../users/slice';
import LoadingOverlay from '../../commons/LoadingOverlay';
import { RootError } from '../../utils/api';

interface WorkspaceRedirectParams {
  redirectTo: string;
}

const errorRoute: Record<string, string> = {
  permission_denied: '/errors/workspace-not-found',
  emergency_maintenance: '/errors/emergency-maintenance',
  unknown_error: '/errors/unknown-error',
};

const FALLBACK_PATH = '/errors/unknown-error';

const WorkspaceRedirect: React.FC<WorkspaceRedirectParams> = ({
  redirectTo,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const [{ status, error }, setStatus] = useState<{
    status: string;
    error?: RootError;
  }>({ status: 'idle' });

  useEffect(() => {
    const redirectWorkspace = async () => {
      if (workspaceId) {
        try {
          await dispatch(
            activateWorkspace({
              token,
              workspaceId,
            }),
          ).unwrap();
          setStatus({ status: 'success' });
        } catch (e) {
          awsRum().then((rum) => rum.recordError(e)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
          const error = e as RootError;
          setStatus({ status: 'failed', error });
        }
      }
    };

    const token = getAccessTokenSilently();
    redirectWorkspace();
  }, [dispatch, navigate, getAccessTokenSilently, workspaceId]);

  if (status === 'idle') {
    return <LoadingOverlay open={true} />;
  }

  if (status === 'failed') {
    return <Navigate to={errorRoute[error?.code ?? ''] ?? FALLBACK_PATH} />;
  }

  return <Navigate to={redirectTo} />;
};

export default WorkspaceRedirect;
