import React from 'react';
import { useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface HookStatusProps {
  target: string;
  error: Error | boolean | null;
  loading: boolean;
  noData?: boolean;
}

const HookStatus: React.FC<HookStatusProps> = (props) => {
  const { target, error, loading, noData } = props;
  const intl = useIntl();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100px', padding: '10px' }}
      >
        <CircularProgress data-testid="CircularProgress" />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ my: 1.25 }}>
        {intl.formatMessage({
          id: `${target}.alertError`,
        })}
      </Alert>
    );
  }

  if (noData) {
    return (
      <Box py={2}>
        <Alert severity="info">
          {intl.formatMessage({
            id: `${target}.alertInfo`,
          })}
        </Alert>
      </Box>
    );
  }

  return null;
};

export default HookStatus;
