import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppDispatch, useSelector } from '../../../store';
import LoadingOverlay from '../../../commons/LoadingOverlay';
import {
  putUsersEmailChange,
  updateResultSelector,
  UsersEmailChangeErrorCode,
} from './slice';

interface UpdateProps {
  redirectTo: {
    [key in UsersEmailChangeErrorCode]?: string;
  };
  children: (updated: boolean) => React.ReactNode;
}

const FALLBACK_PATH = '/errors/unknown-error';

const Update: React.FC<UpdateProps> = ({ redirectTo, children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const { search } = useLocation();
  const { status, error } = useSelector(updateResultSelector);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const emailChangeId = query.get('email_change_id') ?? '';
    const token = getAccessTokenSilently();
    const promise = dispatch(putUsersEmailChange({ token, emailChangeId }));
    return () => promise.abort();
  }, [getAccessTokenSilently, dispatch, search]);

  if (status === 'idle' || status === 'loading') {
    return <LoadingOverlay open={true} />;
  }

  if (status === 'failed') {
    if (!error || error.recoverable) {
      return <>{children(false)}</>;
    } else {
      const path = redirectTo[error.code] ?? FALLBACK_PATH;
      return <Navigate to={path} />;
    }
  }

  return <>{children(true)}</>;
};

export default Update;
