import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import { useDispatch } from 'react-redux';
import {
  activeWorkspaceSelector,
  Workspace as Workspace,
} from 'src/domains/root/features/users/slice';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useIntl } from 'react-intl';
import { getUsersMe } from 'src/domains/root/features/users/slice';
import { Step } from 'src/domains/root/pages/other-setting/packages/New';
import {
  getWorkspacesUsers,
  listStatusSelector,
  listErrorSelector,
  usersSelector,
} from '../users/slice';
import WorkspaceStep from './new/Workspace';
import GatewayStep from './new/Gateway';
import { resetCreateResult } from './slice';

export type TemporaryValues = {
  workspaceName: string;
  registrationCode: string;
  imei: string;
  gatewayName: string;
  adminUserId: string;
};

type Props = {
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
};

const New: React.FC<Props> = (props) => {
  const { step, setStep } = props;

  const { user: me, getAccessTokenSilently } = useAuth0();

  const [temporaryValues, setTemporaryValues] = useState<TemporaryValues>({
    workspaceName: '',
    registrationCode: '',
    imei: '',
    gatewayName: '',
    adminUserId: '',
  });

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const users = useSelector(usersSelector) || [];
  const usersStatus = useSelector(listStatusSelector);
  const usersError = useSelector(listErrorSelector);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const handleForward = (
    values: Pick<TemporaryValues, 'workspaceName' | 'adminUserId'>,
  ) => {
    setTemporaryValues({ ...temporaryValues, ...values });
    setStep('step2');
    dispatch(resetCreateResult());
  };

  const handleBack = (
    values: Pick<TemporaryValues, 'registrationCode' | 'imei' | 'gatewayName'>,
  ) => {
    setTemporaryValues({ ...temporaryValues, ...values });
    setStep('step1');
  };

  const handleSubmit = () => {
    const token = getAccessTokenSilently();
    dispatch(getUsersMe({ token, silent: true }));
    navigate('../created');
  };

  useEffect(() => {
    if (!me) return;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setTemporaryValues({ ...temporaryValues, adminUserId: me.sub! });
  }, [me]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!workspace.workspaceId) return;

    const token = getAccessTokenSilently();
    const promise = dispatch(
      getWorkspacesUsers({
        token,
        workspaceId: workspace.workspaceId,
      }),
    );

    return () => promise.abort();
  }, [workspace]); // eslint-disable-line react-hooks/exhaustive-deps

  const switchStep = () => {
    switch (step) {
      case 'step1':
        return (
          <WorkspaceStep
            adminUsers={users}
            values={temporaryValues}
            onForward={handleForward}
          />
        );
      case 'step2':
        return (
          <GatewayStep
            values={temporaryValues}
            onBack={handleBack}
            onSubmit={handleSubmit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {usersStatus === 'loading' && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100px', padding: '10px' }}
        >
          <CircularProgress />
        </Box>
      )}
      {usersStatus === 'failed' && usersError && (
        <Alert severity="error">
          {intl.formatMessage({
            id: `features.workspaces.packages.new.workspace.alert.error.${usersError.code}`,
          })}
        </Alert>
      )}
      {usersStatus === 'succeeded' && switchStep()}
    </>
  );
};

export default New;
