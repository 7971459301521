import { Box } from '@mui/material';
import React from 'react';
import {
  PowerSupply,
  SensorUnit,
} from 'src/domains/root/features/gateways/slice';
import ChartLinkButton from '../../commons/ChartLinkButton';
import CustomTableCell from '../../commons/CustomTableCell';
import SensorHeartbeatCell from '../../commons/SensorHeartbeatCell';
import SensorUnitName from '../sensor-units/SensorUnitName';
import UnitizedValue from '../sensor-units/UnitizedValue';

interface GatewayTableRowProps {
  powerSupply: PowerSupply | undefined;
  sensorUnit: SensorUnit;
  onClickChartLinkButton: () => void;
}

const GatewayTableRow: React.FC<GatewayTableRowProps> = (props) => {
  const { powerSupply, sensorUnit, onClickChartLinkButton } = props;

  return (
    <>
      <CustomTableCell component="th" scope="SensorUnit" align="center">
        {sensorUnit.number}
      </CustomTableCell>
      <CustomTableCell align="left">
        <SensorUnitName powerSupply={powerSupply} battery={sensorUnit.battery}>
          {sensorUnit.name}
        </SensorUnitName>
      </CustomTableCell>
      <SensorHeartbeatCell heartBeat={sensorUnit.isAlive} />
      <CustomTableCell align="center">
        <Box>
          <span>
            {sensorUnit.temperature === 1
              ? '有'
              : sensorUnit.temperature === 0
                ? '無'
                : '-'}
          </span>
        </Box>
      </CustomTableCell>
      <CustomTableCell align="center">
        <Box>
          {sensorUnit.humidity == null
            ? '-'
            : `${Math.floor(sensorUnit.humidity)} hPa`}
        </Box>
      </CustomTableCell>
      <CustomTableCell align="center">
        <UnitizedValue
          value={sensorUnit.objectTemperature}
          type="objectTemperature"
        />
      </CustomTableCell>
      <CustomTableCell align="center">
        <ChartLinkButton
          observeMode={sensorUnit.observe.mode}
          onClickAlertChartButton={onClickChartLinkButton}
        />
      </CustomTableCell>
    </>
  );
};

export default GatewayTableRow;
