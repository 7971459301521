import React from 'react';
import Alert from '@mui/material/Alert';
import { Box, Typography } from '@mui/material';
import CloseIconButton from '../../snackbar/actions/CloseIconButton';

type Props = {
  onClose?: () => void;
  children: React.ReactNode;
};
export default function AlertNotification({ onClose, children }: Props) {
  const close = () => {
    onClose?.();
  };

  return (
    <Alert
      icon={false}
      sx={{
        px: {
          xs: 2,
          sm: 3,
        },
        py: 0.5,
        backgroundColor: '#FFBC59',
        fontSize: {
          xs: 14,
          sm: 16,
        },
        fontWeight: 700,
        color: '#000',
        alignItems: 'center',
        borderRadius: 0,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          lineHeight={1.5}
          fontSize={{
            xs: 14,
            sm: 16,
          }}
          fontWeight={700}
          pr={4.5}
        >
          {children}
        </Typography>
        {onClose && (
          <CloseIconButton
            onClick={close}
            sx={{
              position: 'absolute',
              right: 16,
              svg: {
                width: 30,
                height: 30,
              },
            }}
          />
        )}
      </Box>
    </Alert>
  );
}
