import React from 'react';
import getAwsRum from './aws-rum-web';

export default class RumErrorRecorder extends React.Component<{
  children: React.ReactNode;
}> {
  componentDidCatch(error: Error) {
    getAwsRum().then((awsRum) => {
      awsRum.recordError(error);
    });
  }
  render() {
    return <>{this.props.children}</>;
  }
}
