import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Title from 'src/domains/root/commons/dialog/Title';
import Box from '@mui/material/Box';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';

interface ConfirmDialogProps {
  isOpen: boolean;
  workspaceName: string;
  onClose: () => void;
}

interface FormValues {
  email: string;
  role: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { isOpen, workspaceName, onClose: handleClose } = props;
  const intl = useIntl();
  const formatMessage = (id: string, values = {}) =>
    intl.formatMessage({ id }, values);
  const { values, isValid, isSubmitting, handleSubmit } =
    useFormikContext<FormValues>();

  const location = useLocation();
  useEffect(() => {
    if (isOpen) {
      awsRum().then((rum) => {
        rum.recordPageView(
          location.pathname + '#InvitationConfirmDialogRender',
        );
      });
    }
  }, [location, isOpen]);

  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth>
      <DialogTitle id="workspaces-users-dialog-title">
        <Title onClose={handleClose}>
          {intl.formatMessage({
            id: 'pages.OtherSetting.users.invitation.dialog.title',
          })}
        </Title>
      </DialogTitle>
      <DialogContent dividers>
        <Box py={1}>
          <Typography sx={{ wordBreak: 'break-all' }}>
            {intl.formatMessage({
              id: 'pages.OtherSetting.users.invitation.dialog.heading',
            })}
          </Typography>
          <Box sx={{ wordBreak: 'break-all', mt: 3 }}>
            <Box display="flex">
              <Typography
                sx={{ minWidth: 120, display: 'inline-block' }}
                component="span"
              >
                {formatMessage(
                  'pages.OtherSetting.users.invitation.dialog.description.workspace_name',
                )}
              </Typography>
              <Typography fontWeight="bold" pr={1}>
                :
              </Typography>
              <Typography fontWeight="bold">{workspaceName}</Typography>
            </Box>
            <Box display="flex">
              <Typography
                sx={{ minWidth: 120, display: 'inline-block' }}
                component="span"
              >
                {formatMessage(
                  'pages.OtherSetting.users.invitation.dialog.description.email',
                )}
              </Typography>
              <Typography fontWeight="bold" pr={1}>
                :
              </Typography>
              <Typography fontWeight="bold">{values.email}</Typography>
            </Box>
            <Box display="flex">
              <Typography
                sx={{ minWidth: 120, display: 'inline-block' }}
                component="span"
              >
                {formatMessage(
                  'pages.OtherSetting.users.invitation.dialog.description.permission',
                )}
              </Typography>
              <Typography fontWeight="bold" pr={1}>
                :
              </Typography>
              <Typography fontWeight="bold">
                {formatMessage(
                  `pages.OtherSetting.users.invitation.dialog.description.role.${values.role}`,
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <NegativeButton onClick={handleClose}>
          {intl.formatMessage({
            id: 'pages.OtherSetting.users.invitation.dialog.buttons.no',
          })}
        </NegativeButton>
        <PrimaryButton
          data-testid="SubmitButton"
          /**
           * Button系のリファクタリングで露呈した型不整合。
           * formのためのformikをbuttonイベントにセットしているから発生している。
           * handleSubmit はsubmitイベントにセットするものなので、このボタンが意図するformのonSubmitにセットし、
           * このボタンをformタグの中に入れて、`type='submit'`をセットすべき。
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={handleSubmit as any}
          disabled={!isValid || isSubmitting}
        >
          {intl.formatMessage({
            id: 'pages.OtherSetting.users.invitation.dialog.buttons.yes',
          })}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
