import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { Result, RootState } from 'src/domains/root/store';
import { RootError, RootErrorResponse } from '../../../utils/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface WorkspacesInvitation {
  invitationId: string;
  email: string;
  role: string;
  invitedAt: number;
  expiredAt: number;
}

export type WorkspacesInvitationsErrorCode =
  | GetWorkspacesInvitationsErrorCode
  | PostWorkspacesInvitationErrorCode
  | DeleteWorkspacesInvitationErrorCode;

interface GetWorkspacesInvitationsParams {
  token: Promise<string>;
  workspaceId: string;
}

interface GetWorkspacesInvitationsResponse {
  invitations: WorkspacesInvitation[];
}

export type GetWorkspacesInvitationsErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getWorkspacesInvitations = createAsyncThunk<
  WorkspacesInvitation[],
  GetWorkspacesInvitationsParams,
  {
    state: RootState;
    rejectValue: RootError<GetWorkspacesInvitationsErrorCode>;
  }
>('workspaces/invitations/getInvitations', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId } = params;

  try {
    const response = await axios.get<GetWorkspacesInvitationsResponse>(
      `${API_ENDPOINT}/workspaces/${workspaceId}/invitations`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          status: 'valid',
        },
      },
    );

    return response.data.invitations;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspacesInvitationsError = getWorkspacesInvitationsError(error);
    return thunkAPI.rejectWithValue(workspacesInvitationsError);
  }
});

export const getWorkspacesInvitationsError = (
  error: AxiosError<RootErrorResponse>,
): RootError<GetWorkspacesInvitationsErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

interface PostWorkspacesInvitationParams {
  token: Promise<string>;
  email: string;
  role: string;
  workspaceId: string;
}

export type PostWorkspacesInvitationErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'already_joined_user'
  | 'emergency_maintenance'
  | 'unknown_error';

interface WorkspacesInvitationsError<T> extends RootError<T> {
  email: string;
}

export const postWorkspacesInvitation = createAsyncThunk<
  Omit<WorkspacesInvitation, 'invitationId' | 'invitedAt' | 'expiredAt'>,
  PostWorkspacesInvitationParams,
  {
    state: RootState;
    rejectValue: WorkspacesInvitationsError<PostWorkspacesInvitationErrorCode>;
  }
>('workspaces/invitations/postInvitation', async (params, thunkAPI) => {
  const token = await params.token;
  const { email, workspaceId, role } = params;
  const body = { email, role };

  try {
    await axios.post<void>(
      `${API_ENDPOINT}/workspaces/${workspaceId}/invitations`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { email, role };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspacesInvitationsError = {
      ...getWorkspacesInvitationError(error),
      email,
      role,
    };
    return thunkAPI.rejectWithValue(workspacesInvitationsError);
  }
});

export const getWorkspacesInvitationError = (
  error: AxiosError<RootErrorResponse>,
): RootError<PostWorkspacesInvitationErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 409:
        return {
          code: 'already_joined_user',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

interface DeleteWorkspacesInvitationParams {
  token: Promise<string>;
  workspaceId: string;
  invitation: WorkspacesInvitation;
}

export type DeleteWorkspacesInvitationErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'not_found'
  | 'used_invitation'
  | 'expired_invitation'
  | 'emergency_maintenance'
  | 'unknown_error';

export const deleteWorkspacesInvitation = createAsyncThunk<
  WorkspacesInvitation,
  DeleteWorkspacesInvitationParams,
  {
    state: RootState;
    rejectValue: WorkspacesInvitationsError<DeleteWorkspacesInvitationErrorCode>;
  }
>('workspaces/invitations/deleteInvitation', async (params, thunkAPI) => {
  const token = await params.token;
  const { workspaceId, invitation } = params;

  try {
    await axios.delete<void>(
      `${API_ENDPOINT}/workspaces/${workspaceId}/invitations/${invitation.invitationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { ...invitation };
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const workspacesInvitationsError = {
      ...getDeleteWorkspacesInvitationError(error),
      email: invitation.email,
    };
    return thunkAPI.rejectWithValue(workspacesInvitationsError);
  }
});

export const getDeleteWorkspacesInvitationError = (
  error: AxiosError<RootErrorResponse>,
): RootError<DeleteWorkspacesInvitationErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'not_found',
          noticeable: false,
          recoverable: true,
        };
      case 409:
        return {
          code: 'used_invitation',
          noticeable: false,
          recoverable: true,
        };
      case 410:
        return {
          code: 'expired_invitation',
          noticeable: false,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: false,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: false,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: false,
    recoverable: true,
  };
};

export interface WorkspacesInvitationsState {
  listResult: Result<
    WorkspacesInvitation[],
    RootError<GetWorkspacesInvitationsErrorCode>
  >;
  createResult: Result<
    Omit<WorkspacesInvitation, 'invitationId' | 'invitedAt' | 'expiredAt'>,
    WorkspacesInvitationsError<PostWorkspacesInvitationErrorCode>
  >;
  removeResult: Result<
    WorkspacesInvitation,
    WorkspacesInvitationsError<DeleteWorkspacesInvitationErrorCode>
  >;
}

const initialState: WorkspacesInvitationsState = {
  listResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  createResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  removeResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

export const workspacesInvitationSlice = createSlice({
  name: 'workspaces/invitations',
  initialState,
  reducers: {
    resetCreateInvitation(state) {
      state.createResult.data = null;
      state.createResult.error = null;
      state.createResult.status = 'idle';
    },
    resetRemoveInvitation(state) {
      state.removeResult.data = null;
      state.removeResult.error = null;
      state.removeResult.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspacesInvitations.pending, (state) => {
        state.listResult.data = null;
        state.listResult.error = null;
        state.listResult.status = 'loading';
      })
      .addCase(getWorkspacesInvitations.fulfilled, (state, action) => {
        state.listResult.data = action.payload;
        state.listResult.error = null;
        state.listResult.status = 'succeeded';
      })
      .addCase(getWorkspacesInvitations.rejected, (state, action) => {
        state.listResult.data = null;
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.listResult.error = action.payload!;
        state.listResult.status = 'failed';
      })
      .addCase(postWorkspacesInvitation.pending, (state) => {
        state.createResult.data = null;
        state.createResult.error = null;
        state.createResult.status = 'loading';
      })
      .addCase(postWorkspacesInvitation.fulfilled, (state, action) => {
        state.createResult.data = action.payload;
        state.createResult.error = null;
        state.createResult.status = 'succeeded';
      })
      .addCase(postWorkspacesInvitation.rejected, (state, action) => {
        state.createResult.data = null;
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.createResult.error = action.payload!;
        state.createResult.status = 'failed';
      })
      .addCase(deleteWorkspacesInvitation.pending, (state) => {
        state.removeResult.data = null;
        state.removeResult.error = null;
        state.removeResult.status = 'loading';
      })
      .addCase(deleteWorkspacesInvitation.fulfilled, (state, action) => {
        state.removeResult.data = action.payload;
        state.removeResult.error = null;
        state.removeResult.status = 'succeeded';
      })
      .addCase(deleteWorkspacesInvitation.rejected, (state, action) => {
        state.removeResult.data = null;
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.removeResult.error = action.payload!;
        state.removeResult.status = 'failed';
      });
  },
});

export const listResultSelector = createSelector(
  (state: RootState) => state.workspacesInvitations,
  (state: WorkspacesInvitationsState) => state.listResult,
);

export const createResultSelector = createSelector(
  (state: RootState) => state.workspacesInvitations,
  (state: WorkspacesInvitationsState) => state.createResult,
);

export const removeResultSelector = createSelector(
  (state: RootState) => state.workspacesInvitations,
  (state: WorkspacesInvitationsState) => state.removeResult,
);

export const { resetCreateInvitation, resetRemoveInvitation } =
  workspacesInvitationSlice.actions;

export default workspacesInvitationSlice.reducer;
