import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import PrimaryButton from '../../commons/buttons/PrimaryButton';
import CabiotLogo from '../../commons/CabiotLogo';

interface UnrecoverableErrorProps {
  messageId?: string;
  message?: React.ReactNode;
  noButton?: boolean;
}

const APP_VERSION = import.meta.env.VITE_WEBAPP_VERSION;

const UnrecoverableError: React.FC<UnrecoverableErrorProps> = ({
  messageId,
  message,
  noButton = false,
}) => {
  const intl = useIntl();
  const currentTime = useMemo(
    () => DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
    [],
  );

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={7.5}>
          <CabiotLogo />
        </Box>
        <Box mt={8.5} mb={10} maxWidth={560}>
          <Typography variant="h4" align="center">
            {message || intl.formatMessage({ id: messageId })}
          </Typography>
        </Box>
        {!noButton && (
          <Box width={320}>
            <PrimaryButton data-testid="PrimaryButton" fullWidth href="/">
              {intl.formatMessage({ id: 'common.button.top' })}
            </PrimaryButton>
          </Box>
        )}
        <Box mt={4}>
          <Typography
            align="center"
            fontSize={(theme) => theme.typography.pxToRem(18)}
            color="darkgrey"
          >
            {intl.formatMessage({ id: `common.versionLabel` })}: {APP_VERSION}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography
            align="center"
            fontSize={(theme) => theme.typography.pxToRem(18)}
            color="darkgrey"
          >
            {currentTime}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default UnrecoverableError;
