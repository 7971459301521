import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../commons/buttons/PrimaryButton';

const Created: React.FC = () => {
  const intl = useIntl();

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch">
      <Box mt={8.5} mb={3}>
        <Typography variant="h2" align="center">
          {intl.formatMessage({
            id: 'registrations.users.created.title',
          })}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={5.5}
        height={36}
      >
        <Typography variant="subtitle1" fontWeight={400} align="center">
          {intl.formatMessage({
            id: 'registrations.users.created.subTitle',
          })}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <PrimaryButton fullWidth href="/" sx={{ maxWidth: 400 }}>
          {intl.formatMessage({ id: 'common.buttons.login' })}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default Created;
