import { useAuth0 } from '@auth0/auth0-react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import { SensorUnit } from '../slice';
import * as API from './api';
import { BeginAndEndTimestampResponse } from './type';

export type SucceededResult = {
  status: 'succeeded';
  data: { begin: number; end: number };
};

type ErrorCode = 'invalid_gatewayId' | 'gateway_not_found' | 'unknown_error';

export type Result =
  | { status: 'hasError'; code: ErrorCode }
  | { status: 'loading' }
  | { status: 'noData' }
  | SucceededResult;

export default function useBeginAndEndTimestamp({
  gatewayId,
  /**
   * 通常グラフではこれを指定する。
   * 指定するとセンサーユニットのbegin, endを返すようになる。
   */
  sensorUnit,
}: {
  gatewayId: string;
  sensorUnit?: SensorUnit;
}): Result {
  const { getAccessTokenSilently } = useAuth0();

  const result = useQuery<BeginAndEndTimestampResponse, API.Error>({
    queryKey: Keys.sensorData.beginAndEndTimestamp(gatewayId),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return API.getBeginAndEndTimestamp(gatewayId, token);
    },
    refetchOnWindowFocus: false,
  });

  useRedirectForError(result);

  if (result.isPending) return { status: 'loading' };
  if (result.isError) {
    const error = result.error;
    switch (error.code) {
      case 'not_found':
        return { status: 'hasError', code: 'gateway_not_found' };
      default:
        return { status: 'hasError', code: 'unknown_error' };
    }
  }

  const beginAndEnd = sensorUnit
    ? result.data.sensorUnits?.find((su) => su.number === sensorUnit.number)
    : result.data.gateway;

  const { begin, end } = beginAndEnd ?? {};
  if (begin === undefined || end === undefined) {
    return { status: 'noData' };
  }

  return {
    status: 'succeeded',
    data: { begin, end },
  };
}

function useRedirectForError(
  beginAndEndTimestampResult: UseQueryResult<
    BeginAndEndTimestampResponse,
    API.Error
  >,
) {
  const navigate = useNavigate();
  useEffect(() => {
    const error = beginAndEndTimestampResult.error;
    if (!error) {
      return;
    }
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  }, [beginAndEndTimestampResult.error, navigate]);
}
