import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';

export default function ContextMenu({
  onClickCsvDownload,
}: {
  onClickCsvDownload: () => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const intl = useIntl();

  const onOpenMenuButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenuButton = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ContextMenuButton onOpenMenuButton={onOpenMenuButton} />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onCloseMenuButton}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={onClickCsvDownload}
          sx={{ fontSize: 13, minHeight: 'unset' }}
        >
          {intl.formatMessage({
            id: 'sensorDataChart.contextMenu.csvDownload.name',
          })}
        </MenuItem>
      </Menu>
    </>
  );
}

function ContextMenuButton({
  onOpenMenuButton,
}: {
  onOpenMenuButton: (event: React.MouseEvent<HTMLElement>) => void;
}) {
  return (
    <IconButton
      onClick={onOpenMenuButton}
      role="menu"
      aria-label="context menu button"
    >
      <ContextMenuButtonHamburgerIcon />
    </IconButton>
  );
}

function ContextMenuButtonHamburgerIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="3" width="20" height="2" rx="1" fill="#F05064" />
      <rect y="9" width="20" height="2" rx="1" fill="#F05064" />
      <rect y="15" width="20" height="2" rx="1" fill="#F05064" />
    </svg>
  );
}
