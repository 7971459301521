import React from 'react';
import styled from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import _CircularProgress from '@mui/material/CircularProgress';

const CircularProgress = styled(_CircularProgress)`
  color: white;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
`;

/**
 * @deprecated './SubmitButton.tsx' を使ってください。
 * */
const ProgressableSubmitButton: React.FC<{ loading: boolean }> = (props) => {
  const { loading } = props;
  const intl = useIntl();
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <Box position="relative">
      <PrimaryButton
        data-testid="SubmitButton"
        type="submit"
        disabled={!isValid || isSubmitting}
      >
        {intl.formatMessage({
          id: 'common.update',
        })}
      </PrimaryButton>
      {loading && <CircularProgress size={24} />}
    </Box>
  );
};

export default ProgressableSubmitButton;
