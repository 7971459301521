import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { LOCAL_STORAGE_KEY } from 'src/constants';
import { awsRum } from 'src/utils/rum';
import { meResultSelector } from '../../../features/users/slice';
import AlertNotification from './AlertNotification';

const ContractExpiredAlertNotification: React.FC = () => {
  const intl = useIntl();

  const [openContractExpiredAlert, setOpenContractExpiredAlert] =
    useState<boolean>(false);
  const usersMeData = useSelector(meResultSelector);

  const handleAlertClose = () => {
    setOpenContractExpiredAlert(false);
    setHasLatestWorkspaceNearContractExpiration();
  };

  const getHasLatestWorkspaceNearContractExpiration = () => {
    return localStorage.getItem(
      LOCAL_STORAGE_KEY.HAS_LATEST_WORKSPACE_NEAR_CONTRACT_EXPIRATION,
    );
  };

  const setHasLatestWorkspaceNearContractExpiration = () => {
    try {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.HAS_LATEST_WORKSPACE_NEAR_CONTRACT_EXPIRATION,
        Date.now().toString(),
      );
    } catch (e) {
      awsRum().then((rum) => rum.recordError(e)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    }
  };

  useEffect(() => {
    const has24HoursElapsed = (lastDisplayTimestamp: string | null) => {
      if (!lastDisplayTimestamp) return true;
      const parsedTimestamp = Number(lastDisplayTimestamp);
      if (isNaN(parsedTimestamp)) return true;
      const twentyFourHoursLater = new Date(parsedTimestamp);
      twentyFourHoursLater.setUTCHours(twentyFourHoursLater.getUTCHours() + 24);
      return twentyFourHoursLater <= new Date();
    };
    const lastDisplayTimestamp = getHasLatestWorkspaceNearContractExpiration();

    if (
      has24HoursElapsed(lastDisplayTimestamp) &&
      usersMeData.data?.hasLatestWorkspaceNearContractExpiration
    ) {
      setOpenContractExpiredAlert(true);
    } else {
      setOpenContractExpiredAlert(false);
    }
  }, [usersMeData]);

  return (
    <>
      {openContractExpiredAlert && (
        <AlertNotification onClose={handleAlertClose}>
          {intl.formatMessage({
            id: 'globalHeader.alertForContractNearExpiration.message',
          })}
        </AlertNotification>
      )}
    </>
  );
};

export default ContractExpiredAlertNotification;
