import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/domains/root/store';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/domains/root/store';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import Table from './list/Table';
import {
  getWorkspacesInvitations,
  listResultSelector,
  removeResultSelector,
} from './slice';

const List: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: invitations,
    status: listStatus,
    error: listError,
  } = useSelector(listResultSelector);

  const {
    data: removedInvitation,
    status: removeStatus,
    error: removeError,
  } = useSelector(removeResultSelector);

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  useEffect(() => {
    if (!workspace.workspaceId) return;

    const token = getAccessTokenSilently();
    const promise = dispatch(
      getWorkspacesInvitations({
        token,
        workspaceId: workspace.workspaceId,
      }),
    );

    return () => promise.abort();
  }, [workspace.workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      {removeStatus === 'succeeded' && removedInvitation && (
        <Box mb={3.5}>
          <Alert sx={{ wordBreak: 'break-all' }}>
            {intl.formatMessage(
              {
                id: 'features.workspaces.invitations.list.dialog.alert.success.completed',
              },
              {
                email: (
                  <span
                    style={{
                      wordBreak: 'break-all',
                      WebkitLineBreak: 'anywhere',
                    }}
                  >
                    {removedInvitation.email}
                  </span>
                ),
              },
            )}
          </Alert>
        </Box>
      )}
      {removeStatus === 'failed' && !!removeError && (
        <Box mb={3.5}>
          <Alert severity="error" sx={{ wordBreak: 'break-all' }}>
            {intl.formatMessage(
              {
                id: `features.workspaces.invitations.list.dialog.alert.error.${removeError.code}`,
              },
              {
                email: removeError.email,
              },
            )}
          </Alert>
        </Box>
      )}
      <Box
        pl={1.5}
        borderLeft={(props) => `5px solid ${props.palette.nito.main}`}
      >
        <Box mb={2}>
          <Typography fontSize={18} fontWeight="bold">
            {intl.formatMessage({
              id: 'features.workspaces.invitations.list.title',
            })}
          </Typography>
        </Box>
      </Box>
      {listStatus === 'loading' && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100px', padding: '10px' }}
        >
          <CircularProgress />
        </Box>
      )}
      {listStatus === 'failed' && listError && (
        <Alert severity="error">
          {intl.formatMessage({
            id: `features.workspaces.invitations.list.alert.error.${listError.code}`,
          })}
        </Alert>
      )}
      {listStatus === 'succeeded' && (
        <Box mb={2}>
          {!invitations || invitations.length === 0 ? (
            <Box mt={4} ml={2}>
              {intl.formatMessage({
                id: 'features.workspaces.invitations.list.alert.info.no_invitations',
              })}
            </Box>
          ) : (
            <Box>
              <TableContainer>
                <Table
                  workspaceId={workspace.workspaceId}
                  invitations={invitations}
                />
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default List;
