import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import axios from '../api';

const APP_VERSION = import.meta.env.VITE_WEBAPP_VERSION ?? '';

/**
 * このファイルがロードされた時点で AwsRum の初期化を開始する。
 * getAwsRum() の戻り値はPromiseなので、使用する際には初期化が完了していることが保証される。
 */
const awsRumPromise = init();

export default function getAwsRum() {
  return awsRumPromise;
}

async function init() {
  if (location.host.startsWith('localhost:')) {
    // ダミーで初期化。ローカル実行時や初期化に失敗した場合はダミーのまま動かす。
    return new AwsRum('', '', '');
  }

  const { data = {} } = await axios.get('/rum.json');

  /**
   * @see https://github.com/aws-observability/aws-rum-web/blob/533f0bfa771cf2a801cd73c9149d2d35a9cdd0a0/docs/configuration.md
   */
  const config: AwsRumConfig = {
    allowCookies: true,
    enableXRay: false,
    identityPoolId: data.identityPoolId,
    endpoint: location.origin,
    telemetries: [
      'errors',
      // 直近はパフォーマンスよりも安定性とユーザー体験を重視するため、パフォーマンス計測は一旦無効化する。
      // 'performance',
      ['http', { recordAllRequests: true }],
    ],
  };

  return new AwsRum(data.appMonitorId, APP_VERSION, data.region, config);
}
