import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _BatteryHighIcon = createSvgIcon(
  <>
    <path
      d="M34 7H36.5C36.7761 7 37 7.22386 37 7.5V12.5C37 12.7761 36.7761 13 36.5 13H34V7Z"
      fill="#50D25A"
    />
    <path
      d="M34 7H36.5C36.7761 7 37 7.22386 37 7.5V12.5C37 12.7761 36.7761 13 36.5 13H34V7Z"
      fill="#50D25A"
      fillOpacity="0.2"
    />
    <rect width="34" height="20" rx="2" fill="#50D25A" />
    <rect width="34" height="20" rx="2" fill="#50D25A" fillOpacity="0.2" />
    <rect x="2" y="2" width="30" height="16" rx="2" fill="white" />
    <rect x="4.5" y="4.5" width="25" height="11" rx="1" fill="#50D25A" />
    <rect
      x="4.5"
      y="4.5"
      width="25"
      height="11"
      rx="1"
      fill="#50D25A"
      fillOpacity="0.2"
    />
  </>,
  'BatteryHighIcon',
);

const BatteryHighIcon = styled(_BatteryHighIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 37 20',
}))`
  fill: none;
  height: 20px;
  width: 37px;
`;

export default BatteryHighIcon;
