import React from 'react';
import { css } from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const actionButtonStyle = css`
  border-radius: 4px;
  height: 34px;
  width: 100px;
`;

const buttonWrapperStyle = css`
  position: relative;
`;

const buttonBaseStyle = css`
  &:hover {
    background: ${(props) => props.theme.palette.nito.main};
  }

  &.Mui-disabled {
    background: rgba(240, 80, 100, 0.4);
    color: white;
  }

  background: ${(props) => props.theme.palette.primary.main};
  color: white;
  font-weight: bold;
  ${actionButtonStyle}
`;

const buttonProgressStyle = css`
  color: white;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
`;

const FormikSubmitLoadingButton: React.FC<{ loading: boolean }> = (props) => {
  const { loading } = props;
  const intl = useIntl();
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <Box css={buttonWrapperStyle}>
      <Button
        data-testid="UpdateGatewayLocationButton"
        type="submit"
        disabled={!isValid || isSubmitting}
        css={buttonBaseStyle}
      >
        {intl.formatMessage({
          id: 'common.update',
        })}
      </Button>
      {loading && <CircularProgress size={24} css={buttonProgressStyle} />}
    </Box>
  );
};

export default FormikSubmitLoadingButton;
