import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import { useSelector } from 'react-redux';
import New from '../features/registrations/users/New';
import CabiotLogo from '../commons/CabiotLogo';
import { newResultSelector } from '../features/registrations/users/slice';
import { Registration } from '../features/registrations/slice';
import Created from '../features/registrations/users/Created';

interface AccountProps {
  registration: Registration;
}

const Account: React.FC<AccountProps> = ({ registration }) => {
  const { status } = useSelector(newResultSelector);

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={7.5}>
          <CabiotLogo />
        </Box>
        <Box width="100%">
          {status === 'succeeded' ? (
            <Created />
          ) : (
            <New registration={registration} />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Account;
