import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import PrimaryButton from 'src/domains/invitation/commons/buttons/PrimaryButton';
import CabiotLogo from 'src/domains/invitation/commons/CabiotLogo';

const Created: React.FC = () => {
  const intl = useIntl();

  return (
    <Box px={3}>
      <Box display="flex" justifyContent="center" mt={7.5}>
        <CabiotLogo />
      </Box>
      <Box mt={8.5} mb={7}>
        <Typography variant="h2" align="center">
          {intl.formatMessage({
            id: 'users.created.title',
          })}
        </Typography>
      </Box>
      <Box display="flex" mx="auto" mb={2} maxWidth={440}>
        <Typography variant="subtitle1" align="center">
          {intl.formatMessage({
            id: 'users.created.subTitle',
          })}
        </Typography>
      </Box>
      <Box mt={4} mx="auto" maxWidth={420}>
        <PrimaryButton fullWidth href="/">
          {intl.formatMessage({
            id: 'users.created.login',
          })}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default Created;
