import React from 'react';
import UnrecoverableError from './UnrecoverableError';

const ExpiredEmailChangeIdError: React.FC = () => {
  return (
    <UnrecoverableError messageId="features.users.emailChanges.update.errors.expired_email_change_id" />
  );
};

export default ExpiredEmailChangeIdError;
