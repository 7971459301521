import React from 'react';

const HomeIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="HomeIcon"
      {...props}
    >
      <path
        d="M23.894 11.6911L12.3485 0.14236C12.2561 0.0511472 12.1316 0 12.0018 0C11.8721 0 11.7475 0.0511472 11.6552 0.14236L0.109682 11.6911C-0.0769663 11.8778 -0.0236578 12.0245 0.256315 12.0245H2.12283C2.25259 12.0279 2.3761 12.081 2.46789 12.1728C2.55968 12.2646 2.61274 12.3881 2.61611 12.5179V23.5199C2.61958 23.6485 2.67309 23.7706 2.76522 23.8602C2.85736 23.9499 2.98084 24 3.10939 24H9.88203V15.9986H14.1216V24H20.8943C21.0216 24 21.1436 23.9495 21.2337 23.8594C21.3237 23.7694 21.3743 23.6473 21.3743 23.5199V12.5179C21.3776 12.3881 21.4307 12.2646 21.5224 12.1728C21.6142 12.081 21.7378 12.0279 21.8675 12.0245H23.7473C24.014 12.0245 24.0807 11.8778 23.894 11.6911Z"
        fill="#F05064"
      />
    </svg>
  );
};

export default HomeIcon;
