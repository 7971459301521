import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/domains/root/store';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import Typography from '@mui/material/Typography';
import QrReaderButton from 'src/domains/root/commons/buttons/QrReader';
import ImageBox from '../../gateways/new/ImageBox';
import { TemporaryValues } from '../New';
import { createResultSelector } from '../slice';
import Form from './gateway/Form';

interface GatewayProps {
  values: TemporaryValues;
  onBack: (
    values: Pick<TemporaryValues, 'registrationCode' | 'imei' | 'gatewayName'>,
  ) => void;
  onSubmit: () => void;
}

const Gateway: React.FC<GatewayProps> = (props) => {
  const {
    values: tmpValues,
    onBack: handleBack,
    onSubmit: handleSubmit,
  } = props;

  const intl = useIntl();
  const { status: createStatus, error: createError } =
    useSelector(createResultSelector);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <LoadingOverlay open={createStatus === 'loading'} />
      {createStatus === 'failed' && createError && (
        <Box mb={3.5}>
          <Alert severity="error">
            {intl.formatMessage({
              id: `features.workspaces.packages.new.gateway.error.${createError.code}`,
            })}
          </Alert>
        </Box>
      )}
      <Box mb={2}>
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: 'features.workspaces.packages.new.gateway.step',
          })}
        </Typography>
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: 'features.workspaces.packages.new.gateway.title',
          })}
        </Typography>
      </Box>
      <Box mb={5}>
        <Typography>
          {intl.formatMessage({
            id: 'features.workspaces.packages.new.gateway.notices.0',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'features.workspaces.packages.new.gateway.notices.1',
          })}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        gap={6.5}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Box flexGrow={2} maxWidth={468}>
          <Box mt={1} display="flex" flexDirection="column" gap={3.5}>
            <QrReaderButton onClick={handleDialogOpen} />
            <Form
              values={tmpValues}
              onBack={handleBack}
              onSubmit={handleSubmit}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
            />
          </Box>
        </Box>
        <Box
          flexGrow={1}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              maxWidth: 304,
            },
          })}
        >
          <ImageBox />
        </Box>
      </Box>
    </>
  );
};

export default Gateway;
