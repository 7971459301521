import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/domains/root/store';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import {
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import { WorkspacesUser } from 'src/domains/root/types';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import TableHead from './list/TableHead';
import TableBody from './list/TableBody';
import {
  DeleteWorkspacesUser,
  deleteStatusSelector,
  deleteErrorSelector,
} from './slice';

export type WorkspacesUserTableHead = keyof Omit<WorkspacesUser, 'userId'>;
export type Order = 'asc' | 'desc';

const List: React.FC = () => {
  const intl = useIntl();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<WorkspacesUserTableHead>('email');
  const [deletedUser, setDeletedUser] = useState<DeleteWorkspacesUser | null>(
    null,
  );
  const isSmallScreen = useSmallScreen();

  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;

  const deleteUserStatus = useSelector(deleteStatusSelector);
  const deleteUserError = useSelector(deleteErrorSelector);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: WorkspacesUserTableHead,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <LoadingOverlay open={deleteUserStatus === 'loading'} />
      {deleteUserStatus === 'succeeded' && (
        <Box mb={3.5}>
          <Alert>
            {intl.formatMessage(
              {
                id: 'pages.OtherSetting.users.management.delete.alert.complete',
              },
              {
                email: (
                  <span
                    style={{
                      wordBreak: 'break-all',
                      WebkitLineBreak: 'anywhere',
                    }}
                  >
                    {deletedUser?.email}
                  </span>
                ),
                workspaceName: (
                  <b style={{ wordBreak: 'break-all' }}>
                    {workspace.workspaceName}
                  </b>
                ),
              },
            )}
          </Alert>
        </Box>
      )}
      {deleteUserStatus === 'failed' && !!deleteUserError && (
        <Box mb={3.5}>
          <Alert severity="error">
            {intl.formatMessage(
              {
                id: `pages.OtherSetting.users.management.delete.alert.error.${deleteUserError.code}`,
              },
              {
                email: deleteUserError.email,
              },
            )}
          </Alert>
        </Box>
      )}
      <Box pt={0.5}>
        <Box
          pl={1.5}
          borderLeft={(props) => `5px solid ${props.palette.nito.main}`}
        >
          <Box mb={2}>
            <Typography
              fontSize={(props) => props.typography.pxToRem(18)}
              fontWeight={700}
            >
              {intl.formatMessage({
                id: 'pages.OtherSetting.users.management.description',
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mx="auto">
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <TableHead
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              isSmallScreen={isSmallScreen}
            />
            <TableBody
              order={order}
              orderBy={orderBy}
              setDeletedUser={setDeletedUser}
              isSmallScreen={isSmallScreen}
            />
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default List;
