import { useMemo } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useValidationSchema from './useValidationSchema';

interface FormValues {
  termsOfService: boolean;
  privacyPolicy: boolean;
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useFormikInstance = (onSubmitAgree: () => void) => {
  const { termsOfServiceValidationSchema, privacyPolicyValidationSchema } =
    useValidationSchema();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        termsOfService: termsOfServiceValidationSchema,
        privacyPolicy: privacyPolicyValidationSchema,
      }),
    [termsOfServiceValidationSchema, privacyPolicyValidationSchema],
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      termsOfService: false,
      privacyPolicy: false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      return onSubmitAgree();
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  return formik;
};

export default useFormikInstance;
