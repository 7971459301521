import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RegistrationState, Result } from '../../store';
import {
  RegistrationError,
  RegistrationErrorResponse,
} from '../../utils/registrations/api';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type UsersMeErrorCode = 'unknown_error' | 'emergency_maintenance';

export interface Workspace {
  workspaceId: string;
  workspaceName: string;
}

interface Me {
  email: string;
  latestWorkspaceId: string;
  workspaces: Workspace[];
}

export interface GetUsersMeParams {
  token: Promise<string>;
  silent?: boolean;
}

export const getUsersMe = createAsyncThunk<
  Me,
  GetUsersMeParams,
  { state: RegistrationState; rejectValue: RegistrationError<UsersMeErrorCode> }
>('users/me', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const response = await axios.get<Me>(`${API_ENDPOINT}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const meError = getGetUsersMeError(error);
    return thunkAPI.rejectWithValue(meError);
  }
});

const getGetUsersMeError = (
  error: AxiosError<RegistrationErrorResponse>,
): RegistrationError<UsersMeErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return { code: 'emergency_maintenance', recoverable: false };
          default:
            return { code: 'unknown_error', recoverable: true };
        }
      default:
        return { code: 'unknown_error', recoverable: true };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      recoverable: false,
    };
  }
  return {
    code: 'unknown_error',
    recoverable: false,
  };
};

interface UsersState {
  meResult: Result<Me, RegistrationError<UsersMeErrorCode>>;
}

const initialState: UsersState = {
  meResult: {
    data: null,
    error: null,
    status: 'idle',
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersMe.pending, (state, action) => {
        if (!action.meta.arg.silent) {
          state.meResult.data = null;
          state.meResult.status = 'loading';
          state.meResult.error = null;
        }
      })
      .addCase(getUsersMe.fulfilled, (state, action) => {
        state.meResult.data = action.payload;
        state.meResult.status = 'succeeded';
        state.meResult.error = null;
      })
      .addCase(getUsersMe.rejected, (state, action) => {
        if (!action.meta.arg.silent) {
          state.meResult.data = null;
          state.meResult.status = 'failed';
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          state.meResult.error = action.payload!;
        }
      });
  },
});

export const meResultSelector = createSelector(
  (state: RegistrationState) => state.users,
  (state: UsersState) => state.meResult,
);

export default usersSlice.reducer;
