import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useValidationSchema = () => {
  const intl = useIntl();

  const termsOfServiceValidationSchema = useMemo(
    () =>
      yup
        .boolean()
        .label(
          intl.formatMessage({
            id: 'users.new.agreement.termsOfService',
          }),
        )
        .isTrue(),
    [intl],
  );

  const privacyPolicyValidationSchema = useMemo(
    () =>
      yup
        .boolean()
        .label(
          intl.formatMessage({
            id: 'users.new.agreement.privacyPolicy',
          }),
        )
        .isTrue(),
    [intl],
  );

  return {
    termsOfServiceValidationSchema,
    privacyPolicyValidationSchema,
  };
};

export default useValidationSchema;
