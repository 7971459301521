import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  email: string;
}

const EmailField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, email: true });
  };

  return (
    <TextField
      data-testid="EmailInput"
      name="email"
      type="email"
      value={values.email}
      label={intl.formatMessage({
        id: 'pages.OtherSetting.users.invitation.form.email.textFieldLabel',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={touched.email && Boolean(errors.email)}
      helperText={touched.email && errors.email}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default EmailField;
