import React from 'react';
import { CircularProgress, Dialog, DialogTitle } from '@mui/material';
import { useIntl } from 'react-intl';

import Title from 'src/domains/root/commons/dialog/Title';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';

interface Props {
  open: boolean;
  title: string;
  content: React.ReactNode;
  confirmText?: string;
  closeText?: string;
  isConfirming: boolean;
  confirm: () => Promise<void>;
  close: () => void;
}

export default function ConfirmDialog({
  open,
  title,
  content,
  confirmText,
  closeText,
  isConfirming,
  confirm,
  close,
}: Props) {
  const intl = useIntl();

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <Title onClose={close}>{title}</Title>
      </DialogTitle>
      <DialogContent sx={{ py: 3.5 }}>{content}</DialogContent>
      <DialogActions sx={{ display: 'flex', columnGap: 1 }}>
        <NegativeButton onClick={close}>
          {closeText ?? intl.formatMessage({ id: 'common.buttons.no' })}
        </NegativeButton>
        <PrimaryButton type="button" disabled={isConfirming} onClick={confirm}>
          {!isConfirming ? (
            confirmText ?? intl.formatMessage({ id: 'common.buttons.yes' })
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
