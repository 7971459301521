/**
 * このファイル名の `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import React from 'react';
import { Box, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import type { BreadcrumbsProps } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type Props = {
  small?: boolean;
  children: React.ReactNode;
} & BreadcrumbsProps;

export default function Breadcrumbs({
  children,
  small = false,
  sx = [],
  ...rest
}: Props) {
  return (
    <Box
      sx={{
        py: { sm: small ? 2 : 3 },
        px: { sm: small ? 3 : 3.5 },
        backgroundColor: { sm: 'white' },
        borderRadius: { sm: 1 },
      }}
    >
      <MUIBreadcrumbs
        separator={
          <NavigateNextIcon
            sx={{
              fontSize: {
                xs: 'large',
                sm: 'x-large',
              },
              color: 'black',
            }}
          />
        }
        sx={[
          {
            a: {
              textDecoration: 'none',
            },
          },

          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      >
        {children}
      </MUIBreadcrumbs>
    </Box>
  );
}
