import React from 'react';
import { useIntl } from 'react-intl';
import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { WorkspacesUser } from 'src/domains/root/types';
import ValidationBox from '../../../../../commons/form/ValidationBox';
import SubmitButton from '../../../../../commons/form/SubmitButton';
import { TemporaryValues } from '../../New';
import WorkspaceField from './form/WorkspaceField';
import UserSelect from './form/UserSelect';

interface FormProps {
  adminUsers: WorkspacesUser[];
  values: TemporaryValues;
  onForward: (
    values: Pick<TemporaryValues, 'workspaceName' | 'adminUserId'>,
  ) => void;
}

interface FormValues {
  name: string;
  adminUserId: string;
}

const Form: React.FC<FormProps> = (props) => {
  const { adminUsers, values, onForward: handleForward } = props;

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'features.workspaces.packages.new.workspace.form.name.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .max(200, intl.formatMessage({ id: 'validation.string.max' })),
    adminUserId: yup
      .string()
      .label(
        intl.formatMessage({
          id: 'features.workspaces.packages.new.workspace.form.adminUserId.label',
        }),
      )
      .required(intl.formatMessage({ id: 'validation.mixed.required' })),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: values.workspaceName,
      adminUserId: values.adminUserId,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { name, adminUserId } = values;
      handleForward({ workspaceName: name, adminUserId });
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <ValidationBox>
          <WorkspaceField />
        </ValidationBox>
        <ValidationBox>
          <UserSelect users={adminUsers} />
        </ValidationBox>
        <Box display="flex" pt={1.5} justifyContent="space-between">
          <Box flex={2}>
            <SubmitButton data-testid="NextButton" size="large" fullWidth>
              {intl.formatMessage({
                id: 'features.workspaces.packages.new.workspace.button.submit',
              })}
            </SubmitButton>
          </Box>
        </Box>
      </FormikForm>
    </FormikProvider>
  );
};

export default Form;
