import styled from 'styled-components';
import { Divider as _Divider } from '@mui/material';

const Divider = styled(_Divider).attrs(() => ({
  orientation: 'vertical',
  flexItem: true,
}))`
  margin-left: ${(props) => props.theme.spacing(4)};
  margin-right: ${(props) => props.theme.spacing(4)};
`;

export default Divider;
