import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { awsRum } from 'src/utils/rum';
import { AppDispatch } from 'src/domains/root/store';
import { RootError } from 'src/domains/root/utils/api';
import useSnackbar, {
  useDemoSiteSnackbar,
} from 'src/domains/root/commons/snackbar';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import { postWorkspacesStripeCustomerPortalSessions } from 'src/domains/root/features/workspaces/stripe-customer-portal-session/slice';
import { InfoDialog } from 'src/domains/root/commons/dialog';
import { useCheckFeatureFlag } from 'src/utils/feature-flag/FeatureFlag';
import { IS_ENV } from 'src/constants';

export default function ContractHistoryButton() {
  const intl = useIntl();
  const isPaymentMethodFeatureEnabled = useCheckFeatureFlag(
    'contract.payment-method',
  );
  const { enqueueDemoSiteSnackbar } = useDemoSiteSnackbar();

  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (IS_ENV.DEMO) {
      enqueueDemoSiteSnackbar();

      return;
    }

    setOpenDialog(true);
  };

  const handleClose = () => {
    if (!loading) {
      setOpenDialog(false);
    }
  };

  return (
    <>
      {isPaymentMethodFeatureEnabled ? (
        <>
          <SecondaryButton
            onClick={handleClick}
            sx={{
              px: 3,
            }}
            data-testid="PaymentHistoryButton"
          >
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.list.table.historyButton.name',
            })}
          </SecondaryButton>
          <InfoDialog
            open={openDialog}
            title={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.list.table.historyButton.name',
            })}
            content={
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                py={2}
                maxWidth={320}
                mx="auto"
                rowGap={4}
              >
                <CreditHistory setLoading={setLoading} loading={loading} />
                <Divider
                  flexItem
                  sx={{ '&::before, &::after': { border: '1px solid grey' } }}
                >
                  <Typography fontSize={12} fontWeight={700} color="grey">
                    {intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.list.table.historyButton.divider',
                    })}
                  </Typography>
                </Divider>
                <InvoiceHistory />
              </Box>
            }
            maxWidth={572}
            close={handleClose}
            loggingId="ContractHistoryDialog"
            disableClose={loading}
          />
        </>
      ) : (
        <CreditHistory setLoading={setLoading} loading={loading} />
      )}
    </>
  );
}

function CreditHistory({
  setLoading,
  loading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}) {
  const intl = useIntl();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(
    'SNACKBAR_POST_STRIPE_CUSTOMER_PORTAL_SESSIONS_ERROR',
    {
      noRefreshButton: true,
    },
  );
  const isPaymentMethodFeatureEnabled = useCheckFeatureFlag(
    'contract.payment-method',
  );

  const handleClick = async () => {
    setLoading(true);

    const token = getAccessTokenSilently();
    try {
      const session = await dispatch(
        postWorkspacesStripeCustomerPortalSessions({ token }),
      ).unwrap();
      closeSnackbar();
      window.location.assign(session.url);
    } catch (e) {
      const error = e as RootError;
      enqueueSnackbar(
        `features.workspaces.stripeCustomerPortalSession.post.error.${error.code}`,
      );
      awsRum().then((rum) => rum.recordError(e)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    } finally {
      setLoading(false);
    }
  };

  return (
    <SecondaryButton
      onClick={handleClick}
      disabled={loading}
      sx={{
        px: 3,
        width: isPaymentMethodFeatureEnabled ? '100%' : 200,
      }}
    >
      {!loading ? (
        isPaymentMethodFeatureEnabled ? (
          intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.table.historyButton.creditHistoryButton',
          })
        ) : (
          intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.table.historyButton.name',
          })
        )
      ) : (
        <CircularProgress size={20} />
      )}
    </SecondaryButton>
  );
}

function InvoiceHistory() {
  const intl = useIntl();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        fontSize={12}
        fontWeight={700}
        textAlign="center"
        color="#5A5A5A"
        pb={2}
      >
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.list.table.historyButton.invoiceDescription1',
        })}
        <br />
        <Link
          href="https://np-kakebarai.com/buy/member/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.table.historyButton.invoiceDescription2',
          })}
        </Link>
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.list.table.historyButton.invoiceDescription3',
        })}
      </Box>
      <SecondaryButton
        href="https://membership.np-kakebarai.com/login/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.list.table.historyButton.invoiceHistoryButton',
        })}
      </SecondaryButton>
    </Box>
  );
}
