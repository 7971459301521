import React from 'react';
import { css } from 'styled-components/macro';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import theme from 'src/domains/root/styles/theme';
import useCheckScrollTop from 'src/domains/root/hooks/use-check-scroll-top';

const floatingActionButtonStyle = css`
  bottom: ${theme.spacing(4)};
  line-height: initial;
  position: fixed;
  right: ${theme.spacing(3)};
  ${theme.breakpoints.down('sm')} {
    bottom: ${theme.spacing(4)};
    right: ${theme.spacing(2.5)};
  }
`;
const iconBoxStyle = css`
  font-size: 0;
  left: 12px;
  position: absolute;
  top: 6px;
  ${theme.breakpoints.down('sm')} {
    left: 8px;
    top: 2px;
  }
`;
const letterBoxStyle = css`
  font-size: 10px;
  font-weight: 600;
  left: 14px;
  position: absolute;
  top: 25px;
  ${theme.breakpoints.down('sm')} {
    left: 10px;
    top: 20px;
  }
`;

interface FloatingActionButtonProps {
  size?: 'small' | 'medium' | 'large';
}

const TopButton: React.FC = () => {
  const theme = useTheme();
  const [isTop] = useCheckScrollTop();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const floatingActionButtonProps: FloatingActionButtonProps = {
    size: isSmallScreen ? 'small' : 'medium',
  };

  const moveTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'auto' });
  };

  return (
    <Fade in={!isTop}>
      <Fab
        {...floatingActionButtonProps}
        onClick={moveTop}
        css={floatingActionButtonStyle}
        data-testid="TopButton"
      >
        <Box display="flex" flexDirection="column">
          <Box css={iconBoxStyle}>
            <KeyboardArrowUpIcon />
          </Box>
          <Box css={letterBoxStyle}>TOP</Box>
        </Box>
      </Fab>
    </Fade>
  );
};

export default TopButton;
