import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'src/domains/root/store';
import New from '../features/setup/gateways/New';
import Created from '../features/setup/gateways/Created';
import {
  createResultSelector,
  workspaceSelector,
} from '../features/setup/workspaces/slice';
import MainContents from '../commons/layout/MainContents';

type Props = {
  ifNoWorkspaceName: React.ReactElement;
};

const SetupGateway: React.FC<Props> = (props) => {
  const { ifNoWorkspaceName: renderForNoWorkspaceName } = props;

  const intl = useIntl();

  const { status, data } = useSelector(createResultSelector);
  const workspace = useSelector(workspaceSelector);

  if (!workspace) {
    return renderForNoWorkspaceName;
  }

  return (
    <MainContents
      containerMaxWidth="md"
      backgroundColor="white"
      showTopButton={false}
    >
      <Box maxWidth={812} m="0 auto">
        <Box display="grid" justifyContent="center" pt={4}>
          <Typography
            fontWeight={700}
            fontSize={(theme) => theme.typography.pxToRem(18)}
            lineHeight={1.57}
          >
            STEP 2 / 2
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={(theme) => theme.typography.pxToRem(32)}
            lineHeight={1.167}
          >
            {intl.formatMessage({
              id: 'pages.Setup.Gateway.title',
            })}
          </Typography>
        </Box>
        {status === 'succeeded' ? (
          <Created
            workspace={workspace}
            contractExpiredAt={data?.contractExpiredAt}
          />
        ) : (
          <New />
        )}
      </Box>
    </MainContents>
  );
};

export default SetupGateway;
