/**
 * このファイル名の `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import { Alert } from 'src/domains/root/features/alerts/slice';
import BatteryIcon from './battery/BatteryIcon';

type GatewayAlert = Alert & {
  battery?: undefined;
};

interface Props {
  gateway: Gateway | GatewayAlert;
  displayBattery: boolean;
  /**
   * trueの場合、gatewayNameがchildrenとぶつかるとchildrenが下段に潜り込む。
   */
  wrapChild?: boolean;
  /**
   * trueの場合、gatewayNameをwrapしているBoxのflex-directionをcolumnにする
   */
  flexColumn?: boolean;
  /**
   * trueの場合、gatewayNameBoxのwidthを100%にする
   */
  gatewayNameBoxFullWidth?: boolean;
  /**
   * trueの場合、childrenをwrapしているBoxのwidthを100%にする
   */
  childrenBoxFullWidth?: boolean;
  children?: React.ReactNode;
}
export default function GatewayName(props: Props) {
  const {
    gateway,
    displayBattery,
    wrapChild = false,
    flexColumn = false,
    gatewayNameBoxFullWidth = false,
    childrenBoxFullWidth = false,
    children,
  } = props;
  const { name } = gateway;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const px = isSmallScreen ? 2 : 4;

  return (
    <Box
      bgcolor="secondary.main"
      color="secondary.contrastText"
      display="flex"
      flexDirection={flexColumn ? 'column' : 'row'}
      flexWrap={wrapChild ? 'wrap' : 'nowrap'}
      alignItems="center"
      justifyContent="space-between"
      paddingX={px}
      paddingY={2}
      columnGap={2}
      rowGap={1}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={2}
        width={gatewayNameBoxFullWidth ? '100%' : undefined}
      >
        {displayBattery ? <BatteryIcon battery={gateway.battery} /> : null}
        <Box flexGrow="1">
          <Typography
            fontWeight="bold"
            fontSize={(theme) => theme.typography.pxToRem(18)}
            sx={{ wordBreak: 'break-all' }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
      {children ? (
        <Box flexGrow="1" width={childrenBoxFullWidth ? '100%' : undefined}>
          {children}
        </Box>
      ) : undefined}
    </Box>
  );
}
