import React from 'react';
import { useSelector } from 'src/domains/root/store';
import { workspacesSelector } from 'src/domains/root/features/users/slice';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import TermsOfServiceDocument from 'src/domains/root/features/users/terms-of-service-reagreement/TermsOfServiceDocument';
import HeaderOnlyLayout from '../commons/layout/HeaderOnlyLayout';

const TermsOfServiceReagreement: React.FC = () => {
  const workspaces = useSelector(workspacesSelector);

  return (
    <HeaderOnlyLayout withMobileDrawer={!!workspaces?.length}>
      <MainContents backgroundColor="white">
        <TermsOfServiceDocument />
      </MainContents>
    </HeaderOnlyLayout>
  );
};

// ページタイトルの取得のために設定
TermsOfServiceReagreement.displayName = 'TermsOfServiceReagreement';
export default TermsOfServiceReagreement;
