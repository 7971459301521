import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { awsRum } from 'src/utils/rum';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Status } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export interface GetAlertStateParams {
  token: Promise<string>;
  workspaceId: string;
  silent?: boolean;
}
interface GetAlertStatusResponse {
  alert: number;
}

export type AlertStatusErrorCode =
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

export const getAlertState = createAsyncThunk<
  GetAlertStatusResponse,
  GetAlertStateParams,
  { state: RootState; rejectValue: RootError<AlertStatusErrorCode> }
>('currnetAlert/getAlertState', async (params, thunkAPI) => {
  const token = await params.token;
  try {
    const response = await axios.get<GetAlertStatusResponse>(
      `${API_ENDPOINT}/alerts/${params.workspaceId}/status`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (!isAxiosError(error)) {
      awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
      throw error;
    }
    const alertStatusError = getAlertStatusError(error);
    if (alertStatusError.code === 'unknown_error') {
      awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
    }
    return thunkAPI.rejectWithValue(alertStatusError);
  }
});

const getAlertStatusError = (
  error: AxiosError<RootErrorResponse>,
): RootError<AlertStatusErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        return {
          code: 'permission_denied',
          noticeable: false,
          recoverable: false,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface CurrentAlertState {
  count: number;
  error: RootError<AlertStatusErrorCode> | null;
  status: Status;
}

const initialState: CurrentAlertState = {
  count: 0,
  status: 'idle',
  error: null,
};

export const currentAlertSlice = createSlice({
  name: 'currnetAlert',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAlertState.pending, (state, action) => {
        if (!action.meta.arg.silent) {
          state.status = 'loading';
          state.error = null;
        }
      })
      .addCase(getAlertState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.count = action.payload.alert;
        state.error = null;
      })
      .addCase(getAlertState.rejected, (state, action) => {
        if (!action.meta.arg.silent) {
          state.status = 'failed';
          state.count = 0;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          state.error = action.payload!;
        }
      });
  },
});

export const currentAlertCountSelector = createSelector(
  (state: RootState) => state.currentAlert,
  (state: CurrentAlertState) => state.count,
);

export const errorSelector = createSelector(
  (state: RootState) => state.currentAlert,
  (state: CurrentAlertState) => state.error,
);

export default currentAlertSlice.reducer;
