import React from 'react';
import _Breadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styled from 'styled-components';

const Breadcrumbs = styled(_Breadcrumbs).attrs<BreadcrumbsProps>(() => ({
  separator: <NavigateNextIcon fontSize="small" />,
  color: 'textPrimary',
}))<BreadcrumbsProps>`
  * > a {
    text-decoration: none;
  }
`;

export default Breadcrumbs;
