import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import { handleError } from './error';
import { MemoImage, MemoImageUploadResponse } from './types';

type Props = {
  gatewayId: string;
  memoId: string;
  content: string;
  images: MemoImage[];
  token: string;
};

type Response = MemoImageUploadResponse;

export async function putGatewaysMemos({
  gatewayId,
  memoId,
  content,
  images,
  token,
}: Props): Promise<Response> {
  return axios
    .put(
      `${API_ENDPOINT}/gateways/${gatewayId}/memos/${memoId}`,
      { content, images },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
