import React from 'react';
import { CssBaseline } from '@mui/material';

import useAxiosInterceptor from 'src/utils/api/use-axios-interceptor';

import createProviders from './Providers';
import Routers from './Routes';

const Providers = createProviders();

export default function App() {
  useAxiosInterceptor();
  return (
    <>
      <CssBaseline />
      <Providers>
        <Routers />
      </Providers>
    </>
  );
}
