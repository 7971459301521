import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components/macro';

const _InformationIcon = createSvgIcon(
  <>
    <path
      d="M8.09961 8.09999C8.09961 7.85147 8.30108 7.64999 8.54961 7.64999H9.44961C9.69814 7.64999 9.89961 7.85147 9.89961 8.09999V13.05C9.89961 13.2985 9.69814 13.5 9.44961 13.5H8.54961C8.30108 13.5 8.09961 13.2985 8.09961 13.05V8.09999Z"
      fill="#1996FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.2C12.9764 16.2 16.2 12.9764 16.2 9C16.2 5.02355 12.9764 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 12.9764 5.02355 16.2 9 16.2ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill="#1996FA"
    />
    <ellipse cx="8.99961" cy="5.4" rx="0.900001" ry="0.9" fill="#1996FA" />
  </>,
  'Information',
);

const InformationIcon = styled(_InformationIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 18 18',
}))`
  fill: none;
  height: 18px;
  width: 18px;
`;

export default InformationIcon;
