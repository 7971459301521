import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import { AppDispatch, useSelector } from '../store';
import { getUsersMe, meResultSelector } from '../features/users/slice';
import LoadingOverlay from '../commons/LoadingOverlay';

interface OnlyNoWorkspaceProps {
  redirectTo: string;
  children: React.ReactNode;
}

export default function OnlyNoWorkspace({
  redirectTo,
  children,
}: OnlyNoWorkspaceProps) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const { data: me, status } = useSelector(meResultSelector);
  const [error, setError] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const token = getAccessTokenSilently();
    const promise = dispatch(getUsersMe({ token }));
    promise.unwrap().catch(() => setError(true));
    return () => promise.abort();
  }, [dispatch, getAccessTokenSilently]);

  if (me && me.workspaces.length !== 0) {
    return <Navigate to={redirectTo} />;
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Box mt={4}>
          <Alert severity="error">
            {intl.formatMessage({
              id: `common.error.unknown_error`,
            })}
          </Alert>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <LoadingOverlay open={status === 'loading'} />
      {children}
    </>
  );
}
