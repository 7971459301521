/**
 * このファイル名の `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import * as API from 'src/apis';
import GatewayDataChip from './ChipV3';

type GatewayAlert = API.Alert & {
  battery?: undefined;
};

interface GatewayLocationProps {
  gateway: Gateway | GatewayAlert;
}

const GatewayLocation: React.FC<GatewayLocationProps> = (props) => {
  const { area } = props.gateway;

  const intl = useIntl();

  const [locationName, setLocationName] = useState<string | undefined>();

  useEffect(() => {
    if (area === undefined) {
      setLocationName(intl.formatMessage({ id: 'gatewayInfo.noLocation' }));
      return;
    }

    if ('areaId' in area && area.areaId === 'noLocation') {
      setLocationName(intl.formatMessage({ id: 'gatewayInfo.noLocation' }));
      return;
    }

    setLocationName([area.prefectureName, area.displayAreaName].join(' '));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  return (
    <Box
      pb={0.25}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      })}
    >
      <GatewayDataChip
        label={intl.formatMessage({
          id: 'gatewayInfo.locationMobile',
        })}
      />
      <Box
        pt={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            pt: 0,
            pl: 2,
          },
        })}
      >
        <Typography>{locationName}</Typography>
      </Box>
    </Box>
  );
};

export default GatewayLocation;
