import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import styled from 'styled-components';

export type BaseButtonProps = ButtonProps & { target?: string };

const BaseButton = styled(({ children, ...props }: BaseButtonProps) => {
  const { sx, size = 'medium', variant } = props;

  return (
    <Button
      {...props}
      sx={{
        fontSize: fontSize(size),
        lineHeight: lineHeight(size),
        paddingY: paddingY(size, variant),
        ...sx,
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-expect-error
        minWidth: sx?.minWidth ?? '100px',
        /* eslint-enable @typescript-eslint/ban-ts-comment */
      }}
    >
      {children}
    </Button>
  );
})`
  border-radius: 4px;
  border-width: 2px;
  font-weight: bold;

  :hover {
    border-width: 2px;
  }

  :disabled {
    border-width: 2px;
    opacity: 0.4;
  }
`;

export default BaseButton;

// lib

function paddingY(
  size: ButtonProps['size'],
  variant: ButtonProps['variant'],
): string | undefined {
  const borderWidth = variant === 'outlined' ? 2 : 0;
  switch (size) {
    case 'small':
      // small はまだ考慮していないので、medium と同じにする
      return `${7 - borderWidth}px`;
    case 'medium':
      // 1行のときのボタンの高さを 34px にする
      return `${7 - borderWidth}px`;
    case 'large':
      // 1行のときのボタンの高さを 44px にする
      return `${9 - borderWidth}px`;
    default:
      return undefined;
  }
}

function fontSize(size: ButtonProps['size']): number | undefined {
  switch (size) {
    case 'small':
      // small はまだ考慮していないので、medium と同じにする
      return 14;
    case 'medium':
      return 14;
    case 'large':
      return 18;
    default:
      return undefined;
  }
}

function lineHeight(size: ButtonProps['size']): string | undefined {
  switch (size) {
    case 'small':
      // small はまだ考慮していないので、medium と同じにする
      return '20px';
    case 'medium':
      return '20px';
    case 'large':
      return '26px';
    default:
      return undefined;
  }
}
