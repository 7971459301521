import type { IntlShape } from 'react-intl';
import type { RangeSelectorButtonsOptions } from 'highcharts/highstock';

export const getRangeSelectorButtons = (
  isFullData: boolean,
  intl: IntlShape,
): RangeSelectorButtonsOptions[] =>
  !isFullData
    ? [
        buttonOption(intl, 'day'),
        buttonOption(intl, 'week'),
        buttonOption(intl, '1month'),
        buttonOption(intl, '3month'),
      ]
    : [
        buttonOption(intl, 'day'),
        buttonOption(intl, 'week'),
        buttonOption(intl, '1month'),
        buttonOption(intl, '3month'),
        buttonOption(intl, '6month'),
        buttonOption(intl, 'all'),
      ];

const BUTTON_PATTERNS = {
  day: { type: 'day', count: 1, label: 'day' },
  week: { type: 'week', count: 1, label: 'week' },
  '1month': { type: 'month', count: 1, label: 'month' },
  '3month': { type: 'month', count: 3, label: '3month' },
  '6month': { type: 'month', count: 6, label: '6month' },
  all: { type: 'all', label: 'all' },
} as const;

const buttonOption = (
  intl: IntlShape,
  key: keyof typeof BUTTON_PATTERNS,
): RangeSelectorButtonsOptions => {
  const { label, ...rest } = BUTTON_PATTERNS[key];
  const intlId = `highchartsOptions.chartOptions.rangeSelector.buttons.${label}`;
  return {
    ...rest,
    text: intl.formatMessage({ id: intlId }),
    title: intl.formatMessage({ id: intlId }),
  };
};
