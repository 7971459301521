import React from 'react';
import UnrecoverableError from './UnrecoverableError';

const UsedRegistrationCodeError: React.FC = () => {
  return (
    <UnrecoverableError
      messageId="registrations.users.new.error.used_registration_code"
      buttonId="common.buttons.top"
      buttonLink="/registration/signup"
    />
  );
};

export default UsedRegistrationCodeError;
