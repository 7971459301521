/**
 * このファイル名の `V3` はデザインのバージョンを表している。
 * 現在は過渡期で、V3（スマホ対応版）とV2（スマホ未対応版）がコード中に混じっている。
 * これをif分岐で対応するとメンテナビリティを損なうため、ファイルを分けて定義している。
 * この `V3` のサフィックスはV3対応がかんりょうしたら除去する。
 */
import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider/Divider';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import { Alert } from 'src/domains/root/features/alerts/slice';
import GatewayDataDivider from './data/Divider';
import GatewayNumber from './data/GatewayNumber';
import GatewayLatestTimestamp from './data/GatewayLatestTimestampV3';
import GatewayLocation from './data/GatewayLocationV3';

type GatewayAlert = Alert & {
  battery?: undefined;
};

interface GatewayDataProps {
  gateway: Gateway | GatewayAlert;
  removeLatestTimestamp?: boolean;
}

const GatewayData: React.FC<GatewayDataProps> = (props) => {
  const { physicalId, latestTimestamp } = props.gateway;
  const { removeLatestTimestamp } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          rowGap: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
          px: 0,
          flexDirection: 'column',
          rowGap: theme.spacing(2),
        },
      })}
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      px={1}
    >
      {!isSmallScreen && <GatewayNumber physicalId={physicalId} />}
      {!isSmallScreen && <GatewayDataDivider />}
      {!removeLatestTimestamp && (
        <GatewayLatestTimestamp latestTimestamp={latestTimestamp} />
      )}
      {isSmallScreen && !removeLatestTimestamp && <Divider />}
      {!isSmallScreen && <GatewayDataDivider />}
      <GatewayLocation gateway={props.gateway} />
    </Box>
  );
};

export default GatewayData;
