import axios from 'src/utils/api';
import { handleError } from './error';
import { MemoImage, MemoImageUploadResponse } from './types';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

type Props = {
  gatewayId: string;
  content: string;
  images: MemoImage[];
  token: string;
};
type Response = MemoImageUploadResponse;
export async function postGatewaysMemos({
  gatewayId,
  content,
  images,
  token,
}: Props): Promise<Response> {
  return axios
    .post(
      `${API_ENDPOINT}/gateways/${gatewayId}/memos`,
      { content, images },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
