import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import { DateTime } from 'luxon';
import IconButton from '@mui/material/IconButton';
import { default as MuiTableBody } from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import SettingIcon from 'src/domains/root/commons/layout/drawer/icons/SettingIcon';
import { useAuth0 } from '@auth0/auth0-react';
import AccessControl from 'src/domains/root/commons/AccessControl';
import { WorkspacesUser } from 'src/domains/root/types';
import {
  DeleteWorkspacesUser,
  usersSelector,
  updateTargetUser,
} from '../slice';
import { Order, WorkspacesUserTableHead } from '../List';
import UserDeleteDialog from '../delete/Dialog';
import UserRoleUpdateDialog from '../update/role/Dialog';
import Menu from './Menu';

interface TableBodyProps {
  order: Order;
  orderBy: WorkspacesUserTableHead;
  setDeletedUser: React.Dispatch<
    React.SetStateAction<DeleteWorkspacesUser | null>
  >;
  isSmallScreen: boolean;
}

const TableBody: React.FC<TableBodyProps> = (props) => {
  const { order, orderBy, setDeletedUser, isSmallScreen } = props;
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();
  const { user: me } = useAuth0();

  const users = useSelector(usersSelector);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userRoleUpdateDialogOpen, setUserRoleUpdateDialogOpen] =
    useState<boolean>(false);
  const [userDeleteDialogOpen, setUserDeleteDialogOpen] =
    useState<boolean>(false);

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(
    order: Order,
    orderBy: WorkspacesUserTableHead,
  ): (
    a: {
      [key in WorkspacesUserTableHead]: number | string;
    },
    b: {
      [key in WorkspacesUserTableHead]: number | string;
    },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: WorkspacesUser,
  ) => {
    setAnchorEl(event.currentTarget);
    dispatch(updateTargetUser(user));
  };

  const handleUserRoleUpdateDialogOpen = () => {
    setUserRoleUpdateDialogOpen(true);
    setAnchorEl(null);
  };

  const handleUserRoleUpdateDialogClose = () => {
    setUserRoleUpdateDialogOpen(false);
  };

  const handleUserDeleteDialogOpen = () => {
    setUserDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      <MuiTableBody>
        {users
          ?.slice()
          .sort(getComparator(order, orderBy))
          .map((user) => {
            return (
              <TableRow
                key={user.userId}
                sx={{
                  height: 56,
                  '& > td': {
                    px: 2.5,
                    py: 0,
                  },
                }}
              >
                <TableCell
                  align="left"
                  sx={{ minWidth: '280px', wordBreak: 'break-all' }}
                >
                  {user.email}
                </TableCell>
                {!isSmallScreen && (
                  <TableCell align="center">
                    {DateTime.fromMillis(user.createdAt).toFormat('yyyy/M/d')}
                  </TableCell>
                )}
                <TableCell align="center">
                  {intl.formatMessage({
                    id: `pages.OtherSetting.users.management.table.role.${user.role}`,
                  })}
                  {user.userType === 'serviceProvider' && '🎃'}
                </TableCell>
                <AccessControl
                  permit={(permissions) =>
                    permissions.includes('workspace.user.update') ||
                    permissions.includes('workspace.user.delete')
                  }
                >
                  <TableCell align="center">
                    {user.userId !== me!.sub && ( // eslint-disable-line @typescript-eslint/no-non-null-assertion
                      <IconButton
                        data-testid="UserManagementMenuButton"
                        size="large"
                        onClick={(e) => handleClick(e, user)}
                      >
                        <SettingIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </AccessControl>
              </TableRow>
            );
          })}
      </MuiTableBody>
      <Menu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleUserRoleUpdateDialogOpen={handleUserRoleUpdateDialogOpen}
        handleUserDeleteDialogOpen={handleUserDeleteDialogOpen}
      />
      <UserRoleUpdateDialog
        open={userRoleUpdateDialogOpen}
        onClose={handleUserRoleUpdateDialogClose}
      />
      <UserDeleteDialog
        userDeleteDialogOpen={userDeleteDialogOpen}
        setUserDeleteDialogOpen={setUserDeleteDialogOpen}
        setDeletedUser={setDeletedUser}
      />
    </>
  );
};

export default TableBody;
