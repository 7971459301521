import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  BreadcrumbsV3,
  BreadcrumbTitleV3,
} from 'src/domains/root/commons/breadcrumbs';
import MainContents from 'src/domains/root/commons/layout/MainContents';
import EnableInvoice from 'src/domains/root/features/contracts/payment-method/EnableInvoice';

export default function PaymentMethodInvoice() {
  const intl = useIntl();

  return (
    <MainContents
      noXPaddingIfMobile
      notAllowOverflow
      showContractExpiredAlert
      showTopButton={false}
    >
      <Box display="flex" flexDirection="column" gap={{ xs: 3, sm: 3.5 }}>
        <Box pl={{ xs: 2, sm: 0 }}>
          <BreadcrumbsV3>
            <Link to="/settings/others">
              <BreadcrumbTitleV3 color="primary">
                {intl.formatMessage({
                  id: 'pages.OtherSetting.title',
                })}
              </BreadcrumbTitleV3>
            </Link>
            <BreadcrumbTitleV3>
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.payment-method.title',
              })}
            </BreadcrumbTitleV3>
          </BreadcrumbsV3>
        </Box>
        <EnableInvoice />
      </Box>
    </MainContents>
  );
}
