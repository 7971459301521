import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import Highcharts from 'highcharts/highstock';
import Annotation from 'highcharts/modules/annotations';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';
import type {
  SensorData,
  WeatherArea,
} from 'src/domains/root/features/gateways/sensor-data/type';
import { getGlobalOptions } from 'src/domains/root/utils/highcharts/base-options';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import { createChartOptions, WeatherHistoriesData } from './createChartOptions';
import { SensorUnit } from './slice';
import { Result } from './sensor-data/useSensorDataChart';

export type { WeatherHistoriesData } from './createChartOptions';

Annotation(Highcharts);

interface Props {
  gatewayName: string;
  sensorUnit: Pick<SensorUnit, 'name' | 'observe' | 'isAlive'>;
  sensorData: SensorData;
  weatherArea: WeatherArea;
  weatherHistories: WeatherHistoriesData;
  isFullData: boolean;
  phase: Extract<Result, { status: 'succeeded' }>['phase'];
  initialViewRange?: { start: Date; end: Date };
  occurredAt?: Date;
}

const SensorDataChart = ({
  gatewayName,
  sensorUnit,
  sensorData,
  weatherArea,
  weatherHistories,
  isFullData,
  phase,
  initialViewRange,
  occurredAt,
}: Props) => {
  const intl = useIntl();

  const isSmallScreen = useSmallScreen();

  const chartRef = useRef(null);

  Highcharts.setOptions(getGlobalOptions(intl));
  // 「highchartsの表示領域が変更されたか？」のフラグを管理する
  const [graphRangeTouched, setGraphRangeTouched] = useState(false);
  const touchGraphRange = () => setGraphRangeTouched(true);

  const options: Highcharts.Options = createChartOptions({
    intl,
    gatewayName,
    sensorUnit,
    sensorData,
    weatherArea,
    weatherHistories,
    isSmallScreen,
    isFullData,
    touchGraphRange,
    graphRangeTouched,
    occurredAt,
    initialViewRange,
  });

  useEffect(() => {
    if (chartRef.current && phase === 'partial_data_fetched') {
      const refCurrent = chartRef.current as unknown as Highcharts.Chart;
      const chart = refCurrent.chart;

      /**
       * 点と点が繋がらない問題が発生したので追加でreflowを実行して繋がるようにした
       * @PBI https://www.pivotaltracker.com/story/show/185348050
       */
      chart.reflow();
    }
  }, [phase]);

  return (
    <Box sx={{ userSelect: 'none', touchAction: 'pan-x pan-y' }}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
    </Box>
  );
};

export default SensorDataChart;
