import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar, OptionsObject, SnackbarKey } from 'notistack';
import { IS_ENV } from 'src/constants';
import CloseIconButton from './actions/CloseIconButton';
import RefreshButton from './actions/RefreshButton';
import { Action, Message } from './styles';

type Props = {
  noRefreshButton?: boolean;
  enqueueOptions?: OptionsObject;
};
export default function useCabiotSnackbar(
  key?:
    | 'SNACKBAR_CURRENT_ALERT_ERROR'
    | 'SNACKBAR_ANNOUNCE_ERROR'
    | 'SNACKBAR_PAYMENTS_ERROR'
    | 'SNACKBAR_AFTER_PAYMENTS_INFO'
    | 'SNACKBAR_GET_STRIPE_CUSTOMER_ERROR'
    | 'SNACKBAR_POST_STRIPE_CUSTOMER_PORTAL_SESSIONS_ERROR'
    | 'SNACKBAR_DELETE_ACCOUNT_DEMO'
    | 'SNACKBAR_IMAGE_UPLOAD_ERROR',
  props?: Props,
) {
  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _closeSnackbar = useCallback(() => closeSnackbar(key), []);

  const _enqueueSnackbar = useCallback(
    async (messageId: string): Promise<void> => {
      const snackbarAction = (snackbarKey: SnackbarKey) => (
        <Action>
          {!props?.noRefreshButton && (
            <RefreshButton onClick={() => window.location.reload()} />
          )}
          <CloseIconButton onClick={() => closeSnackbar(snackbarKey)} />
        </Action>
      );

      enqueueSnackbar(
        <Message>{intl.formatMessage({ id: messageId })}</Message>,
        {
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          persist: true,
          ...props?.enqueueOptions,
          key,
          action: snackbarAction,
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    enqueueSnackbar: _enqueueSnackbar,
    closeSnackbar: _closeSnackbar,
  };
}

export function useDemoSiteSnackbar() {
  const { enqueueSnackbar } = useCabiotSnackbar(undefined, {
    noRefreshButton: true,
  });

  return {
    enqueueDemoSiteSnackbar: () =>
      IS_ENV.DEMO && enqueueSnackbar('common.errors.on_demo_site'),
  };
}
