import axios from 'src/utils/api';
import { handleError } from './error';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type PutServiceProviderWorkspacesUsersMeRequestBody = {
  role: 'admin' | 'normal';
};

export type PutServiceProviderWorkspacesUsersMeResponse = undefined;

export async function putServiceProviderWorkspacesUsersMe({
  workspaceId,
  role,
  token,
}: {
  workspaceId: string;
  role: PutServiceProviderWorkspacesUsersMeRequestBody['role'];
  token: string;
}): Promise<PutServiceProviderWorkspacesUsersMeResponse> {
  const requestBody: PutServiceProviderWorkspacesUsersMeRequestBody = {
    role,
  };

  return axios
    .put(
      `${API_ENDPOINT}/serviceProvider/workspaces/${workspaceId}/users/me`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
