import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  errorSelector as gatewaysErrorSelector,
  GatewaysErrorCode,
} from 'src/domains/root/features/gateways/slice';
import {
  listResultSelector as alertsListResultSelector,
  updateResultSelector as alertUpdateResultSelector,
  AlertsErrorCode,
} from 'src/domains/root/features/alerts/slice';
import {
  errorSelector as alertHistoriesErrorSelector,
  AlertHistoriesErrorCode,
} from 'src/domains/root/features/alerts/histories/slice';
import {
  errorSelector as alertStatusErrorSelector,
  AlertStatusErrorCode,
} from 'src/domains/root/features/current-alert/slice';
import {
  errorSelector as currentWorkspacesErrorSelector,
  CurrentWorkspaceErrorCode,
} from 'src/domains/root/features/current-workspace/slice';
import {
  errorSelector as gatewayAndSensorUnitNamesErrorSelector,
  GatewayAndSensorUnitNamesErrorCode,
} from 'src/domains/root/features/gateway-and-sensor-names/slice';
import {
  errorSelector as sensorUnitObserveModeErrorSelector,
  SensorUnitObserveModeErrorCode,
} from 'src/domains/root/features/sensor-units/observe/slice';
import {
  errorSelector as gatewaysAreaIdErrorSelector,
  GatewaysAreaIdErrorCode,
} from 'src/domains/root/features/gateways/area-id/slice';
import {
  showResultSelector as sensorUnitsAlarmThresholdsShowResultSelector,
  updateResultSelector as sensorUnitsAlarmThresholdsUpdateResultSelector,
  SensorUnitsAlarmThresholdsErrorCode,
} from 'src/domains/root/features/sensor-units/alarm-threshold/slice';
import { useSnackbar } from 'notistack';
import { useSelector } from 'src/domains/root/store';
import {
  resultSelector as areasResultSelector,
  AreasErrorCode,
} from '../features/areas/slice';
import {
  updateResultSelector as workspaceUpdateResultSelector,
  WorkspaceErrorCode,
} from '../features/workspaces/slice';
import {
  errorSelector as workspacesGatewaysErrorSelector,
  WorkspacesGatewaysErrorCode,
} from '../features/workspaces/gateways/slice';
import {
  listErrorSelector as workspacesUsersListErrorSelector,
  updateResultSelector as workspacesUsersUpdateResultSelector,
  deleteErrorSelector as workspacesUsersDeleteErrorSelector,
  WorkspacesUsersErrorCode,
} from '../features/workspaces/users/slice';
import {
  listResultSelector as workspacesUsersListResultSelector,
  createResultSelector as workspacesUsersCreateResultSelector,
  removeResultSelector as workspacesUsersRemoveResultSelector,
  WorkspacesInvitationsErrorCode,
} from '../features/workspaces/invitations/slice';
import {
  removeResultSelector as workspacesGatewaysSensorUnitsRemoveResultSelector,
  WorkspacesGatewaysSensorDataErrorCode,
} from '../features/workspaces/gateways/sensor-data/slice';
import {
  createResultSelector as workspacesPackagesCreateResultSelector,
  NewWorkspaceErrorCode,
} from '../features/workspaces/packages/slice';
import {
  getResultSelector as workspacesStripeCustomerGetResultSelector,
  updateResultSelector as workspacesStripeCustomerUpdateResultSelector,
  WorkspacesStripeCustomerErrorCode,
} from '../features/workspaces/stripe-customer/slice';
import {
  createResultSelector as workspacesStripeCustomerPortalSessionCreateResultSelector,
  WorkspacesStripeCustomerPortalSessionsErrorCode,
} from '../features/workspaces/stripe-customer-portal-session/slice';
import {
  createResultSelector as usersEmailChangesCreateSelector,
  updateResultSelector as usersEmailChangesUpdateSelector,
  UsersEmailChangeErrorCode,
} from '../features/users/email-changes/slice';
import {
  meResultSelector,
  UsersMeErrorCode,
  workspaceActivationResultSelector,
  ActivateWorkspaceErrorCode,
} from '../features/users/slice';
import { resultSelector } from '../features/users/terms-of-service-reagreement/slice';
import {
  createResultSelector as paymentsCreateResultSelector,
  PaymentsErrorCode,
} from '../features/contracts/paymentSlice';
import {
  createResultSelector,
  PostWorkspaceResponseErrorCode,
} from '../features/setup/workspaces/slice';

type CallbackFunction = () => string;

export interface UnrecoverableErrorHandlerProps {
  redirectTo: {
    [key in
      | WorkspaceErrorCode
      | CurrentWorkspaceErrorCode
      | UsersMeErrorCode
      | ActivateWorkspaceErrorCode
      | GatewaysErrorCode
      | AlertsErrorCode
      | AlertHistoriesErrorCode
      | AreasErrorCode
      | GatewaysAreaIdErrorCode
      | GatewayAndSensorUnitNamesErrorCode
      | SensorUnitObserveModeErrorCode
      | SensorUnitsAlarmThresholdsErrorCode
      | AlertStatusErrorCode
      | WorkspacesUsersErrorCode
      | WorkspacesGatewaysErrorCode
      | WorkspacesGatewaysSensorDataErrorCode
      | NewWorkspaceErrorCode
      | UsersEmailChangeErrorCode
      | WorkspacesInvitationsErrorCode
      | WorkspacesStripeCustomerErrorCode
      | WorkspacesStripeCustomerPortalSessionsErrorCode
      | PaymentsErrorCode
      | PostWorkspaceResponseErrorCode]?: CallbackFunction | string;
  };
  children: React.ReactNode;
}
export default function UnrecoverableErrorHandler({
  redirectTo: redirectionMapping,
  children,
}: UnrecoverableErrorHandlerProps) {
  const { closeSnackbar } = useSnackbar();

  const errors = [
    useSelector(workspaceUpdateResultSelector).error,
    useSelector(currentWorkspacesErrorSelector),
    useSelector(meResultSelector).error,
    useSelector(workspaceActivationResultSelector).error,
    useSelector(gatewaysErrorSelector),
    useSelector(alertsListResultSelector).error,
    useSelector(alertUpdateResultSelector).error,
    useSelector(alertHistoriesErrorSelector),
    useSelector(areasResultSelector).error,
    useSelector(gatewaysAreaIdErrorSelector),
    useSelector(gatewayAndSensorUnitNamesErrorSelector),
    useSelector(sensorUnitObserveModeErrorSelector),
    useSelector(sensorUnitsAlarmThresholdsShowResultSelector).error,
    useSelector(sensorUnitsAlarmThresholdsUpdateResultSelector).error,
    useSelector(alertStatusErrorSelector),
    useSelector(workspacesGatewaysErrorSelector),
    useSelector(workspacesGatewaysSensorUnitsRemoveResultSelector).error,
    useSelector(workspacesUsersListErrorSelector),
    useSelector(workspacesUsersUpdateResultSelector).error,
    useSelector(workspacesUsersDeleteErrorSelector),
    useSelector(workspacesPackagesCreateResultSelector).error,
    useSelector(workspacesUsersListResultSelector).error,
    useSelector(workspacesUsersCreateResultSelector).error,
    useSelector(workspacesUsersRemoveResultSelector).error,
    useSelector(workspacesStripeCustomerGetResultSelector).error,
    useSelector(workspacesStripeCustomerUpdateResultSelector).error,
    useSelector(workspacesStripeCustomerPortalSessionCreateResultSelector)
      .error,
    useSelector(usersEmailChangesCreateSelector).error,
    useSelector(usersEmailChangesUpdateSelector).error,
    useSelector(resultSelector).error,
    useSelector(paymentsCreateResultSelector).error,
    useSelector(createResultSelector).error,
  ];

  const redirectTo = errors
    .filter((error): error is NonNullable<typeof error> => !!error)
    .filter((error) => !error.recoverable)
    .map((error) => error.code)
    .map((errorCode) => redirectionMapping[errorCode])
    .find((redirectTo) => redirectTo);

  if (redirectTo) {
    closeSnackbar();
    const path = typeof redirectTo === 'function' ? redirectTo() : redirectTo;
    return <Navigate to={path} />;
  }

  return <>{children}</>;
}
