import styled from 'styled-components';

const Action = styled.div`
  & > * {
    margin: ${(props) => props.theme.spacing(1)};
  }
`;

const Message = styled.div`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

export { Action, Message };
