import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import * as API from 'src/apis';

type Role = API.PutServiceProviderWorkspacesUsersMeRequestBody['role'];

export type SucceededResult = {
  status: 'succeeded';
};

type ErrorCode = 'bad_request' | 'unknown_error';

export type Result =
  | { status: 'hasError'; code: ErrorCode }
  | { status: 'loading' }
  | { status: 'idle' }
  | SucceededResult;

export default function usePutServiceProviderWorkspacesUsersMe(): {
  mutate: ({
    workspaceId,
    role,
  }: {
    workspaceId: string;
    role: Role;
  }) => Promise<API.PutServiceProviderWorkspacesUsersMeResponse>;
  reset: () => void;
  result: Result;
} {
  const { getAccessTokenSilently } = useAuth0();

  const result = useMutation<
    undefined,
    API.Error,
    { workspaceId: string; role: Role }
  >({
    mutationFn: async ({
      workspaceId,
      role,
    }: {
      workspaceId: string;
      role: Role;
    }) => {
      const token = await getAccessTokenSilently();

      return API.putServiceProviderWorkspacesUsersMe({
        workspaceId,
        role,
        token,
      });
    },
  });

  const mutate = ({
    workspaceId,
    role,
  }: {
    workspaceId: string;
    role: Role;
  }) => {
    return result.mutateAsync({ workspaceId, role });
  };

  useRedirectForError(result);

  if (result.isIdle) {
    return {
      mutate,
      reset: result.reset,
      result: { status: 'idle' },
    };
  }

  if (result.isPending) {
    return {
      mutate,
      reset: result.reset,
      result: { status: 'loading' },
    };
  }

  if (result.isError) {
    const error = result.error as API.Error;
    switch (error.code) {
      case 'bad_request':
        return {
          mutate,
          reset: result.reset,
          result: {
            status: 'hasError',
            code: 'bad_request',
          },
        };
      default:
        return {
          mutate,
          reset: result.reset,
          result: {
            status: 'hasError',
            code: 'unknown_error',
          },
        };
    }
  }

  return {
    mutate,
    reset: result.reset,
    result: {
      status: 'succeeded',
    },
  };
}

function useRedirectForError(
  result: UseMutationResult<
    undefined,
    API.Error,
    {
      workspaceId: string;
      role: Role;
    },
    unknown
  >,
) {
  const navigate = useNavigate();
  useEffect(() => {
    const error = result.error;
    if (!error) {
      return;
    }
    switch (error.code) {
      case 'permission_denied':
        navigate('/errors/permission-denied');
        break;
      case 'emergency_maintenance':
        navigate('/errors/emergency-maintenance');
        break;
    }
  }, [result.error, navigate]);
}
