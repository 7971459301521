import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import SecondaryButton from './Secondary';

const _BackIcon = createSvgIcon(
  <path
    d="M7 1L2 6L7 11"
    stroke="#F05064"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  'Back',
);

const BackIcon = styled(_BackIcon).attrs<SvgIconProps>(() => ({
  viewBox: '0 0 10 12',
}))`
  fill: none;
  height: 12px;
  width: 10px;
`;

const buttonStyle = css`
  &:hover {
    path {
      stroke: white;
    }
  }
`;

const BackButton: React.FC<ButtonProps> = (props) => {
  const intl = useIntl();

  return (
    <SecondaryButton
      fullWidth
      startIcon={<BackIcon />}
      css={buttonStyle}
      {...props}
    >
      {intl.formatMessage({
        id: 'common.buttons.back',
      })}
    </SecondaryButton>
  );
};

export default BackButton as typeof Button;
