import React from 'react';
import { useIntl } from 'react-intl';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CabiotLogo from '../../commons/CabiotLogo';
import PrimaryButton from '../../commons/buttons/PrimaryButton';

const QrWelcome: React.FC = () => {
  const intl = useIntl();

  const registrationCode =
    new URLSearchParams(location.search).get('registrationCode') || '';

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={7.5}>
          <CabiotLogo />
        </Box>
        <Box my={8.5}>
          <Typography variant="h2" align="center">
            {intl.formatMessage({
              id: 'qr.welcome.title.0',
            })}
            <Box
              component="br"
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: {
                  display: 'none',
                },
              })}
            />
            {intl.formatMessage({
              id: 'qr.welcome.title.1',
            })}
          </Typography>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up('sm')]: {
                width: 500,
              },
            })}
          >
            <Box mt={7}>
              <Typography variant="body2" align="center">
                {intl.formatMessage({
                  id: 'qr.welcome.signup.message',
                })}
              </Typography>
            </Box>
            <Box mt={2}>
              <PrimaryButton
                fullWidth
                href={`/registration/signup?registrationCode=${registrationCode}`}
              >
                {intl.formatMessage({
                  id: 'qr.welcome.signup.button',
                })}
              </PrimaryButton>
            </Box>
            <Box mt={7}>
              <Typography variant="body2" align="center">
                {intl.formatMessage({
                  id: 'qr.welcome.signin.message',
                })}
              </Typography>
            </Box>
            <Box mt={2}>
              <PrimaryButton fullWidth href="/">
                {intl.formatMessage({
                  id: 'qr.welcome.signin.button',
                })}
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default QrWelcome;
