import React from 'react';

const GatewayLabelIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="28"
      height="12"
      viewBox="0 0 28 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="28" height="12" rx="1" fill="#E2DCD2" />
      <path
        d="M9.21347 6.72874H10.8112V7.139C10.804 7.63203 10.6498 8.02969 10.3485 8.33198C10.0473 8.64867 9.66535 8.80702 9.20271 8.80702C8.92298 8.80702 8.6827 8.74764 8.48187 8.62888C8.27745 8.52812 8.11248 8.39676 7.98696 8.23482C7.83275 8.06568 7.73054 7.84435 7.68033 7.57085C7.62295 7.29735 7.59426 6.77373 7.59426 6C7.59426 5.22627 7.62295 4.69906 7.68033 4.41835C7.73054 4.15205 7.83275 3.93432 7.98696 3.76518C8.11248 3.60324 8.27745 3.46829 8.48187 3.36032C8.6827 3.25596 8.92298 3.20018 9.20271 3.19298C9.58286 3.20018 9.90563 3.30994 10.171 3.52227C10.4256 3.74539 10.6014 4.01529 10.6982 4.33198H11.957C11.8315 3.67341 11.5302 3.12101 11.0532 2.67476C10.5763 2.23212 9.95942 2.0072 9.20271 2C8.58587 2.0072 8.07124 2.15475 7.65881 2.44265C7.23922 2.72695 6.93438 3.05083 6.74431 3.41431C6.62596 3.60504 6.5381 3.85515 6.48072 4.16464C6.42692 4.47413 6.40002 5.08592 6.40002 6C6.40002 6.89968 6.42692 7.50787 6.48072 7.82456C6.50941 7.9937 6.54527 8.13405 6.5883 8.24561C6.63493 8.35358 6.68693 8.46694 6.74431 8.5857C6.93438 8.94917 7.23922 9.26946 7.65881 9.54656C8.07124 9.83446 8.58587 9.98561 9.20271 10C10.0025 9.98561 10.6659 9.7139 11.1931 9.18489C11.7167 8.65227 11.9857 7.9919 12 7.20378V5.60054H9.21347V6.72874Z"
        fill="black"
      />
      <path
        d="M14.9688 9.9349H15.9121L17.3425 4.26148H17.363L18.7882 9.9349H19.7366L21.6027 2.06445H20.4082L19.2291 7.6569H19.2086L17.7629 2.06445H16.9426L15.4917 7.6569H15.4712L14.2972 2.06445H13.1027L14.9688 9.9349Z"
        fill="black"
      />
    </svg>
  );
};

export default GatewayLabelIcon;
