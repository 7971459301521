import React from 'react';
import { CircularProgress } from '@mui/material';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';

type Props = {
  loading: boolean;
  disalbed: boolean;
  children: React.ReactNode;
};

export function SubmitButton({ loading, disalbed, children }: Props) {
  return (
    <PrimaryButton type="submit" disabled={disalbed} data-testid="SubmitButton">
      {loading ? <CircularProgress color="inherit" size={20} /> : children}
    </PrimaryButton>
  );
}
