import React from 'react';

const WorkspaceArrowDownIcon: React.FC = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
): React.JSX.Element => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 4L8 9L13 4"
        stroke="#F05064"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WorkspaceArrowDownIcon;
