import React from 'react';
import { CompareChartDataType } from 'src/domains/root/utils/highcharts/chart-utils';
import HookStatus from 'src/domains/root/commons/HookStatus';
import { Gateway } from './slice';
import useGatewaysDataChart from './sensor-data/useGatewaysSensorDataChart';
import GatewaySensorDataChart from './GatewaySensorDataChart';

type Props = {
  gateway: Gateway;
  type: CompareChartDataType;
};
const GatewaySensorDataChartContainer: React.FC<Props> = ({
  gateway,
  type,
}) => {
  const result = useGatewaysDataChart({ gateway });

  if (result.status === 'loading' || result.status === 'hasError') {
    return (
      <HookStatus
        target="sensorDataChart"
        error={result.status === 'hasError'}
        loading={result.status === 'loading'}
      />
    );
  }

  const noDataAboutSelectedType = result.gatewaySensorData.every(
    (data) => !data[type],
  );
  if (noDataAboutSelectedType) {
    return (
      <HookStatus
        target="sensorDataChart"
        error={false}
        loading={false}
        noData={true}
      />
    );
  }

  return (
    <GatewaySensorDataChart
      type={type}
      gateway={gateway}
      gatewaySensorDataResult={result}
    />
  );
};

export default GatewaySensorDataChartContainer;
