import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Status } from 'src/domains/root/store';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type GatewayAndSensorUnitNamesErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'emergency_maintenance'
  | 'unknown_error';

export interface PutGatewayAndSensorUnitNamesParams {
  token: Promise<string>;
  gateway: { deviceId: string; name: string };
  sensorUnits: { deviceId: string; number: number; name: string }[];
}

export const putGatewayAndSensorUnitNames = createAsyncThunk<
  void,
  PutGatewayAndSensorUnitNamesParams,
  {
    state: RootState;
    rejectValue: RootError<GatewayAndSensorUnitNamesErrorCode>;
  }
>(
  'gatewayAndSensorNames/putGatewayAndSensorUnitNames',
  async (params, thunkAPI) => {
    const token = await params.token;
    const { gateway, sensorUnits } = params;
    const body = {
      gateway: gateway.name,
      sensorUnits: sensorUnits.map((sensorUnit) => {
        const { number, name } = sensorUnit;
        return { number, name };
      }),
    };

    try {
      await axios.put<void>(
        `${API_ENDPOINT}/gateways/name/${params.gateway.deviceId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }
      const gatewayAndSensorUnitNamesError =
        getGatewayAndSensorUnitNamesError(error);
      return thunkAPI.rejectWithValue(gatewayAndSensorUnitNamesError);
    }
  },
);

const getGatewayAndSensorUnitNamesError = (
  error: AxiosError<RootErrorResponse>,
): RootError<GatewayAndSensorUnitNamesErrorCode> => {
  if (error.response) {
    const { status } = error.response;
    const { errorCode } = error.response.data;
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: false,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

export interface GatewayAnsSensorNamesState {
  error: RootError<GatewayAndSensorUnitNamesErrorCode> | null;
  status: Status;
}

const initialState: GatewayAnsSensorNamesState = {
  error: null,
  status: 'idle',
};

export const gatewaySlice = createSlice({
  name: 'gatewayAndSensorNames',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putGatewayAndSensorUnitNames.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putGatewayAndSensorUnitNames.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(putGatewayAndSensorUnitNames.rejected, (state, action) => {
        state.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.error = action.payload!;
      });
  },
});

export const errorSelector = createSelector(
  (state: RootState) => state.gatewayAndSensorNames,
  (state: GatewayAnsSensorNamesState) => state.error,
);

export const statusSelector = createSelector(
  (state: RootState) => state.gatewayAndSensorNames,
  (state: GatewayAnsSensorNamesState) => state.status,
);

export default gatewaySlice.reducer;
