import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  registrationCode: string;
}

const RegistrationCodeField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, registrationCode: true });
  };

  const errorMessage =
    (values.registrationCode || touched.registrationCode) &&
    errors.registrationCode;

  return (
    <TextField
      data-testid="RegistrationCodeTextInput"
      name="registrationCode"
      type="text"
      value={values.registrationCode}
      label={intl.formatMessage({
        id: 'pages.OtherSetting.gateways.new.form.registrationCode.label',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default RegistrationCodeField;
