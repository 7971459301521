import React from 'react';
import HookStatus from 'src/domains/root/commons/HookStatus';
import { activeWorkspaceSelector } from 'src/domains/root/features/users/slice';
import { useSelector } from 'src/domains/root/store';
import GatewayList from './GatewayList';
import useGateways from './useGateways';

/**
 * TODO: 実験用センサーユニットの利用が終わったら削除する
 * PBI: https://www.pivotaltracker.com/story/show/186128793
 */
import GatewayListExperimental from './GatewayList_for_experimental_20231004';

const GatewayListContainer: React.FC = () => {
  const { gateways, error, status } = useGateways();

  const loading = status === 'loading';
  const noData = status === 'succeeded' && !gateways.length;

  /**
   * TODO: 実験用センサーユニットの利用が終わったら削除する
   * PBI: https://www.pivotaltracker.com/story/show/186128793
   */
  const workspace = useSelector(activeWorkspaceSelector);
  const specialWorkspace =
    !!workspace &&
    [
      'ws-297c7cbb-45ef-40c8-8a03-3683543f3588', // [dev] _PBI #186128793 試験用WS
      'ws-bb481c81-adaf-42d8-9a5b-a211fc1f00ee', // [rev] _PBI #186128793 試験用WS
      'ws-8c89bae0-b4cc-4c60-aa57-d8799a753e4e', // [stg] 長久手工場 今回対象のワークスペース
    ].includes(workspace.workspaceId);

  if (loading || !!error || noData) {
    return (
      <HookStatus
        target="gatewayTable"
        loading={loading}
        error={!!error}
        noData={noData}
      />
    );
  }

  /**
   * TODO: 実験用センサーユニットの利用が終わったら削除する
   * PBI: https://www.pivotaltracker.com/story/show/186128793
   * 合わせて、以下のコンポーネントも削除する
   * - GatewayList_for_experimental_20231004.tsx
   * - GatewayTableRow_for_experimental_20231004.tsx
   */
  if (specialWorkspace) {
    return <GatewayListExperimental gateways={gateways} />;
  }

  return <GatewayList gateways={gateways} />;
};

export default GatewayListContainer;
