import React, { useEffect } from 'react';
import { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import {
  useMediaQuery,
  useTheme,
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Link,
  Switch,
  SxProps,
  Theme,
} from '@mui/material';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import SensorUnitName from 'src/domains/root/commons/settings/dialog/SensorUnitName';
import GatewayName from 'src/domains/root/commons/settings/dialog/GatewayName';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import {
  updateResultSelector,
  resetShowResult,
  resetUpdateResult,
  SensorUnitsAlarmThresholds,
  putSensorUnitsAlarmThresholds,
  showResultSelector,
  getSensorUnitsAlarmThresholds,
} from 'src/domains/root/features/sensor-units/alarm-threshold/slice';
import { useDispatch } from 'react-redux';
import Title from 'src/domains/root/commons/dialog/Title';
import Description from 'src/domains/root/commons/settings/dialog/Description';
import ErrorAlert from 'src/domains/root/commons/dialog/alerts/ErrorAlert';
import SuccessAlert from 'src/domains/root/commons/dialog/alerts/SuccessAlert';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import { updateSensorUnitAlarms } from 'src/domains/root/features/gateways/slice';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import ProgressOverlay from 'src/domains/root/commons/dialog/actions/ProgressOverlay';
import HookStatus from 'src/domains/root/commons/HookStatus';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import { CONDENSATION_ALERT_TEMPERATURE_FAQ_URL } from 'src/constants';
import { Text } from 'src/shared/ui';
import AlarmThresholdInputForm, { FormValue } from './AlarmThresholdInputForm';

const dialogStyle = css`
  & .MuiDialog-paper {
    width: 750px;
    ${(props) => props.theme.breakpoints.down('md')} {
      margin: 24px;
      width: 400px;
    }
  }
`;

const boxStyle = css`
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`;

type ThresholdKey = 'temperature' | 'humidity' | 'objectTemperature';

interface Props {
  permitted: boolean;
  gatewayName: string;
  sensorUnitName: string;
  sensorUnitId: string;
  observeMode: string;
  isAlarmEnabled: boolean;
  setOpen: (open: boolean) => void;
  sx?: SxProps<Theme>;
}
export default function AlarmSettingModal(props: Props) {
  const {
    permitted,
    gatewayName,
    sensorUnitName,
    sensorUnitId,
    observeMode,
    setOpen,
    sx = [],
  } = props;

  const intl = useIntl();
  const { getAccessTokenSilently } = useAuth0();
  const { status: updateStatus } = useSelector(updateResultSelector);
  const dispatch: AppDispatch = useDispatch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: alarmThresholds, status: showStatus } =
    useSelector(showResultSelector);

  useEffect(() => {
    if (!open) return;

    const token = getAccessTokenSilently();
    const promise = dispatch(
      getSensorUnitsAlarmThresholds({ token, sensorUnitId }),
    );

    return () => promise.abort();
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: FormValue) => {
    const token = getAccessTokenSilently();
    const promise = dispatch(
      putSensorUnitsAlarmThresholds({
        token,
        sensorUnitId,
        alarmThresholds: convertToAlarmThresholds(values),
      }),
    );

    promise.unwrap().then((data) => {
      const { gatewayId, sensorUnitId, isAlarmEnabled } = data;
      dispatch(
        updateSensorUnitAlarms({
          gateway: { deviceId: gatewayId },
          sensorUnits: [
            {
              deviceId: sensorUnitId,
              isAlarmEnabled: isAlarmEnabled,
            },
          ],
        }),
      );
    });

    return promise;
  };

  const requireTest = (
    upperLower: 'upper' | 'lower',
  ): yup.TestConfig<number | undefined, yup.AnyObject> => ({
    name: 'required',
    message: intl.formatMessage({ id: 'validation.mixed.required' }),
    test: (val, context) => {
      return context.parent[`${upperLower}ThresholdEnabled`]
        ? val != null
        : true;
    },
  });
  const validNumberTest = (
    upperLower: 'upper' | 'lower',
  ): yup.TestConfig<number | undefined, yup.AnyObject> => ({
    name: 'valid number',
    message: intl.formatMessage(
      { id: 'alarmSettingModal.validation.number.range' },
      { min: -100, max: 1000 },
    ),
    test: (val, context) => {
      return context.parent[`${upperLower}ThresholdEnabled`]
        ? yup.number().min(-100).max(1000).isValidSync(val)
        : true;
    },
  });
  const validHumidityNumberTest = (
    upperLower: 'upper' | 'lower',
  ): yup.TestConfig<number | undefined, yup.AnyObject> => ({
    name: 'valid number',
    message: intl.formatMessage(
      { id: 'alarmSettingModal.validation.number.range' },
      { min: 0, max: 100 },
    ),
    test: (val, context) => {
      return context.parent[`${upperLower}ThresholdEnabled`]
        ? yup.number().min(0).max(100).isValidSync(val)
        : true;
    },
  });
  const greaterThanLowerTest: yup.TestConfig<
    number | undefined,
    yup.AnyObject
  > = {
    name: 'greater than lowerThreshold',
    message: intl.formatMessage({
      id: 'alarmSettingModal.validation.number.greaterThanLower',
    }),
    test: (val, context) => {
      return context.parent.upperThresholdEnabled &&
        context.parent.lowerThresholdEnabled &&
        context.parent.lowerThreshold &&
        val != null
        ? context.parent.lowerThreshold < val
        : true;
    },
  };
  const lessThanUpperTest: yup.TestConfig<number | undefined, yup.AnyObject> = {
    name: 'less than upperThreshold',
    message: intl.formatMessage({
      id: 'alarmSettingModal.validation.number.lowerThanUpper',
    }),
    test: (val, context) => {
      return context.parent.lowerThresholdEnabled &&
        context.parent.upperThresholdEnabled &&
        context.parent.upperThreshold &&
        val != null
        ? context.parent.upperThreshold > val
        : true;
    },
  };

  const validationSchema = yup.object().shape({
    temperature: yup.object().shape({
      upperThresholdEnabled: yup.boolean(),
      upperThreshold: yup
        .number()
        .test(requireTest('upper'))
        .test(validNumberTest('upper'))
        .test(greaterThanLowerTest),
      lowerThresholdEnabled: yup.boolean(),
      lowerThreshold: yup
        .number()
        .test(requireTest('lower'))
        .test(validNumberTest('lower'))
        .test(lessThanUpperTest),
    }),
    humidity: yup.object().shape({
      upperThresholdEnabled: yup.boolean(),
      upperThreshold: yup
        .number()
        .test(requireTest('upper'))
        .test(validHumidityNumberTest('upper'))
        .test(greaterThanLowerTest),
      lowerThresholdEnabled: yup.boolean(),
      lowerThreshold: yup
        .number()
        .test(requireTest('lower'))
        .test(validHumidityNumberTest('lower'))
        .test(lessThanUpperTest),
    }),
    objectTemperature: yup.object().shape({
      upperThresholdEnabled: yup.boolean(),
      upperThreshold: yup
        .number()
        .test(requireTest('upper'))
        .test(validNumberTest('upper'))
        .test(greaterThanLowerTest),
      lowerThresholdEnabled: yup.boolean(),
      lowerThreshold: yup
        .number()
        .test(requireTest('lower'))
        .test(validNumberTest('lower'))
        .test(lessThanUpperTest),
    }),
    condensationAlertTemperature: yup.object().shape({
      enabled: yup.boolean(),
    }),
  });

  const formik = useFormik({
    initialValues: convertToFormValues(alarmThresholds),
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleClose = () => {
    if (setOpen) setOpen(false);
    dispatch(resetShowResult());
    dispatch(resetUpdateResult());
  };

  const location = useLocation();
  useEffect(() => {
    awsRum().then((rum) => {
      rum.recordPageView(
        location.pathname + '#GatewaySettingsAlarmSettingModalRender',
      );
    });
  }, [location]);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
      aria-labelledby="alarm-threshold-setting-dialog"
      css={dialogStyle}
    >
      <FormikProvider value={formik}>
        <DialogTitle id="alarm-threshold-setting-dialog-title">
          <Title onClose={handleClose}>
            {intl.formatMessage({
              id: 'alarmSettingModal.dialogTitle',
            })}
          </Title>
          {updateStatus === 'failed' && (
            <Box mt={2.5} mb={0.5}>
              <ErrorAlert />
            </Box>
          )}
          {updateStatus === 'succeeded' && (
            <Box mt={2.5} mb={0.5}>
              <SuccessAlert />
            </Box>
          )}
        </DialogTitle>
        <DialogContent
          sx={[
            {
              paddingX: isSmallScreen ? '20px' : '24px',
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          <GatewayName name={gatewayName} />
          <Box mt={1.5} mx={1.5}>
            <SensorUnitName name={sensorUnitName} observeMode={observeMode} />
            {!permitted && (
              <Box my={2.5}>
                <Description>
                  {intl.formatMessage({
                    id: 'alarmSettingModal.description',
                  })}
                </Description>
              </Box>
            )}
          </Box>
          {showStatus === 'failed' || showStatus === 'loading' ? (
            <HookStatus
              target="alarmThresholdData"
              error={showStatus === 'failed'}
              loading={showStatus === 'loading'}
            />
          ) : (
            <Box
              data-testid="DialogForm"
              display="flex"
              flexDirection="column"
              mt={3.5}
              css={boxStyle}
            >
              <AlarmThresholdInputForm
                permitted={permitted}
                target="temperature"
              />
              <AlarmThresholdInputForm
                permitted={permitted}
                target="humidity"
              />
              <AlarmThresholdInputForm
                permitted={permitted}
                target="objectTemperature"
              />
              <CondensationAlertTemperature permitted={permitted} />
            </Box>
          )}
        </DialogContent>
        <Form>
          <DialogActions>
            <Box display="flex" gap={1.5}>
              <CancelButton onClick={handleClose} />
              {permitted && (
                <ProgressOverlay loading={formik.isSubmitting}>
                  <PrimaryButton
                    data-testid="SubmitButton"
                    type="submit"
                    disabled={
                      showStatus !== 'succeeded' ||
                      !formik.isValid ||
                      formik.isSubmitting
                    }
                  >
                    {intl.formatMessage({
                      id: 'common.update',
                    })}
                  </PrimaryButton>
                </ProgressOverlay>
              )}
            </Box>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

function convertToAlarmThresholds(
  values: FormValue,
): SensorUnitsAlarmThresholds {
  const convertItem = (
    item: FormValue[ThresholdKey],
  ): SensorUnitsAlarmThresholds[ThresholdKey] => ({
    upperThreshold: {
      enabled: item.upperThresholdEnabled,
      value: item.upperThreshold,
    },
    lowerThreshold: {
      enabled: item.lowerThresholdEnabled,
      value: item.lowerThreshold,
    },
  });

  return {
    temperature: convertItem(values.temperature),
    humidity: convertItem(values.humidity),
    objectTemperature: convertItem(values.objectTemperature),
    condensationAlertTemperature: {
      enabled: values.condensationAlertTemperature.enabled,
    },
  };
}

function convertToFormValues(
  alarmThresholds: SensorUnitsAlarmThresholds | null,
): FormValue {
  const convertItem = (
    threshold?: SensorUnitsAlarmThresholds[ThresholdKey],
  ): FormValue[ThresholdKey] => ({
    upperThresholdEnabled: threshold?.upperThreshold.enabled ?? false,
    upperThreshold: threshold?.upperThreshold.value,
    lowerThresholdEnabled: threshold?.lowerThreshold.enabled ?? false,
    lowerThreshold: threshold?.lowerThreshold.value,
  });

  return {
    temperature: convertItem(alarmThresholds?.temperature),
    humidity: convertItem(alarmThresholds?.humidity),
    objectTemperature: convertItem(alarmThresholds?.objectTemperature),
    condensationAlertTemperature: {
      enabled: alarmThresholds?.condensationAlertTemperature.enabled ?? false,
    },
  };
}

function CondensationAlertTemperature({ permitted }: { permitted: boolean }) {
  const intl = useIntl();
  const theme = useTheme();
  const isDownMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { values, handleChange } =
    useFormikContext<SensorUnitsAlarmThresholds>();

  const enabled = values.condensationAlertTemperature?.enabled;

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
      >
        <Box
          display="flex"
          width={{ xs: '100%', sm: '100%', md: 'auto' }}
          pb={{ xs: 4, sm: 4, md: 0 }}
          alignItems="center"
        >
          <Text variant="Basic_A" pl={{ xs: 0, sm: 0, md: 1.5 }}>
            {intl.formatMessage({
              id: 'common.condensationAlertTemperature',
            })}
          </Text>
          {isDownMediumScreen && (
            <Divider
              sx={{
                ml: 5,
                backgroundColor: '#dcdcdc',
                flex: 1,
                height: '1px',
              }}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            pl={{ xs: 0, sm: 0, md: 4 }}
            pr={2.5}
            ml={{ xs: '-11px', sm: '-11px', md: 0 }}
          >
            <Switch
              color="primary"
              checked={enabled}
              onChange={handleChange}
              name="condensationAlertTemperature.enabled"
              disabled={!permitted}
            />
          </Box>
          <Text variant="Button_B">
            {intl.formatMessage({
              id: 'alarmSettingModal.condensationAlertTemperature.description',
            })}
          </Text>
        </Box>
      </Box>
      <Box
        textAlign="right"
        pt={{ xs: 1, sm: 1, md: 0 }}
        pb={{ xs: 2.5, sm: 2.5, md: 0 }}
      >
        <Link
          href={CONDENSATION_ALERT_TEMPERATURE_FAQ_URL}
          target="_blank"
          rel="noopener noreferrer"
          fontSize={12}
          fontWeight={700}
        >
          {intl.formatMessage({
            id: 'alarmSettingModal.condensationAlertTemperature.about',
          })}
        </Link>
      </Box>
    </Box>
  );
}
