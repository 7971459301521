import React from 'react';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

export interface Values {
  password: string;
}

const PasswordField: React.FC = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setTouched } =
    useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, password: true });
  };

  return (
    <TextField
      data-testid="PasswordTextInput"
      name="password"
      type="password"
      value={values.password}
      label={intl.formatMessage({
        id: 'registrations.users.new.form.password',
      })}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      error={touched.password && Boolean(errors.password)}
      helperText={touched.password && errors.password}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default PasswordField;
