import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import GatewayDataChip from './Chip';

const GatewayNumber: React.FC<{ physicalId: string }> = (props) => {
  const { physicalId } = props;
  const intl = useIntl();

  return (
    <Box pb={0.25}>
      <GatewayDataChip
        label={intl.formatMessage({
          id: 'gatewayInfo.number',
        })}
      />
      <Box pt={2} display="flex" flexDirection="row" alignItems="center">
        <Typography sx={{ wordBreak: 'break-word' }}>{physicalId}</Typography>
      </Box>
    </Box>
  );
};

export default GatewayNumber;
