import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import Title from 'src/domains/root/commons/dialog/Title';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import { awsRum } from 'src/utils/rum';
import { useLocation } from 'react-router-dom';
import { DeletableCheck } from './../slice';

const REASON_CODE = {
  YOU_ADMIN_USER_BUT_NOT_EXIST_OTHER_USER_AND_EXIST_DEVICE:
    'YOU_ADMIN_USER_BUT_NOT_EXIST_OTHER_USER_AND_EXIST_DEVICE',
  YOU_ONLY_ADMIN_USER_AND_EXIST_OTHER_USER:
    'YOU_ONLY_ADMIN_USER_AND_EXIST_OTHER_USER',
};

interface DialogProps {
  open: boolean;
  onClose: () => void;
  workspaces?: DeletableCheck['workspaces'];
}

const NotDeletableDialog: React.FC<DialogProps> = (props) => {
  const { open, onClose: _handleClose, workspaces } = props;

  const intl = useIntl();

  const handleClose = () => {
    _handleClose();
  };

  const location = useLocation();
  useEffect(() => {
    if (open) {
      awsRum().then((rum) => {
        rum.recordPageView(
          location.pathname + '#DeleteAccountNotDeletableDialogRender',
        );
      });
    }
  }, [location, open]);

  return (
    <Dialog
      data-testid="NotDeletableDialog"
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="account-delete-dialog"
      onClose={handleClose}
    >
      <DialogTitle id="account-delete-dialog-title">
        <Title onClose={handleClose}>
          {intl.formatMessage({
            id: 'pages.DeleteAccount.dialogs.notDeletable.title',
          })}
        </Title>
      </DialogTitle>
      <DialogContent dividers>
        <Box mt={-1}>
          {workspaces?.map((workspace, index) => (
            <Workspace
              key={index}
              workspace={workspace}
              last={workspaces?.length - 1 === index}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mx={1}>
          <NegativeButton
            data-testid="NotDeletableDialogCloseButton"
            onClick={handleClose}
          >
            {intl.formatMessage({
              id: 'common.cancel',
            })}
          </NegativeButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NotDeletableDialog;

type Workspace = {
  name: string;
  reasonCode: string[];
  deletable: boolean;
};

type WorkspaceProp = {
  workspace: Workspace;
  last: boolean;
};

const Workspace: React.FC<WorkspaceProp> = ({ workspace, last }) => {
  const intl = useIntl();

  const reason = (workspace: Workspace) => {
    if (
      workspace.reasonCode.includes(
        REASON_CODE.YOU_ADMIN_USER_BUT_NOT_EXIST_OTHER_USER_AND_EXIST_DEVICE,
      )
    )
      return 'pages.DeleteAccount.dialogs.notDeletable.messages.YOU_ADMIN_USER_BUT_NOT_EXIST_OTHER_USER_AND_EXIST_DEVICE';

    if (
      workspace.reasonCode.includes(
        REASON_CODE.YOU_ONLY_ADMIN_USER_AND_EXIST_OTHER_USER,
      )
    )
      return 'pages.DeleteAccount.dialogs.notDeletable.messages.YOU_ONLY_ADMIN_USER_AND_EXIST_OTHER_USER';
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      gap={1}
      borderBottom={`${!last && '1px solid #DCDCDC'}`}
      sx={{
        wordBreak: 'break-all',
      }}
    >
      <Typography
        fontWeight={700}
        fontSize={(theme) => theme.typography.pxToRem(16)}
        lineHeight={(theme) => theme.typography.pxToRem(24)}
      >
        {intl.formatMessage({
          id: 'pages.DeleteAccount.dialogs.notice.description.workspace',
        })}
        ：{workspace.name}
      </Typography>
      <Typography
        fontSize={(theme) => theme.typography.pxToRem(16)}
        lineHeight={(theme) => theme.typography.pxToRem(24)}
      >
        {intl.formatMessage({
          id: reason(workspace),
        })}
      </Typography>
    </Box>
  );
};
